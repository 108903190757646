import React from 'react';
import { Button, Col, Glyphicon, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AlertDialog from '../../Shared/AlertDialog';

class PrintList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetMemberID: null,
            targetMemberName: null
        };
        this.removeTooltip = (
            <Tooltip id="remove"><strong>Remove</strong></Tooltip>
        );
    }

    removeMember = () => {
        this.props.removeMember(this.state.targetMemberID);
    }

    removeAllMembers = () => {
        this.props.removeAllMembers();
    }

    handleRemoveClick = (rowIndex) => {
        this.setState({ targetMemberID: this.refs.removedMemberList.state.data[rowIndex].memberMasterID,
                        targetMemberName: this.refs.removedMemberList.state.data[rowIndex].memberName });
        this.props.handleConfirmRemove();
    }

    handleRemoveAllClick = () => {
        this.props.handleConfirmRemoveAll();
    }

    renderTable = (results) => {
        const customSelect = (props) => {
            const rowIndex = props.rowIndex;
            return rowIndex !== 'Header' ? (
                <div>
                    <OverlayTrigger placement="top" overlay={this.removeTooltip}>
                        <Button onClick={() => this.handleRemoveClick(props.rowIndex)}>
                            <Glyphicon glyph="trash" />
                        </Button>
                    </OverlayTrigger>
                </div>
            ) : null;
        };
        const selectRow = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '7rem'
        };

        return (
            <div>
                <Row style={{'marginBottom': '3.3rem'}}>
                    <Col sm={9}>
                        <h4>{this.props.label}</h4>
                    </Col>
                    <Col sm={3}>
                        <Button onClick={this.handleRemoveAllClick} style={{ float: 'right' }}>
                            <Glyphicon glyph="trash" style={{ marginRight: '0.6rem' }} />Clear List
                        </Button>
                    </Col>
                </Row>
                <BootstrapTable data={results} selectRow={selectRow} maxHeight="516px" scroll-top="Top" hover ref="removedMemberList">
                    <TableHeaderColumn dataField="memberName" dataAlign="left" dataSort>Member Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberID" dataAlign="left" dataSort isKey>Member ID</TableHeaderColumn>
                </BootstrapTable>
                <AlertDialog visible={this.props.showConfirmRemoveModal} 
                    handleModalToggle={this.props.handleConfirmRemove} 
                    title={"Remove Selected Member?"}
                    message={`Are you sure you want to delete - ${this.state.targetMemberName}`}
                    handleConfirm={this.removeMember} 
                    confirmLabel={"Remove"} 
                    cancelLabel={"Close"} 
                    confirmStyle={"success"} 
                    glyphicon={"ok"} />
                <AlertDialog visible={this.props.showConfirmRemoveAllModal}
                    handleModalToggle={this.props.handleConfirmRemoveAll}
                    title={"Clear all list"}
                    message={"Are you sure you want to clear the list?"} 
                    handleConfirm={this.removeAllMembers} 
                    confirmLabel={"Yes"} 
                    cancelLabel={"No"} 
                    confirmStyle={"success"} 
                    glyphicon={"ok"} />
            </div>
        );
    }

    render() {
        const members = this.props.selectedMembers ? this.props.selectedMembers : [];
        const table = this.renderTable(members);
        return (
            <div>
                <div style={{paddingLeft: '4px'}}>
                    {table}
                </div>
            </div>
        );
    }
}

export default PrintList;

import React from 'react';
import { Panel , Table, Row , Col } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class Encounter extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    noData = () =>
    {
            return(
    
                <Panel id='NoEncounter'>
                    <Panel.Body>
                        <div className='sectionHeader'>
                        Encounters
                        </div>    
                        <NoData />                                   
                    </Panel.Body>
                </Panel>
            ) 
    }

    encounterData =(item) => {
        return (
            <div>       
                <Panel id='Encounters'>
                    <Panel.Body>
                        <div className='sectionHeader'>
                            Encounters
                        </div>
                        <Row>
                            <span>{formatDatetimeStringWithPrefixZero(item.encounter_start_date)}</span>
                            <span className='twoSectionResults'> - {item.practice_name}</span>
                        </Row>
                        <Row>
                            <Col className='twoSectionCol'>
                                <div>
                                    <Row>
                                        <span>START DATE: </span>
                                        <span className='twoSectionResults'>{formatDatetimeStringWithPrefixZero(item.encounter_start_date)}</span>
                                    </Row>
                                    <Row>
                                        <span>ENCOUNTER ID: </span>
                                        <span className='twoSectionResults'>{item.encounter_id}</span>
                                    </Row>
                                    <Row>
                                        <span>END DATE: </span>
                                        <span className='twoSectionResults'>{formatDatetimeStringWithPrefixZero(item.encounter_end_date)}</span>
                                    </Row>
                                    <Row>
                                        <span>ENCOUNTER TYPE: </span>
                                        <span className='twoSectionResults'>{item.encounter_type}</span>
                                    </Row>
                                    <Row>
                                        <span>DISCHARGE TO LOCATION: </span>
                                        <span className='twoSectionResults'>{item.discharge_to_location}</span>
                                    </Row>
                                    <Row>
                                        <span>DISPOSITION CODE: </span>
                                        <span className='twoSectionResults'>{item.disposition_code}</span>
                                    </Row>
                                    <Row>
                                        <span>REFERRAL CODE: </span>
                                        <span className='twoSectionResults'>{item.referral_code}</span>
                                    </Row>                                                                                                                                                                               
                                </div>
                            </Col>
                            <Col className='twoSectionCol'>
                                <div>
                                    <Row>
                                        <span>PROVIDER: </span>
                                        <span className='twoSectionResults'>{item.servicing_provider_name}</span>
                                    </Row>
                                    <Row>
                                        <span>PROVIDER NPI: </span>
                                        <span className='twoSectionResults'>{item.servicing_provider_id !== '0' ? item.servicing_provider_id : ''}</span>
                                    </Row>
                                    <Row>
                                        <span>PROVIDER SPECIALITY: </span>
                                        <span className='twoSectionResults'>{item.servicing_provider_speciality}</span>
                                    </Row>
                                    <Row>
                                        <span>PLACE OF SERVICE: </span>
                                        <span className='twoSectionResults'>{item.place_of_service}</span>
                                    </Row>
                                    <Row>
                                        <span>PRACTICE NPI: </span>
                                        <span className='twoSectionResults'>{item.practice_id !== '0' ? item.practice_id : ''}</span>
                                    </Row>
                                    <Row>
                                        <span>ADMITTING PROVIDER: </span>
                                        <span className='twoSectionResults'>{item.admitting_provider_name}</span>
                                    </Row>                                                                           
                                    <Row>
                                        <span>ADMITTING PROVIDER NPI: </span>
                                        <span className='twoSectionResults'>{item.admitting_provider_id !== '0' ? item.admitting_provider_id : ''}</span>
                                    </Row>
                                    <Row>
                                        <span>ADMISSION TYPE: </span>
                                        <span className='twoSectionResults'>{item.admission_type}</span>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <span className='oneSectionCol'>CHIEF COMPLAINT: </span>
                        </Row>
                        <Row>
                            <span className='oneSectionCol'>{item.chief_complaint}</span>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        )
    }

    render() 
    {
        if(this.props.memberLoaded && !isEmpty(this.props.data))
        {
            const item = this.props.data;
            return this.encounterData(item);
        }
        else 
        {
            return this.noData();
        }
    }
}


export default Encounter;
import React from 'react';

const WorkflowHeaderDetailsInline = ({ title, provider, practice, deliveryDate, detailsButton, reprintButton, packageType }) =>
    <h4>
        <b>{title}</b> - {provider} - {practice} {deliveryDate ? '- ' + deliveryDate : ''}
        <span>{packageType === "PrePrint" ? " | Pre-Print" : packageType === "Hybrid" ? " | Hybrid" : ""}</span>
        {detailsButton}
        {reprintButton}
    </h4>

export default WorkflowHeaderDetailsInline;
import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import '../../styles/Report.css';

const RequestStatusByHostPlan = () => {
    useEffect(() => {
        const lineOfBusinessValue = localStorage.getItem('lineOfBusiness');
        if (lineOfBusinessValue === "Commercial") {
            window.location.href = '/MRR';
        }
    }, []);

    return (
        <div className="hostPlan reportContainer">
            <div className="innerReportContainer" style={{ minHeight: '250px', marginLeft: '1%', marginRight: '1%' }}>
                <header>
                    <Col className="title" >
                        <h3> Request Status by Host Plan Report </h3>
                    </Col>
                </header>
                <section className="table-section">
                    <BootstrapTable data={''} maxHeight='400px'>
                        <TableHeaderColumn dataField="HostPlanID" isKey={true} hidden>Host Plan ID </TableHeaderColumn>
                        <TableHeaderColumn dataField="HostPlan" dataSort width='100px'>Host Plan</TableHeaderColumn>
                        <TableHeaderColumn dataField="projectName" dataSort width='100px'>Request Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="TotalRequestReceived" dataSort width='120px'>Total Requests Received</TableHeaderColumn>
                        <TableHeaderColumn dataField="Open" dataSort width='120px'>Open</TableHeaderColumn>
                        <TableHeaderColumn dataField="Closed" dataSort width='120px'>Closed</TableHeaderColumn>
                        <TableHeaderColumn dataField="Withdrawn" dataSort width='120px'>Withdrawn</TableHeaderColumn>
                    </BootstrapTable>
                </section>
                <div className="export-table-data">
                    <LongRunningDownloadAnchor linkText="Download .CSV"
                        displayCSVDownloader={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default RequestStatusByHostPlan;

import React from 'react';
import { Button, Well } from 'react-bootstrap'

class SearchFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            gatheringEvidence: true,
            finalizedInCDAT: true,
            complete: true,
            enrolleeID : ''
        };
    }

    componentDidMount() {
        const cachedSearchParams = JSON.parse(sessionStorage.getItem('radvSearchParams'));
        if (cachedSearchParams) {
            this.setState({
                gatheringEvidence: cachedSearchParams.gatheringEvidence ? cachedSearchParams.gatheringEvidence : true,
                finalizedInCDAT: cachedSearchParams.finalizedInCDAT ? cachedSearchParams.finalizedInCDAT : true,
                complete: cachedSearchParams.complete ? cachedSearchParams.complete : true,
                enrolleeID: cachedSearchParams.enrolleeID ? cachedSearchParams.enrolleeID : ''
            });
            this.handleSearchClick(cachedSearchParams);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.projectID && nextProps.projectID !== this.props.projectID) {
            this.handleSearchClick();
        }
    }

    handleSearchClick = (cachedSearchParams) => {
        let searchParams;
        if (cachedSearchParams) {
            searchParams = {
                gatheringEvidence: cachedSearchParams.gatheringEvidence ? cachedSearchParams.gatheringEvidence : this.state.gatheringEvidence,
                finalizedInCDAT: cachedSearchParams.finalizedInCDAT ? cachedSearchParams.finalizedInCDAT : this.state.finalizedInCDAT,
                complete: cachedSearchParams.complete ? cachedSearchParams.complete : this.state.complete,
                enrolleeID: cachedSearchParams.enrolleeID ? cachedSearchParams.enrolleeID : this.state.enrolleeID
            };
        }
        else {
            searchParams = {
                gatheringEvidence: this.state.gatheringEvidence,
                finalizedInCDAT: this.state.finalizedInCDAT,
                complete: this.state.complete,
                enrolleeID: this.state.enrolleeID
            };
        }

        sessionStorage.setItem('radvSearchParams', JSON.stringify(searchParams));
        this.props.searchAction(searchParams);
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value });
    }
    openStatusHistory = () => {
       //  this.props.openStatusHistory(this.state.enrolleeID);
    }

    render() {
        return (
            <div>
                <Well style={{ height: "160px" }}>
                    <div className="statusOptions" style={{ float: 'left', marginLeft: "2%" }}>
                        Status<br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="gatheringEvidence" checked={this.state.gatheringEvidence} onChange={this.handleInputChange}/> Gathering Evidence
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="finalizedInCDAT" checked={this.state.finalizedInCDAT} onChange={this.handleInputChange}/> Finalized
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="complete" checked={this.state.complete} onChange={this.handleInputChange}/> Complete
                        </label> <br />
                        <label onClick={this.openStatusHistory} style={{ paddingTop: '3px', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline' }}>
                           Status History
                        </label>
                        <br />
                       
                    </div>
                    <div className="enrolleeOptions" style={{ float: 'left', marginLeft: "5%" }}>
                        Enrollee ID<br/>
                        <input type="text" name="enrolleeID" value={this.state.enrolleeID ? this.state.enrolleeID : ''} onChange={this.handleInputChange}/>
                    </div>
                    <div className="buttonActions" style={{ float: 'right'}}>
                        <Button bsStyle="primary" onClick={this.handleSearchClick}>Search</Button>
                    </div>
                </Well>
            </div>
        );
    }
}

export default SearchFilter;
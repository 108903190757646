import React from 'react';
import { Col, Row } from 'react-bootstrap';
import OOAAlertResponseDetail from './OOAAlertReponseDetail';

const OOAAlertResponseGroup = (props) => {
    const renderHeader = () => {
        return (<Row>
            <Col lg={12}>
                <h5>{props.displayText}</h5>
            </Col>
        </Row>);
    }
    return (
        <div key={props.groupCode} className="alert-workflow-alert-response-group">
            {renderHeader()}
            {
                props.responseDetails.map(responseDetail => {
                    return (
                        <OOAAlertResponseDetail key={responseDetail.alertResponseID} responseDetail={responseDetail}
                            isViewOnly={props.isViewOnly} updateAlertResponse={props.updateAlertResponse} deleteAlertResponse={props.deleteAlertResponse}
                            refreshAlertResponse={props.refreshAlertResponse} />
                    )
                })
            }
        </div>
    );
};

export default OOAAlertResponseGroup;
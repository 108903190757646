import React from 'react';
import axios from 'axios';
import { Col, Table, Button, Panel } from 'react-bootstrap';
import { Link } from 'react-router';
import AdditionalOptions from './AdditionalOptions.jsx';
import AlertToastr from '../../Shared/AlertToastr';
import SubmittedContainer from './SubmittedContainer.jsx';
import SubmittedEnrolleeRADV from './SubmittedEnrolleeRADV';

class SubmittedRankingResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataRecords: [],
            radvAuditTypeID: null,
            enrolleeInfo: null,
            selectedCMS: null,
            saveDisabled: false,
            toast: null
        };
        this.radvAuditID = this.props.location.query.radvAuditID;
        this.enrolleeID = this.props.location.query.enrolleeID;
        this.radvAuditEnrolleeStatuses = ["Gathering Evidence", "Finalized", "Complete"];
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        this.getRADVEnrolleeDiagDetails();
        this.getRADVAuditType();
        this.getRADVEnrollee();
    }

    redirectToRADVLanding() {
        this.props.router.push("RADV");
    }
   
    getRADVEnrolleeDiagDetails() {
        if (this.enrolleeID) {
            axios.get(`/RADVAuditEnrollees/${this.enrolleeID}/DiagRADVDetails/Submitted`)
            .then((response) => {
                this.setState({ 
                    dataRecords: response.data.sort((a, b) => {
                        return a.rank - b.rank;
                    })
                });
            })
            .catch((error) => {
                this.state.toast('error', 'Failed to load RADV enrollee diagnosis details.');
            })
        }
    }

    getRADVAuditType() {
        if (this.radvAuditID) {
            axios.get(`/RADVAuditProjects/${this.radvAuditID}`)
            .then((response) => {
                this.setState({ radvAuditTypeID: response.data.radvAuditTypeID });
            })
            .catch((error) => {
                this.state.toast('error', 'Failed to load RADV audit data.');
            });
        }
    }

    getRADVEnrollee() {
        if (this.enrolleeID && this.radvAuditID) {
            axios.get(`/RADVAudits/${this.radvAuditID}/RADVAuditEnrollees/${this.enrolleeID}`)
            .then((response) => {
                this.setState({ enrolleeInfo: response.data })
            })
            .catch((error) => {
                this.state.toast('error', 'Failed to load RADV enrollee data.');
            });
        }
    }

    dosFromFormatter(cell, row) {
        return new Date(row.dosFrom).toLocaleDateString('en-US');
    }

    dosThruFormatter(cell, row) {
        return new Date(row.dosThru).toLocaleDateString('en-US');
    }

    submittedDateFormatter(cell, row) {
        return new Date(row.submittedDatetime).toLocaleDateString('en-US');
    }

    saveSubmitted = (param) => {
        // Save radio button status changes
        this.setState({ saveDisabled: true });

        if (this.enrolleeID) {
            axios.post(`/RADVAuditEnrollees/${this.enrolleeID}`, {
                radvAuditEnrolleeID: this.enrolleeID,
                radvAuditEnrolleeStatusID: this.state.enrolleeInfo.radvAuditEnrolleeStatusID
            })
            .then((response) => {
                this.setState({ saveDisabled: true }, this.saveSubmittedRows(0));
            }).catch((error) => {
                this.setState({ saveDisabled: false });
                this.state.toast('error', 'Failed to save RADV enrollee data.');
            });
        }
    }

    saveSubmittedRows(index) {
        // Save dropdown changes if enrollee is in 'Finalized' status
        if (this.state.enrolleeInfo.radvAuditEnrolleeStatusID === 2) {
            axios.post(`/RADVAuditEnrollees/${this.enrolleeID}/DiagRADVDetails/Submitted`,
                this.state.dataRecords[index]
            )
            .then((response) => {
                if (index < (this.state.dataRecords.length - 1)) {
                    index += 1;
                    this.saveSubmittedRows(index);
                }
                else {
                    this.redirectToRADVLanding();
                }
            })
            .catch((error) => {
                this.setState({ saveDisabled: false });
                this.state.toast('error', 'Failed to save RADV diagnosis details.');
            });
        }
        else {
            this.redirectToRADVLanding();
        }
    }

    updateEnrolleeStatus = (statusID) => {
        this.setState({
            enrolleeInfo: {
                ...this.state.enrolleeInfo,
                radvAuditEnrolleeStatusID: statusID,
                statusDesc: statusID ? this.radvAuditEnrolleeStatuses[statusID - 1] : ""
            }
        });
    }

    handleDropdownSelection = (selectedCMS, id) => {
        let hccStatusID = null;
        if (selectedCMS === "Submitted") {
            hccStatusID = 2;
        }
        else if (selectedCMS === "Denied") {
            hccStatusID = 5;
        }
        else if (selectedCMS === "Accepted") {
            hccStatusID = 4;
        }
        else if (selectedCMS === "Interim Denied") {
            hccStatusID = 6;
        }

        //update record
        const records = [...this.state.dataRecords];
        const target = records.find(x => x.medicalRecordDiagRADVDetailID === id);
        target.radvAuditHCCStatusDesc = selectedCMS;
        target.radvAuditHCCStatusID = hccStatusID;

        this.setState({
            selectedCMS: selectedCMS,
            dataRecords: records,
        });
    }

    render() {
        return (
            <div>
                <SubmittedEnrolleeRADV enrolleeInfo={this.state.enrolleeInfo} />
                <Col md={3}>
                    <Panel>
                        <AdditionalOptions radvAuditEnrolleeStatusID={this.state.enrolleeInfo ? this.state.enrolleeInfo.radvAuditEnrolleeStatusID : null}
                            updateEnrolleeStatus={this.updateEnrolleeStatus} />
                    </Panel>
                </Col>
                <Col xs={12} md={12}>
                    <Button className="btn-sm btn-info" style={{float: "left", marginBottom: "3px"}}>
                        <Link to="/PEC/" style={{ textDecoration: 'none', color: "white" }}>Submission History</Link>
                    </Button>
                    <Button className="btn-sm btn-danger" style={{float: "right", marginBottom: "3px"}}>
                        <Link to="/RADV/"  style={{ textDecoration: 'none', color: "white" }}>Cancel and Return</Link>
                    </Button>
                    <Button className="btn-sm btn-success" style={{float: "right", marginBottom: "3px", marginRight:"6px"}}
                        onClick={this.saveSubmitted} disabled={this.state.saveDisabled}>
                        Save and Return
                    </Button> 
                    <Table striped bordered className="enrolleeTable">  
                        <thead>
                            <tr>
                                <th colSpan="14" className="text-center" style={{background: "#efefef"}}>
                                    MRR Ranking Results
                                </th>
                            </tr>
                            <tr style={{background: 'rgb(239, 239, 239)'}}>
                                <th>Rank</th>
                                <th>Coversheet ID</th>
                                <th>Score</th>
                                <th>Targeted HCC</th>
                                <th>Non-Related HCC</th>
                                <th>Provider Type</th>
                                <th>DOS</th>
                                <th>DOS Thru</th>
                                <th>Attestation</th>
                                <th>Coding Details</th>
                                <th>Evidence File</th>
                                <th>Submitted By</th>
                                <th>Submitted On</th>
                                <th>CMS Results</th>
                            </tr>
                        </thead>
                        <SubmittedContainer id={1}
                            cards={this.state.dataRecords}
                            radvAuditTypeID={this.state.radvAuditTypeID}
                            selectedCMS={this.state.selectedCMS}
                            handleDropdownSelection={this.handleDropdownSelection}
                            radvAuditEnrolleeStatusID={this.state.enrolleeInfo ? this.state.enrolleeInfo.radvAuditEnrolleeStatusID : null}
                            toast={this.state.toast} />
                    </Table>
                </Col>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        )
    }
}

export default SubmittedRankingResults;
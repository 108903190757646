import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Glyphicon } from 'react-bootstrap';

const EscalationQueueTable = (props) => {

    const formatCapturedDiags = (cell, row) => {
        return row.medicalRecordDiagExists ? 'Y' : 'N';
    }

    const formatResolutionActions = (cell, row) => {
        const matchingOption = props.resolutionActionOptions.filter(x => x.value === row.resolutionActionID);
        return matchingOption.length > 0 ? matchingOption[0].label : '';
    }

    const resolutionActionOptionsEditable = {
        type: 'select',
        options: {
            values: props.resolutionActionsEditable
        }
    }

    const onRowSelect = ({ medicalRecordReviewEscalationID }, isSelected) => {
        const records = [...props.escalationQueueItems];
        const target = records.find(x => x.medicalRecordReviewEscalationID === medicalRecordReviewEscalationID) || {};
        target.selected = isSelected; 

        props.setMRREscalations(records);
    }

    const onSelectAll = (isSelected, rows) => {
        const escalationIDs = rows.map(x => x.medicalRecordReviewEscalationID);
        const records = [...props.escalationQueueItems];

        let target;
        escalationIDs.forEach(x => {
            target = records.find(y => y.medicalRecordReviewEscalationID === x) || {};
            target.selected = isSelected;
        });

        props.setMRREscalations(records);        
    }

    const selectRowProp = {
        mode: 'checkbox',
        onSelect: onRowSelect,
        onSelectAll: onSelectAll,
        selected: props.getSelectedEscalations()
    };

    const onBeforeSaveCell = (row, cellName, cellValue) => {
        row[cellName] = cellValue;
        props.handleUpdateRow(row);
    }

    const onAfterSaveCell = (row, cellName, cellValue) => {
        if (cellValue) {
            return cellValue;
        }
    }

    const cellEdit = {
        mode: 'dbclick',
        blurToSave: true,
        beforeSaveCell: onBeforeSaveCell,
        afterSaveCell: onAfterSaveCell
    };

    const options = {
        sortName: "medicalRecordReviewEscalationID",
        sortOrder: "asc"
    };

    return (
        <BootstrapTable data={props.escalationQueueItems} options={options} selectRow={selectRowProp} cellEdit={cellEdit} 
            maxHeight="516px" scrollTop={"Top"} hover pagination bordered>
            <TableHeaderColumn dataField="medicalRecordReviewEscalationID" hidden isKey></TableHeaderColumn>
            <TableHeaderColumn dataField="medicalRecordReviewID" editable={false} dataSort>MRRID</TableHeaderColumn>
            <TableHeaderColumn dataField="campaignDescription" editable={false} dataSort>Campaign</TableHeaderColumn>
            <TableHeaderColumn dataField="projectName" editable={false} dataSort>Project</TableHeaderColumn>
            <TableHeaderColumn dataField="campaignPracticeName" editable={false} dataSort>Practice</TableHeaderColumn>
            <TableHeaderColumn dataField="providerName" editable={false} dataSort>Provider</TableHeaderColumn>
            <TableHeaderColumn dataField="medicalRecordDiagExists" dataFormat={formatCapturedDiags} editable={false} dataSort>Captured Diags</TableHeaderColumn>
            <TableHeaderColumn dataField="escalationType" editable={false} dataSort>Issue Type</TableHeaderColumn>
            <TableHeaderColumn dataField="escalationReason" editable={false} dataSort>Description</TableHeaderColumn>
            <TableHeaderColumn dataField="resolutionActionID" dataFormat={formatResolutionActions} editable={resolutionActionOptionsEditable} dataSort>Resolution Action<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/></TableHeaderColumn>
        </BootstrapTable>
    );
}

export default EscalationQueueTable;
import React from 'react';
import axios from 'axios';
import { Checkbox, Modal, Col, Row, FormControl, FormGroup, ControlLabel, Button } from 'react-bootstrap';


class CoderQAReviewCommentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            penalty: 0,
            feedbackTXT: '',
            coderQAComments: []
        }
    }
    
    componentWillMount() {
        this.getCoderQAComments();
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.coderQAComments.length > 0 && nextProps.visible) {
            this.getCoderQAScore();
        }
    }

    getCoderQAComments = () => {
        axios.get('/projectCommentDefinition',
        {
            params: {
                projectId: this.props.projectID,
                mrrId: this.props.medicalRecordReviewID,
                commentType: 'MedicalRecordReviewCoderQA'
            }
        })
        .then((response) => {
            this.setState({ coderQAComments: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load Coder QA comments.', 'Error');
        })
    }

    getCoderQAScore = () => {
        axios.get(`/coderQA/${this.props.coderQAQueueID}/medicalRecordReviews/${this.props.medicalRecordReviewID}/score`)
        .then((response) => {
            this.setState({
                coderQAComments: this.state.coderQAComments.map(comment => {
                    return {
                        ...comment,
                        isChecked: response.data.feedbackReasonsIDs.find(selectedCommentID => selectedCommentID === comment.commentDefinitionID) !== undefined
                    }
                }),
                feedbackTXT: response.data.feedbackTXT,
                penalty: response.data.penaltyValue
            });
        });
    }

    mapReviewComments = () => {
        if (this.props.comments.find(x => x.isChecked)) {
            return this.props.comments.filter(comment => comment.isChecked).map(x => <li key={x.commentDefinitionID}><label>{x.commentText}</label></li>);
        }
        else {
            return <li><label>No comments were selected.</label></li>
        }
    }

    coderQACommentCheckboxChanged = (comment) => {
        if(this.props.isCoderQAComplete) {
            return;
        }

        comment.isChecked = !comment.isChecked;
        this.setState({ coderQAComments: this.state.coderQAComments });
    }

    mapCoderQAComments = () => {
        return this.state.coderQAComments.map(x => {
                return (
                    <li key={x.commentDefinitionID}>
                        <Checkbox onChange={() => this.coderQACommentCheckboxChanged(x)} name="selectedCoderQAComments"
                            id={x.commentGroupID} checked={x.isChecked} className="commentCheckbox" readOnly={this.props.isCoderQAComplete}>
                            {x.commentText}
                        </Checkbox>
                    </li>
                )
            }
        )
    }

    saveComments = () => {
        const coderQAScore = {
            medicalRecordReviewCoderQAQueueID: this.props.coderQAQueueID,
            penaltyValue: this.state.penalty,
            feedbackTXT: this.state.feedbackTXT,
            feedbackReasonsIDs: this.state.coderQAComments.filter(comment => comment.isChecked).map(comment => comment.commentDefinitionID)
        }

        axios.post(`/coderQA/${this.props.coderQAQueueID}/medicalRecordReviews/${this.props.medicalRecordReviewID}/score`, coderQAScore)
            .then((response) => {
                this.props.handleModalToggle();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not save feedback.', 'Error');
            })
    }

    cancelComments = () => {
        this.props.handleModalToggle();
    }

    handleSetPenalty = (e) => {
        this.setState({ penalty: e.target.value });
    }
    
    handleSetFeedbackTXT = (e) => {
        this.setState({ feedbackTXT: e.target.value });
    }

    render() {
        return (
            <Modal style={{maxHeight: '800px'}} show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Would you like to apply Review Level Feedback?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={12}>
                                        <h5>Review Comments</h5>
                                        <ul style={{listStyle: 'none'}}>
                                        {
                                            this.mapReviewComments()
                                        }
                                        </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <h5>Feedback Reasons</h5>
                                        <ul style={{listStyle: 'none'}}>
                                        {
                                            this.mapCoderQAComments()
                                        }
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <ControlLabel style={{marginRight: '1rem'}}>Comments</ControlLabel>
                                            <FormControl componentClass="textarea" value={this.state.feedbackTXT} onChange={this.handleSetFeedbackTXT}
                                                         placeholder="Comments" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Row>
                            <Col sm={2}>
                                <Button onClick={this.saveComments}>Save</Button>
                            </Col>
                            <Col sm={2}>
                                <Button onClick={this.cancelComments}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CoderQAReviewCommentModal;
import React, { useState } from 'react';
import { Row, Col, ControlLabel, FormGroup, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import Loader from 'react-loader';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const supplementalOptions = [
    { value: 'delta', label: 'Delta' },
    { value: 'full-file', label: 'Full File' }
];

class SupplementalFile extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            isLoaded :false,
            fileRuns : [],
            selectValue:''
        }
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentDidMount() {
        this.setState({isLoaded:true});
        this.getAFileRuns();
    }

    handleSelectChange = (selectedOptions) => {
        if (selectedOptions) {
            this.setState({selectValue:selectedOptions.value});
        }
        else {
            this.setState({selectValue:''});
        }
    };

    handleExecute = () => {
        axios.post(`/SupplementalFile/${this.state.selectValue}`)
            .then(response => {
                this.props.toast('success', 'Began file generation', 'Success');
            })
            .catch(error => {
                this.props.toast('error', 'Failed to start file generation', 'Error');
            });
    };

    getAFileRuns = () => {
        axios.get(`/SupplementalFile/fileRuns`,{
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                fileRuns : response.data
            })
        })
        .catch(error => {
            this.props.toast('error', 'No file runs found', 'Error');
        });
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString() : '';
    }

    render() {
        return (
            <div style={{ marginTop: '2rem' }}>
                <Loader loaded={this.state.isLoaded}>
                <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Supplemental File Generation
                                </ControlLabel>
                                <Select value={this.state.selectValue}
                                    options={supplementalOptions}
                                    onChange={this.handleSelectChange}
                                />
                            </FormGroup>
                            <Button onClick={this.handleExecute}>Execute</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '1rem'}}>
                            <Col sm={12}>
                                <BootstrapTable data={this.state.fileRuns} ref="table" multiColumnSort={8} maxHeight="554px" scrollTop={"Top"}
                                    hover pagination>
                                    <TableHeaderColumn dataField="fileExportID" dataAlign="left" dataSort isKey>File Export ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="fileName" dataAlign="left" dataSort>File Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField="fileStatus" dataAlign="left">Status</TableHeaderColumn>                                    
                                    <TableHeaderColumn dataField="createdDate" dataFormat={this.formatDateString} dataAlign="left" dataSort>Created Date</TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>                     
                </Loader>
            </div>
        );
    }
}

export default SupplementalFile;
import { connect } from 'react-redux';
import { setClaimsList, setTriggerDiagRefresh } from "../../../../redux/actions/index";
import ClaimsContainer from './ClaimsContainer';

const mapStateToProps = state => ({
    diags: state.diags
});

const mapDispatchToProps = dispatch => ({
    setClaimsList: claimsList => dispatch(setClaimsList(claimsList)),
    setTriggerDiagRefresh: triggerDiagRefresh => dispatch(setTriggerDiagRefresh(triggerDiagRefresh))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClaimsContainer);
import React from 'react';
import { Checkbox, Modal , FormControl , FormGroup } from 'react-bootstrap';

class ReviewCommentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editActive: false,
            editingText: ''
        };        
    }

    handleEditChange = (e) => {
        this.setState({ editingText: e.target.value });
        this.props.handleCDIFreeText(this.state.editingText);
    }

    mapComments = () => {
        return this.props.reviewType !== "CompletedCoding" ?
            this.mapEditableComments() : this.mapReadOnlyComments();
    }

    //todo: determine why checkbox id is using commentGroupID vs. commentDefinitionID or commentDefinitionCD
    mapEditableComments = () => {
        if (this.props.comments && this.props.comments.length > 0) {
            return this.props.comments.map(x => {
                    //CommentGroupID 16 == pec correction comments
                    if (x.commentGroupID === 16) {
                        return !this.props.reviewHasAddedRecords ? (
                            <li key={x.commentDefinitionID}>
                                <Checkbox onChange={() => this.commentCheckboxChanged(x)} name="selectedComments"
                                    id={x.commentGroupID} checked={x.isChecked} className="commentCheckbox">
                                    {x.commentText}
                                </Checkbox>
                            </li>
                        ) : null;
                    }
                    else {
                        return (
                            <li key={x.commentDefinitionID}>
                                <Checkbox onChange={() => this.commentCheckboxChanged(x)} name="selectedComments"
                                    id={x.commentGroupID} checked={x.isChecked} className="commentCheckbox">
                                    {x.commentText}
                                </Checkbox>
                            </li>
                        );
                    }
                }
            )
        }
        else {
            return <li><label>No comments are available.</label></li>
        }
    }

    mapReadOnlyComments = () => {
        if (this.props.comments.find(x => x.isChecked)) {
            return this.props.comments.filter(y => y.isChecked).map(z => <li key={z.commentDefinitionID}><label>{z.commentText}</label></li>);
        }
        else {
            return <li><label>No comments were selected.</label></li>
        }
    }

    commentCheckboxChanged = (comment) => {
        comment.isChecked = !comment.isChecked;
        if(comment.commentDefinitionCD === "PECOther")
        {
            this.setState({ editActive: !this.state.editActive });
        }
        if (comment.commentGroupID === '16') {
            this.props.setPECCorrectionCommentSelected(false)
        }
        this.props.setSelectedComments([...this.props.comments.filter(x => x.commentDefinitionID !== comment.commentDefinitionID), comment]);
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-large-vertical-alignment" show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Review Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul style={{listStyle: 'none'}}>
                    {
                        this.mapComments()
                    }
                    </ul>
                    {
                        this.props.reviewProcessingUIConfig.isPECOtherVisible &&
                        !this.props.reviewHasAddedRecords &&
                        <FormGroup>
                        <FormControl
                            componentClass='textarea' placeholder='Other - PEC Correction Free Text' maxLength='250' disabled={!this.state.editActive}
                            value={this.state.editingText} onChange={this.handleEditChange} />
                        </FormGroup>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}

export default ReviewCommentModal;
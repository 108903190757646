import React from 'react';
import {Col, ControlLabel, FormControl, Table, Button} from 'react-bootstrap';
import axios from 'axios';
import '../../styles/Report.css';
import ProjectStatusReportModal from './ProjectStatusReportModal';
import ProjectStatusReportChartModal from './ProjectStatusReportChartModal';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import AlertToastr from '../Shared/AlertToastr';

class ProjectStatusReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectRecords: [],
            projectName: "",
            projectID: null,
            reportData: [],
            copied: "",
            copiedTimer: false,
            currentAuditProject: {
                projectName: "",
                projectID: null
            },
            noResults: false,
            hideChartButton: true,
            displayCSVDownloader: false,
            toast: null
        }
    }

    componentWillMount() {
        this.getProjects();
        if (localStorage.currentAuditProject) {
            const project = JSON.parse(localStorage.currentAuditProject);
            this.setState({ currentAuditProject: project });
            this.getProjectStatusReportData(project.projectID);
        }
    }

    getProjects = () => {
        axios.get('/applicationreports/1/projects')
        .then((response) => {
            this.setState({ projectRecords: response.data, noResults: false });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load projects.', 'Error');
        });
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    handleProjectChange = (evt) => {
        const projectName = [...evt.target.options].filter(o => o.selected).map(o => o.text)[0];
        const currentAuditProject = { projectID: evt.target.value, projectName: projectName };
        localStorage.currentAuditProject = JSON.stringify(currentAuditProject);
        this.setState({
            currentAuditProject: currentAuditProject,
            projectID: currentAuditProject.projectID,
            reportData: [],
            noResults: false
        });
        this.getProjectStatusReportData(currentAuditProject.projectID);
    }

    getProjectStatusReportData = (currentProjectID) => {
        this.setState({ projectID: currentProjectID });
        if (currentProjectID === '0') {
            this.setState({ displayCSVDownloader: false });
            return;
        }

        if (currentProjectID > 0) {
            axios.get('/Reports/MedicalRecordReviews/ProjectReviewStatusReport/' + currentProjectID)
            .then((response) => {
                if (response.data.totalRecords > 0) {
                    this.setState({
                        reportData: response.data.statusSummaries,
                        totalRecords: response.data.totalRecords,
                        noResults: false,
                        displayCSVDownloader: true
                    });
                }
                else {
                    this.setState({
                        reportData: response.data.statusSummaries,
                        totalRecords: response.data.totalRecords,
                        noResults: true,
                        displayCSVDownloader: false
                    });
                }
            })
            .catch((error) => {
                this.setState({ noResults: true, displayCSVDownloader: false });
                this.state.toast('error', 'Could not load project status report data.', 'Error');
            });
        }
    }

    handleProjectStatusCellClick = (displayValue, modalTitle, pageNumber, mrrStatusID) => {
        return <ProjectStatusReportModal displayValue={displayValue} 
            modalTitle={modalTitle} pageNumber={pageNumber} 
            projectID={this.state.projectID} mrrStatusID={mrrStatusID} />
    }

    buildFunctionToSendToDownloadLink = () => {
        return axios.post("/ApplicationReports/DownloadRequests", this.downloadRequestParameters());
    }

    downloadRequestParameters = () => {
        return {
            applicationReportID : "1",
            reportParameters : { projectID : this.state.projectID }
        };
    }

    copyToClipboard = (e) => {
        const element = this.table;
        const selection = window.getSelection();
        const range = window.document.createRange();
        try {
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.setState({ copied: "Copied.", copiedTimer: true });
            setTimeout(() => {
                this.setState({ copiedTimer: false });
            }, 3000);
        }
        catch (e) {
            this.setState({ copied: "Could not copy. Try again." });
        }
        selection.removeAllRanges();
    }

    render() {
        return (
            <div className="reportContainer">
                <div className="innerReportContainer">
                    <br/>
                    <header>
                        <Col style={{marginLeft: 'auto', marginRight: 'auto', width: '240px'}}>
                            <h3>Project Status Report</h3>
                        </Col>
                    </header>
                    
                    <div style={{float: 'left', width: '25%', marginBottom: '20px'}}>
                        <ControlLabel>Project:</ControlLabel>
                        <FormControl componentClass="select" placeholder="select" value={this.state.currentAuditProject.projectID !== null ? this.state.currentAuditProject.projectID : "Select"} onChange={this.handleProjectChange}>
                            <option value="0">Select</option>
                            {this.state.projectRecords.map((pr) => <option key={pr.projectID} value={pr.projectID}>{pr.projectName}</option>)}
                        </FormControl>
                        <br/>
                        <ProjectStatusReportChartModal reportData={this.state.reportData}  />
                        <br/>
                        <label hidden={!this.state.noResults}>The report returned no results.</label>
                    </div>

                    <div style={{float: 'right'}}>
                        <Button bsStyle="info" onClick={this.copyToClipboard}>Copy</Button>
                        <p style={{display: this.state.copiedTimer === true ? 'block' : 'none'}}>{this.state.copied !== "" ?  this.state.copied : ""}</p>
                    </div>

                    <div className="copyContent" ref={(table) => this.table = table}>
                        <Table bordered className="claimsAuditTable" style={{display: this.state.reportData.length > 0 ? 'table' : 'none'}}>  
                            <thead>
                                <tr>
                                    <th colSpan="1" className="text-center">
                                        Status
                                    </th>
                                    <th colSpan="1" className="text-center">
                                        Medical Record Reviews
                                    </th>
                                    <th colSpan="1" className="text-center">
                                        %
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.reportData.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{row.statusDescription}</td>
                                            <td>{this.handleProjectStatusCellClick(row.statusCount, row.statusDescription, 1, row.medicalRecordReviewStatusID)}</td>
                                            <td>{row.statusPercentage}%</td>                                            
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td colSpan="1">Total Reviews</td>
                                    <td colSpan="1">{this.state.totalRecords}</td>
                                    <td colSpan="1"></td>
                                </tr>
                            </tbody>
                        </Table>
                        <div style={{ marginBottom: "20px" }}>
                            <LongRunningDownloadAnchor linkText="Download .CSV" 
                                postRequest={this.buildFunctionToSendToDownloadLink}
                                displayCSVDownloader={this.state.displayCSVDownloader} />
                        </div>
                    </div>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default ProjectStatusReport;
import React from 'react';
import { Col, Collapse, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import WorkflowSectionHeader from './WorkflowSectionHeader';

const AssignedInfo = (props) => {
    const handleProviderTaxIDChange = (e) => {
        props.handleWorkflowFieldChange({ providerTaxID: e.target.value });
    }

    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="assigned" title="Assigned" />
            <Collapse in={props.expanded}>
                <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Organization Code</ControlLabel>
                                <FormControl type="text" value={props.workflow.providerOrganizationID} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Organization Name</ControlLabel>
                                <FormControl type="text" value={props.workflow.providerOrganizationDescription} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Practice Code</ControlLabel>
                                <FormControl type="text" value={props.workflow.assignedPracticeCode} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Practice Name</ControlLabel>
                                <FormControl type="text" value={props.workflow.practiceName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider ID</ControlLabel>
                                <FormControl type="text" value={props.workflow.providerID} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider NPI</ControlLabel>
                                <FormControl type="text" value={props.workflow.providerNPI} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Tax ID</ControlLabel>
                                <FormControl type="text" value={props.workflow.providerTaxID ? props.workflow.providerTaxID : ''}
                                    maxLength="20" onChange={handleProviderTaxIDChange} disabled={props.isViewOnly} />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Name</ControlLabel>
                                <FormControl type="text" value={props.workflow.providerFullName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>
    );
}

export default AssignedInfo;
import React from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, Row, Grid, Col } from 'react-bootstrap';
import { getRedirect } from '../../../../services/ReviewProcessing';
import AlertToastr from '../../../Shared/AlertToastr';

class ExceptionComments extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            alertWorkflowCorrections: [],
            alertsAndQueries: [],
            toast: null
        }
    }

    componentWillMount() {
        this.getAlertWorkflowComments();
    }

    formatTimestamp = (timeString) => {
        if (!timeString) {
            return '';
        }
        const date = new Date(timeString);
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString('en-US')}`;

        return formattedDate;
    }

    getAlertWorkflowComments = () => {
        axios.get(`/MedicalRecordReview/${this.props.params.mrrID}/CDIAlertWorkflows`)
        .then(alertWorkflows => {
            if (alertWorkflows.data) {
                Promise.all(alertWorkflows.data.map(workflow => axios.get(`/CDIAlerts/${workflow.cdiAlertWorkflowID}/Corrections`)))
                .then((results) => {
                    // Combine exeption option text and comment text into one column
                    this.setState({ 
                        alertWorkflowCorrections: results[0].data.map((item) => {
                            return { 
                                commentText: item.cdiAlertWorkflowExceptionOption ? item.cdiAlertWorkflowExceptionOption.exceptionOptionText : item.commentText,
                                cdiAlertWorkflowExceptionPECComment: item.cdiAlertWorkflowExceptionPECComment,
                                reviewer: item.reviewer,
                                commentDate: this.formatTimestamp(item.commentDate),
                                addressed: item.addressed ? "Yes" : "No",
                                addressedDatetime: this.formatTimestamp(item.addressedDatetime)
                            }
                        })
                    });
                });
            }
        })
        .catch(error => {
            this.state.toast('error', 'Could not retrieve CDI Alert Workflows.', 'Error');
        });
    }

    returnToReview = () => {
        getRedirect({
            redirectAction: "Coding",
            redirectController: "Coding",
            params: {
                medicalRecordReviewId: this.props.params.mrrID
            }
        });
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    render() {
        return (
            <div>
                <Grid fluid>
                    <Row style={{'marginBottom': '1.4rem', 'paddingLeft': '13px'}}>
                        <h4>PEC Exception Comments</h4>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <BootstrapTable data={this.state.alertWorkflowCorrections}>
                                <TableHeaderColumn dataField="commentText" isKey dataSort>Exception Reason</TableHeaderColumn>
                                <TableHeaderColumn dataField="reviewer" dataSort>Created By</TableHeaderColumn>
                                <TableHeaderColumn dataField="commentDate" dataSort>Created Date</TableHeaderColumn>
                                <TableHeaderColumn dataField="addressed" dataSort>Resolved?</TableHeaderColumn>
                                <TableHeaderColumn dataField="addressedDatetime" dataSort>Resolved Date</TableHeaderColumn>
                                <TableHeaderColumn dataField="cdiAlertWorkflowExceptionPECComment" dataSort>PEC Comments</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                    <Row style={{'marginTop': '1.4rem', 'paddingLeft': '13px'}}>
                        <Button bsStyle="primary" onClick={this.returnToReview}>Return to Review</Button>
                    </Row>
                </Grid>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default ExceptionComments;

import React from 'react';
import axios from 'axios';
import { Panel, Glyphicon, Modal, Button, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Select from 'react-select';
import AlertDialog from '../../../Shared/AlertDialog';
import { MedicalRecordType } from '../../../../enums/MedicalRecordType';

//todo: replace non-standard Date handling
class CDIAlertContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateOfServiceString: null,
            cdiAlertWorkflowID: null,
            providerID: null,
            queryData: [],
            queryCodes: [],
            queryTexts: [],
            cdiAlertEntityDetailDiags: [],
            containsQueryResponse: false,
            queryCodeID: "",
            queryTextID: "",
            queryText: "",
            cdiAlertWorkflowQuery: null,
            showQueryModal: false,
            deleteEnabled: false,
            outOfDate: false,
            loaded: false,
            showConfirmModal: false,
            modalMessage: "Delete this query?",
            handleConfirm: () => this.handleDeleteQuery(),
            hiddenAlertEntityID: null,
            hiddenAlertWorkflowQueryID: null
        }
    }

    componentWillMount() {
        Promise.all([
            axios.get(`/MedicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/CDIAlertWorkflows`)
            .then((response) => {
                if (response.data.length > 0) {
                    this.setState({
                        dateOfServiceString: new Date(response.data[0].appointmentDate).toLocaleDateString('en-US', { timeZone: 'UTC' }),
                        cdiAlertWorkflowID: response.data[0].cdiAlertWorkflowID,
                        providerID: response.data[0].providerMasterID
                    }, () => {
                        this.checkAppointmentDate();
                    });
                    return axios.get(`/CDIAlertWorkflows/${response.data[0].cdiAlertWorkflowID}/CDIAlertWorkflowAlertsAndQueries`, {
                        params: {
                            alertGroupIDs: [1, 2]
                        }
                    });
                }
            }),
            axios.get('/cdialertworkflowquery/cdialertquerycodetypes'),
            axios.get('/cdialertworkflowquery/cdialertquerytext'),
            axios.get(`/MedicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/CDIAlertEntityDetailDiag`),
            axios.get(`/merlinUser/${this.props.reviewProcessingUIConfig.userID}`)
        ])
        .then((response) => {
            if (response[0]) {
                this.setState({
                    queryData: response[0].data,
                    queryCodes: response[1].data.filter(x => x.cdiAlertQueryCodeID !== 175).sort((a, b) =>
                        { return a.cdiAlertQueryCodeDesc.localeCompare(b.cdiAlertQueryCodeDesc) }),
                    queryTexts: response[2].data,
                    cdiAlertEntityDetailDiags: response[3].data,
                    containsAlertResponse: response[0].data.filter(x => x.cdiAlertWorkflowResponse).length > 0,
                    containsQueryResponse: response[0].data.filter(x => x.cdiAlertQueryResponseDesc).length > 0,
                    loaded: true,
                    userFullName: response[4].data.fullName
                });
            }
            else {
                this.setState({ loaded: true });
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load query data.', 'Error');
            this.setState({ loaded: true });
        })
    }

    componentWillReceiveProps(nextProps) {
        // set "hidden" query data via value passed from DiagnosisCapture/DiagnosisCaptureRedux.
        // id-wise, some of our data rows use cdiAlertEntityDetailID, some use cdiAlertWorkflowQueryID, and some may have both set.
        if (nextProps.diags.length > 0) {
            let diagFormData = nextProps.diags.find(x => x.type === "SET_DIAG_FORM");
            if (diagFormData) {
                diagFormData = diagFormData.diagFormData;
                if (diagFormData) {
                    //set hidden ID fields if the "add diagnosis" selection has changed
                    if ((diagFormData.cdiAlertEntityDetailID && this.state.hiddenAlertEntityID !== diagFormData.cdiAlertEntityDetailID) ||
                        (diagFormData.cdiAlertWorkflowQueryID && this.state.hiddenAlertWorkflowQueryID !== diagFormData.cdiAlertWorkflowQueryID)) {
                        this.setState({
                            hiddenAlertEntityID: diagFormData.cdiAlertEntityDetailID,
                            hiddenAlertWorkflowQueryID: diagFormData.cdiAlertWorkflowQueryID
                        });
                    }
                }
                else {
                    //clear hidden ID fields if the diagnosis add has been canceled
                    if (this.state.hiddenAlertEntityID || this.state.hiddenAlertWorkflowQueryID) {
                        this.setState({
                            hiddenAlertEntityID: null,
                            hiddenAlertWorkflowQueryID: null
                        });
                    }
                }
            }

            //refresh cdiAlertEntityDetailDiags if the diag IDs have changed
            if (this.state.loaded) {
                const diagIDList = nextProps.diags.find(x => x.type === "SET_DIAG_ID_LIST");
                if (diagIDList && diagIDList.diagIDList) {
                   this.refreshAlertEntityDetailDiags(diagIDList.diagIDList);
                }
            }
        }
    }

    refreshAlertEntityDetailDiags = (diagIDList) => {
        const a = new Set(diagIDList);
        const b = new Set(this.state.cdiAlertEntityDetailDiags.map(x => x.cdiAlertEntityDetailID));
        const listsAreEqual = a.size === b.size && [...a].every(value => b.has(value));

        if (!listsAreEqual) {
            axios.get(`/MedicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/CDIAlertEntityDetailDiag`)
            .then((response) => {
                this.setState({ cdiAlertEntityDetailDiags: response.data });
            })
            .catch((error) => {
                this.props.toast('error', 'Could not refresh query data.', 'Error');
                this.setState({ loaded: true });
            })
        }
    }

    getCDIAlertEntityDetailDiags = () => {
        axios.get(`/MedicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/CDIAlertEntityDetailDiag`)
        .then((response) => {
            this.setState({ cdiAlertEntityDetailDiags: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not refresh query data.', 'Error');
            this.setState({ loaded: true });
        });
    }

    refreshAlertWorkflowsAndQueries = () => {
        axios.get(`/CDIAlertWorkflows/${this.state.cdiAlertWorkflowID}/CDIAlertWorkflowAlertsAndQueries`, {
            params: {
                alertGroupIDs: [1, 2]
            }
        })
        .then((response) => {
            this.setState({
                queryData: response.data,
                containsAlertResponse: response.data.filter(x => x.cdiAlertWorkflowResponse).length > 0,
                containsQueryResponse: response.data.filter(x => x.cdiAlertQueryResponseDesc).length > 0
            });
        });
    }

    //todo: refactor this
    checkAppointmentDate = () => {
        // Check if appointment date is too far in the past
        const today = new Date(new Date().toLocaleDateString('en-US', { timeZone: 'UTC' }));
        const dosDate = new Date(this.state.dateOfServiceString);

        //todo: confirm that this is a valid calculation
        const dayDifference = Math.ceil(Math.abs(today.getTime() - dosDate.getTime()) / (1000 * 3600 * 24));
        if (dayDifference > this.props.reviewProcessingUIConfig.maxDayDifferenceBetweenAppointmentDateAndCurrentDate) {
            this.setState({ outOfDate: true });
        }
    }

    openQueryModal = (row, modalType) => () => {
        if (modalType === "add" || modalType === "edit") {
            if (this.props.pecCorrectionCommentSelected) {
                this.props.toast('error', `Cannot add a query when a 'PEC Correction' comment is selected.`, 'Error');
                return;
            }

            if (modalType === "add") {
                this.setState({
                    showQueryModal: true,
                    cdiAlertWorkflowQuery: row,
                    deleteEnabled: false
                });
            }
            else {
                this.setState({
                    showQueryModal: true,
                    cdiAlertWorkflowQuery: row,
                    deleteEnabled: true,
                    queryCodeID: row.cdiAlertQueryCodeID,
                    queryTextID: this.findQueryTextID(row.cdiAlertQueryText),
                    queryText: row.cdiAlertQueryText
                });
            }
        }
        else if (modalType === "add-on") {
            this.setState({
                showQueryModal: true,
                deleteEnabled: false
            });
        }
    }

    //note: this is a new feature - sets the query code selection if the saved query text matches a default
    findQueryTextID = (queryText) => {
        const match = this.state.queryTexts.find(x => x.queryTextDesc === queryText);
        return match ? match.cdiAlertQueryTextID : null;
    }

    closeQueryModal = () => {
        this.setState({
            showQueryModal: false,
            showConfirmModal: false,
            cdiAlertWorkflowQuery: null,
            queryCodeID: "",
            queryTextID: "",
            queryText: ""
        });
    }

    handleQueryCodeChange = (e) => {
        this.setState({
            queryCodeID: e ? e.value : e
        });
    }

    handleQueryTextChange = (e) => {
        this.setState({
            queryTextID: e ? e.value : e,
            queryText: this.state.queryTexts.filter(x => x.cdiAlertQueryTextID === e.value)[0].queryTextDesc + ' - '
                + this.state.userFullName + ', ' + new Date().toDateString()
        })
    }

    handleQueryTextBoxChange = (e) => {
        if (e && (400 - e.target.value.length >= 0)) {
            this.setState({
                queryText: e ? e.target.value : e
            })
        }
    }

    handleSaveQuery = () => {
        if (this.validateQuery()) {
            const action = !this.state.deleteEnabled ? this.addQuery : this.updateQuery;
            action()
            .then((response) => {
                this.refreshAlertWorkflowsAndQueries();
                this.closeQueryModal();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not save query.', 'Error');
            });
        }
    }

    //todo: this validation should take place all at once & utilize bootstrap's validation styling
    validateQuery = () => {
        if ((!this.state.queryText || !this.state.queryCodeID) && !this.state.outOfDate) {
            this.props.toast('error', 'Query Code and Query Text are required fields.', 'Error');
            return false;
        }
        else if (!this.state.queryCodeID && this.state.outOfDate) {
            this.props.toast('error', 'Query Code is a required field.', 'Error');
            return false;

        }
        else if (this.state.queryText && this.state.queryText.length < 4 && !this.state.outOfDate) {
            this.props.toast('error', 'Query Text must be at least 4 characters.', 'Error');
            return false;
        }

        return true;
    }

    addQuery = () => {
        return axios.post(`/cdialertWorkflows/${this.state.cdiAlertWorkflowID}/cdialertworkflowqueries`, {
            cdiAlertWorkflowQueryID: null,
            cdiAlertWorkflowID: this.state.cdiAlertWorkflowID,
            cdiAlertQueryResponseID: this.state.cdiAlertWorkflowQuery ? this.state.cdiAlertWorkflowQuery.cdiAlertQueryResponseID : null,
            cdiAlertEntityDetailID: this.state.cdiAlertWorkflowQuery ? this.state.cdiAlertWorkflowQuery.cdiAlertEntityDetailID : null,
            cdiAlertQueryCodeID: this.state.queryCodeID,
            cdiAlertQueryText: this.state.queryText
        });
    }

    updateQuery = () => {
        return axios.put(`/cdialertWorkflows/${this.state.cdiAlertWorkflowID}/cdialertworkflowqueries/${this.state.cdiAlertWorkflowQuery.cdiAlertWorkflowQueryID}`, {
            cdiAlertWorkflowQueryID: this.state.cdiAlertWorkflowQuery.cdiAlertWorkflowQueryID,
            cdiAlertWorkflowID: this.state.cdiAlertWorkflowID,
            cdiAlertQueryResponseID: this.state.cdiAlertWorkflowQuery.cdiAlertQueryResponseID,
            cdiAlertEntityDetailID: this.state.cdiAlertWorkflowQuery.cdiAlertEntityDetailID,
            cdiAlertQueryCodeID: this.state.queryCodeID,
            cdiAlertQueryText: this.state.queryText
        });
    }

    handleDeleteQuery = () => {
        axios.delete(`/cdialertWorkflows/${this.state.cdiAlertWorkflowID}/cdialertworkflowqueries/${this.state.cdiAlertWorkflowQuery.cdiAlertWorkflowQueryID}`)
        .then((response) => {
            this.refreshAlertWorkflowsAndQueries();
            this.closeQueryModal();
        })
        .catch((error) => {
            this.closeQueryModal();
            this.props.toast('error', 'Could not delete query.', 'Error');
        });
    }

    // set diag form modal fields; hide actions for selected alert line
    addDiagnosis = (row) => () => {
        axios.get(`/provider/${this.state.providerID}`)
        .then((response) => {
            const mappedDiagEditData = this.mapAlertToDiagEdit({
                alert: row,
                provider: response.data,
                dos: this.state.dateOfServiceString
            });

            if (mappedDiagEditData !== null) {
                this.props.setDiagFormData({
                    ...mappedDiagEditData,
                    'cdiAlertEntityDetailID': row.cdiAlertEntityDetailID,
                    'cdiAlertWorkflowQueryID': row.cdiAlertWorkflowQueryID
                });
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not add diagnosis.', 'Error');
        });
    }

    //todo: add additional enums(?)
    mapAlertToDiagEdit = (alertObject) => {
        if (alertObject.alert && alertObject.provider) {
            const diagnosis = {};

            diagnosis.providerMasterID = alertObject.provider.internalKey;
            diagnosis.providerID = alertObject.provider.providerID;
            diagnosis.providerNPI = alertObject.provider.providerNPI;
            diagnosis.providerLastName = alertObject.provider.lastName;
            diagnosis.providerFirstName = alertObject.provider.firstName;

            // Default providerTypeID to "Outpatient"
            diagnosis.providerTypeID = 102;
            diagnosis.dateOfServiceFrom = new Date(alertObject.dos);
            diagnosis.dateOfServiceTo = new Date(alertObject.dos);

            if (alertObject.alert.starOrDiagnosisCode) {
                diagnosis.diagnosisCD = alertObject.alert.starOrDiagnosisCode;
            }
            else {
                diagnosis.diagnosisCD = "";
            }

            // Default sourceID to "Progress Note"
            diagnosis.sourceID = 201;
            diagnosis.diagnosisComments = [];
            diagnosis.pages = "";

            // Default medicalRecordTypeID to "Scan"
            diagnosis.medicalRecordTypeID = MedicalRecordType.Scan;

            // Default rafTypeID to "A"
            diagnosis.rafTypeID = 901;

            return diagnosis;
        }
        else {
            return null;
        }
    }

    toggleConfirmModal = () => {
        this.setState({ showConfirmModal: !this.state.showConfirmModal, showQueryModal: this.state.showConfirmModal });
    }

    formatAlertGroupDesc = (cell) => {
        return cell ? cell : 'Add-On Query';
    }

    formatQueryCodeText = (cell, row) => {
        const queryCode =  this.state.queryCodes.find(x => {
            return x.cdiAlertQueryCodeID === row.cdiAlertQueryCodeID
        });
        return queryCode ? queryCode.cdiAlertQueryCodeDesc : '';
    }

    //todo: simplify the mess of logic below
    formatAction = (cdiAlertWorkflowQueryID, row) => {
        const buttons = [];

        if (!this.props.reviewProcessingUIConfig.isQA) {
            if ((!this.state.hiddenAlertEntityID || (this.state.hiddenAlertEntityID && row.cdiAlertEntityDetailID !== this.state.hiddenAlertEntityID)) &&
                (!this.state.hiddenAlertWorkflowQueryID || (this.state.hiddenAlertWorkflowQueryID && row.cdiAlertWorkflowQueryID !== this.state.hiddenAlertWorkflowQueryID))) {

                const alertWithDiag = this.state.cdiAlertEntityDetailDiags ?
                    this.state.cdiAlertEntityDetailDiags.find(x => x.cdiAlertEntityDetailID === row.cdiAlertEntityDetailID)
                    : null;

                if (!alertWithDiag) {
                    if (row.cdiAlertQueryResponseDesc !== null || row.cdiAlertQueryCodeID < 1) {
                        buttons.push(
                            <Button key="cdi-add-diag" onClick={this.addDiagnosis(row)}>
                                <Glyphicon glyph="plus"/>
                            </Button>
                        );
                    }

                    if (row.cdiAlertWorkflowQueryID === null && !this.state.containsQueryResponse) { //previously "if (!row.cdiAlertQueryText)"
                        buttons.push(
                            <Button key="cdi-add-query" onClick={this.openQueryModal(row, 'add')}>
                                <Glyphicon glyph="question-sign" />
                            </Button>
                        );
                    }
                }

                if (row.cdiAlertWorkflowQueryID !== null && row.cdiAlertQueryResponseDesc === null && !this.state.containsQueryResponse) {
                    buttons.push(
                        <Button key="cdi-edit-query" onClick={this.openQueryModal(row, 'edit')}>
                            <Glyphicon glyph="pencil" />
                        </Button>
                    );
                }
            }
        }

        return (
            <div>
                { buttons.map(x => { return x; }) }
            </div>
        );
    }

    renderOutOfDateMessage = () => {
        return `Warning: The appointment date for this alert is ${this.state.dateOfServiceString}, which is beyond 
            ${this.props.reviewProcessingUIConfig.maxDayDifferenceBetweenAppointmentDateAndCurrentDate} days old.
            Because of its age, the query will not be sent to the PEC to be delivered to the provider.`;
    }

    renderDeleteButton = () => {
        return this.state.deleteEnabled ? (<Button onClick={this.toggleConfirmModal}>Delete</Button>)
        : (<Button disabled>Delete</Button>);
    }

    render() {
        return (
            <Panel className="capturePanel cdi-alert-panel">
                <div style={{ padding: '1rem' }}>
                    <h5 style={{ display: 'inline-block', fontWeight: 'bold' }}>DOS: {this.state.dateOfServiceString}</h5>
                    {
                        !this.props.reviewProcessingUIConfig.isQA && !this.state.containsAlertResponse && !this.state.containsQueryResponse &&
                        <Button key="cdi-edit-query" onClick={this.openQueryModal(null, 'add-on')} style={{ float: 'right' }}>
                            <Glyphicon glyph="question-sign" style={{ fontWeight: '800', marginRight: '0.6rem' }} />Add-On Query
                        </Button>
                    }
                </div>
                <Panel.Body>
                    <Loader loaded={this.state.loaded}>
                        <BootstrapTable data={this.state.queryData} className="cdiAlertTable" condensed hover>
                            <TableHeaderColumn dataField="cdiAlertEntityDetailID" hidden isKey />
                            <TableHeaderColumn dataField="alertGroupDesc" dataSort dataFormat={this.formatAlertGroupDesc}>Alert Category</TableHeaderColumn>
                            <TableHeaderColumn dataField="alertLineText" dataSort>Alert Line</TableHeaderColumn>
                            <TableHeaderColumn dataField="alertResponse" dataSort>Alert Response</TableHeaderColumn>
                            <TableHeaderColumn dataField="cdiAlertQueryCodeText" dataFormat={this.formatQueryCodeText} dataSort>Query Code</TableHeaderColumn>
                            <TableHeaderColumn dataField="cdiAlertQueryText" dataSort>Query Text</TableHeaderColumn>
                            <TableHeaderColumn dataField="cdiAlertQueryResponseDesc" dataSort>Query Response</TableHeaderColumn>
                            <TableHeaderColumn dataField="cdiAlertWorkflowQueryID" dataFormat={this.formatAction}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </Loader>
                </Panel.Body>
                <Modal show={this.state.showQueryModal} onHide={this.closeQueryModal}>
                    <Modal.Header>Query Creation</Modal.Header>
                    <Modal.Body>
                        <Form>
                            <FormGroup>
                                <ControlLabel>Query Code</ControlLabel>
                                <Select value={this.state.queryCodeID}
                                    options={this.state.queryCodes
                                        ? this.state.queryCodes
                                            .map(item => {
                                                return {
                                                    value: item.cdiAlertQueryCodeID,
                                                    label: item.cdiAlertQueryCodeDesc
                                                }
                                            }) 
                                        : []}
                                    onChange={this.handleQueryCodeChange} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Query Text</ControlLabel>
                                <Select value={this.state.queryTextID}
                                    options={this.state.queryTexts
                                        ? this.state.queryTexts
                                            .map(item => {
                                                return { 
                                                    value: item.cdiAlertQueryTextID,
                                                    label: item.queryTextHeading
                                                }
                                            })
                                        : []}
                                    onChange={this.handleQueryTextChange} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{400 - this.state.queryText.length} Characters remaining</ControlLabel>
                                <FormControl componentClass="textarea" value={this.state.queryText} onChange={this.handleQueryTextBoxChange} rows="10" />
                            </FormGroup>
                            <FormGroup>
                                <FormControl.Static style={{ color: '#a94442' }}>
                                {this.state.outOfDate && this.renderOutOfDateMessage()}
                                </FormControl.Static>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleSaveQuery}>Save</Button>
                        <Button onClick={this.closeQueryModal}>Cancel</Button>
                        {this.renderDeleteButton()}
                    </Modal.Footer>
                </Modal>
                <AlertDialog visible={this.state.showConfirmModal} handleModalToggle={this.toggleConfirmModal} title={this.state.modalMessage}
                    renderBody={false} handleConfirm={this.state.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
            </Panel>
        )
    }
}
export default CDIAlertContainer;
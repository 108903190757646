import React from 'react';
import axios from 'axios';
import { Button, Glyphicon, ListGroup, ListGroupItem, Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortPageNumbers, sortComments, sortDate } from '../../../../../utils/SortUtils';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus';
import { StarsUtils } from '../../../../../utils/DiagnosisUtils';
import RejectModal from '../RejectModal';
import StarsDetailModal from './StarsDetailModal';
import StarsEntryModal from './StarsEntryModal';
import StarsReasonCodeModal from './StarsReasonCodeModal';
import AlertDialog from '../../../../Shared/AlertDialog';

class StarsCapture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiagDetailModal: false,
            showStarsEntryModal: false,
            showStarsReasonCodeModal: false,
            showConfirmModal: false,
            showRejectModal: false,
            starsObj: null,
            modalRow: null,
            editModalRowID: null,
            editModalRowMeasureID: null,
            editMode: false,
            customRowActions: false,
            rejectedReasons: null,
            starsReasonCodeTypes: []
        }

        this.approvedAndRejectedStatuses = [LineItemReviewStatus.Confirmed, LineItemReviewStatus.MDConfirmed, 
            LineItemReviewStatus.Rejected, LineItemReviewStatus.MDRejected];
        this.confirmModalMessage = 'Are you sure you want to delete this record?';
        this.isEditable = false;
        this.isRemovable = false;
        this.isApprovable = false;
        this.isRejectable = false;
        this.isReopenable = false;

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillReceiveProps(nextProps) {
        // if star measure selected from star profile, toggle form modal and pass in selectedMeasure
        if (nextProps.selectedStarMeasure) {
            this.toggleFormModal(nextProps.selectedStarMeasure);
        }
    }

    componentWillMount() {
        // if complete, set all actions to false
        if (this.props.isComplete) {
            this.isEditable = false;
            this.isRemovable = false;
            this.isApprovable = false;
            this.isRejectable = false;
            this.isReopenable = false;
            this.setState({
                customRowActions: false
            })
        }
        // if initial coding, set edit and delete functionality to true
        else if (!this.props.isQA && !this.props.isMD) {
            this.isEditable = true;
            this.isRemovable = true;
            this.isApprovable = false;
            this.isRejectable = false;
            this.isReopenable = false;
            this.setState({
                customRowActions: false
            })
        }
        // if QA or MD, accept edit, delete, and QA actions - specific to row
        else {
            this.setState({
                customRowActions: true
            })
        }
    }

    formatPages = (cell, row) => {
        return row.pageNumFrom + "-" + row.pageNumTo;
    }

    formatActions = (cell, row) => {
        if (this.state.customRowActions) {
            this.isEditable = (!this.approvedAndRejectedStatuses.includes(row.lineItemReviewStatusID))
            this.isRemovable = (row.enteredByUserID === this.props.userID);
            this.isApprovable = (row.enteredByUserID !== this.props.userID && !this.approvedAndRejectedStatuses.includes(row.lineItemReviewStatusID));
            this.isRejectable = this.isApprovable; // Same logic
            this.isReopenable = (this.approvedAndRejectedStatuses.includes(row.lineItemReviewStatusID));
        }
        return (
            <div className="diag-qa-button-wrapper">
                {
                    this.isEditable &&
                    <Button onClick={() => this.toggleFormModal(row)}>
                        <Glyphicon bsSize="large" glyph="pencil"/>
                    </Button>
                }
                {
                    this.isRemovable &&
                    <Button className="deleteButton" onClick={() => this.toggleConfirmModal(row)}>
                        <Glyphicon bsSize="large" glyph="trash"/>
                    </Button>
                }
                {
                    this.isApprovable &&
                    <Button className="approveButton" onClick={() => this.approveRecord(row)}>
                        <Glyphicon bsSize="large" glyph="ok"/>
                    </Button>
                }
                {
                    this.isRejectable &&
                    <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>
                        <Glyphicon bsSize="large" glyph="remove"/>
                    </Button>
                }
                {
                    StarsUtils.isApprovedOrRejected(row) &&
                    <div className="diag-qa-button-wrapper">
                        {
                            StarsUtils.isApproved(row) &&
                            <span className="approveButton">Accepted</span>
                        }
                        {
                            StarsUtils.isRejected(row) &&
                            <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>Rejected</Button>
                        }
                        {
                            this.isReopenable &&
                            <Button onClick={() => this.reopenRecord(row)}>
                                <Glyphicon bsSize="large" bsClass="glyphicon mirror-icon glyphicon-repeat" />
                            </Button>
                        }
                    </div>
                }
            </div>
        )
    }

    // generate dynamic values array to display in detail modal
    mapRowValues = (row) => {
        if (row.medicalRecordImageID === -1) {
            row.medicalRecordImageID = "Not Available in MRR";
        }
        row.dynamicValues = row.dynamicCapturedData.split(',').map(x => x.trim().split(':'));
    }

    toggleDiagDetailModal = (row) => {
        if (row && !row.target) {
            this.mapRowValues(row);
        }
        this.setState({ 
            showDiagDetailModal: !this.state.showDiagDetailModal,
            modalRow: row
        });
    }

    toggleConfirmModal = (row) => {
        this.setState({ 
            showConfirmModal: !this.state.showConfirmModal,
            starsObj: row && !row.target ? row : null,
        });
    }

    handleConfirm = () => {
        this.toggleConfirmModal();
        this.deleteRecord();
    }

    toggleFormModal = (row) => {
        // if row exists and medicalRecordQualityCaptureID is not null, you're editing a record - set editRowID to the current row ID and editMode to true
        this.setState({ 
            showStarsEntryModal: !this.state.showStarsEntryModal, 
            editModalRowID: row && row.medicalRecordQualityCaptureID ? row.medicalRecordQualityCaptureID : '',
            editModalRowMeasureID: row && row.medicalRecordQualityCaptureID ? row.measureID : '',
            editMode: row && row.medicalRecordQualityCaptureID,
            enteredByUserID: row && row.medicalRecordQualityCaptureID ? row.enteredByUserID : ''
        });
        // if modal is being closed, clear selected star measure (if exists)
        if (!row) {
            this.props.clearSelectedStarMeasure();
        }
    }

    deleteRecord = () => {
        this.state.starsObj &&
        axios.delete(`/medicalRecordQualityCapture`, {
            params: { 
                medicalRecordQualityCaptureID: this.state.starsObj.medicalRecordQualityCaptureID 
            }
        })
        .then((response) => {
            this.props.getStarsData();
            this.props.getStarsHistoryData();
        })
        .catch((error) => {
            this.props.toast('error', 'Could not delete stars record.', 'Error');
        })
    }

    approveRecord = (row) => {
        this.setStatus(row, this.props.isMD ? LineItemReviewStatus.MDConfirmed : LineItemReviewStatus.Confirmed);
    }

    reopenRecord = (row) => {
        this.setStatus(row, LineItemReviewStatus.Captured);
    }

    // set approved/reopen status
    setStatus = (row, reviewStatusID) => {
        axios.get(`/medicalRecordQualityCapture/${row.medicalRecordQualityCaptureID}`)
        .then((response) => {
            this.setState({
                starsObj: {
                    ...response.data,
                    userID: this.props.userID,
                    lineItemReviewStatusID: reviewStatusID
                }
            }, () => {
                axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/MedicalRecordQualityCapture/${row.medicalRecordQualityCaptureID}`, this.state.starsObj)
                .then((response) => {
                    this.props.getStarsData();
                    this.props.getStarsHistoryData();
                })
                .catch((error) => {
                    this.props.toast('error', 'Could not update star record status.', 'Error');
                })
            });
        })
    }
    
    toggleRejectModal = (row) => {
        this.setState({ 
            rejectRow: row,
            showRejectModal: !this.state.showRejectModal,
            rejectedReasons: this.props.rejectionReasons.map(x => {
                x.isChecked = row && row.rejectionComments.includes(x.commentDefinitionID);
                return x;
            })
        });
    }

    // update rejectedReasons on checkbox select
    setSelectedRejectionReasons = (updatedRejectionReasons) => {
        this.setState({ rejectedReasons: updatedRejectionReasons.sort(sortComments)});
    }

    rejectEntry = (row) => {
        axios.get(`/medicalRecordQualityCapture/${row.medicalRecordQualityCaptureID}`)
        .then((response) => {
            this.setState({
                starsObj: {
                    ...response.data,
                    userID: this.props.userID,
                    lineItemReviewStatusID: this.props.isMD ? LineItemReviewStatus.MDRejected : LineItemReviewStatus.Rejected,
                    rejectionComments: this.state.rejectedReasons.filter(x => {
                        return x.isChecked
                    }).map(y => { return y.commentDefinitionID.toString() })
                }
            }, () => {
                axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/MedicalRecordQualityCapture/${row.medicalRecordQualityCaptureID}`, this.state.starsObj)
                .then((response) => {
                    this.props.getStarsData();
                    this.props.getStarsHistoryData();
                    this.toggleRejectModal();
                })
                .catch((error) => {
                    this.props.toast('error', 'Could not reject star record.', 'Error');
                })
            });
        })
    }

    toggleStarsReasonCodeModal = () => {
        this.setState((prevState) => {
            return { showStarsReasonCodeModal: !prevState.showStarsReasonCodeModal };
        });
    }

    renderTable = (results) => {
        const options = {
            defaultSortName: 'medicalRecordQualityCaptureID',
            defaultSortOrder: 'asc',
            onRowDoubleClick: this.toggleDiagDetailModal
        };
        return (
            <div>
                <BootstrapTable data={results} options={options} scroll-top="Top" hover striped>
                    <TableHeaderColumn dataField="medicalRecordQualityCaptureID" dataSort isKey>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerNPI" dataSort>Provider NPI</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceFrom" dataSort sortFunc={(a, b, order) => sortDate(a.dateOfServiceFrom, b.dateOfServiceFrom, order)}>DOS</TableHeaderColumn>
                    <TableHeaderColumn dataField="measure" dataSort width="6%">Measure</TableHeaderColumn>
                    <TableHeaderColumn dataField="serviceDescription" dataSort width="20%" tdStyle={{ whiteSpace: 'normal' }}>Service Description</TableHeaderColumn>
                    <TableHeaderColumn dataField="dynamicCapturedData" dataSort width="15%" tdStyle={{ whiteSpace: 'normal' }}>Service Result</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordImageID" dataSort tdStyle={{ whiteSpace: 'normal' }}>Image ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="pageNumFrom" dataFormat={this.formatPages} sortFunc={sortPageNumbers} dataSort>Pages</TableHeaderColumn>
                    <TableHeaderColumn width="15%" dataFormat={this.formatActions}>Update Entry</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Panel className={!this.props.isComplete ? 'capturePanel capture-panel-no-top-padding' : 'capturePanel'} header="Stars Capture">
                    {
                        !this.props.isComplete &&
                        <ListGroup style={{ margin: '0' }}>
                            <ListGroupItem style={{ borderBottom: '0', borderTop: '0', borderRadius: '0' }}>
                                <Button bsStyle="primary" onClick={this.toggleFormModal}
                                    style={{ display: 'inline-block' }}>
                                    Add Entry
                                </Button>
                                <Button bsStyle="primary" onClick={this.toggleStarsReasonCodeModal}
                                    style={{ display: 'inline-block', marginLeft: '1rem' }}>
                                    Reason Codes
                                </Button>
                            </ListGroupItem>
                        </ListGroup>
                    }
                    {
                        this.props.starsData &&
                        this.renderTable(this.props.starsData)
                    }
                </Panel>
                <StarsDetailModal visible={this.state.showDiagDetailModal} handleModalToggle={this.toggleDiagDetailModal}
                    row={this.state.modalRow} rejectionReasons={this.props.rejectionReasons} />
                <StarsEntryModal visible={this.state.showStarsEntryModal} handleModalToggle={this.toggleFormModal} 
                    isQA={this.props.isQA} isComplete={this.props.isComplete} isMD={this.props.isMD} enteredByUserID={this.state.enteredByUserID}
                    userID={this.props.userID} memberID={this.props.memberID} medicalRecordReviewID={this.props.medicalRecordReviewID}
                    maxDateDifference={this.props.maxDateDifference} qualityMeasures={this.props.qualityMeasures} 
                    imageRecords={this.props.imageRecords} getStarsData={this.props.getStarsData} editMode={this.state.editMode}
                    editModalRowID={this.state.editModalRowID} measureID={this.state.editModalRowMeasureID} 
                    selectedStarMeasure={this.props.selectedStarMeasure} getStarsHistoryData={this.props.getStarsHistoryData}
                    member={this.props.member} mrrProviderNPIs={this.props.mrrProviderNPIs} toast={this.props.toast} />
                <RejectModal visible={this.state.showRejectModal} rejectionReasons={this.state.rejectedReasons} 
                    handleModalToggle={this.toggleRejectModal} row={this.state.rejectRow} title="Reject Stars Entry" 
                    userID={this.props.userID} setSelectedRejectionReasons={this.setSelectedRejectionReasons} 
                    rejectEntry={this.rejectEntry} />
                <StarsReasonCodeModal visible={this.state.showStarsReasonCodeModal} handleModalToggle={this.toggleStarsReasonCodeModal}
                    qualityMeasures={this.props.qualityMeasures} medicalRecordReviewID={this.props.medicalRecordReviewID}
                    isComplete={this.props.isComplete} toast={this.props.toast} />
                <AlertDialog visible={this.state.showConfirmModal} handleModalToggle={this.toggleConfirmModal} title={this.confirmModalMessage}
                    renderBody={false} handleConfirm={this.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
            </div>
        );
    }
}

export default StarsCapture;
import React from 'react';
import axios from 'axios';
import { Panel, ListGroup, ListGroupItem, Button, FormGroup, FormControl, ControlLabel, Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortPageNumbers, sortComments, sortDate } from '../../../../../utils/SortUtils';
import { DiagnosisUtils } from '../../../../../utils/DiagnosisUtils';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus';
import AlertDialog from '../../../../Shared/AlertDialog';
import CoderQADiagnosisFeedbackModal from './CoderQADiagnosisFeedbackModal';
import DiagnosisDetailModal from './DiagnosisDetailModal';
import DiagnosisFormModal from './DiagnosisFormModal';
import DiagnosisLookupModal from './DiagnosisLookupModal';
import RADVModal from './RADVModal';
import RejectModal from '../RejectModal';

class DiagnosisCapture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiagDetailModal: false,
            showFormModal: false,
            showConfirmDeleteModal: false,
            showDiagFeedbackModal: false,
            showDiagLookupModal: false,
            modalRow: null,
            editModalRow: null,
            editMode: false,
            editQABool: false,
            rejectFromDetailModal: false,
            rejectedReasons: null,
            radvRow: null,
            showRADVModal: false,
            rowIndex: null,
            diagFilter: '',
            diagSaveObj: null,
            selectedPostSubmitStatus: null,
            confirmConflictModalMessage: null,
            selectedDiagLookupCD: null,
            selectedDiagLookupDOS: null,
            selectedProvider: null,
            selectedSecondaryProvider: null,
            selectedProviderFromDiagAdd: null,
            claimsMap: new Map(),
            claimsProviderMap: new Map(),
            providerGroupMap: new Map()
        }
        this.reopenID = 10;
        this.confirmModalMessage = 'Are you sure you want to delete this record?';
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            diagnosisData: nextProps.diagnosisData
        });

        //value passed via react-redux from CDIAlertContainer/CDIAlertContainerRedux:
        if (nextProps.diags.length > 0 && nextProps.diags.find(x => x.diagFormData)) {
            this.toggleFormModal(nextProps.diags.find(x => x.diagFormData).diagFormData, false, false, true);
        }
    }

    // format helpers
    formatTooltip = (cell, row) => {
        return (
            <OverlayTrigger placement="top" overlay={this.getTooltip(row.medicalRecordDiagID, cell)}>
                <span>{cell}</span>
            </OverlayTrigger>
        );
    }

    getTooltip = (id, displayValue) => {
        return (
            <Tooltip id={id}>
                <p key={displayValue}>{displayValue}</p>
            </Tooltip>
        );
    }

    formatReferenceDescription = (cell, row, collection) => {
        return collection.referenceData.find(x => x.value === cell).description;
    }

    formatInitiatorTypeDescription = (cell, row) => {
        let description = "";
        switch(cell) {
            case 1:
                description = "User";
                break;
            case 2:
                description = "Claim";
                break;
            case 3:
                description = "Risk Mitigation";
                break;
            default:
                description = "User";
                break;
        }
        return description;
    }

    formatPages = (cell, row) => {
        return row.pageNumFrom + "-" + row.pageNumTo;
    }

    formatRADV = (cell, row) => {
        if(row.radvScore && row.hccHierarchy) {
            const radvDisplay = row.radvScore.description + " " + row.hccHierarchy.description;
            return (
                <OverlayTrigger placement="top" overlay={this.getTooltip(row.medicalRecordDiagID, radvDisplay)}>
                    <Button onClick={() => this.toggleRADVModal(row)} style={{ whiteSpace: 'normal' }}>{radvDisplay}</Button>
                </OverlayTrigger>
            );
        }
        else {
            return "";
        }
    }

    formatInitialStatus = (cell, row) => {
        let initialStatusText = '';
        if (row.diagnosisCDReviewStatusID === LineItemReviewStatus.Captured) {
            initialStatusText = 'Captured';
        }
        else if (row.diagnosisCDReviewStatusID === LineItemReviewStatus.NotCaptured) {
            initialStatusText = 'Not Captured';
        }
        else if (row.diagnosisCDReviewStatusID === LineItemReviewStatus.Rejected || row.diagnosisCDReviewStatusID === LineItemReviewStatus.MDRejected) {
            initialStatusText = row.isSystemApproved ? 'System Rejected' : 'Rejected';
        }
        else if (row.diagnosisCDReviewStatusID === LineItemReviewStatus.Confirmed || row.diagnosisCDReviewStatusID === LineItemReviewStatus.MDConfirmed) {
            initialStatusText = row.isSystemApproved ? 'System Accepted' : 'Accepted';
        }
        else {
            initialStatusText = this.props.isMD ? 'Ready for MD' : 'Ready for QA';
        }
        
        return <div className={(initialStatusText.indexOf('Accepted') > -1) ? 'diag-accepted-status' : 'diag-rejected-status'}
            style={{ textAlign: 'center' }}>{initialStatusText}</div>
    }

    formatActions = (cell, row) => {
        if (!this.props.isComplete) {
            if (!this.props.isQA && !this.props.isMD) {
                if (DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(row)) {
                    if (!DiagnosisUtils.isApprovedOrRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) {
                        // show QA icons
                        return <div className="diag-qa-button-wrapper">
                            <Button onClick={() => this.toggleFormModal(row)}>
                                <Glyphicon bsSize="large" glyph="pencil"/>
                            </Button>
                            <Button className="approveButton" onClick={() => this.approveDiagnosis(row)}>
                                <Glyphicon bsSize="large" glyph="ok"/>
                            </Button>
                            <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>
                                <Glyphicon bsSize="large" glyph="remove"/>
                            </Button>
                            {
                                DiagnosisUtils.isClaimsDiagnosis(row) &&
                                <Button className="deleteButton" onClick={() => this.toggleConfirmDeleteModal(row)}>
                                    <Glyphicon bsSize="large" glyph="trash"/>
                                </Button>
                            }
                        </div>
                    }
                    else if (DiagnosisUtils.isApprovedOrRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) {
                        // show accepted/rejected/reopen
                        return <div className="diag-qa-button-wrapper">
                            {
                                DiagnosisUtils.isApproved(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                                <p style={{ display: 'inline-block', marginRight: '1rem' }}>Accepted</p>
                            }
                            {
                                DiagnosisUtils.isRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                                <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>Rejected</Button>
                            }
                            <Button onClick={() => this.reopenDiagnosis(row)}>
                                <Glyphicon bsSize="large" glyph="repeat" bsClass="glyphicon mirror-icon glyphicon-repeat" />
                            </Button>
                        </div>
                    }
                }
                else if (!DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(row)) {
                    //show edit/delete icons
                    return <div className="diag-qa-button-wrapper">
                        <Button onClick={() => this.toggleFormModal(row)}>
                            <Glyphicon bsSize="large" glyph="pencil" />
                        </Button>
                        <Button className="deleteButton" onClick={() => this.toggleConfirmDeleteModal(row)}>
                            <Glyphicon bsSize="large" glyph="trash" />
                        </Button>
                    </div>
                }
            }
            else if ((this.props.isQA || this.props.isMD) && !this.props.isCoderQA) {
                // NEW diags
                if (row.createdUserID === this.props.userID) {
                    if (!DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) {
                        //show edit/delete icons
                        return <div className="diag-qa-button-wrapper">
                            <Button onClick={() => this.toggleFormModal(row)}>
                                <Glyphicon bsSize="large" glyph="pencil" />
                            </Button>
                            <Button className="deleteButton" onClick={() => this.toggleConfirmDeleteModal(row)}>
                                <Glyphicon bsSize="large" glyph="trash" />
                            </Button>
                        </div>
                    }
                    else if (DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)
                                && !DiagnosisUtils.isApprovedOrRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) {
                        // show edit/approve/reject/ delete claims icons
                        return <div className="diag-qa-button-wrapper">
                            <Button onClick={() => this.toggleFormModal(row)}>
                                <Glyphicon bsSize="large" glyph="pencil" />
                            </Button>
                            <Button className="approveButton" onClick={() => this.approveDiagnosis(row)}>
                                <Glyphicon bsSize="large" glyph="ok" />
                            </Button>
                            <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>
                                <Glyphicon bsSize="large" glyph="remove" />
                            </Button>
                            {
                                DiagnosisUtils.isClaimsDiagnosis(row) &&
                                <Button className="deleteButton" onClick={() => this.toggleConfirmDeleteModal(row)}>
                                    <Glyphicon bsSize="large" glyph="trash"/>
                                </Button>
                            }
                        </div>
                    }
                }
                else if (DiagnosisUtils.isApprovedOrRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) {
                    //show accepted/rejected/reopen icons
                    return <div className="diag-qa-button-wrapper">
                        {
                            DiagnosisUtils.isApproved(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                            <p style={{ display: 'inline-block', marginRight: '1rem' }}>Accepted</p>
                        }
                        {
                            DiagnosisUtils.isRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                            <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>Rejected</Button>
                        }
                        <Button onClick={() => this.reopenDiagnosis(row)}>
                            <Glyphicon bsSize="large" glyph="repeat" bsClass="glyphicon mirror-icon glyphicon-repeat" />
                        </Button>
                    </div>
                }
            }
            else if (this.props.isCoderQA) {
                if (!DiagnosisUtils.isApprovedOrRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) {
                    // Diags from another coder
                    if (row.diagnosisCDReviewStatusID !== LineItemReviewStatus.NotCaptured) {
                        //TODO: show confirm/reject icons with checkConflicts
                        return <div className="diag-qa-button-wrapper">
                            <Button className="approveButton" onClick={() => this.setPostSubmitStatus(row, LineItemReviewStatus.CoderQAConfirmed, true)}>
                                <Glyphicon bsSize="large" glyph="ok" />
                            </Button>
                            <Button className="deleteButton" onClick={() => this.setPostSubmitStatus(row, LineItemReviewStatus.CoderQARejected, true)}>
                                <Glyphicon bsSize="large" glyph="remove" />
                            </Button>
                        </div>
                    }
                    // NEW Diags
                    else if (row.diagnosisCDReviewStatusID === LineItemReviewStatus.NotCaptured || row.diagnosisCDReviewStatusID === '') {
                        // show edit/delete icons without checkConflicts
                        return <div className="diag-qa-button-wrapper">
                            <Button onClick={() => this.toggleFormModal(row)}>
                                <Glyphicon bsSize="large" glyph="pencil" />
                            </Button>
                            <Button className="deleteButton" onClick={() => this.toggleConfirmDeleteModal(row)}>
                                <Glyphicon bsSize="large" glyph="trash" />
                            </Button>
                        </div>
                    }
                }
                else {
                    //show approved/rejected/reopen icons
                    return <div className="diag-qa-button-wrapper">
                        {
                            DiagnosisUtils.isApproved(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                            <Button className="approveButton" onClick={() => this.setPostSubmitStatus(row, LineItemReviewStatus.CoderQAConfirmed, false)}>Accepted</Button>
                        }
                        {
                            // TODO coderQA Feedback Modal (reject)
                            DiagnosisUtils.isRejected(row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                            <Button className="deleteButton" onClick={() => this.setPostSubmitStatus(row, LineItemReviewStatus.CoderQARejected, false)}>Rejected</Button>
                        }
                        <Button onClick={() => this.reopenDiagnosisCoderQA(row)}>
                            <Glyphicon bsSize="large" glyph="repeat" bsClass="glyphicon mirror-icon glyphicon-repeat" />
                        </Button>
                    </div>
                }
            }
        }
    }

    // diag row functionality helpers
    qaFilterWorkload = (e) => {
        let diagFilter = '';
        if (e.target && e.target.value) {
            diagFilter = e.target.value;
            if (diagFilter === "QA Complete" || diagFilter === "MD Complete") {
                this.setState({
                    diagFilter: diagFilter,
                    diagnosisData: this.props.diagnosisData.filter(x => {
                        return x.qaGroupingID === 2;
                    })
                });
            }
            else if (diagFilter === "Ready for QA" || diagFilter === "Ready for MD") {
                this.setState({
                    diagFilter: diagFilter,
                    diagnosisData: this.props.diagnosisData.filter(x => {
                        return x.qaGroupingID === 1 && x.createdUserID !== this.props.userID;
                    })
                });
            }
            else if (diagFilter === "Initial Entry") {
                this.setState({
                    diagFilter: diagFilter,
                    diagnosisData: this.props.diagnosisData.filter(x => {
                        return x.createdUserID === this.props.userID;
                    })
                });
            }
        }
        else {
            this.setState({
                diagFilter: diagFilter,
                diagnosisData: this.props.diagnosisData
            });
        }
    }

    getPrevNextRows = (rowIndex) => {
        let showPrev = false;
        let showNext = false;
        if (rowIndex !== 0) {
            showPrev = true;
        }
        if (rowIndex !== this.props.diagnosisData.length - 1) {
            showNext = true;
        }
        return { showPrev: showPrev, showNext: showNext };
    }

    setRow = (rowIndex) => {
        const prevNext = this.getPrevNextRows(rowIndex);
        this.setState({ rowIndex: rowIndex, modalRow: this.table.state.data[rowIndex], showPrev: prevNext.showPrev, showNext: prevNext.showNext });
    }

    // confirm modal handlers
    toggleConfirmDeleteModal = (row, isDetailModalClick) => {
        this.setState({ 
            showConfirmDeleteModal: !this.state.showConfirmDeleteModal,
            diagSaveObj: row && !row.target ? row : null,
            isDetailModalClick: isDetailModalClick
        });
    }

    handleConfirm = () => {
        this.toggleConfirmDeleteModal(null, this.state.isDetailModalClick);
        this.deleteRow(this.state.diagSaveObj, this.state.isDetailModalClick);
    }

    toggleConfirmConflictModal = () => {
        this.setState({ 
            showConfirmConflictModal: !this.state.showConfirmConflictModal
        });
    }

    confirmCoderQAStatusConflict = () => {
        this.toggleConfirmConflictModal();
        this.setPostSubmitStatus(this.state.diagSaveObj, this.state.selectedPostSubmitStatus, false);
    }

    // detail and form modal handlers
    toggleDiagDetailModal = (row) => {
        const index = this.table.state.data.indexOf(this.state.diagnosisData.find(x => {
            return row && x.medicalRecordDiagID === row.medicalRecordDiagID
        }));
        const prevNext = this.getPrevNextRows(index);
        this.setState({ 
            showDiagDetailModal: !this.state.showDiagDetailModal,
            modalRow: row,
            rowIndex: index,
            showPrev: prevNext.showPrev, 
            showNext: prevNext.showNext 
        });
    }

    //todo: pass an actionType param instead of these bools; use default param values (don't leave undefined)
    toggleFormModal = (row, isDetailModalClick, editQABool, viaAlert = false) => {
        let editRow; 
        let editMode = false;
        const providerMaster = this.props.mrrWithProviderMaster && this.props.mrrWithProviderMaster.populateReviewProvider ?
            this.props.mrrWithProviderMaster.providerMaster : null;
        // if row exists and medicalRecordDiagID is not null, you're editing a diag - set editRow to the current row and editMode to true
        if ((row && row.medicalRecordDiagID) || (row && viaAlert)) {
            editRow = row;
            if (!viaAlert) {
                editMode = true;
            }
            else {
                row.viaAlert = true;
            }
        }
        // if true, edit was selected from the detail modal - so, close the detail modal as the edit modal opens
        if (isDetailModalClick) {
            this.toggleDiagDetailModal(row);
        }
        // for non-edits, if the review has an associated provider and the user hasn't added a diag, the associated provider is used to prepopulate the provider field
        this.setState({ 
            showFormModal: !this.state.showFormModal, 
            editModalRow: editRow, 
            editMode: editMode, 
            editQABool: editQABool,
            selectedProvider: editRow ? 
                {
                    internalKey: editRow.providerMasterID,
                    lastName: editRow.providerLastName, 
                    firstName: editRow.providerFirstName,
                    providerNPI: editRow.providerNPI,
                    planProviderID: editRow.planProviderID,
                    label: this.getProviderDropdownLabel(editRow.providerLastName, editRow.providerFirstName, editRow.providerID, editRow.providerNPI, null)
                } : providerMaster ?
                    {
                        internalKey: providerMaster.internalKey,
                        lastName: providerMaster.lastName,
                        firstName: providerMaster.firstName,
                        providerNPI: providerMaster.providerNPI,
                        planProviderID: providerMaster.planProviderID, //todo: confirm this is being populated
                        label: this.getProviderDropdownLabel(providerMaster.lastName, providerMaster.firstName, providerMaster.providerID, providerMaster.providerNPI, null)
                    } : null,
            selectedSecondaryProvider: editRow && editRow.secondaryProviderMasterID ?
                {
                    internalKey: editRow.secondaryProviderMasterID,
                    lastName: editRow.secondaryProviderLastName,
                    firstName: editRow.secondaryProviderFirstName,
                    providerNPI: editRow.secondaryProviderNPI,
                    planProviderID: editRow.secondaryPlanProviderID,
                    label: this.getProviderDropdownLabel(editRow.secondaryProviderLastName, editRow.secondaryProviderFirstName, editRow.secondaryProviderID, editRow.secondaryProviderNPI, null)
                } : null
        });
    }

    getProviderDropdownLabel = (lastName, firstName, providerID, providerNPI, alternateProviderIDs = null) => {
        const providerName = firstName ? `${lastName}, ${firstName}` : lastName;
        if (alternateProviderIDs) {
            providerID = `${providerID}, ${alternateProviderIDs.join(', ')}`;
        }
        if (providerNPI && providerID) {
            return `${providerName} (${providerNPI} - ${providerID})`;
        }
        else if (providerNPI && !providerID) {
            return `${providerName} (NPI: ${providerNPI})`;
        }
        else if (!providerNPI && providerID) {
            return `${providerName} (Provider ID: ${providerID})`;
        }
        else {
            return `${providerName} (Missing ProviderID and NPI)`;
        }
    }

    //------------ RADV helpers -------------//
    toggleRADVModal = (row) => {
        this.setState({
            showRADVModal: !this.state.showRADVModal,
            radvRow: row
        });
    }
  
    // ----------- approve/reject/reopen/delete --------------//
    reopenDiagnosis = (row) => {
        // clear rejection comments if diag is being reopened
        row.rejectionComments = [];
        this.setStatus(this.reopenID, row);
    }

    reopenDiagnosisCoderQA = (row) => {
        // Delete the score
        axios.delete(`/coderQA/${this.props.coderQAQueueID}/medicalRecordReviews/${row.medicalRecordReviewID}/diagnosis/${row.medicalRecordDiagID}/score`)
        .catch((error) => {
            console.log(error);
        })

        // Update the post submit status
        this.setPostSubmitStatus(row, null, false);
    }

    // approve helper
    approveDiagnosis = (row) => {
        this.setStatus(this.props.isMD ? LineItemReviewStatus.MDConfirmed : LineItemReviewStatus.Confirmed, row);
    }

    // approve/reject Coder QA helper
    setPostSubmitStatus = (row, postSubmitStatus, checkConflict) => {
        this.setState({
            diagSaveObj: row && !row.target ? row : null,
            selectedPostSubmitStatus: postSubmitStatus
        });

        if (checkConflict && (row.isSystemApproved !== true && row.postSubmitStatusID === null &&
                ((postSubmitStatus === LineItemReviewStatus.CoderCoderQAConfirmed && row.diagnosisCDReviewStatusID === LineItemReviewStatus.Rejected)
                || (postSubmitStatus === LineItemReviewStatus.CoderQARejected && row.diagnosisCDReviewStatusID === LineItemReviewStatus.Confirmed))))
        {
            this.setState({
                confirmConflictModalMessage: (postSubmitStatus === LineItemReviewStatus.CoderCoderQAConfirmed) ? 
                    "This diagnosis was rejected during QA. Have you confirmed this approval with your manager?"
                    : "This diagnosis was approved during QA. Have you confirmed this rejection with your manager?"
            }, () => this.toggleConfirmConflictModal());
        }
        else
        {
            // If there are no conflicts and a post submit status hasn't already been set or it's being removed, save the new status
            if (row.postSubmitStatusID !== postSubmitStatus) {
                this.setState({
                    diagSaveObj: {
                        ...row,
                        postSubmitStatusID: postSubmitStatus,
                        userID: this.props.userID,
                        expectedVersion: row.updatedDateTime,
                        isBeingAcceptedOrRejected: true
                    }
                }, () => {
                    axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiag/${row.medicalRecordDiagID}`, this.state.diagSaveObj)
                    .then((response) => {
                        this.props.getDiagnosisData();
                        this.props.getDiagnosisHistoryData();
                        // If not reopening the diag, open the modal for diag feedback
                        if (postSubmitStatus !== null) {
                            this.toggleDiagFeedbackModal(this.state.diagSaveObj);
                        }
                    })
                    .catch((error) => {
                        this.props.toast('error', 'Could not update diagnosis status.', 'Error');
                    });
                });
            }
            else {
                this.toggleDiagFeedbackModal(row);
            }
        }
    }

    // sets status to appropriate reviewStatusID
    setStatus = (reviewStatusID, row, afterSuccess) => {
        this.setState({
            diagSaveObj: {
                ...row,
                diagnosisCDReviewStatusID: reviewStatusID,
                userID: this.props.userID,
                expectedVersion: row.updatedDateTime,
                isBeingAcceptedOrRejected: true
            }
        }, () => {
            axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiag/${row.medicalRecordDiagID}`, this.state.diagSaveObj)
            .then((response) => {
                this.props.getDiagnosisData();
                this.props.getDiagnosisHistoryData();
                if (afterSuccess) {
                    afterSuccess();
                }
                // refresh diag detail modal row with updated status
                this.setState({
                    modalRow: {
                        ...this.state.diagSaveObj,
                        updatedDateTime: response.data.updatedDateTime
                    }
                })
            })
            .catch((error) => {
                this.props.toast('error', 'Could not update diagnosis status.', 'Error');
            })
        })
    }

    // reject modal helpers
    toggleRejectModal = (row, isDetailModalClick) => {
        this.setState({
            rejectRow: row,
            rejectFromDetailModal: isDetailModalClick,
            showRejectModal: !this.state.showRejectModal,
            rejectedReasons: this.props.rejectionReasons.map(x => {
                x.isChecked = row && row.rejectionComments && row.rejectionComments.includes(x.commentDefinitionID.toString());
                return x;
            })
        });
        // if opening reject modal, close detail modal (and vice-versa)
        if (isDetailModalClick) {
            this.toggleDiagDetailModal(row);
        }
    }

    // update rejectedReasons on checkbox select
    setSelectedRejectionReasons = (updatedRejectionReasons) => {
        this.setState({ rejectedReasons: updatedRejectionReasons.sort(sortComments)});
    }

    // reject helper
    rejectEntry = (row, isDetailModalClick) => {
        this.setState({
            diagSaveObj: {
                ...row,
                expectedVersion: this.state.diagnosisData.find(x => {
                    return x.medicalRecordDiagID === row.medicalRecordDiagID
                }).updatedDateTime,
                userID: this.props.userID,
                //TODO: set RADVScore, HCCHierarchy
                rejectionComments: this.state.rejectedReasons.filter(x => {
                    return x.isChecked
                }).map(y => { return y.commentDefinitionID.toString() }),
                diagnosisCDReviewStatusID: this.props.isMD ? LineItemReviewStatus.MDRejected : LineItemReviewStatus.Rejected,
                isBeingAcceptedOrRejected: true
            },
        }, () => {
            axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiag/${row.medicalRecordDiagID}`, this.state.diagSaveObj)
            .then((response) => {
                this.props.getDiagnosisData();
                this.props.getDiagnosisHistoryData();

                this.toggleRejectModal();
                
                // refresh diag detail modal row with updated status
                this.setState({
                    modalRow: {
                        ...this.state.diagSaveObj,
                        updatedDateTime: response.data.updatedDateTime
                    },
                    diagSaveObj: {
                        ...this.state.diagSaveObj,
                        updatedDateTime: response.data.updatedDateTime
                    }
                }, () => {
                    // if rejected from detail modal, reopen detail modal with updated row data
                    if (isDetailModalClick) {
                        this.toggleDiagDetailModal(this.state.diagSaveObj);
                    }
                })
            })
            .catch((error) => {
                this.props.toast('error', 'Could not update diagnosis status.', 'Error');
            })
        })
    }

    toggleDiagFeedbackModal = (diag) => {
        this.setState({
            showDiagFeedbackModal: !this.state.showDiagFeedbackModal,
            modalRow: diag ? {...diag} : null
        });
    }

    toggleDiagLookupModal = () => {
        this.setState({ showDiagLookupModal: !this.state.showDiagLookupModal });
    }

    selectDiagLookup = (diagCD, dateOfService, addDiag) => {
        if (addDiag) {
            this.toggleDiagLookupModal();
            this.setState({
                selectedDiagLookupCD: diagCD,
                selectedDiagLookupDOS: dateOfService
            }, () => {
                this.toggleFormModal(null, false, null);
            });
        }
        else {
            if (this.state.showDiagLookupModal) {
                this.toggleDiagLookupModal();
            }
            this.setState({
                selectedDiagLookupCD: diagCD,
                selectedDiagLookupDOS: dateOfService
            });
        }
    }

    deleteRow = (row, isDetailModalClick) => {
        this.setState({
            diagSaveObj: {
                ...row,
                medicalRecordDiagID: row.medicalRecordDiagID,
                userID: this.props.userID,
                expectedVersion: this.state.diagnosisData.find(x => {
                    return x.medicalRecordDiagID === row.medicalRecordDiagID
                }).updatedDateTime
            }
        }, () => {
            Promise.all([
                axios.delete(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiag/${row.medicalRecordDiagID}`, {
                    data: {...this.state.diagSaveObj}
                }),
                axios.delete(`/medicalRecordReview/${this.props.medicalRecordReviewID}/CDIAlertEntityDetailDiag/${row.medicalRecordDiagID}`)
            ])
            .then((response) => {
                this.props.getDiagnosisData();
                this.props.getDiagnosisHistoryData();
                if (isDetailModalClick) {
                    this.toggleDiagDetailModal(row);
                }
            })
            .catch((error) => {
                this.props.toast('error', 'Could not delete diagnosis.', 'Error');
            })
        })
    }

    //todo: reuse Maps / don't init again
    setClaimsAndClaimProviders = (dosRange, claimsResponse) => {
        this.setState({
            claimsMap: new Map([ ...this.state.claimsMap,
                [dosRange, claimsResponse.claims ? claimsResponse.claims : []]
            ]),
            claimsProviderMap: new Map([ ...this.state.claimsProviderMap,
                [dosRange, claimsResponse.claimProviders ? claimsResponse.claimProviders : []]
            ])
        });
    }

    //todo: reuse Maps / don't init again
    setProviderGroup = (planProviderID, providerGroup) => {
        this.setState({
            providerGroupMap: new Map([ ...this.state.providerGroupMap,
                [planProviderID, providerGroup ? providerGroup : [] ]
            ])
        });
    }

    // table render functions
    renderTable = (results) => {
        const options = {
            defaultSortName: 'medicalRecordDiagID',
            defaultSortOrder: 'asc',
            onRowDoubleClick: this.toggleDiagDetailModal
        };
        console.log("Reload Diagnosis table");
        return (            
            <div style={{ paddingTop: 0 }}>
                <BootstrapTable data={results} ref={(table) => { this.table = table }} options={options} scroll-top="Top" hover striped>
                    <TableHeaderColumn hidden dataField="providerMasterID" />
                    <TableHeaderColumn isKey dataField="medicalRecordDiagID" dataFormat={this.formatTooltip} dataSort width="4%">ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerID" dataSort width="7%">Provider ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceFrom" dataSort width="6%" sortFunc={(a, b, order) => sortDate(a.dateOfServiceFrom, b.dateOfServiceFrom, order)}>DOS</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceTo" dataSort width="6%" sortFunc={(a, b, order) => sortDate(a.dateOfServiceTo, b.dateOfServiceTo, order)}>DOS Thru</TableHeaderColumn>
                    <TableHeaderColumn dataField="diagnosisCD" dataSort width="6%">Diagnosis</TableHeaderColumn>
                    <TableHeaderColumn dataField="hccCsDisplay" dataSort width="6%" tdStyle={{ whiteSpace: 'normal' }}>HCC-C</TableHeaderColumn>
                    <TableHeaderColumn dataField="hccDsDisplay" dataSort width="6%" tdStyle={{ whiteSpace: 'normal' }}>HCC-D</TableHeaderColumn>
                    <TableHeaderColumn dataField="description" dataSort width="17%" tdStyle={{ whiteSpace: 'normal' }}>Description</TableHeaderColumn>
                    <TableHeaderColumn dataField="diagnosisCommentCount" dataSort width="3%"><Glyphicon glyph="comment" /></TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordImageID" dataSort width="6%">Image ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="pageNumFrom" dataFormat={this.formatPages} dataSort sortFunc={sortPageNumbers} width="4%">Pages</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordTypeID" dataFormat={this.formatReferenceDescription} formatExtraData={this.props.medicalRecordTypeData} dataSort width="6%">Record Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="initiatorTypeID" dataFormat={this.formatInitiatorTypeDescription} tdStyle={{ whiteSpace: 'normal' }} dataSort width="6%">Origin</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.formatRADV} hidden={!this.props.isRADV} tdStyle={{ whiteSpace: 'normal' }} width="9%">RADV Score</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.formatInitialStatus} hidden={!this.props.isCoderQA} width="6%">Initial Status</TableHeaderColumn>
                    <TableHeaderColumn width="11%" dataFormat={this.formatActions} tdStyle={{whiteSpace: 'normal'}}>Update</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Panel className="capturePanel" header="Diagnosis Capture">
                    <ListGroup>
                        <ListGroupItem>
                        {
                            !this.props.isComplete && (this.props.isQA || this.props.isMD) &&
                            <FormGroup style={{ width: '200px', marginRight: '20px', display: 'inline-block' }}>
                                <ControlLabel style={{ marginRight: '1rem', fontSize: '14px'}}>Showing Items: </ControlLabel>
                                <FormControl componentClass="select" placeholder="All" value={this.state.diagFilter} onChange={this.qaFilterWorkload}>
                                    <option style={{ display: this.props.isQA && !this.props.isMD ? 'block' : 'none' }} value="Ready for QA">Ready for QA</option>
                                    <option style={{ display: this.props.isQA && !this.props.isMD ? 'block' : 'none' }} value="QA Complete">QA Complete</option>
                                    <option style={{ display: this.props.isMD ? 'block' : 'none' }} value="Ready for MD">Ready for MD</option>
                                    <option style={{ display: this.props.isMD ? 'block' : 'none' }} value="MD Complete">MD Complete</option>
                                    <option value="Initial Entry">Initial Entry</option>
                                    <option value="">All</option>
                                </FormControl>
                            </FormGroup>
                        }
                        {
                            <div>
                                {
                                    !this.props.isComplete &&
                                    <Button bsStyle="primary" onClick={() => this.toggleFormModal(null, false, null)}
                                        style={{ marginRight: '1rem', display: 'inline-block' }}>Add Diagnosis</Button>
                                }
                                <Button bsStyle="primary" onClick={this.toggleDiagLookupModal}>Diagnosis Lookup</Button>
                            </div>
                        }
                        </ListGroupItem>
                    </ListGroup>
                    { 
                        this.state.diagnosisData &&
                        this.renderTable(this.state.diagnosisData)
                    }
                </Panel>

                <DiagnosisFormModal visible={this.state.showFormModal} handleModalToggle={this.toggleFormModal} 
                    medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                    providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData} diagnosisComments={this.props.diagnosisComments}
                    imageRecords={this.props.imageRecords} userUploadedImages={this.props.userUploadedImages} isRADV={this.props.isRADV}
                    isQA={this.props.isQA} isComplete={this.props.isComplete} isMD={this.props.isMD} isCoderQA={this.props.isCoderQA}
                    isRiskMitigation={this.props.isRiskMitigation} medicalRecordTypeID={this.props.medicalRecordTypeID}
                    userID={this.props.userID} memberID={this.props.memberID} medicalRecordReviewID={this.props.medicalRecordReviewID}
                    maxDateDifference={this.props.maxDateDifference} getDiagnosisData={this.props.getDiagnosisData} 
                    editModalRow={this.state.editModalRow} editMode={this.state.editMode} editQABool={this.state.editQABool}
                    radvScores={this.props.radvScores} hccHierarchies={this.props.hccHierarchies} radvAuditEnrolleeHCCs={this.props.radvAuditEnrolleeHCCs}
                    getDiagnosisHistoryData={this.props.getDiagnosisHistoryData} projectID={this.props.projectID}
                    toggleDiagFeedbackModal={this.toggleDiagFeedbackModal} showDiagFeedbackModal={this.state.showDiagFeedbackModal}
                    setDiagFormData={this.props.setDiagFormData} selectedDiagLookupCD={this.state.selectedDiagLookupCD}
                    selectedDiagLookupDOS={this.state.selectedDiagLookupDOS} selectDiagLookup={this.selectDiagLookup} toast={this.props.toast}
                    radvEnrolleeID={this.props.radvEnrolleeID} reloadImages={this.props.reloadImages} selectedProvider={this.state.selectedProvider}
                    selectedSecondaryProvider={this.state.selectedSecondaryProvider} member={this.props.member} getProviderDropdownLabel={this.getProviderDropdownLabel} reduxDiags={this.props.diags}
                    autoClaimsLinkingEnabled={this.props.autoClaimsLinkingEnabled}
                    parallelClaimsDiagCaptureEnabled={this.props.parallelClaimsDiagCaptureEnabled}
                    claimsMap={this.state.claimsMap} claimsProviderMap={this.state.claimsProviderMap}
                    setClaimsAndClaimProviders={this.setClaimsAndClaimProviders} providerGroupMap={this.state.providerGroupMap}
                    setProviderGroup={this.setProviderGroup} />

                <DiagnosisDetailModal visible={this.state.showDiagDetailModal} handleModalToggle={this.toggleDiagDetailModal}
                    row={this.state.modalRow} medicalRecordTypeData={this.props.medicalRecordTypeData}
                    rejectionReasons={this.props.rejectionReasons} diagnosisComments={this.props.diagnosisComments}
                    sourceData={this.props.sourceData} providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData}
                    isQA={this.props.isQA} isComplete={this.props.isComplete} isMD={this.props.isMD} isCoderQA={this.props.isCoderQA} 
                    userID={this.props.userID} memberID={this.props.memberID} index={this.state.rowIndex} showPrev={this.state.showPrev} showNext={this.state.showNext}
                    setRow={this.setRow} editMode={this.state.editMode} toggleFormModal={this.toggleFormModal}
                    reopenDiagnosis={this.reopenDiagnosis} approveDiagnosis={this.approveDiagnosis} toggleRejectModal={this.toggleRejectModal}
                    deleteRow={this.deleteRow} toggleConfirmDeleteModal={this.toggleConfirmDeleteModal} handleConfirm={this.handleConfirm} />

                <DiagnosisLookupModal visible={this.state.showDiagLookupModal} handleModalToggle={this.toggleDiagLookupModal} 
                    memberID={this.props.memberID} isRADV={this.props.isRADV} selectDiagLookup={this.selectDiagLookup} toast={this.props.toast} 
                    isComplete={this.props.isComplete}/>

                <CoderQADiagnosisFeedbackModal visible={this.state.showDiagFeedbackModal} handleModalToggle={this.toggleDiagFeedbackModal}
                    medicalRecordReviewID={this.props.medicalRecordReviewID} projectID={this.props.projectID} coderQAQueueID={this.props.coderQAQueueID}
                    medicalRecordDiagID={this.state.modalRow ? this.state.modalRow.medicalRecordDiagID : ''}
                    postSubmitStatusID={this.state.modalRow ? this.state.modalRow.postSubmitStatusID : 0}/>

                <RejectModal visible={this.state.showRejectModal} rejectionReasons={this.state.rejectedReasons} 
                    handleModalToggle={this.toggleRejectModal} row={this.state.rejectRow} title="Reject Diagnosis" 
                    userID={this.props.userID} rejectFromDetailModal={this.state.rejectFromDetailModal} 
                    setSelectedRejectionReasons={this.setSelectedRejectionReasons} rejectEntry={this.rejectEntry}/>

                <RADVModal visible={this.state.showRADVModal} handleModalToggle={this.toggleRADVModal} row={this.state.radvRow}
                           radvScores={this.props.radvScores} hccHierarchies={this.props.hccHierarchies} radvAuditEnrolleeHCCs={this.props.radvAuditEnrolleeHCCs} />
                
                <AlertDialog visible={this.state.showConfirmDeleteModal} handleModalToggle={this.toggleConfirmDeleteModal} title={this.confirmModalMessage}
                    renderBody={false} handleConfirm={this.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                    
                <AlertDialog visible={this.state.showConfirmConflictModal} handleModalToggle={this.toggleConfirmConflictModal} title={this.state.confirmConflictModalMessage}
                    renderBody={false} handleConfirm={this.confirmCoderQAStatusConflict} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
            </div>
        );
    }
}

export default DiagnosisCapture;
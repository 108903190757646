// exports PracticeValidationStatus - an immutable object 'enum'
// usage: import { PracticeValidationStatus } from '../../enums/PracticeValidationStatus';
const practiceValidationStatuses = {
    NotApplicable: 1,
    PendingValidation: 2,
    Accepted: 4,
    Declined: 5,
    Escalated: 6,
    Expired: 7
};
Object.freeze(practiceValidationStatuses);
export const PracticeValidationStatus = practiceValidationStatuses;
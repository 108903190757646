import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Panel, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import AlertDialog from '../../Shared/AlertDialog';
import WorkflowHeaderDetails from './WorkflowHeaderDetails';
import { PackageStatus } from '../../../enums/PackageStatus';
import { PackageBundleType } from '../../../enums/PackageBundleType';
import { PracticeValidationStatus } from '../../../enums/PracticeValidationStatus';
import { PackageComponentType } from '../../../enums/PackageComponentType';

class PackageReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createdAlerts: [],
            alertBundle: {},
            reportBundle: {},
            alertLoadingStatus: '',
            reportLoadingStatus: '',
            selectedReports: [],
            showPackageDetailsModal: false,
            showConfirmFaxModal: false,
            loaded: false,
            faxEnabled: false
        };
        this.timer = null;
        this.pollingInterval = 10000;
        this.pollAlerts = true;
        this.pollBundles = true;

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillMount() {
        this.getSelectedReports();
        this.getPackageQueryList();
    }

    componentDidMount() {
        if (this.props.provider.id) {
            this.setState({
                loaded: true
            });
        }
        this.refreshData();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.provider.id && nextProps.provider.id !== this.state.providerID) {
            this.setState({
                loaded: true
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.source.cancel();
    }

    //poll for created alerts, alert package, and report package pdfs
    refreshData = () => {
        clearTimeout(this.timer);

        if (this.pollAlerts) {
            this.getCreatedAlerts();
        }
        if (this.pollBundles) {
            this.getLinks();
        }
        else {
            if (!this.state.alertLoadingStatus) {
                this.setState({ alertLoadingStatus: "Complete" });
            }
            if (!this.state.reportLoadingStatus) {
                this.setState({ reportLoadingStatus: "Complete" });
            }
        }

        if (this.pollAlerts || this.pollBundles) {
            this.timer = setTimeout(this.refreshData, this.pollingInterval);
        }
    }

    getPackageQueryList = () => {
        axios.get('/packages/' + this.props.packageID + '/IncludedQueryWorkflows')
        .then(response => {
            this.setState({ packageQueryList: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load queries.', 'Error');
        });
    }

    getSelectedReports = () => {
        axios.get('/packages/' + this.props.packageID + '/Component/', {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ selectedReports: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load report selections.', 'Error');
        });
    }

    //get created alerts
    getCreatedAlerts = () => {
        Promise.all([axios.get('/packages/' + this.props.packageID), axios.get('/packages/' + this.props.packageID + '/AlertsCreated')])
        .then((response) => {
            this.getFaxEnabled(response[0].data);
            if (response[1].data.length > 0) {
                //poll alerts until all created alerts have cdiAlertWorkflowID values
                const noWorkflowGenerated = response[1].data.filter(x => !x.cdiAlertWorkflowID);
                if (noWorkflowGenerated.length === 0) {
                    this.pollAlerts = false;
                }
                this.setState({ createdAlerts: response[1].data });
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load created alerts.', 'Error');
        });
    }

    getFaxEnabled = (alertPackage) => {
        // If fax enabled has already been set or the package does not meet the right criteria, return
        if (this.state.faxEnabled === true || alertPackage.packageStatusID !== 'SyncCompleted' || alertPackage.includeHeaders !== true) {
            return;
        }

        Promise.all([
            axios.get('/securable/config', { params: { configKeys: ["faxEnabled"] } }),
            axios.get(`/practice/PracticeValidationInfo/${this.props.practice.groupingID}`),
            axios.get(`/packages/${this.props.packageID}/Component`),
        ])
        .then((response) => {
            if (response[0].data && Boolean(response[0].data.faxEnabled) && response[1].data && response[2].data && response[1].data.practiceValidationStatusID === PracticeValidationStatus.Accepted
                && new Date(response[1].data.validationExpirationDate) > new Date()
                && response[2].data.findIndex(packageComp => packageComp.packageComponentTypeID === PackageComponentType.FaxCoverSheet || packageComp.packageComponentTypeID === PackageComponentType.QueryFaxCoverSheet) >= 0) {
                this.setState({ faxEnabled: true });
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load fax permissions.', 'Error');
        });
    }

    faxPackage = () =>
    {
        // Get fax information
        axios.get(`/practice/practiceeditinfo/?practiceID=${this.props.practice.groupingID}`)
        .then((response) => {
            const faxNumber = response.data.faxNumber ? response.data.faxNumber : response.data.originalFax;
            if (!faxNumber) {
                this.props.toast('error', 'There is no valid fax number associated with this practice.', 'Error');
                return;
            }

            this.toggleConfirmFaxModal();

            // Create the request to fax the package
            axios.post('/faxrequests', {
                DocumentType: 'AlertPackage',
                DocumentEntityID: this.props.packageID,
                FaxNumber: faxNumber,
                FaxToName: response.data.practiceName
            })
            .then((response) => {
                this.props.toast('success', 'Package fax initiated.', 'Success');
            })
            .catch((error) => {
                this.props.toast('error', 'Could not fax package.', 'Error');
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not fax package.', 'Error');
        });
    }

    //get alert package and report package bundles (includes pdf links when available)
    getLinks = () => {
        axios.get('/packages/' + this.props.packageID + '/bundles/')
        .then((response) => {
            if (response.data) {
                response.data.bundles.forEach(bundle => {
                    if (bundle.packageBundleType === PackageBundleType.AlertPackage) {
                        if (bundle.link) {
                            this.pollBundles = false;
                            this.setState({ alertBundle: { filename: bundle.fileName, uri: bundle.link.href}, alertLoadingStatus: "Complete" });
                        }
                    }
                    else if (bundle.packageBundleType === PackageBundleType.ReportPackage) {
                        if (bundle.link) {
                            this.pollBundles = false;
                            this.setState({ reportBundle: { filename: bundle.fileName, uri: bundle.link.href}, reportLoadingStatus: "Complete" });
                        }
                    }
                });
                if (response.data.packageStatusID === PackageStatus.SyncingWorkflowsCompleted) {
                    this.pollBundles = false;
                    this.setState({ alertLoadingStatus: "Complete", reportLoadingStatus: "Complete" });
                }
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load bundle download links.', 'Error');
        });
    }

    getPdf = (bundle) => {
        axios.get(bundle.uri.substring(bundle.uri.indexOf("/packages")), {responseType: 'arraybuffer'})
        .then((response) => {
            this.fileDownload(response.data, bundle.filename, "application/pdf");
        })
        .catch((error) => {
            this.props.toast('error', 'Could not download bundle.', 'Error');
        });
    }

    reprintPackage = () => {
        this.setState({ loaded: false });
        //check for completed alert workflows
        axios.put('/packages/' + this.props.packageID + '/Component/',
            {
                packageID: this.props.packageID,
                isReprint: true,
                checkReprintValidity: true
            }
        )
        .then((response) => {
            this.setState(
                { loaded: true },
                this.props.goToPackageReviewReprint
            );
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.props.toast('error', 'Cannot reprint package. An alert workflow associated with this package has already been completed.', 'Error');
        });
    }

    toggleConfirmFaxModal = () => {
        this.setState({ showConfirmFaxModal: !this.state.showConfirmFaxModal });
    }

    togglePackageDetailsModal = () => {
        this.setState({ showPackageDetailsModal: !this.state.showPackageDetailsModal });
    }
       
    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    renderPackageDetails = () => {
        return (
            <div>
                <h5><b>Include Blank Alerts:</b> {this.props.printOptions.includeBlankAlerts ? "Yes" : "No"}</h5>
                <h5><b>Include Headers:</b> {this.props.printOptions.includeHeaders ? "Yes" : "No"}</h5>
                <h5><b>Selected Reports:</b> {this.state.selectedReports.length === 0 ? "None" : ""}</h5>
                {
                    this.state.selectedReports.length > 0 ?
                    <ul>
                    {
                        this.state.selectedReports.sort((a,b) => {
                            return parseInt(a.order, 10)  - parseInt(b.order, 10);
                        }).map((item, index) => {
                            return <li key={index}>{item.packageComponentTypeName}</li>
                        })
                    }
                    </ul>
                    : ""
                }
            </div>
        );
    }

    renderAlertLink = () => {
        return (
            <h4 style={{ fontSize: '16px', marginTop: '0rem', marginLeft: '1.5rem' }}>
                Alert Package:
                { this.state.alertLoadingStatus !== "Complete" ? this.renderLinkLoading() : this.renderLink(this.state.alertBundle) }
                { this.state.alertLoadingStatus !== "Complete" || !this.state.faxEnabled ? '' : <Button onClick={() =>this.toggleConfirmFaxModal()}>Fax</Button> }
            </h4>
        );
    }

    renderReportLink = () => {
        return (
            <h4 style={{ fontSize: '16px' }}>
                Report Package:
                { this.state.reportLoadingStatus !== "Complete" ? this.renderLinkLoading() : this.renderLink(this.state.reportBundle) }
            </h4>
        );
    }

    renderLink = (bundle) => {
        if (!bundle.filename || !bundle.uri) {
            return (
                <div style={{ display: 'inline-block' }}>
                    <p style={{ display: 'inline', fontSize: '16px', marginLeft: '1rem' }}>Not available</p>
                    <Button style={{ visibility: 'hidden' }}>&nbsp;</Button>
                </div>
            );
        }
        else {
            return <Button style={{ marginLeft: '1rem' }} onClick={() => this.getPdf(bundle)}>Download</Button>
        }
    }

    renderLinkLoading = () => {
        return (
            <div style={{ display: 'inline-block', paddingLeft: '1rem' }}>
                Loading
                <Glyphicon className="spinning" glyph="cog" style={{ lineHeight: "14px", color: "#6c716c", marginLeft: '0.7rem', verticalAlign: 'text-top' }} />
                <Button style={{ visibility: 'hidden' }}>&nbsp;</Button>
            </div>
        );
    }

    renderPackageDetailsButton = () => {
        return <Button onClick={this.togglePackageDetailsModal} title="View package details" style={{ marginLeft: '1rem', padding: '2px 5px' }}><Glyphicon glyph="list-alt"/></Button>
    }

    renderReprintButton = () => {
        return <Button onClick={this.reprintPackage} title="Reprint package" style={{ marginLeft: '1rem', padding: '2px 5px' }}><Glyphicon glyph="repeat"/></Button>
    }

    formatCDIAlertWorkflowLink = (cdiAlertWorkflowID) => {
        return <a href={'/cdi/' + cdiAlertWorkflowID + '/viewonly?isPECAddAlert=true'}>{cdiAlertWorkflowID}</a>;
    }
    
    fileDownload = (data, filename, mime) => {
        const blob = new Blob([data], {type: mime || 'application/octet-stream'});
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were 
            // revoked by closing the blob for which they were created. 
            // These URLs will no longer resolve as the data backing 
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
        }
        else {
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);
            tempLink.setAttribute('target', '_blank');
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
    }

    render() { 
        const packageDetails = this.renderPackageDetails();
        const isPrePrint = this.props.packageType === "PrePrint";
        return (
            <div>
                <Row>
                    <Col sm={9}>
                        <h3 className="workflow-title">Package Review</h3>
                        { 
                            this.state.loaded &&
                            <WorkflowHeaderDetails title={"Package Review"} provider={this.props.provider.desc + ' (' + this.props.provider.id + ')'} practice={this.props.practice.desc}
                                deliveryDate={this.formatDateString(this.props.deliveryDate)} detailsButton={this.renderPackageDetailsButton()} reprintButton={this.renderReprintButton()}
                                packageType={this.props.packageType}/>
                        }
                    </Col>
                    <Col sm={3}>
                        {
                            this.state.loaded &&
                            <Panel style={{ border: 'none', boxShadow: 'none', float: 'right', marginBottom: '0px', marginTop: '1.4rem', minWidth: '240px' }}>
                                {this.renderAlertLink()}
                                {this.renderReportLink()}
                            </Panel>
                        }
                    </Col>
                </Row>
                <hr style={{ marginTop: '10px', marginBottom: '10px' }}/>
                <Loader loaded={this.state.loaded}>
                    <Row>
                        <Col sm={12}>
                            <h4 style={{ marginTop: '2rem', marginBottom: '1.4rem', marginRight: '0.2rem' }}>
                                <b>Alerts Created</b> - {this.state.createdAlerts ? this.state.createdAlerts.filter(a => a.cdiAlertWorkflowID > 0).length : 0} alerts for {this.props.memberCount} members
                            </h4>
                            <BootstrapTable data={this.state.createdAlerts} multiColumnSort={3} maxHeight="300px" scroll-top="Top" hover>
                                <TableHeaderColumn dataField='cdiAlertWorkflowID' isKey dataSort dataFormat={this.formatCDIAlertWorkflowLink}>Alert Workflow ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberName' dataSort>Member Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberID' dataSort>Member ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberDOB' dataFormat={this.formatDateString} dataSort>Member DOB</TableHeaderColumn>
                                <TableHeaderColumn dataField='assignedProvider' dataSort>Assigned Provider</TableHeaderColumn>
                                <TableHeaderColumn dataField='appointmentDatetime' dataFormat={this.formatDateString} dataSort hidden={isPrePrint}>Appt. Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='appointmentProvider' dataSort hidden={isPrePrint}>Appt. Provider</TableHeaderColumn>
                                <TableHeaderColumn dataField='altProviderLastName' dataSort hidden={isPrePrint}>Alt. Provider Last</TableHeaderColumn>
                                <TableHeaderColumn dataField='altProviderFirstName' dataSort hidden={isPrePrint}>Alt. Provider First</TableHeaderColumn>
                                <TableHeaderColumn dataField='medicalRecordNumber' dataSort>MRN</TableHeaderColumn>
                                <TableHeaderColumn dataField='alertLineCount' dataSort>Alert Line Count</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h4 style={{ marginTop: '4rem', marginRight: '0.2rem', marginBottom: '1.4rem' }}>
                                <b>Queries Included</b>
                            </h4>
                            <BootstrapTable data={this.state.packageQueryList} multiColumnSort={3} maxHeight="300px" scroll-top="Top" hover>
                                <TableHeaderColumn dataField='cdiAlertWorkflowID' isKey dataSort dataFormat={this.formatCDIAlertWorkflowLink}>Queried Alert Workflow ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberName' dataSort>Member Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberID' dataSort>Member ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='providerName' dataSort>Appt. Provider</TableHeaderColumn>
                                <TableHeaderColumn dataField='providerID' dataSort>Provider ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='appointmentDatetime' dataFormat={this.formatDateString} dataSort>Appt. Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='numberOfQueries' dataSort># of Query Lines</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                </Loader>
                <AlertDialog visible={this.state.showPackageDetailsModal} handleModalToggle={this.togglePackageDetailsModal} title={"Package Details"}
                    message={packageDetails} cancelLabel={"Close"} showConfirm={false} renderInnerDiv={true} />
                <AlertDialog visible={this.state.showConfirmFaxModal} handleModalToggle={this.toggleConfirmFaxModal} title={"Fax Alert Package"}
                    message={"Are you sure you want to fax this package?"} handleConfirm={() => this.faxPackage()}
                    confirmLabel={"Confirm"} cancelLabel={"Cancel"} confirmStyle={"success"} glyphicon={"ok"} />
            </div>
        );  
    }
}

export default PackageReview;
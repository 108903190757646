// usage: import { isUserEnteredDiagnosis } from '../../utils/DiagnosisUtils';
import { LineItemReviewStatus } from '../enums/LineItemReviewStatus';

const DiagnosisUtils = {
    isUserEnteredDiagnosis: isUserEnteredDiagnosis,
    isClaimsDiagnosis: isClaimsDiagnosis,
    isRiskMitigationDiagnosis: isRiskMitigationDiagnosis,
    isClaimsOrRiskMitigationDiagnosis: isClaimsOrRiskMitigationDiagnosis,
    isApproved: isApproved,
    isRejected: isRejected,
    isApprovedOrRejected: isApprovedOrRejected
}

const StarsUtils = {
    isApproved: isStarsApproved,
    isRejected: isStarsRejected,
    isApprovedOrRejected: isStarsApprovedOrRejected
}

// diagnosis helpers to identify initiator type
function isUserEnteredDiagnosis(diagnosis) {
    return diagnosis && diagnosis.initiatorTypeID === 1;
}

function isClaimsDiagnosis(diagnosis) {
    return diagnosis && diagnosis.initiatorTypeID === 2;
}

function isRiskMitigationDiagnosis(diagnosis) {
    return diagnosis && diagnosis.initiatorTypeID === 3;
}

function isClaimsOrRiskMitigationDiagnosis(diagnosis) {
    return (isClaimsDiagnosis(diagnosis) || isRiskMitigationDiagnosis(diagnosis));
}

// diagnosis helpers to identify if approved/rejected
function isApproved(capture, isComplete, isCoderQA, isMD) {
    if (isComplete) {
        return capture.diagnosisCDReviewStatusID === LineItemReviewStatus.Confirmed || capture.diagnosisCDReviewStatusID === LineItemReviewStatus.MDConfirmed || capture.postSubmitStatus === LineItemReviewStatus.CoderQAConfirmed;
    }
    if (isCoderQA) {
        return capture.postSubmitStatusID === LineItemReviewStatus.CoderQAConfirmed;
    }
    if (isMD) {
        return capture.diagnosisCDReviewStatusID === LineItemReviewStatus.MDConfirmed;
    }
    return capture.diagnosisCDReviewStatusID === LineItemReviewStatus.Confirmed;
};

function isRejected(capture, isComplete, isCoderQA, isMD) {
    if (isComplete) {
        return capture.diagnosisCDReviewStatusID === LineItemReviewStatus.MDRejected || capture.diagnosisCDReviewStatusID === LineItemReviewStatus.Rejected || capture.postSubmitStatus === LineItemReviewStatus.CoderQARejected;
    }
    if (isCoderQA) {
        return capture.postSubmitStatusID === LineItemReviewStatus.CoderQARejected;
    }
    if (isMD) {
        return capture.diagnosisCDReviewStatusID === LineItemReviewStatus.MDRejected;
    }
    return capture.diagnosisCDReviewStatusID === LineItemReviewStatus.Rejected;
};

function isApprovedOrRejected(capture, isComplete, isCoderQA, isMD) {
    return capture && (isApproved(capture, isComplete, isCoderQA, isMD) || isRejected(capture, isComplete, isCoderQA, isMD));
}

// ------------------- Stars utils ----------------------//
function isStarsApprovedOrRejected (capture) {
    return isStarsApproved(capture) || isStarsRejected(capture);
}

function isStarsApproved (capture) {
    return capture.lineItemReviewStatusID === LineItemReviewStatus.Confirmed || capture.lineItemReviewStatusID === LineItemReviewStatus.MDConfirmed;
}

function isStarsRejected (capture) {
    return capture.lineItemReviewStatusID === LineItemReviewStatus.Rejected || capture.lineItemReviewStatusID === LineItemReviewStatus.MDRejected;
}

export { DiagnosisUtils, StarsUtils };
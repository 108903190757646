import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import Select from 'react-select';

class WorkflowExceptionsAddLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exceptionReason: '',
            resolved: '',
            pecComments: ''
        }
    }

    handleExceptionReasonChange = (e) => {
        this.setState({ exceptionReason:  e ? e.value : '' });
    }

    handleResolvedChange = (e) => {
        this.setState({ resolved:  e ? e.value : '' });
    }

    handlePECCommentsChange = (e) => {
        this.setState({ pecComments: e.target.value });
    }

    handleSaveWorkflowException = () => {
        this.props.saveWorkflowException({
            CDIAlertWorkflowExceptionOptionID: this.state.exceptionReason,
            Addressed: this.state.resolved,
            CDIAlertWorkflowExceptionPECComment: this.state.pecComments
        });

        this.setState({
            exceptionReason: '',
            resolved: '',
            pecComments: ''
        });
    }

    render() {
        return (
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>Exception Reason</ControlLabel>
                        <Select value={this.state.exceptionReason} options={this.props.workflowExceptionOptions}
                            onChange={this.handleExceptionReasonChange} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Resolved</ControlLabel>
                        <Select value={this.state.resolved} options={this.props.resolvedOptions}
                            onChange={this.handleResolvedChange} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <ControlLabel>PEC Comments</ControlLabel>
                        <FormControl componentClass="textarea" maxLength="500" value={this.state.pecComments}
                            onChange={this.handlePECCommentsChange} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <FormGroup>
                        <ControlLabel>&nbsp;</ControlLabel>
                        <Button onClick={this.handleSaveWorkflowException} style={{ display: 'block' }}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

export default WorkflowExceptionsAddLine;
import React from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, Glyphicon, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import '../../styles/Report.css';
import AlertToastr from '../Shared/AlertToastr';
import { TableHeaderColumn } from 'react-bootstrap-table';
import AlertDialog from '../Shared/AlertDialog';
import FileDownload from 'js-file-download';

class RiskMitigationAuditDetailReport extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            projectList: [],
            //NOTE: Filtering by multiple projects possible with some work on the backend
            searchFilter: {
                projectIds: []
            },
            noResults: false,
            displayCSVDownloader: false,
            riskReports:[],
            showQueuedDialog:false,
            queuedMessage:'',
            loading: false,
            mimeType: '',
            documentFileData: null,            
            toast: null
        };
        this.sizePerPageList = [10, 25, 50, 100, 250]
            .map(item => { return { text: item.toString(), value: item } });        
    }    

    componentWillMount() {
        Promise.all([
            this.getProjectsForDropdown()
        ])
        .catch((error) => {
            this.state.toast('error', 'Could not load project options.', 'Error');
        });
        this.getRiskSummary();
    }

    getProjectsForDropdown = () => {
        return axios.get('/applicationreports/1/riskprojects')
            .then((response) => {
                this.setState({
                    projectList: response.data.map((proj) => {
                        return {
                            label: proj.projectName,
                            value: proj.projectID
                        };
                    })
                });
            });
    }

    getRiskSummary = () => {
        return axios.get(`/Reports/RiskMitigationReport/25`)
            .then((response) => {
                this.setState({
                    riskReports : response.data
                })
            })
            .catch((error) => {
                this.setState({
                    riskReports: []
                });
            })     
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    downloadDocument = (filename, applicationReportDownloadRequestID) => {
        this.setState({ loading: true });
        axios.get(`/Reports/DownloadFile/${applicationReportDownloadRequestID}/file`, {
            responseType: 'arraybuffer'
        })
        .then((response) => {
            const extension = filename.split('.').pop();
            this.setState({
                mimeType: response.headers['content-type'],
                documentFileData: URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] })),
                loading: false
            });
            if (extension !== 'pdf') {
                FileDownload(response.data, filename)
            }
        })
        .catch((error) => {
            this.setState({
                showDocumentError: true,
                loading: false
            });
        });
    }

    handleProjectChange = (options) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                projectIds: options.map(item => item.value),
            },
        });
    }

    formatActions = (cell, row) => {
        return (
            <Button onClick={() => this.downloadDocument(row.fileName,row.applicationReportDownloadRequestID)} disabled={!row.isEnabled}>
                Download
            </Button>
        )        
    }

    formatCriteria = (cell,row) => {
        return (
            <p>{row.reportCriteria}</p>
        )
    }

    generateReport = () => {
        const params = {
            projectIds: this.state.searchFilter.projectIds
        }
        axios.post('/Reports/GenerateRiskAuditDetailReport', params)
        .then((response) => {
            this.setState({showQueuedDialog : true, queuedMessage: response.data},
                () => {this.getRiskSummary();});
        })
        .catch((error) => {
            this.state.toast('error', 'Could not queue report generation request', 'Error');
        });        
    }    

    toggleQueuedModal = () => {
        this.setState({ 
            showQueuedDialog: !this.state.showQueuedDialog
        });
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPageList: this.sizePerPageList,
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                });
            }
        };

        return (
            <div className='reportContainer'>
                <div className='innerReportContainer'>
                    <Grid fluid>
                        <Row style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            <Col>
                                <h3>Risk Mitigation Audit Detail Report</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} lg={5}>
                                <FormGroup>
                                    <ControlLabel>Project</ControlLabel>
                                    <br />
                                    <Select multi placeholder="All Projects" value={this.state.searchFilter.projectIds} options={this.state.projectList} autosize={true}
                                        onChange={this.handleProjectChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={1} lg={1}>
                                <ControlLabel>&nbsp;</ControlLabel>
                                <Button onClick={this.generateReport}>
                                    <Glyphicon glyph="download" style={{ 'marginRight': '0.2rem' }} /> Generate
                                </Button>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                            <BootstrapTable data={this.state.riskReports} options={options} pagination maxHeight="420px">
                                <TableHeaderColumn dataField="applicationReportDownloadRequestID" isKey>Report Id</TableHeaderColumn>
                                <TableHeaderColumn dataField="fileStatus">File Generated Status</TableHeaderColumn>
                                <TableHeaderColumn dataField="reportCriteria" dataFormat={this.formatCriteria}>Report Criteria</TableHeaderColumn>
                                <TableHeaderColumn dataField="requestedBy">Requested By</TableHeaderColumn>
                                <TableHeaderColumn dataField="requestedDate">Requested Date</TableHeaderColumn>
                                <TableHeaderColumn dataField="action" dataFormat={this.formatActions}>Action</TableHeaderColumn>
                            </BootstrapTable>
                        </Row>
                    </Grid>
                </div>
                <AlertDialog visible={this.state.showQueuedDialog} handleModalToggle={this.toggleQueuedModal}
                        title={`Report Queue`} message={this.state.queuedMessage} cancelLabel={`Cancel`}
                        renderBody={false} handleConfirm={this.toggleQueuedModal} confirmLabel={"Ok"}
                        confirmStyle={"success"} glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }

}
export default RiskMitigationAuditDetailReport;
import React from "react";
import axios from 'axios';
import { Col, Form, FormGroup, FormControl, ControlLabel, Checkbox } from 'react-bootstrap';

class AlternateChase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedReasonDesc: props.enrolleeInfo.alternateChaseDesc,
            altChaseChecked: props.enrolleeInfo.alternateChaseDesc !== null,
            altChaseReasons: [],
            options: []    
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedReasonDesc: nextProps.enrolleeInfo.alternateChaseDesc,
            altChaseChecked: nextProps.enrolleeInfo.alternateChaseDesc !== null
        });
    }

    componentWillMount() {
        axios.get('/AlternateChaseReasons/')
        .then((response) => {
            this.setState({
                altChaseReasons: response.data,
                options: [(<option key="0" value="select">Select</option>), ...response.data.map(x => {
                    return (<option key={x.alternateChaseReasonID}
                        value={x.description}>{x.description}</option>);
                })]
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Failed to load alternate chase reasons.');
        });
    }

    handleAltChaseClick = () => {
        this.setState({
             altChaseChecked: !this.state.altChaseChecked
        }, () => {
            this.handleReasonClick();
        });
    }

    handleReasonClick = (e) => {
        let selectedReason;
        if (e) {
            const selectedDesc = this.state.options.find(x => parseInt(x.key, 10) === e.target.selectedIndex).props.value;
            selectedReason = this.state.altChaseReasons.find(x => x.description === selectedDesc);
            this.setState({ selectedReasonDesc: selectedReason ? selectedReason.description : 'select' });
        }

        if (this.props.enrolleeInfo.radvAuditEnrolleeID && ((this.state.altChaseChecked && selectedReason) || !this.state.altChaseChecked)) {
            axios.post(`/RADVAuditEnrollees/${this.props.enrolleeInfo.radvAuditEnrolleeID}`, {
                radvAuditEnrolleeID: this.props.enrolleeInfo.radvAuditEnrolleeID,
                alternateChaseReasonID: selectedReason ? selectedReason.alternateChaseReasonID : null,
                isAltChaseChecked: this.state.altChaseChecked
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to save alternate chase selection.');
            });
        }
    }

    render() {
        const displayAltChase = this.state.altChaseChecked ? "visible" : "hidden";
        return (
            <div>
                <Col>
                    <Form>
                        <FormGroup>
                            <Checkbox checked={this.state.altChaseChecked} onChange={this.handleAltChaseClick}>Alternate Chase Needed</Checkbox>
                        </FormGroup>
                        <FormGroup controlId="formControlsSelect" style={{visibility: displayAltChase}}>
                            <ControlLabel>Alternate Chase Reason</ControlLabel>
                            <FormControl componentClass="select" placeholder="select" value={this.state.selectedReasonDesc ? this.state.selectedReasonDesc : ''} onChange={this.handleReasonClick}>
                                {this.state.options}
                            </FormControl>
                        </FormGroup>
                    </Form>
                </Col>
            </div>
        )
    }
}

export default AlternateChase;
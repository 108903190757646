// exports CDIAlertWorkflowStatus - an immutable object 'enum'
// usage: import { CDIAlertWorkflowStatus } from '../../enums/CDIAlertWorkflowStatus';
const cdiAlertWorkflowStatus = {
    Cancelled: -100,
    Created: 1,
    QueryInitiated: 25,
    Captured: 50,
    Completed: 100,
    ReadyForMRR: 125,
    ReviewInProgress: 150,
    Exception: 175
};
Object.freeze(cdiAlertWorkflowStatus);
export const CDIAlertWorkflowStatus = cdiAlertWorkflowStatus;
// usage: import { Validator } from '../../utils/ValidationUtils';
function requiredFieldValidation(value, id, field, fieldLabel, errorText = "is required") {
    return !value ? [{ id, field, errorText: `${fieldLabel} ${errorText}` }] : [];
}

function requiredFieldObjectValidation(objectVal, objectIDPropName, id, field, fieldLabel, errorText = "is required") {
    return !objectVal || (objectVal && !objectVal[objectIDPropName]) ? [{ id, field, errorText: `${fieldLabel} ${errorText}` }] : [];
}

function combinedPageNumValidations(pages) {
    let pageFrom, pageTo;
    if (pages.indexOf('-') !== -1) {
        const splitPages = pages.split('-');
        pageFrom = splitPages[0];
        pageTo = splitPages[1];
    }
    else {
        pageFrom = '1';
        pageTo = pages;
    }

    if (isNaN(pageFrom) || isNaN(pageTo) || pageFrom.includes('.') || pageTo.includes('.')) {
        return [{ id: 'nonIntegerPages', field: "pages", errorText: "Page values must be an integer" }];
    }
    else {
        const parsedFrom = parseInt(pageFrom, 10);
        const parsedTo = parseInt(pageTo, 10);
        if (parsedFrom < 1 || parsedTo < 1) {
            return [{ id: 'lessThanZeroPages', field: "pages", errorText: "Page values must be greater than zero" }];
        }
        else if (pageFrom > 32767 || pageTo > 32767)
        {
            return [{ id:'tooLargePageNumber', field: "pages", errorText: "Page values must be smaller than 32768" }];
        }
        else if (parsedTo < parsedFrom) {
            return [{ id: 'badRangePages', field: "pages", errorText: "Page From cannot be larger than Page To" }];
        }
        else {
            return [];
        }
    }
}

function dateOfServiceValidation(fieldID, dosRangeStartLabel, dosRangeStart, dosRangeEndLabel = 'DOS Thru', dosRangeEnd = null, dosRangeRequired = false, minDate = null, memberDOB = null) {
    const errorArr = [];
    const today = new Date((new Date()).setHours(0,0,0,0));
    dosRangeStart = dosRangeStart ? new Date((new Date(dosRangeStart)).setHours(0,0,0,0)) : null;
    dosRangeEnd = dosRangeEnd ? new Date((new Date(dosRangeEnd)).setHours(0,0,0,0)) : null;
    minDate = minDate ? new Date((new Date(minDate)).setHours(0,0,0,0)) : null;
    memberDOB = memberDOB ? new Date((new Date(memberDOB)).setHours(0,0,0,0)) : null;

    //dos start
    if (!dosRangeStart || isNaN(dosRangeStart.getDate())) {
        errorArr.push({ id: `invalidDOSStart`, field: fieldID, errorText: `Please enter a valid MM/DD/YYYY ${dosRangeStartLabel}` });
    }
    else {
        if (dosRangeStart.getTime() > today.getTime()) {
            errorArr.push({ id: `futureDOSStart`, field: fieldID, errorText: `${dosRangeStartLabel} cannot be a future date` });
        }
        else if (minDate && dosRangeStart.getTime() < minDate.getTime()) {
            errorArr.push({ id: `dosStartBeforeMin`, field: fieldID, errorText: `${dosRangeStartLabel} is earlier than ${minDate.toLocaleDateString('en-US')}` });
        }
        if (memberDOB && dosRangeStart.getTime() < memberDOB.getTime()) {
            errorArr.push({ id: 'dosBeforeMemberDOB', field: fieldID, errorText: `${dosRangeStartLabel} must be after the member's DOB` });
        }
    }

    //dos end
    if (dosRangeRequired) {
        if (!dosRangeEnd || isNaN(dosRangeEnd.getDate())) {
            errorArr.push({ id: `invalidDOSEnd`, field: fieldID, errorText: `Please enter a valid MM/DD/YYYY ${dosRangeEndLabel}` });
        }
        else if (!dosRangeEnd || dosRangeEnd.getTime() > today.getTime()) {
            errorArr.push({ id: `futureDOSEnd`, field: fieldID, errorText: `${dosRangeEndLabel} cannot be a future date` });
        }
        //range
        if (dosRangeStart && dosRangeEnd && dosRangeStart.getTime() > dosRangeEnd.getTime()) {
            errorArr.push({ id: `DOSEndBeforeDOSStart`, field: fieldID, errorText: `${dosRangeEndLabel} must be after ${dosRangeStartLabel}` });
        }
    }
    
    if (minDate && dosRangeEnd && dosRangeEnd.getTime() < minDate.getTime()) {
        errorArr.push({ id: `dosEndBeforeMin`, field: fieldID, errorText: `${dosRangeEndLabel} is earlier than ${minDate.toLocaleDateString('en-US')}` });
    }

    return errorArr;
}

function rejectionReasonValidation(comments) {
    return comments && comments.filter(x => {
        return x.isChecked;
    }).length === 0 ? [{ id: 'invalidRejectionReasons', field: "rejectionComments", errorText: "You must select a rejection reason in order to reject the diagnosis" }]
        : [];
}

export { requiredFieldValidation, requiredFieldObjectValidation, combinedPageNumValidations, dateOfServiceValidation, rejectionReasonValidation };
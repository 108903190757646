import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const ContactInfoTable = (props) => {
    const handleRemoveContact = (cell, row) => {
        props.handleRemoveContact(row)
    }

    const formatActions = (cell, row) => {
        return (
            <Button onClick={() => handleRemoveContact(cell, row)} disabled={props.readOnly}>
                <Glyphicon glyph="trash" />
            </Button>
        )
    }

    return (
        <BootstrapTable data={props.contactInfo} maxHeight="516px" scrollTop={"Top"}
            hover pagination>
            <TableHeaderColumn dataField="campaignEMRInstanceContactID" isKey hidden></TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataAlign="left" dataSort>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="title" dataAlign="left" dataSort>Title</TableHeaderColumn>
            <TableHeaderColumn dataField="phone" dataAlign="left" dataSort>Phone</TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataAlign="left" dataSort>Email</TableHeaderColumn>
            <TableHeaderColumn dataField="helpDeskPhone" dataAlign="left" dataSort>Help Desk Phone</TableHeaderColumn>
            <TableHeaderColumn dataField="notes" dataAlign="left" dataSort>Notes</TableHeaderColumn>
            <TableHeaderColumn dataField="actions" dataAlign="left" dataFormat={formatActions} dataSort width="4%"></TableHeaderColumn>
        </BootstrapTable>
    );
}

export default ContactInfoTable;
import React from 'react';
import axios from 'axios';
import { Tabs, Tab, Grid } from 'react-bootstrap';
import AutomationConfig from './AutomationConfig';
import CoderAssignment from './CoderAssignment';
import ProjectPriority from './ProjectPriority';
import CampaignDetailTable from './CampaignDetailTable';
import { Navbar, Button, Row, Col } from 'react-bootstrap';
import AlertDialog from '../../../Shared/AlertDialog';
import AlertToastr from '../../../Shared/AlertToastr';
import { CampaignStatuses } from '../../../../enums/CampaignStatus';
import { getRedirect } from '../../../../services/ReviewProcessing';

class CampaignDetailContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            showConfirmationModal: false,
            showButtonBar: false,
            disableProjectPriorityButtons: false,
            campaignProject: [],
            projects: [],
            campaignDetailSummary: {},
            toast: null,
            }
    }

     

    componentDidMount() {
        Promise.all([
            this.getCampaignProjectByCampaignID(),
            this.getProjects(),
            this.getCampaignDetailSummary()
        ])
        .then((responses) => {
            this.setState({
               campaignProject: responses[0].data,
               projects: responses[1].data,
               campaignDetailSummary: responses[2].data
            });

            this.handleButtonBarToggle();
        })
        .catch((error) => {
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else if (error.response.status !== 404) {
                this.setState({ loaded: true });
                this.state.toast('error', 'Could not load Campaign Detail data.', 'Error');
            }
        });
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    getCampaignProjectByCampaignID() {
        return axios.get(`/campaignProject/getCampaignProjectByCampaignID?campaignID=${this.props.router.params.campaignID}`);
    }

    getCampaignDetailSummary() {
        return axios.get(`/campaignCoderAssignment/getCampaignProjectSummary/${this.props.router.params.campaignID}`);
    }

    getProjects() {
        return axios.get(`/projects?includeInactive=true`);
    }

    getDisplayName = (campaignName, campaignStatus) => {
        let statusDisplayName = "";
        if(campaignStatus==="New")
            statusDisplayName = " - Initial Setup"

        return "Campaign Detail - " + campaignName + statusDisplayName;
    }

    handleBackButton = () => {
        if(this.state.activeTab>1)
        {
            this.setState({
                activeTab: this.state.activeTab-1
            });
        }
            
    }
    handleNextButton = () => {
        if(this.state.activeTab<3)
        {
            this.setState({
                activeTab: this.state.activeTab+1
            });
        }
    }
    handleFinishedButton = () => {
        //TODO: Possible Validations
        this.toggleConfirmationModal();    
    }

    isNextButtonHidden = () => {
        return this.state.activeTab===3 ? true : false;
    }

    handleSelect = (eventKey) => {
        this.setState({
            activeTab: eventKey
        });
    }

    displayNextOrFinishedButton = () => {
        return(
        //Could we dynamically get a count of tabs? 
        this.state.activeTab === 3 ?
            (
            <Button
            onClick={this.handleFinishedButton}
            bsStyle="success" style={{ float: 'right', marginTop: '0.7rem' }}>
                Finished
            </Button>
            ) :
            (
                <Button 
                onClick={this.handleNextButton} 
                bsStyle="primary" style={{ float: 'right', marginTop: '0.7rem' }}>
                    Next
                </Button>
            )
        );
    }

    toggleConfirmationModal = () => {
        this.setState({ showConfirmationModal: !this.state.showConfirmationModal });
    }

    handleConfirmationClick = () => {
        //TODO: Save data
        let campaignDetail = this.state.campaignDetailSummary;
        campaignDetail.campaignStatus = "confirmed";
        axios.put(`/campaignAutomationConfig/${this.props.router.params.campaignID}`,{
            campaignStatusID: CampaignStatuses.Ready
        });
        this.setState({
            campaignDetailSummary: campaignDetail
        })
        this.toggleConfirmationModal();
        this.handleButtonBarToggle();  
    }
    handleButtonBarToggle = () => {
        let status = this.state.campaignDetailSummary.campaignStatus === 'New' ? true : false;
        this.setState({
            showButtonBar: status
        });
    }
    displayBackButton = () => {
        return (
            this.state.activeTab > 1 ?
            <Button 
                onClick={this.handleBackButton} 
                bsStyle="primary" style={{ float: 'left', marginTop: '0.7rem' }}>
                Back
            </Button> 
            : null
        )
    }

    displayButtonBar = () => {
        return(
        this.state.showButtonBar?
        (
        <Navbar fixedBottom fluid>
            {this.displayBackButton()}
            {this.displayNextOrFinishedButton()}
        </Navbar>
        ): null);
    }

    handleUpdatePriorityAddOne = (row) => {
        this.setState({
            disableProjectPriorityButtons: true
        });
        axios.put(`/campaignProject/updatePriorityAddOne?campaignProjectID=${row.campaignProjectID}`)
        .then(() => {
            this.resetCampaignProject();
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update Campaign Detail data.', 'Error');
            this.setState({
                disableProjectPriorityButtons: false
            });
        });
    }
    handleUpdatePrioritySubtractOne = (row) => {
        this.setState({
            disableProjectPriorityButtons: true
        });
        axios.put(`/campaignProject/updatePrioritySubtractOne?campaignProjectID=${row.campaignProjectID}`)
        .then(() => {
            this.resetCampaignProject();
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update Campaign Detail data.', 'Error');
            this.setState({
                disableProjectPriorityButtons: false
            });
        });
    }
    handleUpdatePriorityToOne = (row) => {
        this.setState({
            disableProjectPriorityButtons: true
        });
        axios.put(`/campaignProject/updatePriorityToOne?campaignProjectID=${row.campaignProjectID}`)
        .then(() => {
            this.resetCampaignProject();
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update Campaign Detail data.', 'Error');
            this.setState({
                disableProjectPriorityButtons: false
            });
        });
    }
    resetCampaignProject = () =>
    {
        this.getCampaignProjectByCampaignID()
        .then((responses) => {
            this.setState({
                campaignProject: responses.data,
                disableProjectPriorityButtons: false
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update Campaign Detail data.', 'Error');
            this.setState({
                disableProjectPriorityButtons: false
            });
        });
    }

    handleBackCampaignManagementClick = () => {
        this.props.router.push(`/CampaignManagement`);
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={8} style={{ paddingLeft: '0rem' }}>
                        <h3>{this.getDisplayName(this.state.campaignDetailSummary.campaignName, this.state.campaignDetailSummary.campaignStatus )}</h3>
                    </Col>
                    <Col xs={4} style={{ paddingTop: '1rem', paddingRight: '0' }}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={this.handleBackCampaignManagementClick} style={{ marginRight: '1rem' }}>
                                Campaign Summary
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <CampaignDetailTable summaryDetail={this.state.campaignDetailSummary}/>
                </Row>
                <Row>
                    <Tabs id="campaignDetail-tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                        <Tab eventKey={1} title="Automation Config">
                            <AutomationConfig campaignID={this.props.router.params.campaignID} toast={this.state.toast} />
                        </Tab>
                        <Tab eventKey={2} title="Coder Assignment">
                            <CoderAssignment 
                                campaignID={this.props.router.params.campaignID} toast={this.state.toast}/>
                        </Tab>
                        <Tab eventKey={3} title="Project Priority">
                            <ProjectPriority data={this.state.campaignProject} projects={this.state.projects} 
                            handleUpdatePriorityAddOne={this.handleUpdatePriorityAddOne} handleUpdatePrioritySubtractOne={this.handleUpdatePrioritySubtractOne}
                            handleUpdatePriorityToOne={this.handleUpdatePriorityToOne}
                            disableProjectPriorityButtons={this.state.disableProjectPriorityButtons}/>
                        </Tab>
                    </Tabs>
                </Row>
                {this.displayButtonBar()}
                <AlertDialog visible={this.state.showConfirmationModal} handleModalToggle={this.toggleConfirmationModal}
                title={`Save Campaign details?`} renderBody={false} handleConfirm={this.handleConfirmationClick} 
                confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        )
    }
}

export default CampaignDetailContainer;
import React from 'react';
import axios from 'axios';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import CDIReviewSearch from './CDIReviewSearch';
import ProspectiveSearch from './ProspectiveSearch';
import ProspectiveTable from './ProspectiveTable';
import RetroSearch from './RetroSearch';
import { MedicalRecordType } from '../../../enums/MedicalRecordType';
import { SessionParamCache } from '../../../utils/SessionUtils';

class InitialReview extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = 'initialSearch';
        this.savedParams = this.paramCache.getParamObj(this.paramCacheKey);

        this.state = {
            project: this.savedParams.project ? this.savedParams.project : '',
            reviewTypes: [],
            recordTypes: [],
            recordEntityTypes: [],
            reviewType: this.savedParams.reviewType ? this.savedParams.reviewType : '',
            memberMedicalRecords: [],
            firstName: '',
            lastName: '',
            planMemberID: '',
            cdiReviews: [],
            cdiTotalItems: 0,
            clearProspectiveTableSelected: false,
            waitingOnSearch: false
        };
    }

    componentDidMount() {
        if (this.savedParams.project && this.savedParams.project.value &&
            this.props.projects.find(x => x.projectID === this.savedParams.project.value)) {
            this.getReferenceData();
        }
        else {
            this.setState({ project: '' });
        }
    }

    //todo: retroReviewType data doesn't seem to be used -- remove if so
    getReviewTypes = () => {
        let url = this.state.project.containsPrePopulatedReviews ?
            `/projects/${this.state.project.value}/referenceData?referenceDataType=retroReviewType`
            : `/projects/${this.state.project.value}/referenceData?referenceDataType=reviewType`;
        return axios.get(url);
    }

    getRecordTypes = () => {
        return axios.get(`/projects/${this.state.project.value}/referenceData?referenceDataType=medicalRecordType`);
    }

    getRecordEntityTypes = () => {
        return axios.get(`/projects/${this.state.project.value}/referenceData?referenceDataType=medicalRecordEntityType`);
    }

    getReferenceData = () => {
        return this.state.project ?
            Promise.all([this.getReviewTypes(), this.getRecordTypes(), this.getRecordEntityTypes()])
            .then((responses) => {
                let reviewTypes = [];
                if (responses[0].data && responses[0].data.referenceDataList && responses[0].data.referenceDataList[0]
                    && responses[0].data.referenceDataList[0].referenceData) {
                    reviewTypes = responses[0].data.referenceDataList[0].referenceData;
                }

                let recordTypes = [];
                if (responses[1].data && responses[1].data.referenceDataList && responses[1].data.referenceDataList[0]
                    && responses[1].data.referenceDataList[0].referenceData) {
                    recordTypes = responses[1].data.referenceDataList[0].referenceData;
                }

                let recordEntityTypes = [];
                if (responses[2].data && responses[2].data.referenceDataList && responses[2].data.referenceDataList[0]
                    && responses[2].data.referenceDataList[0].referenceData) {
                    recordEntityTypes = responses[2].data.referenceDataList[0].referenceData;
                }

                //reset the review type if it isn't an available option for the current project selection
                if (this.state.reviewType && !reviewTypes.map(x => x.value).includes(this.state.reviewType.value)) {
                    this.setState({ reviewType: '' });
                }

                this.setState({
                    reviewTypes,
                    recordTypes,
                    recordEntityTypes
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load search filters.', 'Error');
            }) : null;
    }

    handleProjectChange = (e) => {
        this.setState({ project: e }, this.getReferenceData);
    }

    handleReviewTypeChange = (e) => {
        this.setState({ reviewType: e });
    }

    handleClearMemberMedicalRecords = () => {
        this.setState({ memberMedicalRecords: [] });
    }

    handleSetSelectedMemberReview = (medicalRecordEntityID, selected) => {
        const records = [...this.state.memberMedicalRecords];
        const target = records.find(x => x.medicalRecordEntityID === medicalRecordEntityID);
        target.selected = selected;
        this.setState({ memberMedicalRecords: records });
    }

    handleSetAllSelectedMemberReviews = (medicalRecordEntityIDs, selected) => {
        const records = [...this.state.memberMedicalRecords];
        let target;
        medicalRecordEntityIDs.forEach(x => {
            target = records.find(y => y.medicalRecordEntityID === x);
            target.selected = selected;
        })
        this.setState({ memberMedicalRecords: records });
    }

    //note: 1. legacy version is supplying ~10 params to an endpoint that takes three. 2. moved recordEntityType filter to controller.
    //todo: record type is not currently in use -- drives action availability. should it be removed from the params passed from ProspectiveSearch?
    handleProspectiveSearch = (queryParams, cacheParams) => {
        if (queryParams.planMemberID) {
            this.setState({ waitingOnSearch: true });

            axios.get("/memberMedicalRecord", {
                params: {
                    projectID: this.state.project.value,
                    ...queryParams
                    //todo: why is this not used here?
                    //reviewTypeID: this.state.reviewType ? this.state.reviewType.value : '',
                }
            })
            .then((response) => {
                this.paramCache.addParamObj(this.paramCacheKey, {
                    project: this.state.project,
                    reviewType: this.state.reviewType
                });
                this.paramCache.addParamObj('prospectiveSearch', cacheParams);
                if (response.data && response.data.medicalRecords.length > 0) {
                    this.setState({
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        planMemberID: response.data.planMemberID,
                        memberMedicalRecords: response.data.medicalRecords,
                        clearProspectiveTableSelected: true,
                        waitingOnSearch: false
                    });
                }
                else
                {
                    this.setState({
                        firstName: '',
                        lastName: '',
                        planMemberID: '',
                        memberMedicalRecords: [],
                        clearProspectiveTableSelected: false,
                        waitingOnSearch: false
                    });
                    this.props.toast('warning', "There are no records available.", '');
                }
            })
            .catch((error) => {
                this.setState({ waitingOnSearch: false });

                if (error && error.response && error.response.data && error.response.data.message) {
                    this.props.toast('error', error.response.data.message, 'Error');
                }
                else {
                    this.props.toast('error', "Could not search medical records.", 'Error');
                }
            });
        }
        else {
            this.props.toast('error', "Member ID is required.", 'Error');
        }
    }

    getProjectDropdownOptions = (projects) => {
        return projects.map((proj) => {
            return {
                label: proj.projectName,
                value: proj.projectID,
                isRADV: proj.isRADV,
                containsPrePopulatedReviews: proj.containsPrePopulatedReviews,
                projectRetrieveTypeID: proj.projectRetrieveTypeID,
                projectProgramIDs: proj.programs
            };
        });
    }

    getDropdownOptions = (referenceData) => {
        return referenceData.map((x) => {
            return {
                label: x.description,
                value: x.value
            };
        });
    }

    setClearProspectiveTableSelected = () => {
        this.setState({ clearProspectiveTableSelected: !this.state.clearProspectiveTableSelected });
    }

    projectContainsPrePopulatedReviews = () => {
        return this.state.project && this.state.project.containsPrePopulatedReviews;
    }

    radvProjectSelected = () => {
        return this.state.project && this.state.project.isRADV;
    }

    //todo: 1. determine whether pre2017DateSet is used/needed
    cdiReviewTypeSelected = () => {
        return this.state.project && this.state.reviewType && this.state.reviewType.value === MedicalRecordType.CDIAlertReview;  //&& !search.pre2017DateSet;
    }

    renderSearch = (containsPrePopulatedReviews) => {
        if (containsPrePopulatedReviews) {
            return (<RetroSearch recordTypes={this.state.recordTypes} getDropdownOptions={this.getDropdownOptions} project={this.state.project}
                reviewType={this.state.reviewType} toast={this.props.toast} nlpProcessEnabledValue = {this.props.nlpProcessEnabledValue}
                newNLPScreenEnabled={this.props.newNLPScreenEnabled} maLobNlpProcessEnabled={this.props.maLobNlpProcessEnabled} 
                commLobNlpProcessEnabled={this.props.commLobNlpProcessEnabled} />)
        }
        else if (this.cdiReviewTypeSelected()) {
            return (
                <CDIReviewSearch providers={this.props.providers} practices={this.props.practices} users={this.props.users}
                    handleSearch={this.handleCDISearch} project={this.state.project} reviewType={this.state.reviewType}
                    toast={this.props.toast} />
            )
        }
        else {
            return (
                <div>
                    <ProspectiveSearch recordTypes={this.state.recordTypes} recordEntityTypes={this.state.recordEntityTypes}
                        handleSearch={this.handleProspectiveSearch} getDropdownOptions={this.getDropdownOptions} project={this.state.project}
                        memberMedicalRecords={this.state.memberMedicalRecords} clearMemberMedicalRecords={this.handleClearMemberMedicalRecords}
                        reviewType={this.state.reviewType} requireProviderIDForRecordTypeEMR={this.props.requireProviderIDForRecordTypeEMR}
                        waitingOnSearch={this.state.waitingOnSearch} toast={this.props.toast} />
                    <ProspectiveTable memberMedicalRecords={this.state.memberMedicalRecords} firstName={this.state.firstName} lastName={this.state.lastName}
                        planMemberID={this.state.planMemberID} onRowSelect={this.handleSetSelectedMemberReview} onSelectAll={this.handleSetAllSelectedMemberReviews}
                        setClearProspectiveTableSelected={this.setClearProspectiveTableSelected}
                        clearProspectiveTableSelected={this.state.clearProspectiveTableSelected} />
                </div>
            )
        }
    }

    render() {
        const containsPrePopulatedReviews = this.projectContainsPrePopulatedReviews();
        return (
            <div style={{ marginTop: '2rem' }}>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel className="requiredField">Project</ControlLabel>
                            <Select value={this.state.project ? this.state.project.value : ''} options={this.getProjectDropdownOptions(this.props.projects)}
                                onChange={this.handleProjectChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                    {
                        !containsPrePopulatedReviews &&
                        <FormGroup>
                            <ControlLabel>
                                Review Type
                            </ControlLabel>
                            <Select value={this.state.reviewType} options={this.getDropdownOptions(this.state.reviewTypes)}
                                onChange={this.handleReviewTypeChange} />
                        </FormGroup>
                    }
                    </Col>
                </Row>
                {
                    this.renderSearch(containsPrePopulatedReviews)
                }
            </div>
        );
    }
}

export default InitialReview;
import React from 'react';
import { Col, Panel, Table } from 'react-bootstrap';

//todo: this can be combined with EnrolleeRADV
class SubmittedEnrolleeRADV extends React.Component {
    render() {
        return (
            <Col xs={12} md={9}>
                <Panel className="enrolleePanel">
                    <h5> Enrollee RADV Information </h5>
                    <Table striped bordered className="enrolleeTable">
                        <thead>
                            <tr>
                                <th>Enrollee ID</th>
                                <th>Beneficiary ID</th>
                                <th>Enrollee Name</th>
                                <th>Enrollee DOB</th>
                                <th>Current HMOID</th>
                                <th>Sample Year HMOID</th>
                                <th>CMS Targeted HCC(s)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.enrolleeInfo &&
                                <tr>
                                    <td>{this.props.enrolleeInfo.enrolleeID}</td>
                                    <td>{this.props.enrolleeInfo.beneficiaryID}</td>
                                    <td>{this.props.enrolleeInfo.enrolleeName}</td>
                                    <td>{this.props.enrolleeInfo.enrolleeDOB !== undefined ? new Date(this.props.enrolleeInfo.enrolleeDOB).toLocaleDateString('en-US') : ""}</td>
                                    <td>{this.props.enrolleeInfo.currentHMOID}</td>
                                    <td>{this.props.enrolleeInfo.sampleYearHMOID}</td>
                                    <td>{this.props.enrolleeInfo.cmsTargetedHCCs !== undefined ? this.props.enrolleeInfo.cmsTargetedHCCs.join(", ") : ""}</td>
                                    <td>{this.props.enrolleeInfo.statusDesc}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Panel>
            </Col>
        );
    }
}

export default SubmittedEnrolleeRADV;
import React from 'react';
import axios from 'axios';
import AlertDialog from '../../../Shared/AlertDialog';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, Col, Row, Glyphicon, Form, FormControl, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import { UserRole } from '../../../../enums/UserRole';
import CoderQAScoringReport from '../../../Reports/CoderQAScoringReport';

class CoderAssignment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allCoderDetails: [],
            coderListOptions: [],
            campaignCoderAssignment: [],
            selectedCoder: '',
            toBeDeletedCampaignCoderAssignmentID: '',
            showDeleteConfirmModal: false
        }
    }

    componentDidMount() {
        this.getCampaignCoderAssignment();
        this.getCoderDetails();
    }

    getCoderDetails = () => {
        let roles = [UserRole.L1Coder, UserRole.L2Coder]
        axios.get('/MerlinUser/userRoleInfo', { 
            params: { ids: roles }
        })
        .then((response) => {
            this.setState({
                allCoderDetails: response.data,
                coderListOptions: response.data.map((x) => {
                    return {
                        label: x.fullName,
                        value: x.userID
                    }
                }),
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not get coder data', 'Error');
        })
    }

    getCampaignCoderAssignment = () => {
        axios.get(`/campaignCoderAssignment/${this.props.campaignID}`)
        .then((response) => {
            this.setState({
                campaignCoderAssignment: response.data
            })         
        })
        .catch((error) => {
            if(error.response.status === 404) {
                this.setState({
                    campaignCoderAssignment: []
                });
            } else {
                this.props.toast('error', 'Could not get campaign coder assignment', 'Error');
            }
        })
    }

    updatePauseStatus = (row) => {
        axios.put('/campaignCoderAssignment', {
            CampaignCoderAssignmentID: row.campaignCoderAssignmentID,
            UserID: row.userID,
            CampaignID: row.campaignID,
            IsPaused: !row.isPaused
        })
        .then((response) => {
            this.getCampaignCoderAssignment();
        })
        .catch((error) => {
            this.props.toast('error', 'Could not update pause status.', 'Error');
        })
    }

    handleAutoPopulate = () => {
        axios.post(`/campaignCoderAssignment/autoPopulate/${this.props.campaignID}`)
        .then(() => {              
        this.getCampaignCoderAssignment();    
        })
        .catch((error) => {            
                this.props.toast('error', 'Could not get campaign coder assignment', 'Error');            
        })
    }

    formatDelete = (cell, row) => {
        return (
            <div>
                <Button onClick={() => this.toggleDeleteConfirmModal(row.campaignCoderAssignmentID)}>
                    <Glyphicon glyph="trash" />
                </Button>
            </div>
        )
    }

    toggleDeleteConfirmModal = (id) => {
        this.setState({
            showDeleteConfirmModal: !this.state.showDeleteConfirmModal,
            toBeDeletedCampaignCoderAssignmentID: id
        });
    }

    handleConfirmDelete = () => {
        this.toggleDeleteConfirmModal(null);
        this.handleDeleteCoderDetail(this.state.toBeDeletedCampaignCoderAssignmentID);
    }

    handleDeleteCoderDetail = (campaignCoderAssignmentID) => {
        axios.delete(`/campaignCoderAssignment/${campaignCoderAssignmentID}`)
            .then((response) => {
                this.props.toast('success', 'The coder is deleted.', 'Success');
                this.getCampaignCoderAssignment();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not remove comment.', 'Error');
            }
        );
    }

    formatUserName = (cell, row) => {
        return this.state.allCoderDetails.find(x => x.userID == row.userID) ? 
            this.state.allCoderDetails.find(x => x.userID == row.userID).fullName : 'Not Found';
    }

    formatUserTitle = (cell, row) => {
        const roleID = this.state.allCoderDetails.find(x => x.userID == row.userID) ?
            this.state.allCoderDetails.find(x => x.userID == row.userID).roleID : 0;
        switch (roleID) {
            case UserRole.L1Coder: case UserRole.L2Coder:
                return "Coder";
                break;

            default:
                return "";
                break;
       }
    }

    formatUserRole = (cell, row) => {
        return this.state.allCoderDetails.find(x => x.userID == row.userID) ? 
            this.state.allCoderDetails.find(x => x.userID == row.userID).roleName : '';
    }

    formatAction = (cell, row) => {
        return (
            <div>
                <Button block bsStyle="primary" onClick={() => this.updatePauseStatus(row)}>
                    { row.isPaused ? "Resume" : "Pause" }
                </Button>
            </div>
        )
    }

    handleValueChange = (e) => {
        this.setState({ selectedCoder: e ? e.value : '' });
    }

    handleAddCoder = (row) => {
        if (this.state.campaignCoderAssignment.map(x => x.userID).includes(this.state.selectedCoder)) {
            this.props.toast('warning', 'You cannot add more than allotted users for this campaign', '');
        } else {
            axios.post('/campaignCoderAssignment', {
                UserID: this.state.selectedCoder,
                CampaignID: this.props.campaignID,
                IsDeleted: 0,
                IsPaused: 0
            })
            .then((response) => {
                this.getCampaignCoderAssignment();
                this.props.toast('success', 'A new coder saved.', 'Success');
            })
            .catch((error) => {
                this.props.toast('error', 'Could not add coder.', 'Error');
            })
        }
    }

    render() {
        return(
            <div>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={8}>
                        <BootstrapTable data={this.state.campaignCoderAssignment} maxHeight="555px" margin="100px">
                            <TableHeaderColumn dataField="userID" isKey hidden></TableHeaderColumn>
                            <TableHeaderColumn dataField="CampaignCoderAssignmentID" hidden></TableHeaderColumn>
                            <TableHeaderColumn dataField="fullName" dataFormat={this.formatUserName} dataAlign="left" >Coder Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="roleName" dataFormat={this.formatUserTitle} dataAlign="left">Title</TableHeaderColumn>
                            <TableHeaderColumn dataField="roleName" dataFormat={this.formatUserRole} dataAlign="left">User Level</TableHeaderColumn>
                            <TableHeaderColumn dataField="" dataAlign="center" dataFormat={this.formatAction}>Pause/Resume</TableHeaderColumn>
                            <TableHeaderColumn dataField="" dataAlign="center" dataFormat={this.formatDelete}>Delete</TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={3}>
                        <FormGroup>
                            <Select
                                placeholder={'Coder name'}
                                value={this.state.selectedCoder}
                                options={this.state.coderListOptions}
                                onChange={this.handleValueChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <Button bsStyle="primary" disabled={this.state.selectedCoder === ''} onClick={this.handleAddCoder}>
                                Add
                        </Button>
                    </Col>
                    <Col xs={2}>
                        <div className="text-right">
                            <Button bsStyle="primary" onClick={this.handleAutoPopulate}>
                                    Auto Populate
                            </Button>
                        </div>
                    </Col>
                </Row>

                <AlertDialog visible={this.state.showDeleteConfirmModal}
                    handleModalToggle={() => { this.toggleDeleteConfirmModal(null) }}
                    title={'Delete'} message={'Do you want to delete this Coder?'}
                    handleConfirm={this.handleConfirmDelete} confirmLabel={"Yes"} cancelLabel={"No"}
                    confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
            </div>
        );
    }
}

export default CoderAssignment;
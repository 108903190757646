import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ImageViewerNLPHCCTable from './ImageViewerNLPHCCTable';

class ImageViewerNLPContainer extends React.Component {
    //todo: structure this data on the service / return a model that doesn't need to be manipulated here
    getInsightsByHCC = (insightData) => {
        // creates an obj for each hcc found in the insight record's hcc array and adds it to insightsByHCC.
        // doesn't filter out duplicates, etc.
        const insightsByHCC = [];
        this.props.insightData.forEach(x => {
            const tempHCCs = {...x}.hccs;
            tempHCCs.forEach(y => {
                const temp = {...x};
                temp.hcc = `${y.version}-${y.number}`;
                temp.hccDescription = y.description;
                insightsByHCC.push(temp);
            });
        });

        return insightsByHCC;
    }

    //build a unique array of HCC string + description objects
    getUniqueHCCs = (insightsByHCC) => {
        return insightsByHCC.reduce((acc, cur) => {
            if (!acc.find(x => x.hcc === cur.hcc)) {
                acc.push({
                    hcc: cur.hcc,
                    hccDescription: cur.hccDescription
                });
            }
            return acc;
        }, []);
    }

    formatDate = (date) => {
       return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatConfidenceLevel = (level) => {
       return level ? `${level.substring(0,1).toUpperCase()}${level.substring(1).toLowerCase()}` : '';
    }

    render() {
        const insightsByHCC = this.getInsightsByHCC();
        return (
            <Row>
                <Col xs={12}>
                    <h4 style={{ marginTop: '3rem', marginBottom: '1.4rem', marginLeft: '-15px' }}>
                        <b>{this.props.title}</b>
                    </h4>
                    {
                        this.getUniqueHCCs(insightsByHCC).map(hcc => {
                            return (
                                <ImageViewerNLPHCCTable hcc={hcc} tableCollapsed={this.props.tableCollapsed}
                                    insights={insightsByHCC.filter(x => x.hcc === hcc.hcc)}
                                    formatDate={this.formatDate} formatConfidenceLevel={this.formatConfidenceLevel} />
                            );
                        })
                    }
                </Col>
            </Row>
        );
    }
}

export default ImageViewerNLPContainer;
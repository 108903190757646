import React from 'react';
import Card from './Card';
import { DropTarget } from 'react-dnd';
 
class RankingContainer extends React.Component {
    componentWillReceiveProps(nextProps) {
        this.setState({ cards: nextProps.list });
    }
 
    pushCard = (card) => {
        card.updatedOn = new Date().toLocaleString('en-US');
        this.props.loadState(this.props.id, [...this.props.list, card]);
    }
 
    removeCard = (index) => {
        const tempCards = [...this.props.list];
        tempCards.splice(index, 1);
        this.props.loadState(this.props.id, tempCards);
    }
 
    moveCard = (dragIndex, hoverIndex) => {
        const tempCards = [...this.props.list];
        tempCards[dragIndex].updatedOn = new Date().toLocaleString('en-US');
        tempCards[hoverIndex].updatedOn =  new Date().toLocaleString('en-US');

        const dragCard = tempCards[dragIndex];
        tempCards.splice(dragIndex, 1);
        tempCards.splice(hoverIndex, 0, dragCard);

        this.props.loadState(this.props.id, tempCards);
    }

    render() {
        return this.props.connectDropTarget(
            <tbody>
                {
                    this.props.list.map((card, i) => {
                        const coversheetCard = this.props.list.find(x => x.coversheetID === card.coversheetID);
                        let prevIndex = i;
                        card.rank = i;
                        // if user ranked container and submitted, maintain current rank
                        // logic to assign same rank to diags with same coversheetID while maintaining proper indexing
                        if (this.props.id === 1 && card.submittedToCMS === true) {
                            //if first diag with this coversheet
                            if (coversheetCard.medicalRecordDiagRADVDetailID === card.medicalRecordDiagRADVDetailID) {
                                if (prevIndex === 0) {
                                    card.rank = 1;
                                }
                                else {
                                    card.rank = this.props.list[prevIndex-1].rank + 1;
                                }
                            }
                            // if not first diag with this coversheet, set rank to previous diag's coversheet
                            else {
                                card.rank = coversheetCard.rank;
                            }
                        }
                        //if user ranked container and not submitted, rank based on index
                        else if (this.props.id === 1) {
                            if (prevIndex === 0) {
                                card.rank = 1;
                            }
                            else {
                                card.rank = this.props.list[prevIndex-1].rank + 1;
                            }
                        }
                        //if system ranked container, set rank to null
                        else if (this.props.id === 2) {
                            card.rank = null;
                        }
                        return (
                            <Card key={card.medicalRecordDiagRADVDetailID} index={i} listId={this.props.id} rank={card.rank}
                                coversheetID={card.coversheetID} scoreDesc={card.scoreDesc} targetedHCC={card.targetedHCC} nonrelatedHCC={card.nonRelatedHCC}
                                providerTypeDesc={card.providerTypeDesc} dosFrom={card.dosFrom} dosThru={card.dosThru} attestation={card.attestation}
                                updatedOn={card.updatedOn} submitted={card.submittedToCMS} systemRank={card.systemRank} enrolleeStatusID={this.props.enrolleeStatusID}
                                medicalRecordReviewID={card.medicalRecordReviewID} medicalRecordImageID={card.medicalRecordImageID} medicalRecordDiagID={card.medicalRecordDiagID}
                                checked={card.checked} moveRow={this.moveRow} handleSubmitCheckbox={this.props.handleSubmitCheckbox} card={card}
                                removeCard={this.removeCard} moveCard={this.moveCard} toast={this.props.toast} />
                        );
                    })
                }
                {/*empty row in both user ranked and system ranked rows*/}
                <tr style={{height: "40px"}}>
                    <td></td><td></td>
                    <td></td><td></td>
                    <td></td><td></td>
                    <td></td><td></td>
                    <td></td><td></td>
                    <td></td><td></td>
                    <td></td>
                </tr>
            </tbody>
        );
    } 
}

const cardTarget = {
    drop(props, monitor, component ) {
        const { id } = props;
        const sourceObj = monitor.getItem();
        if ( id !== sourceObj.listId ) component.pushCard(sourceObj.card);
        return {
            listId: id
        };
    }
}
 
export default DropTarget("CARD", cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
}))(RankingContainer);
import React from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/actions/index';

const mapDispatchToProps = dispatch => ({
    setCurrentUser: currentUser => dispatch(setCurrentUser(currentUser)),
});

class Login extends React.Component {
    componentDidMount() {
        if (this.props.location.query.id) {
            axios.post('/session', {
                id: this.props.location.query.id
            })
            .then((response) => {
                if (response.data) {
                    //todo: currently setting user info in the redux store in Container.jsx;
                    // determine if it would be better to set that here
                    // this.props.setCurrentUser(response.data.user)

                    //set token in localStorage
                    localStorage.setItem("token", response.data);

                    axios.get('/MerlinUser/CurrentUser')
                    .then((response) => {
                        //add current user response data to redux store (session / currentUser)
                        this.props.setCurrentUser(response.data);

                        //redirect
                        this.props.router.push('/');
                    });
                }
                else {
                    this.props.router.push('/Unauthorized');
                }
            })
            .catch((error) => {
                this.props.router.push('/Unauthorized');
            });
        }
    }

    //todo: render link to portal login (will allow user to login again on expiry, etc.) + remove placeholder
    render() {
        return (
            <Loader loading={true} />
        );
    }
}

export default connect(
    null,
    mapDispatchToProps
)(Login);
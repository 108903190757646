import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import AlertToastr from '../Shared/AlertToastr';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import { getDatePickerValueAsMidnightISOString } from '../../utils/DateUtils';

class CoderPerformanceReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectList: [],
            reviewTotal: 0,
            diagTotal: 0,
            projectIdInput: null,
            startDateInput: null,
            endDateInput: null,
            //NOTE: Filtering by multiple projects possible with some work on the backend
            searchFilter: {
                projectId: null,
                startDate: null,
                endDate: null
            },
            coderPerformance: [],
            toast: null
        };
    }

    componentWillMount() {
        Promise.all([
            this.getProjectsForDropdown(),
            this.getPerformance()
        ])
            .catch((error) => {
                this.state.toast('error', 'Could not load project options.', 'Error');
            });
    }

    getProjectsForDropdown = () => {
        return axios.get('/projects')
            .then((response) => {
                this.setState({
                    projectList: response.data.map((proj) => {
                        return {
                            label: proj.projectName,
                            value: proj.projectID
                        };
                    })
                });
            });
    }

    handleSearch = () => {
        this.setState({
            searchFilter: {
                projectId: this.state.projectIdInput,
                //Thoughts: Frontend was sending Date + Midnight EST via GMT. (Wed, 03 Oct 2018 04:00:00 GMT)
                //Evan misprinted dates on the backend, confusing himself. (someDate.toString("u") doesn't convert to UTC, it assumes UTC)
                //Backend was converting utc to server local time. UAT running in Central Time... (Wed, 03 Oct 2018 04:00:00 GMT => Tue, 02 Oct 2018 23:00:00 CST)
                //SQL doesn't understand timezones(at least however dapper inserts it), happily compares DateTime as if kind (timezone) unspecified.
                //
                //Now: Sending Date + Midnight no timezone. Backend interprets as Date + Midnight local server time. SQL compares as Date + Midnight no timezone.
                //
                //TODO: Date math causing several hard-to-pin bugs. Consider MomentJS, DateTimeOffset, SQL standardized to UTC, etc.

                startDate: this.state.startDateInput && getDatePickerValueAsMidnightISOString(this.state.startDateInput),
                endDate: this.state.endDateInput && getDatePickerValueAsMidnightISOString(this.state.endDateInput)
            }
        }, this.getPerformance);
    }

    getPerformance = () => {
        return axios.get('/Reports/CoderPerformance/CoderPerformanceReport/CoderPerformanceOverview',
            { params: this.state.searchFilter })
            .then((response) => {
                let reviewTotal = 0;
                let diagTotal = 0;
                response.data.forEach((elem) => {
                    reviewTotal += elem.initialReviews;
                    diagTotal += elem.diags;
                });

                this.setState({ reviewTotal, diagTotal, coderPerformance: response.data });
            });
    }

    handleProjectChange = (selection) => {
        this.setState({ projectIdInput: selection && selection.value });
    };

    handleStartDateChange = (newStart) => {
        this.setState({ startDateInput: newStart });
    }

    handleEndDateChange = (newEnd) => {
        this.setState({ endDateInput: newEnd });
    }

    GetReviewLevelDetails = () => {
        return axios.post('/Reports/CoderPerformance/CoderPerformanceReport/CreateReviewLevelDetailsDownloadRequest',
            this.state.searchFilter);
    }

    GetDiagDetails = () => {
        return axios.post('/Reports/CoderPerformance/CoderPerformanceReport/DownloadDiagDetails',
            this.state.searchFilter);
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return { text: item.toString(), value: item } }),
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                });
            }
        }

        return (
            <div className='reportContainer'>
                <div className='innerReportContainer'>
                    <Grid fluid>
                        <Row style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            <Col>
                                <h3>Initial Coder Performance Report</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} lg={5}>
                                <FormGroup>
                                    <ControlLabel>Project</ControlLabel>
                                    <Select
                                        name="project"
                                        placeholder="All Projects"
                                        options={this.state.projectList}
                                        value={this.state.projectIdInput}
                                        onChange={this.handleProjectChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={5} lg={2}>
                                <FormGroup>
                                    <ControlLabel>Start Date</ControlLabel>
                                    <DatePicker value={this.state.startDateInput} onChange={this.handleStartDateChange} style={{ zIndex: 'auto' }} />
                                </FormGroup>
                            </Col>
                            <Col xs={5} lg={2}>
                                <FormGroup>
                                    <ControlLabel>End Date</ControlLabel>
                                    <DatePicker value={this.state.endDateInput} onChange={this.handleEndDateChange} style={{ zIndex: 'auto' }} />
                                </FormGroup>
                            </Col>
                            <Col xs={1} lg={1}>
                                <ControlLabel>&nbsp;</ControlLabel>
                                <Button onClick={this.handleSearch}>
                                    <Glyphicon glyph="search" style={{ 'marginRight': '0.2rem' }} /> Search
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                            <BootstrapTable data={this.state.coderPerformance} options={options} pagination maxHeight="420px">
                                <TableHeaderColumn dataField="name" isKey dataSort>Coder Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="initialReviews" dataSort>Initial Reviews Performed</TableHeaderColumn>
                                <TableHeaderColumn dataField="diags" dataSort>Number of Diags</TableHeaderColumn>
                            </BootstrapTable>
                        </Row>
                        <Row style={{ marginBottom: '1.4rem' }}>
                            <Col xs={12}>
                                <hr />
                                <Row style={{ marginBottom: '1.4rem', paddingLeft: '0px' }}>
                                    <Col xs={4} lg={3}>
                                        <h5><b>Total Initial Reviews:</b> {this.state.reviewTotal}</h5>
                                        <LongRunningDownloadAnchor
                                            linkText="Review .CSV"
                                            postRequest={this.GetReviewLevelDetails}
                                            displayCSVDownloader={true}
                                            glyph="file" />
                                    </Col>
                                    <Col xs={4} lg={3}>
                                        <h5><b>Total Diags:</b> {this.state.diagTotal}</h5>
                                        <LongRunningDownloadAnchor
                                            linkText="Diag .CSV"
                                            postRequest={this.GetDiagDetails}
                                            displayCSVDownloader={true}
                                            glyph="file" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default CoderPerformanceReport;
import React from 'react';
import { Link } from 'react-router';

export default class NavItems extends React.PureComponent {
    getContextualNavItems = () => {
        switch (this.props.selectedEventKey) {
            case 1.1:
                //MRR
                return [];
            case 1.2:
                //PEC
                return [];
            case 1.3:
                //RADV
                return [];
            case 1.4:
                //Admin
                return [];
            default:
                return [];
        }
    }

    //todo: devise a better matching scheme for subpages (ex. dashboards)
    setActiveStyle = (path, altPaths = null) => {
        const pathname = this.props.pathname.charAt(0) === '/' ?
            this.props.pathname : `/${this.props.pathname}`;

        if (altPaths) {
            return pathname === path || altPaths.map(x => `${this.props.pathRoot}${x}`)
                .includes(pathname) ? 'nav-navitem-active' : '';
        }
        else {
            return pathname === path ? 'nav-navitem-active' : '';
        }
    }

    render() {
        return this.getContextualNavItems().map((x, index) => {
            const path = `${this.props.pathRoot}${x.path}`;
            return <li key={x.label} role="presentation" className={this.setActiveStyle(path, x.altPaths)}>
                <Link to={path}>{x.label}</Link></li>
        })
    }
}
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Select from 'react-select';
import AlertToastr from '../../Shared/AlertToastr';
import MemberSearchModal from '../../Shared/MemberSearchModal';
import ProviderTypeaheadSearch from '../../Shared/ProviderTypeaheadSearch';
import RankContainer from './RankContainer';
import { ClaimMatchTypeDescription } from '../../../enums/ClaimMatchTypeDescription';
import '../../../styles/Report.css';

export default class ClaimsLinkingSummaryReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toast: null,
            projectList: [],
            claimMatchTypeList: [],
            showMemberSearchModal: false,
            showProviderSearchModal: false,
            selectedProvider: null,
            searchFilter: {
                projectID: '',
                memberID: '',
                providerNPI: '',
                claimMatchType: '',
                dosStart: '',
                dosEnd: ''
            },
            reportData: [],
            loaded: true
        }
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        Promise.all([
            this.getProjectsForDropdown(),
            this.getClaimMatchTypesForDropdown()
        ])
        .catch((error) => {
            this.state.toast('error', 'Could not load project options.', 'Error');
        });
    }

    getProjectsForDropdown = () => {
        return axios.get('/projects')
            .then((response) => {
                this.setState({
                    projectList: response.data.map((proj) => {
                        return {
                            label: proj.projectName,
                            value: proj.projectID
                        };
                    })
                });
            });
    }

    getClaimMatchTypesForDropdown = () => {
        return axios.get('/Reports/ClaimsLinkingReport/claimMatchTypes')
            .then((response) => {
                this.setState({
                    claimMatchTypeList: response.data.map((matchType) => {
                        return {
                            label: matchType.claimMatchTypeDescription,
                            value: matchType.claimMatchTypeID
                        };
                    })
                });
            });
    }

    handleSearchResult = () => {
        this.setState({ loaded: false });
        return axios.get('/Reports/ClaimsLinkingReport/summaryReport', { params: this.state.searchFilter })
            .then((response) => {
                this.setState({
                    reportData: response.data.ranks ? response.data.ranks.map((claimRank) => {
                        return {
                            claimMatchTypeID: claimRank.claimMatchTypeID,
                            claimMatchTypeDescription: claimRank.claimMatchTypeDescription,
                            preSubmittedCount: claimRank.preSubmittedCount,
                            submittedCount: claimRank.submittedCount,
                            totalCount: claimRank.preSubmittedCount + claimRank.submittedCount,
                            rankingDetails: claimRank.rankingDetails
                        };
                    }) : [],
                    loaded: true
                });
            })
            .catch((error) => {
                this.setState({ loaded: true });
                this.state.toast('error', 'Could not search claims linking data', 'Error');
            });
    }

    handleProviderTypeaheadChange = (provider) => {
        this.setState({
            selectedProvider: provider,
            searchFilter: {
                ...this.state.searchFilter,
                providerNPI: provider ? provider.providerNPI : null
            }
        });
    }

    handleMemberIDChange = (member) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                memberID: member.target.value
            }
        });
    }

    handleMemberSelect = (memberId) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                memberID: memberId
            }
        });
    }

    handleProjectChange = (project) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                projectID: project ? project.value : null,
            },
        });
    }

    handleClaimMatchTypeChange = (matchType) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                claimMatchType: matchType ? matchType.value : null,
            },
        });
    }

    handleStartDateChange = (newStart) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                dosStart: newStart,
            },
        });
    }

    handleEndDateChange = (newEnd) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                dosEnd: newEnd,
            },
        });
    }

    toggleMemberSearchModal = () => {
        this.setState({ showMemberSearchModal: !this.state.showMemberSearchModal });
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderSearchModal: !this.state.showProviderSearchModal });
    }

    formatClaimMatchType = (cell) => {
        return `${cell} - ${ClaimMatchTypeDescription[cell]}`;
    }

    render() {
        const options = {
            defaultSortName: 'claimMatchTypeID',
            defaultSortOrder: 'asc',
            noDataText: this.state.loaded ? '' : <Loader loaded={false} />,
        };

        return (
            <div className="reportContainer">
                <div className="innerReportContainer" style={{ minHeight: '250px', marginLeft: '3%', marginRight: '3%' }}>
                    <br />
                    <Grid fluid style={{ maxWidth: window.innerWidth }}>
                        <Row style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            <Col>
                                <h3>Claims Linking Summary Report</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <ControlLabel>Project</ControlLabel>
                                <Select options={this.state.projectList} placeholder='All Projects' value={this.state.searchFilter.projectID}
                                    onChange={this.handleProjectChange} />
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>Member ID</ControlLabel>
                                <Row style={{ margin: 0 }}>
                                    <Col xs={10} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                                        <FormControl type="text" value={this.state.searchFilter.memberID} onChange={this.handleMemberIDChange}
                                            style={{ display: 'inline-block' }} />
                                    </Col>
                                    <Col xs={2} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                                        <Button onClick={this.toggleMemberSearchModal} bsSize="small" style={{ height: '3.4rem', minWidth: '4rem', width: '100%' }}>
                                            <Glyphicon glyph="search" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3}>
                                <ProviderTypeaheadSearch value={this.state.selectedProvider} handleProviderChange={this.handleProviderTypeaheadChange}
                                    showProviderModal={this.state.showProviderSearchModal} toggleProviderSearchModal={this.toggleProviderSearchModal}
                                    toast={this.state.toast} />
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>Claim Match Type</ControlLabel>
                                <Select options={this.state.claimMatchTypeList} placeholder='Claim Match Type' value={this.state.searchFilter.claimMatchType}
                                    onChange={this.handleClaimMatchTypeChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <ControlLabel>DOS Start</ControlLabel>
                                <div style={{ width: '60%' }}>
                                    <DatePicker value={this.state.searchFilter.dosStart} onChange={this.handleStartDateChange} style={{ zIndex: 'auto' }} />
                                </div>
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>DOS End</ControlLabel>
                                <div style={{ width: '60%' }}>
                                    <DatePicker value={this.state.searchFilter.dosEnd} onChange={this.handleEndDateChange} style={{ zIndex: 'auto' }} />
                                </div>
                            </Col>
                            <Col xs={3}>
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>&nbsp;</ControlLabel>
                                <div>
                                    <Button onClick={this.handleSearchResult}>
                                        <Glyphicon glyph="search" style={{ 'marginRight': '0.2rem' }} /> Search
                                        </Button>
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row style={{ marginTop: '2rem', marginBottom: '2.6rem' }}>
                            <Col xs={6} xsOffset={3}>
                                <BootstrapTable data={this.state.reportData} options={options}>
                                    <TableHeaderColumn dataField="claimMatchTypeID" dataFormat={this.formatClaimMatchType} isKey dataSort>Rank</TableHeaderColumn>
                                    <TableHeaderColumn dataField="preSubmittedCount" dataSort>Pre-submitted Count</TableHeaderColumn>
                                    <TableHeaderColumn dataField="submittedCount" dataSort>Submitted Count</TableHeaderColumn>
                                    <TableHeaderColumn dataField="totalCount" dataSort>Total Count</TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }}>
                        {
                            this.state.reportData.sort((a, b) => (a.claimMatchTypeID > b.claimMatchTypeID) ? 1 : -1).map(x => {
                                return <RankContainer data={x} />;
                            })
                        }
                        </Row>
                        <MemberSearchModal visible={this.state.showMemberSearchModal} handleModalToggle={this.toggleMemberSearchModal}
                            handleSelectSearchResult={this.handleMemberSelect} toast={this.state.toast} />
                    </Grid>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}
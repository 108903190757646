import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';

const ScanRosterSearch = (props) =>
    <Grid fluid>
        <Row style={{marginBottom: '1.4rem', paddingLeft: '13px'}}>
            <h4>Scan Roster Search</h4>
        </Row>
        <Row style={{marginBottom: '2rem'}}>
            <Col sm={3}>
                <FormGroup>
                    <ControlLabel>Provider Org</ControlLabel>
                    <FormControl componentClass="select" value={props.providerID} onChange={props.setProvider}>
                        <option value="">Select</option>
                        {
                            props.providers.map((item) => {
                                return <option key={item.id} value={item.id}>{item.description}</option>
                            })
                        }
                    </FormControl>
                </FormGroup>
            </Col>
            <Col sm={3}>
                <FormGroup>
                    <ControlLabel>Practice</ControlLabel>
                    <FormControl componentClass="select" value={props.practiceGroupingID} onChange={props.setPractice}>
                        <option value="">Select</option>
                        {
                            props.practices.map((item) => {
                                return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                            })
                        }
                    </FormControl>
                </FormGroup>
            </Col>
        </Row>
        <Row style={{marginBottom: '2rem'}}>
            <Col sm={3}>
                <FormGroup>
                    <ControlLabel>Last Load Before</ControlLabel>
                    <DatePicker value={props.lastLoadDateBefore} onChange={props.setLastLoadDateBefore} />
                </FormGroup>
            </Col>
            <Col sm={3}>
                <FormGroup>
                    <ControlLabel>Practice ID</ControlLabel>
                    <FormControl type="text" placeholder="Practice ID" value={props.practiceIDText} onChange={props.setPracticeIDText} />
                </FormGroup>
            </Col>
        </Row>
        <Row style={{marginBottom: '2rem'}}>
            <Col sm={6}>
                <Button onClick={() => props.searchRoster(1)} disabled={!props.searchEnabled} style={{marginRight: '2rem'}}>
                    <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Search
                </Button>
                <Button onClick={props.clearSearch} style={{marginRight: '2rem'}}>
                    Clear Search
                </Button>
                <Button onClick={props.togglePrintModal} disabled={!props.printEnabled}>
                    <Glyphicon glyph="print" style={{marginRight: '0.2rem'}} /> Print
                </Button>
            </Col>
        </Row>
    </Grid>

export default ScanRosterSearch;
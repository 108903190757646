import React from 'react';
import axios from 'axios';
import { Button, Col, Collapse, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import { BootstrapTable, SizePerPageDropDown, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Select from 'react-select';
import { MedicalRecordReviewStatus } from '../../../enums/MedicalRecordReviewStatus';
import { SessionParamCache } from '../../../utils/SessionUtils';
import ProviderTypeaheadSearch from '../../Shared/ProviderTypeaheadSearch';

// todo: should MD and QA remain separate tabs?
class QA extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = !this.props.isMD ? 'qaSearch' : 'mdSearch';
        this.savedParams = this.paramCache.getParamObj(this.paramCacheKey);
        this.paramCacheKeyShowFilters = !this.props.isMD ? 'qaSearchShowFilters' : 'mdSearchShowFilters';
        this.savedParamsShowFilters = this.paramCache.getParamObj(this.paramCacheKeyShowFilters);

        this.state = {
            updateCoders: [],
            reportingLevels: [],
            recordTypes: [],
            project: this.savedParams.project ? this.savedParams.project : '',
            recordType: this.savedParams.recordType ? this.savedParams.recordType : '',
            planMemberID: this.savedParams.planMemberID ? this.savedParams.planMemberID : '',
            selectedProvider: this.savedParams.selectedProvider ? this.savedParams.selectedProvider : null,
            medicalRecordReviewID: this.savedParams.medicalRecordReviewID ? this.savedParams.medicalRecordReviewID : '',
            reportingLevelSelections: this.savedParams.reportingLevelSelections ? this.savedParams.reportingLevelSelections : {},
            lastUpdatedBy: this.savedParams.lastUpdatedBy ? this.savedParams.lastUpdatedBy : '',
            searchResults: [],
            page: 1,
            sizePerPage: this.savedParams.sizePerPage ? this.savedParams.sizePerPage : 10,
            totalItems: 0,
            sortBy: this.savedParams.sortBy ? this.savedParams.sortBy : '',
            sortByDesc: this.savedParams.sortByDesc ? this.savedParams.sortByDesc : false,
            loaded: true,
            showProviderSearchModal: false,
            showFilters: this.savedParamsShowFilters.showFilters ? this.savedParamsShowFilters.showFilters : false
        };
    }

    componentWillMount() {
        Promise.all([this.getFilteredCoders('MRR Updater'), this.getReportingLevels()])
        .then((responses) => {
            this.setState({
                updateCoders: responses[0].data,
                reportingLevels: responses[1] ? responses[1].data : []
            });
            if (!this.isSearchDisabled()) {
                this.searchQAReviews();
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load search filters.', 'Error');
        })
    }

    componentDidMount() {
        if (this.savedParams.project) {
            this.getRecordTypes();
        }
    }

    getFilteredCoders = (filterName) => {
        return axios.get('/merlinuser/', {
            params: {
                filter: filterName
            }
        });
    }

    getReportingLevels = () => {
        if (this.lineOfBusiness !== 'Commercial') {
            return axios.get('/reportingLevel')
        }
        else {
            return null;
        }
    }

    getRecordTypes = () => {
        if (this.state.project) {
            axios.get(`/projects/${this.state.project.value}/referenceData?referenceDataType=medicalRecordType`)
            .then((response) => {
                if (response.data && response.data.referenceDataList && response.data.referenceDataList[0] && response.data.referenceDataList[0].referenceData) {
                    this.setState({ recordTypes: response.data.referenceDataList[0].referenceData });
                }
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load record types.', 'Error');
            });
        }
        else {
            this.setState({ recordTypes: [] });
        }
    }

    //todo: add loading animation?
    searchQAReviews = (page = this.state.page, sizePerPage = this.state.sizePerPage) => {
        //map level selections to an object like { levelX: valX, levelY: valY, ... }
        const levelSelections = Object.entries(this.state.reportingLevelSelections).reduce((acc, cur) => {
            return cur[0] && cur[1] ? {...acc, [`level${cur[0]}`]: cur[1] } : acc;
        }, {});

        axios.get('/qaMemberMedicalRecord', {
           params: {
                isMD: this.props.isMD,
                lastReviewedUserID: this.state.lastUpdatedBy ? this.state.lastUpdatedBy.value : null,
                medicalRecordReviewID: this.state.medicalRecordReviewID ? this.state.medicalRecordReviewID : null,
                memberID: this.state.planMemberID ? this.state.planMemberID : null,
                projectID: this.state.project ? this.state.project.value : null,
                providerMasterID: this.state.selectedProvider ? this.state.selectedProvider.internalKey : null,
                recordType: this.state.recordType ? this.state.recordType.value : null,
                ...levelSelections,
                pageNumber: page,
                pageSize: sizePerPage,
                sort: this.getSortColumnMapping()
            }
        })
        .then((response) => {
            this.paramCache.addParamObj(this.paramCacheKey, {
                lastUpdatedBy: this.state.lastUpdatedBy,
                medicalRecordReviewID: this.state.medicalRecordReviewID,
                planMemberID: this.state.planMemberID,
                project: this.state.project,
                selectedProvider: this.state.selectedProvider,
                recordType: this.state.recordType,
                reportingLevelSelections: this.state.reportingLevelSelections,
                sizePerPage: this.state.sizePerPage,
                sortBy: this.state.sortBy,
                sortByDesc: this.state.sortByDesc
            });
            this.setState({
                searchResults: response.data.items,
                totalItems: response.data.totalItems
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not search reviews. If the problem persists, please select more search criteria.', 'Error');
        });
    }

    getSortColumnMapping = () => {
        let sort = ""
        switch (this.state.sortBy) {
            case "medicalRecordReviewID":
                sort = "Review.MedicalRecordReviewID";
                break;
            case "memberID":
                sort = "Member.MemberID";
                break;
            case "memberName":
                sort = "Member.lastName";
                break;
            case "providerID":
                sort = "Provider.ProviderID";
                break;
            case "providerNPI":
                sort = "Provider.NPI";
                break;
            case "providerName":
                sort = "Provider.LastName";
                break;
            case "dos":
                sort = "d.DOS";
                break;
            case "dosThru":
                sort = "d.DOSThru";
                break;
            case "recordType":
                sort = "RecordType.Description";
                break;
            case "reviewStatus":
                sort = "ReviewStatus.StatusDescription";
                break;
            case "lastReviewedBy":
                sort = "LastReviewedBy";
                break;
            case "lastReviewedDate":
                sort = "LastReviewedDate";
                break;
            case "lastReviewType":
                sort = "LastReviewType";
                break;
            default:
                sort = null;
                break;
        }

        return sort ? `${sort}${this.state.sortByDesc ? ' desc' : ''}` : null;
    }

    handleProjectChange = (e) => {
        this.setState({ project: e }, this.getRecordTypes);
    }

    handleRecordTypeChange = (e) => {
        this.setState({ recordType: e });
    }

    handleProviderTypeaheadChange = (e) => {
        this.setState({ selectedProvider: e });
    }

    handleMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value })
    }

    handleMedicalRecordReviewIDChange = (e) => {
        const mrrID = e.target.value ? parseInt(e.target.value, 10) : '';
        this.setState({ medicalRecordReviewID: Number.isInteger(mrrID) ? mrrID : '' });
    }

    handleLastUpdatedByChange = (e) => {
        this.setState({ lastUpdatedBy: e });
    }

    getProjectDropdownOptions = (projects) => {
        return projects.map((proj) => {
            return {
                label: proj.projectName,
                value: proj.projectID
            };
        });
    }

    getDropdownOptions = (referenceData) => {
        return referenceData.map((x) => {
            return {
                label: x.description,
                value: x.value
            };
        });
    }

    getReportingLevelDropdownOptions = (levelItems) => {
        return levelItems.map((x) => {
            return {
                label: x.displayName,
                value: x.levelID
            };
        });
    }

    getUserDropdownOptions = (users) => {
        return users.map((x) => {
            return {
                label: x.fullName,
                value: x.userID
            };
        });
    }

    redirectToReview = (reviewType, medicalRecordReviewID, recordType) => {
        //if Record Type is Scan and NLP process is enabled for the client and if the new NLP screen is enabled
        console.log("isNlpProcessEnabled? "); console.log(this.props.nlpProcessEnabledValue);
        if(recordType.toLowerCase() === 'scan' && this.props.nlpProcessEnabledValue === 'true' && this.props.newNLPScreenEnabled === 'true')
        {
            console.log("check review for Nlp Insights data");
            axios.get(`/mrrnlpapi/v1/ReviewHasInsightsData/${medicalRecordReviewID}`)
            .then((response) => {
                if(response.data && ((reviewType === 'QA' && this.lineOfBusiness === 'MA' && this.props.maLobNlpProcessEnabled === 'true') || (reviewType === 'QA' && this.lineOfBusiness === 'Commercial' && this.props.commLobNlpProcessEnabled === 'true')))
                    window.location.href = `/NLP/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
                else
                    window.location.href = `/ReviewProcessing/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
            }).catch((error) => {
                console.log('QA screen error' + error);
                this.props.toast('error', "Could not start QA Review.", 'Error');
            });    
        }
        else
            window.location.href = `/ReviewProcessing/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
    }

    formatActions = (cell, row) => {
        if (row.reviewStatusID === MedicalRecordReviewStatus.ReadyForQA && !this.props.isMD) {
            return (
                <Button onClick={() => this.redirectToReview('QA', row.medicalRecordReviewID, row.recordType)}>Start QA</Button>
            );
        }
        else if (row.reviewStatusID === MedicalRecordReviewStatus.ReadyForMDQA && this.props.isMD) {
            return (
                <Button onClick={() => this.redirectToReview('MD', row.medicalRecordReviewID, row.recordType)}>Start MD</Button>
            );
        }
        else {
            return '';
        }
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderSearchModal: !this.state.showProviderSearchModal });
    }

    handleReportingLevelSelection = (reportingLevel, e) => {
        this.setState({
            reportingLevelSelections: {
                ...this.state.reportingLevelSelections,
                [reportingLevel]: e && e.value ? e.value : ''
            }
        });
    }

    renderReportingLevelFilters = (reportingLevels) => {
        return reportingLevels.map(reportingLevel => {
            if (reportingLevel.display && reportingLevel.level && reportingLevel.level.levelItems && reportingLevel.level.levelItems.length > 0) {
                return (
                    <Col xs={3} key={`qa-reporting-level-${reportingLevel.level.level}`}>
                        <FormGroup>
                            <ControlLabel>
                                {reportingLevel.level.levelName}
                            </ControlLabel>
                            <Select value={this.state.reportingLevelSelections[reportingLevel.level.level]} options={this.getReportingLevelDropdownOptions(reportingLevel.level.levelItems)}
                                onChange={(e) => this.handleReportingLevelSelection(reportingLevel.level.level, e)} />
                        </FormGroup>
                    </Col>
                );
            }
            else {
                return '';
            }
        });
    }

    handleFilterToggle = () => {
        const toggleVal = !this.state.showFilters;
        this.setState({ showFilters: toggleVal });

        //cache updated toggle value
        this.paramCache.addParamObj(this.paramCacheKeyShowFilters, {
            showFilters: toggleVal
        });
    }

    renderFilterToggleLabel = () => {
        return this.state.showFilters ?
            <span><Glyphicon glyph="minus" style={{ marginRight: '0.2rem', fontSize: '12px' }} />&nbsp;Filters</span>
            : <span><Glyphicon glyph="plus" style={{ marginRight: '0.2rem', fontSize: '12px' }} />&nbsp;Filters</span>
    }

    isSearchDisabled = () => {
        return !(this.state.project && this.state.project.value);
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            defaultSortName: this.state.sortBy,
            defaultSortOrder: this.state.sortByDesc ? 'desc' : 'asc',
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                }, this.searchQAReviews);
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortOrder === "desc",
                    page: 1
                }, this.searchQAReviews);
            },
            sizePerPageDropDown: (props) => { return <SizePerPageDropDown variation='dropup' {...props} /> },
            sizePerPageList: [10, 25, 50, 100, 250],
        };

        return (
            <div style={{ marginTop: '2rem' }}>
                <Button onClick={this.handleFilterToggle}>
                    {this.renderFilterToggleLabel()}
                </Button>
                <Collapse in={this.state.showFilters}>
                    <div style={{ marginTop: '2rem' }}>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel className="requiredField">Project</ControlLabel>
                                    <Select value={this.state.project ? this.state.project.value : ''} options={this.getProjectDropdownOptions(this.props.projects)}
                                        onChange={this.handleProjectChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>
                                        Record Type
                                    </ControlLabel>
                                    <Select value={this.state.recordType} options={this.getDropdownOptions(this.state.recordTypes)}
                                        onChange={this.handleRecordTypeChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <ProviderTypeaheadSearch value={this.state.selectedProvider} handleProviderChange={this.handleProviderTypeaheadChange}
                                    showProviderModal={this.state.showProviderSearchModal} toggleProviderSearchModal={this.toggleProviderSearchModal}
                                    toast={this.props.toast} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>
                                        Member ID
                                    </ControlLabel>
                                    <div>
                                        <FormControl type="text" value={this.state.planMemberID} onChange={this.handleMemberIDChange} />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>
                                        MRR ID
                                    </ControlLabel>
                                    <div>
                                        <FormControl type="text" maxLength={9} value={this.state.medicalRecordReviewID} onChange={this.handleMedicalRecordReviewIDChange} />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {
                                this.renderReportingLevelFilters(this.state.reportingLevels)
                            }
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>
                                        Last Updated By
                                    </ControlLabel>
                                    <Select value={this.state.lastUpdatedBy} options={this.getUserDropdownOptions(this.state.updateCoders)}
                                        onChange={this.handleLastUpdatedByChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1}>
                                <FormGroup style={{ marginTop: '1rem', marginBottom: '0' }}>
                                    <Button onClick={() => this.searchQAReviews(1)} disabled={this.isSearchDisabled()}>
                                        <Glyphicon glyph="search" style={{ marginRight: '0.2rem' }} /> Search
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col xs={2} style={{ paddingLeft: 0 }}>
                            {
                                this.state.totalItems > 0 &&
                                <h4 style={{ marginTop: '1.76rem', marginBottom: 0 }}>
                                    Total Reviews: {this.state.totalItems}
                                </h4>
                            }
                            </Col>
                        </Row>
                    </div>
                </Collapse>
                <div style={{ marginTop: '4rem' }}>
                    <Loader loaded={this.state.loaded}>
                        <BootstrapTable data={this.state.searchResults} options={options} hover pagination
                            remote fetchInfo={{ dataTotalSize: this.state.totalItems }}>
                            <TableHeaderColumn dataField="medicalRecordReviewID" isKey dataSort>MRR ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberID" dataSort>Member ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberName" width="10%" dataSort>Member Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="providerID" dataSort>Provider ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="providerNPI" dataSort>NPI</TableHeaderColumn>
                            <TableHeaderColumn dataField="providerName" dataSort>Provider Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="dos" dataSort>DX Start</TableHeaderColumn>
                            <TableHeaderColumn dataField="dosThru" dataSort>DX End</TableHeaderColumn>
                            <TableHeaderColumn dataField="recordType" dataSort>Record Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="reviewStatus" dataSort>Review Status</TableHeaderColumn>
                            <TableHeaderColumn dataField="lastReviewedBy" dataSort>Last Updated By</TableHeaderColumn>
                            <TableHeaderColumn dataField="lastReviewedDate" dataSort>Last Updated</TableHeaderColumn>
                            <TableHeaderColumn dataField="lastReviewType" dataSort>Last Action</TableHeaderColumn>
                            <TableHeaderColumn dataField="action" dataFormat={this.formatActions}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </Loader>
                </div>
            </div>
        );
    }
}

export default QA;
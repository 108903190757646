import React from 'react';
import axios from 'axios';
import { Button, Glyphicon, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { BootstrapTable, SizePerPageDropDown, TableHeaderColumn } from 'react-bootstrap-table';
import { getDatePickerValueAsMidnightISOString } from '../../../utils/DateUtils';
import ScanRosterSearch from './ScanRosterSearch';
import ScanRosterPrintModal from './ScanRosterPrintModal';
import AlertDialog from '../../Shared/AlertDialog';

class ScanRosterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            rowCount: 0,
            providerID: '',
            practiceGroupingID: '',
            practiceIDText: '',
            practiceName: '',
            lastLoadDateBefore: null,
            page: 1,
            sizePerPage: 250,
            sortName: 'memberID',
            sortOrder: 'asc',
            searchEnabled: false,
            printEnabled: false,
            showPrintModal: false,
            showUploadModal: false,
            files: [],
            confirmingDelete: false,
        };
    }

    handleProviderChange = (e) => {
        this.setState({
            providerID: e.target.value,
            practiceGroupingID: ''
        }, () => this.searchEnabled());
    }

    handlePracticeChange = (e) => {
        this.setState({
            practiceGroupingID: e.target.value,
            practiceName: e.target.options[e.target.selectedIndex].label
        }, () => this.searchEnabled());
    }

    handlePracticeIDTextChange = (e) => {
        this.setState({
            practiceIDText: e.target.value
        }, () => this.searchEnabled());
    }

    handleLastLoadDateBeforeChange = (e) => {
        this.setState({
            lastLoadDateBefore: e ? getDatePickerValueAsMidnightISOString(e) : null
        }, () => this.searchEnabled());
    }

    searchEnabled = () => {
        if (this.state.practiceGroupingID || this.state.practiceIDText) {
            this.setState({ searchEnabled: true });
        }
        else {
            this.setState({ searchEnabled: false });
        }
    }

    printRosterEnabled = () => {
        if (this.state.searchResults && this.state.searchResults.length > 0) {
            if (!this.state.printEnabled) {
                this.setState({ printEnabled: true });
            }
        }
        else{
            this.setState({ printEnabled: false });
        }
    }

    //search roster via selected params
    searchRoster = (page) => {
        axios.get('/scan/ScanList/', {
            params: {
                providerOrgID: this.state.providerID,
                practiceID: this.state.practiceIDText ? this.state.practiceIDText : this.state.practiceGroupingID,
                lastLoadDateBefore: this.state.lastLoadDateBefore,
                pageSize: this.state.sizePerPage,
                pageNumber: page,
                sortBy: this.state.sortName,
                sortByDesc: this.state.sortOrder === "desc"
            }
        })
        .then((response) => {
            this.setState({
                searchResults: response.data.items,
                rowCount: response.data.totalItems,
                page: page
            },() => this.printRosterEnabled());
        })
        .catch((error) => {
            this.props.toast('error', 'Could not search scan roster.', 'Error');
            this.setState({ printEnabled: false });
        });
    }

    clearSearch = () => {
        this.setState({
            searchResults: [],
            providerID: '',
            practiceGroupingID: '',
            practiceIDText: '',
            lastLoadDateBefore: null,
            searchEnabled: false,
            printEnabled: false
        });
    }

    togglePrintModal = () => {
        this.setState({
            showPrintModal: !this.state.showPrintModal
        });
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatLongDateString = (date) => {
        return date ? date.substring(0, date.indexOf('T')) : '';
    }

    formatUploadButton = (memberMasterID) => {
        const attachTooltip = (
            <Tooltip><strong>Upload File</strong></Tooltip>
        );

        return (
            <div>
                <OverlayTrigger placement="top" overlay={attachTooltip}>
                    <Button onClick={this.openAttachPDFModal(memberMasterID)}>
                        <Glyphicon glyph="paperclip" />
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }

    openAttachPDFModal = (memberMasterID) => () => {
        this.setState({
            showUploadModal: true,
            memberID: memberMasterID,
        });
        axios.get('/members/' + memberMasterID + '/medicalRecordEntities')
            .then(resp => {
                this.setState({
                    files: resp.data
                });
            });
    }
    
    deleteEntity = (medicalRecordEntityID) => () => {
        axios.delete('/medicalRecordEntities/' + medicalRecordEntityID)
            .then(resp => {
                this.setState({
                    files: this.state.files.filter((file) => file.medicalRecordEntityID !== medicalRecordEntityID),
                    confirmingDelete: false,
                })
            })
    }

    toggleConfirmDelete = () => {
        this.setState({
            confirmingDelete: !this.state.confirmingDelete
        })
    }

    formatImageTableActions = (medicalRecordImageID, row) => {
        const viewTooltip = (
            <Tooltip><strong>View File</strong></Tooltip>
        )

        const deleteTooltip = (
            <Tooltip><strong>Delete File</strong></Tooltip>
        )

        return (
            <div>
                <OverlayTrigger placement="top" overlay={viewTooltip}>
                    <Button onClick={this.viewImage(medicalRecordImageID)}>
                        <Glyphicon glyph="search" />
                    </Button>
                </OverlayTrigger>
                {!row.hasBeenAttachedToReview &&
                    <OverlayTrigger placement="top" overlay={deleteTooltip}>
                        <Button onClick={() => this.setState({
                            confirmingDelete: true,
                            medicalRecordEntityIDToDelete: row.medicalRecordEntityID,
                        })}>
                            <Glyphicon glyph="remove" />
                        </Button>
                    </OverlayTrigger>
                }
            </div>
        )
    }

    viewImage = (medicalRecordImageID) => () => {
        axios.get('/medicalRecordImages/' + medicalRecordImageID + '/download')
            .then(resp => {
                this.setState({
                    pdfData: URL.createObjectURL(new Blob([resp.data], {type : "application/pdf"})),
                })
            })
    }

    renderTable = (results) => {
        const options = {
            page: this.state.page,
            pageStartIndex: 1,
            sizePerPage: this.state.sizePerPage,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return { value: item, text: item.toString() } }),
            sizePerPageDropDown: (props) => { return <SizePerPageDropDown variation='dropup' {...props} /> },
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    sizePerPage: sizePerPage
                },
                () => this.searchRoster(page));
            },
            onSizePerPageList: (sizePerPage) => {
                this.setState({
                    page: 1,
                    pageSize: sizePerPage
                },
                () => this.searchRoster(this.state.page));
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortName: sortName,
                    sortOrder: sortOrder === "desc" ? "asc" : "desc"
                },
                () => this.searchRoster(this.state.page));
            },
            defaultSortName: [this.state.sortName],
            defaultSortOrder: [this.state.sortOrder]
        };

        return (
                <BootstrapTable data={results} ref="table" options={options} maxHeight="400px" fetchInfo={{ dataTotalSize: this.state.rowCount }}
                    hover pagination remote>
                    <TableHeaderColumn dataField="memberID" dataAlign="left" dataSort isKey>Member ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberName" dataAlign="left" dataSort>Member Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfBirth" dataAlign="left" dataFormat={this.formatDateString} dataSort>Date of Birth</TableHeaderColumn>
                    <TableHeaderColumn dataField="lastLoadDate" dataAlign="left" dataFormat={this.formatDateString} dataSort>Last Load Date</TableHeaderColumn>
                    <TableHeaderColumn dataField="endOfLastScanDateRange" dataAlign="left" dataSort>End of Last Scan Date Range</TableHeaderColumn>
                    <TableHeaderColumn dataField="practiceID" dataAlign="left" dataSort>Practice ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="practiceName" dataAlign="left" dataSort>Appt. Practice Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerID" dataAlign="left" dataSort>Provider ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerName" dataAlign="left" dataSort>Provider Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerOrganization" dataAlign="left" dataSort>Provider Org</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberMasterID" dataFormat={this.formatUploadButton}>Actions</TableHeaderColumn>
                </BootstrapTable>
        );
    }

    closeUploadModal = () => {
        this.setState({
            showUploadModal: false,
            files: [],
        })
    }

    getFilteredPractices = () => {
        return this.state.providerID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.providerID ? practice : false;
            })
            : this.props.practices ? this.props.practices : [];
    }

    getProviderDesc = () => {
        return this.props.providers && this.state.providerID ? this.props.providers.find(x => x.id === this.state.providerID).description : '';
    }

    getPracticeDesc = () => {
        const groupingID = this.state.practiceIDText ? this.state.practiceIDText : this.state.practiceGroupingID;
        return this.props.practices && groupingID ? this.props.practices.find(x => x.groupingID === groupingID).description : '';
    }

    render() {
        const results = this.state.searchResults && this.state.searchResults.length > 0 ? this.state.searchResults : [];
        return (
            <div style={{'marginTop': '2rem'}}>
                <ScanRosterSearch providers={this.props.providers} practices={this.getFilteredPractices()} searchRoster={this.searchRoster}
                    clearSearch={this.clearSearch} providerID={this.state.providerID} practiceGroupingID={this.state.practiceGroupingID}
                    lastLoadDateBefore={this.state.lastLoadDateBefore} practiceIDText={this.state.practiceIDText}
                    setProvider={this.handleProviderChange} setPractice={this.handlePracticeChange}
                    setLastLoadDateBefore={this.handleLastLoadDateBeforeChange} setPracticeIDText={this.handlePracticeIDTextChange}
                    togglePrintModal={this.togglePrintModal} searchEnabled={this.state.searchEnabled} printEnabled={this.state.printEnabled} />
                <hr/>
                {this.renderTable(results)}
                <ScanRosterPrintModal visible={this.state.showPrintModal} togglePrintModal={this.togglePrintModal} router={this.props.router}
                    providerDesc={this.getProviderDesc} practiceDesc={this.getPracticeDesc} searchParams={
                        {
                            providerOrgID: this.state.providerID,
                            practiceID: this.state.practiceIDText ? this.state.practiceIDText : this.state.practiceGroupingID,
                            lastLoadDateBefore: this.state.lastLoadDateBefore,
                            pageSize: this.state.rowCount,
                            pageNumber: 1,
                            totalItems: this.state.rowCount,
                            sortBy: this.state.sortName,
                            sortByDesc: this.state.sortOrder === "desc"
                        }
                    } />
                <Modal show={this.state.showUploadModal} onHide={this.closeUploadModal} bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title>Upload PDF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <embed width="100%" src={this.state.pdfData} type="application/pdf" />
                        <BootstrapTable data={this.state.files} maxHeight="400px" hover pagination>
                            <TableHeaderColumn 
                                isKey={true} 
                                dataField="fileName" 
                                dataAlign="left"
                                dataSort
                                width="45%">
                                File Name
                            </TableHeaderColumn>
                            <TableHeaderColumn 
                                dataField="startMonth" 
                                dataAlign="left" 
                                dataFormat={this.formatDateString} 
                                dataSort
                                width="10%">
                                Start Month
                            </TableHeaderColumn>
                            <TableHeaderColumn 
                                dataField="endMonth" 
                                dataAlign="left" 
                                dataFormat={this.formatDateString} 
                                dataSort 
                                width="10%">
                                End Month
                            </TableHeaderColumn>
                            <TableHeaderColumn 
                                dataField="uploadDate" 
                                dataAlign="left" 
                                dataFormat={this.formatDateString} 
                                dataSort
                                width="10%">
                                Upload Date
                            </TableHeaderColumn>
                            <TableHeaderColumn 
                                dataField="uploadBy" 
                                dataAlign="left" 
                                dataSort
                                width="15%">
                                Uploaded By
                            </TableHeaderColumn>
                            <TableHeaderColumn 
                                dataField="medicalRecordImageID" 
                                dataFormat={this.formatImageTableActions}
                                width="10%">
                                Actions
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Modal.Body>
                </Modal>
                <AlertDialog visible={this.state.confirmingDelete} 
                    handleModalToggle={this.toggleConfirmDelete} title={"Delete File"}
                    message={"Are you sure you want to delete the file"} 
                    handleConfirm={this.deleteEntity(this.state.medicalRecordEntityIDToDelete)}
                    confirmLabel={"Confirm"} 
                    cancelLabel={"Cancel"}
                    confirmStyle={"success"}
                    glyphicon={"ok"}
                />

            </div>
        );
    }
}

export default ScanRosterContainer;

import React from 'react';
import { ProgressBar } from 'react-bootstrap';

class FileList extends React.Component {
    renderFileList = (file) => {
        return (
            <li key={file.name} style={{ textAlign: 'left', listStyleType: 'none' }}>
                {`'${file.name}' ${file.message}`}
                <ProgressBar now={file.uploadProgress} bsStyle={file.style} />
            </li>
        );
    }

    render() {
        if (this.props.files && this.props.files.length > 0) {
            return (
                <div>
                    <ul>
                        {this.props.files.map(file => this.renderFileList(file))}
                    </ul>
                </div>
            );
        }
        else {
            return (
                <div>No file uploaded</div>
            );
        }
    }
}

export default FileList;
import React from 'react';
import axios from 'axios';
import { Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Select from 'react-select';
import { MedicalRecordReviewStatus } from '../../../enums/MedicalRecordReviewStatus';
import { formatISODateOnly }  from '../../../utils/DateUtils';
import { SessionParamCache } from '../../../utils/SessionUtils';
import { ClientSource } from '../../../enums/ClientSource'

// todo: AllowPreExtractQAButton and ShowCoderQA permission checks are needed here -- pass them from LandingTabs (parent),
// then test completedReviewIsEditable
class Complete extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = 'completeSearch';
        this.savedParams = this.paramCache.getParamObj(this.paramCacheKey);

        this.state = {
            qaCoders: [],
            recordTypes: [],
            reviewStatuses: [],
            initialCoder: this.savedParams.initialCoder ? this.savedParams.initialCoder : '',
            qaCoder: this.savedParams.qaCoder ? this.savedParams.qaCoder : '',
            project: this.savedParams.project ? this.savedParams.project : '',
            recordType: this.savedParams.recordType ? this.savedParams.recordType : '',
            reviewStatus: this.savedParams.reviewStatus ? this.savedParams.reviewStatus : '',
            minCompletedDate: this.savedParams.minCompletedDate ? this.savedParams.minCompletedDate : '',
            maxCompletedDate: this.savedParams.maxCompletedDate ? this.savedParams.maxCompletedDate : '',
            planMemberID: this.savedParams.planMemberID ? this.savedParams.planMemberID : '',
            medicalRecordReviewID: this.savedParams.medicalRecordReviewID ? this.savedParams.medicalRecordReviewID : '',
            coderQAScored: this.savedParams.coderQAScored ? this.savedParams.coderQAScored : false,
            searchResults: [],
            page: 1,
            sizePerPage: 10,
            totalItems: 0,
            sortBy: '',
            sortByDesc: true,
            loaded: true,
            searchDisabled: false
        };

        this.datepickerMin = null;
        this.datepickerMax = null;
    }

    componentWillMount() {
        Promise.all([this.props.getFilteredCoders('QA Coder'), this.getReviewStatuses()])
        .then((responses) => {
            this.setState({
                qaCoders: responses[0].data,
                reviewStatuses: responses[1].data
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load search filters.', 'Error');
        })
    }

    componentDidMount() {
        if (this.savedParams.project) {
            this.getRecordTypes();
        }
    }

    getReviewStatuses = () => {
        return axios.get('/reviewStatuses');
    }

    getRecordTypes = () => {
        if (this.state.project) {
            axios.get(`/projects/${this.state.project.value}/referenceData?referenceDataType=medicalRecordType`)
            .then((response) => {
                if (response.data && response.data.referenceDataList && response.data.referenceDataList[0] && response.data.referenceDataList[0].referenceData) {
                    this.setState({ recordTypes: response.data.referenceDataList[0].referenceData });
                }
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load record types.', 'Error');
            });
        }
        else {
            this.setState({ recordTypes: [] });
        }
    }

    searchCompleteReviews = (page = this.state.page, sizePerPage = this.state.sizePerPage) => {
        //todo:
        // if ($scope.search.earliestDateCompleted && !$scope.search.latestDateCompleted) {
        //     $scope.completeErrorMessages.push('A Thru date must be supplied.');
        //     return;
        // }
        // if ($scope.search.latestDateCompleted && !$scope.search.earliestDateCompleted) {
        //     $scope.completeErrorMessages.push('An earliest date must be supplied.');
        //     return;
        // }
        this.setState({
            loaded: false,
            searchDisabled: true
        }, () => {
            const queryParams = {
                initialCoderUserID: this.state.initialCoder ? this.state.initialCoder.value : '',
                qaCoderUserID: this.state.qaCoder ? this.state.qaCoder.value : '',
                projectID: this.state.project ? this.state.project.value : '',
                recordTypeID: this.state.recordType ? this.state.recordType.value : '',
                reviewStatus: this.state.reviewStatus ? this.state.reviewStatus.value : '',
                //recordEntityType: this.state.recordEntityType -- is this used?
                earliestCompletedDate: this.state.minCompletedDate ? formatISODateOnly(new Date(this.state.minCompletedDate)) : '',
                latestCompletedDate: this.state.maxCompletedDate ? formatISODateOnly(new Date(this.state.maxCompletedDate)) : '',
                memberID: this.state.planMemberID,
                medicalRecordReviewID: this.state.medicalRecordReviewID,
                onlyReviewsThatHaveCompletedCoderQA: this.state.coderQAScored,
                orderByColumnName: this.state.sortBy,
                orderByDescending: this.state.sortByDesc,
                pageNumber: this.state.page,
                pageSize: this.state.sizePerPage
            };
            //todo: replace this with disabling the search button if the minimum amount of criteria isn't met, if needed.
            // the addition of pagination to the legacy app essentially disabled this validation, as the pagination and
            // coder QA toggle combination seem to count as three pieces of search criteria.
            // if (Object.entries(queryParams).filter(x => x[1]).length < 3) {
            //     this.props.toast('error', 'Please select more search criteria.', 'Error');
            // }
            // else {
                axios.get('/medicalrecordreview/completeReviews', {
                    params: queryParams
                })
                .then((response) => {
                    this.paramCache.addParamObj(this.paramCacheKey, {
                        initialCoder: this.state.initialCoder,
                        qaCoder: this.state.qaCoder,
                        project: this.state.project,
                        recordType: this.state.recordType,
                        reviewStatus: this.state.reviewStatus,
                        minCompletedDate: this.state.minCompletedDate,
                        maxCompletedDate: this.state.maxCompletedDate,
                        planMemberID: this.state.planMemberID,
                        medicalRecordReviewID: this.state.medicalRecordReviewID,
                        coderQAScored: this.state.coderQAScored
                    });
                    this.setState({
                        searchResults: response.data.items,
                        totalItems: response.data.totalItems,
                        loaded: true,
                        searchDisabled: false
                    });
                })
                .catch((error) => {
                    this.setState({
                        loaded: true,
                        searchDisabled: false
                    });
                     this.props.toast('error', 'Could not search reviews. If the problem persists, please select more search criteria.', 'Error');
                });
            //}
        });
    }

    handleInitialCoderChange = (e) => {
        this.setState({ initialCoder: e });
    }

    handleQACoderChange = (e) => {
        this.setState({ qaCoder: e });
    }

    handleProjectChange = (e) => {
        this.setState({ project: e }, this.getRecordTypes);
    }

    handleRecordTypeChange = (e) => {
        this.setState({ recordType: e });
    }

    handleReviewStatusChange = (e) => {
        this.setState({ reviewStatus: e });
    }

    handleMinCompletedDateChange = (e) => {
        if (e && this.validateCompletedDateRange(e, this.state.minCompletedDate)) {
            this.setState({ minCompletedDate: e });
        }
        else {
            this.setState({ minCompletedDate: '' });
        }
    }

    handleMaxCompletedDateChange = (e) => {
        if (e && this.validateCompletedDateRange(this.state.maxCompletedDate, e)) {
            this.setState({ maxCompletedDate: e });
        }
        else {
            this.setState({ maxCompletedDate: '' });
        }
    }

    validateCompletedDateRange = (min, max) => {
        const minDate = min ? new Date(min) : null;
        const maxDate = max ? new Date(max) : null;

        if (minDate && maxDate) {
            if (minDate >= maxDate) {
                this.props.toast('error', "Max completed date must be greater than or equal to the min completed date.", 'Error');
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    handleMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value })
    }

    handleMedicalRecordReviewIDChange = (e) => {
        const mrrID = e.target.value ? parseInt(e.target.value, 10) : '';
        this.setState({ medicalRecordReviewID: Number.isInteger(mrrID) ? mrrID : '' });
    }

    handleCoderQAScored = () => {
        this.setState({ coderQAScored: !this.state.coderQAScored });
    }

    getUserDropdownOptions = (users) => {
        return users.map((x) => {
            return {
                label: x.fullName,
                value: x.userID
            };
        });
    }

    getProjectDropdownOptions = (projects) => {
        return projects.map((proj) => {
            return {
                label: proj.projectName,
                value: proj.projectID
            };
        });
    }

    getDropdownOptions = (referenceData) => {
        return referenceData.map((x) => {
            return {
                label: x.description,
                value: x.value
            };
        });
    }

    getReviewStatusDropdownOptions = (reviewStatuses) => {
        return reviewStatuses.map((x) => {
            return {
                label: x.statusDescription,
                value: x.medicalRecordReviewStatusID
            };
        });
    }

    formatMemberName = (cell, row) => {
        return row ? `${row.memberFirstName} ${row.memberLastName}` : '';
    }

    redirectToReview = (reviewType, medicalRecordReviewID, recordType) => {
        //if Record Type is Scan and NLP process is enabled for the client and if the new NLP screen is enabled
        console.log("isNlpProcessEnabled? "); console.log(this.props.nlpProcessEnabledValue);
        console.log("recordType? "); console.log(recordType);
        if(recordType.toLowerCase() === 'scan' && this.props.nlpProcessEnabledValue === 'true' && this.props.newNLPScreenEnabled === 'true')
        {
            console.log("check review for Nlp Insights data");
            axios.get(`/mrrnlpapi/v1/ReviewHasInsightsData/${medicalRecordReviewID}`)
            .then((response) => {
                if(response.data && ((this.lineOfBusiness === 'MA' && this.props.maLobNlpProcessEnabled === 'true') || (this.lineOfBusiness === 'Commercial' && this.props.commLobNlpProcessEnabled === 'true')))
                    window.location.href = `/NLP/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
                else
                    window.location.href = `/ReviewProcessing/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
            }).catch((error) => {
                console.log('CompletedCoding screen error' + error);
                this.props.toast('error', "Could not view CompletedCoding Review.", 'Error');
            });    
        }
        else
            window.location.href = `/ReviewProcessing/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
    }


    //todo: test validation cases
    completedReviewIsEditable = (review) => {
        const isStars = this.props.projects.find(x => x.projectID === review.projectID && x.isStars);
        // var isStarsProgram = !!_.find($scope.projectsForCompleteTab, (item) => {
        //     return item.projectID === completedReview.projectID && item.isStars === true;
        // });

        const initialReviewCheck = review.initialCoderUserID === this.props.currentUser.userID
            && [MedicalRecordReviewStatus.EvaluationForQA, MedicalRecordReviewStatus.ReadyForQA,
                MedicalRecordReviewStatus.ReadyForMDQA].includes(review.reviewStatusID)
            && !review.hasBeenAssignedForQA;
        // var initialReviewCheck = !!(
        //     completedReview.initialCoderUserID == $scope.userID
        //     && [25, 30, 36].indexOf(completedReview.reviewStatusID) >= 0
        //     && completedReview.hasBeenAssignedForQA === false
        // );

        const preExtractCheck = isStars
            && this.props.showPreExtractQAButton
            && review.reviewStatusID === MedicalRecordReviewStatus.ExtractReview;
        // var preExtractCheck = !!(
        //     isStarsProgram === true
        //     && $scope.showPreExtractQAButton === true
        //     && completedReview.reviewStatusID === 39
        // );

        const coderQACheck = this.props.showCoderQA
            && review.reviewStatusID === MedicalRecordReviewStatus.SubmissionEvaluationComplete
            && !review.hasCoderQARecord
            && review.initialCoderUserID !== this.props.currentUser.userID;

        // var coderQACheck = !!(
        //     $scope.showCoderQA === true
        //     && completedReview.reviewStatusID === 50
        //     && completedReview.hasCoderQARecord === false
        //     && completedReview.initialCoderUserID != $scope.userID
        // );

        return initialReviewCheck || preExtractCheck || coderQACheck;
    }

    formatActions = (cell, row) => {
        //todo: confirm that this should change from "item.reviewStatus.id != 15 || item.reviewStatus.id == 35"
        if (row.reviewStatusID !== MedicalRecordReviewStatus.ReviewInProgress && row.reviewStatusID !== MedicalRecordReviewStatus.QAInProgress) {
            return (
                <Button onClick={() => this.redirectToReview('CompletedCoding', row.medicalRecordReviewID, row.medicalRecordTypeDesc)}>
                    {
                        this.completedReviewIsEditable(row) &&
                        <Glyphicon glyph="pencil" style={{ marginRight: '0.6rem'}} />
                    }
                    View
                </Button>
            );
        }
        else {
            return '';
        }
    }

    formatMemberID = (cell, row) => {
        if (row.sourceID) {
            return `${row.memberID} - ${ClientSource[row.sourceID]}`;
        } else {
            return row.memberID;
        }
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                }, this.searchCompleteReviews);
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortOrder === "desc",
                    page: 1
                }, this.searchCompleteReviews);
            }
        };

        return (
            <div style={{ marginTop: '2rem' }}>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Initial Reviewer
                            </ControlLabel>
                            <Select value={this.state.initialCoder} options={this.getUserDropdownOptions(this.props.initialCoders)}
                                onChange={this.handleInitialCoderChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                QA Reviewer
                            </ControlLabel>
                            <Select value={this.state.qaCoder} options={this.getUserDropdownOptions(this.state.qaCoders)}
                                onChange={this.handleQACoderChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Project
                            </ControlLabel>
                            <Select value={this.state.project ? this.state.project.value : ''} options={this.getProjectDropdownOptions(this.props.projects)}
                                onChange={this.handleProjectChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel>
                                Record Type
                            </ControlLabel>
                            <Select value={this.state.recordType} options={this.getDropdownOptions(this.state.recordTypes)}
                                onChange={this.handleRecordTypeChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Review Status
                            </ControlLabel>
                            <Select value={this.state.reviewStatus} options={this.getReviewStatusDropdownOptions(this.state.reviewStatuses)}
                                onChange={this.handleReviewStatusChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }}>Min Completed Date</ControlLabel>
                            <DatePicker value={this.state.minCompletedDate} onChange={this.handleMinCompletedDateChange} style={{ zIndex: 'auto' }}
                                ref={(ref) => this.datepickerMin = ref}  />
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }}>Max Completed Date</ControlLabel>
                            <DatePicker value={this.state.maxCompletedDate} onChange={this.handleMaxCompletedDateChange} style={{ zIndex: 'auto' }}
                                ref={(ref) => this.datepickerMax = ref}  />
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel>
                                Member ID
                            </ControlLabel>
                            <div>
                                <FormControl type="text" value={this.state.planMemberID} onChange={this.handleMemberIDChange} />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel>
                                MRR ID
                            </ControlLabel>
                            <div>
                                <FormControl type="text" value={this.state.medicalRecordReviewID}
                                    onChange={this.handleMedicalRecordReviewIDChange} />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormGroup style={{ marginTop: '1rem' }}>
                            <Button onClick={() => this.searchCompleteReviews(1)} disabled={this.state.searchDisabled}>
                                <Glyphicon glyph="search" style={{ marginRight: '0.2rem'}} /> Search
                            </Button>
                            <Checkbox checked={this.state.coderQAScored} onChange={this.handleCoderQAScored} style={{ display: 'inline-block', marginLeft: '3rem', verticalAlign: 'middle' }}>
                                Coder QA Scored
                            </Checkbox>
                        </FormGroup>
                    </Col>
                </Row>
                <div style={{ marginTop: '4rem' }}>
                    <Loader loaded={this.state.loaded} />
                    <BootstrapTable data={this.state.searchResults} options={options} hover pagination
                        remote fetchInfo={{ dataTotalSize: this.state.totalItems }}>
                        <TableHeaderColumn dataField="medicalRecordReviewID" isKey dataSort>MRR ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberID" dataFormat={this.formatMemberID} dataSort>Member ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberName" dataFormat={this.formatMemberName} dataSort>Member Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="projectName" dataSort>Project</TableHeaderColumn>
                        <TableHeaderColumn dataField="medicalRecordTypeDesc" dataSort>Record Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="reviewTypeDesc" dataSort>Review Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="reviewStatusDesc" dataSort>Review Status</TableHeaderColumn>
                        <TableHeaderColumn dataField="initialCoderName" dataSort>Initial Reviewer</TableHeaderColumn>
                        <TableHeaderColumn dataField="qaCoderName" dataSort>QA Reviewer</TableHeaderColumn>
                        <TableHeaderColumn dataField="lastUpdated" dataSort>Last Updated</TableHeaderColumn>
                        <TableHeaderColumn dataField="action" dataFormat={this.formatActions}>Action</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

export default Complete;
// exports Program - an immutable object 'enum'
// usage: import { Program } from '../../enums/Program';
const program = {
    Prospective: 1,
    Retrospective: 2,
    Stars: 3,
    RADV:  4,
    RiskMitigation: 5,
    ClaimAuditing: 6,
    FracturedImage: 7,
    Coding: 8,
    Opportunities: 9
};
Object.freeze(program);
export const Program = program;
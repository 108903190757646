import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

class AttestationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            diagnosis: [],
            attestation: null
        }
    }

    componentWillMount() {
        this.getRADVDetails(); 
        this.getAttestation();
    }

    getRADVDetails = () => {
        axios.get(`/MedicalRecordDiagRADVDetails/${this.props.row.card.medicalRecordDiagRADVDetailID}/CodingDetails`)
        .then((response) => {
            this.setState({
                diagnosis: response.data,
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Failed to load coding details.');
        });
    }

    updateAttestation = () => {
        if (this.state.diagnosis.length > 0) {
            if (this.state.diagnosis.length === 1) {
                axios.post(`/AttestationResponse/${this.state.diagnosis[0].medicalRecordDiagID}`)
                .then((response) => {
                    this.getAttestation();
                })
                .catch((error) => {
                    this.props.toast('error', 'Failed to update attestation.');
                });
            }
            else {
                const requests = this.state.diagnosis.map((diag) => {
                    return axios.post(`/AttestationResponse/${diag.medicalRecordDiagID}`);
                })
                Promise.all(requests)
                .then((response) => {
                    this.getAttestation();
                })
                .catch((error) => {
                    this.props.toast('error', 'Failed to update attestations.');
                });
            }
        }
        this.close();
    }

    getAttestation = () => {
        axios.get(`/AttestationResponse/${this.props.medicalRecordDiagID}`)
        .then((response) => {
            this.setState({attestation: response.data});
        })
        .catch((error) => {
            this.props.toast('error', 'Failed to load attestation.');
        });
    }

    open = () => {
        this.setState({ showModal: true });
    }

    close = () => {
        this.setState({ showModal: false });
    }

    render() {
        let diagCD = "";
        if (this.state.diagnosis.length > 0) {
            this.state.diagnosis.map((diag, index) => {
                if (index > 0) {
                    diagCD += ",";
                }
                return diagCD += " " + diag.diagnosisCD;
            });
        }
        const isRetrieved = this.state.attestation !== null && this.state.attestation.attestationReceivedFlag;
        const isAttested = this.props.row.attestation === 'Y';

        return (
            <div>
                <button className="buttonLink" onClick={this.open} style={{ display: isAttested && !isRetrieved? 'block' : 'none', fontWeight: 'bold' }}>{this.props.row.attestation}</button>
                <span style={{ display: isRetrieved? 'block' : 'none' }}>Retrieved</span>
                <span style={{ display: !isAttested && !isRetrieved? 'block' : 'none' }}>{this.props.row.attestation}</span>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <div hidden={!this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Attestation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                Has the attestation for {this.props.dos}-{this.props.dosThru} for Diagnosis Code {diagCD} been successfully retrieved?<br/>
                                NOTE: Selecting 'Yes' will mark this attestation as received and release this diagnosis for submission.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="success" onClick={this.updateAttestation} style={{ float: 'left' }}>Yes</Button>
                            <Button onClick={this.close}>No</Button>
                        </Modal.Footer>
                    </div>
                </Modal>  
            </div> 
        );
    }
}

export default AttestationModal;

import React from 'react';
import { connect } from 'react-redux';
import NavHeader from './NavHeader/NavHeader';
import '../styles/Container.css';
import '../styles/Ranking.css';

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

class Container extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                {<NavHeader router={this.props.router} currentUser={this.props.currentUser} />}
                <div id="container">
                    <div className="container-body">
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps
)(Container);
// usage: import { getRedirect } from '../../services/ReviewProcessing';
// takes redirect response and determine redirect url
//todo: remove the legacy coding routes from this + the relevant mrr-service endpoints
function getRedirect({ redirectAction, redirectController, params = {} }) {
    let redirectUrl = `${window.location.origin}`;

    //build redirect url based on existing routing scheme
    switch (redirectController) {
        case "Landing":
            switch (redirectAction) {
                case "Index":
                    redirectUrl = `${redirectUrl}/MRR`;
                    break;
                default:
                    break;
            }
            break;
        case "Coding":
            switch (redirectAction) {
                case "Coding":
                    redirectUrl = !params.isCDIQueue ? `${redirectUrl}/Coding/Coding?medicalRecordReviewId=${params.medicalRecordReviewId}&isCDIQueue=false`
                        : `${redirectUrl}/Coding/Coding?medicalRecordReviewId=${params.medicalRecordReviewId}&isCDIQueue=${params.isCDIQueue}`;
                    break;
                case "AutoReview":
                    redirectUrl = `${redirectUrl}/MRRAutoQueue/`;
                    break;
                case "InitialCoding":
                    //redirectUrl = `${redirectUrl}/MRRAutoQueue/InitialCoding?medicalRecordReviewId=${params.medicalRecordReviewId}&isCDIQueue=${params.isCDIQueue}`;
                    if(params.skip){
                        redirectUrl = `${redirectUrl}/MRRAutoQueue/InitialCoding/${params.medicalRecordReviewId}/${params.isCDIQueue}?skip=true&mrrId=${params.medicalRecordReviewId}`;
                    } else {
                        redirectUrl = `${redirectUrl}/MRRAutoQueue/InitialCoding/${params.medicalRecordReviewId}/${params.isCDIQueue}/`;
                    }
                    break;
                case "CompletedCoding":
                    //redirectUrl = `${redirectUrl}/MRRAutoQueue/CompletedCoding?medicalRecordReviewId=${params.medicalRecordReviewId}`;
                    redirectUrl = `${redirectUrl}/MRRAutoQueue/CompletedCoding/${params.medicalRecordReviewId}/`;
                    break;
                default:
                    break;
            }
            break;
        case "QA":
            switch (redirectAction) {
                case "Index":
                    redirectUrl = `${redirectUrl}/QA?medicalRecordReviewId=${params.medicalRecordReviewId}`;
                    break;
                default:
                    break;
            }
            break;
        case "CoderQA":
            //note: the "NextReview" route is handled in CodingContainer
            switch (redirectAction) {
                case "Index":
                    redirectUrl = `${redirectUrl}/CoderQA?medicalRecordReviewId=${params.medicalRecordReviewId}&coderQAQueueID=${params.coderQAQueueID}`;
                    break;
                default:
                    break;
            }
            break;
        case "EMR":
            //note: the "NextReview" route is handled in CodingContainer
            switch (redirectAction) {
                case "Access":
                    redirectUrl = `${redirectUrl}/EMR/Access/${params.emrInstanceID}/practice/${params.practiceID}/viewonly`;
                    break;
                default:
                    break;
            }
            break;
        case "NLP":
                switch (redirectAction) {
                    case "InitialCoding":
                        redirectUrl = `${redirectUrl}/NLP/InitialCoding?medicalRecordReviewId=${params.medicalRecordReview.medicalRecordReviewID}`;
                        console.log("NLP InitialCoding redirect url"); console.log(redirectUrl);
                        break;
                }   
                break; 
        default:
            break;
    }

    //map existing route to react scheme, if necessary
    if (redirectUrl.includes("medicalRecordReviewId")) {
        redirectUrl = redirectUrl.replace("medicalRecordReviewId", "medicalRecordReviewID");
    }
    if (redirectUrl.includes("/Coding/Coding")) {
        redirectUrl = redirectUrl.replace("/Coding/Coding", "/ReviewProcessing/InitialCoding");
    }
    else if (redirectUrl.includes("/Coding/CompleteReview")) {
        redirectUrl = redirectUrl.replace("/Coding/CompleteReview", "/ReviewProcessing/CompletedCoding");
    }
    else if (redirectUrl.includes("/QA")) {
        redirectUrl = redirectUrl.replace("/QA", "/ReviewProcessing/QA");
    }
    else if (redirectUrl.includes("/MD")) {
        redirectUrl = redirectUrl.replace("/MD", "/ReviewProcessing/MD");
    }
    else if (redirectUrl.includes("/CoderQA")) {
        redirectUrl = redirectUrl.replace("/CoderQA", "/ReviewProcessing/CoderQA");
    }

    window.location.replace(redirectUrl);
}

function getNextQueuedAlertWorkflow() {
    const lineOfBusiness = localStorage.getItem('lineOfBusiness');
    const alertReviewQueueKey = `cdiAlertReviewQueue-${lineOfBusiness}`;

    let queuedAlert;
    const queue = JSON.parse(sessionStorage.getItem(alertReviewQueueKey));
    if (queue && queue.length > 0) {
        //pop item from beginning of queue
        queuedAlert = queue.shift();
        sessionStorage.setItem(alertReviewQueueKey, JSON.stringify(queue));
    }
    return queuedAlert ? queuedAlert : null;
}

export { getRedirect, getNextQueuedAlertWorkflow };
//a hassle-free way to update an object-of-objects residing in sessionStorage
const SessionParamCache = {
    cacheName: '',
    storage: '',
    create: (cacheName, storage = 'session') => {
        if (!SessionParamCache.cacheName) {
            SessionParamCache.storage = storage === 'session' ?
                sessionStorage : localStorage;
            SessionParamCache.cacheName = cacheName;
            if (!SessionParamCache.storage.getItem(cacheName)) {
                SessionParamCache.storage.setItem(cacheName, JSON.stringify({}));
            }
            Object.freeze(SessionParamCache);
        }
        return SessionParamCache;
    },
    get: () => {
        if (SessionParamCache.cacheName) {
            const parent = SessionParamCache.storage.getItem(SessionParamCache.cacheName);
            return parent ? JSON.parse(parent) : null;
        }
        else {
            return null;
        }
    },
    getParamObj: (key) => {
        const parent = SessionParamCache.get();
        return parent && parent[key] ? parent[key] : {};
    },
    addParamObj: (key, value) => {
        const parent = SessionParamCache.get();
        if (parent) {
            parent[key] = filterEmptyProperties(value);
            SessionParamCache.storage.setItem(SessionParamCache.cacheName, JSON.stringify(parent));
        }
    },
    delete: () => {
        if (SessionParamCache.cacheName) {
            SessionParamCache.storage.removeItem(SessionParamCache.cacheName);
        }
    }
};

function filterEmptyProperties(obj) {
    return typeof(obj) === 'object' ?
        Object.entries(obj).reduce((acc, cur) => {
            if (cur[1]) {
                acc[cur[0].toString()] = cur[1];
            }
            return acc;
        }, {}) : null;
};

export { SessionParamCache };
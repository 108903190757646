import React from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import Error from './Error';

class Unauthorized extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            portalUrl: ''
        };
    }

    componentWillMount() {
        axios.get('/session/portalurl')
        .then((response) => {
            this.setState({ portalUrl: response.data });
        })
        .catch((error) => {
            this.props.router.push("/Error");
        });
    }

    renderError = () => {
        if (this.props.location.query && this.props.location.query.logout) {
            return null;
        }
        else {
            return <Error errorType={'auth'} />
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderError()}
                <Row>
                    <Col xs={10} xsOffset={1}>
                        <div style={{ textAlign: 'center', paddingTop: `${this.props.location.query &&
                            this.props.location.query.logout ? '20%' : '7%'}`, paddingBottom: '10%' }}>
                            <h1>Login required</h1>
                            <a href={this.state.portalUrl} style={{ color: 'inherit' }}>Click here to Login</a>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default Unauthorized;
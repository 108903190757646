import React from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class MemberInfoBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.encounterName = "";
    }

    noData = () =>
    {
        return(
            <Navbar fluid>
                <Nav>
                    <NavItem>
                        <NoData />
                    </NavItem>
                </Nav>
            </Navbar>

        ) 
    }

    memberInfo = (item) => {
        return(
            <Navbar fluid>
                <Nav>
                    <NavItem>
                        <div>
                            <span style={{ fontSize: 'large' }}>{`${item.full_name}`}</span>
                            <span>{` (ID: ${item.plan_member_id})`}</span>
                        </div>
                        <div>
                            <span>Encounters</span>
                            <span>{' >> '}</span>
                            <span>{`Encounter ${this.encounterName}`}</span>
                        </div>
                    </NavItem>
                </Nav>
            </Navbar>                       
        )
    }

    render() {
        
        this.encounterName = this.props.encounterDate;
        if (isEmpty(this.props.member) || !this.props.memberLoaded) {
            return this.noData();
        }
        else {
            const item = this.props.member;
            if (isEmpty(item)) {
                return this.noData();
            }
            else {
                return this.memberInfo(item);
            }
        }
    }
}

export default MemberInfoBar;
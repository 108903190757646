import React from 'react';
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

//todo: have page size options drop upwards(?)
class CDIReviewTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.getSelected()
        }
    };

    componentWillReceiveProps(nextProps) {
        //update selected state on initial load and startQueue calls
        if (nextProps.searchResults.length > 0 && nextProps.refreshSelected) {
            this.setState({ selected: this.getSelected(nextProps.searchResults) });
            this.props.toggleRefreshSelected();
        }
    }

    getSelected = (searchResults = this.props.searchResults) => {
        return searchResults ? searchResults.filter(x => x.selected).map(x => x.alertID) : [];
    }

    onRowSelect = ({ cdiAlertWorkflowID }, isSelected) => {
        //set isSelected
        const records = [...this.props.searchResults];
        const target = records.find(x => x.cdiAlertWorkflowID === cdiAlertWorkflowID);
        target.selected = isSelected;

        //update sessionStorage
        this.props.updateAlertQueue(cdiAlertWorkflowID, isSelected);

        //update selected state
        this.setState({ selected: this.getSelected(records) });

        //update search results (parent state)
        this.props.handleSetSearchResults(records);
    }

    //todo: consider making a batch version of updateAlertQueue
    onSelectAll = (isSelected, rows) => {
        const cdiAlertWorkflowIDs = rows.map(x => x.cdiAlertWorkflowID);
        const records = [...this.props.searchResults];
        let target;
        cdiAlertWorkflowIDs.forEach(x => {
            target = records.find(y => y.cdiAlertWorkflowID === x);
            target.selected = isSelected;
            this.props.updateAlertQueue(x, isSelected);
        });

        this.setState({ selected: this.getSelected(records) });
        this.props.handleSetSearchResults(records);
    }

    formatDate = (cell) => {
        return cell ? cell.split('T')[0] : '';
    }

    //todo: replace with icon / add icon?
    formatActions = (cell, row) => {
        return (
            <Button onClick={() => this.props.startAlertReview(row)}>Start Review</Button>
        )
    }

    render() {
        const options = {
            page: this.props.page,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange,
            onSortChange: this.props.onSortChange
        };
        const selectRowProp = {
            mode: 'checkbox',
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            selected: this.state.selected
        };

        return (
            <div style={{ marginTop: '4rem' }}>
                <BootstrapTable ref="alertReviewTable" data={this.props.searchResults} options={options} selectRow={selectRowProp}
                    hover pagination remote fetchInfo={{ dataTotalSize: this.props.totalItems }}>
                    <TableHeaderColumn dataField="alertID" isKey dataSort>Alert ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberID" dataSort>Member ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberName" dataSort>Member Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="practiceDesc" dataSort>Practice</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerID" dataSort>Provider ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerName" dataSort>Provider Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="appointmentDate" dataSort dataFormat={this.formatDate}>Appt. Date</TableHeaderColumn>
                    <TableHeaderColumn dataField="cdiReceivedDate" dataSort dataFormat={this.formatDate}>Captured Date</TableHeaderColumn>
                    <TableHeaderColumn dataField="createdByFullName" dataSort>PEC</TableHeaderColumn>
                    <TableHeaderColumn dataField="queryCreated" dataSort dataFormat={this.formatDate}>Query Created</TableHeaderColumn>
                    <TableHeaderColumn dataField="cdiAlertStatusDesc" dataSort>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" dataFormat={this.formatActions}>Action</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default CDIReviewTable;
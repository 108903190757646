// exports UserRole - an immutable object 'enum'
// usage: import { UserRole } from '../../enums/UserRole';
const UserRoles = {
    SuperUser: 1,
    OperationsManager: 2,
    ProjectAdministrator: 3,
    QualityAnalyst: 4,
    MedicalCoder: 5,
    MedicalDirector: 6,
    MRRManager: 7,
    EMRAdmin: 8,
    CodingManagerAdmin: 9,
    PECUsers: 10,
    L1Coder: 11,
    L2Coder: 12,
    QACoder: 13,
    RiskMitigation: 17
};
Object.freeze(UserRoles);
export const UserRole = UserRoles;
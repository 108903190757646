import React from 'react';
import axios from 'axios';
import { Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BootstrapTable, SizePerPageDropDown, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import MemberSelectionCombinedSearch from './MemberSelectionCombinedSearch';

class MemberSelectionCombinedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            page: 1,
            sizePerPage: 10,
            setSearchDefaults: true,
            sortName: [],
            sortOrder: [],
            loaded: true
        };
        this.addTooltip = (
            <Tooltip id="add"><strong>Add Member</strong></Tooltip>
        );
        this.removeTooltip = (
            <Tooltip id="remove"><strong>Remove Member</strong></Tooltip>
        );
    }

    //search members via selected params
    searchMembers = (params) => {
        this.setState({ loaded: false });
        axios.get('/alert/GetMemberByPCPSearch/', {
            params: {
                parentOrganizationID: params.providerID,
                practiceID: params.practiceGroupingID,
                memberFirstName: params.firstName,
                memberLastName: params.lastName,
                memberID: params.memberID,
                packageIDMemberWillBeAddedTo: this.props.packageID
            }
        })
        .then((response) => {
            this.setState({
                searchResults: response.data.items,
                page: 1,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.props.toast('error', 'Could not search members.', 'Error');
        });
    }

    getPrePrintRemoveCount = (searchResults) => {
        let assignedPracticeResultCount = 0;
        searchResults.forEach(result => {
            if (result.assignedGroupingID === this.props.practice.groupingID || result.assignedPractice === this.props.practice.desc) {
                assignedPracticeResultCount++;
            }
        });
        return assignedPracticeResultCount;
    }

    //add selected member to print list
    handleAddMemberClick = (rowIndex) => {
        this.props.addMember(this.refs.table.state.data[rowIndex].memberMasterID);
    }

    handleRemoveMemberClick = (rowIndex) => {
        this.props.removeMember(this.refs.table.state.data[rowIndex].memberMasterID);
    }

    //remove selected members from search results
    filterSearchResults = (results) => {
        if (this.props.selectedMembers && this.props.selectedMembers.length > 0) {
            const selectedMemberIDs = this.props.selectedMembers.map(member => {
                return member.memberMasterID;
            });
            return results.filter(member => {
                return !selectedMemberIDs.includes(member.memberMasterID);
            });
        }
        else {
            return results;
        }
    }

    //disable setting the default provider and practice values after the initial set
    disableSearchDefaults = () => {
        this.setState({ setSearchDefaults: false });
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatLongDateString = (date) => {
        return date ? date.substring(0, date.indexOf(' ')) : '';
    }

    formatAlert = (cell, row) => {
        if (cell) {
            let link = `/cdi/${cell}/`;
            if (row.activeMemberWorkflowStatusID === 1) {
                link += "update";
            }
            else {
                link += "viewonly";
            }

            return <a target="_blank" rel="noopener noreferrer" href={link}>{cell}</a>;
        }
    }

    memberIsAtPackagePractice = (rowIndex) => {
        return this.refs.table.state.data[rowIndex].assignedGroupingID === this.props.practice.groupingID;
    }

    //sort practice names alphabetically w/the assigned practice results first
    sortAssignedPracticeFirst = (a, b, order, sortField) => {
        const assignedPractice = this.props.practice.desc ? this.props.practice.desc.toLowerCase() : '';
        const practiceA = a.assignedPractice ? a.assignedPractice.toLowerCase() : '';
        const practiceB = b.assignedPractice ? b.assignedPractice.toLowerCase() : '';
        a = sortField !== "memberDateOfBirth" ? a[sortField] : new Date(a[sortField]);
        b = sortField !== "memberDateOfBirth" ? b[sortField] : new Date(b[sortField]);
        if(a === null) {
            a = "";
        }
        if(b === null) {
            b = "";
        }

        if (practiceA === assignedPractice && practiceB !== assignedPractice) {
            return -1;
        }
        else if (practiceB === assignedPractice && practiceA !== assignedPractice) {
            return 1;
        }
        else if (order === 'asc') {
            if (a < b) {
                return -1;
            }
            else if (a > b) {
                return 1;
            }
            else {
                return 0;
            }
        }
        else {
            if (a > b) {
                return -1;
            }
            else if (a < b) {
                return 1;
            }
            else {
                return 0;
            }
        }
    }

    onSortChange = (sortName, sortOrder) => {
        this.setState({
            sortName,
            sortOrder
        });
    }

    renderTable = (results, assignedPracticeResultCount) => {
        const customSelect = (props) => {
            const rowIndex = props.rowIndex;
            if (rowIndex === 'Header') {
                return null;
            }
            else {
                //calculate index relative to the entire (non-paged) data set
                const currentRowIndex = props.rowIndex + ((this.state.page - 1) * this.state.sizePerPage);
                //rows with an assigned practice matching the package's assigned practice are at the top of the search results - these should have a remove icon
                if (this.props.packageType === 'PrePrint' && currentRowIndex < assignedPracticeResultCount) {
                    return (
                        <div>
                            <OverlayTrigger placement="top" overlay={this.removeTooltip}>
                                <Button onClick={() => this.handleRemoveMemberClick(props.rowIndex)}>
                                    <Glyphicon glyph="minus" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            <OverlayTrigger placement="top" overlay={this.addTooltip}>
                                <Button onClick={() => this.handleAddMemberClick(props.rowIndex)}>
                                    <Glyphicon glyph="plus" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    );
                }
            }
        };
        const selectRow = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '7rem'
        };
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                });
            },
            sizePerPageList: [10, 25, 50, 100, 250],
            sizePerPageDropDown: (props) => { return <SizePerPageDropDown variation='dropup' {...props} /> },
            onSortChange: this.onSortChange,
            defaultSortName: this.state.sortName.length > 1 ? this.state.sortName : ['memberName', 'assignedPractice'],
            defaultSortOrder: this.state.sortOrder.length > 1 ? this.state.sortOrder : ['asc', 'asc']
        };

        return (
                <BootstrapTable data={results} ref="table" selectRow={selectRow} options={options} multiColumnSort={8} maxHeight="276px" scrollTop={"Top"}
                    containerClass="memberSelectionTable" hover pagination>
                    <TableHeaderColumn dataField="memberName" dataAlign="left" dataSort sortFunc={ this.sortAssignedPracticeFirst } width="17%">Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberID" dataAlign="left" dataSort sortFunc={ this.sortAssignedPracticeFirst } isKey width="6%">ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberDateOfBirth" dataAlign="left" dataFormat={this.formatLongDateString} dataSort sortFunc={ this.sortAssignedPracticeFirst } width="6%">DoB</TableHeaderColumn>
                    <TableHeaderColumn dataField="assignedPractice" dataAlign="left" dataSort sortFunc={ this.sortAssignedPracticeFirst } width="24%">Assigned Practice</TableHeaderColumn>
                    <TableHeaderColumn dataField="assignedProvider" dataAlign="left" dataSort sortFunc={ this.sortAssignedPracticeFirst } width="16%">Assigned Provider</TableHeaderColumn>
                    <TableHeaderColumn dataField="assignedProviderID" dataAlign="left" dataSort sortFunc={ this.sortAssignedPracticeFirst } width="8%">Provider ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="activeMemberAlertWorkflowID" dataAlign="left" dataFormat={this.formatAlert} dataSort sortFunc={ this.sortAssignedPracticeFirst } width="8%">Active Alert</TableHeaderColumn>
                    <TableHeaderColumn dataField="alertDeliveryDate" dataAlign="left" dataFormat={this.formatDateString} dataSort sortFunc={ this.sortAssignedPracticeFirst } width="8%">Alert Created</TableHeaderColumn>
                    <TableHeaderColumn dataField="alertLineCount" dataAlign="left" dataSort width="7%">Line Count</TableHeaderColumn>
                </BootstrapTable>
        );
    }

    render() {
        const results = this.state.searchResults && this.state.searchResults.length > 0 ? this.filterSearchResults(this.state.searchResults) : [];
        return (
            <div style={{minHeight: '433px'}}>
                <MemberSelectionCombinedSearch status={this.props.status} provider={this.props.provider} practice={this.props.practice} setDefaults={this.state.setSearchDefaults}
                    disableSearchDefaults={this.disableSearchDefaults} providers={this.props.providers} practices={this.props.practices} searchMembers={this.searchMembers} searchEnabled={this.state.loaded} />
                <div>
                    <Loader loaded={this.state.loaded}>
                        {this.renderTable(results, this.getPrePrintRemoveCount(results))}
                    </Loader>
                </div>
            </div>
        );
    }
}

export default MemberSelectionCombinedTable;

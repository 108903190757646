import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Radio, Row } from 'react-bootstrap';
import Select from 'react-select';

const PracticeSummaryFilters = (props) => {
    const handlePracticeCodeChange = (e) => {
        props.handleFilterFieldChange({ practiceCode: e.target.value });
    }

    const handlePracticeNameChange = (e) => {
        props.handleFilterFieldChange({ practiceName: e.target.value });
    }

    const handlePracticeStatusChange = (e) => {
        props.handleFilterFieldChange({ practiceStatus: e ? e.value : '' });
    }

    const handleRetrievalMethodChange = (e) => {
        props.handleFilterFieldChange({ retrievalMethod: e ? e.value : '' });
    }

    const handleProviderOrgCodeChange = (e) => {
        props.handleFilterFieldChange({ providerOrgCode: e.target.value });
    }

    const handleProviderOrgNameChange = (e) => {
        props.handleFilterFieldChange({ providerOrgName: e.target.value });
    }

    const handleHospitalMedicalCenterChange = (e) => {
        props.handleFilterFieldChange({ hospitalMedicalCenter: e.target.value });
    }

    const handlePracticeFullAddressChange = (e) => {
        props.handleFilterFieldChange({ practiceFullAddress: e.target.value });
    }

    const handleAccessTypeChange = (e) => {
        props.handleFilterFieldChange({ accessType: e ? e.value : '' });
    }

    const handlePracticeGroupingChange = (e) => {
        props.handleFilterFieldChange({ practiceGrouping: e ? e.value : '' });
    }

    const handleEngagedChange = (e) => {
        props.handleFilterFieldChange({ engaged: e ? e.value : '' });
    }

    const handleAccountAdminChange = (e) => {
        props.handleFilterFieldChange({ accountAdmin: e ? e.value : '' });
    }

    const handleServicesChange = (e) => {
        props.handleFilterFieldChange({ services: e });
    }

    const handleServicesToggle = () => {
        props.handleAddRefItemModalOpen();
    }

    return (
        <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            <Row>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Practice Code</ControlLabel>
                        <FormControl type="text" value={props.practiceCode} onChange={handlePracticeCodeChange}
                            maxLength="255" disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Practice Name</ControlLabel>
                        <FormControl type="text" value={props.practiceName} onChange={handlePracticeNameChange}
                            maxLength="255" disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Status</ControlLabel>
                        <Select value={props.practiceStatus} options={props.practiceStatusOptions}
                            onChange={handlePracticeStatusChange} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Retrieval Method</ControlLabel>
                        <Select value={props.retrievalMethod} options={props.retrievalMethodOptions}
                            onChange={handleRetrievalMethodChange} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Provider Org. ID</ControlLabel>
                        <FormControl type="text" value={props.providerOrgCode} onChange={handleProviderOrgCodeChange}
                            maxLength="50" disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Provider Org. Desc</ControlLabel>
                        <FormControl type="text" value={props.providerOrgName} onChange={handleProviderOrgNameChange}
                            maxLength="100" disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Hospital Medical Center</ControlLabel>
                        <FormControl type="text" value={props.hospitalMedicalCenter} onChange={handleHospitalMedicalCenterChange}
                            maxLength="255" disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Practice Full Address</ControlLabel>
                        <FormControl type="text" value={props.practiceFullAddress} onChange={handlePracticeFullAddressChange}
                            maxLength="500" disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>EMR Access Type</ControlLabel>
                        <Select value={props.accessType} options={props.accessTypeOptions}
                            onChange={handleAccessTypeChange} disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Practice Grouping</ControlLabel>
                        <Select value={props.practiceGrouping} options={props.practiceGroupingOptions}
                            onChange={handlePracticeGroupingChange} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Engaged Status</ControlLabel>
                        <Select value={props.engaged} options={props.engagedStatusOptions}
                            onChange={handleEngagedChange} disabled={props.practiceInformationMode} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Account Admin</ControlLabel>
                        <Select value={props.accountAdmin} options={props.accountAdminOptions}
                            onChange={handleAccountAdminChange} />
                    </FormGroup>
                </Col>
            </Row>
            {
                props.practiceInformationMode &&
                <div>
                    <Row>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>Services</ControlLabel>
                                <Select multi value={props.services} options={props.serviceOptions}
                                    onChange={handleServicesChange} />
                            </FormGroup>
                        </Col>
                        <Col xs={1}>
                            <div style={{ paddingTop: '2.4rem' }}>
                                <Button onClick={handleServicesToggle}>
                                    <Glyphicon glyph="plus" />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button onClick={props.handleSave} bsStyle="primary" style={{ float: 'right' }}>
                                <Glyphicon glyph="floppy-disk" style={{ marginRight: '0.6rem' }} />Save
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <hr />
                    </Row>
                </div>
            }
        </div>
    );
}

export default PracticeSummaryFilters;
import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import MemberDemographic from './MemberDemographic';

export default class MemberDemographicNewWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberDemo: null,
            radvEnrolleeID: null,
            chart: null,
            loaded: false
        };
    }

    componentWillMount() {
        axios.get(`/ReviewProcessingUIConfig/${this.props.params.reviewType}`, {
            params: {
                medicalRecordReviewID: this.props.location.query.medicalRecordReviewID,
                isCDIQueue: this.props.location.query.isCDIQueue || false
            }
        })
        .then((response) => {
            this.setState({
                radvEnrolleeID: response.data.isRADV && response.data.radvEnrollee ?
                    response.data.radvEnrollee.enrolleeID : null,
                chart: response.data.ekgChart || null
            });

            this.getMemberDemographic(response.data.memberInternalKey);
        })
        .catch((error) => {
            this.setState({ loaded: true });
            //todo: take action based on error response code  - ex. redirect to search screen; display error(s)
            //this.props.toast('error', 'Could not load coding config.', 'Error');
        });
    }

    getMemberDemographic = (memberInternalKey) => {
        if (memberInternalKey) {
            axios.get('/memberService/v1/member/' + memberInternalKey + '/memberDemographic/')
            .then((response) => {
                this.setState({
                    memberDemo: response.data,
                    loaded: true
                });
            })
            .catch((error) => {
                this.setState({ loaded: true });
                //todo: this.props.toast('error', 'Could not load member info.', 'Error');
            });
        }
    }

    render() {
        return (
            <Loader loaded={this.state.loaded}>
                <MemberDemographic
                    memberDemo={this.state.memberDemo}
                    radvEnrolleeID={this.state.radvEnrolleeID}
                    openNewWindow={null}
                    chart={this.state.chart}
                    isPopout={true} />
            </Loader>
        );
    }
}
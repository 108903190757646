import React from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import MemberSelectionTable from './MemberSelectionTable';
import PrintList from './PrintList';
import WorkflowHeaderDetails from './WorkflowHeaderDetails';

class MemberSelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includedMembers: [],
            excludedMembers: [],
            showConfirmAddDeleteModal: false,
            showConfirmRemoveDeleteModal: false,
            showConfirmRemoveDeleteAllModal: false,
            showConfirmAddDeleteAllModal: false,
            loaded: false
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillMount() {
        this.getMembersIncludedInPackage();
        if (this.props.packageType === 'PrePrint') {
            this.getMembersExcludedFromPackage();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.packageType === 'PrePrint') {
            this.getMembersExcludedFromPackage();
        }
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    getMembersIncludedInPackage = () => {
        axios.get('/packages/' + this.props.packageID + '/Members', {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                includedMembers: response.data.items
            });
            if (this.props.packageType !== 'PrePrint') {
                this.setState({
                    loaded: true
                });
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    //add member to package
    includeMemberInPackage = (memberID) => {
        axios.post('/packages/' + this.props.packageID + '/Members', {
            memberMasterID : memberID,
        })
        .then((response) => {
            this.setState({ 
                includedMembers: [...this.state.includedMembers].concat(response.data)
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    //remove all members from package
    deleteMembersIncludedInPackage = () => {
            axios.delete('/packages/' + this.props.packageID + '/Members')
            .then((response) => 
                this.setState({
                    includedMembers: [],
                    showConfirmAddDeleteAllModal: false
                })
            )
            .catch((error) => {
                this.props.toast('error', 'Could not remove members.', 'Error');
            });
    }

    //remove member from package
    deleteMemberIncludedInPackage = (memberID) => {
        axios.delete('/packages/' + this.props.packageID + '/Members/' + memberID, {
            packageId: this.props.packageID,
            memberId: memberID
        })
        .then((response) => {
            this.setState({
                includedMembers: [...this.state.includedMembers].filter(member => member.memberMasterID !== memberID),
                showConfirmAddDeleteModal: false
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    getMembersExcludedFromPackage = () => {
        axios.get('/packages/' + this.props.packageID + '/MemberExclusions')
        .then((response) => {
            this.setState({
                excludedMembers: response.data,
                loaded: true
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    excludeMemberFromPackage = (memberID) => {
        axios.post('/packages/' + this.props.packageID + '/MemberExclusions', {
            memberMasterID: memberID
        })
        .then((response) => {
            this.setState({
                excludedMembers: [...this.state.excludedMembers].concat(response.data)
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    //remove all members from package
    deleteMembersExcludedFromPackage = () => {
        axios.delete('/packages/' + this.props.packageID + '/MemberExclusions')
        .then((response) => 
            this.setState({
                excludedMembers: [],
                showConfirmRemoveDeleteAllModal: false
            })
        )
        .catch((error) => {
            this.props.toast('error', 'Could not remove members.', 'Error');
        });
    }
    
    //remove member from package
    deleteMemberExcludedFromPackage = (memberID) => {
        axios.delete('/packages/' + this.props.packageID + '/MemberExclusions/' + memberID, {
            packageId: this.props.packageID,
            memberId: memberID
        })
        .then((response) => {
            this.setState({
                includedMembers: [...this.state.includedMembers].filter(member => member.memberMasterID !== memberID),
                excludedMembers: [...this.state.excludedMembers].filter(member => member.memberMasterID !== memberID),
                showConfirmAddDeleteModal: false,
                showConfirmRemoveDeleteModal: false
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    handleConfirmDeleteAdd = () => {
        this.setState((prevState) => ({ showConfirmAddDeleteModal: !prevState.showConfirmAddDeleteModal }));
    }

    handleConfirmDeleteRemove = () => {
        this.setState({ 
            showConfirmRemoveDeleteModal: !this.state.showConfirmRemoveDeleteModal 
        });
    }

    handleConfirmDeleteAllRemove = () => {
        this.setState({
            showConfirmRemoveDeleteAllModal: !this.state.showConfirmRemoveDeleteAllModal
        });
    }
        
    handleConfirmDeleteAllAdd = () => {
        this.setState({
            showConfirmAddDeleteAllModal: !this.state.showConfirmAddDeleteAllModal
        });
    }

    render() {
        const removeList = this.props.packageType === 'PrePrint' ?
            <div style={{marginTop: '3rem'}}> 
                <PrintList label={'Removed Members'}
                    selectedMembers={this.state.excludedMembers} 
                    showConfirmRemoveModal={this.state.showConfirmRemoveDeleteModal}
                    showConfirmRemoveAllModal={this.state.showConfirmRemoveDeleteAllModal}
                    removeMember={this.deleteMemberExcludedFromPackage}
                    removeAllMembers={this.deleteMembersExcludedFromPackage} 
                    handleConfirmRemove={this.handleConfirmDeleteRemove}
                    handleConfirmRemoveAll={this.handleConfirmDeleteAllRemove} /> 
            </div> : null;
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <h3 className="workflow-title">Member Selection</h3>
                        {
                            this.state.loaded &&
                            <WorkflowHeaderDetails provider={this.props.provider.desc + ' (' + this.props.provider.id + ')'} practice={this.props.practice.desc} packageType={this.props.packageType}/>
                        }
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Loader loaded={this.state.loaded}>
                        <Col sm={9}>
                            <MemberSelectionTable packageType={this.props.packageType} selectedMembers={[...this.state.includedMembers].concat([...this.state.excludedMembers])}
                                addMember={this.includeMemberInPackage} removeMember={this.excludeMemberFromPackage} providers={this.props.providers} practices={this.props.practices}
                                provider={this.props.provider} practice={this.props.practice} packageID={this.props.packageID} toast={this.props.toast} />
                        </Col>
                        <Col sm={3} style={{marginTop: '2rem'}}>
                            <div>
                                <PrintList label={this.props.packageType === 'PrePrint' ? 'Additional Members' : 'Print List'}
                                    selectedMembers={this.state.includedMembers}
                                    showConfirmRemoveModal={this.state.showConfirmAddDeleteModal}
                                    showConfirmRemoveAllModal={this.state.showConfirmAddDeleteAllModal}
                                    removeMember={this.deleteMemberIncludedInPackage}
                                    removeAllMembers={this.deleteMembersIncludedInPackage}
                                    handleConfirmRemove={this.handleConfirmDeleteAdd}
                                    handleConfirmRemoveAll={this.handleConfirmDeleteAllAdd} />
                            </div>
                            {removeList}
                        </Col>
                    </Loader>
                </Row>
            </div>
        );
    }
}

export default MemberSelectionContainer;
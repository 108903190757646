import React from 'react'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Loader from 'react-loader';

class MedicalRecordDiagComments extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dataRecords: [],
            showModal: false,
            loaded: false
        }
    }

    close = () => {
        this.setState({ showModal: false });
    }

    open = () => {
        if (!this.props.medicalRecordDiagID) {
            this.props.toast('error', 'Could not open comments.');
            return;
        }
        else {
            this.setState({
                showModal: true,
                loaded: false
            }, () =>
                axios.get(`/MedicalRecordDiags/${this.props.medicalRecordDiagID}/Comments`)
                .then((response) => {
                    this.setState({
                        dataRecords: response.data,
                        loaded: true
                    });
                })
                .catch((error) => {
                    this.setState({
                        dataRecords: [],
                        loaded: true
                    });
                    this.props.toast('error', 'Failed to save load comments.');
                })
            );
        }
    }

    render() {
        return(
            <div>
                <button className="buttonLink" onClick={this.open} disabled={this.props.count === 0}>{this.props.count}</button>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Loader loaded={this.state.loaded}>
                        <Modal.Header closeButton>
                            <Modal.Title>Comment Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="landingSearchResults">
                                <BootstrapTable data={this.state.dataRecords} hover>
                                    <TableHeaderColumn dataField="commentText" isKey>Comments</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </Modal.Body>
                    </Loader>
                </Modal>
            </div>
        )
    }
}
export default MedicalRecordDiagComments;
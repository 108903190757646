import React from 'react';
import { DropTarget } from 'react-dnd';

const boxTarget = {
    drop(props, monitor) {
        if (props.onDrop) {
            props.onDrop(props, monitor)
        }
    }
}

function collect(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDropTarget: connect.dropTarget(),
        // You can ask the monitor about the current drag state:
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    };
}

class FileTargetBox extends React.Component {
    render() {
        return this.props.connectDropTarget(
            <div style={{ border: '1px solid gray', height: '20rem', width: '20rem', padding: '2rem', textAlign: 'center' }}>
                {this.props.canDrop && this.props.isOver ? 'Release to drop' : 'Choose a file or drag file here'}
            </div>
        );
    }
}

export default DropTarget(props => props.accepts, boxTarget, collect)(FileTargetBox);
import React from 'react';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { connect } from 'react-redux';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { HttpCode } from '../../../../enums/HttpCode';
import { EMRDocumentFileType } from '../../../../enums/EMRDocumentFileType';
import AlertToastr from '../../../Shared/AlertToastr';
import AlertDialog from '../../../Shared/AlertDialog';
import Loader from 'react-loader';
import Select from 'react-select';
import Error from '../../../Shared/Error';
import { getRedirect } from '../../../../services/ReviewProcessing';

const mapStateToProps = state => {
    const currentUserProp = state.session.find(x => x.currentUser);
    return currentUserProp ? currentUserProp : {};
};

class DocumentContainer extends React.Component {
    constructor(props) {
        super(props);

        this.fileUpload = React.createRef();
        this.state = {
            documents: [],
            documentTypes: [],
            selectedDocType: null,
            selectedDocumentID: '',
            mimeType: '',
            documentFileData: null,
            showDocumentError: false,
            tempDeleteDocumentID: null,
            showDeleteConfirmModal: false,
            loading: false,
            toast: null,
            readOnly: this.props.location.pathname.toLowerCase().includes("viewonly")
        };
    }

    componentDidMount() {
        this.getDocumentTypes();
        this.getDocuments();
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    getDocuments() {
        this.setState({ loading: true });
        let url = this.state.readOnly ? `/campaignDocument/${this.props.router.params.practiceID}/${this.state.readOnly & 1}`
            : `/campaignDocument?practiceID=${this.props.router.params.practiceID}`;
        axios.get(url)
            .then((response) => {
                this.setState({ 
                    documents: response.data,
                    loading: false
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                if (error.response.status === 403) {
                    this.state.toast('error', error.response.data.message, 'Error');
                    setTimeout(() => {
                        getRedirect({
                            redirectAction: "Index",
                            redirectController: "Landing"
                        });
                    }, 1000);
                } else {
                    this.state.toast('error', 'Cannot load documents.', 'Error');
                }
            })
    }

    getDocumentTypes() {
        axios.get(`/campaignDocument/campaignDocumentType`)
            .then((response) => {
                this.setState({ documentTypes: response.data.map(x => { return { label: x.campaignDocumentTypeDescription, value: x.campaignDocumentTypeID } }) })
            })
    }

    formatDocumentLink = (cell, row) => {
        return (
            <Button bsStyle="link" onClick={() => this.setSelectedDocument(cell, row.campaignDocumentID)}
                style={{ border: 'none', padding: '0px 0px' }}>{cell}</Button>
        );
    }

    formatDateTime = (dateTime) => {
        return dateTime ? `${new Date(dateTime).toLocaleDateString('en-US')} -
            ${new Date(dateTime).toLocaleTimeString('en-US')}` : '';
    }

    toggleDeleteConfirmModal = (row) => {
        this.setState({ 
            showDeleteConfirmModal: !this.state.showDeleteConfirmModal,
            tempDeleteDocumentID: !this.state.showDeleteConfirmModal ? row.campaignDocumentID : null
        });
    }

    setSelectedDocument = (filename, documentID) => {
        this.setState({ loading: true });
        axios.get(`/campaignDocument/${documentID}/file`, {
            responseType: 'arraybuffer'
        })
        .then((response) => {
            const extension = filename.split('.').pop();
            this.setState({
                selectedDocumentID: documentID,
                mimeType: response.headers['content-type'],
                documentFileData: URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] })),
                showDocumentError: false,
                loading: false
            });
            if (extension !== 'pdf') {
                FileDownload(response.data, filename)
            }
        })
        .catch((error) => {
            this.setState({
                showDocumentError: true,
                loading: false
            });
        });
    }

    validateFileType = (extension) => {
        return EMRDocumentFileType.some(t => t === extension);
    }

    handleDocTypeChange = (e) => {
        if (e) {
            this.setState({ selectedDocType: e.value })
        }
    }

    handleUpload = (e) => {
        if (!this.state.selectedDocType) {
            this.state.toast('error', `Document type cannot be empty`, 'Error');
            return;
        }

        const files = e.target ? Array.from(e.target.files) : Array.from(e);
        if (!files[0]) {
            return;
        }

        const extension = files[0].name.split('.').pop().toLowerCase();
        if (!this.validateFileType(extension)) {
            this.state.toast('error', `Cannot upload file of ${extension} type`, 'Error');
            return;
        }

        this.setState({ loading: true });

        const documentJson = JSON.stringify({
            campaignPracticeID: this.props.router.params.practiceID,
            campaignDocumentTypeID: this.state.selectedDocType
        });
        const data = new FormData();
        data.append('file', files[0]);

        axios.post(`/campaignDocument?serializedCampaignDocument=${documentJson}`, data)
        .then((response) => {
            this.getDocuments();
            this.state.toast('success', 'File uploaded', '');
        })
        .catch((error) => {
            this.setState({ showDeleteConfirmModal: false });
            if (error.response.status === HttpCode.InternalServerError) {
                this.state.toast('error', 'Could not upload file.', 'Error');
            }
            else {
                this.state.toast('error', error.response.data.message, 'Error');
            }
        });
    }

    handleDeleteDocumentCall = () => {
        if (!this.state.tempDeleteDocumentID) {
            this.state.toast('error', `No document selected to delete`, 'Error');
            return;
        }

        this.setState({ loading: true });

        axios.delete(`/campaignDocument/${this.state.tempDeleteDocumentID}`)
        .then((response) => {
            this.getDocuments();
            this.setState({ showDeleteConfirmModal: false });
            this.state.toast('success', 'File deleted.', '');
        })
        .catch((error) => {
            this.setState({ loading: false, showDeleteConfirmModal: false });
            this.state.toast('error', 'Could not delete file.', 'Error');
        })
    }

    handleEMRBackClick = () => {
        this.props.router.push(`/EMR/practices/`);
    }

    handlePracticeClick = () => {
        this.props.router.push(`/EMR/practices/${this.props.router.params.practiceID}`);
    }

    formatAction = (cell, row) => {
        return this.props.currentUser && this.props.currentUser.authorizedTabs.allowEMRDocumentAdmin ?
            (
                <Button onClick={() => this.toggleDeleteConfirmModal(row)} disabled={this.state.readOnly}>
                    <Glyphicon bsSize="large" glyph="trash" />
                </Button>
            ) : null
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={9} style={{ paddingLeft: '0rem' }}>
                        <h3 style={{ display: 'inline-block' }}>Documents</h3>
                        <hr/>
                    </Col>
                    <Col xs={3} style={{ paddingTop: '0.6rem', paddingRight: '0' }}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={this.handleEMRBackClick} style={{ marginRight: '1rem' }} disabled={this.state.readOnly} >
                                EMR Summary
                            </Button>      
                            <Button onClick={this.handlePracticeClick} style={{ marginRight: '1rem' }} disabled={this.state.readOnly}>
                                EMR Practice
                            </Button>                      
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem', paddingRight: '1rem', paddingLeft: '1rem' }}>
                    <BootstrapTable data={this.state.documents}>
                        <TableHeaderColumn dataField="campaignDocumentID" isKey hidden />
                        <TableHeaderColumn dataField="campaignDocumentName" width="40%" dataFormat={this.formatDocumentLink} dataSort>Document Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="campaignDocumentFileType" width="10%" dataSort>Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="createdDatetime" dataFormat={this.formatDateTime} dataSort>Uploaded Date</TableHeaderColumn>
                        <TableHeaderColumn width="10%" dataFormat={this.formatAction}>Action</TableHeaderColumn>
                    </BootstrapTable>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <Col xs={2}>
                        {
                            this.props.currentUser && this.props.currentUser.authorizedTabs.allowEMRDocumentAdmin &&
                            <FormGroup>
                                <ControlLabel>Document Type</ControlLabel>
                                <Select value={this.state.selectedDocType} options={this.state.documentTypes} onChange={this.handleDocTypeChange} />
                            </FormGroup>
                        }
                    </Col>
                    <Col xs={2}>
                        {
                            this.props.currentUser && this.props.currentUser.authorizedTabs.allowEMRDocumentAdmin &&
                            <div onClick={() => this.fileUpload.current.click()} className="dropbox" style={{ height: '12rem' }} >
                                <div className="message" style={{ paddingTop: '1rem' }}>
                                    <Glyphicon glyph="file" />
                                    <h5><b>Choose a file</b></h5>
                                </div>
                                <input ref={this.fileUpload} type="file" onChange={this.handleUpload}
                                    name="Upload" style={{ visibility: 'hidden' }} 
                                    disabled={this.state.readOnly} />
                            </div>
                        }
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    {
                        this.state.selectedDocumentID && this.state.documents.find(x => x.campaignDocumentID === this.state.selectedDocumentID) &&
                        <h5 style={{ paddingLeft: '1rem' }}>{this.state.documents.find(x => x.campaignDocumentID === this.state.selectedDocumentID).campaignDocumentName}</h5>
                    }
                    {
                        !this.state.showDocumentError && this.state.documentFileData && this.state.mimeType === 'text/plain' &&
                        <embed width="100%" height={window.innerHeight - 35} src={this.state.documentFileData} type={this.state.mimeType} />
                    }
                    {
                        !this.state.showDocumentError && this.state.documentFileData && (this.state.mimeType === 'image/png' || this.state.mimeType === 'image/jpeg') &&
                        <Col xs={8} xsOffset={2}>
                            <img maxHeight={window.innerHeight - 35} src={this.state.documentFileData} type={this.state.mimeType} />
                        </Col>
                    }
                    {
                        !this.state.showDocumentError && this.state.documentFileData && this.state.mimeType === 'application/pdf' &&
                        <embed width="100%" height={window.innerHeight - 35} src={this.state.documentFileData} type={this.state.mimeType} />
                    }
                    {
                        !this.state.showDocumentError && (this.state.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || 
                            this.state.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
                        <Error errorType={'previewNotSupported'} style={{ marginTop: '5rem' }} />
                    }
                    {
                        this.state.showDocumentError &&
                        <Error errorType={'default'} style={{ marginTop: '5rem' }} />
                    }
                </Row>
                <AlertDialog visible={this.state.showDeleteConfirmModal} handleModalToggle={this.toggleDeleteConfirmModal}
                        title={`Are you sure to delete this file?`}
                        renderBody={false} handleConfirm={this.handleDeleteDocumentCall} confirmLabel={"Yes"}
                        cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <AlertToastr setAddToast={this.setAddToast} />
                <Loader loaded={!this.state.loading} />
            </Grid>
        )
    }
}

export default connect(
    mapStateToProps
)(DocumentContainer);
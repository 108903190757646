import React from 'react';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import ProviderSearchModal from './ProviderSearchModal';
import ProviderTypeaheadSearchInput from './ProviderTypeaheadSearchInput';

// note: this is not suitable for use within a modal, as react-bootstrap does not natively support rendering multiple modals
// (a suggested solution is mentioned in the docs, if needed).
// as an alternative, use a combination of ProviderTypeaheadSearchInput and ProviderSearchModal, a la DiagnosisFormModal.
const ProviderTypeaheadSearch = (props) => {
    const typeaheadOnBlur = props.onBlur ? props.onBlur : () => {};

    // handle select of provider from provider search modal -- set provider state value and update typeahead
    const handleProviderSearchModalSelect = (internalKey, providerID, providerNPI, lastName, firstName) => {
        // when a selection is made from the ProviderNPISearchModal, the NPI is treated as the provider ID (aka planProviderID)
        if (internalKey) {
            const selectedProvider = {
                internalKey: internalKey,
                lastName: lastName,
                firstName: firstName,
                providerNPI: providerNPI,
                label: getProviderDropdownLabel(lastName, firstName, providerID, providerNPI, null)
            };
            props.handleProviderChange(selectedProvider);
        }
        else {
            this.props.toast('error', 'Could not select provider.', 'Error');
        }
    }

    const getProviderDropdownLabel = (lastName, firstName, providerID, providerNPI, alternateProviderIDs = null) => {
        let providerName = firstName ? `${lastName}, ${firstName}` : lastName;
        if(lastName.length === 0)
        {
            providerName = `${firstName}`;
        }
        if (alternateProviderIDs) {
            providerID = `${providerID}, ${alternateProviderIDs.join(', ')}`;
        }
        if (providerNPI && providerID) {
            return `${providerName} (${providerNPI} - ${providerID})`;
        }
        else if (providerNPI && !providerID) {
            return `${providerName} (NPI: ${providerNPI})`;
        }
        else if (!providerNPI && providerID) {
            return `${providerName} (Provider ID: ${providerID})`;
        }
        else {
            return `${providerName} (Missing ProviderID and NPI)`;
        }
    }

    return (
         <FormGroup bsClass="form-group provider-typeahead-form-group">
            <ControlLabel style={{ marginRight: '1rem' }} className={props.isRequired ? 'requiredField' : ''}>Provider</ControlLabel>
            <Row style={{ margin: 0 }}>
                <Col xs={10} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                    <ProviderTypeaheadSearchInput value={props.value} autoFocus={props.autoFocus}
                        disabled={props.disabled} onBlur={typeaheadOnBlur} handleProviderChange={props.handleProviderChange}
                        getProviderDropdownLabel={getProviderDropdownLabel} toast={props.toast} />
                </Col>
                <Col xs={2} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                    <Button bsSize="small" style={{ height: '3.6rem', minWidth: '4rem', width: '100%' }}
                        onClick={props.toggleProviderSearchModal} disabled={props.disabled}>
                        <Glyphicon glyph="search" />
                    </Button>
                </Col>
            </Row>
            <ProviderSearchModal visible={props.showProviderModal} value={props.value}
                handleModalToggle={props.toggleProviderSearchModal} handleSelectSearchResult={handleProviderSearchModalSelect}
                toast={props.toast} />
        </FormGroup>
    )
}

export default ProviderTypeaheadSearch;
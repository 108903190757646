// exports MedicalRecordReviewStatus - an immutable object 'enum'
// usage: import { MedicalRecordReviewStatus } from '../../enums/MedicalRecordReviewStatus';
const medicalRecordReviewStatuses = {
    Identified: 1,
    ReadyForReview: 10,
    Abandoned: 13,
    ReviewInProgress: 15,
    EvaluationForQA: 25,
    ReadyForQA: 30,
    QAInProgress: 35,
    ReadyForMDQA: 36,
    MDQAInProgress: 37,
    ExtractReview: 39,
    EvaluationForSubmission: 40,
    SubmissionEvaluationComplete: 50,
    ReevaluationForSubmission: 70
};
Object.freeze(medicalRecordReviewStatuses);
export const MedicalRecordReviewStatus = medicalRecordReviewStatuses;
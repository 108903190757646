import React from 'react';
import { Button, Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import NLPActionMenu from './NLPActionMenu';
import NLPReviewCommentModal from './NLPReviewCommentModal';
import { getRedirect } from '../../../../services/ReviewProcessing';
import { MedicalRecordReviewStatus } from '../../../../enums/MedicalRecordReviewStatus';
import AlertDialog from '../../../Shared/AlertDialog';
import axios from 'axios';

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

const hoursSpan = (startDate, endDate) => {
    var difference_In_Time = new Date(endDate).getTime() - new Date(startDate).getTime();
    return Math.ceil((difference_In_Time)/(1000 * 3600))
  }

class NLPActionBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProjectEditButton: false,
            showReviewCommentModal: false,
            showConfirmModal: false,
            showCoderQAReviewCommentModal: false,
            showProjectEditModal: false,
            comments: this.props.reviewProcessingUIConfig.comments ? this.sortComments([...this.props.reviewProcessingUIConfig.comments]) : []
        }
    }

    sortComments = (comments) => {
        return comments.sort((a, b) => {
            return (a.commentRank ? a.commentRank : Number.MAX_SAFE_INTEGER) - (b.commentRank ? b.commentRank : Number.MAX_SAFE_INTEGER)
                || a.commentText.localeCompare(b.commentText);
        });
    }

    setSelectedComments = (updatedComments) => {
        this.setState({ comments: this.sortComments(updatedComments) });
    }

    updateReview = (submitButton, getNextCDIAlertReview) => {
        //todo: confirm that commentDefinitionCD is the desired value (vs. commentDefinitionID)
        this.props.updateReview({
            selectedComments: this.state.comments.filter(x => x.isChecked === true).map(x => x.commentDefinitionCD).join(','),
            submitButton
        }, getNextCDIAlertReview);
    }

    updateAndGetNextNlpReview = (submitButton) => {
        //todo: confirm that commentDefinitionCD is the desired value (vs. commentDefinitionID)
        this.props.updateAndGetNextNlpReview({
            selectedComments: this.state.comments.filter(x => x.isChecked === true).map(x => x.commentDefinitionCD).join(','),
            submitButton
        });
    }

    toggleReviewCommentModal = () => {
        this.setState({ showReviewCommentModal: !this.state.showReviewCommentModal });
    }

    toggleConfirmModal = (action) => {
        let modalMessage = '';
        switch (action) {
            case 'forceQA':
                modalMessage = "Are you sure you want to perform a Force QA?"
                break;
            case 'reopen':
                modalMessage = "Reopen this review for editing?";
                break;            
            default:
                break;
        }        
        this.setState({
            showConfirmModal: !this.state.showConfirmModal,
            confirmAction: action,
            modalMessage: modalMessage
        });
    }

    handleConfirm = () => {
        switch (this.state.confirmAction) {
            case 'forceQA':
                this.forceQA();
                break;
            case 'reopen':
                this.editCompletedReview();
                break;            
            default:
                break;
        }
    }

    // force QA
    forceQA = () => {
        this.updateReview("Force QA", false);
    }

    editCompletedReview = () => {
        axios.post(`/medicalRecordReview/reopen?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`)
        .then((response) => {
            window.location.href = `NLP/InitialCoding?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`;
        })
        .catch((error) => {
            if (error.message) {
                this.props.toast('error', error.message, 'Error');
            }
            else {
                this.props.toast('error', 'Could not reopen review.', 'Error');
            }
        })
    }


    returnToLanding = () => {
        getRedirect({
            redirectAction: "Index",
            redirectController: "Landing"
        });
    }

    render() {
        return (
            <div>
                <Navbar fluid bsStyle='inverse'>
                    <Nav pullLeft bsClass="nav nav-no-padding">
                        <NavItem>
                            <div className="coding-actionbar-desc-div">
                                <span className="topLogo">
                                    <b>Project: </b> {this.props.reviewProcessingUIConfig.projectName}
                                </span>
                                {
                                    this.props.reviewProcessingUIConfig.practiceName && 
                                    this.props.reviewProcessingUIConfig.practiceName.trim() &&
                                    <span className="topLogo">
                                        <b>Practice Name: </b>{this.props.reviewProcessingUIConfig.practiceName}
                                    </span>
                                }
                                <span className="topLogo">
                                    <b>MRR ID: </b>{this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                                </span>
                                <span className="topLogo">
                                    <b>NPI: </b>{this.props.reviewProcessingUIConfig.providerNPI ?
                                        this.props.reviewProcessingUIConfig.providerNPI : 'Not available'}
                                    <span className="tooltipText">
                                        {this.props.reviewProcessingUIConfig.providerFullName ?
                                            this.props.reviewProcessingUIConfig.providerFullName : 'Not available'}
                                    </span>
                                </span>
                            </div>
                        </NavItem>
                    </Nav>
                    <Nav pullRight>
                        {
                            hoursSpan(this.props.reviewProcessingUIConfig.expectedDatetime, new Date()) >= 24 &&
                            this.props.reviewProcessingUIConfig.isCoderQA &&
                            <NavItem className="scorecardButton">
                                <Button onClick={() => this.toggleConfirmModal('forceQA')}>
                                    <Glyphicon bsSize="large" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Force QA
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display force QA
                            hoursSpan(this.props.reviewProcessingUIConfig.expectedDatetime, new Date()) >= 24 &&
                            this.props.reviewProcessingUIConfig.userCanForceQA &&
                            <NavItem className="forceQAButton">
                                <Button onClick={() => this.toggleConfirmModal('forceQA')}>
                                    <Glyphicon bsSize="large" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Force QA
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display reopen icon in completed reviews
                            this.props.reviewProcessingUIConfig.initialReviewCompletionDate &&
                            hoursSpan(this.props.reviewProcessingUIConfig.initialReviewCompletionDate, new Date()) <= 24 &&
                            this.props.reviewProcessingUIConfig.initialCoderUserID === this.props.reviewProcessingUIConfig.userID &&
                            !this.props.reviewProcessingUIConfig.hasPassedCoding &&
                            (this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.EvaluationForQA
                                || this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.ReadyForQA
                                || this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.ReadyForMDQA) &&
                            <NavItem className="reopenButton">
                                <Button onClick={() => this.toggleConfirmModal('reopen')}>
                                    <Glyphicon bsSize="large" glyph="pencil" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Reopen
                                </Button>
                            </NavItem>
                        }
                        {
                            <NavItem>
                                <Button onClick={this.toggleReviewCommentModal}>
                                    <Glyphicon glyph="comment" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}} /> Comments
                                </Button>
                            </NavItem>
                        }
                        {
                            this.props.reviewProcessingUIConfig.isComplete &&
                            <NavItem>
                                <Button onClick={this.returnToLanding}>
                                    Return to Landing
                                </Button>
                            </NavItem>
                        }
                        {
                            !this.props.reviewProcessingUIConfig.isComplete &&
                            <NavItem componentClass="span">
                                <NLPActionMenu reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} updateReview={this.updateReview}
                                    getNextAlertReviewDefault={this.props.getNextAlertReviewDefault} updateAndGetNextNlpReview={this.updateAndGetNextNlpReview}/>
                            </NavItem>
                        }
                    </Nav>
                </Navbar>
                <NLPReviewCommentModal comments={this.state.comments} reviewHasAddedRecords={this.props.reviewHasAddedRecords}
                    setSelectedComments={this.setSelectedComments} setPECCorrectionCommentSelected={this.props.setPECCorrectionCommentSelected}
                    visible={this.state.showReviewCommentModal} handleModalToggle={this.toggleReviewCommentModal} reviewType={this.props.reviewType} />
                <AlertDialog visible={this.state.showConfirmModal} handleModalToggle={this.toggleConfirmModal} title={this.state.modalMessage}
                renderBody={false} handleConfirm={this.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
            </div>
        )
    }
}

export default connect(
    mapStateToProps
)(NLPActionBar);
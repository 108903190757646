import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Loader from 'react-loader';
import Select from 'react-select';
import '../../styles/Report.css';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import CoderQAReviewLevelModal from './CoderQAReviewLevelModal';
import { sortByLowercase } from '../../utils/SortUtils';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

class CoderQAScoringReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: [],
            reportSectionData: [],
            currentAuditProject: {
                projectName: "",
                projectID: null
            },
            noResults: false,
            hideChartButton: true,
            displayCSVDownloader: false,
            selectedCoders: [],
            selectedCoderID: null,
            selectedProjects: [],
            errorMessage: null,
            dateFrom: null,
            dateThru: null,
            showReport: false,
            loaded: true,
            coderQAReviewLevelData: [],
            checkOrder: "asc",
            copied: "",
            copiedTimer: false,
        }
    }

    componentWillMount() {
        this.getMedicalCoderList();
        this.getProjectsList();
    }

    getMedicalCoderList = () => {
        axios.get('/MerlinUser/?filter=Initial%20Coder')
        .then((response) => {
            this.setState({ medicalCoders: response.data.map(x => { return { label: x.fullName, value: x.userID }; }) });
        });
        // .catch((error) => {
        //     //todo: add error notification
        // });
    }

    getProjectsList = () => {
        axios.get('/applicationreports/1/projects')
        .then((response) => {
            this.setState({ projects: response.data.map(x => { return { label: x.projectName, value: x.projectID }; }) });
        });
        // .catch((error) => {
        //     //todo: add error notification
        // });
    }

    handleCoderChange = (e) => {
        if (e) {
            this.setState({ selectedCoders: e.map(x => x.value) });
        }
    }

    handleDateFromChange = (dateFrom) => {
        this.setState({ dateFrom: dateFrom })
    }

    handleDateThruChange = (dateThru) => {
        this.setState({ dateThru: dateThru });
    }

    handleProjectChange = (e) => {
        if (e) {
            this.setState({ selectedProjects: e.map(x => x.value) });
        }
    }

    handleViewScoresClick = () => {
        this.setState({ loaded: false });
        const dateFrom = this.state.dateFrom ? new Date(this.state.dateFrom) : null;
        const dateThru = this.state.dateThru ? new Date(this.state.dateThru) : null;
        const selectedCoders = this.state.selectedCoders.length === 0 ? this.state.medicalCoders.map(x => x.value) : this.state.selectedCoders;
        const selectedProjects = this.state.selectedProjects.length === 0 ? this.state.projects.map(x => x.value) : this.state.selectedProjects;

        axios.post('/Reports/CoderQA/CoderQAScoringReport/', {
            MedicalCoderIDs: selectedCoders,
            CompletedStartDate: dateFrom,
            CompletedThroughDate: dateThru,
            ProjectIDs: selectedProjects
        })
        .then((response) => {
            response.data = response.data.sort(sortByLowercase("fullname"));

            let reportSectionDataArr = [];
            if (response.data && response.data[0]) {
                reportSectionDataArr = response.data[0].reportSectionData;
            }

            if (response.data.length > 0) {
                this.setState({
                    reportData: response.data,
                    reportSectionData: reportSectionDataArr,
                    showReport: true,
                    loaded: true,
                    displayCSVDownloader: true,
                    noResults: false
                });
            }
            else {
                this.setState({
                    reportData: [],
                    reportSectionData: [],
                    showReport: false,
                    loaded: true,
                    displayCSVDownloader: false,
                    noResults: true
                });
            }
        })
        .catch((error) => {
            if (error.response) {
                this.setState({
                    errorMessage: error.response && error.response.data && error.response.data.message ? error.response.data.message.substring(2, error.response.data.message.length - 2)
                        : "An error has occurred.",
                    loaded: true,
                    displayCSVDownloader: false,
                    reportData: [],
                    reportSectionData: [],
                    showReport: false
                }, () => {
                    setTimeout(() => {
                        this.setState({ errorMessage: null });
                    }, 5000)
                });
            }
        });
    }

    buildFunctionToSendToDownloadLink = () => {
        const selectedCoders = this.state.selectedCoders.length === 0 ? this.state.medicalCoders.map(x => x.value) : this.state.selectedCoders;
        const selectedProjects = this.state.selectedProjects.length === 0 ? this.state.projects.map(x => x.value) : this.state.selectedProjects;

        return axios.post("/ApplicationReports/DownloadRequests", {
            ApplicationReportID: "3",
            ReportParameters: {
            MedicalCoderIDs: JSON.stringify(selectedCoders),
            CompletedStartDate:  new Date(this.state.dateFrom ),
            CompletedThroughDate: new Date( this.state.dateThru) ,
            ProjectIDs: JSON.stringify(selectedProjects)
            }
        });
    }

   

    filterCoders = (index, column) => {
        //todo: this sort is questionable...
        const sortedData = [...this.state.reportData].sort((a, b) => {
                const rowA = a.reportSectionData[index];
                const valA = rowA[column];
                const rowB = b.reportSectionData[index];
                const valB = rowB[column];
                return (valA > valB) ? 1 : ((valB > valA) ? -1 : 0)
        });

        if (this.state.checkOrder === "desc") {
            this.setState({
                reportData: sortedData.reverse(),
                checkOrder: "asc"
            });
        }
        else {
            this.setState({
                reportData: sortedData,
                checkOrder: "desc"
            });
        }
    }

    copyToClipboard = (e) => {
        const element = this.table;
        const selection = window.getSelection();
        const range = window.document.createRange();
        try {
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.setState({ copied: "Copied.", copiedTimer: true });
            setTimeout(() => {
                this.setState({ copiedTimer: false })
            }, 3000);
        }
        catch (e) {
            this.setState({ copied: "Could not copy. Try again." });
        }
        selection.removeAllRanges();
    }

    //todo: replace copy, error, and no results messages with toasts
    render() {
        return (
            <div className="reportContainer">
                <div className="innerReportContainer">
                    <br/>
                    <header>
                        <Col style={{ marginLeft: 'auto', marginRight: 'auto', width: '300px', marginBottom: '2.6rem' }}>
                            <h3>Coder QA Scoring Report</h3>
                        </Col>
                    </header>
                    <Row>
                        <Col xs={3}>
                            <ControlLabel>Medical Coder</ControlLabel>
                            <Select multi value={this.state.selectedCoders}
                                options={this.state.medicalCoders
                                    ? this.state.medicalCoders
                                    : []}
                                onChange={this.handleCoderChange}
                                placeholder="All Coders" />
                        </Col>
                        <Col xs={2}>
                            <ControlLabel>Initial Review Completion</ControlLabel>
                            <DatePicker value={this.state.dateFrom} onChange={this.handleDateFromChange}
                                showTodayButton={true} todayButtonLabel="Today"></DatePicker>
                        </Col>
                        <Col xs={2}>
                            <ControlLabel>Thru</ControlLabel>
                            <DatePicker value={this.state.dateThru} onChange={this.handleDateThruChange}
                                showTodayButton={true} todayButtonLabel="Today"></DatePicker>
                        </Col>
                        <Col xs={3}>
                            <ControlLabel>Project</ControlLabel>
                            <Select multi value={this.state.selectedProjects}
                                options={this.state.projects
                                    ? this.state.projects
                                    : []}
                                onChange={this.handleProjectChange}
                                placeholder="All Projects" />
                        </Col>
                        <Col xs={2}>
                            <ControlLabel style={{ display: 'block', marginTop: '0.3rem' }}>&nbsp;</ControlLabel>
                            <Button bsStyle="primary" onClick={this.handleViewScoresClick}>View Scores</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={{ marginBottom: '2rem' }}>
                            <ControlLabel style={{ display: 'block' }}>&nbsp;</ControlLabel>
                            <Button bsStyle="info" onClick={this.copyToClipboard}>Copy</Button>
                            <LongRunningDownloadAnchor linkText="Download .CSV" glyph="file"
                                postRequest={this.buildFunctionToSendToDownloadLink}
                                displayCSVDownloader buttonStyle={{ marginLeft: '1rem' }} />
                            <ControlLabel style={{ display: this.state.copiedTimer ? 'inline-block' : 'none' }}>{this.state.copied !== "" ?  this.state.copied : ""}</ControlLabel>
                            <ControlLabel style={{ display: this.state.noResults ? 'inline-block' : 'none' }}>No results were returned.</ControlLabel>
                        </Col>
                        <Col xs={3}>
                            <ControlLabel style={{ color: 'red', display: !this.state.noResults ? 'inline-block' : 'none' }}>{this.state.errorMessage}</ControlLabel>
                        </Col>
                    </Row>
                    <div className="copyContent" ref={(table) => this.table = table} style={{ overflowX: 'auto', width: '1000px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Loader loaded={this.state.loaded}>
                            <Table className="coderQAScoringReportTable" hidden={!this.state.showReport} bordered>
                                <tbody>
                                    <tr className="tableHeaderRow">
                                        <th className="blankHeader" style={{ width: '233px', minWidth: '233px', borderRight: '1px solid #c1c1c1', borderTop: 'none', borderColor: '#c1c1c1' }}></th>
                                        <th style={{ minWidth: "233px", width: "233px" }}></th>
                                        {this.state.reportSectionData && this.state.reportSectionData.map((month, i) => {
                                            return (
                                                <th key={i} colSpan="1" style={{ width: '100px', textAlign: 'center', borderTop: 'none', borderColor: '#c1c1c1' }}>{month.completedLabel}</th>
                                            );
                                        })}
                                    </tr>
                                    <tr className="tableHeaderRow">
                                        <th className="fixedHeader" style={{ width: '233px', minWidth: '233px', borderRight: '1px solid #c1c1c1', borderTop: 'none', borderColor: '#c1c1c1' }}>Medical Coder</th>
                                        <th style={{ minWidth: "233px", width: "233px" }}></th>
                                        {this.state.reportSectionData.map((month, i) => {
                                            return (
                                                <th key={i} className="coderQARowData" style={{ borderRight: '1px solid #c1c1c1', borderColor: '#c1c1c1' }}>
                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <th onClick={() => this.filterCoders(i, 'totalReviews')}
                                                                    style={{ width: '33%', minWidth: '100px', cursor: 'pointer', textAlign: 'center', border: 'none' }}># Reviews<span></span></th>
                                                                <th onClick={() => this.filterCoders(i, 'qAedReviews')}
                                                                    style={{ width: '33%', minWidth: '100px', cursor: 'pointer', textAlign: 'center', border: 'none' }}># Coder QA<span></span></th>
                                                                <th onClick={() => this.filterCoders(i, 'averageScore')}
                                                                    style={{ width: '33%', minWidth: '100px', cursor: 'pointer', textAlign: 'center', border: 'none' }}>Avg. Score<span></span></th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    {
                                        this.state.reportData.map((coder, i) => {
                                            return (
                                                <tr key={i} className="coderQAScoringReportInnerTableRow">
                                                    <th className="coderQAScoringReportNameColumn fixedHeader" style={{ width: '233px', minWidth: '200px', borderRight: '1px solid #c1c1c1', backgroundColor: '#ececec', borderColor: '#c1c1c1' }}>
                                                        <CoderQAReviewLevelModal displayValue={coder.fullname} medicalCoderIDs={coder.medicalCoderID}
                                                            completedStartDate={new Date(this.state.dateFrom)} completedThroughDate={new Date(this.state.dateThru)}
                                                            projectIDs={this.state.selectedProjects} totalAverageScore={coder.reportSectionData[coder.reportSectionData.length - 1].averageScore} currentUser={this.props.currentUser}/>
                                                    </th>
                                                    <th style={{ minWidth: "233px", width: "233px" }}></th>
                                                    {coder.reportSectionData.map((month, i) => {
                                                        return (
                                                            <td key={i} className="coderQARowData" style={{ borderRight: '1px solid #c1c1c1', borderColor: '#c1c1c1' }}>
                                                                <Table>
                                                                    <tbody>
                                                                        <tr className="coderQAScoringReportInnerTableRow">
                                                                            <td style={{ width: '33%', textAlign: 'center', border: 'none' }}>{month.totalReviews}</td>
                                                                            <td style={{ width: '33%', textAlign: 'center', border: 'none' }}>{month.qAedReviews}</td>
                                                                            <td style={{ width: '33%', textAlign: 'center', border: 'none' }}>{month.averageScore == null ? 'N/A' : month.averageScore}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Loader>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(CoderQAScoringReport);
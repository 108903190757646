import React from 'react';
import axios from 'axios';
import { Modal, Button, Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { dateOfServiceValidation }  from '../../../../../utils/ValidationUtils';
import DatePicker from 'react-16-bootstrap-date-picker';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus';
import OpportunityDiagnosisLookupModal from './OpportunityDiagnosisLookupModal';

class OpportunityFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.defaultOppObject = {
            dateOfServiceFrom: '',
            dateOfService: '',
            commentGroupID: '',
            opportunityCD: '',
            diagnosisCD: '',
            diagnosisCodes: null,
            opportunityText: '',
            medicalRecordOpportunityID: null
        };

        this.state = {
            oppObj: { ...this.defaultOppObject },
            editModalRow: null,
            showLookupModal: false,
            selectedDXCode: null,
            textToShowAlertOptionsAvailable: null,
            validationErrorMessages: [],
            wordCount: 500
        };

        this.validationFields = ['dateOfServiceFrom', 'opportunityCD', 'diagnosisCD'];
        this.minDateOfService = "2012-01-01T12:00:00.000Z";
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            visible: nextProps.visible,
            editModalRow: nextProps.editModalRow ? nextProps.editModalRow : null,
            textToShowAlertOptionsAvailable: nextProps.opportunityReviewComments
        }, () => {
            if (this.state.editModalRow) {
                this.populateEditFields();
            }
        });
    }

    // populate form fields on edit
    populateEditFields = () => {
        this.setState({
            oppObj: {
                ...this.state.oppObj,
                medicalRecordOpportunityID: this.state.editModalRow.medicalRecordOpportunityID,
                dateOfServiceFrom: new Date(this.state.editModalRow.dateOfServiceFrom).toISOString(),
                dateOfService: new Date(this.state.editModalRow.dateOfServiceFrom),
                opportunityCD: this.state.editModalRow.opportunityCD,
                diagnosisCD: this.state.editModalRow.diagnosisCD,
                commentGroupID: this.props.opportunityReviewComments.find(x => {
                    return x.commentDefinitionCD === this.state.editModalRow.opportunityCD
                }).commentGroupID,
                diagnosisCodes: this.props.opportunityReviewComments.find(x => {
                    return x.commentDefinitionCD === this.state.editModalRow.opportunityCD
                }).icD10,
                opportunityReviewStatusID: this.state.editModalRow.opportunityReviewStatusID,
                opportunityText: this.state.editModalRow.opportunityText,
                updatedDateTime: this.state.editModalRow.updatedDateTime,
                updatedUserID: this.state.editModalRow.updatedUserID,
                createdUserID: this.state.editModalRow.createdUserID
            }
        }, () => {
            this.getWordCount();
        })
    }

    // on close of modal without save/edit, clear modal data
    handleModalToggle = () => {
        this.props.handleModalToggle();
        this.setState({
            oppObj: {
                ...this.defaultOppObject
            },
            validationErrorMessages: [],
            wordCount: 500
        });
    }

    // validator function to check all required fields
    validate = () => {
        const errorArr = dateOfServiceValidation("DOS", "DOS", this.state.oppObj.dateOfServiceFrom, '', null, false, this.minDateOfService);
        if (!this.state.oppObj.opportunityCD && this.state.oppObj.diagnosisCodes) {
            errorArr.push({ id: 'invalidOpportunityCD', field: "opportunityCD", errorText: "TEXT TO SHOW ALERT is required" });
        }
        if (!this.state.oppObj.diagnosisCD) {
            errorArr.push({ id: 'invalidDiagnosisCD', field: "diagnosisCD", errorText: "DX Code is required" });
        }
        if (!this.state.oppObj.opportunityText) {
            errorArr.push({ id: 'invalidOpportunityText', field: "opportunityText", errorText: "Opportunity text is required" });
        }
        this.setState({ validationErrorMessages: errorArr });
        return errorArr && !errorArr.length > 0;
    }
    
    // form field helpers
    // date change/blur helpers
    handleDateChange = (dateValue) => {
        dateValue = dateValue ? dateValue : '';
        if (dateValue) {
            this.setState({
                oppObj: {
                    ...this.state.oppObj,
                    dateOfServiceFrom: dateValue,
                    dateOfService: new Date(dateValue)
                }
            });
        }
    }

    // comment group change handler
    handleCommentGroupChange = (e) => {
        const commentGroupID = e && e.target ? e.target.value : '';
        this.setState({
            oppObj: {
                ...this.state.oppObj,
                commentGroupID: commentGroupID,
            },
            textToShowAlertOptionsAvailable: commentGroupID ? this.props.opportunityReviewComments.filter(x => {
                return x.commentGroupID === parseInt(commentGroupID, 10)
            }) : this.props.opportunityReviewComments
        });
    }

    // options change handler
    textToShowAlertChange = (e) => {
        const opportunityCD = e && e.target ? e.target.value : '';
        let defaultText = '';
        let opportunityText = '';
        if (opportunityCD) {
            defaultText = this.props.opportunityReviewComments.find(x => {
                return x.commentDefinitionCD === opportunityCD
            });
            if (defaultText && !this.props.editMode) {
                opportunityText = defaultText.commentText;
            }
        }
        this.setState({
            oppObj: {
                ...this.state.oppObj,
                opportunityCD: opportunityCD,
                opportunityText: opportunityText ? opportunityText : this.state.oppObj.opportunityText,
                diagnosisCD: defaultText && defaultText.icD10 && defaultText.icD10.length === 0 ? null : this.state.oppObj.diagnosisCD,
                diagnosisCodes: defaultText ? defaultText.icD10 : []
            }
        }, () => {
            this.getWordCount();
        })
    }

    handleDXValueChange = (e) => {
        const value = e && e.target ? e.target.value : '';
        // if dxCode is 0000, lauch diagnosis lookup modal
        if (value === '0000') {
            this.toggleLookupModal();
        }
        this.setState({
            oppObj: {
                ...this.state.oppObj,
                diagnosisCD: value === '0000' ? this.state.oppObj.diagnosisCD : value
            }
        });
    }

    // handle dx lookup modal toggle
    toggleLookupModal = () => {
        this.setState({
            showLookupModal: !this.state.showLookupModal,
            visible: this.state.showLookupModal
        })
    }

    // add selected DX Code to DX code dropdown list and set as diagnosisCD (from DX Lookup modal)
    selectDXLookup = (dxCode) => {
        this.setState({
            selectedDXCode: dxCode,
            oppObj: {
                ...this.state.oppObj,
                diagnosisCD: dxCode
            }
        })
    }

    // word count helpers
    getWordCount = (e) => {
        const countTotal = 500;
        let opportunityText = e && e.target ? e.target.value : this.state.oppObj.opportunityText;
        let charactersLeft;
        if (opportunityText && opportunityText.length > 0) {
            charactersLeft = countTotal - opportunityText.length;
        } else if (opportunityText && (opportunityText.length === countTotal || opportunityText.length > countTotal)) {
            opportunityText = opportunityText.substring(0, (countTotal - 1));
            charactersLeft = 0;
        } else {
            charactersLeft = countTotal;
        }
        this.setState({
            oppObj: {
                ...this.state.oppObj,
                opportunityText: opportunityText,
            },
            wordCount: charactersLeft
        });
    }

    // add entry helpers
    addEntry = () => {
        if (this.validate()) {
            // if creating a new entry
            if (!this.state.oppObj.medicalRecordOpportunityID) {
                // if pec correction comment has been selected, show alert and return
                if (this.props.pecCorrectionCommentSelected) {
                    this.props.toast('error', 'Cannot add an opportunity when a \'PEC Correction\' comment is selected.', 'Error');
                    return;
                }
                // otherwise, proceed to create new opportunity
                else {
                    this.setState({
                        oppObj: {
                            ...this.state.oppObj,
                            medicalRecordReviewID: this.props.medicalRecordReviewID,
                            userID: this.props.userID
                        }
                    }, () => {
                        axios.post(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordOpportunity`, this.state.oppObj)
                        .then((response) => {
                            this.props.getOpportunityData();
                            this.props.getOpportunityHistoryData();
                            this.handleModalToggle();
                        })
                        .catch((error) => {
                            this.errorCallbackTasks(error.response.data);
                        })
                    });
                }
            }
            // if editing existing record
            else {
                this.setState({
                    oppObj: {
                        ...this.state.oppObj,
                        medicalRecordReviewID: this.props.medicalRecordReviewID,
                        userID: this.props.userID,
                        expectedVersion: this.state.oppObj.updatedDateTime
                    }
                }, () => {
                    axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordOpportunity/${this.state.oppObj.medicalRecordOpportunityID}`, this.state.oppObj)
                    .then((response) => {
                        this.props.getOpportunityData();
                        this.props.getOpportunityHistoryData();
                        this.handleModalToggle();
                    })
                    .catch((error) => {
                        this.errorCallbackTasks(error.response.data);
                    })
                });
            }
        }
    }

    saveEditedOpportunity = () => {
        this.setState({
            oppObj: {
                ...this.state.oppObj,
                updateAndApprove: true,
                opportunityReviewStatusID: LineItemReviewStatus.Confirmed
            }
        }, () => {
            this.addEntry();
        })
    }

    errorCallbackTasks = (data) => {
        this.props.toast('error', data, 'Error');
    }

    render() {
        return (
            <div>
                <Modal show={this.state.visible} className="diagFormModal" onHide={this.handleModalToggle} keyboard bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title><b>{ this.props.editMode ? 'Edit' : 'Add' } Opportunity</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem', marginBottom: '15px' }} className="requiredField">DOS</ControlLabel>
                                    <DatePicker value={this.state.oppObj.dateOfServiceFrom} onChange={this.handleDateChange} onBlur={this.handleDateBlur}
                                        style={{zIndex: 'auto'}}/>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Group</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.oppObj.commentGroupID} 
                                        onChange={this.handleCommentGroupChange} style={{marginTop: '10px'}} disabled={!this.state.oppObj.dateOfServiceFrom}>
                                        <option value="">Select</option>
                                        {
                                            this.props.commentGroupOptions &&
                                            this.props.commentGroupOptions.map((item) => {
                                                return <option key={item.id} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Alert Options</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.oppObj.opportunityCD}
                                        onChange={this.textToShowAlertChange} style={{marginTop: '10px'}} disabled={!this.state.oppObj.dateOfServiceFrom}>
                                        <option value="">Select</option>
                                        {
                                            this.state.textToShowAlertOptionsAvailable &&
                                            this.state.textToShowAlertOptionsAvailable.map((item) => {
                                                return <option key={item.commentDefinitionID} value={item.commentDefinitionCD}>{item.commentText}</option>
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>DX Code</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.oppObj.diagnosisCD} 
                                        onChange={this.handleDXValueChange} style={{marginTop: '10px'}} disabled={!this.state.oppObj.opportunityCD}>
                                        <option value="">Select</option>
                                        {
                                            this.state.selectedDXCode &&
                                            <option key={this.state.selectedDXCode} value={this.state.selectedDXCode}>{this.state.selectedDXCode}</option>
                                        }
                                        {
                                            this.state.oppObj.diagnosisCodes &&
                                            this.state.oppObj.diagnosisCodes.map((item) => {
                                                return <option key={item} value={item}>{item}</option>
                                            })
                                        }
                                        <option key={'0000'} value={'0000'}>{'Lookup...'}</option>
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Text that will display within alert (customize as needed)</ControlLabel>
                                    <FormControl componentClass="textarea" maxLength="500" value={this.state.oppObj.opportunityText} 
                                        onChange={this.getWordCount} style={{marginTop: '10px'}}>
                                    </FormControl>
                                </FormGroup>
                                <span>Characters remaining: {this.state.wordCount}</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{textAlign: 'left'}}>
                            {
                                this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0 &&
                                this.state.validationErrorMessages.map(error => {
                                    return <div key={error.id} className="errorMessage">{error.errorText}</div>
                                })
                            }
                            <br/>
                        </div>
                        <div style={{float: 'left'}}>
                            {
                                !this.props.editQABool &&
                                <Button bsStyle="primary" onClick={this.addEntry}>
                                    { this.props.editMode ? '+ Save Edited Entry' : 'Add Entry' }
                                </Button>
                            }
                            {
                                this.props.editMode && this.props.editQABool &&
                                <Button bsStyle="primary" onClick={this.saveEditedOpportunity}>
                                    Save and Approve
                                </Button>
                            }
                            <Button bsStyle="primary" onClick={this.handleModalToggle}>
                                Cancel
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <OpportunityDiagnosisLookupModal visible={this.state.showLookupModal} row={this.state.oppObj} memberID={this.props.memberID}
                    handleModalToggle={this.toggleLookupModal} selectDXLookup={this.selectDXLookup}/>
            </div>
        );
    }
}

export default OpportunityFormModal;
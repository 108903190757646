import React from 'react';
import { Panel, ControlLabel, FormGroup } from 'react-bootstrap';

//todo: increase visual contrast between label / value; adjust font size, etc.
const WorkflowHeader = (props) => {
    return (
        <Panel className="alert-workflow-header">
            <h4>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Name:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{`${props.workflow.memberLastName}, ${props.workflow.memberFirstName}`}</span>
                </FormGroup>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Date of Birth:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{props.workflow.memberDateOfBirth}</span>
                </FormGroup>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Beneficiary ID:</ControlLabel>
                    <span style={{ marginLeft: '1rem'}}>{props.workflow.beneficiaryID}</span>
                </FormGroup>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Member ID:</ControlLabel>
                    <span style={{ marginLeft: '1rem'}}>{props.workflow.memberID}</span>
                </FormGroup>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Alert Workflow:</ControlLabel>
                    <span style={{ marginLeft: '1rem'}}>{props.workflow.cdiAlertWorkflowID}</span>
                </FormGroup>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Status:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{props.workflow.cdiAlertWorkflowStatusDesc}</span>
                </FormGroup>
            </h4>
        </Panel>
    );
}

export default WorkflowHeader;
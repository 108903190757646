import React from 'react';
import SubmittedCard from './SubmittedCard';
 
class SubmittedContainer extends React.Component {
    render() {
        return (
            <tbody>
                {
                    this.props.cards.map((card, i) => {
                        return (
                            <SubmittedCard key={card.medicalRecordDiagRADVDetailID} index={i} listId={this.props.id} radvAuditTypeID={this.props.radvAuditTypeID}
                                selectedCMS={card.radvAuditHCCStatusDesc} radvAuditEnrolleeStatusID={this.props.radvAuditEnrolleeStatusID} newStatusID={this.props.newStatusID}
                                rank={card.rank} coversheetID={card.coversheetID} scoreDesc={card.scoreDesc} targetedHCC={card.targetedHCC}
                                nonRelatedHCC={card.nonRelatedHCC} providerTypeDesc={card.providerTypeDesc} dosFrom={card.dosFrom} dosThru={card.dosThru}
                                attestation={card.attestation} codingDetails="" evidenceFile="" submittedUserFullName={card.submittedUserFullName}
                                submittedDatetime={card.submittedDatetime} card={card} medicalRecordReviewID={card.medicalRecordReviewID}
                                medicalRecordImageID={card.medicalRecordImageID} handleDropdownSelection={this.props.handleDropdownSelection} toast={this.props.toast} />
                        );
                    })
                }
            </tbody>
        );
    } 
}
 
export default SubmittedContainer;
import React, { Component } from 'react';
import axios from 'axios';
import AlertToastr from '../../Shared/AlertToastr';
import OOAActionBar from './OOAActionBar';
import OOAAlertHeader from './OOAAlertHeader';
import OOAAssignedInfo from './OOAAssignedInfo';
import OOAAlertInfo from './OOAAlertInfo';
import OOAAppointmentInfo from './OOAAppointmentInfo';
import OOAAlertResponseContainer from './OOAAlertResponseContainer';
import OOAAlertErrors from './OOAAlertErrors';
import ImageUploadModal from '../AlertWorkflow/ImageUploadModal';

class OOAAlertContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertData: {},
            alertResponseData: [],
            practices: [],
            practiceProviders: [],
            providerDropdownDisabled: false,
            isViewOnly: false,
            assignedExpanded: true,
            alertExpanded: true,
            responseExpanded: true,
            appointmentExpanded: true,
            toast: null,
            loaded: false,
            validationMessages: [],
            uploadedFiles: []
        };
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    getAlertData = () => {
        return axios.get(`/ooacdialert/${this.props.router.params.ooaAlertID}/AlertData`);
    }

    getAlertResponseData = () => {
        return axios.get(`/ooacdialert/${this.props.router.params.ooaAlertID}/AlertResponseData`);
    }

    //Get all practices
    getAllPractices = () => {
        return axios.get('/practice/AllPractices');
    }

    getPracticeProviders = (groupingID = this.state.alertData.appointmentPracticeCode) => {
        axios.get(`/practice/${groupingID}/provider`)
            .then((response) => {
                this.setState({ practiceProviders: response.data.systemEntered.concat(response.data.userEntered) });
            })
            .catch((error) => {
                this.state.toast('error', 'Could not load provider data.', 'Error');
            });
    }

    //attach provider search modal result to appointment provider options
    attachProviderToPractice = (internalKey) => {
        axios.post(`/practice/${this.state.alertData.appointmentPracticeCode}/provider/${internalKey}`)
        .then((response) => {
            this.getPracticeProviders();
            this.handleAlertDataFieldChange({ appointmentProviderMasterID: internalKey });            
        })
        .catch((error) => {
            this.state.toast('error', 'Could not add provider to practice.', 'Error');
        });
    }

    handleAlertPracticeChange = (groupingID, practiceName) => {
        if (groupingID) {
            this.getPracticeProviders(groupingID);
        }
        this.setState({
            alertData: {
                ...this.state.alertData,
                appointmentPracticeCode: groupingID,
                appointmentPracticeName: practiceName,
                appointmentProviderMasterID: ''
            }
        });
    }

    componentDidMount() {
        this.loadAlertData();
    }

    loadAlertData = () => {
        Promise.all([this.getAlertData(), this.getAlertResponseData(), this.getAllPractices(), this.getFiles()])
            .then((responses) => {
                let isViewOnly = (responses[0].data.alertStatus === "Complete");
                this.setState({
                    alertData: responses[0].data,
                    alertResponseData: responses[1].data,
                    practices: responses[2].data,
                    uploadedFiles: responses[3].data,
                    isViewOnly: isViewOnly,
                    loaded: true
                }, () => {
                    this.getPracticeProviders();
                });
            })
            .catch((error) => {
                // this.state.toast('error', 'Failed to load Alert data.', 'Error');
            });
    }

    toggleImageUploadModal = () => {
        // if (!this.state.isViewOnly) {
        //     this.saveWorkflow(false);
        // }
        this.setState({ showImageUploadModal: !this.state.showImageUploadModal });
    }

    addOrUpdateFile = (file) => {
        const tempFiles = [...this.state.uploadedFiles];
        let target = tempFiles.find(x => x.imageID === file.imageID);
        if (target) {
            target = file;
            this.setState({ uploadedFiles: [...tempFiles] });
        }
        else {
            this.setState({ uploadedFiles: [...tempFiles, file] });
        }
    }

    removeFile = (imageID) => {
        this.setState({ uploadedFiles: this.state.uploadedFiles.filter(x => x.imageID !== imageID) });
    }

    getFiles = () => {
        return axios.get(`/ooacdialert/${this.props.router.params.ooaAlertID}/files`);
    }

    refreshFiles = () => {
        this.getFiles()
        .then((response) => {
            this.setState({ uploadedFiles: response.data });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load file uploads.', 'Error');
        });
    }
    
    //Update selected option in state
    refreshAlertResponse = (value, alertResponseID) => {
        //alert(`${value} ${alertResponseID}`);
        const updateResponseData = [...this.state.alertResponseData];
        let i = 0;
        for (i in updateResponseData) {
            let j = 0;
            for (j in updateResponseData[i].responseDetails) {
                if (updateResponseData[i].responseDetails[j].alertResponseID === alertResponseID) {
                    //Update the selected option
                    updateResponseData[i].responseDetails[j].selectedOption = value;
                    break;
                }
            }
        }

        //Update state
        this.setState(
            { alertResponseData: updateResponseData }
        );

    }

    saveAlertAction = (callback) => {
        const cdiAlertDTO = this.getCDIAlertDTO();
        const alertResponsesDTO = this.getAlertResponsesDTO();
        cdiAlertDTO.alertStatus = "Created";

        const alertInfoDTO = { 'alertDTO': cdiAlertDTO, 'alertResponsesDTO': alertResponsesDTO };
        //console.log(alertInfoDTO);

        axios.put('/ooacdialert', alertInfoDTO)
            .then((response) => {
                callback();
            })
            .catch((error) => {
                if(error.response && error.response.data.message)
                {
                     this.state.toast('error', error.response.data.message, 'Error');
                }               
            });
    }

    showSaveSuccess = () => {
        this.state.toast('success', 'Your changes were successfully saved.', '');
    }

    redirectToWorkflowSearch = () => {
        this.state.toast('success', 'Your changes were successfully saved.', '');
        window.location.href = '/PEC';
    }

    saveAlert = () => {
        this.setState({ validationMessages: [] });
        this.saveAlertAction(this.showSaveSuccess);
    }

    saveAlertForLater = () => {
        this.setState({ validationMessages: [] });
        this.saveAlertAction(this.redirectToWorkflowSearch);
    }

    completeAlert = () => {
        const cdiAlertDTO = this.getCDIAlertDTO();
        const alertResponsesDTO = this.getAlertResponsesDTO();
        cdiAlertDTO.alertStatus = "Complete";

        const alertInfoDTO = { 'alertDTO': cdiAlertDTO, 'alertResponsesDTO': alertResponsesDTO };
        //console.log(alertInfoDTO);

        const validationMessages = this.validateAlertInfoDTO(cdiAlertDTO, alertResponsesDTO);

        if (validationMessages.length === 0) {
            axios.put('/ooacdialert', alertInfoDTO)
                .then((response) => {
                    this.redirectToWorkflowSearch();
                })
                .catch((error) => {
                    this.state.toast('error', error.message, 'Error');
                });
        } else {
            this.setState({ validationMessages: validationMessages });
        }
    }

    redirectToLanding = () => {
        window.location.href = '/PEC';
    }

    getCDIAlertDTO = () => {
        return {
            ...this.state.alertData
        };
    }

    getAlertResponsesDTO = () => {
        return [
            ...this.state.alertResponseData
        ];
    }

    validateAlertInfoDTO = (cdiAlertDTO, alertResponsesDTO) => {
        const errorMsgs = [];

        //Validate alert data
        if (!cdiAlertDTO.appointmentDate) {
            errorMsgs.push('Appointment Date is required.');
        }

        if (!cdiAlertDTO.deliveryDate) {
            errorMsgs.push('Date CDI Distributed is required.');
        }

        //Validate alert responses data
        let i = 0;
        for (i in alertResponsesDTO) {
            let j = 0;
            for (j in alertResponsesDTO[i].responseDetails) {
                if (!alertResponsesDTO[i].responseDetails[j].selectedOption) {
                    errorMsgs.push(`Selection is required for <${alertResponsesDTO[i].responseDetails[j].alertLineText}>.`);
                }
            }
        }

        return errorMsgs;
    }

    toggleSection = (sectionName) => {
        switch (sectionName) {
            case 'assigned':
                this.setState({ assignedExpanded: !this.state.assignedExpanded });
                break;
            case 'alert':
                this.setState({ alertExpanded: !this.state.alertExpanded });
                break;
            case 'appointment':
                this.setState({ appointmentExpanded: !this.state.appointmentExpanded });
                break;
            case 'query':
                this.setState({ queryExpanded: !this.state.queryExpanded });
                break;
            case 'response':
                this.setState({ responseExpanded: !this.state.responseExpanded });
                break;
            default:
                return;
        }
    }

    handleAlertDataFieldChange = (updateObj) => {
        this.setState({
            alertData: { ...this.state.alertData, ...updateObj }
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.loaded &&
                    <div>
                        <OOAActionBar toggleImageUploadModal={this.toggleImageUploadModal} saveAlert={this.saveAlert}
                            saveAlertForLater={this.saveAlertForLater} completeAlert={this.completeAlert}
                            redirectToLanding={this.redirectToLanding} isViewOnly={this.state.isViewOnly} />
                        <OOAAlertHeader alertData={this.state.alertData} />
                        <OOAAlertErrors validationMessages={this.state.validationMessages} />
                        <OOAAssignedInfo alertData={this.state.alertData} expanded={this.state.assignedExpanded} toggleSection={this.toggleSection} />
                        <OOAAlertInfo alertData={this.state.alertData} handleAlertDataFieldChange={this.handleAlertDataFieldChange}
                            isViewOnly={this.state.isViewOnly} expanded={this.state.alertExpanded}
                            toggleSection={this.toggleSection} />
                        <OOAAppointmentInfo alertData={this.state.alertData} practices={this.state.practices}
                            practiceProviders={this.state.practiceProviders}
                            handleAlertPracticeChange={this.handleAlertPracticeChange} attachProviderToPractice={this.attachProviderToPractice}
                            providerDropdownDisabled={this.state.providerDropdownDisabled} handleAlertDataFieldChange={this.handleAlertDataFieldChange}
                            isViewOnly={this.state.isViewOnly} expanded={this.state.appointmentExpanded}
                            toggleSection={this.toggleSection} toast={this.state.toast} />
                        <OOAAlertResponseContainer alertResponseData={this.state.alertResponseData} isViewOnly={this.state.isViewOnly}
                            expanded={this.state.responseExpanded} toggleSection={this.toggleSection} refreshAlertResponse={this.refreshAlertResponse}
                            toast={this.state.toast} />
                        <ImageUploadModal uploadedFiles={this.state.uploadedFiles}
                            visible={this.state.showImageUploadModal} handleModalToggle={this.toggleImageUploadModal}
                            ooaAlertID={this.props.router.params.ooaAlertID} addOrUpdateFile={this.addOrUpdateFile} removeFile={this.removeFile}
                            isOOA={true} toast={this.state.toast} />

                    </div>
                }
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default OOAAlertContainer;


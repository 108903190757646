import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import '../../../styles/EMRSection.css';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';
import {formatDatetimeStringWithPrefixZero} from '../../../utils/DateUtils';

class Diagnosis extends React.Component {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            [ 'CODE' ],
            [ 'NAME' ],
            [ 'TYPE', 'STATUS' ],
            [ 'CODING SYS' ],
            [ 'PROVIDER', 'PROVIDER NPI' ],
            [ 'PRACTICE', 'PRACTICE NPI' ],
            [ 'DRUG NAME', 'DRUG CODE' ]
        ];

        this.data = [];
    }

    formatMultidataCell = (value) => {
        if (!value || value.length === 0) {
            return '-';
        } else {
            return value;
        }
    }

    renderColumnHeaders = () => {
        return this.columnHeaders.map((x, index) => {
            return (x.length === 2 ?
                <th className='rawTableColumnHeader' key={index}>
                    <div>{x[0]}</div>
                    <div>{x[1]}</div>
                </th>
                : <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
            )
        })
    }

    renderDataRows = (records) => {
        return records.map((x, index) => {
            var diagnosis = [];

            if(!isEmpty(x.diagnosis_code))
                this.generateDiagnosis(diagnosis, x.diagnosis_code, x.diagnosis_name, x.coding_system, 'Primary');  

            if(!isEmpty(x.additional_diagnosis))
                x.additional_diagnosis.forEach(d => {
                    this.generateDiagnosis(diagnosis, d.code, d.name, d.system, 'Secondary');            
                });
                
            if(!isEmpty(x.admitting_diagnosis))
                x.admitting_diagnosis.forEach(d => {
                    this.generateDiagnosis(diagnosis, d.code, d.name, d.system, 'Admitting');            
                });
                
            return this.renderDiagnosis(x, index, diagnosis)
        })
    }

    generateDiagnosis = (diags, code, name, system, type) => {
        diags.push({'diagnosisCode':code, 'diagnosisName':name, 'diagnosisSystem':system, 'type':type});
        return diags;
    }

    renderDiagnosis = (x, index,  diagnosis) => {
        return diagnosis.map((d, i) => {
            const key = `${index}_${i}`;
            return (
                <tr key={key}>
                    <td className='width10'>{d.diagnosisCode}</td>
                    <td className='width20'>{d.diagnosisName}</td>
                    <td className='width20'>
                        <div>{this.formatMultidataCell(d.type)}</div>
                        <div>{this.formatMultidataCell(x.status)}</div>
                    </td>
                    <td className='width10'>{d.diagnosisSystem}</td>
                    <td className='width15'>
                        <div>{this.formatMultidataCell(x.Provider_name)}</div>
                        <div>{this.formatMultidataCell(x.Provider_id !== '0' ? x.Provider_id : '')}</div>
                    </td>
                    <td className='width15'>
                        <div>{this.formatMultidataCell(x.Practice_name)}</div>
                        <div>{this.formatMultidataCell(x.Practice_id !== '0' ? x.Practice_id : '')}</div>
                    </td>
                    <td className='width10'>
                        <div>{this.formatMultidataCell(x.drg_name)}</div>
                        <div>{this.formatMultidataCell(x.drg_code)}</div>
                    </td>
                </tr>
            )
        })
        
    }

    renderDatedTables = () => {
        return this.data.map((x, index) => {
            return (
                <div id='datedTableWrapper' key={index}>
                    <div className='datedTableHeader'>
                        {formatDatetimeStringWithPrefixZero(x.date_of_diagnosis)}
                    </div>
                    <Table responsive className='datedTable'>
                        <thead>
                            <tr>
                                {this.renderColumnHeaders()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderDataRows(x.diagnosisInfos)}
                        </tbody>
                    </Table>
                </div>
            )
        })
    }

    render() {
        this.data = this.props.data;
        return (
            <Panel id='diagnosis'>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Diagnosis
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                        this.renderDatedTables() : <NoData />
                    }
                   
                </Panel.Body>
            </Panel>
        )
    }
}

export default Diagnosis;
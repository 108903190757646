import React from 'react';
import axios from 'axios';
import { Col, Button, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Moment from 'moment';
const colStyle = { paddingLeft: '0rem' };

class AutomationConfig extends React.Component {
    constructor(props) {
        super(props);
            
        this.state = {
            l1CoderCounts: 0,
            l2CoderCounts: 0,
            qaCoderCounts: 0,
            codingCompletionDate: '',
        }
    }

    componentDidMount() {
        this.getProjectAutomationConfigByProjectID();
    }

    getProjectAutomationConfigByProjectID() {
        axios.get(`/projectAutomationConfig/GetbyProject/${this.props.projectID}`)
        .then((response) => {
            if (response.data) {
                this.setState({
                    l1CoderCounts: response.data.l1CoderCount,
                    l2CoderCounts: response.data.l2CoderCount,
                    qaCoderCounts: response.data.qaCoderCount,
                    codingCompletionDate: response.data.codingCompletionDate,
                })
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load automation config.', 'Error');
        })
    }

    
    handleSave = () => {
        this.props.handleSaveAutomationConfig({projectID: this.props.projectID, 
                                               l1CoderCounts: this.state.l1CoderCounts, 
                                               l2CoderCounts: this.state.l2CoderCounts, 
                                               qaCoderCounts: this.state.qaCoderCounts, 
                                               codingCompletionDate: this.state.codingCompletionDate})    
    }

    handleL1Count = (e) => {
        if(
           this.props.campaignAutomationConfig.l1CoderCount -
           this.props.projectTotals.l1CoderCount +
           this.props.projectStartCount.l1CoderCount -
           e.target.value >= 0)
        {
            if(e.target.value >= 0)
            {
                this.setState({ l1CoderCounts: e.target.value });
            }
            else{
                this.props.toast('error', 'Value must be positive.', 'Error');
            }
        }
        else{
            this.props.toast('error', 'Value exceed the Campaign count.', 'Error');
        }
    }

    handleL2Count = (e) => {
        if(this.props.campaignAutomationConfig.l2CoderCount -
           this.props.projectTotals.l2CoderCount +
           this.props.projectStartCount.l2CoderCount -
           e.target.value >= 0)
        {
            if(e.target.value >= 0)
            {
                this.setState({ l2CoderCounts: e.target.value });
            }
            else{
                this.props.toast('error', 'Value must be positive.', 'Error');
            }
        }
        else{
            this.props.toast('error', 'Value exceed the Campaign count.', 'Error');
        }
    }

    handleQACount = (e) => {
        if(this.props.campaignAutomationConfig.qaCoderCount -
           this.props.projectTotals.qaCoderCount +
           this.props.projectStartCount.qaCoderCount -
           e.target.value >= 0)
        {
            if(e.target.value >= 0)
            {
                this.setState({ qaCoderCounts: e.target.value });
            }
            else{
                this.props.toast('error', 'Value must be positive.', 'Error');
            }
        }
        else{
            this.props.toast('error', 'Value exceed the Campaign count.', 'Error');
        }
    }

    formatDate = (dateValue) => {
        return Moment(dateValue).startOf('day').format('yyyy-MM-DD');
    } 

    handleCodingCompleteDate = (dateValue) => {
        let dateValueYYYYMMDD = this.formatDate(dateValue);
        let codingCompletionDateYYYYMMDD = this.formatDate(this.props.campaignAutomationConfig.codingCompletionDate);
        let todaysDate = this.formatDate(Date());
        
        if(dateValueYYYYMMDD > codingCompletionDateYYYYMMDD)
        {
            this.setState({ codingCompletionDate: codingCompletionDateYYYYMMDD });
            this.props.toast('error', 'Date can not exceed Campaign set date.', 'Error');
        }
        else if(dateValueYYYYMMDD < todaysDate){
            this.setState({ codingCompletionDate: todaysDate });
            this.props.toast('error', "Date can not be less then today's date.", 'Error');
        }
        else{
            this.setState({ codingCompletionDate: dateValueYYYYMMDD });
        } 
    }


    render() {
        const inlineFormStyle = {
            marginLeft: '2rem'
        }
        return(
            <div>
                <Row>
                    <Col xs={9} style={colStyle}>
                        <h5 style={inlineFormStyle}>Coder Allocation</h5>
                        <Form inline>
                            <FormGroup>
                                <ControlLabel style={inlineFormStyle}>L1</ControlLabel>
                                <FormControl style={inlineFormStyle} type="number" value={this.state.l1CoderCounts} onChange={this.handleL1Count} />
                            </FormGroup>
                            <FormGroup >
                                <ControlLabel style={inlineFormStyle}>L2</ControlLabel>
                                <FormControl style={inlineFormStyle} type="number" value={this.state.l2CoderCounts} onChange={this.handleL2Count} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel style={inlineFormStyle}>QA</ControlLabel>
                                <FormControl style={inlineFormStyle} type="number" value={this.state.qaCoderCounts} onChange={this.handleQACount} />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5} style={colStyle}>
                        <h5 style={inlineFormStyle}>Coding Completion Date</h5>
                        <FormGroup style={{ width: '40%', marginLeft: '2rem' }}>
                            <DatePicker value={this.state.codingCompletionDate} onChange={this.handleCodingCompleteDate} showClearButton />
                        </FormGroup>
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={1} style={colStyle}>
                        <Button bsStyle="primary" style={{ marginLeft: '2rem' }} onClick={this.handleSave}>Save</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AutomationConfig;
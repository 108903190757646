import React from 'react';
import axios from 'axios';
import { Button, Col, DropdownButton, Glyphicon, MenuItem, Modal, Pagination, Row, Table } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import '../../styles/Report.css';
import { formatDatetimeString } from '../../utils/DateUtils';

//todo: refactor this
// 1. this contains both the scorecard and review level modal, for some reason (should be split out / see usages of "showScorecard")
// 2. the legacy scorecard code is using bootstrap's table module instead of react-bootstrap-table
// 3. meta, preliminary, & final score display can be split into a separate component(s)
class CoderQAReviewLevelModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showError: false,
            modalResults: [],
            activePage: 1,
            orderByDescending: false,
            orderByColumnName: null,
            loaded: true,
            scorecardLoaded: true,
            pageSize: 10,
            showScorecard: false,
            displayValue: null,
            scorecardMeta: [],
            scorecardResults: [],
            initialCoderUserID: -1,
            initialCoderFullName: '',
            coderQAUserID: -1,
            coderQAFullName: '',
            scorecardDiagnosisItems: [],
            scorecardReviewComments: [],
            medicalRecordReviewScorecardItem: [],
            feedbackItems: []
        }
    }

    // set showModal state to true
    open = () => {
        if (this.props.showScorecard) {
            this.setState({
                showModal: true,
                showScorecard: true,
                displayValue: this.props.displayValue,
                scorecardLoaded: false
            }, () => {
                this.getScorecardData(this.props.medicalRecordReviewID, this.props.coderQAQueueID);
            });
        }
        else {
            this.setState({
                showModal: true,
                loaded: false,
                displayValue: this.props.displayValue
            }, () => {
                this.getCoderQAReviewLevelModalRecords();
            });
        }
    }

    // set showModal state to false if 'cancel' or 'hide' selected
    close = () => {
        this.setState({
            showModal: false,
            activePage: 1,
            pageSize: 10,
            showScorecard: false
        });
    }

    checkNotesReadOnly = (notesName) => {
        if (notesName === 'coder') {
            return !(this.props.currentUser.userID === this.state.initialCoderUserID);
        }
        else if (notesName === 'qa') {
            return !(this.props.currentUser.userID === this.state.coderQAUserID);
        }
        else {
            return true;
        }
    }

    getCoderQAReviewLevelModalRecords = (orderByColumnName, pageNumber, pageSize) => {
        // Update state values
        let orderByDescending = false;
        if (orderByColumnName) {
            if (orderByColumnName !== this.state.orderByColumnName) {
                this.setState({
                    orderByColumnName: orderByColumnName,
                    orderByDescending: false
                });
            }
            else {
                this.setState({ orderByDescending: !this.state.orderByDescending });
                orderByDescending = !this.state.orderByDescending;
            }
        }

        // Builds the query string
        var queryParams = [];
        if (orderByColumnName) {
            queryParams.push('orderByColumnName=' + orderByColumnName);
            queryParams.push('orderByDescending=' + orderByDescending);
        }
        else {
            queryParams.push('orderByDescending=' + this.state.orderByDescending);
        }
        if (pageNumber) {
            queryParams.push('pageNumber=' + pageNumber);
        }
        if (pageSize) {
            queryParams.push('pageSize=' + pageSize);
        }
        var queryStr = queryParams.join('&');

        axios.post('/Reports/CoderQA/CoderQAScoringReport/Records?' + queryStr, {
            medicalCoderIDs: [this.props.medicalCoderIDs],
            completedStartDate: this.props.completedStartDate,
            completedThroughDate: this.props.completedThroughDate,
            projectIDs: this.props.projectIDs
        })
            .then((response) => {
                if (response.data.totalItems === 0) {
                    this.setState({ displayValue: null });
                }
                this.setState({
                    coderQAReviewLevelData: response.data,
                    totalItems: response.data.totalItems,
                    loaded: true,
                    modalResults: response.data.items
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loaded: true });
            });
    }

    handlePageSelection = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        this.getCoderQAReviewLevelModalRecords(this.state.orderByColumnName, pageNumber, this.state.pageSize);
    }

    handlePageSizeSelection = (pageSize) => {
        this.setState({
            pageSize: pageSize,
            activePage: 1
        });
        this.getCoderQAReviewLevelModalRecords(this.state.orderByColumnName, 1, pageSize);
    }

    getSortIcon = (columnName) => {
        if (columnName !== this.state.orderByColumnName) {
            return '';
        }
        return this.state.orderByDescending ? 'triangle-bottom' : 'triangle-top';
    }

    handleScorecardLinkClick = (mrrID, coderQAQueueID) => {
        this.setState({
            showScorecard: true,
            displayValue: 'Scorecard',
            scorecardLoaded: false
        });
        this.getScorecardData(mrrID, coderQAQueueID);
    }

    handleBackClick = () => {
        this.setState({
            showScorecard: false,
            displayValue: this.props.displayValue
        });
    }

    getScorecardData = (mrrID, coderQAQueueID) => {
        Promise.all([axios.get(`/coderQA/${coderQAQueueID}/MedicalRecordReviews/${mrrID}/Scorecard`),
        axios.get(`/MedicalRecordReview/${mrrID}/Comments`)])
            .then((responses) => {
                this.setState({
                    initialCoderUserID: responses[0].data.initialCoderUserID,
                    initialCoderFullName: responses[0].data.initialCoderFullName,
                    coderQAUserID: responses[0].data.coderQAUserID,
                    coderQAFullName: responses[0].data.coderQAFullName,
                    scorecardDiagnosisItems: responses[0].data.scorecardDiagnosisItems,
                    medicalRecordReviewScorecardItem: responses[0].data.medicalRecordReviewScorecardItem,
                    feedbackItems: responses[0].data.medicalRecordReviewScorecardItem.feedbackItems,
                    scorecardMeta: [responses[0].data.meta],
                    scorecardResults: [responses[0].data.results],
                    scorecardReviewComments: responses[1].data,
                    scorecardLoaded: true
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load scorecard data', 'Error');
                this.setState({ scorecardLoaded: true });
            });
    }

    updateScorecardResults = (dto) => {
        axios.post(`/coderQA/${this.props.coderQAQueueID}/MedicalRecordReviews/${this.props.medicalRecordReviewID}/ScoreCard/results`, {
            coderQaQueueId: this.props.coderQAQueueID,
            medicalRecordReviewId: this.props.medicalRecordReviewID,
            totalMemberDOSAudited: dto.totalMemberDOSAudited,
            finalHccAccuracy: dto.finalHccAccuracy,
            finalHccCompleteness: dto.finalHccCompleteness,
            finalCdiAccuracy: dto.finalCdiAccuracy,
            finalCdiCompleteness: dto.finalCdiCompleteness
        })
            .then((response) => {
                this.setState({
                    scorecardMeta: [{
                        ...this.state.scorecardMeta[0],
                        totalMemberDOSAudited: response.data.totalMemberDOSAudited
                    }],
                    scorecardResults: [{
                        ...this.state.scorecardResults[0],
                        finalHccAccuracy: response.data.finalHccAccuracy,
                        finalHccCompleteness: response.data.finalHccCompleteness,
                        finalCdiAccuracy: response.data.finalCdiAccuracy,
                        finalCdiCompleteness: response.data.finalCdiCompleteness
                    }]
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to save update', 'Error');
            });
    }

    updateScorecardResults = (dto) => {
        axios.post(`/coderQA/${this.props.coderQAQueueID}/MedicalRecordReviews/${this.props.medicalRecordReviewID}/ScoreCards/${dto.entityID}/Notes`, dto)
            .catch((error) => {
                this.props.toast('error', 'Failed to save update', 'Error');
            });
    }

    formatAuditDateRange = (value, row) => {
        return value ? `${formatDatetimeString(value)} - ${formatDatetimeString(row.auditDateEnd)}` : '';
    }

    render() {
        const scorecardMeta = [...this.state.scorecardMeta];
        const scorecardResults = [...this.state.scorecardResults];

        const onBeforeSaveCell = (row, cellName, cellValue) => {
            if (parseInt(cellValue, 10) === parseInt(row[cellName])) {
                return false; // reject update if data isn't changing (prevents pointless API request)
            }

            const intVal = parseInt(cellValue, 10);

            // totalMemberDOSAuditedCount & final score int validation
            if (Number.isInteger(intVal)) {
                if (cellName === 'totalMemberDOSAuditedCount') {
                    return true; // update saved via onAfterSaveCell
                }
                // final score int percentage validation
                else if (intVal >= 0 && intVal <= 100) {
                    return true; // update saved via onAfterSaveCell
                }
            }

            return false; // update rejected -- cell returns to pre-update value
        };

        const onAfterSaveCell = (row, cellName, cellValue) => {
            if (cellName === 'totalMemberDOSAuditedCount') {
                this.updateScorecardResults({
                    totalMemberDOSAudited: parseInt(cellValue, 10),
                    finalHccAccuracy: scorecardResults[0].finalHccAccuracy,
                    finalHccCompleteness: scorecardResults[0].finalHccCompleteness,
                    finalCdiAccuracy: scorecardResults[0].finalCdiAccuracy,
                    finalCdiCompleteness: scorecardResults[0].finalCdiCompleteness
                });
            }
            else {
                this.updateScorecardResults({
                    totalMemberDOSAudited: scorecardMeta[0].totalMemberDOSAuditedCount,
                    finalHccAccuracy: parseInt(row.finalHccAccuracy, 10),
                    finalHccCompleteness: parseInt(row.finalHccCompleteness, 10),
                    finalCdiAccuracy: parseInt(row.finalCdiAccuracy, 10),
                    finalCdiCompleteness: parseInt(row.finalCdiCompleteness, 10)
                });
            }
        }

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: onAfterSaveCell,
            beforeSaveCell: onBeforeSaveCell
        };

        const saveScordCardItemUpdates = (row, cellName, cellValue) => {
            let updates = {
                entityID: row.medicalRecordDiagID,
                medicalRecordReviewCoderQAQueueID: row.medicalRecordReviewCoderQAQueueID,
                coderQAEntityTypeID: row.coderQAEntityTypeID,
                coderNotes: row.coderNotes,
                qaNotes: row.qaNotes,
            };

            updates[cellName] = cellValue;

            this.updateScorecardResults(updates);
        };

        const onBeforeSaveScoreCard = (row, cellName, cellValue) => {
            if (row[cellName] === cellValue) {
                return false;
            }

            return true;
        }

        const feedbackRender = (cell, row) => {
            return (
                <ul>
                    {cell.map((feedback, i) => {
                        return (
                            <li key={i}>{feedback}<br /></li>
                        )
                    })}
                </ul>
            )
        }

        const scordCardItemEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: saveScordCardItemUpdates,
            beforeSaveCell: onBeforeSaveScoreCard
        };

        const attributes =
        {
            rows: 3,
            onKeyDown: function (event) {
                event.stopPropagation();
            }
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button style={{ color: this.props.displayColor ? this.props.displayColor : 'inherit' }} onClick={this.open}>{this.props.displayValue}</Button>
                <Loader loaded={this.state.loaded}>
                    <Modal className="projectStatusReportModal" show={this.state.showModal} onHide={this.close}>
                        <div hidden={this.state.showError}>
                            <div style={{ margin: '35px 35px 35px 35px', display: this.state.totalItems > 0 || this.state.showScorecard ? 'none' : 'block', textAlign: 'center' }}>
                                <h3>No results were returned</h3>
                            </div>
                            <div style={{ display: this.state.totalItems > 0 || this.state.showScorecard ? 'block' : 'none' }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div style={{ float: 'left' }}>{this.state.displayValue}</div>
                                        <div style={{ float: 'right', marginRight: '20px', display: this.props.openedFromReview ? 'none' : 'block' }}>Average Score: {this.props.totalAverageScore}</div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="reviewLevelDiv" style={{ display: this.state.showScorecard ? 'none' : 'block' }}>
                                        <DropdownButton id={`dropdown-size-medium`} title={this.state.pageSize} onSelect={this.handlePageSizeSelection} style={{ marginTop: '-25px' }}>
                                            <MenuItem eventKey="5">5</MenuItem>
                                            <MenuItem eventKey="10">10</MenuItem>
                                            <MenuItem eventKey="20">20</MenuItem>
                                            <MenuItem eventKey="50">50</MenuItem>
                                            <MenuItem eventKey="100">100</MenuItem>
                                            <MenuItem eventKey="250">250</MenuItem>
                                        </DropdownButton>
                                        <Pagination bsSize="medium" items={Math.ceil(this.state.totalItems / this.state.pageSize)}
                                            activePage={this.state.activePage} style={{ marginLeft: '10px', marginTop: '0px', marginBottom: '0px' }}
                                            onSelect={this.handlePageSelection} prev next first last ellipsis boundaryLinks maxButtons={5} />
                                        <Table bordered className="projectStatusReportModalTable">
                                            <thead>
                                                <tr>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'initialReviewer')}>Medical Coder <Glyphicon glyph={this.getSortIcon('initialReviewer')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'medicalRecordReviewID')}>MRR ID <Glyphicon glyph={this.getSortIcon('medicalRecordReviewID')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'memberID')}>Member ID <Glyphicon glyph={this.getSortIcon('memberID')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'memberName')}>Member Name <Glyphicon glyph={this.getSortIcon('memberName')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'projectName')}>Project <Glyphicon glyph={this.getSortIcon('projectName')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'recordType')}>Record Type <Glyphicon glyph={this.getSortIcon('recordType')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'initialReviewCompletionDate')}>Completion Date <Glyphicon glyph={this.getSortIcon('initialReviewCompletionDate')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'coderQAReviewer')}>Coder QA Reviewer <Glyphicon glyph={this.getSortIcon('coderQAReviewer')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'totalDiagnoses')}># of Dx <Glyphicon glyph={this.getSortIcon('totalDiagnoses')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'totalPenalties')}># of Penalties <Glyphicon glyph={this.getSortIcon('totalPenalties')} /></th>
                                                    <th onClick={this.getCoderQAReviewLevelModalRecords.bind(this, 'totalScore')}>Score <Glyphicon glyph={this.getSortIcon('totalScore')} /></th>
                                                    <th>Scorecard</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.modalResults.map((row, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{row.initialReviewer}</td>
                                                                <td>{row.medicalRecordReviewID}</td>
                                                                <td>{row.memberID}</td>
                                                                <td>{row.memberName}</td>
                                                                <td>{row.projectName}</td>
                                                                <td>{row.recordType}</td>
                                                                <td>{row.initialReviewCompletionDate}</td>
                                                                <td>{row.coderQAReviewer}</td>
                                                                <td>{row.totalDiagnoses}</td>
                                                                <td>{row.totalPenalties}</td>
                                                                <td>{row.totalScore}</td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <Button onClick={this.handleScorecardLinkClick.bind(this, row.medicalRecordReviewID, row.coderQAQueueID)}>
                                                                        <Glyphicon glyph="file-picture-o" />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="scorecardDiv" style={{ display: this.state.showScorecard ? 'block' : 'none' }}>
                                        <Loader loaded={this.state.scorecardLoaded}>
                                            <div style={{ display: this.state.scorecardLoaded ? 'block' : 'none' }}>
                                                <div style={{ float: 'left' }}><b>MRR ID:</b> {this.props.medicalRecordReviewID}</div>
                                                <div style={{ float: 'right', marginBottom: '10px' }}>
                                                    <b>Initial Coder:</b> {this.state.initialCoderFullName}<br />
                                                    <b>Coder QA Coder:</b> {this.state.coderQAFullName}
                                                </div>
                                                <Row>
                                                    <Col sm={12} style={{ maxHeight: '150px', overflowY: 'auto', clear: 'both' }}>
                                                        <BootstrapTable
                                                            data={this.state.scorecardDiagnosisItems}
                                                            cellEdit={scordCardItemEditProp}
                                                            scroll-top="Top"
                                                            hover
                                                        >
                                                            <TableHeaderColumn dataField="medicalRecordDiagID" hidden isKey />
                                                            <TableHeaderColumn dataField="dateOfServiceFrom" editable={false}>DOS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="dateOfServiceTo" editable={false}>DOS Thru</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="diagnosisCD" editable={false}>Diagnosis</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="hcC_Cs" editable={false}>HCC-C</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="hcC_Ds" editable={false}>HCC-D</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="coderQAStatusDesc" editable={false}>Coder QA Status</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="feedbackItems" dataFormat={feedbackRender} editable={false}>Feedback</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="feedBackText" editable={false}>Description</TableHeaderColumn>
                                                            <TableHeaderColumn dataField="coderNotes" editable={{ readOnly:this.checkNotesReadOnly('coder'), type: 'textarea', attrs: attributes }}>
                                                                Coder Notes <Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} />
                                                            </TableHeaderColumn>
                                                            <TableHeaderColumn dataField="qaNotes" editable={{ readOnly:this.checkNotesReadOnly('qa'), type: 'textarea', attrs: attributes }}>
                                                                QA Notes <Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} />
                                                            </TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '1.66rem' }}>
                                                    <Col sm={12}>
                                                        {
                                                            scorecardMeta.length > 0 &&
                                                            <BootstrapTable data={scorecardMeta} cellEdit={cellEditProp} maxHeight="320px" scroll-top="Top" hover>
                                                                <TableHeaderColumn dataField="key" hidden isKey />
                                                                <TableHeaderColumn dataField="qaReviewDate" editable={{ readOnly: true }} dataAlign="left" dataFormat={formatDatetimeString} dataSort>Date of QA Review</TableHeaderColumn>
                                                                <TableHeaderColumn dataField="auditDateStart" editable={{ readOnly: true }} dataAlign="left" dataFormat={this.formatAuditDateRange} dataSort>Audit Date Range</TableHeaderColumn>
                                                                <TableHeaderColumn dataField="totalMemberDOSAuditedCount" editable={{ type: 'textarea' }} dataAlign="left" dataSort>Total Member/DOS Audited<Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} /></TableHeaderColumn>
                                                                <TableHeaderColumn dataField="totalHCCDiagAuditedCount" editable={{ readOnly: true }} dataAlign="left" dataSort>Total Codes Audited</TableHeaderColumn>
                                                                <TableHeaderColumn dataField="totalCDIOpportunityAuditedCount" editable={{ readOnly: true }} dataAlign="left" dataSort>Total CDI Opportunties Audited</TableHeaderColumn>
                                                            </BootstrapTable>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '1.66rem' }}>
                                                    <Col sm={8}>
                                                        {
                                                            scorecardResults.length > 0 &&
                                                            <div>
                                                                <h4 style={{ paddingLeft: '0.33rem' }}>Preliminary Score</h4>
                                                                <BootstrapTable data={scorecardResults} maxHeight="320px" scroll-top="Top" hover>
                                                                    <TableHeaderColumn dataField="key" dataAlign="left" dataSort hidden isKey>Preliminary Score</TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="preliminaryHccAccuracy" dataAlign="left" dataSort>HCC Accuracy (%)</TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="preliminaryHccCompleteness" dataAlign="left" dataSort>HCC Completeness (%)</TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="preliminaryCdiAccuracy" dataAlign="left" dataSort>CDI Accuracy (%)</TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="preliminaryCdiCompleteness" dataAlign="left" dataSort>CDI Completeness (%)</TableHeaderColumn>
                                                                </BootstrapTable>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '1rem' }}>
                                                    <Col sm={8}>
                                                        {
                                                            scorecardResults.length > 0 &&
                                                            <div>
                                                                <h4 style={{ paddingLeft: '0.33rem' }}>Final Score</h4>
                                                                <BootstrapTable data={scorecardResults} cellEdit={cellEditProp} maxHeight="320px" scroll-top="Top" hover>
                                                                    <TableHeaderColumn dataField="key" editable={{ readOnly: true }} dataAlign="left" dataSort hidden isKey>Final Score</TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="finalHccAccuracy" editable={{ type: 'textarea' }} dataAlign="left" dataSort>HCC Accuracy (%)<Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} /></TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="finalHccCompleteness" editable={{ type: 'textarea' }} dataAlign="left" dataSort>HCC Completeness (%)<Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} /></TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="finalCdiAccuracy" editable={{ type: 'textarea' }} dataAlign="left" dataSort>CDI Accuracy (%)<Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} /></TableHeaderColumn>
                                                                    <TableHeaderColumn dataField="finalCdiCompleteness" editable={{ type: 'textarea' }} dataAlign="left" dataSort>CDI Completeness (%)<Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} /></TableHeaderColumn>
                                                                </BootstrapTable>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ display: scorecardResults.length === 0 ? 'block' : 'none' }}>
                                                <b>No scorecard data found.</b>
                                            </div><br /><br />
                                        </Loader>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ float: 'left', display: this.state.showScorecard && !this.props.openedFromReview ? 'block' : 'none' }}
                                        onClick={this.handleBackClick}>Back</Button>
                                    <Button bsStyle="primary" style={{ float: 'right' }} onClick={this.close}>Close</Button>
                                </Modal.Footer>
                            </div>
                        </div>
                    </Modal>
                </Loader>
            </div>
        );
    }
}

export default CoderQAReviewLevelModal;
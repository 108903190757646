import React from 'react';
import { Col, Collapse, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { CDIAlertWorkflowStatus } from '../../../enums/CDIAlertWorkflowStatus';
import WorkflowSectionHeader from './WorkflowSectionHeader';

const QueryInfo = (props) => {
    const handleQueryReceivedChange = (e) => {
        props.handleWorkflowFieldChange({ queryReceivedFromCoderDate: e });
    }

    const handleResponseReceivedChange = (e) => {
        props.handleWorkflowFieldChange({ queryResponseReceivedDate: e });
    }

    const handleQueryForwardedChange = (e) => {
        props.handleWorkflowFieldChange({ queryForwardedToDoctorDate: e });
    }

    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="query" title="Query" />
            <Collapse in={props.expanded}>
                <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                    <Row>
                        <Col xs={3}>
                           <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Received from Coder</ControlLabel>
                                <DatePicker value={props.workflow.queryReceivedFromCoderDate} onChange={handleQueryReceivedChange}
                                    style={{ zIndex: 'auto' }}
                                    disabled={props.workflow.cdiAlertWorkflowStatusID !== CDIAlertWorkflowStatus.QueryInitiated
                                        || props.isViewOnly} />
                           </FormGroup>
                        </Col>
                        <Col xs={3}>
                           <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Forwarded to Doctor</ControlLabel>
                                <DatePicker value={props.workflow.queryForwardedToDoctorDate} onChange={handleQueryForwardedChange}
                                    style={{ zIndex: 'auto' }}
                                    disabled={props.workflow.cdiAlertWorkflowStatusID !== CDIAlertWorkflowStatus.QueryInitiated
                                        || props.isViewOnly} />
                           </FormGroup>
                        </Col>
                        <Col xs={3}>
                           <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Response Received</ControlLabel>
                                <DatePicker value={props.workflow.queryResponseReceivedDate} onChange={handleResponseReceivedChange}
                                style={{ zIndex: 'auto' }}
                                disabled={props.workflow.cdiAlertWorkflowStatusID !== CDIAlertWorkflowStatus.QueryInitiated
                                    || props.isViewOnly} />
                           </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>
    );
}

export default QueryInfo;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { FaxRequestDocumentType } from  '../../../enums/FaxRequestDocumentType';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
const Multiselect = require('react-bootstrap-multiselect');

class PrintPackageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includeBlankAlerts: false,
            includeHeaders: false,
            reportOptions: this.getReportOptions(),
            deliveryDate: '',
            selectedReports: '',
            showLoading: false,
            showError: false,
            errorMsg: '',
            faxToProvider: false,
            faxMessage: ''
        };
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");
    }
   
    componentWillReceiveProps(nextProps) {
        //set default report selections
        if (nextProps.defaultReports) {
            const selections = nextProps.defaultReports.split(',').map(x => { return parseInt(x, 10) });
            //set the 'selected' attribute on report options matching default selections
            const selectedOptions = this.state.reportOptions.map(option => {
                return selections.find(s => s === option.value) ? {...option, selected: true} : option;
            });

            this.setState({
                reportOptions: selectedOptions,
                selectedReports: selectedOptions.filter(option => {
                    return option.selected;
                }).map(x => { return x.value.toString() })
            });
        }
    }

    handleModalToggle = () => {
        this.props.handleModalToggle();
    }

    getReportOptions = () => {
        axios.get('/packages/' + this.props.packageID + '/ComponentType/')
        .then((response) => {
            const optionsArr = [...response.data.map((item) => {
                return { label: item.componentType, value: item.packageComponentTypeID, order: item.order };
            })];
            optionsArr.sort(function(a, b){
                return a.order - b.order;
            });
            this.setState({ reportOptions: optionsArr });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load report options.', 'Error');
        });
    }

    printPackage = () => {
        let sort = {};
        if (this.props.alertSort && this.props.querySort) {
            //handle mismatch between sortName (react-bootstrap-table model) & sortColumn (mrr-ws model)
            const tempAlertSort = [...this.props.alertSort];
            tempAlertSort.forEach((item) => {
                item.sortColumn = item.sortName;
                delete item.sortName;
            });
            const tempQuerySort = [...this.props.querySort];
            tempQuerySort.forEach((item) => {
                item.sortColumn = item.sortName;
                delete item.sortName;
            });
            sort = { alertSort: tempAlertSort, querySort: tempQuerySort };
        }

        this.setState({
            showLoading: true,
            showError: false,
            errorMsg: ''
        });

        if (this.state.faxToProvider) {
            // create fax request
            axios.post(`/FaxRequests/`, {
                DocumentType: FaxRequestDocumentType.AlertPackage,
                DocumentEntityID: this.props.packageID
            })
            .then((response) => {
                axios.get(`/FaxRequests/${this.props.practiceCode}`)
                .then((response) => {
                    switch (response.data[0].faxStatus.faxStatusID) {
                        case -100:
                            this.props.toast('error', 'Fax attempt has failed.', 'Error');
                            break;
                        case 10 || 50:
                            this.props.toast('info', 'Fax has been sent.', 'Info');
                            this.setState({ faxMessage: `A fax has been attempted for this package: ${new Date().toLocaleDateString('en-US', { timeZone: 'UTC' })}, ${response.data[0].faxStatus.faxStatusDesc}` })
                            break;
                        case 100:
                            this.props.toast('success', 'Fax was successful.', 'Success');
                            break;
                        default:
                            break;
                    }
                })
                .catch((error) => {
                    this.props.toast('error', 'Could not retrieve fax request.', 'Error');
                })
            })
            .catch((error) => {
                this.props.toast('error', 'Could not send fax.', 'Error');
            });
        }
        
        axios.put('/packages/' + this.props.packageID + '/Component/',
            {
                packageID: this.state.packageID,
                deliveryDate: this.state.deliveryDate,
                includeBlankAlerts: this.state.includeBlankAlerts,
                includeHeaders: this.state.includeHeaders,
                selectedReports: this.state.reportOptions.filter(option => {
                    return this.state.selectedReports.length > 0 ?
                    this.state.selectedReports.find(sel => sel === option.value.toString()) : null;
                }).map(x => { return x.label }),
                sortOrder: sort,
                isReprint: this.props.reprint
            } 
        )
        .then((response) => {
            //transition to the next screen
            this.setState({ showLoading: false });
            this.props.onComplete();
        })
        .catch((error) => {
            this.setState({
                showLoading: false,
                showError: true,
                errorMsg: error.response && error.response.data.message
            });
        });
    }

    handleCheckboxEvent = (e) => {
        if (e.target.value === 'alert') {
            this.setState({ 
                includeBlankAlerts : e.target.checked,
                faxToProvider: false
            });
        }
        else if (e.target.value === 'header') {
            this.setState({ 
                includeHeaders : e.target.checked,
                faxToProvider: false
            });
        }
    }
    
    handleDeliveryDateChange = (e) => {  
        this.setState({ deliveryDate: e })
    }

    handleSelectReport = (e) => {
        if (e[0].selected) {
            this.setState({ selectedReports: [...this.state.selectedReports, e[0].value] });
        } 
        else {
            this.setState({
                selectedReports: this.state.selectedReports.filter(report => {
                    return report !== e[0].value;
                })
            });
        }
    }
    
    handleSelectAllReports = (e) => {
        if (this.state.selectedReports.length !== this.state.reportOptions.length) {
            this.setState({ selectedReports:
                this.state.reportOptions.map((report) => {
                    return report.value.toString();
                })
            });
        }
        else {
            this.setState({ selectedReports: [] });
        }
    }

    render() {
        return (
            <Modal className="printModal" show={this.props.visible} onHide={this.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title><b>Print Options</b></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: '560px'}}>
                <Form>
                    <Row style={{marginTop: '1rem'}}>
                        <Col sm={10} smOffset={1}>
                            <FormGroup>
                                <ControlLabel style={{marginRight: '1rem'}}>Include:</ControlLabel>
                                <br />
                                <input type="checkbox" onChange={this.handleCheckboxEvent} value="alert" disabled={this.lineOfBusiness === 'Commercial'} />
                                    <span style={{marginLeft:'7px', marginRight:'20px'}}>Blank Alerts</span>
                                <input type="checkbox" onChange={this.handleCheckboxEvent} value="header" />
                                    <span style={{marginLeft:'7px', marginRight:'20px'}} >Headers</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1rem'}}>
                        <Col sm={6} smOffset={1}>
                            <FormGroup>
                                <ControlLabel style={{marginRight: '1rem'}}>Delivery Date:</ControlLabel>
                                <DatePicker  
                                value={this.state.deliveryDate} onChange={this.handleDeliveryDateChange} clearButtonElement={<Glyphicon glyph="calendar" />}></DatePicker> 
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10} smOffset={1} style={{marginTop: '1rem'}}>
                            <FormGroup>
                                <ControlLabel style={{marginRight: '1rem'}}>Selected Reports:</ControlLabel>
                                <br />
                                <Multiselect buttonClass="btn btn-danger" style={{buttonWidth: '250px'}} multiple data={this.state.reportOptions} includeSelectAllOption="true"
                                    onChange={this.handleSelectReport} onSelectAll={this.handleSelectAllReports} onDeselectAll={this.handleSelectAllReports} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1rem'}}>
                        <Col sm={10} smOffset={1}>
                            <b className="text-primary">Once finished, the contents of the package will be locked and cannot be changed.</b>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1rem'}}>
                        <Col sm={10} smOffset={1}>
                            <h5 style={{display: this.props.alertSortDisplay.length ? 'block' : 'none'}}>The alerts will be printed in the order listed: <b>{this.props.alertSortDisplay}</b></h5>
                            <h5 style={{display: this.props.querySortDisplay.length ? 'block' : 'none'}}>The queries will be printed in the order listed: <b>{this.props.querySortDisplay}</b></h5>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1rem', marginBottom: '1rem'}}>
                        <Col sm={10} smOffset={1}>
                            {
                                this.state.showError &&
                                <b className="text-danger" style={{display: 'block'}}>Error: {this.state.errorMsg}</b>
                            }
                            {
                                this.state.showLoading &&
                                <b className="text-warning" style={{display: 'block'}}>Loading...</b>
                            }
                            {
                                this.state.faxMessage.length > 0 &&
                                <b className="text-warning" style={{display: 'block'}}>{this.state.faxMessage}</b>
                            }
                        </Col>
                    </Row> 
                </Form> 
                </Modal.Body>
                <Modal.Footer>
                        <Button bsStyle={'success'} style={{float: 'right'}} onClick={this.printPackage}>
                            <Glyphicon glyph="print" style={{'marginRight': '0.2rem'}} /> Print
                        </Button>
                        <Button style={{float: 'left'}} onClick={this.handleModalToggle}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PrintPackageModal;
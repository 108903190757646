import React from 'react';
import axios from 'axios';
import { uniqBy, union } from 'lodash';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import { CDIAlertWorkflowStatus } from '../../../enums/CDIAlertWorkflowStatus';
import { SessionParamCache } from '../../../utils/SessionUtils'
import AlertModal from './AlertModal';

//todo: refactor
class CDIAlertWorkflowsSearch extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`pecSettings-${this.lineOfBusiness}`, 'local');
        this.paramCacheKey = 'cdiAlertWorkflowSearch';

        const savedParams = this.paramCache.getParamObj(this.paramCacheKey);
        this.defaultSearchParams = {
            memberID: '',
            alertID: '',
            memberFirstName: '',
            memberLastName: '',
            createdByUserID: '',
            parentOrganizationID: '',
            practiceIDs: [],
            providerID: '',
            outboundTypeIDs: [],
            maxAppointmentDate: '',
            minAppointmentDate: '',
            maxDeliveryDate: '',
            minDeliveryDate: '',
            statusIDs: [CDIAlertWorkflowStatus.Created, CDIAlertWorkflowStatus.QueryInitiated],
            assignedPECUserID: ''
        };
        this.outstandingProviderRequest = {};

        this.state = {
            providers: [],
            memberFirstNameInvalid: false,
            searchParams: { ...this.defaultSearchParams, ...savedParams },
            showAddAlertModal: window.location.href.search("isPECAddAlert=true") > -1 ? true : false,
            exceptionAlertsExist: '',
            singleExceptionAlert: ''
        };
    }

    componentWillMount() {
        this.props.search(this.state.searchParams)
    }

    componentDidMount() {
        this.showUserExceptionAlerts();
    }

    showUserExceptionAlerts = () => {
        axios.get('/alert/GetMemberExceptionAlerts')
        .then(response => {
            let alertExceptionCount = response.data.length;
            if (alertExceptionCount > 0) {
                if (alertExceptionCount === 1)
                {
                    const alertUrl = `/cdi/${response.data[0]}/queryResponse?isPECUpdateAlert=true`;
                    this.props.toast('warning', <div><b><a href={alertUrl} onClick={() => this.openAlertLink(alertUrl)}>Alert Workflow ID {response.data[0]}</a></b>
                        &nbsp;is in exception status. Please review.</div>, '', { tapToDismiss: false });
                }
                else {
                    this.props.toast('warning', 'Multiple alert workflows are in exception status. Please review.', '', { tapToDismiss: false });
                }
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load alert exceptions.', 'Error');
        });
    }

    openAlertLink = (alertUrl) => {
        //todo: does this need rel="noopener noreferrer"?
        window.open(alertUrl, "_blank");
    }

    clearSearchParams = () => {
        this.setState({ searchParams: {...this.defaultSearchParams} },
            () => this.props.search(this.state.searchParams)
        );
    }

    handleTextBoxChange = (statePropertyName) => (e) => {
        if(statePropertyName === "memberLastName" && e.target.value.length === 0){
            this.setState({
                searchParams: {
                    ...this.state.searchParams,
                    ...{[statePropertyName]: e.target.value},
                    ...{"memberFirstName": ''}
                }
            })
        }
        else {
            this.setState({
                searchParams: {
                    ...this.state.searchParams,
                    ...{[statePropertyName]: e.target.value}
                }
            });
        }
    }

    handleDropDownChange = (statePropertyName) => (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{[statePropertyName]: e ? e.value : e}
            }
        });
    }

    handleProviderOrgChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{
                    practiceIDs: [],
                    groupingID: '', 
                    providers: [],
                    providerID: '',
                    parentOrganizationID: e ? e.value : e,
                }
            }
        });
    }

    //todo: refactor
    getProvidersForPractice = (groupingID) => {
        if(!this.outstandingProviderRequest[groupingID]) {
            this.outstandingProviderRequest[groupingID] = this.props.getProvidersForPractice(groupingID);
        }
        this.outstandingProviderRequest[groupingID]
            .then(response => {
                var existingProv = this.state.providers;
                var systemEntered = response.data.systemEntered.map(item => {item.groupingID = groupingID; return item}); 
                var userEntered = response.data.userEntered.map(item => {item.groupingID = groupingID; return item}); 
                this.setState({
                    providers: uniqBy(union(existingProv, union(systemEntered, userEntered)), item => item.providerName)
                        .sort(function(a, b){
                            if(a.providerName < b.providerName) return -1;
                            else if(a.providerName > b.providerName) return 1;
                            return 0;
                        })
                })
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load practice data.', 'Error');
            });
    }

    handlePracticeChange = (options) => {
        var groupingIDs = options.map(item => item.value);
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{
                    practiceIDs: groupingIDs,
                }
            },
            providers: []
        });
        groupingIDs.forEach(item => this.getProvidersForPractice(item), this);
    }

    handleStatusChange = (options) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{statusIDs: options.map(item => item.value)}
            }})
    }

    handleOutboundTypeChange = (options) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{
                    outboundTypeIDs: options.map(item => item.value)
                }
            }
        });
    }

    handleDateChange = (statePropertyName) => (dateValue) => {
        if((statePropertyName === "maxAppointmentDate" || statePropertyName === "maxDeliveryDate") && dateValue !== null){
            dateValue = new Date((new Date(dateValue)).setHours(18,59,59,0)).toISOString();
        }
        else if((statePropertyName === "minAppointmentDate" || statePropertyName === "minDeliveryDate") && dateValue !== null){
            dateValue = new Date((new Date(dateValue)).setHours(0,0,0,0)).toISOString();
        }
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{[statePropertyName]: dateValue}
            }
        });
    }

    handleSearchClick = () => {
        this.props.search(this.state.searchParams, 1, this.props.pageSize);
    }

    toggleAddAlertModal = () => {
        this.setState({ showAddAlertModal: !this.state.showAddAlertModal });
    }
    
    render() {
        const practices = this.props.practices
        .filter(item => {
            const parentOrg = this.state.searchParams.parentOrganizationID
            return !parentOrg || item.providerOrganizationID === parentOrg;
        })
        .map(item => {
            return {
                value: item.groupingID,
                label: item.description + ' - ' + item.groupingID,
            }
        });
        
        const statuses = this.props.cdiAlertWorkflowStatuses ?
        this.props.cdiAlertWorkflowStatuses.map(item => {
            return {
                value: item.id,
                label: item.description,
            }
        }) : [];

        const outboundTypes = this.props.outboundTypes ?
        this.props.outboundTypes.map(item => {
            return {
                value: item.practiceOutboundTypeID,
                label: item.outboundMethod,
            }
        }) : [];

        const providerOrgs = this.props.providerOrgs
        .map(item => {
            return {
                value: item.id,
                label: item.description
            }
        });

        const providers = this.state.providers
        .map(item => {
            return {
                value: item.internalKey,
                label: item.lastName + ', ' + item.firstName + ' - ' + item.groupingID
            }
        });

        const memberNamesNotLongEnough = this.state.searchParams.memberFirstName.length === 1 || this.state.searchParams.memberLastName.length === 1;

	    return (
            <Grid fluid style={{marginTop: '2rem'}}>
                <Form horizontal>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Member ID
                                </ControlLabel>
                                <FormControl type="text" value={this.state.searchParams.memberID} onChange={this.handleTextBoxChange('memberID')}/>
                            </FormGroup>
                        </Col>
                        <Col sm={1} />
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Member Last Name
                                </ControlLabel>
                                <FormControl type="text" value={this.state.searchParams.memberLastName} onChange={this.handleTextBoxChange('memberLastName')}/>
                            </FormGroup>
                        </Col>
                        <Col sm={1} />
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Member First Name
                                </ControlLabel>
                                <FormControl type="text" value={this.state.searchParams.memberFirstName} onChange={this.handleTextBoxChange('memberFirstName')}
                                disabled={this.state.searchParams.memberLastName.length < 2}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Alert ID
                                </ControlLabel>
                                <FormControl type="text" value={this.state.searchParams.alertID} onChange={this.handleTextBoxChange('alertID')}/>
                            </FormGroup>
                        </Col>
                        <Col sm={1} />
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Assigned PEC
                                </ControlLabel>
                                <Select
                                    value={this.state.searchParams.assignedPECUserID}
                                    options={this.props.assignedPECs ? this.props.assignedPECs
                                                .map(item => {
                                                    return {
                                                        value: item.userID,
                                                        label: item.userName,
                                                    }
                                                }) : []}
                                    onChange={this.handleDropDownChange('assignedPECUserID')}/>
                            </FormGroup>
                        </Col>
                        <Col sm={1} />
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Workflow Statuses
                                </ControlLabel>
                                <Select
                                    multi
                                    value={this.state.searchParams.statusIDs}
                                    options={statuses}
                                    onChange={this.handleStatusChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Provider Org
                                </ControlLabel>
                                <Select
                                    value={this.state.searchParams.parentOrganizationID}
                                    options={providerOrgs}
                                    onChange={this.handleProviderOrgChange}/>
                            </FormGroup>
                        </Col>
                        <Col sm={1} />
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Practices
                                </ControlLabel>
                                <Select
                                    multi
                                    value={this.state.searchParams.practiceIDs}
                                    options={practices}
                                    autosize={true}
                                    onChange={this.handlePracticeChange}/>
                            </FormGroup>
                        </Col>
                        <Col sm={1} />
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>
                                    Practice Providers
                                </ControlLabel>
                                <Select
                                    value={this.state.searchParams.providerID}
                                    options={providers}
                                    onChange={this.handleDropDownChange('providerID')}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <ControlLabel>
                                    Appointment Date
                                </ControlLabel>
                                <div>
                                    <div style={{width: '36%', display: 'inline-block', marginRight: '1.9rem'}}>
                                        <DatePicker value={this.state.searchParams.minAppointmentDate} onChange={this.handleDateChange('minAppointmentDate')} style={{zIndex: 'auto'}}/>
                                    </div>
                                    <div style={{width: '36%', display: 'inline-block'}}>
                                        <DatePicker value={this.state.searchParams.maxAppointmentDate} onChange={this.handleDateChange('maxAppointmentDate')} style={{zIndex: 'auto'}}/>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <ControlLabel>
                                    Delivery Date
                                </ControlLabel>
                                <div>
                                    <div style={{width: '36%', display: 'inline-block', marginRight: '1.9rem'}}>
                                        <DatePicker value={this.state.searchParams.minDeliveryDate} onChange={this.handleDateChange('minDeliveryDate')} style={{zIndex: 'auto'}}/>
                                    </div>
                                    <div style={{width: '36%', display: 'inline-block'}}>
                                        <DatePicker value={this.state.searchParams.maxDeliveryDate} onChange={this.handleDateChange('maxDeliveryDate')} style={{zIndex: 'auto'}}/>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>                                
                                <ControlLabel>
                                    Practice Type
                                </ControlLabel>                        
                                <Select 
                                    multi
                                    value={this.state.searchParams.outboundTypeIDs}
                                    options={outboundTypes}
                                    autosize={true}
                                    onChange={this.handleOutboundTypeChange}/> 
                            </FormGroup>                           
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} style={{width: '29%', paddingLeft: '0px'}}>
                            <FormGroup>
                                <Button disabled={!this.props.searchEnabled || memberNamesNotLongEnough}
                                    onClick={this.handleSearchClick} style={{margin: '1rem'}}>
                                    <Glyphicon glyph="search" style={{'marginRight': '0.2rem'}} /> Search Workflows
                                </Button>
                                <Button onClick={this.clearSearchParams} style={{marginRight: '2rem'}}>
                                    Clear Search
                                </Button>
                                <Button onClick={this.toggleAddAlertModal} style={{margin: '1rem'}}>
                                    <Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} /> Add Alert
                                </Button>
                                <AlertModal visible={this.state.showAddAlertModal} toggleModal={this.toggleAddAlertModal} toast={this.props.toast} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </Grid>
        );
    }
}
export default CDIAlertWorkflowsSearch;
// exports LineItemReviewStatus - an immutable object 'enum'
// usage: import { LineItemReviewStatus } from '../../enums/LineItemReviewStatus';
const lineItemReviewStatus = {
    Captured: 10,
    NotCaptured: 11,
    Confirmed: 50,
    MDConfirmed: 51,
    CoderQAConfirmed: 52,
    ReadyForSubmission: 60,
    CoderQARejected: 97,
    MDRejected: 98,
    Rejected: 99,
    Reconciled: 100
};
Object.freeze(lineItemReviewStatus);
export const LineItemReviewStatus = lineItemReviewStatus;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default class CampaignEditorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignTypeOptions: null,
            campaignTypeID: '',
            campaignName: '',
            campaignDescription: ''
        }
    }

    componentDidMount() {
        Promise.all([
            this.getCampaignTypeOptions()
    ])
    .then((responses) => {
        this.setState({
            campaignTypeOptions: responses[0].data.map(x => {
                return {
                    label: x.campaignTypeDescription,
                    value: x.campaignTypeID
                }
            })
        })
    })
        .catch((error) => {
            this.setState({ loaded: true });
            this.props.toast('error', 'Could not load Campaign data.', 'Error');
        });
    }

    handleSaveBtnClick = () => {
        axios.post('/campaign', {
            campaignTypeID: this.state.campaignTypeID,
            description: this.state.campaignDescription,
            campaignName: this.state.campaignName
        })
        .then((response) => {
            this.setState({ 
                campaignTypeID: '',
                campaignDescription: '',
                campaignName: ''
                });
                this.props.refreshCampaignDropDownOptions();
                this.props.toast('success', 'New Campaign added.', 'Success');
        })
        .catch((error) => {
            if(error.request.status === 409)
            {
                this.props.toast('error', 'Campaign Name and Campaign Type already exists.', 'Error');
            }
            else{
                this.props.toast('error', 'Failed to add Campaign.', 'Error');
            }
            
        });

    }

    getCampaignTypeOptions = () => {
        return axios.get(`/campaign/campaignTypes`);
    }

    handleCampaignTypeChange = (e) => {
        this.setState({ campaignTypeID: e ? e.value : '' });
    }

    handleCampaignNameInput = (e) => {
        this.setState({ campaignName: e.target.value });
    }

    handleCampaignDescriptionInput = (e) => {
        this.setState({ campaignDescription: e.target.value });
    }

    render() {
        const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell
        };

        return (
            <div>
                <Modal show={this.props.visible} onHide={this.props.toggleModal} keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: '340px' }}>
                        <Row>
                            <Col xs={8}>
                                <FormGroup>
                                    <ControlLabel className="requiredField">Campaign Name</ControlLabel>
                                    <FormControl type="text" 
                                                 value={this.state.campaignName} 
                                                 onChange={this.handleCampaignNameInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <FormGroup>
                                    <ControlLabel className="requiredField">Campaign Type</ControlLabel>
                                    <Select value={this.state.campaignTypeID} 
                                            options={this.state.campaignTypeOptions} 
                                            onChange={this.handleCampaignTypeChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={8}>
                                <FormGroup>
                                    <ControlLabel className="requiredField">Campaign Description</ControlLabel>
                                    <FormControl type="text" 
                                                 value={this.state.campaignDescription} 
                                                 onChange={this.handleCampaignDescriptionInput} />
                                </FormGroup>
                            </Col>
                            <Col xs={1}>
                                <div style={{ paddingTop: '2.4rem' }}>
                                    <Button onClick={this.handleSaveBtnClick}
                                        disabled={this.state.campaignName.length < 1 
                                               || this.state.campaignTypeID.length < 1 
                                               || this.state.campaignDescription.length < 1}>
                                         Add
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

import React, { Component } from 'react';
import DatePicker from 'react-16-bootstrap-date-picker';
import { Button, Col, Collapse, ControlLabel, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import Select from 'react-select';
import PracticeSearchModal from '../../Shared/PracticeSearchModal';
import ProviderSearchModal from '../../Shared/ProviderSearchModal';
import WorkflowSectionHeader from '../AlertWorkflow/WorkflowSectionHeader';

class OOAAppointmentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPracticeSearchModal: false,
            showProviderSearchModal: false
        };
    }

    getPracticeOptions = () => {
        return this.props.practices.map((item) => {
            return { value: item.groupingID, label: item.description }
        });
    }

    getProviderOptions = () => {
        return this.props.practiceProviders.map((item) => {
            return { value: item.internalKey, label: `${item.lastName}, ${item.firstName}` }
        });
    }

    handlePracticeChange = (e) => {
        const selectedValue = e ? e.value : '';
        const selectedText = e ? e.label : '';
        this.props.handleAlertPracticeChange(selectedValue, selectedText);
    }

    handlePracticeChangeFromModal = (e) => {
        const selectedValue = e ? e.groupingID : '';
        const selectedText = e ? e.description : '';
        
        this.props.handleAlertPracticeChange(selectedValue, selectedText);
    }

    handleProviderChange = (e) => {
        this.props.handleAlertDataFieldChange({ appointmentProviderMasterID: e ? e.value : '' });
    }

    handleProviderModalSelect = (internalKey, providerID, providerNPI, lastName, firstName) => {
        this.props.attachProviderToPractice(internalKey);
    }

    handleAppointmentDateChange = (e) => {
        this.props.handleAlertDataFieldChange({ appointmentDate: e });
    }

    togglePracticeSearchModal = () => {
        this.setState({ showPracticeSearchModal: !this.state.showPracticeSearchModal });
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderSearchModal: !this.state.showProviderSearchModal });
    }


    render() {
        return (
            <div className="alert-workflow-subcontainer">
                <WorkflowSectionHeader expanded={this.props.expanded} toggle={this.props.toggleSection}
                    toggleKey="appointment" title="Appointment" />
                <Collapse in={this.props.expanded}>
                    <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>Appointment Practice</ControlLabel>
                                    <Row style={{ margin: 0 }}>
                                        <Col xs={11} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                                            <Select value={this.props.alertData.appointmentPracticeCode} options={this.getPracticeOptions()}
                                                onChange={this.handlePracticeChange} disabled={this.props.isViewOnly} />
                                        </Col>
                                        <Col xs={1} style={{ paddingRight: '0rem', paddingLeft: '0rem' }}>
                                            <Button onClick={this.togglePracticeSearchModal} bsSize="small" disabled={this.props.isViewOnly}
                                                style={{ height: '3.4rem', minWidth: '4rem', width: '100%' }}>
                                                <Glyphicon glyph="search" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>Appointment Provider</ControlLabel>
                                    <Row style={{ margin: 0 }}>
                                        <Col xs={11} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                                            <Select value={this.props.alertData.appointmentProviderMasterID} options={this.getProviderOptions()}
                                                disabled={this.props.providerDropdownDisabled || !this.props.alertData.appointmentPracticeCode
                                                    || this.props.isViewOnly} onChange={this.handleProviderChange} />
                                        </Col>
                                        <Col xs={1} style={{ paddingRight: '0rem', paddingLeft: '0rem' }}>
                                            <Button onClick={this.toggleProviderSearchModal} bsSize="small" disabled={this.props.isViewOnly}
                                                style={{ height: '3.4rem', minWidth: '4rem', width: '100%' }}>
                                                <Glyphicon glyph="search" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }}>Appointment Date</ControlLabel>
                                    <DatePicker value={this.props.alertData.appointmentDate} onChange={this.handleAppointmentDateChange}
                                        style={{ zIndex: 'auto' }} disabled={this.props.isViewOnly} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
                <PracticeSearchModal visible={this.state.showPracticeSearchModal} handleModalToggle={this.togglePracticeSearchModal}
                    handleSelectSearchResult={this.handlePracticeChangeFromModal} toast={this.props.toast} />
                <ProviderSearchModal visible={this.state.showProviderSearchModal} handleModalToggle={this.toggleProviderSearchModal}
                    handleSelectSearchResult={this.handleProviderModalSelect} toast={this.props.toast} />
            </div>

        );
    }
}

export default OOAAppointmentInfo;
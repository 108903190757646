import React from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import AlertToastr from '../../Shared/AlertToastr';
import CoderQA from './CoderQA';
import Complete from './Complete';
import Incomplete from './Incomplete';
import InitialReview from './InitialReview';
import QA from './QA';
import Reports from '../../Reports/Reports';
import { ApplicationReportGroup } from '../../../enums/ApplicationReportGroup';
import { Securable } from '../../../enums/Securable';
import { SecurableType } from '../../../enums/SecurableType';
import { SessionParamCache } from '../../../utils/SessionUtils';
import { sortBy } from '../../../utils/SortUtils';


const mapStateToProps = state => {
    const currentUserProp = state.session.find(x => x.currentUser);
    return currentUserProp ? currentUserProp : {};
};

//todo: create viewbag analog for LandingController.cs
class ReviewSearchContainer extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = 'activeLandingTab';
        const savedParams = this.paramCache.getParamObj(this.paramCacheKey);

        this.state = {
            activeTab: savedParams.activeTabID ? savedParams.activeTabID : 1,
            //loadedTabs: [activeTabID],
            projects: [],
            practices: [],
            providerOrgs: [],
            reports: [],
            // allProviderOrgs: [],
            // allPractices: [],
            users: [],
            initialCoders: [],
            landingSecurables: [],
            authorizedReports: [],
            requireProviderIDForRecordTypeEMR: true,
            nlpProcessEnabledValue: false,
            newNLPScreenEnabled: false,
            currentUser: this.props.currentUser,
            loaded: false,
            toast: null,
            riskMitigationUri:'',
            maLobNlpProcessEnabledValue: false,
            commLobNlpProcessEnabledValue: false,
            //assignedPECs: []
            commaSeparatedValues: '', // State to store the response
        };
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        Promise.all([
            this.getProjects(),
            this.getPractices(),
            this.getUsers(),
            this.getFilteredCoders('Initial Coder'),
            this.getSecurables(),
            this.getConfigValues(),
            this.getNLPProcessConfigValue(),
            this.getNewNLPScreenConfigValue(),
            this.getRiskMitigationUri(),
            this.getMALobNLPConfigValue(),
            this.getCommLobNLPConfigValue(),
            this.getCancelRolesConfigValue()
        ])
        .then((responses) => {
            this.setState({
                projects: responses[0].data,
                providerOrgs: this.getUniqueProviderOrgs(responses[1].data),
                practices: responses[1].data,
                users: responses[2].data,
                initialCoders: responses[3].data,
                landingSecurables: responses[4].data.filter(x => x.securableTypeID === SecurableType.MRRLandingPageTab),
                authorizedReports: responses[4].data.filter(x => x.securableTypeID === SecurableType.Reports
                    && (x.applicationReportGroupID === ApplicationReportGroup.MRRReports || x.applicationReportGroupID === ApplicationReportGroup.CommReports)),
                requireProviderIDForRecordTypeEMR: responses[5].data && Boolean(responses[5].data.requireProviderIDForRecordTypeEMR),
                nlpProcessEnabledValue: responses[6].data.nlpProcessEnabled,
                newNLPScreenEnabled: responses[7].data.newNLPScreenEnabled,
                riskMitigationUri: responses[8].data.riskMitigationUrl,
                loaded: true,
                maLobNlpProcessEnabled: responses[9].data.maLobNLPEnabled,
                commLobNlpProcessEnabled: responses[10].data.commLobNLPEnabled,
                commaSeparatedValues : responses[11].data["incomplete Tab Cancel Functionality"]
            })
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.state.toast('error', 'Could not load MRR data.', 'Error');
        });
    }

    getProjects = () => {
        return axios.get('/projects');
    }

    getPractices = () => {
        return axios.get('/practice');
    }

    // getAllPractices = () => {
    //     axios.get('/practice/AllPractices')
    //     .then((response) => {
    //         this.setState({
    //             allProviderOrgs: this.getUniqueProviderOrgs(response.data),
    //             allPractices: response.data
    //         });
    //     })
    //     .catch((error) => {
    //         this.state.toast('error', 'Could not load practice data.', 'Error');
    //     });
    // }

    //get unique provider organizations & associated descriptions via practice data
    getUniqueProviderOrgs = (practiceData) => {
        return practiceData.reduce((uniqueProviderOrgs, practice) => {
            if (uniqueProviderOrgs.findIndex(p => p.id === practice.providerOrganizationID) === -1) {
                uniqueProviderOrgs.push({
                    id: practice.providerOrganizationID,
                    description: practice.providerOrganizationDescription + " - " + practice.providerOrganizationID
                });
            }
            return uniqueProviderOrgs;
        }, []).sort(sortBy('description'));
    }

    //todo -- validate this. previously:
    //     var userObj = $.Deferred();
    // $scope.promises.push(userObj.promise());
    // dataFetcherService.getData('/merlinuser/').success(function (data) {
    //     var flags = [];
    //     var distinctRecords = [];
    //     for (var i = 0; i < data.length; i++) {
    //         if (flags[data[i].fullName]) continue;
    //         flags[data[i].fullName] = true;
    //         distinctRecords.push(data[i]);
    //     }
    //     $scope.userOptions = distinctecords;

    //     userObj.resolve();
    // }).error(function (data) {
    //     console.dir(data);
    // });
    getUsers = () => {
        return axios.get('/MerlinUser');
        // .then(response => {
        //     this.setState({
        //         users: response.data
        //         // distinctUsers: response.data.reduce((a, b) => {
        //         //     if (b.userID && b.userName && a.findIndex(x => x.userID === b.userID) === -1) {
        //         //         a.push({ userID: b.userID, userName: b.userName });
        //         //     }
        //         //     return a;
        //         // }, [])
        //     });
        // })
        // .catch((error) => {
        //     this.state.toast('error', 'Could not load user data.', 'Error');
        // });
    }

    getFilteredCoders = (filterName) => {
        return axios.get('/merlinuser/', {
            params: {
                filter: filterName
            }
        });
    }

    //todo: should assigned PECs be used instead of /MerlinUser?
    // refreshAssignedPECs = () => {
    //     return axios.get('/Practice/UserAssignments')
    //     .then(response => {
    //         this.setState({
    //             //assigns an obj array of unique user IDs + names
    //             assignedPECs: response.data.reduce((a, b) => {
    //                 if (b.userID && b.userName && a.findIndex(x => x.userID === b.userID) === -1) {
    //                     a.push({ userID: b.userID, userName: b.userName });
    //                 }
    //                 return a;
    //             }, [])
    //         })
    //     })
    //     .catch(error => {
    //         this.state.toast('error', 'Could not load assigned PECs.', 'Error');
    //     });
    // }

    // updateAssignedPECs = (userID, userName) => {
    //     if (!this.state.assignedPECs.find(assignedPEC => assignedPEC.userID === userID)) {
    //         this.setState({
    //             assignedPECs: [...this.state.assignedPECs, { userID, userName }]
    //         });
    //     }
    // }

    getSecurables = () => {
        return axios.get('/securable', {
            params: {
                securableTypes: [SecurableType.MRRLandingPageTab, SecurableType.Reports]
            }
        });
    }

    getConfigValues = () => {
        return axios.get('/securable/config', { params: { configKeys: ["requireProviderIDForRecordTypeEMR"] } });
    }

    getNLPProcessConfigValue = () => {
        return axios.get('/securable/config', { params: { configKeys: ["NLPProcessEnabled"] } });
    }

    getNewNLPScreenConfigValue = () => {
        return axios.get('/securable/config', { params: { configKeys: ["NewNLPScreenEnabled"] } });
    }

    getCancelRolesConfigValue = () => {
        return axios.get('/securable/configDB', { params: { configKeys: ["Incomplete Tab Cancel Functionality"] } });
    }

    getRiskMitigationUri = () => {
        return axios.get('/securable/config',{ params: { configKeys: ["RiskMitigationUrl"] } } );
    }

    getMALobNLPConfigValue = () => {
        return axios.get('/securable/config',{ params: { configKeys: ["MALobNLPEnabled"] } } );
    }

    getCommLobNLPConfigValue = () => {
        return axios.get('/securable/config',{ params: { configKeys: ["CommLobNLPEnabled"] } } );
    }

    // getActiveTab = () => {
    //     let activeTab = 1;
    //     if (this.props.location.query.tab)
    //     {
    //         if (this.props.location.query.tab === "Packages")
    //         {
    //             activeTab = 2;
    //         }
    //         else if (this.props.location.query.tab === "PracticeInfo")
    //         {
    //             activeTab = 3;
    //         }
    //         else if (this.props.location.query.tab === "PracticeType")
    //         {
    //             activeTab = 4;
    //         }
    //         else if (this.props.location.query.tab === "FaxStatus")
    //         {
    //             activeTab = 5;
    //         }
    //         else if (this.props.location.query.tab === "ScanRoster")
    //         {
    //             activeTab = 6;
    //         }
    //     }

    //     return activeTab;
    // }

    handleSelect = (eventKey) => {
        this.paramCache.addParamObj(this.paramCacheKey, {
            activeTabID: eventKey
        });
        this.setState({
            activeTab: eventKey,
            //loadedTabs: [...this.state.loadedTabs, eventKey]
        });

        // this.getPractices();
        // if (this.state.loadedTabs.includes(eventKey)) {
        //     this.setState({
        //         activeTab: eventKey
        //     });
        // }
        // else {
        //     this.setState({
        //         activeTab: eventKey,
        //         loadedTabs: [...this.state.loadedTabs, eventKey]
        //     });
        // }
    }

    render() {
        return (
            <div style={{ marginTop: '2rem' }}>
                <Loader loaded={this.state.loaded}>
                    <Tabs id="mrr-landing-tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.InitialReviewTab) ?
                            <Tab eventKey={1} title="Initial Review">
                            {
                                //this.state.loadedTabs.includes(1) &&
                                <InitialReview projects={this.state.projects} providers={this.state.providerOrgs} practices={this.state.practices}
                                    users={this.state.users} requireProviderIDForRecordTypeEMR={this.state.requireProviderIDForRecordTypeEMR}
                                    currentUser={this.state.currentUser} toast={this.state.toast} nlpProcessEnabledValue = {this.state.nlpProcessEnabledValue}
                                    newNLPScreenEnabled={this.state.newNLPScreenEnabled} maLobNlpProcessEnabled={this.state.maLobNlpProcessEnabled} 
                                    commLobNlpProcessEnabled={this.state.commLobNlpProcessEnabled}/>
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.QATab) ?
                            <Tab eventKey={2} title="QA">
                            {
                                // this.state.loadedTabs.includes(2) &&
                                <QA projects={this.state.projects} isMD={false} toast={this.state.toast} 
                                        newNLPScreenEnabled={this.state.newNLPScreenEnabled}  nlpProcessEnabledValue={this.state.nlpProcessEnabledValue}
                                        maLobNlpProcessEnabled={this.state.maLobNlpProcessEnabled} commLobNlpProcessEnabled={this.state.commLobNlpProcessEnabled}/>
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.MedicalDirectorTab) ?
                            <Tab eventKey={3} title="Medical Director" onEnter={this.showFailedFaxExceptions}>
                            {
                                // this.state.loadedTabs.includes(3) &&
                                <QA projects={this.state.projects} isMD={true} toast={this.state.toast} />
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.IncompleteTab) ?
                            <Tab eventKey={4} title="Incomplete">
                            {
                                // this.state.loadedTabs.includes(4) &&
                                <Incomplete users={this.state.users} currentUser={this.props.currentUser} 
                                toast={this.state.toast} 
                                nlpProcessEnabledValue={this.state.nlpProcessEnabledValue} 
                                newNLPScreenEnabled={this.state.newNLPScreenEnabled} 
                                riskMitigationSite={this.state.riskMitigationUri} 
                                maLobNlpProcessEnabled={this.state.maLobNlpProcessEnabled} 
                                commLobNlpProcessEnabled={this.state.commLobNlpProcessEnabled} 
                                commaSeparatedValue={this.state.commaSeparatedValues}/>
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.CompleteTab) ?
                            <Tab eventKey={5} title="Complete">
                            {
                                // this.state.loadedTabs.includes(5) &&
                                <Complete users={this.state.users} projects={this.state.projects} initialCoders={this.state.initialCoders}
                                    showPreExtractQAButton={this.state.landingSecurables.find(x => x.securableID === Securable.PreExtractQAButton) !== null}
                                    showCoderQA={this.state.landingSecurables.find(x => x.securableID === Securable.CoderQATab) !== null}
                                    getFilteredCoders={this.getFilteredCoders} currentUser={this.props.currentUser}
                                    toast={this.state.toast} nlpProcessEnabledValue={this.state.nlpProcessEnabledValue} 
                                    newNLPScreenEnabled={this.state.newNLPScreenEnabled} maLobNlpProcessEnabled={this.state.maLobNlpProcessEnabled} commLobNlpProcessEnabled={this.state.commLobNlpProcessEnabled}/>
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.CoderQATab) ?
                            <Tab eventKey={6} title="Coder QA">
                            {
                                // this.state.loadedTabs.includes(6) &&
                                <CoderQA users={this.state.users} projects={this.state.projects} initialCoders={this.state.initialCoders} toast={this.state.toast} />
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.ReportsTab) ?
                            <Tab eventKey={7} title="Reports">
                            {
                                // this.state.loadedTabs.includes(6) &&
                                <Reports reports={this.state.authorizedReports} toast={this.state.toast} />
                            }
                            </Tab> : null
                        }                        
                    </Tabs>
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(ReviewSearchContainer);
// exports ClaimMatchTypeDescription - an immutable object 'enum'
// usage: import { ClaimMatchTypeDescription } from '../../enums/ClaimMatchTypeDescription';
const claimMatchTypeDescription = {
    1: 'Exact match, same provider',
    2: 'Exact match, same practice',
    3: 'Pending edge, same practice',
    4: 'No provider match, edge accepted',
    5: 'No provider match, pending edge',
    6: 'DOS near, edge accepted, same practice',
    7: 'DOS near, pending edge, same practice',
    8: 'DOS not near, same practice',
    9: 'DOS near, no provider match',
    10: 'No match',
    11: 'QA rejected'
};
Object.freeze(claimMatchTypeDescription);
export const ClaimMatchTypeDescription = claimMatchTypeDescription;
import React from 'react';
import { Glyphicon, Panel } from 'react-bootstrap';
import { sortDate } from '../../../utils/SortUtils';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import EncounterGroup from './EncounterGroup';
import '../../../styles/SidePanel.css';
import isEmpty from 'lodash/isEmpty';

class SidePanel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            clicked: '0'
        };
    }

    handleToggle = (index, encounterId, encounterDate)=>{
        if(this.state.clicked === index) {
            return this.setState({clicked:'0'});
          }
        this.setState({clicked:index});
        this.props.changeCurrentEncounter(encounterId, encounterDate);
    }

    renderEncounter = (encounterName, encounterId, index) => {
        return (
            <React.Fragment key={index}>
                <EncounterGroup encounterName={encounterName}
                                handleSamePageNav={this.props.handleSamePageNav} 
                                onToggle={() => this.handleToggle(index, encounterId, encounterName)}
                                active={this.state.clicked == index}/>
            </React.Fragment>
        );
    }
    
    render() {
        return (
            <Panel>
                <Panel.Heading>
                    <Panel.Title componentClass="h3">Medical Record Chart</Panel.Title>
                </Panel.Heading>
                <Panel.Body style={{ maxHeight: '85vh', overflow: 'overlay' }}>
                    <div className='panelDiv'>
                        <span className='panelLink' onClick={()=>this.props.handleSamePageNav('Patient Demographics')}>Patient Demographics</span>
                    </div>
                    <div className='panelDiv'>
                        <span className='panelLink' onClick={()=>this.props.handleSamePageNav('Encounters')}>Encounters</span>
                    </div>                   
                    {
                        !isEmpty(this.props.data) ? this.props.data.sort((a, b) => sortDate(a.encounter_start_date, b.encounter_start_date, 'desc') ).map((x, index) => {
                            return this.renderEncounter(formatDatetimeStringWithPrefixZero(x.encounter_start_date), x.encounter_id, index)
                        }) : ''
                    }
                    {/* TODO: Additional Info should only be rendered if there's data for it */}
                    {this.renderEncounter('Additional Info', -1, -1)}

                    <div className='panelDiv'>
                        <span className='panelLink' onClick={()=>this.props.handleSamePageNav('History of Present Illness')}>History of Present Illness</span>
                    </div>

                    <div className='panelDiv'>
                        <span className='panelLink' onClick={()=>this.props.handleSamePageNav('assessments')}>Assessments</span>
                    </div>
                    <div className='panelDiv'>
                        <span className='panelLink' onClick={()=>this.props.handleSamePageNav('assessmentquestions')}>Assessment Questions</span>
                    </div>
                    <div className='panelDiv'>
                        <span className='panelLink' onClick={()=>this.props.handleSamePageNav('attachments')}>Attachments</span>
                    </div>
                </Panel.Body>
            </Panel>
        )
    }
}

export default SidePanel;
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CreatePackage from './CreatePackage';
import PackageTable from './PackageTable';
import { OutboundType } from '../../../enums/OutboundType';

class Packages extends React.Component {
    render() {
        const practices = this.props.practices.filter((practice) => {
            return practice.outboundTypeID !== OutboundType.EPEC;
        });

        const providers = this.props.providers.filter((provider) => {
            return practices.find(practice => practice.providerOrganizationID === provider.id);
        });

        return (
            <div style={{marginTop: '2rem'}}>
                <CreatePackage router={this.props.router} providers={providers} practices={practices} toast={this.props.toast} />
                <Tabs id="packagesTabs" defaultActiveKey={1} style={{'marginTop': '2rem'}}>
                    <Tab eventKey={1} title="In Progress">
                        <PackageTable router={this.props.router} status={"In Progress"} providers={providers} practices={practices} toast={this.props.toast} />
                    </Tab>
                    <Tab eventKey={2} title="Completed">
                        <PackageTable router={this.props.router} status={"Completed"} providers={providers} practices={practices} toast={this.props.toast} />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default Packages;
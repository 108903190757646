import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import { MedicalRecordType } from '../../../enums/MedicalRecordType';
import { SessionParamCache } from '../../../utils/SessionUtils';
import ProviderTypeaheadSearch from '../../Shared/ProviderTypeaheadSearch';
import MemberTypeaheadSearch from '../../Shared/MemberTypeaheadSearch';
import { UserRole } from '../../../enums/UserRole';
import { ProjectInventoryType } from '../../../enums/ProjectInventoryType'

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

//todo: why does this have different column names from prospective search for non-Scan record types? (ex. DoSTo/DosFrom vs. Dos/DosThru)
class RetroSearch extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = 'retroSearch';
        const savedParams = this.paramCache.getParamObj(this.paramCacheKey);

        this.state = {
            recordType: savedParams.recordType ? savedParams.recordType : 
                this.props.project.projectRetrieveTypeID === ProjectInventoryType.RMVendor ? MedicalRecordType.Scan : '',
            planMemberID: savedParams.planMemberID ? savedParams.planMemberID : '',
            selectedProvider: savedParams.selectedProvider ? savedParams.selectedProvider : '',
            selectedMember: savedParams.selectedMmeber ? savedParams.selectedMember : '',
            memberMedicalRecords: [],
            showMemberSearchModal: false,
            showProviderSearchModal: false,
            waitingOnSearch: false
        };
    }

    handleRecordTypeChange = (e) => {
        this.setState({ recordType: e && e.value ? e.value : '' });
    }

    handleMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value })
    }

    handleProviderTypeaheadChange = (e) => {
        this.setState({ selectedProvider: e });
    }

    handleMemberTypeaheadChange = (e) => {
        this.setState({ selectedMember: e });
    }

    //todo: test further
    searchRetroReviews = () => {
        this.setState({ waitingOnSearch: true });

        axios.get('/medicalrecordreview/retroOpenReviews', {
            params: {
                projectID: this.props.project.value,
                recordTypeID: this.state.recordType ? this.state.recordType : '',
                memberID: this.state.selectedMember ? this.state.selectedMember.memberID : '',
                providerMasterID: this.state.selectedProvider ? this.state.selectedProvider.internalKey : null,
                providerID: '' //todo: remove this once the service is updated to only use providerMasterID
            }
        })
        .then((response) => {
            this.cacheInitialReviewParams();
            this.paramCache.addParamObj(this.paramCacheKey, {
                recordType: this.state.recordType,
                planMemberID: this.state.selectedMember?.memberID,
                selectedProvider: this.state.selectedProvider
            });

            this.setState({
                memberMedicalRecords: response.data,
                waitingOnSearch: false
            });

            if (response.data.length === 0) {
                this.props.toast('warning', "There are no records in Ready for Review status.", '');
            }
        })
        .catch((error) => {
            this.setState({ waitingOnSearch: false });
            this.props.toast('error', "Could not search retro reviews.", 'Error');
        });
    }

    redirectReviewToNlpScreen = (mrrId, projectId, hasNlpResults) => {
        console.log(this.lineOfBusiness);
        console.log(this.props.maLobNlpProcessEnabled);
        console.log(this.props.commLobNlpProcessEnabled);
        console.log(this.props.newNLPScreenEnabled);
        console.log(this.props.nlpProcessEnabledValue);
        console.log(mrrId);
        console.log(projectId);
        console.log(hasNlpResults);
        console.log(this.lineOfBusiness === 'Commercial')
        if ((this.lineOfBusiness === 'MA' && this.props.maLobNlpProcessEnabled === 'true') || (this.lineOfBusiness === 'Commercial' && this.props.commLobNlpProcessEnabled === 'true')) 
        {
        console.log("Entered loop1");
            if (this.props.newNLPScreenEnabled === 'true' && this.props.nlpProcessEnabledValue === 'true'
                && mrrId && projectId && hasNlpResults) {
        console.log("Entered loop2");
                return true;
            }
            else {
                return false;
            }
        }
        else{
            return false;
        }

    }
   //todo: test
   startScanRetroReview = () => {
    console.log("RetroSearch-this.props.newNLPScreenEnabled value: " + this.props.newNLPScreenEnabled); 
    if (this.props.currentUser != null && this.props.currentUser.authorizedRoles.some(x => x.roleID === UserRole.RiskMitigation) 
        && this.props.project.projectRetrieveTypeID === ProjectInventoryType.RMVendor) {
            axios.get(`/medicalrecordreview/project/${this.props.project.value}`)
            .then((response) => {
                window.location.replace(response.data);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    this.props.toast('warning', "Risk mitigation url is missing.", '');
                } else {
                    this.props.toast('error', "Could not redirect to the RM UI.", 'Error');
                }
            });
    }
    else if (this.state.recordType) 
    {
        this.cacheInitialReviewParams();
        this.paramCache.addParamObj(this.paramCacheKey, {
            recordType: this.state.recordType,
            planMemberID: '',
            selectedProvider: ''
        });

        axios.get(`/medicalrecordreview/project/${this.props.project.value}/NextCodingReviewByRecordType/${this.state.recordType}`)
        .then((response) => {
                if (this.redirectReviewToNlpScreen(response.data.medicalRecordReviewID, response.data.projectID, response.data.hasNlpResults)) {  
                    console.log("Inside nlp review screen redirect");     
                    window.location.href = `/NLP/InitialCoding?medicalRecordReviewID=${response.data.medicalRecordReviewID}&projectID=${response.data.projectID}`;                 
                }                      
                else{
                    console.log("Inside old review screen redirect");                  
                    window.location.href = `/ReviewProcessing/InitialCoding?medicalRecordReviewID=${response.data.medicalRecordReviewID}&projectID=${response.data.projectID}`;    
                }
        })
        .catch((error) => {
            if (error.response.status === 404) {
                this.props.toast('warning', "No record is available for review for this Project and Record Type.", '');
                }
            else {
                this.props.toast('error', "Could not start retro review.", 'Error');
            }
        });
    }
    else {
        this.props.toast('error', 'Review Type is required.', 'Error');
    }
}

    startNonScanRetroReview = (rowIndex) => {
        this.cacheInitialReviewParams();
        this.paramCache.addParamObj(this.paramCacheKey, {
            recordType: this.state.recordType,
            planMemberID: '',
            selectedProvider: ''
        });

        const mrrID = this.refs.table.state.data[rowIndex].medicalRecordReviewID;
        axios.post(`/medicalrecordreview/assignNew?medicalRecordReviewID=${mrrID}&newReview=true`)
        .then((response) => {
            window.location.href = `/ReviewProcessing/InitialCoding?medicalRecordReviewID=${mrrID}`;
        })
        .catch((error) => {
            this.props.toast('error', error && error.message ? error.message : "Could not start retro review.", 'Error');
        });
    }

    cacheInitialReviewParams = () => {
        this.paramCache.addParamObj('initialSearch', {
            project: this.props.project,
            reviewType: this.props.reviewType
        });
    }

    handleMemberSelect = (planMemberID) => {
        this.setState({ planMemberID: planMemberID });
    }

    toggleMemberSearchModal = () => {
        this.setState({ showMemberSearchModal: !this.state.showMemberSearchModal });
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderSearchModal: !this.state.showProviderSearchModal });
    }

    formatMemberName = (cell, row) => {
        return row ? `${row.memberFirstName} ${row.memberLastName}` : '';
    }

    formatProviderName = (cell, row) => {
        //MRR-32324-Handling null provider first name
        return row ? `${row.providerFirstName ? row.providerFirstName : ''} ${row.providerLastName ? row.providerLastName : ''}` : ''
    }

    render() {
        const customSelect = (props) => {
            return props.rowIndex !== 'Header' ? (
                <div>
                    <Button onClick={() => this.startNonScanRetroReview(props.rowIndex)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
            ) : null;
        };
        const selectRowProp = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '6rem'
        };

        return (
            <div>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel className="requiredField">Record Type</ControlLabel>
                            <Select value={this.state.recordType} options={this.props.getDropdownOptions(this.props.recordTypes)}
                                onChange={this.handleRecordTypeChange} />
                        </FormGroup>
                    </Col>
                    {
                        this.state.recordType !== MedicalRecordType.Scan &&
                        <div>
                           <Col xs={3}>
                           <MemberTypeaheadSearch value={this.state.selectedMember} handleMemberChange={this.handleMemberTypeaheadChange} 
                                showMemberSearchModal={this.state.showMemberSearchModal} toggleMemberSearchModal={this.toggleMemberSearchModal}
                                toast={this.props.toast} />
                                
                            </Col>
                            <Col xs={3}>
                                <ProviderTypeaheadSearch value={this.state.selectedProvider} handleProviderChange={this.handleProviderTypeaheadChange}
                                    showProviderModal={this.state.showProviderSearchModal} toggleProviderSearchModal={this.toggleProviderSearchModal}
                                    toast={this.props.toast} />
                            </Col>
                        </div>
                    }
                </Row>
                <Row>
                    <Col xs={3}>
                        {
                            this.state.recordType !== MedicalRecordType.Scan &&
                            <Button onClick={this.searchRetroReviews} disabled={this.state.waitingOnSearch}
                                style={{ marginTop: '2rem' }}>
                                <Glyphicon glyph="search" style={{ marginRight: '0.2rem' }} /> Search
                            </Button>
                        }
                        {
                            this.state.recordType === MedicalRecordType.Scan &&
                            <Button onClick={this.startScanRetroReview} style={{ marginTop: '2rem' }}>
                                <Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} /> Start Review
                            </Button>
                        }
                    </Col>
                </Row>
                <div style={{ marginTop: '4rem' }}>
                    <BootstrapTable data={this.state.memberMedicalRecords} ref="table" selectRow={selectRowProp} pagination>
                        <TableHeaderColumn dataField="medicalRecordReviewID" isKey hidden />
                        <TableHeaderColumn dataField="memberID" dataSort>Member ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberName" dataSort dataFormat={this.formatMemberName}>Member Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerID" dataSort>Provider ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerNPI">NPI</TableHeaderColumn>
                        <TableHeaderColumn dataField="provider.firstName" dataSort dataFormat={this.formatProviderName}>Provider Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="dateOfServiceFrom" dataSort>Date of Service From</TableHeaderColumn>
                        <TableHeaderColumn dataField="dateOfServiceTo" dataSort>Date of Service To</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(RetroSearch);
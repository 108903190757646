import React from 'react';

const WorkflowHeaderDetails = ({ provider, practice, deliveryDate, detailsButton, reprintButton, packageType }) =>
    <div>
        {
            <h4>
                {provider} - {practice} {deliveryDate ? '- ' + deliveryDate : ''} 
                <span>{packageType === "PrePrint" ? " | Pre-Print" : packageType === "Hybrid" ? " | Hybrid" : ""}</span> 
                {detailsButton}
                {reprintButton}
            </h4>
        }
    </div>

export default WorkflowHeaderDetails;
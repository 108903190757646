import React from 'react';
import { Button, Glyphicon, NavItem } from 'react-bootstrap';
import ReactPopout from '../../../../external/ReactPopout';

class ImageViewerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopout: false
        };
    }

    togglePopoutWindow = () => {
        this.setState({ isPopout: !this.state.isPopout });
    }

    render() {
        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width,
            height: window.screen.height,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };

        return !this.state.isPopout ?
            <NavItem>
                <Button onClick={this.togglePopoutWindow}>
                    <Glyphicon glyph="picture" style={{ fontWeight: '800', marginRight: '0.6rem' }} /> Images
                </Button>
            </NavItem>
            : <ReactPopout title="Images" onClosing={this.togglePopoutWindow} options={options}
                url={`/ReviewProcessing/Images?medicalRecordReviewID=${this.props.medicalRecordReviewID}`}>
              </ReactPopout>
    }
}

export default ImageViewerContainer;
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus';

class StarsDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            row: null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visible && nextProps.row !== this.props.row) {
            this.setState({ 
                row: nextProps.row
            });
        }
    }

    // format helpers
    getCommentDescriptionTasks = (collection, value) => {
        const foundItem = collection.find(x => x.commentDefinitionID === value);
        if (foundItem) {
            return "*" + foundItem.commentText;
        }
    }

    getCommentsData = (array, collection) => {
        return array.map(x => this.getCommentDescriptionTasks(collection, parseInt(x, 10))).toString().replace(/,/g, "\n");
    }

    isApproved = (capture) => {
        return capture.lineItemReviewStatusID === LineItemReviewStatus.Confirmed || capture.lineItemReviewStatusID === LineItemReviewStatus.MDConfirmed;
    };

    isRejected = (capture) => {
        return capture.lineItemReviewStatusID === LineItemReviewStatus.Rejected || capture.lineItemReviewStatusID === LineItemReviewStatus.MDRejected;
    };

    isApprovedOrRejected = function (capture) {
        return capture && (this.isApproved(capture) || this.isRejected(capture));
    }

    renderMedicalRecordImageID = () => {
        if (this.state.row.medicalRecordImageID) {
            return this.state.row.medicalRecordImageID === -1 ? 'Not available in MRR'
            : this.state.row.medicalRecordImageID;
        }
        else {
            return '';
        }
    }

    render() {
        return (
            <Modal className="starDetailModal" show={this.props.visible} onHide={this.props.handleModalToggle} keyboard bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title><b>Stars Details</b></Modal.Title>
                </Modal.Header>
                {
                    this.state.row &&
                    <Modal.Body style={{ padding: '5px' }}>
                        <Row style={{ display: 'flex', flexWrap: 'wrap', marginRight: 'auto', marginLeft: 'auto' }}>
                            <Col xs={5}>
                                <div>
                                    <header>
                                        <h4>Provider</h4>
                                    </header>
                                    <div className="diagModalContainer">
                                        <Row>
                                            <span>{`Name: ${this.state.row.providerLastName}, ${this.state.row.providerFirstName}`}</span>
                                        </Row>
                                        <Row>
                                            <span>{`Provider ID: ${this.state.row.providerID}`}</span>
                                        </Row>
                                        <Row>
                                            <span>{`Provider NPI: ${this.state.row.providerNPI}`}</span>
                                        </Row>
                                    </div>
                                    <header>
                                        <h4>Medical Record</h4>
                                    </header>
                                    <div className="diagModalContainer">
                                        <Row>
                                            <span>{`DOS: ${this.state.row.dateOfServiceFrom}`}</span>
                                        </Row>
                                        <Row>
                                            <span>{`Image ID: ${this.renderMedicalRecordImageID()}`}</span>
                                        </Row>
                                        <Row>
                                            <span>{`Pages: ${this.state.row.pageNumFrom} - ${this.state.row.pageNumTo}`}</span>
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={5}>
                                <div>
                                    <header>
                                        <h4>Star Measure</h4>
                                    </header>
                                    <div className="diagModalContainer">
                                        <Row>
                                            <span>{`Measure: ${this.state.row.measure}`}</span>
                                        </Row>
                                        <Row>
                                            <span>{`Service Description: ${this.state.row.serviceDescription}`}</span>
                                        </Row>
                                        {
                                            this.state.row && this.state.row.dynamicValues &&
                                            this.state.row.dynamicValues.map((item, index) => { 
                                                return <Row key={index}>
                                                    <span style={{ marginLeft: '10px' }}>{item[0]}</span>
                                                    <span>{item[1]}</span>
                                                </Row>
                                            })
                                        }
                                        <Row>
                                            <span>{`Entered By: ${this.state.row.enteredBy}`}</span>
                                        </Row>
                                        <Row>
                                            <span>{`Entered On: ${this.state.row.enteredDate}`}</span>
                                        </Row>
                                        {
                                            this.state.row && (this.isApprovedOrRejected(this.state.row)) &&
                                            <Row>
                                                <span>Status:</span>
                                                {
                                                    this.isApproved(this.state.row) && 
                                                    <span style={{ color: '#38ca6e', marginLeft: '1rem' }}><b>Accepted</b></span>
                                                }
                                                {
                                                    this.isRejected(this.state.row) && 
                                                    <span style={{ color: '#f00', marginLeft: '1rem' }}><b>Rejected</b></span>
                                                }
                                            </Row>
                                        }
                                    </div>
                                    <div className="diagModalContainer">
                                        {
                                            this.state.row.rejectionComments && this.state.row.rejectionComments.length > 0 &&
                                            <div>
                                                <Row>
                                                    <span>Rejection Reasons</span>
                                                </Row>
                                                <Row>
                                                    <p className="commentBox">{this.getCommentsData(this.state.row.rejectionComments, this.props.rejectionReasons)}</p>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                }
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default StarsDetailModal;
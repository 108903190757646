import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import { formatISODateOnly }  from '../../utils/DateUtils';
import { ClientSource } from '../../enums/ClientSource'

class MemberSearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastName: '',
            firstName: '',
            minDateOfBirth: '',
            maxDateOfBirth: '',
            beneficiaryID: '',
            planMemberID: '',
            searchResults: [],
            sourceID: null,
            page: 1,
            sizePerPage: 10,
            sortBy: 'memberName',
            sortByDesc: false,
            totalItems: 0,
            loaded: true
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        this.datepickerMin = null;
        this.datepickerMax = null;
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            lastName: nextProps.value ? nextProps.value.lastName : '',
            firstName: nextProps.value ? nextProps.value.firstName : '',
            planMemberID: nextProps.value ? nextProps.value.memberID : '',
            searchResults: [],
            sourceID: nextProps.value ? nextProps.value.sourceID : null,
            page: 1,
            sizePerPage: 10,
            totalItems: 0
        });
    }

    handleMemberSearch = (page = this.state.page, sizePerPage = this.state.sizePerPage, sortBy = this.state.sortBy, sortByDesc = this.state.sortByDesc) => {
        this.setState({ loaded: false });
        const queryParams = {
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            beneficiaryID: this.state.beneficiaryID,
            memberID: this.state.planMemberID,
            dateOfBirthMin: this.state.minDateOfBirth ? formatISODateOnly(new Date(this.state.minDateOfBirth)) : this.state.minDateOfBirth,
            dateOfBirthMax: this.state.maxDateOfBirth ? formatISODateOnly(new Date(this.state.maxDateOfBirth)) : this.state.maxDateOfBirth,
            page: page,
            pageSize: sizePerPage,
            orderByColumnName: sortBy,
            orderByDescending: sortByDesc
        };

        axios.get('/memberService/v1/member', {
            params: queryParams,
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                searchResults: response.data.items,
                totalItems: response.data.totalItems,
                sourceID: response.data.sourceID,
                page: response.data.pageNumber,
                hasSearchBeenPerformed: true,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({
                searchResults: [],
                totalItems: 0,
                page: 1,
                loaded: true
            });
            this.props.toast('error', 'Could not search members.', 'Error');
        });
    }

    handleLastNameChange = (e) => {
        this.setState({ lastName: e.target.value });
    }

    handleFirstNameChange = (e) => {
        this.setState({ firstName: e.target.value });
    }

    handleMinDateOfBirthChange = (e) => {
        this.setState({ minDateOfBirth: e });
    }

    handleMaxDateOfBirthChange = (e) => {
        this.setState({ maxDateOfBirth: e });
    }

    handleBeneficiaryIDChange = (e) => {
        this.setState({ beneficiaryID: e.target.value });
    }

    handlePlanMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value });
    }

    handleSelectSearchResult = (rowIndex) => {
        this.props.handleSelectSearchResult(this.refs.table.state.data[rowIndex]);
        this.props.handleModalToggle();
    }

    validateBeforeSearch = () => {
        if (!this.state.lastName && !this.state.firstName) {
                return (this.state.beneficiaryID || this.state.planMemberID);
        }
        else {
                return this.state.lastName && this.state.firstName && this.state.lastName.length > 1 && this.state.firstName.length > 1;
        }
    }

    formatPCPName = (cell, row) => {
        if (row.pcpLastName && row.pcpFirstName) {
            return `${row.pcpLastName}, ${row.pcpFirstName}`;
        }
        else if (row.pcpLastName) {
            return row.pcpLastName;
        }
    }

    formatMemberID = (cell, row) => {
        if (row.sourceID) {
            return `${row.memberID} - ${ClientSource[row.sourceID]}`;
        } else {
            return row.memberID;
        }
    }

    render() {
        const customSelect = (props) => {
            const rowIndex = props.rowIndex;
            return rowIndex !== 'Header' ? (
                <div>
                    <Button onClick={() => this.handleSelectSearchResult(rowIndex)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
            ) : null;
        };
        const selectRowProp = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '6rem'
        };
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            sortName: this.state.sortBy,
            sortOrder: this.state.sortByDesc ? 'desc' : 'asc',
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                }, this.handleMemberSearch);
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    page: 1,
                    sizePerPage: this.state.sizePerPage,
                    sortBy: sortName,
                    sortByDesc: sortOrder === 'desc'
                }, this.handleMemberSearch(1));
            }
        };

        return (
            <div>
                <Modal show={this.props.visible} onHide={this.props.handleModalToggle} className="modal-wide" keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Member Search</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginBottom: '1.6rem' }}>
                            <Row>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Last Name</ControlLabel>
                                        <FormControl type="text" value={this.state.lastName} placeholder="Last Name" onChange={this.handleLastNameChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>First Name</ControlLabel>
                                        <FormControl type="text" value={this.state.firstName} placeholder="First Name" onChange={this.handleFirstNameChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Min Date of Birth</ControlLabel>
                                        <DatePicker value={this.state.minDateOfBirth} onChange={this.handleMinDateOfBirthChange} style={{ zIndex: 'auto' }}
                                            ref={(ref) => this.datepickerMin = ref}  />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Max Date of Birth</ControlLabel>
                                        <DatePicker value={this.state.maxDateOfBirth} onChange={this.handleMaxDateOfBirthChange} style={{ zIndex: 'auto' }}
                                            ref={(ref) => this.datepickerMax = ref}  />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Beneficiary ID</ControlLabel>
                                        <FormControl type="text" value={this.state.beneficiaryID} placeholder="Beneficiary ID" onChange={this.handleBeneficiaryIDChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Member ID</ControlLabel>
                                        <FormControl type="text" value={this.state.planMemberID} placeholder="Member ID" onChange={this.handlePlanMemberIDChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} style={{ marginTop: '2.4rem' }}>
                                    <Button onClick={() => this.handleMemberSearch(1)} style={{ float: 'left', marginRight: '1rem' }} disabled={!this.validateBeforeSearch()}>
                                        <Glyphicon glyph="search" style={{ marginRight: '0.2rem' }} /> Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <div style={{ minHeight: '12rem' }}>
                            <Loader loaded={this.state.loaded}>
                                <BootstrapTable data={this.state.searchResults} ref="table" selectRow={selectRowProp} options={options} hover pagination
                                    remote fetchInfo={{ dataTotalSize: this.state.totalItems }}>
                                    <TableHeaderColumn dataField="internalKey" dataAlign="left" isKey hidden></TableHeaderColumn>
                                    <TableHeaderColumn dataField="name" dataAlign="left" dataSort>Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField="beneficiaryID" dataAlign="left" dataSort>Beneficiary ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="memberID" dataAlign="left" dataSort dataFormat={this.formatMemberID}>Member ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="dob" dataAlign="left" dataSort>Date of Birth</TableHeaderColumn>
                                    <TableHeaderColumn dataField="gender" dataAlign="left" dataSort>Gender</TableHeaderColumn>
                                    <TableHeaderColumn dataField="planID" dataAlign="left" dataSort>Plan ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="pcpName" dataFormat={this.formatPCPName} dataAlign="left" dataSort>PCP Name</TableHeaderColumn>
                                </BootstrapTable>
                            </Loader>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.handleModalToggle}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default MemberSearchModal;
import React from 'react';
import HCCReport from './HCCReport'
import EnrolleeReport from './EnrolleeReport'
import { FormControl, ControlLabel } from 'react-bootstrap'

class ReportContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedReport: "1"
        }
    }

    handleReportChange = (event) => {
        if (event) {
            this.setState({ selectedReport: [...event.target.options].find(o => o.selected).value });
        }
        else {
            this.setState({ selectedReport: "1" });
        }
    }

    render () {
        return (
            <div>
                <div style={{ float: 'left', width: '25%', marginTop: '1%', marginBottom: '1%' }}>
                    <ControlLabel>Report Type</ControlLabel>
                    <FormControl componentClass="select" value={this.state.selectedReport} onChange={this.handleReportChange}>
                        <option value="1">RADV HCC Validation Report</option>
                        <option value="2">RADV Enrollee Report</option>
                    </FormControl>
                </div>
                {
                    <div style={{ display: this.state.selectedReport === "1" ? 'inherit' : 'none' }}>
                        <HCCReport radvAuditID={this.props.radvAuditID} toast={this.props.toast} />
                    </div>
                }
                {
                    <div style={{ display: this.state.selectedReport === "2" ? 'inherit' : 'none' }}>
                        <EnrolleeReport projectID={this.props.projectID} toast={this.props.toast} />
                    </div>
                }
            </div>
        );
    }
}

export default ReportContainer;
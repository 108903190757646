import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Tab, Tabs } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import { CSVLink } from 'react-csv';
import AlertToastr from '../Shared/AlertToastr';
import '../../styles/Report.css';

const HostRequestStatusHomePlan = () => {
    const reportType = ['Summary', 'Details'];
    const [statusHomePlanData, setStatusHomePlanData] = useState({ data: [], loaded: false });
    const [toast, setToast] = useState({ toastfn: null });
    const [exportData, setExportData] = useState({ summaryData: '', detailsData: '' });

    const setAddToast = (addToast) => {
        setToast({ ...toast, toastfn: addToast })
    };

    useEffect(() => {
        const lineOfBusinessValue = localStorage.getItem('lineOfBusiness');
        if (lineOfBusinessValue === "Commercial") {
            window.location.href = '/MRR';
        } else {
            axios.get('/OOAReports/GetHostRequestStatusbyHomePlan')
                .then(response => {
                    if (response.data.length) {
                        const result = response.data.map((item, index) => {
                            item.homePlanSummaryID = index;
                            item.homePlanDetailsID = index;
                            return item;
                        });
                        setStatusHomePlanData(statusHomePlanData => ({
                            ...statusHomePlanData,
                            data: result,
                            loaded: true
                        }));
                    }
                })
                .catch(err => {
                    setStatusHomePlanData(statusHomePlanData => ({
                        ...statusHomePlanData,
                        loaded: true
                    }));
                    setToast({ ...toast, toastfn: ('error', 'Could not load data.', 'Error') });
                })
        }
    });

    useEffect(() => {
        axios.get(`/OOAReports/HostRequestStatusbyHomePlan/exportCSV?ReportType=${reportType[0]}`)
            .then(response => {
                setExportData(exportData => ({
                    ...exportData,
                    summaryData: response.data
                }));
            })
            .catch(err => {
                setExportData(exportData => ({
                    ...exportData,
                    summaryData: ''
                }));
                setToast({ ...toast, toastfn: ('error', 'Could not load data.', 'Error') });
            })
        axios.get(`/OOAReports/HostRequestStatusbyHomePlan/exportCSV?ReportType=${reportType[1]}`)
            .then(response => {
                setExportData(exportData => ({
                    ...exportData,
                    detailsData: response.data
                }));
            })
            .catch(err => {
                setExportData(exportData => ({
                    ...exportData,
                    detailsData: ''
                }));
                setToast({ ...toast, toastfn: ('error', 'Could not load data.', 'Error') });
            })
    });

    const dateFormat = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = String((today.getMonth() + 1));
        if (month.length === 1) {
            month = `0${month}`;
        }
        let date = String(today.getDate());
        if (date.length === 1) {
            date = `0${date}`;
        }
        const currentDate = `${year}${month}${date}`;
        return currentDate;
    };

    const exportDownload = (exportDataDownload, name) => {
        return <div className="export-table-data">
            <CSVLink data={exportDataDownload} filename={`HostRequestStatusbyHomePlan${name}_${dateFormat()}.CSV`}
                className="btn btn-primary">
                <Glyphicon glyph={"file"} style={{ marginRight: '0.3rem' }} />Download .CSV
            </CSVLink>
        </div>
    };

    const summaryReportTab = (statusHomePlanData, exportData) => (
        <>
            {
                <Loader loaded={statusHomePlanData.loaded}>
                    <section className="table-section">
                        <BootstrapTable data={statusHomePlanData.data} maxHeight='400px'>
                            <TableHeaderColumn dataField="homePlanSummaryID" isKey={true} hidden>Home Plan Summary ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="homePlan" dataSort width='100px'>Home Plan</TableHeaderColumn>
                            <TableHeaderColumn dataField="requestType" dataSort width='100px'>Request Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="totalRequestsReceived" dataSort width='120px'>Total Requests Received</TableHeaderColumn>
                            <TableHeaderColumn dataField="open" dataSort width='120px'>Open</TableHeaderColumn>
                            <TableHeaderColumn dataField="totalClosed" dataSort width='120px'>Closed</TableHeaderColumn>
                            <TableHeaderColumn dataField="withdrawn" dataSort width='120px'>Withdrawn</TableHeaderColumn>
                        </BootstrapTable>
                    </section>
                </Loader>
            }
            {exportDownload(exportData.summaryData, 'Summary')}
        </>
    );

    const detailsReportTab = (statusHomePlanData, exportData) => (
        <>
            {
                <Loader loaded={statusHomePlanData.loaded}>
                    <section className="table-section">
                        <BootstrapTable data={statusHomePlanData.data} maxHeight='400px'>
                            <TableHeaderColumn dataField="homePlanDetailsID" isKey={true} hidden>Home Plan Details ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="homePlan" dataSort width='100px'>Home Plan</TableHeaderColumn>
                            <TableHeaderColumn dataField="requestType" dataSort width='100px'>Request Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="totalRequestsReceived" dataSort width='120px'>Total Requests Received</TableHeaderColumn>
                            <TableHeaderColumn dataField="open" dataSort width='120px'>Open</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedSuccessfullyWithSLA" dataSort width='120px'>Closed Successfully With SLA</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedSuccessfullyAfterSLA" dataSort width='120px'>Closed Successfully After SLA</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedBySystem" dataSort width='120px'>Closed By System</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedNoProviderResponse" dataSort width='120px'>Closed No Provider Response</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedProviderNotInArea" dataSort width='120px'>Closed Provider Not In Area</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedMemberNotInArea" dataSort width='120px'>Closed Member Not In Area</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedProviderDisagrees" dataSort width='120px'>Closed Provider Disagrees</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedGapClosedLocally" dataSort width='120px'>Closed Gap Closed Locally</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedProviderDoesNotHaveRecords" dataSort width='120px'>Closed Provider Does Not Have Records</TableHeaderColumn>
                            <TableHeaderColumn dataField="closedNoOutreach" dataSort width='120px'>Closed No Outreach</TableHeaderColumn>
                            <TableHeaderColumn dataField="withdrawn" dataSort width='120px'>Withdrawn</TableHeaderColumn>
                        </BootstrapTable>
                    </section>
                </Loader>
            }
            {exportDownload(exportData.detailsData, 'Details')}
        </>
    );

    const handleBackButton = () => {
        window.location.href = '/OOA'
    };

    return (
        <div className="hostPlan reportContainer">
            <div>
                <Button variant="secondary" onClick={handleBackButton} style={{ marginBottom: '10px', marginLeft: '20px' }}>
                    <Glyphicon glyph="arrow-left" style={{ marginRight: '0.2rem' }} /> Back
                </Button>
            </div>
            <div className="innerReportContainer" style={{ minHeight: '250px', marginLeft: '1%', marginRight: '1%' }}>
                <header>
                    <Col className="title" >
                        <h3> Host Request Status by Home Plan Report </h3>
                    </Col>
                </header>
                <Tabs id="homePlanTabs" defaultActiveKey={1} style={{ marginTop: '2rem' }}>
                    <Tab eventKey={1} title="Summary">
                        {
                            summaryReportTab(statusHomePlanData, exportData)
                        }
                    </Tab>
                    <Tab eventKey={2} title="Details">
                        {
                            detailsReportTab(statusHomePlanData, exportData)
                        }
                    </Tab>
                </Tabs>
            </div>
            <AlertToastr setAddToast={setAddToast} />
        </div>
    )
}

export default HostRequestStatusHomePlan;

import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import HCCProfile from './HCCProfile';

export default class HCCProfileNewWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewProcessingUIConfig: null,
            radvAudit: null,
            loaded: false
        };
    }

    componentWillMount() {
        axios.get(`/ReviewProcessingUIConfig/${this.props.params.reviewType}`, {
            params: {
                medicalRecordReviewID: this.props.location.query.medicalRecordReviewID,
                isCDIQueue: this.props.location.query.isCDIQueue || false
            }
        })
        .then((response) => {
            this.setState({
                reviewProcessingUIConfig: response.data,
            }, () => {
                this.getRADVAudit();
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
        });
    }

    getRADVAudit = () => {
        axios.get('/radvaudit', {
            params: {
                projectID: this.state.reviewProcessingUIConfig.projectID
            }
        })
        .then((response) => {
            this.setState({
                radvAudit: response.data,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({
                loaded: true
            });
        })
    }

    render() {
        return (
            <Loader loaded={this.state.loaded}>
                <HCCProfile reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} radvAudit={this.state.radvAudit}
                    visible={true} isPopout={true}/>
            </Loader>
        );
    }
}
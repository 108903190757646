import React from 'react';
import axios from 'axios';
import { Button, ControlLabel, FormControl, FormGroup, Glyphicon, ListGroup, ListGroupItem, Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus';
import { sortComments, sortDate } from '../../../../../utils/SortUtils';
import OpportunityFormModal from './OpportunityFormModal';
import RejectModal from '../RejectModal';

class OpportunityCapture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFormModal: false,
            showRejectModal: false,
            diagFilter: '',
            oppObj: null,
            editMode: false,
            editQABool: false,
            editModalRow: null,
            rejectedReasons: null
        }
        this.qaInitialEntryID = 1;
        this.qaCompleteID = 2;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            opportunityData: nextProps.opportunityData
        });
    }

    formatStatus = (cell, row) => {
        const statusID = row.opportunityReviewStatusID;
        if (statusID === LineItemReviewStatus.Rejected) {
            return "Complete - Rejected";
        }
        else if (statusID === LineItemReviewStatus.Confirmed) {
            return "Complete - Approved";
        }
        else if (statusID !== LineItemReviewStatus.Confirmed && statusID !== LineItemReviewStatus.Rejected && row.createdUserID !== this.props.userID) {
            return "Ready for QA";
        }
        else if (row.createdUserID === this.props.userID) {
            return "Initial";
        }
    }

    getCommentDescriptionTasks = (collection, value) => {
        const foundItem = collection.find(x => x.commentDefinitionID === value);
        if (foundItem) {
            return "*" + foundItem.commentText;
        }
    }

    getCommentsData = (array, collection) => {
        return array.map(x => this.getCommentDescriptionTasks(collection, parseInt(x, 10))).toString();
    }

    formatRejectionComments = (cell, row) => {
        const comments = this.getCommentsData(row.rejectionComments, this.props.rejectionReasons);
        return (
            <span className="opp-capture-tooltip">{row.rejectionComments.length}
                {
                    comments &&
                    <span className="opp-capture-tooltip-text">{comments}</span>
                } 
            </span>
        );
    }

    formatUser = (cell, row) => {
        return row.user.fullName;
    }

    formatActions = (cell, row) => {
        if (!this.props.isComplete) {
            if (!this.props.isQA || (this.props.isQA && row.createdUserID === this.props.userID)){
                return <div className="diag-qa-button-wrapper">
                    <Button onClick={() => this.toggleFormModal(row)}>
                        <Glyphicon bsSize="large" glyph="pencil" />
                    </Button>
                    <Button className="deleteButton" onClick={() => this.deleteRecord(row)}>
                        <Glyphicon bsSize="large" glyph="trash" />
                    </Button>
                </div>
            }
            else if (this.props.isQA && row.createdUserID !== this.props.userID 
                && (row.opportunityReviewStatusID !== LineItemReviewStatus.Rejected 
                && row.opportunityReviewStatusID !== LineItemReviewStatus.Confirmed)) {
                return <div className="diag-qa-button-wrapper">
                    <Button onClick={() => this.toggleFormModal(row, true)}>
                        <Glyphicon bsSize="large" glyph="pencil" />
                    </Button>
                    <Button className="approveButton" onClick={() => this.approveQA(row)}>
                        <Glyphicon bsSize="large" glyph="ok" />
                    </Button>
                    <Button className="deleteButton" onClick={() => this.toggleRejectModal(row)}>
                        <Glyphicon bsSize="large" glyph="remove" />
                    </Button>
                </div>
            }
            else if (this.props.isQA && (row.opportunityReviewStatusID === LineItemReviewStatus.Rejected 
                || row.opportunityReviewStatusID === LineItemReviewStatus.Confirmed)) {
                return <div className="diag-qa-button-wrapper">
                    <Button onClick={() => this.reopenRecord(row)}>
                        <Glyphicon bsSize="large" bsClass="glyphicon mirror-icon glyphicon-repeat" />
                    </Button>
                </div>
            }
        }
    }

    // opp row functionality helpers
    qaFilterWorkload = (e) => {
        let diagFilter = '';
        if (e.target && e.target.value) {
            diagFilter = e.target.value;
            if (diagFilter === "QA Complete") {
                this.setState({
                    diagFilter: diagFilter,
                    opportunityData: this.props.opportunityData.filter(x => {
                        return x.qaGroupingID === this.qaCompleteID;
                    })
                });
            }
            else if (diagFilter === "Ready for QA") {
                this.setState({
                    diagFilter: diagFilter,
                    opportunityData: this.props.opportunityData.filter(x => {
                        return x.qaGroupingID === this.qaInitialEntryID && x.createdUserID !== this.props.userID;
                    })
                });
            }
            else if (diagFilter === "Initial Entry") {
                this.setState({
                    diagFilter: diagFilter,
                    opportunityData: this.props.opportunityData.filter(x => {
                        return x.createdUserID === this.props.userID;
                    })
                });
            }
        }
        else {
            this.setState({
                diagFilter: diagFilter,
                opportunityData: this.props.opportunityData
            });
        }
    }

    toggleFormModal = (row, isEditQA) => {
        this.setState({ 
            showFormModal: !this.state.showFormModal,
            editMode: row && row.medicalRecordOpportunityID,
            editModalRow: row && row.medicalRecordOpportunityID ? row : null,
            editQABool: isEditQA
        });
    }

    deleteRecord = (row) => {
        this.setState({
            oppObj: {
                ...row,
                medicalRecordReviewID: this.props.medicalRecordReviewID,
                userID: this.props.userID,
                dateOfService: new Date(row.dateOfServiceFrom),
                expectedVersion: row.updatedDateTime
            }
        }, () => {
            axios.delete(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordOpportunity/${this.state.oppObj.medicalRecordOpportunityID}`, {
                data: {...this.state.oppObj}
            })
            .then((response) => {
                this.props.getOpportunityData();
                this.props.getOpportunityHistoryData();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not delete opportunity record.', 'Error');
            })
        });
    }

    approveQA = (row) => {
        this.setStatus(row, LineItemReviewStatus.Confirmed);
    }

    reopenRecord = (row) => {
        this.setStatus(row, LineItemReviewStatus.Captured);
    }

    // status set helper for approve/reopen
    setStatus = (row, reviewStatusID) => {
        this.setState({
            oppObj: {
                ...row,
                medicalRecordReviewID: this.props.medicalRecordReviewID,
                opportunityReviewStatusID: reviewStatusID,
                qaGroupingID: reviewStatusID === LineItemReviewStatus.Confirmed ? this.qaCompleteID : this.qaInitialEntryID,
                userID: this.props.userID,
                dateOfService: new Date(row.dateOfServiceFrom),
                expectedVersion: row.updatedDateTime
            }
        }, () => {
            axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordOpportunity/${row.medicalRecordOpportunityID}`, this.state.oppObj)
            .then((response) => {
                this.props.getOpportunityData();
                this.props.getOpportunityHistoryData();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not update opportunity record status.', 'Error');
            })
        });
    }

    toggleRejectModal = (row) => {
        this.setState({ 
            rejectRow: row,
            showRejectModal: !this.state.showRejectModal,
            rejectedReasons: this.props.rejectionReasons.map(x => {
                x.isChecked = row && row.rejectionComments.includes(x.commentDefinitionID);
                return x;
            })
        });
    }

    // update rejectedReasons on checkbox select
    setSelectedRejectionReasons = (updatedRejectionReasons) => {
        this.setState({ rejectedReasons: updatedRejectionReasons.sort(sortComments)});
    }

    // reject entry with status 99 and add rejection comments
    rejectEntry = (row) => {
        this.setState({
            oppObj: {
                ...row,
                medicalRecordReviewID: this.props.medicalRecordReviewID,
                opportunityReviewStatusID: LineItemReviewStatus.Rejected,
                userID: this.props.userID,
                dateOfService: new Date(row.dateOfServiceFrom),
                expectedVersion: row.updatedDateTime,
                rejectionComments: this.state.rejectedReasons.filter(x => {
                    return x.isChecked
                }).map(y => { return y.commentDefinitionID.toString() })
            }
        }, () => {
            axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordOpportunity/${row.medicalRecordOpportunityID}`, this.state.oppObj)
            .then((response) => {
                this.props.getOpportunityData();
                this.props.getOpportunityHistoryData();
                this.toggleRejectModal();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not reject star record.', 'Error');
            })
        });
    }

    renderTable = (results) => {
        const options = {
            defaultSortName: 'medicalRecordOpportunityID',
            defaultSortOrder: 'asc'
        };
        return (
            <div>
                <BootstrapTable data={results} options={options} hover striped>
                    <TableHeaderColumn dataField="statusDescription" dataFormat={this.formatStatus} hidden={!this.props.isQA} dataSort tdStyle={{ whiteSpace: 'normal' }} width="7%">Status</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordOpportunityID" isKey dataSort>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceFrom" dataSort sortFunc={(a, b, order) => sortDate(a.dateOfServiceFrom, b.dateOfServiceFrom, order)}>DOS</TableHeaderColumn>
                    <TableHeaderColumn dataField="opportunityText" dataSort tdStyle={{ whiteSpace: 'normal' }} width="40%">Text To Show Alert</TableHeaderColumn>
                    <TableHeaderColumn dataField="diagnosisCD" dataSort>DX Code</TableHeaderColumn>
                    <TableHeaderColumn dataField="rejectionComments" dataFormat={this.formatRejectionComments} hidden={!this.props.isQA} dataSort>Rejection Comments</TableHeaderColumn>
                    <TableHeaderColumn dataField="user" hidden={!this.props.isQA} dataFormat={this.formatUser} dataSort tdStyle={{ whiteSpace: 'normal' }}>Entered By</TableHeaderColumn>
                    <TableHeaderColumn dataField="createdDateTime" hidden={!this.props.isQA} dataSort>Entered Date</TableHeaderColumn>
                    <TableHeaderColumn width="10%" dataFormat={this.formatActions}>Update Entry</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Panel className={!this.props.isComplete ? 'capturePanel capture-panel-no-top-padding' : 'capturePanel'} header="Opportunities Capture">
                    {
                        !this.props.isComplete &&
                        <ListGroup style={{ margin: '0' }}>
                            <ListGroupItem style={{ borderBottom: '0', borderTop: '0', borderRadius: '0' }}>
                            {
                                (this.props.isQA || this.props.isMD) &&
                                <FormGroup style={{ width: '200px', marginRight: '20px', display: 'inline-block' }}>
                                    <ControlLabel style={{ marginRight: '1rem', fontSize: '14px', color: 'white' }}>Showing Items: </ControlLabel>
                                    <FormControl componentClass="select" placeholder="All" value={this.state.diagFilter} onChange={this.qaFilterWorkload}>
                                        <option value="Ready for QA">Ready for QA</option>
                                        <option value="QA Complete">QA Complete</option>
                                        <option value="Initial Entry">Initial Entry</option>
                                        <option value="">All</option>
                                    </FormControl>
                                </FormGroup>
                            }
                            {
                                <Button bsStyle="primary" onClick={this.toggleFormModal} style={{ display: 'inline-block' }}>Add Opportunity</Button>
                            }
                            </ListGroupItem>
                        </ListGroup>
                    }
                    { 
                        this.state.opportunityData &&
                        this.renderTable(this.state.opportunityData) 
                    }
                </Panel>

                <OpportunityFormModal visible={this.state.showFormModal} handleModalToggle={this.toggleFormModal} 
                    opportunityReviewComments={this.props.opportunityReviewComments} commentGroupOptions={this.props.commentGroupOptions}
                    isQA={this.props.isQA} isComplete={this.props.isComplete} isMD={this.props.isMD}
                    userID={this.props.userID} memberID={this.props.memberID} medicalRecordReviewID={this.props.medicalRecordReviewID}
                    getOpportunityData={this.props.getOpportunityData} getOpportunityHistoryData={this.props.getOpportunityHistoryData} editMode={this.state.editMode}
                    editModalRow={this.state.editModalRow} editQABool={this.state.editQABool} 
                    pecCorrectionCommentSelected={this.props.pecCorrectionCommentSelected} toast={this.props.toast}/>

                <RejectModal visible={this.state.showRejectModal} rejectionReasons={this.state.rejectedReasons} 
                    handleModalToggle={this.toggleRejectModal} row={this.state.rejectRow} title="Rejection Comments" 
                    userID={this.props.userID} setSelectedRejectionReasons={this.setSelectedRejectionReasons} 
                    rejectEntry={this.rejectEntry}/>
            </div>
        );
    }
}

export default OpportunityCapture;
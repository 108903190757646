import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';

export default class CommentDefaultGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentDefaultGroups: [],
            commentDefaultGroupID: "",
            comments: [],
            addingGroup: false,
            groupTitle: "",
        }
    }

    componentWillMount() {
        this.getCommentDefaultGroups();
    }

    getCommentDefaultGroups = () => {
        axios.get('/commentDefaultGroups')
        .then((response) => {
            this.setState({ commentDefaultGroups: response.data });
        });
    }

    toggleModal = () => {
        this.setState({ addingGroup: !this.state.addingGroup });
    }

    handleTitleChange = (e) => {
        this.setState({ groupTitle: e ? e.target.value : e });
    }

    addDefaultGroup = () => {
        axios.post('/commentDefaultGroups', { commentDefaultGroupTitle: this.state.groupTitle })
        .then((response) => {
            this.toggleModal();
            this.getCommentDefaultGroups();
            this.setState({ groupTitle: "" })
        });
    }

    handleGroupChange = (e) => {
        this.setState({
            commentDefaultGroupID: e ? e.value : ''
        }, () => {
            if (e) {
                this.getComments();
            }
            else {
                this.setState({ comments: [] });
            }
        });
    }

    getComments = () => {
        axios.get(`/commentDefaultGroups/${this.state.commentDefaultGroupID}/comments`)
        .then((response) => {
            this.setState({ comments: response.data });
        })
    }

    renderAddRemoveButton = (cell, row) => {
        if (row.isActive) {
            return <Button onClick={this.removeComment(row.commentDefinitionID)}>Remove</Button>
        }
        else {
            return <Button onClick={this.addComment(row.commentDefinitionID)}>Add</Button>
        }
    }

    addComment = (commentDefinitionID) => () => {
        axios.put(`/commentDefaultGroups/${this.state.commentDefaultGroupID}/comments/${commentDefinitionID}`)
        .then(this.getComments)
    }

    removeComment = (commentDefinitionID) => () => {
        axios.delete(`/commentDefaultGroups/${this.state.commentDefaultGroupID}/comments/${commentDefinitionID}`)
        .then(this.getComments)
    }

    render() {
        return (
            <React.Fragment>
                <Row style={{ marginTop: '1.4rem' }}>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel>Comment Default Groups</ControlLabel>
                            <Select options={this.state.commentDefaultGroups.map((item) => {
                                    return {
                                        label: item.commentDefaultGroupTitle,
                                        value: item.commentDefaultGroupID
                                    }
                                })} value={this.state.commentDefaultGroupID} onChange={this.handleGroupChange} />
                        </FormGroup>
                        <Button onClick={this.toggleModal}>Create Comment Default Group</Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={12}>
                        <BootstrapTable data={this.state.comments}>
                            <TableHeaderColumn dataField="commentDefinitionID" dataAlign="left" dataSort isKey hidden />
                            <TableHeaderColumn dataField="commentText" dataAlign="left" dataSort>Comment Text</TableHeaderColumn>
                            <TableHeaderColumn dataField="isActive" dataAlign="left" dataFormat={this.renderAddRemoveButton} dataSort width="10%"></TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
                <Modal show={this.state.addingGroup} onHide={this.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Comment Default Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <ControlLabel>Title</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.groupTitle}
                                onChange={this.handleTitleChange}/>
                            <Button onClick={this.addDefaultGroup}>Add Group</Button>
                        </FormGroup>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}
// exports OutboundType - an immutable object 'enum'
// usage: import { OutboundType } from '../../enums/OutboundType';
const outboundType = {
    InOffice: 1, 
    RemotePDF: 2, 
    RemoteMailMerge: 3, 
    OnHand: 4, 
    RemoteOOS: 5, 
    EPEC: 6
};
Object.freeze(outboundType);
export const OutboundType = outboundType;
import React from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import AlertToastr from '../../Shared/AlertToastr';
import '../../../styles/Print.css';

class ScanRosterPrintable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rosterData: this.loadRoster(),
            loaded: false
        };
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    //load roster via query params
    loadRoster = (page) => {
        axios.get('/scan/ScanList/', {
            params: {
                providerOrgID: this.props.location.query.providerID,
                practiceID: this.props.location.query.practiceID,
                lastLoadDateBefore: this.props.location.query.lastLoadDateBefore,
                pageSize: this.props.location.query.pageSize,
                pageNumber: this.props.location.query.pageNumber,
                sortBy: this.props.location.query.sortBy,
                sortByDesc: this.props.location.query.sortByDesc
            }
        })
        .then((response) => {
            this.setState({
                rosterData: response.data.items,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.props.toast('error', 'Could not load roster data.', 'Error');
        });
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatProviderDesc = (desc) => {
        if (desc) {
            const descParts = desc.split('-');
            if (descParts.length === 2) {
                return `${descParts[0].trim()} (${descParts[1].trim()})  - `;
            }
        }
    }

    formatCheckbox = (cell) => {
        return <input type="checkbox" style={{margin: 0}} />;
    }

    render() {
        const options = {
            printToolbar: false
        };
        return (
            this.state.loaded &&
            <div className="print-container">
                <Loader loaded={this.state.loaded}>
                    <h4><b>Scan Roster</b> - {this.formatProviderDesc(this.props.location.query.providerDesc)}{this.props.location.query.practiceDesc}</h4>
                    <hr />
                    <BootstrapTable data={this.state.rosterData} ref="table" options={options} hover printable>
                        <TableHeaderColumn dataField="checkbox" dataFormat={this.formatCheckbox} dataAlign="left" width="3%"></TableHeaderColumn>
                        <TableHeaderColumn dataField="memberID" dataAlign="left" isKey width="8%">Member ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberName" dataAlign="left" width="13%">Member Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="dateOfBirth" dataAlign="left" dataFormat={this.formatDateString} width="6%">Date of Birth</TableHeaderColumn>
                        <TableHeaderColumn dataField="lastLoadDate" dataAlign="left" dataFormat={this.formatDateString} width="6%">Last Load Date</TableHeaderColumn>
                        <TableHeaderColumn dataField="endOfLastScanDateRange" dataAlign="left" width="8%">End of Last Scan Date Range</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceID" dataAlign="left" width="8%">Practice ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceName" dataAlign="left" width="11%">Appt. Practice Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerID" dataAlign="left" width="8%">Provider ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerName" dataAlign="left" width="11%">Provider Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerOrganization" dataAlign="left" width="10%">Provider Org</TableHeaderColumn>
                        <TableHeaderColumn dataField="notes" dataAlign="left" width="8%">Notes</TableHeaderColumn>
                    </BootstrapTable>
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default ScanRosterPrintable;
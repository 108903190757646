// exports AppointmentType - an immutable object 'enum'
// usage: import { AppointmentType } from '../../enums/AppointmentType';
const appointmentType = {
    Regular_CDIGeneratedPriorToAppointment: 1,
    Regular_BlankCDI: 2,
    WalkIn: 3,
    PrePrintOffice: 4,
    PrePrintOffice_BlankCDI: 5,
    Cancelled_Missed_NoShow: 6,
    LabsOnly_NoPCPVisit: 7
};
Object.freeze(appointmentType);
export const AppointmentType = appointmentType;
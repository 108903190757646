import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import '../../../styles/EMRSection.css';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';
import {formatDatetimeStringWithPrefixZero} from '../../../utils/DateUtils';

class PastSurgicalProcedures extends React.Component {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            ['PROCEDURE'],
            ['CODE', 'END DATE'],
            ['SYSTEM', 'TYPE'],
            ['MODIFIERS'],
            ['PROVIDER', 'NPI'],
            ['NOTE']
        ];
        this.data = [];
    }

    formatMultidataCell = (value) => {
        if (!value || value.length === 0) {
            return '-';
        } else {
            return value;
        }
    }

    renderColumnHeaders = () => {
        return this.columnHeaders.map((x, index) => {
            return (x.length === 2 ?
                <th className='rawTableColumnHeader' key={index}>
                    <div>{x[0]}</div>
                    <div>{x[1]}</div>
                </th>
                : <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
            )
        })
    }

    renderDataRows = (records) => {
       return records.map((x, index) => {
            var pastsurgicalprocedure = [];
            if(!isEmpty(x.primary_procedure_code) && String(x.primary_procedure_type).toLowerCase() === 'historical')
                this.generatePastSurgicalProcedure(pastsurgicalprocedure, x.primary_procedure_code, x.primary_procedure_name, x.primary_procedure_coding_system, x.primary_procedure_type);  

            if(!isEmpty(x.additional_procedure) && String(x.primary_procedure_type).toLowerCase() === 'historical')
                x.additional_procedure.forEach(p => {
                    this.generatePastSurgicalProcedure(pastsurgicalprocedure, p.code, p.name, p.system, p.type);            
                });
            return this.renderPastSurgicalProcedure(x, index, pastsurgicalprocedure)
        })
    }

    generatePastSurgicalProcedure = (procs, code, name, system, type) => {
        procs.push({'pastSurgicalProcedureCode':code, 'pastSurgicalProcedureName':name, 'pastSurgicalProcedureSystem':system, 'type':type});
        return procs;
    }

    renderPastSurgicalProcedure = (x, index,  pastsurgicalprocedure) => {
       return pastsurgicalprocedure.map((p, i) => {
            
            const key = `${index}_${i}`;
            return (
                <tr key={key}>
                    <td className='width15'>
				<div>{p.pastSurgicalProcedureName}</div>
				</td>
				<td className='width25'>
					<div>{this.formatMultidataCell(p.pastSurgicalProcedureCode)}</div>
					<div>{this.formatMultidataCell(formatDatetimeStringWithPrefixZero(x.end_datetime))}</div>
				</td>
				<td className='width15'>
					<div>{this.formatMultidataCell(p.pastSurgicalProcedureSystem)}</div>
					<div>{this.formatMultidataCell(p.type)}</div>
				</td>
				<td className='width15'>
					<div>{x.primary_procedure_modifiers.modifier_Code_1 !== null ? x.primary_procedure_modifiers.modifier_Code_1 + ',' : ''}</div>
					<div>{x.primary_procedure_modifiers.modifier_Code_2 !== null ? x.primary_procedure_modifiers.modifier_Code_2 + ',' : ''}</div>
					<div>{x.primary_procedure_modifiers.modifier_Code_3 !== null ? x.primary_procedure_modifiers.modifier_Code_3 + ',' : ''}</div>
					<div>{x.primary_procedure_modifiers.modifier_Code_4 !== null ? x.primary_procedure_modifiers.modifier_Code_4 + ',' : ''}</div>
					<div>{x.primary_procedure_modifiers.modifier_Code_5 !== null ? x.primary_procedure_modifiers.modifier_Code_5 : ''}</div>
				</td>
				<td className='width15'>
					<div>{this.formatMultidataCell(x.Provider_name)}</div>
					<div>{this.formatMultidataCell(x.Provider_id !== '0' ? x.Provider_id : '')}</div>
				</td>
				<td className='width20'>{x.procedure_note}</td>
                </tr>
            )
        })
    }

    renderDatedTables = () => {
        return this.data.map((x, index) => {
            return (
                <div id='datedTableWrapper' key={index}>
                    <div className='datedTableHeader'>
                        {formatDatetimeStringWithPrefixZero(x.start_dateTime)}
                    </div>
                    <Table responsive className='datedTable'>
                        <thead>
                            <tr>
                                {this.renderColumnHeaders()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderDataRows(x.procedureInfos)}
                        </tbody>
                    </Table>
                </div>
            )
        })
    }

    render() {
        this.data = this.props.data;
        return (
            <Panel id='pastsurgicalprocedures'>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Past Surgical Procedures
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                        this.renderDatedTables() : <NoData />
                    }
                </Panel.Body>
            </Panel>
        )
    }
}
export default PastSurgicalProcedures;
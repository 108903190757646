import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { browserHistory, Router } from 'react-router';
import { HttpCode } from './enums/HttpCode';
import { setCurrentUser } from './redux/actions/index';
import Routes from "./Routes";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";




function mapDispatchToProps(dispatch) {
    return ({
        setCurrentUser: currentUser => dispatch(setCurrentUser(currentUser)),
    });
}

var reactPlugin = new ReactPlugin();

const env = location.host.toLowerCase();
var key = "";
if (env.indexOf("localhost") > -1 || env.indexOf("qa") > -1)
    key = '7058fe84-a0ee-41af-a74d-5faae58ff504';
else if (env.indexOf("int") > -1)
    key = '3a256e61-e743-4f7a-966d-5786f7b77646';
else if (env.indexOf("stg") > -1)
    key = '6ce2b288-dbc1-48ce-b4f2-b2e4988b411c';
else if (env.indexOf("perf") > -1)
    key = 'f122e474-26e2-4c89-94ce-b4f5d2354064';
else if (env.indexOf("csb") > -1)
    key = 'd40f48c6-1326-4247-bb5e-a65da18b858a';
else
    key = '18c0408f-d92e-4143-843f-ef221ee14caf'; 


var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: key,     //use localstorage.environment? 
        enableAutoRouteTracking: true,
        disableAjaxTracking: false, 
        disableCorrelationHeaders: false, 
        disableFetchTracking: false, 
        enableRequestHeaderTracking: true, 
        enableResponseHeaderTracking: true, 
        enableCorsCorrelation: true, 
        extensions: [reactPlugin],
    }
});
appInsights.loadAppInsights();

axios.interceptors.request.use((config) => {
    //inject token
    if (localStorage.token) {
        config.headers.common['token'] = localStorage.token;
    }
    //build fully-qualified request url. ignore paths beginning with http.
    if (config.url.indexOf("http") === -1) {
        if (process.env.NODE_ENV === 'development') {
            //memberService API calls
            if (config.url.search("/memberService/v1") === 0) {
                config.url = process.env.REACT_APP_MEMBER_API_URL_DEV + '/' + localStorage.getItem('lineOfBusiness') + config.url.replace('/memberService/v1', '');
            }
            //NLP API calls
            else if(config.url.search("/mrrnlpapi/v1") === 0) {
                var baseUrl = '';
                if(localStorage.Environment === 'prod')
                    baseUrl =  process.env.REACT_APP_MRR_NLP_API_URL_PROD
                else if (localStorage.Environment === 'int')
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_INT;
                else if (localStorage.Environment === 'stg' || localStorage.Environment === 'stage')
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_INT;                    
                else if (localStorage.Environment === 'qa')
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_QA;
                else    
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_DEV;

                var client = localStorage.Client === null || localStorage.Client === undefined ? 'qa' : localStorage.Client;
                config.url = baseUrl + '/' + client + '/' + localStorage.getItem('lineOfBusiness').toLowerCase() + config.url.replace('/mrrnlpapi/v1', '');
                console.log("mrr-nlp-api url"); console.log(config.url);
            }
            //MRR Service API calls
            else {
                config.url = process.env.REACT_APP_MRR_API_URL_DEV + '/' + localStorage.getItem('lineOfBusiness') + config.url.replace('/mrrService/v1', '');
            }
        }
        //Non Dev Environments
        else 
        {
            if (config.url.search("/memberService/v1") === 0) {
                console.log("memberService URI");
                config.url = process.env.REACT_APP_MEMBER_API_URL_PROD + '/' + localStorage.getItem('lineOfBusiness') + config.url.replace('/memberService/v1', '');
            }
            else if(config.url.search("/mrrnlpapi/v1") === 0)
            {
                var baseUrl = '';
                if(localStorage.Environment === 'prod')
                    baseUrl =  process.env.REACT_APP_MRR_NLP_API_URL_PROD
                else if (localStorage.Environment === 'int')
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_INT;
                else if (localStorage.Environment === 'stg' || localStorage.Environment === 'stage')
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_INT;                    
                else if(localStorage.Environment === 'qa')
                    baseUrl = process.env.REACT_APP_MRR_NLP_API_URL_QA;

                var client = localStorage.Client;
                config.url = baseUrl + '/' + client + '/' + localStorage.getItem('lineOfBusiness').toLowerCase() + config.url.replace('/mrrnlpapi/v1', '');
                console.log("mrr-nlp-api url"); console.log(config.url);
            }
            else {
                console.log("mrrService URI");
                config.url = process.env.REACT_APP_MRR_API_URL_PROD + '/' + localStorage.getItem('lineOfBusiness') + config.url.replace('/mrrService/v1', '');
            }
        }
    }

    return config;
});

axios.interceptors.response.use(
    (response) => {
        // todo: need to implement timeout logic - see EDPS
        // todo: add token to memberService response headers
        // refresh client-side token
        if (response.headers['token'] && localStorage.token !== response.headers['token']) {
            localStorage.token = response.headers['token'];
        }
        return response;
    },
    (error) => {
        // redirect to /Unauthorized route (if not already there)
        const currentLocation = window.location.href.split('/').pop();
        if (error.response && error.response.status === HttpCode.Unauthorized && currentLocation !== "Unauthorized") {
           window.location.href = "/Unauthorized";
        }
        return Promise.reject(error);
    }
);

class App extends React.Component {
    componentWillMount() {
        let currentLocation = window.location.href.split('/').pop();
        if (currentLocation.indexOf('?') !== -1) {
            currentLocation = currentLocation.split('?')[0];
        }

        if (currentLocation !== 'Login') {
            axios.get('/MerlinUser/CurrentUser')
            .then((response) => {
                //add current user response data to redux store (session / currentUser)
                this.props.setCurrentUser(response.data);
            });
        }

        //get client & environment
        axios.get('/session/portalurl')
        .then((response) => {
            //https://qamrr-mrr.qa.advantasure.com/ https://bcbsm-mrr.int.advantasure.com/
            var url = response.data;
            console.log("session/portalurl - response.data"); console.log(response.data);
            var startIdx = response.data.indexOf('://',0)+3; 
            var endIdx = response.data.indexOf('-',0)
            var client = endIdx !== -1? url.substr(startIdx,(endIdx-startIdx)).toLowerCase(): "dev";
            localStorage.Client = client === 'qamrr' ? 'qa' : client;

            //environment
            var env = '';
            env = response.data.indexOf('prod',0) > 0 ? 'prod' : ( 
                response.data.indexOf('int',0) > 0 ? 'int' : (  
                                    response.data.indexOf('stage',0) > 0 || response.data.indexOf('stg',0) > 0? 'stg' : ( response.data.indexOf('qa',0) > 0 ? 'qa' : 'dev') 
                            )

            )
            localStorage.Environment = env;
        })
        .catch((error) => {
            localStorage.Client = null;
            localStorage.Environment = null;
            console.log(error);
        });
    }

    render() {
        return (
            <Router history={browserHistory}>
                {Routes}
            </Router>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps
)(withAITracking(reactPlugin, App));
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import ProviderNPISearchModal from './ProviderNPISearchModal';

class ProviderSearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastName: '',
            firstName: '',
            providerID: '',
            providerNPI: '',
            searchResults: [],
            hasSearchBeenPerformed: false,
            showProviderNPISearchModal: false,
            page: 1,
            sizePerPage: 10,
            totalItems: 0,
            loaded: true
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            lastName: nextProps.value ? nextProps.value.lastName : '',
            firstName: nextProps.value ? nextProps.value.firstName : '',
            providerNPI: nextProps.value ? nextProps.value.providerNPI : '',
            providerID: nextProps.value ? nextProps.value.providerID : '',
            searchResults: [],
            page: 1,
            sizePerPage: 10,
            totalItems: 0
        });
    }

    handleProviderSearch = (page = this.state.page, sizePerPage = this.state.sizePerPage) => {
        this.setState({ loaded: false });
        const queryParams = {
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            providerID: this.state.providerID,
            providerNPI: this.state.providerNPI,
            memberMasterID: this.props.memberID,
            page: page,
            pageSize: sizePerPage
        };
 
        axios.get('/memberService/v1/ProviderSearch/ProviderInfo', {
            params: queryParams,
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                searchResults: response.data.items,
                totalItems: response.data.totalItems,
                page: page,
                hasSearchBeenPerformed: true,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({
                searchResults: [],
                totalItems: 0,
                page: 1,
                loaded: true
            });
            this.props.toast('error', 'Could not search providers.', 'Error');
        });
    }

    handleEnterKeySearch = (e) => {
        if (e.key === "Enter" && !this.validateBeforeSearch()) { this.handleProviderSearch(1); }
    }

    setLastName = (e) => {
        this.setState({ lastName: e.target.value });
    }

    setFirstName = (e) => {
        this.setState({ firstName: e.target.value });
    }

    setProviderID = (e) => {
        this.setState({ providerID: e.target.value });
    }

    setProviderNPI = (e) => {
        this.setState({ providerNPI: e.target.value });
    }

    // toggle diagnosis form modal and this modal, if NPI registry modal is not visible
    handleModalToggle = () => {
        if (!this.state.showProviderNPISearchModal) {
            this.props.handleModalToggle();
        }
    }

    // toggle between this modal and NPI registry modal
    toggleProviderNPISearchModal = () => {
        this.setState({ 
            showProviderNPISearchModal: !this.state.showProviderNPISearchModal,
            visible: this.state.showProviderNPISearchModal
        });
    }

    // toggle NPI registry modal AND this modal, to return user back to diagnosis form
    toggleBackToDiagnosisForm = () => {
        this.toggleProviderNPISearchModal();
        this.props.handleModalToggle();
        this.setState({ searchResults: [] });
    }

    handleSelectSearchResult = (rowIndex) => {
        this.props.handleSelectSearchResult(this.refs.table.state.data[rowIndex].providerMasterID, 
                                            this.refs.table.state.data[rowIndex].providerID,
                                            this.refs.table.state.data[rowIndex].npi, 
                                            this.refs.table.state.data[rowIndex].lastName, 
                                            this.refs.table.state.data[rowIndex].firstName,
                                            this.refs.table.state.data[rowIndex].planProviderID);
        this.handleModalToggle();
    }

    validateBeforeSearch = () => {
        if ((!this.state.lastName || this.state.lastName.length < 2) && !this.state.providerID && !this.state.providerNPI) {
            return true;
        }
        return false;
    }

    formatProviderName = (cell, row) => {
        return `${row.lastName}, ${row.firstName}`;
    }

    formatAddress = (cell, row) => {
        return `${row.address}, ${row.city}, ${row.state} ${row.zipCode}`
    }

    render() {
        const canSearch = this.validateBeforeSearch();
        const customSelect = (props) => {
            const rowIndex = props.rowIndex;
            return rowIndex !== 'Header' ? (
                <div>
                    <Button onClick={() => this.handleSelectSearchResult(rowIndex)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
            ) : null;
        };
        const selectRowProp = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '6rem'
        };
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                }, this.handleProviderSearch);
            }
        };

        return (
            <div>
                <Modal show={this.state.visible} onHide={() => this.handleModalToggle()} className="modal-wide" keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Provider Search</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginBottom: '1.6rem' }}>
                            <Row>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Last Name</ControlLabel>
                                        <FormControl type="text" value={this.state.lastName} placeholder="Last Name"
                                        onKeyPress={this.handleEnterKeySearch} onChange={this.setLastName} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>First Name</ControlLabel>
                                        <FormControl type="text" value={this.state.firstName} placeholder="First Name"
                                        onKeyPress={this.handleEnterKeySearch} onChange={this.setFirstName} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Provider ID</ControlLabel>
                                        <FormControl type="text" value={this.state.providerID} placeholder="Provider ID"
                                        onKeyPress={this.handleEnterKeySearch} onChange={this.setProviderID} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>NPI</ControlLabel>
                                        <FormControl type="text" value={this.state.providerNPI} placeholder="NPI"
                                        onKeyPress={this.handleEnterKeySearch} onChange={this.setProviderNPI} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} style={{ marginTop: '2.4rem' }}>
                                    <Button onClick={() => this.handleProviderSearch(1)} style={{ float: 'left', marginRight: '1rem' }} disabled={canSearch}>
                                        <Glyphicon glyph="search" style={{ marginRight: '0.2rem' }} /> Search
                                    </Button>
                                    {
                                        this.state.hasSearchBeenPerformed &&
                                        <Button style={{ marginRight: '0.3rem' }} onClick={this.toggleProviderNPISearchModal}>
                                            <Glyphicon glyph="new-window" style={{ marginRight: '0.6rem' }} />NPI Registry
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                        <div style={{ minHeight: '12rem' }}>
                            <Loader loaded={this.state.loaded}>
                                <BootstrapTable data={this.state.searchResults} ref='table' selectRow={selectRowProp} options={options} hover pagination
                                    remote fetchInfo={{ dataTotalSize: this.state.totalItems }}>
                                    <TableHeaderColumn dataField="providerMasterID" dataAlign="left" isKey hidden></TableHeaderColumn>
                                    <TableHeaderColumn dataField="providerName" dataFormat={this.formatProviderName} tdStyle={{ whiteSpace: 'normal' }}
                                        dataAlign="left" width="20%">Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField="providerID" dataAlign="left" width="13%">Provider ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="npi" dataAlign="left" width="13%">NPI</TableHeaderColumn>
                                    <TableHeaderColumn dataField="addressComposite" dataFormat={this.formatAddress} tdStyle={{ whiteSpace: 'normal' }}
                                        dataAlign="left" width="39%">Address</TableHeaderColumn>
                                    <TableHeaderColumn dataField="phoneNumber" dataAlign="left" width="15%">Phone</TableHeaderColumn>
                                </BootstrapTable>
                            </Loader>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleModalToggle()}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <ProviderNPISearchModal visible={this.state.showProviderNPISearchModal} handleModalToggle={this.toggleProviderNPISearchModal}
                    toggleBackToDiagnosisForm={this.toggleBackToDiagnosisForm} handleSelectSearchResult={this.props.handleSelectSearchResult}
                    providerFirstName={this.state.firstName} providerLastName={this.state.lastName} providerNPI={this.state.providerNPI} 
                    memberID={this.props.memberID} toast={this.props.toast} />
            </div>
        );
    }
}

export default ProviderSearchModal;
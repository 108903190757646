import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import PDFViewer from './FileViewer/PDFViewer';
import DOCXViewer from './FileViewer/DOCXViewer';
import PNGViewer from './FileViewer/PNGViewer';
import TXTViewer from './FileViewer/TXTViewer';

class FileViewModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {         
            imageLoading: false
        };

        this.fileTransformUrl = '/EMRMedicalRecord/attachments?attachmentName=';
    }

    render() {
        const file = this.fileTransformUrl + this.props.filePath;
        const fileName = this.props.fileName;
        const type = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

        return (           
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle} backdrop="static" className={"modal-medium-wide"} >
                <Modal.Header closeButton>
                    <Modal.Title>{fileName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>                  
                    <Row style={{ padding: '2rem 1rem 0 1rem' }}>
                        <Loader loaded={!this.state.imageLoading}>
                            {       
                                type==='pdf' && <PDFViewer uri={file}/> 
                            }
                            {                       
                                type==='docx' && <DOCXViewer uri={file}/> 
                            }
                                                        {       
                                type==='png' && <PNGViewer uri={file}/> 
                            }      
                            {                       
                                type==='txt' && <TXTViewer uri={file}/> 
                            }      
                                            
                        </Loader>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default FileViewModal;
import React from 'react';
import axios from 'axios';
import { Button, DropdownButton, Glyphicon, MenuItem, Modal, Pagination, Table } from 'react-bootstrap';
import Loader from 'react-loader';
import '../../styles/Report.css';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor.jsx';

class ReportDrillDownModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showError: false,
            records: [],
            activePage: 1,
            orderByDescending: false,
            orderByColumnName: null,
            loaded: true,
            pageSize: 10
        }
    }

    // set showModal state to true
    open = () => {
        this.setState({ showModal: true, loaded: false });
        this.getDrillDownRecords();
    }

    // set showModal state to false if 'cancel' or 'hide' selected
    close = () => {
        this.setState({ showModal: false, activePage: 1, pageSize: 10 });
    }

    getDrillDownRecords = (orderByColumnName, pageNumber, pageSize) => {
        var _this = this;

        // Update state values
        var orderByDescending = false;
        if (orderByColumnName !== undefined) {
            if (orderByColumnName !== _this.state.orderByColumnName) {
                _this.setState({ orderByColumnName: orderByColumnName, orderByDescending: false });
            } else {
                _this.setState({ orderByDescending: !_this.state.orderByDescending });
                orderByDescending = !_this.state.orderByDescending;
            }
        }

        // Builds the query string
        var queryParams = [];
        if (_this.props.reportType === "RiskMitigation") {
            queryParams.push('/Reports/RiskMitigation/RiskMitigationAuditReport/Records?projectID=' + _this.props.projectID);
        } else {
            queryParams.push('/Reports/ClaimsAudit/ClaimsAuditReport/Records?projectID=' + _this.props.projectID);
        }
        queryParams.push('filter=' + _this.props.diagReportFilter);
        if (_this.props.commentCD) {
            queryParams.push('commentCDFilter=' + _this.props.commentCD);
        }
        if (_this.props.initiatorTypeID) {
            queryParams.push('initiatorType=' + _this.props.initiatorTypeID);
        }
        if (orderByColumnName !== undefined) {
            queryParams.push('orderByColumnName=' + orderByColumnName);
            queryParams.push('orderByDescending=' + orderByDescending);
        } else {
            queryParams.push('orderByDescending=' + _this.state.orderByDescending);
        }
        if (pageNumber !== undefined) {
            queryParams.push('pageNumber=' + pageNumber);
        }
        queryParams.push('pageSize=' + (pageSize ? pageSize : this.state.pageSize));
        var queryStr = queryParams.join('&');

        axios.get(queryStr)
            .then((response) => {
                _this.setState({ records: response.data.items,
                    totalItems: response.data.totalItems,
                    loaded: true,
                    showError: false
                });
            }).catch((error) => {
                console.log(error);
                this.setState({ showError: true, loaded: true });
            });
    }

    handlePageSelection = (pageNumber) => {
        this.setState({activePage: pageNumber});
        this.getDrillDownRecords(this.state.orderByColumnName, pageNumber, this.state.pageSize);
    }

    handlePageSizeSelection = (pageSize) => {
        this.setState({ pageSize: pageSize, activePage: 1 });

        this.getDrillDownRecords(this.state.orderByColumnName, 1, pageSize);
    }

    getSortIcon = (columnName) => {
        if (columnName !== this.state.orderByColumnName) {
            return '';
        }
        return this.state.orderByDescending ? 'triangle-bottom' : 'triangle-top';
    }

    buildFunctionToSendToDownloadLink = () => {
        return axios.post("/ApplicationReports/DownloadRequests"
        , {
            applicationReportID : 6,
            reportParameters : {
                projectId : this.props.projectID,
                filter : this.props.diagReportFilter,
                initiatorType : this.props.initiatorTypeID
            }
        })
    }

    render() {
        return (
        <div style={{display: 'inline-block'}}>
            <button className="buttonLink" onClick={this.open}>{this.props.displayValue}</button>
            <Loader loaded={this.state.loaded}>
                <Modal show={this.state.showModal} onHide={this.close} dialogClassName="modal-dialog-fullwidth">
                    <div hidden={this.state.showError}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.props.modalTitle}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <DropdownButton id={`dropdown-size-medium`} title={this.state.pageSize} onSelect={this.handlePageSizeSelection} style={{marginTop: '-25px'}}>
                                    <MenuItem eventKey="5">5</MenuItem>
                                    <MenuItem eventKey="10">10</MenuItem>
                                    <MenuItem eventKey="20">20</MenuItem>
                                    <MenuItem eventKey="50">50</MenuItem>
                                    <MenuItem eventKey="100">100</MenuItem>
                                    <MenuItem eventKey="250">250</MenuItem>
                                </DropdownButton>
                                <Pagination bsSize="medium" items={Math.ceil(this.state.totalItems / this.state.pageSize)} activePage={this.state.activePage} style={{marginLeft: '10px', marginTop: '0px', marginBottom: '0px'}} onSelect={this.handlePageSelection}
                                    prev next first last ellipsis boundaryLinks maxButtons={5} />
                                <Table bordered className="reportDrillDownTable">  
                                    <thead>
                                        <tr>
                                            <th hidden={this.props.initiatorTypeID !== 2} onClick={this.getDrillDownRecords.bind(this, 'InitiatorIdentifier')}>Claim # <Glyphicon glyph={this.getSortIcon('InitiatorIdentifier')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'HCCs')}>HCC <Glyphicon glyph={this.getSortIcon('HCCs')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'DiagnosisCD')}>Dx <Glyphicon glyph={this.getSortIcon('DiagnosisCD')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'MedicalRecordReviewID')}>MRR ID <Glyphicon glyph={this.getSortIcon('MedicalRecordReviewID')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'MemberID')}>Member ID <Glyphicon glyph={this.getSortIcon('MemberID')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'BeneficiaryID')}>Beneficiary ID <Glyphicon glyph={this.getSortIcon('BeneficiaryID')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'MemberName')}>Name <Glyphicon glyph={this.getSortIcon('MemberName')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'MemberDateOfBirth')}>Date of Birth <Glyphicon glyph={this.getSortIcon('MemberDateOfBirth')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'NPI')}>NPI <Glyphicon glyph={this.getSortIcon('NPI')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'ProviderName')}>Provider Name <Glyphicon glyph={this.getSortIcon('ProviderName')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'DateOfServiceFrom')}>DOS From <Glyphicon glyph={this.getSortIcon('DateOfServiceFrom')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'DateOfServiceTo')}>DOS Thru <Glyphicon glyph={this.getSortIcon('DateOfServiceTo')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'IsSignatureImpaired')}>Signature Impaired <Glyphicon glyph={this.getSortIcon('IsSignatureImpaired')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'UpdatedDatetime')}>Last Updated <Glyphicon glyph={this.getSortIcon('UpdatedDatetime')} /></th>
                                            <th onClick={this.getDrillDownRecords.bind(this, 'UpdatedName')}>Last Updated By <Glyphicon glyph={this.getSortIcon('UpdatedName')} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.records && this.state.records.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td hidden={this.props.initiatorTypeID !== 2}>{row.initiatorIdentifier}</td>
                                                    <td>{row.hcCs}</td>
                                                    <td>{row.diagnosisCD}</td>
                                                    <td>{row.medicalRecordReviewID}</td>                                            
                                                    <td>{row.memberID}</td>
                                                    <td>{row.beneficiaryID}</td>
                                                    <td>{row.memberName}</td>
                                                    <td>{row.memberDateOfBirth}</td>
                                                    <td>{row.npi}</td>
                                                    <td>{row.providerName}</td>
                                                    <td>{row.dateOfServiceFrom}</td>
                                                    <td>{row.dateOfServiceTo}</td>
                                                    <td>{row.isSignatureImpaired}</td>
                                                    <td>{row.updatedDatetime}</td>
                                                    <td>{row.updatedName}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer style={{textAlign: 'initial'}}>
                                <LongRunningDownloadAnchor linkText="Download .CSV" postRequest={this.buildFunctionToSendToDownloadLink}
                                    displayCSVDownloader={this.props.displayDownloadButton} style={{float: 'left'}}/>
                                <Button onClick={this.close} style={{float: 'right'}}>Close</Button>
                            </Modal.Footer>
                    </div>
                    <div hidden={!this.state.showError}>
                        <span>An error occurred while trying to load the modal.</span>
                    </div>
                    
                </Modal>
            </Loader>
        </div>
        );
    }
}

export default ReportDrillDownModal;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import TargetBox from '../../../../Shared/FileTargetBox';
import FileList from '../../../../Shared/FileList';
const { FILE } = NativeTypes;

class EvidenceUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            droppedFiles: [],
            initialProgress: 50,
            finalProgress: 100,
            diagData: {
                providerID: '',
                dateOfServiceFrom: '',
                dateOfServiceTo: '',
                medicalRecordDiagID: ''
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visible && this.state.diagData.medicalRecordDiagID !== nextProps.diagData.medicalRecordDiagID
            && nextProps.diagData.medicalRecordDiagID > 0) {
            this.setState({
                diagData: { ...nextProps.diagData },
                droppedFiles: []
            });
        }
        this.setState({ visible: nextProps.visible });
    }

    /* this is invoked for validating and file upload progress */
    handleValidateAndUpload = () => {
        if (this.state.droppedFiles.length > 0) {
            let fileStateChange = false;
            this.state.droppedFiles.forEach(f => {
                if (f.uploadProgress !== this.state.finalProgress) {
                    fileStateChange = true;
                    this.handleFileValidate(f);
                    this.uploadFile(f);
                }
            });

            if (fileStateChange) {
                this.setState({ droppedFiles: this.state.droppedFiles });
            }
        }
    }

    /* this is invoked when user drags and drops a file */
    handleFileDrop = (item, monitor) => {
        if (monitor) {
            const droppedFile = monitor.getItem().files[0];
            droppedFile.uploadProgress = this.state.initialProgress;
            this.setState({
                ...this.state.droppedFiles.push(droppedFile)
            }, () => this.handleValidateAndUpload());
        }
    }

    /* this is invoked when user selects a file using file explorer */
    handleUploadFile = (ev) => {
        ev.preventDefault();
        if (ev.target.files.length > 0) {
            const droppedFile = ev.target.files[0];
            droppedFile.uploadProgress = this.state.initialProgress;
            this.setState({
                ...this.state.droppedFiles.push(droppedFile)
            }, () => this.handleValidateAndUpload());
        }
    }

    handleModalToggle = () => {
        this.setState({ showEvidenceModal: !this.state.visible });
        this.props.handleModalToggle();
    }

    handleFileValidate(file) {
        if (file.finalProgress === this.state.finalProgress) {
            return file;
        }
        const fileExtension = file.name.split('.').pop();
        if (fileExtension !== "pdf" && file.type !== "application/pdf") {
            file.uploadProgress = this.state.finalProgress;
            file.message = "Invalid file type - must be .pdf";
            file.style = "danger";
            return file;
        }

        const fName = file.name.split('.')[0];
        if (fName !== this.props.radvEnrolleeID) {
            file.message = "The filename must begin with the RADV Enrollee ID (" + this.props.radvEnrolleeID + ")";
            file.uploadProgress = this.state.finalProgress;
            file.style = "danger";
        }
        return file;
    }

    uploadFile(file) {
        if (file === null || file.uploadProgress === this.state.finalProgress || file.queuedToUpload) {
            return file;
        }

        const data = new FormData();
        data.append('file', file);
        data.append('name', file.name);

        file.queuedToUpload = true;

        axios.post(`/mrrService/v1/medicalrecordreview/${this.props.medicalRecordReviewID}/images?providerID=${this.state.diagData.providerID}
            &dosFrom=${this.state.diagData.dateOfServiceFrom}&dosTo=${this.state.diagData.dateOfServiceTo}`, data)
        .then(response => {
            file.uploadProgress = this.state.finalProgress;
            file.style = "success";
            file.message = "Uploaded sucessfully";
            this.setState({droppedFiles: this.state.droppedFiles});
        })
        .catch(error => {
            file.message = "Failed to upload";
            file.style = "danger";
            file.uploadProgress = this.state.finalProgress;
            this.setState({droppedFiles: this.state.droppedFiles});
        });
    }

    render() {
        return (
            <div>
                <Modal show={this.state.visible} onHide={this.handleModalToggle} className="modal-wide" keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Evidence File Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginBottom: '1.6rem' }}>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="fileupload">
                                            <label>
                                                <input type="file" onChange={this.handleUploadFile} />
                                                <DragDropContextProvider backend={HTML5Backend}>
                                                    <TargetBox accepts={[FILE]} onDrop={this.handleFileDrop} />
                                                </DragDropContextProvider>
                                            </label>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Row>
                                            <ControlLabel>
                                                Appointment Provider
                                            </ControlLabel>
                                            <FormControl type="text" value={this.state.diagData.providerID} disabled>
                                            </FormControl>
                                        </Row>
                                        <Row>
                                            <ControlLabel>DOS</ControlLabel>
                                            <DatePicker value={this.state.diagData.dateOfServiceFrom} disabled />
                                        </Row>
                                        <Row>
                                            <ControlLabel>DOS Thru</ControlLabel>
                                            <DatePicker value={this.state.diagData.dateOfServiceTo} disabled />
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} style={{ overflowY: 'auto' }}>
                                    <FileList style={{ textAlign: 'top', verticalAlign: 'top' }} files={this.state.droppedFiles} />
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleModalToggle}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default EvidenceUploadModal;
import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import MedicalRecordDiagComments from './MedicalRecordDiagComments';
import PDFViewerComponent from './PDFViewerComponent'

class CodingDetailsNotLanding extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dataRecords: [],
            showModal: false,
            loaded: false
        };
    }

    close = () => {
        this.setState({ showModal: false });
    }

    open = () => {
        if (!this.props.medicalRecordDiagRADVDetailID) {
            this.props.toast('error', 'Could not open coding details.');
            return;
        }
        else {
            this.setState({
                showModal: true,
                loaded: false
            }, () =>
                axios.get(`/MedicalRecordDiagRADVDetails/${this.props.medicalRecordDiagRADVDetailID}/CodingDetails`)
                .then((response) => {
                    this.setState({
                        dataRecords: response.data,
                        loaded: true
                    });
                })
                .catch((error) => {
                    this.setState({
                        dataRecords: [],
                        loaded: true
                    });
                    this.props.toast('error', 'Failed to load coding details.');
                })
            );
        }
    }

    formatCommentModal = (cell, row) => {
        return <MedicalRecordDiagComments count={row.diagCommentCount} medicalRecordDiagID={row.medicalRecordDiagID} toast={this.props.toast} />;
    }

    formatImageID = (cell, row) => {
        return <PDFViewerComponent displayText={row.medicalRecordImageID}
            uri={`/medicalrecordreview/${row.medicalRecordReviewID}/images/${row.medicalRecordImageID}`}
            toast={this.props.toast} />;
    }

    formatDate = (cell, row) => {
        return new Date(cell).toLocaleDateString('en-US');
    }

    render() {
        return(
            <div>
                <button className="buttonLink" onClick={this.open}>Details</button>
                <Modal show={this.state.showModal} onHide={this.close} dialogClassName="modal-dialog-fullwidth">
                    <Loader loaded={this.state.loaded}>
                        <Modal.Header closeButton>
                            <Modal.Title>Coding Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="landingSearchResults">
                                <BootstrapTable data={this.state.dataRecords} hover>
                                    <TableHeaderColumn dataField="medicalRecordDiagID" isKey dataAlign="left">Diag ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagProviderID" dataAlign="left">Provider ID/NPI</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagProviderTypeDesc" dataAlign="left">Provider Type</TableHeaderColumn>
                                    <TableHeaderColumn dataField="dateOfServiceFrom" dataFormat={this.formatDate} dataAlign="left">DOS</TableHeaderColumn>
                                    <TableHeaderColumn dataField="dateOfServiceTo" dataFormat={this.formatDate} dataAlign="left">DOS Thru</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagnosisCD" dataAlign="left">Diagnosis</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagnosisHCCs" dataAlign="left">HCC-C</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagnosisHCCShortDesc" dataAlign="left">Description</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagSourceDesc" dataAlign="left">Source</TableHeaderColumn>
                                    <TableHeaderColumn dataFormat={this.formatCommentModal} dataAlign="left">Comments</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagPages" dataAlign="left">Pages</TableHeaderColumn>
                                    <TableHeaderColumn dataField="medicalRecordImageId" dataFormat={this.formatImageID} dataAlign="left">Image ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="medicalRecordTypeDesc" dataAlign="left">Record Type</TableHeaderColumn>
                                    <TableHeaderColumn dataField="scoreDesc" dataAlign="left">RADV Score</TableHeaderColumn>
                                    <TableHeaderColumn dataField="diagUpdatedUserFullName" dataAlign="left">Coded By</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Close</Button>
                        </Modal.Footer>
                    </Loader>
                </Modal>
            </div>
        );
    }
}
export default CodingDetailsNotLanding;
import React from "react";
import axios from 'axios';
import { Col, Form, FormGroup, Checkbox } from 'react-bootstrap';

class MDRankingNeeded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.enrolleeInfo.medicalDirectorReviewOfRankingNeeded != null ? props.enrolleeInfo.medicalDirectorReviewOfRankingNeeded : false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ checked: nextProps.enrolleeInfo.medicalDirectorReviewOfRankingNeeded });
    }

    handleClick = () => {
        this.setState({
             checked: !this.state.checked
        }, () => {
            if (this.props.enrolleeInfo.radvAuditEnrolleeID) {
                axios.post(`/RADVAuditEnrollees/${this.props.enrolleeInfo.radvAuditEnrolleeID}`, {
                    radvAuditEnrolleeID: this.props.enrolleeInfo.radvAuditEnrolleeID,
                    medicalDirectorReviewOfRankingNeeded: this.state.checked
                })
                .catch((error) => {
                    this.props.toast('error', 'Failed to save ranking selection.');
                });
            }
        });
    }

    render() {
        return (
            <div>
                <Col>
                    <Form>
                        <FormGroup>
                            <Checkbox checked={this.state.checked} onClick={this.handleClick}>Medical Director Review Ranking Needed</Checkbox>
                        </FormGroup>
                    </Form>
                </Col>
            </div>
        )
    }
}

export default MDRankingNeeded;
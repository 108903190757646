import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { CoderQAGeneratorType } from '../../../enums/CoderQAGeneratorType';
import { CoderQAQueueStatus } from '../../../enums/CoderQAQueueStatus';
import { MedicalRecordReviewCoderQAStatus } from '../../../enums/MedicalRecordReviewCoderQAStatus';
import { MedicalRecordReviewStatus } from '../../../enums/MedicalRecordReviewStatus';

const CoderQATable = (props) => {
    const formatCompletionDateRange = (cell, row) => {
        return `${formatDate(row.completionDateStart)} - ${formatDate(row.completionDateEnd)}`;

        function formatDate(date) {
            return date ? new Date(date).toLocaleDateString() : '';
        }
    }

    const startQueue = (cell, row) => {
        axios.get(`/ReviewProcessingUIConfig/CoderQA/NextReview?CoderQAQueueID=${row.coderQAQueueID}`)
        .then((response) => {
            window.location.href = `/ReviewProcessing/CoderQA?medicalRecordReviewID=${response.data.medicalRecordReviewID}&coderQAQueueID=${response.data.coderQAQueueID}`;
        })
        .catch((error) => {
            props.toast('error', 'Could not start queue.', 'Error');
        });
    }

    const closeQueue = (cell, row) => {
        axios.put(`/coderQA/${row.coderQAQueueID}`, {
            ...{ ...row, status: 'Closed' }
        })
        .then((response) => {
            props.addQueueToState(response.data);
        })
        .catch((error) => {
            props.toast('error', 'Could not close queue.', 'Error');
        })
    }

    //todo: should the review status validation also be applied for fractured queues?
    //todo: add glyphicons
    const formatActions = (cell, row) => {
        const renderClose = (cell, row) => {
            return (
                <Button onClick={() => closeQueue(cell, row)} disabled={row.status === CoderQAQueueStatus.Closed} style={{ marginLeft: '1rem' }}>
                    {/*<Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} />*/}
                    Close
                </Button>
            )
        }

        if (row.status !== CoderQAQueueStatus.Building) {
            if (row.coderQAGeneratorTypeID === CoderQAGeneratorType.User_Generated) {
                return (
                    <div>
                        <Button onClick={() => startQueue(cell, row)} disabled={row.medicalRecordReviewReadyCount === 0 || row.status === CoderQAQueueStatus.Closed
                            || (row.MedicalRecordReviewCoderQAStatus !== MedicalRecordReviewCoderQAStatus.Ready
                                && row.ReviewStatusID === MedicalRecordReviewStatus.SubmissionEvaluationComplete)}>
                            {/*<Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} /> */}
                            Start
                        </Button>
                        {renderClose(cell, row)}
                    </div>
                );
            }
            else if (row.coderQAGeneratorTypeID === CoderQAGeneratorType.System_Generated) {
                return (
                    <div>
                        <Button onClick={() => startQueue(cell, row)} disabled={row.medicalRecordReviewReadyCount === 0 || row.status === CoderQAQueueStatus.Closed}>
                            {/*<Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} /> */}
                            Start
                        </Button>
                        {renderClose(cell, row)}
                    </div>
                );
            }
        }
        else {
            return 'Building';
        }
    }

    return (
        <div style={{ marginTop: '2rem' }}>
            <BootstrapTable data={props.queues} pagination>
                <TableHeaderColumn dataField="coderQAQueueID" isKey hidden />
                <TableHeaderColumn dataField="medicalCoderDisplayName" dataSort>Medical Coder</TableHeaderColumn>
                <TableHeaderColumn dataField="completionDateStart" dataFormat={formatCompletionDateRange} dataSort>Completion Date Range</TableHeaderColumn>
                <TableHeaderColumn dataField="projectDisplayName" dataSort>Project</TableHeaderColumn>
                <TableHeaderColumn dataField="recordTypesDisplay" dataSort>Record Type</TableHeaderColumn>
                <TableHeaderColumn dataField="medicalRecordReviewFullCount" dataSort>Total</TableHeaderColumn>
                <TableHeaderColumn dataField="medicalRecordReviewReadyCount" dataSort>Ready for Coder QA</TableHeaderColumn>
                <TableHeaderColumn dataField="medicalRecordReviewSkippedCount" dataSort>In Progress</TableHeaderColumn>
                <TableHeaderColumn dataField="medicalRecordReviewCompletedCount" dataSort>Skipped</TableHeaderColumn>
                <TableHeaderColumn dataField="action" dataFormat={formatActions}>Action</TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
}

export default CoderQATable;
// exports MedicalRecordType - an immutable object 'enum'
// usage: import { MedicalRecordType } from '../../enums/MedicalRecordType';
// note: this corresponds to the ReferenceDataItem table but is named MedicalRecordType
// for consistency with the like-named enum in mrr-services/mrr_domain
const medicalRecordTypes = {
    Inpatient: 101,
    Outpatient: 102,
    Physician: 103,
    Specialty: 104,
    ProgressNote: 201,
    FromEncounter: 202,
    ConsultNote: 203,
    HospitalNote: 204,
    ERNote: 205,
    PathologistReport: 206,
    Other: 207,
    DeleteFromRAPS: 208,
    EMR: 301,
    Scan: 302,
    Onsite: 303,
    MedicalRecordReview: 401,
    MedicalRecordReReview: 402,
    CDIAlertReview: 403,
    DroppedConditionReview: 404,
    HospitalDischargeReview: 405,
    OpportunitiesOnlyReview: 406,
    CodesOnlyReview: 407,
    DiagnosisRules: 501,
    DroppedHCC: 502,
    Pharmacy: 503,
    MRR: 504,
    Lab: 505,
    SuppData: 506,
    RetrospectiveReview: 601,
    Open: 701,
    Duplicate: 702,
    Matched: 703,
    MedicalRecord: 801,
    CDIAlertInitialResponse: 802,
    CDIAlertQueryResponse: 803,
    A: 901,
    B: 902,
    C: 903
};
Object.freeze(medicalRecordTypes);
export const MedicalRecordType = medicalRecordTypes;
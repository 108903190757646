import React from 'react';
import { Button, Checkbox, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Spinner from '../../Shared/Spinner';

class EPECProviderExclusionModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sortBy: "isExcluded",
            sortByDesc: true
        };
    }

    formatIsExcluded = (cell, row) => {
        //cell = isExcluded flag
        return <Checkbox defaultChecked={cell} 
                         onChange={() => this.props.handleIsExcludedChange(cell, row, this.state.sortBy, this.state.sortByDesc)} />
    }

    render() {
        const options = {
            //set default sort to render appropriate caret on <TableHeaderColumn>
            defaultSortName: "isExcluded",
            defaultSortOrder: "desc",
            onSortChange: (sortName, sortOrder) => {
                const sortByDesc = sortOrder === "desc";

                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortByDesc
                });

                this.props.getEPECProviderExclusionList(sortName, sortByDesc);
            }};

        return (
            <div>
                <Modal show={this.props.show} onHide={this.props.handleToggle}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            EPEC Provider Exception List for Practice <strong>{this.props.practiceName}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.props.loading ?
                                <Spinner /> :
                                <BootstrapTable data={this.props.providers} options={options} remote>
                                    <TableHeaderColumn hidden dataField="providerMasterID" isKey />                                                                             
                                    <TableHeaderColumn dataField="isExcluded" dataFormat={this.formatIsExcluded} 
                                        dataSort editable={false}>
                                        EPEC Excluded
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="providerLastName" dataSort>
                                        Provider Last Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="providerFirstName" dataSort>
                                        Provider First Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="providerNPI" datasort>
                                        Provider NPI
                                    </TableHeaderColumn>
                                </BootstrapTable>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <span style={{ marginRight: '1rem' }}>
                        {
                            this.props.saveMessage
                        }
                        </span>
                        <Button onClick={this.props.handleToggle}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default EPECProviderExclusionModal;
import React from 'react';
import axios from 'axios';
import { Modal, Row, Col, Button, Glyphicon   } from 'react-bootstrap';
import { DiagnosisUtils } from '../../../../../utils/DiagnosisUtils';

class DiagnosisDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            row: null,
            historyRow: null,
            diagnosisComments: null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visible && nextProps.row !== this.props.row) {
            this.setState({ 
                row: nextProps.row
            }, () => {
                this.getDiagHistory();
                this.getDiagComments();
            });
        }
    }
 
    getDiagHistory = () => {
        if (!this.state.row) {
            return;
        }

        axios.get(`/member/${this.props.memberID}/diaghistory`)
        .then((response) => {
            this.setState({ historyRow: response.data.find(x => x.medicalRecordDiagID === this.state.row.medicalRecordDiagID) });
        })
    }

    getDiagComments = () => {
        if (!this.state.row) {
            return;
        }
        axios.get(`/medicalrecorddiag/${this.state.row.medicalRecordDiagID}/diagcomments`)
        .then((response) => {
            this.setState({ 
                diagnosisComments: response.data.map(x => {
                    return x.commentCD
                }) 
            });
        })
    }

    formatReferenceDescription = (cell, collection) => {
        return collection.referenceData.find(x => x.value === parseInt(cell, 10)).description;
    }

    formatInitiatorTypeDescription = (value) => {
        switch (value) {
            case 1:
                return "User";
            case 2:
                return "Claim";
            case 3:
                return "Risk Mitigation";
            default:
                return "";
        }
    }

    getCommentDescriptionTasks = (collection, value) => {
        const foundItem = collection.find(x => x.commentDefinitionID === value);
        if (foundItem) {
            return "*" + foundItem.commentText;
        }
    }

    getCommentsData = (selectedComments, diagnosisComments) => {
        return selectedComments.map(x => this.getCommentDescriptionTasks(diagnosisComments, parseInt(x, 10))).toString().replace(/,/g, "\n");
    }

    renderMedicalRecordImageID = () => {
        if (this.state.row.medicalRecordImageID) {
            return this.state.row.medicalRecordImageID === -1 ? 'Not available in MRR'
            : this.state.row.medicalRecordImageID;
        }
        else {
            return '';
        }
    }

    render() {
        return (
            <div>
                <Modal className="diagDetailModal modal-wide" show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title><b>Diagnosis Details</b></Modal.Title>
                    </Modal.Header>
                    {
                        this.state.row &&
                        <Modal.Body style={{ padding: '5px' }}>
                            <Row style={{ display: 'flex', flexWrap: 'wrap', marginRight: 'auto', marginLeft: 'auto' }}>
                                <Col xs={3}>
                                    <div>
                                        <header>
                                            <h4>Provider</h4>
                                        </header>
                                        <div className="diagModalContainer">
                                            <Row>
                                                <span>{`Name: ${this.state.row.providerName}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Type: ${this.state.row.providerType ?
                                                    this.state.row.providerType : this.formatReferenceDescription(this.state.row.providerTypeID, this.props.providerTypeData)}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Provider ID: ${this.state.row.providerID}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Provider NPI: ${this.state.row.providerNPI}`}</span>
                                            </Row>
                                        </div>
                                        {
                                            this.state.row.secondaryProviderMasterID > 0 &&
                                            <div>
                                                <header>
                                                    <h4>Secondary Provider</h4>
                                                </header>
                                                <div className="diagModalContainer">
                                                    <Row>
                                                        <span>{`Name: ${this.state.row.secondaryProviderName ? this.state.row.secondaryProviderName : ''}`}</span>
                                                    </Row>
                                                    <Row>
                                                        <span>{`Provider ID: ${this.state.row.secondaryProviderID ? this.state.row.secondaryProviderID : ''}`}</span>
                                                    </Row>
                                                    <Row>
                                                        <span>{`Provider NPI: ${this.state.row.secondaryProviderNPI ? this.state.row.secondaryProviderNPI : ''}`}</span>
                                                    </Row>
                                                </div>
                                            </div>
                                        }
                                        <header>
                                            <h4>Medical Record</h4>
                                        </header>
                                        <div className="diagModalContainer">
                                            <Row>
                                                <span>{`DOS: ${this.state.row.dateOfServiceFrom} - ${this.state.row.dateOfServiceTo}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Image ID: ${this.renderMedicalRecordImageID()}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Pages: ${this.state.row.pageNumFrom} - ${this.state.row.pageNumTo}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Record Type: ${this.formatReferenceDescription(this.state.row.medicalRecordTypeID, this.props.medicalRecordTypeData)}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Source: ${this.formatReferenceDescription(this.state.row.sourceID, this.props.sourceData)}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`RAF: ${this.formatReferenceDescription(this.state.row.rafTypeID, this.props.rafTypeData)}`}</span>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={3}>
                                    <div>
                                        <header>
                                            <h4>Diagnosis</h4>
                                        </header>
                                        <div className="diagModalContainer">
                                            <Row>
                                                <span>{`Diagnosis: ${this.state.row.diagnosisCD}`}</span>
                                            </Row>
                                            {/* hcc display for diag history modal row */}
                                            {
                                                this.state.row.hccNumber &&
                                                <Row>
                                                    <span>{`HCC: ${this.state.row.hccNumber}`}</span>
                                                </Row>
                                            }
                                            {/* hcc display for diag capture modal - hccC and hccD */}
                                            {
                                                this.state.row.hccCsDisplay &&
                                                <Row>
                                                    <span>{`HCC-C: ${this.state.row.hccCsDisplay}`}</span>
                                                </Row>
                                            }
                                            {
                                                this.state.row.hccDsDisplay &&
                                                <Row>
                                                    <span>{`HCC-D: ${this.state.row.hccDsDisplay}`}</span>
                                                </Row>
                                            }
                                            <Row>
                                                <span>{`Description: ${this.state.row.description}`}</span>
                                            </Row>
                                            <Row>
                                                <span></span>
                                                <span>{`Entered By: ${this.state.row.coderUserFullname ?
                                                    this.state.row.coderUserFullname : this.state.row.user.fullName}`}</span>
                                            </Row>
                                            <Row>
                                                <span>{`Entered On: ${this.state.row.createdDateTime}`}</span>
                                            </Row>
                                            {/* todo - check diagHistoryRow to see if QA */}
                                            <div>
                                                {
                                                    this.state.historyRow && this.state.historyRow.qaUserFullname &&
                                                    <div>
                                                        <Row>
                                                            <span>{`QA By: ${this.state.historyRow.qaUserFullname}`}</span>
                                                        </Row>
                                                        <Row>
                                                            <span>{`QA On: ${this.state.historyRow.updatedDate}`}</span>
                                                        </Row>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                this.state.row && (DiagnosisUtils.isApprovedOrRejected(this.state.row, this.props.isComplete, this.props.isCoderQA, this.props.isMD)) &&
                                                <Row>
                                                    <span>Status:</span>
                                                    {
                                                        DiagnosisUtils.isApproved(this.state.row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) && 
                                                        <span style={{ color: '#38ca6e', marginLeft: '1rem' }}><b>Accepted</b></span>
                                                    }
                                                    {
                                                        DiagnosisUtils.isRejected(this.state.row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) && 
                                                        <span style={{ color: '#f00', cursor: 'pointer', marginLeft: '1rem' }}  onClick={() => this.props.toggleRejectModal(this.state.row, true)}><b>Rejected</b></span>
                                                    }
                                                </Row>
                                            }
                                        </div>
                                        <div className="diagModalContainer">
                                            {
                                                this.state.row.rejectionComments && this.state.row.rejectionComments.length > 0 &&
                                                <div>
                                                    <Row>
                                                        <span>Rejection Reasons</span>
                                                    </Row>
                                                    <Row>
                                                        <p className="commentBox">{this.state.row.rejectComments ? this.state.row.rejectComments : this.getCommentsData(this.state.row.rejectionComments, this.props.rejectionReasons)}</p>
                                                    </Row>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>
                                    <div>
                                        <header>
                                            <h4>Comments</h4>
                                        </header>
                                        <div className="diagModalContainer">
                                            <Row>
                                            {
                                                this.state.diagnosisComments &&
                                                <p className="commentBox" style={{ minHeight: '150px', height: '150px', overflowY: 'scroll' }}>{this.getCommentsData(this.state.diagnosisComments, this.props.diagnosisComments)}</p>
                                            }
                                            </Row>
                                            <Row>
                                                <span>{`Origin: ${this.formatInitiatorTypeDescription(this.state.row.initiatorTypeID)}`}</span>
                                            </Row>
                                            {
                                                this.state.row.initiatorIdentifier &&
                                                <Row>
                                                    <span>{`Claim Number: ${this.state.row.initiatorIdentifier}`}</span>
                                                </Row>
                                            }
                                            {
                                                !this.props.hideActions && !this.props.isComplete &&
                                                <div style={{ marginTop: '3rem' }}>
                                                    {
                                                        // Initial review
                                                        !this.props.isQA && !this.props.isMD &&
                                                        <div>
                                                            {
                                                                !DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(this.state.row) &&
                                                                <div className="diag-qa-button-wrapper diagModalCenter">
                                                                    <Button onClick={() => this.props.toggleFormModal(this.state.row, true, false)}>
                                                                        <Glyphicon bsSize="large" glyph="pencil"/>
                                                                    </Button>
                                                                    <Button className="deleteButton" onClick={() => this.props.toggleConfirmDeleteModal(this.state.row, true)}>
                                                                        <Glyphicon bsSize="large" glyph="trash"/>
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        // QA
                                                        (this.props.isQA || this.props.isMD) && !this.props.isCoderQA &&
                                                        <div>
                                                            {
                                                                // diags from initial review by another coder
                                                                this.state.row.createdUserID !== this.props.userID &&
                                                                <div className="diagModalCenter">
                                                                    {
                                                                        !DiagnosisUtils.isApprovedOrRejected(this.state.row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                                                                        // approve, reject, editQA
                                                                        <div className="diag-qa-button-wrapper">
                                                                            <Button className="approveButton" onClick={() => this.props.approveDiagnosis(this.state.row)}>
                                                                                <Glyphicon bsSize="large" glyph="ok"/>
                                                                            </Button>
                                                                            <Button className="deleteButton" onClick={() => this.props.toggleRejectModal(this.state.row, true)}>
                                                                                <Glyphicon bsSize="large" glyph="remove"/>
                                                                            </Button>
                                                                            <Button onClick={() => this.props.toggleFormModal(this.state.row, true, true)}>
                                                                                <Glyphicon bsSize="large" glyph="pencil"/>
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                // NEW diags
                                                                this.state.row.createdUserID === this.props.userID &&
                                                                <div>
                                                                    {
                                                                        !DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(this.state.row) &&
                                                                        <div className="diag-qa-button-wrapper diagModalCenter">
                                                                            <Button onClick={() => this.props.toggleFormModal(this.state.row, true, false)}>
                                                                                <Glyphicon bsSize="large" glyph="pencil"/>
                                                                            </Button>
                                                                            <Button className="deleteButton" onClick={() => this.props.toggleConfirmDeleteModal(this.state.row, true)}>
                                                                                <Glyphicon bsSize="large" glyph="trash"/>
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                DiagnosisUtils.isApprovedOrRejected(this.state.row, this.props.isComplete, this.props.isCoderQA, this.props.isMD) &&
                                                                // reopen
                                                                <div className="diag-qa-button-wrapper diagModalCenter">
                                                                    <Button onClick={() => this.props.reopenDiagnosis(this.state.row)}>Reopen</Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        {
                            this.props.showPrev &&
                            <Button onClick={() => this.props.setRow(this.props.index - 1)} style={{ float: 'left' }}>Previous</Button>
                        }
                        {
                            this.props.showNext &&
                            <Button onClick={() => this.props.setRow(this.props.index + 1)} style={{ float: 'right' }}>Next</Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default DiagnosisDetailModal;
import React from 'react';
import { Panel , Row , Col } from 'react-bootstrap';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';

class MemberDemographics extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    formatPhoneNumber = (phoneNumberString) => {
        
        var cleaned = (''+phoneNumberString).replace(/\D/g, '');
        if(cleaned.length === 10)
        {
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) 
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumberString;
    }

    noData = () =>
    {
        return(

            <Panel id='NoDemographics'>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Patient Demographics
                    </div>    
                    <NoData />                                   
                </Panel.Body>
            </Panel>
        ) 
    }

    memberData =(item) => {
        return(
            <div>       
                <Panel id='Patient Demographics'>
                    <Panel.Body>
                        <div className='sectionHeader'>
                            Patient Demographics
                        </div>
                        <Row>
                            <Col className='twoSectionCol'>
                                <div>
                                    <Row>
                                        <span>NAME: </span>
                                        <span className='twoSectionResults'>{item.full_name}</span>
                                    </Row>
                                    <Row>
                                        <span>MEMBER ID: </span>
                                        <span className='twoSectionResults'>{item.plan_member_id}</span>
                                    </Row>
                                    <Row>
                                        <span>ADDRESS: </span>
                                        <span className='twoSectionResults'>{item.full_address}</span>
                                    </Row>
                                    <Row>
                                        <span>EMAIL: </span>
                                        <span className='twoSectionResults'>{item.email}</span>
                                    </Row>
                                    <Row>
                                        <span>PHONE: </span>
                                        <span className='twoSectionResults'>{this.formatPhoneNumber(item.contact_number)}</span>
                                    </Row>
                                    <Row>
                                        <span>PREFIX: </span>
                                        <span className='twoSectionResults'>{item.prefix}</span>
                                    </Row>
                                    <Row>
                                        <span>SUFFIX: </span>
                                        <span className='twoSectionResults'>{item.suffix}</span>
                                    </Row>                                                                                                                                                                               
                                </div>
                            </Col>
                            <Col className='twoSectionCol'>
                                <div>
                                    <Row>
                                        <span>GENDER: </span>
                                        <span className='twoSectionResults'>{item.gender}</span>
                                    </Row>
                                    <Row>
                                        <span>DATE OF BIRTH: </span>
                                        <span className='twoSectionResults'>{formatDatetimeStringWithPrefixZero(item.birth_date)}</span>
                                    </Row>
                                    <Row>
                                        <span>ETHNICITY: </span>
                                        <span className='twoSectionResults'>{item.ethnicity}</span>
                                    </Row>
                                    <Row>
                                        <span>RACE: </span>
                                        <span className='twoSectionResults'>{item.race}</span>
                                    </Row>
                                    <Row>
                                        <span>LANGUAGE: </span>
                                        <span className='twoSectionResults'>{item.primary_language}</span>
                                    </Row>
                                    <Row>
                                        <span>MARTIAL STATUS: </span>
                                        <span className='twoSectionResults'>{item.marital_status}</span>
                                    </Row>
                                    <Row>
                                        <span>DECEASED DATE: </span>
                                        <span className='twoSectionResults'>{formatDatetimeStringWithPrefixZero(item.deceased_date)}</span>
                                    </Row>                                                                           
                                </div>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        )

    }

    render() {
        if (isEmpty(this.props.member) || !this.props.memberLoaded) {
            return this.noData();
        }
        else {
            const item = this.props.member;
            if (isEmpty(this.props.member)) {
                return this.noData();
            }
            else {
                return this.memberData(item);
            }
        }
    }
}

export default MemberDemographics;
import React, { Component } from 'react';
import { Row, Col, Well } from 'react-bootstrap';

export default class NoDataDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: 'The applied filter did not return any records.'
        };
    }

    render() {
        return (
            <Row>
                <Col md={12} style={{textAlign: 'center'}}>
                    <Well>
                        {this.state.text}
                    </Well>
                </Col>
            </Row>
        );
    }
}
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-select/dist/react-select.css';
import rootReducer from './redux/reducers';
import App from './App';
import './index.css';

//todo: import a selection of styles from the full collection:
//import './styles/fonts/firasans.css'

import './styles/libs/bootstrap/css/bootstrap.min.css';
import './styles/libs/bootstrap/css/bootstrap-theme.min.css';
import './styles/libs/react-toastr/animate.min.css';
import './styles/libs/react-toastr/toastr.min.css';

import './styles/dropdownMenu.css';
import './styles/mrrCommon.css';

//add debug token here
// if (process.env.NODE_ENV === 'development') {
//     localStorage.token = 'paste_token_here';
// }

const store = createStore(rootReducer);
render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('radvRoot')
);
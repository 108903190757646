import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Radio, Row } from 'react-bootstrap';
import Select from 'react-select';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';

const RDPInfo = (props) => {
    const handleRemoteDesktopChange = (e) => {
        props.handleWorkflowFieldChange({ remoteDesktop: !props.rdpInfo.remoteDesktop });
    }

    const handleRemoteSoftwareChange = (e) => {
        props.handleWorkflowFieldChange({ remoteSoftware: e ? e.value : '' });
    }

    const handleAccessTypeChange = (e) => {
        props.handleWorkflowFieldChange({ accessType: e ? e.value : '' });
    }

    const handleIPOrComputerNameChange = (e) => {
        props.handleWorkflowFieldChange({ ipOrComputerName: e.target.value });
    }

    const handleDomainChange = (e) => {
        props.handleWorkflowFieldChange({ domain: e.target.value });
    }

    const handleNotesChange = (e) => {
        props.handleWorkflowFieldChange({ notes: e.target.value });
    }

    return (
        <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel className="requiredField">Remote Desktop?</ControlLabel>
                        <div>
                            <Radio style={{ marginLeft: '1rem' }} onChange={handleRemoteDesktopChange}
                                value="yes" checked={props.rdpInfo.remoteDesktop} inline disabled={props.readOnly}>Yes
                            </Radio>
                            <Radio onChange={handleRemoteDesktopChange}
                                value="no" checked={!props.rdpInfo.remoteDesktop} inline disabled={props.readOnly}>No
                            </Radio>
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Remote Software</ControlLabel>
                        <Select value={props.rdpInfo.remoteSoftware} options={props.remoteSoftwareOptions}
                            onChange={handleRemoteSoftwareChange} disabled={props.readOnly || !props.rdpInfo.remoteDesktop} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.RDPSoftware)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel className="requiredField">Access Type</ControlLabel>
                        <Select value={props.rdpInfo.accessType} options={props.accessTypeOptions}
                            onChange={handleAccessTypeChange} disabled={props.readOnly || !props.rdpInfo.remoteDesktop} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.AccessType)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel className="requiredField">IP / Computer Name</ControlLabel>
                        <FormControl type="text" value={props.rdpInfo.ipOrComputerName} onChange={handleIPOrComputerNameChange}
                            disabled={props.readOnly || !props.rdpInfo.remoteDesktop} maxLength="255" />
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>Domain</ControlLabel>
                        <FormControl type="text" value={props.rdpInfo.domain} onChange={handleDomainChange}
                            disabled={props.readOnly || !props.rdpInfo.remoteDesktop} maxLength="255" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <FormGroup>
                        <ControlLabel>Notes</ControlLabel>
                        <FormControl type="text" value={props.rdpInfo.notes} onChange={handleNotesChange}
                            disabled={props.readOnly || !props.rdpInfo.remoteDesktop} maxLength="1000" />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default RDPInfo;
import React from "react";
import Loader from 'react-loader';
import Select from 'react-select';
import {
    Button,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Modal,
    Row
} from "react-bootstrap";

export default class AddCredentialModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            credentialUserID: '',
            credentialPassword: '',
            campaignEMRCredentialTypeID: ''
        };
    }

    handleSelectChange = (e) => {
        this.setState({ campaignEMRCredentialTypeID: e ? e.value : '' });
    }

    handleModalClose = () => {
        this.setState({
            // loaded: true,
            credentialUserID: '',
            credentialPassword: '',
            campaignEMRCredentialTypeID: ''
        });
        this.props.handleModalClose();
    }

    handleUserIDInput = (e) => {
        this.setState({ credentialUserID: e.target.value });
    }

    handlePasswordInput = (e) => {
        this.setState({ credentialPassword: e.target.value });
    }

    handleSaveBtnClick = () => {
        const { credentialUserID, credentialPassword, campaignEMRCredentialTypeID } = this.state;

        // :: TODO :: 
        // Add any additional validation requirements and show validation errors to user, etc

        if (!credentialUserID || !credentialPassword || !campaignEMRCredentialTypeID) {
            return;
        }

        this.props.saveNewEntry({ credentialUserID, credentialPassword, campaignEMRCredentialTypeID });
        this.handleModalClose();

    }
    

    render() {
        return (
            <div className='modal-content react-bs-table-insert-modal'>
                <Modal show={this.props.showModal} onHide={this.handleModalClose}>
                <Modal.Header closeButton>
                        <Modal.Title>
                            <b>Add Credential</b>
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body style={{ height: '340px' }}>
                        <Loader loaded={this.state.loaded}>
                            <Row>
                                <Col xs={8}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Credential Type</ControlLabel>
                                        <Select value={this.state.campaignEMRCredentialTypeID} options={this.props.options} onChange={this.handleSelectChange} />
                                     </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">User ID</ControlLabel>
                                        <FormControl type="text" value={this.state.credentialUserID} onChange={this.handleUserIDInput} />
                                    </FormGroup>
                                </Col>
                                <Col xs={8}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Password</ControlLabel>
                                        <FormControl type="text" value={this.state.credentialPassword} onChange={this.handlePasswordInput} />
                                    </FormGroup>
                                </Col>
                                <Col xs={1}>
                                    <div style={{ paddingTop: '2.4rem' }}>
                                        <Button onClick={this.handleSaveBtnClick}
                                            disabled={this.state.campaignEMRCredentialTypeID.length < 1 
                                                    || this.state.credentialUserID.length < 1 
                                                    || this.state.credentialPassword.length < 1}>
                                            Add
                                        </Button>
                                    </div>
                                </Col>
                            </Row> 
                         </Loader>
                    </Modal.Body>
                    <Modal.Footer />
                </Modal>
            </div>
        );
    }
}
import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';
import { Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader';
import '../../styles/Report.css';

class ProjectStatusReportChartModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showError: false,
            modalResults: [],
            activePage: 1,
            orderByDescending: false,
            orderByColumnName: null,
            loaded: true,
            pageSize: 10,
            chartData: [],
        }
        this.backgroundColors = ["#FFCE56", "#693d84", "#4bc0c0", "#ff9b00", "#36A2EB", "#FF6384", "#8470FF", "#2E8B57", "#BA55D3"];
    }

    // set showModal state to true
    open = () => {
        this.setState({ showModal: true, loaded: false });
        this.getProjectStatusModalRecords();
    }

    // set showModal state to false if 'cancel' or 'hide' selected
    close = () => {
        this.setState({ showModal: false, activePage: 1, pageSize: 10 });
    }

    //todo: refactor
    getProjectStatusModalRecords = (orderByColumnName, pageNumber, pageSize) => {
        const labels = [];
        const data = [];
        for (var item in this.props.reportData) {
            labels.push(this.props.reportData[item].statusDescription);
            data.push({name: this.props.reportData[item].statusDescription, value: this.props.reportData[item].statusCount});
        }
        
        this.setState({ chartData: data, loaded: true });
    }

    render() {
        return (
            <div style={{display: 'inline-block'}}>
                <Button bsStyle='primary' style={{ display: this.props.reportData.length === 0 ? 'none' : 'inline-block' }} onClick={this.open}>See Chart</Button>
                <Loader loaded={this.state.loaded}>
                    <Modal className="projectStatusReportModal" show={this.state.showModal} onHide={this.close}>
                        <Modal.Header closeButton>
                            <Modal.Title>Project Status Report Chart</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div>
                            <PieChart width={1000} height={600} margin={{left: 150}}>
                                <Legend verticalAlign="top"/>
                                <Tooltip/>
                                <Pie dataKey="value" data={this.state.chartData} outerRadius={250}>
                                    {
                                        this.state.chartData.map((entry, index) => <Cell key={index} fill={this.backgroundColors[index % this.backgroundColors.length]}/>)
                                    }
                                </Pie>
                            </PieChart>
                        </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </Loader>
            </div>
        );
    }
}

export default ProjectStatusReportChartModal;
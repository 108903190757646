import React from 'react';
import axios from 'axios';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { CDIAlertWorkflowStatus } from '../../../enums/CDIAlertWorkflowStatus';
import AlertModalAlertSearch from './AlertModalAlertSearch';
import AlertModalMemberSearchTab from './AlertModalMemberSearchTab';

class AlertModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            alertID: "",
            alertMetadata: {},
            createWorkflowDisabled: true,
            editOOAAlertDisabled: true,
            providerFullName: "",
            memberFullName: ""
        };
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    handleAlertIDChange = (e) => {
        this.setState({ alertID: e.target.value });
    }

    getWorkflows = () => {
        axios.get('/Alert', {
            params: {
                alertID: this.state.alertID,
                statusIDs: [CDIAlertWorkflowStatus.Created]
            }
        })
            .then(response => {
                if (response.data.items.length > 0) {
                    const warningMessage = response.data.items[0].practiceDesc ?
                        `An active alert workflow exists for this alert ID for practice ${response.data.items[0].practiceDesc}.`
                        : 'An active alert workflow exists for this alert ID.';
                    this.props.toast('warning', warningMessage, 'Error');
                }
            });
    }

    getAlertMetadata = () => {
        if (!isNaN(this.state.alertID)) {
            axios.get(`/cdialerts/?alertID=${this.state.alertID}`)
                .then(response => {
                    this.setState({
                        alertMetadata: response.data,
                        createWorkflowDisabled: false,
                        editOOAAlertDisabled: true,
                        providerFullName: response.data.providerLastName + ", " + response.data.providerFirstName,
                        memberFullName: response.data.memberLastName + ", " + response.data.memberFirstName
                    });

                    //check if workflows exist for selected alert ID in created status
                    this.getWorkflows();
                })
                .catch(error => {
                    this.setState({ alertMetadata: {} })
                    this.props.toast('error', 'Invalid alert ID', 'Error');
                });
        } else {
            axios.get(`/ooacdialert/?ooaAlertID=${this.state.alertID}`)
                .then(response => {
                    this.setState({
                        alertMetadata: response.data,
                        createWorkflowDisabled: false,
                        editOOAAlertDisabled: true,
                        providerFullName: response.data.providerLastName + ", " + response.data.providerFirstName,
                        memberFullName: response.data.memberLastName + ", " + response.data.memberFirstName
                    });
                })
                .catch(error => {
                    this.setState({ alertMetadata: {} })
                    this.props.toast('error', 'Invalid OOA alert ID', 'Error');
                });
        }
    }

    //create alert workflow for member based on most recent cdi alert entity entry
    handleAddMemberClick = (memberID) => {
        axios.get(`/cdialerts/${memberID}/cdiAlertEntity`)
            .then((response) => {
                response.data.providerKey = response.data.providerMasterID;
                this.setState({ alertMetadata: response.data });
                this.saveAlertData();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not find alert for selected member.', 'Error');
            })
    }

    saveAlertData = (callback) => {
        if (!isNaN(this.state.alertID)) {
            const alertWorkflowData = {
                providerMasterID: this.state.alertMetadata.providerKey,
                cdiAlertWorkflowStatusID: CDIAlertWorkflowStatus.Created,
                cdiAlertEntityID: this.state.alertMetadata.cdiAlertEntityID,
                altProviderFirstName: null,
                altProviderLastName: null,
                appointmentDate: null,
                deliveryDate: null,
                groupingID: null,
                appointmentProviderMasterID: null
            }

            axios.post('/cdialerts/', alertWorkflowData)
                .then((response) => {
                    window.location.href = `/cdi/${response.data.cdiAlertWorkflowID}/update?isPECAddAlert=true`;
                })
                .catch((error) => {
                    this.props.toast('error', 'Could not create alert workflow for selected member.', 'Error');
                });
        } else {
            window.location.href = `/cdi/ooa/${this.state.alertID}/update`;
        }
    }

    handleSelectTab = (selectedTab) => {
        this.setState({
            activeTab: selectedTab
        });
    }

    closeModal = () => {
        this.clearTextEntry();
        this.props.toggleModal();
    }

    clearTextEntry = () => {
        this.setState({
            alertID: "",
            createWorkflowDisabled: true,
            editOOAAlertDisabled: true,
            alertMetadata: {},
            providerFullName: "",
            memberFullName: "",
            memberResults: []
        });
    }

    render() {
        return (
            <Modal className="modal-wide" show={this.props.visible} onHide={this.closeModal} keyboard>
                <Modal.Body>
                    <Tabs id="alert-modal-tabs" activeKey={this.state.activeTab} onSelect={this.handleSelectTab}>
                        <Tab eventKey={1} title="Alert Search">
                            <AlertModalAlertSearch alertID={this.state.alertID} memberFullName={this.state.memberFullName}
                                alertMetadata={this.state.alertMetadata} providerFullName={this.state.providerFullName}
                                createWorkflowDisabled={this.state.createWorkflowDisabled} handleAlertIDChange={this.handleAlertIDChange}
                                clearTextEntry={this.clearTextEntry} getAlertMetadata={this.getAlertMetadata} saveAlertData={this.saveAlertData}
                                editOOAAlertDisabled={this.state.editOOAAlertDisabled} editOOAAlertData={this.editOOAAlertData} />
                        </Tab>
                        <Tab eventKey={2} title="Member Search">
                            <AlertModalMemberSearchTab handleAddMemberClick={this.handleAddMemberClick} toast={this.props.toast} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AlertModal;
import React from 'react';
import axios from 'axios';
import { Modal, Button, Row, Col, FormGroup, FormControl, ControlLabel, Radio, Checkbox, Glyphicon } from 'react-bootstrap';
import { dateOfServiceValidation, combinedPageNumValidations, requiredFieldObjectValidation } from '../../../../../utils/ValidationUtils';
import DatePicker from 'react-16-bootstrap-date-picker';
import ProviderSearchModal from '../../../../Shared/ProviderSearchModal';

class StarsEntryModal extends React.Component {
    constructor(props) {
        super(props);
        this.defaultStarsObj = {
            medicalRecordQualityCaptureID: '',
            measureID: '',
            serviceDescriptionID: '',
            providerID: '',
            medicalRecordImageID: '',
            dos: '',
            pages: '',
            pageNumFrom: '',
            pageNumTo: '',
            dynamicEntries: [],
            userID: '',
            serializationType:''
        };

        this.state = {
            starsObj: { ...this.defaultStarsObj },
            addAnother: false,
            showProviderModal: false,
            editSelected: false,
            editModalRowID: null,
            editModalRow: null,
            isQADisabled: false,
            serviceDescs: {
                descriptions: [],
                exclusions: []
            },
            validationErrorMessages: [],
            isAddEntryDisabled: false
        };

        this.validationFields = ['providerID', 'measureID', 'dos', 'serviceDescriptionID', 'pages'];
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            visible: nextProps.visible,
            editModalRowID: nextProps.editModalRowID ? nextProps.editModalRowID : null,
            // disable measure/service description fields if isQA and current user and initial user are different
            isQADisabled: nextProps.editMode && nextProps.userID !== nextProps.enteredByUserID && nextProps.isQA
        }, () => {
            this.initialSetup();
            if (this.state.editModalRowID) {
                this.getRecordData();
            }
            // if star measure has been selected from star profile container, update measure and service descriptions
            if (this.props.selectedStarMeasure) {
                this.handleMeasureChange(this.props.selectedStarMeasure.qualityMeasureID);
            }
        });
    }

    initialSetup = () => {
        // if review contains provider and user has not selected a different provider ID
        const defaultProviderNPI = this.getDefaultProviderNPI();
        // add -1 to image select dropdown
        this.setState({
            starsObj: {
                ...this.state.starsObj,
                medicalRecordReviewID: this.props.medicalRecordReviewID,
                userID: this.props.userID,
                providerID: defaultProviderNPI && !this.state.starsObj.providerID ? defaultProviderNPI : this.state.starsObj.providerID
            }
        })
    }

    getDefaultProviderNPI = () => {
        let defaultProviderNPI;
        if (this.props.mrrProviderNPIs) {
            if (this.props.mrrProviderNPIs.medicalRecordEntityNPI) {
                defaultProviderNPI = this.props.mrrProviderNPIs.medicalRecordEntityNPI;
            }
            else if (this.props.mrrProviderNPIs.medicalRecordNPI) {
                defaultProviderNPI = this.props.mrrProviderNPIs.medicalRecordNPI;
            }
            else if (this.props.mrrProviderNPIs.memberPCPNPI) {
                defaultProviderNPI = this.props.mrrProviderNPIs.memberPCPNPI;
            }
        }
        return defaultProviderNPI;
    }

    // retrieve previously added stars record data on edit
    getRecordData = () => {
        axios.get(`/medicalRecordQualityCapture/${this.state.editModalRowID}`)
        .then((response) => {
            this.setState({
                editModalRow: {
                    ...response.data,
                    measureID: this.props.measureID
                }
            }, () => {
                // update service descriptions based on record's measureID, then update dynamic fields
                this.updateServiceDescs(this.props.qualityMeasures.find(x => {
                    return x.id === parseInt(this.state.editModalRow.measureID, 10)
                }), () => this.updateDynamicFields({ id: this.state.editModalRow.serviceDescriptionID }));
                // populate form fields with record data
                this.populateEditFields();
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not retrieve stars record.', 'Error');
        })
    }

    // populate form fields on edit
    populateEditFields = () => {
        this.setState({
            starsObj: {
                ...this.state.starsObj,
                medicalRecordQualityCaptureID: this.state.editModalRow.medicalRecordQualityCaptureID,
                measureID: this.state.editModalRow.measureID,
                serviceDescriptionID: this.state.editModalRow.serviceDescriptionID,
                providerID: this.state.editModalRow.providerID,
                medicalRecordImageID: this.state.editModalRow.medicalRecordImageID,
                dos: this.state.editModalRow.dateOfServiceFrom,
                dateOfServiceFrom: new Date(this.state.editModalRow.dateOfServiceFrom),
                dateOfServiceTo: new Date(this.state.editModalRow.dateOfServiceTo),
                expectedVersion: this.state.editModalRow.expectedVersion,
                pages: this.state.editModalRow.pageNumFrom + '-' + this.state.editModalRow.pageNumTo,
                pageNumFrom: this.state.editModalRow.pageNumFrom,
                pageNumTo: this.state.editModalRow.pageNumTo,
                userID: this.props.userID,
                dynamicEntries: JSON.parse(this.state.editModalRow.dynamicCapturedData)
            }
        })
    }

    // on close of modal without save/edit, clear modal data
    handleModalToggle = (clearFields) => {
        this.props.handleModalToggle();
        if (clearFields) { // if true, set starsObj to default values and clear dynamic fields
            this.setState({
                starsObj: {
                    ...this.defaultStarsObj,
                    // if add (not edit), maintain providerID
                    providerID: !this.state.editModalRowID && this.state.starsObj ? this.state.starsObj.providerID : ''
                },
                dynamicFields: [],
                serviceDescs: {
                    descriptions: [],
                    exclusions: []
                },
                validationErrorMessages: [],
            });
        }
    }

    countDecimals = (str) => str ? str.split("").filter(i => i === ".").length : 0

    isValidInteger = (str) => {
        if (this.countDecimals(str) !== 0) {
            return false;
        }
        else if (parseInt(str, 10).toString() !== str) {
            return false;
        }
        return true;
    }

    isValidDecimal = (str) => {
        if (this.countDecimals(str) > 1) {
            return false;
        }
        else if (isNaN(str)) {
            return false;
        }
        return true;
    }

    isNegative = (str) => {
        const temp = parseFloat(str, 10);
        if (temp === str && temp < 0) {
            return true;
        }
        return false;
    }
    
    // validates all required fields and any dynamic fields
    validate = () => {
        let title=[];
        const errorArr = dateOfServiceValidation("DOS", "DOS", this.state.starsObj.dos, '', null, false, null, this.props.member.dob);
        
        errorArr.push(...requiredFieldObjectValidation(this.state.starsObj, 'providerID', 'invalidProviderID', 'providerID',
            'Provider ID/NPI'));

        errorArr.push(...requiredFieldObjectValidation(this.state.starsObj, 'measureID', 'invalidMeasureID', 'measureID',
            'Measure'));

        errorArr.push(...requiredFieldObjectValidation(this.state.starsObj, 'serviceDescriptionID', 'invalidServiceDescriptionID',
            'serviceDescriptionID', 'Service Description or Exclusions'));

        if (this.state.starsObj.pages) {
            errorArr.push(...combinedPageNumValidations(this.state.starsObj.pages));
        } 

        if (this.state.dynamicFields) {
            this.state.dynamicFields.forEach((item) => {
                const entry = this.state.starsObj.dynamicEntries.find(x => { return x.id === item.id; });
                if (!item.optional && entry && (!entry.value || (typeof (entry.value) === 'string' && entry.value.trim().length === 0))) {
                    errorArr.push({ id: `invalid${item.title}`, field: item.title, errorText: `${item.title} is a required field` });
                }
                else if (item.isNumeric && !item.decimalScale) {
                    if (!this.isValidInteger(entry.value)) {
                        errorArr.push({ id: `invalid${item.title}`, field: item.title, errorText: `${item.title} must be an integer` });
                    }
                    else if (this.isNegative(entry.value)) {
                        errorArr.push({ id: `invalid${item.title}`, field: item.title, errorText: `${item.title} cannot be a negative number` });
                    }
                }
                else if (item.isNumeric && item.decimalScale) {
                    if (!this.isValidDecimal(entry.value)) {
                        errorArr.push({ id: `invalid${item.title}`, field: item.title, errorText: `${item.title} must be a valid number` });
                    }
                    else if (this.isNegative(entry.value)) {
                        errorArr.push({ id: `invalid${item.title}`, field: item.title, errorText: `${item.title} cannot be a negative number` });
                    }
                 }
                 if(item.validateDate) {
                        title = title.concat(item.title);
                        if((entry.value || (typeof (entry.value) === 'string' && entry.value.trim().length > 0)))
                            errorArr.push(...dateOfServiceValidation("DOS", item.title, entry.value, '', null, false, null, this.props.member.dob));
                 }
            });
        }

        this.setState({ validationErrorMessages: errorArr });
        return errorArr && !errorArr.length > 0;
    }

    // measure helpers
    handleMeasureChange = (e) => {
        const measureID = e && e.target ? e.target.value : (e ? e : '');
        this.setState({
            starsObj: {
                ...this.state.starsObj,
                measureID: measureID,
                serviceDescriptionID: ''
            }
        }, () => {
            this.updateServiceDescs(this.props.qualityMeasures.find(x => {
                return x.id === parseInt(this.state.starsObj.measureID, 10)
            }), () => this.updateDynamicFields({ id: this.state.starsObj.serviceDescriptionID }, true));
        });
    }

    // service description/exclusions helpers
   mapServiceDescriptions = () => {
        return this.state.serviceDescs.descriptions && this.state.serviceDescs.descriptions.map(x => {
            return (
                <li key={x.id}>
                    <Radio id={x.id} checked={this.state.starsObj.serviceDescriptionID === x.id} disabled={this.state.isQADisabled}
                        name="selectedServiceDescription" className="starsRadio" onChange={() => this.updateDynamicFields(x, true)}>
                        {x.name}
                    </Radio>
                </li>
            )
        })
    }

    // update service description entries based on selected measureID
    updateServiceDescs = (data, updateDynamicFields) => {
        this.setState({ 
            serviceDescs: {
                exclusions: data && data.serviceDescriptions.filter(x => {
                    return x.isExclusion;
                }),
                descriptions: data && data.serviceDescriptions.filter(x => {
                    return !x.isExclusion;
                }).map(x => {
                    x.name = x.name.replace('Excluded - ', '');
                    return x;
                })
            }
        }, () => {
            // if measure has only one service description (radio), autoselect radio and update dynamic fields
            if (this.state.serviceDescs.descriptions && this.state.serviceDescs.descriptions.length === 1) {
                this.setState({
                    starsObj: {
                        ...this.state.starsObj,
                        serviceDescriptionID: this.state.serviceDescs.descriptions[0].id
                    }
                }, ()=> {
                    updateDynamicFields();
                })
            }
            // if edit mode, update dynamic fields after updating service descriptions
            else if (updateDynamicFields) {
                updateDynamicFields();
            }
        })
    }

    // dynamic field helpers
    mapDynamicFields = () => {
        return this.state.dynamicFields && this.state.dynamicFields.map(x => {
            return (
                <FormGroup key={x.id}>
                    <ControlLabel style={{ marginRight: '1rem' }} className={x.optional ? "" : "requiredField"}>{x.title}</ControlLabel>
                        {
                            x.isDropDown &&
                            <FormControl componentClass={'select'} placeholder="Select" value={this.state.starsObj.dynamicEntries.find(y => {return y.id === x.id}) ? 
                                this.state.starsObj.dynamicEntries.find(y => {return y.id === x.id}).value : ''}
                                onChange={(e) => this.handleDynamicFieldChange(e, x)}>
                                <option value="">Select</option>
                                {
                                    x.dynamicDropDownValues.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    })
                                }
                            </FormControl>
                        }
                        {
                            !x.isDropDown && !x.validateDate &&
                            <FormControl type="text" value={this.state.starsObj.dynamicEntries.find(y => {return y.id === x.id}) ? 
                                this.state.starsObj.dynamicEntries.find(y => {return y.id === x.id}).value : ''}
                                required={true} onChange={(e) => this.handleDynamicFieldChange(e, x)}/>
                        }
                        {
                            !x.isDropDown &&  x.validateDate &&
                                <DatePicker  value={this.state.starsObj.dynamicEntries.find(y => {return y.id === x.id}) ? 
                                this.state.starsObj.dynamicEntries.find(y => {return y.id === x.id}).value : ''} onChange={(e) => this.handleDynamicDateChange(e, x)} style={{ zIndex: 'auto' }}/>
                        }
                        {
                            x.message &&
                            <p style={{ marginRight: '1rem',color:'Red',fontSize:'10pt',backgroundColor:'none'}}>The difference between Urine Albumin Test and Creatinine Test should be 4 days or less apart.</p>
                        }
                </FormGroup>
            )
        })
    }

    handleDynamicFieldChange = (e, field) => {
        const value = e && e.target ? e.target.value : e;
        this.setState({
            starsObj: {
                ...this.state.starsObj,
                dynamicEntries: this.state.starsObj.dynamicEntries.map(x => {
                    if (x.id === field.id) {
                        x.value = value;
                    }
                    return x;
                })
            }
        }, () => {
            this.setState({
                starsObj: {
                    ...this.state.starsObj,
                    dynamicCapturedData: JSON.stringify(this.state.starsObj.dynamicEntries)
                }
            })
        });
    }

    handleDynamicDateChange =  (dateValue, field) => {
        this.setState({
            starsObj: {
                ...this.state.starsObj,
                dynamicEntries: this.state.starsObj.dynamicEntries.map(x => {
                    if (x.id === field.id) {
                        x.value = dateValue == null ? '': new Date(dateValue).toISOString().slice(0,10);
                    }
                    return x;
                })
            }
        }, () => {
            this.setState({
                starsObj: {
                    ...this.state.starsObj,
                    dynamicCapturedData: JSON.stringify(this.state.starsObj.dynamicEntries)
                }
            })
        });
    }

    // update dynamic field values and starsObj.dynamicEntries on 
    // initial serviceDescriptionID select or serviceDescriptionID change (if edit)
    updateDynamicFields = (e, isServiceDescriptionChanged, isExclusion = false) => {
        const serviceDescriptionID = e && e.target ? e.target.value : (e && e.id ? e.id : '');
        
        let dynamicFields = [];
        let dynamicEntries = [];
        // if serviceDescriptionID is not '' and descriptions and/or exclusions have been populated
        if (serviceDescriptionID &&
            ((this.state.serviceDescs && this.state.serviceDescs.descriptions && this.state.serviceDescs.descriptions.length > 0) || 
            (this.state.serviceDescs && this.state.serviceDescs.exclusions && this.state.serviceDescs.exclusions.length > 0))) {
            const descList = [...this.state.serviceDescs.descriptions].concat(...this.state.serviceDescs.exclusions);
            dynamicFields = descList.find(x => {
                return x.id === parseInt(serviceDescriptionID, 10)
            }).dynamicCaptureFields;
            dynamicEntries = dynamicFields && dynamicFields.map(x => {
                return { id: x.id, value: null, optional: x.optional }
            });
        }
        this.setState({ 
            starsObj: {
                ...this.state.starsObj,
                serviceDescriptionID: serviceDescriptionID,
                // if edit mode, populate these arrays with the record's dynamicCapturedData; 
                // else, populate with appropriate dynamic field ids and null values
                dynamicEntries: this.state.editModalRow && this.props.editMode && !isServiceDescriptionChanged ? JSON.parse(this.state.editModalRow.dynamicCapturedData) : (isExclusion ? [] : dynamicEntries),
                dynamicCapturedData: this.state.editModalRow && this.props.editMode && !isServiceDescriptionChanged ? this.state.editModalRow.dynamicCapturedData : (isExclusion ? null : JSON.stringify(dynamicEntries))
            },
            dynamicFields: dynamicFields
        })
    };

    //---------------- provider id/npi modal helpers -----------------//
    toggleProviderSearchModal = () => {
        this.setState({ 
            showProviderModal: !this.state.showProviderModal, 
            visible: this.state.showProviderModal 
        });
    }

    setSerializationType = (serviceDescriptionID) => {
        let serializationType ='';
        
        if (serviceDescriptionID &&
            ((this.state.serviceDescs && this.state.serviceDescs.descriptions && this.state.serviceDescs.descriptions.length > 0) || 
            (this.state.serviceDescs && this.state.serviceDescs.exclusions && this.state.serviceDescs.exclusions.length > 0))) {
            const descList = [...this.state.serviceDescs.descriptions].concat(...this.state.serviceDescs.exclusions);
           
            const dynamicFields = descList.some(x => {
                return x.id === parseInt(serviceDescriptionID, 10) && x.isDate === true
            });
            if(dynamicFields)
                serializationType= 'Ked' 
        }
        return serializationType;
    }

    // handle select of provider from provider lookup modals
    handleProviderSelect = (internalKey, providerID, providerNPI, lastName, firstName) => {
        // note: the relevant quality capture endpoints attempt to match this "providerID" property across providerID *and* NPI
        const providerSelection = providerID ? providerID : providerNPI;
        if (providerSelection) {
            this.setState({
                starsObj: {
                    ...this.state.starsObj,
                    providerID: providerSelection
                }
            });
        }
        else {
            this.props.toast('error', 'Could not select provider.', 'Error');
        }
    }

    handleProviderIDChange = (e) => {
        if (e.target) {
            this.setState({
                starsObj: {
                    ...this.state.starsObj,
                    providerID: e.target.value
                }
            });
        }
    }

    // ----------- image ID helpers ---------------//
    handleImageIDChange = (e) => {
        if (e.target) {
            this.setState({
                starsObj: {
                    ...this.state.starsObj,
                    medicalRecordImageID: e.target.value
                }
            });
        }
    }

    // --------------- DOS helpers ---------------- //
    handleDateChange = (dateValue) => {
        dateValue = dateValue ? dateValue : '';
        this.setState({
            starsObj: {
                ...this.state.starsObj,
                dos: dateValue,
                dateOfServiceFrom: new Date(dateValue),
                dateOfServiceTo: new Date(dateValue)
            }
        });
    }

    //--------------------- page change helpers ------------------------//
    formatPageNumbers = (pages) => {
        let pageFrom, pageTo;
    
        if (pages.indexOf('-') !== -1) {
            pageFrom = pages.split('-')[0];
            pageTo = pages.split('-')[1];
        }
        else {
            pageFrom = pages;
            pageTo = pages;
        }
        this.setState({ 
            starsObj: {
                ...this.state.starsObj,
                pages: pages,
                pageNumFrom: parseInt(pageFrom, 10),
                pageNumTo: parseInt(pageTo, 10)
            },
        });
    }
    
    handlePageChange = (e) => {
        if (e.target) {
            this.formatPageNumbers(e.target.value);
        }
    }
    
    // add another helpers
    handleAddAnother = () => {
        this.setState({ addAnother: !this.state.addAnother });
    }

    //todo: functionality commented-out -- remove this func if not used by additional changes
    parseStarsDynamicData = () => {
        return this.state.starsObj.dynamicEntries.map(x => {
            // const field = this.state.dynamicFields.find(y => y.id === x.id);
            // if (field) {
                // //string
                // if (!y.isNumeric)
                // {
                //     x.value = value;
                // }
                //positive ints and dropdown value IDs
                // if (field.dynamicDropDownValues.length > 0 || (field.isNumeric && !field.decimalScale)) //&& y.isNonNegativeNumber
                // {
                //     x.value = parseInt(x.value, 10);
                // }
                // //positive and negative ints
                // else if (y.isNumeric && !y.isNonNegativeNumber && !y.decimalScale)
                // {

                // }
                //positive decimals
                // else if (field.isNumeric && field.decimalScale) //&& y.isNonNegativeNumber
                // {
                //     x.value = parseFloat(x.value);
                // }
                //positive and negative decimals
                // else if (y.isNumeric && !y.isNonNegativeNumber && !y.decimalScale)
                // {

                // }
            // }
            return x;
         })
    }

    // add entry helpers
    saveRecord = () => {
        if (this.validate()) {
            if (this.state.starsObj.medicalRecordQualityCaptureID) {
                // edit stars record
                this.setState({
                    starsObj: {
                        ...this.state.starsObj,
                        dynamicEntries: this.parseStarsDynamicData(),
                        serializationType: this.setSerializationType(this.state.starsObj.serviceDescriptionID)
                    }
                }, () => {
                    this.setState({
                        starsObj: {
                            ...this.state.starsObj,
                            dynamicCapturedData: JSON.stringify(this.state.starsObj.dynamicEntries)
                        }
                    }, () => {
                        axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/MedicalRecordQualityCapture/${this.state.starsObj.medicalRecordQualityCaptureID}`, this.state.starsObj)
                        .then((response) => {
                            this.props.getStarsData();
                            this.props.getStarsHistoryData();
                            this.handleModalToggle(true);
                        })
                        .catch((error) => {
                            this.errorCallbackTasks(error.response.data);
                        });
                    })
                });
            }
            else {
                // add new stars record
                this.setState({
                    isAddEntryDisabled: true, // disable add entry button
                    starsObj: {
                        ...this.state.starsObj,
                        dynamicEntries: this.parseStarsDynamicData(),
                        serializationType: this.setSerializationType(this.state.starsObj.serviceDescriptionID)
                    }
                }, () => {
                    this.setState({
                        starsObj: {
                            ...this.state.starsObj,
                            dynamicCapturedData: JSON.stringify(this.state.starsObj.dynamicEntries)
                        }
                    }, () => {
                        return axios.post(`/medicalRecordReview/${this.props.medicalRecordReviewID}/MedicalRecordQualityCapture/`, this.state.starsObj)
                        .then((response) => {
                            this.props.getStarsData();
                            this.props.getStarsHistoryData();
                            // if addAnother is true, keep modal open and clear all fields but ProviderID
                            if (this.state.addAnother) {
                                this.setState({
                                    addAnother: false,
                                    starsObj: {
                                        ...this.defaultStarsObj,
                                        providerID: this.state.starsObj.providerID
                                    },
                                    serviceDescs: {
                                        descriptions: [],
                                        exclusions: []
                                    }
                                })
                            }
                            // if addAnother is false, clear fields and close form modal
                            else {
                                this.handleModalToggle(true);
                            }
                        })
                        .catch((error) => {
                            this.errorCallbackTasks(error.response.data);
                        })
                        .finally(() => {
                            this.setState({ // No matter what, enable add entry button again
                                isAddEntryDisabled: false
                            })
                        });
                    });
                })
            }
        }
    }
    
    // populate validationErrorMessages if call to save/submit fails
    errorCallbackTasks = (data) => {
        this.setState({
            validationErrorMessages: Object.keys(data.modelState).filter(x => !x.startsWith('qualityCaptureDTO'))
                .map(x => { return { id: 'invalid' + x , field: x , errorText: data.modelState[x][0] }; })
        });
    }

    renderValidationMessages = () => {
        if (this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0) {
            return (
                <Row>
                    <Col xs={12}>
                        <div style={{ textAlign: 'left' }}>
                            {
                                this.state.validationErrorMessages.map(error => {
                                    return <div key={error.id} className="errorMessage">{error.errorText}</div>
                                })
                            }
                            <br/>
                        </div>
                    </Col>
                </Row>
            );
        }
        else {
            return null;
        }
    }

    calculatePadding = () => {
        //todo: improve this formula
        return this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0 ? (18 - 2.2 * this.state.validationErrorMessages.length) + 'rem' : '18rem';
    }

    render() {
        return (
            <div>
                <Modal show={this.state.visible} className="diagFormModal" onHide={this.handleModalToggle} keyboard bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title><b>{this.props.editMode ? 'Edit' : 'Add'} Stars Entry</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: this.calculatePadding() }}>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Measure</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.starsObj.measureID}
                                        disabled={this.state.isQADisabled} onChange={this.handleMeasureChange}>
                                        <option value="">Select</option>
                                        {
                                            this.props.qualityMeasures &&
                                            this.props.qualityMeasures.sort((a, b) => {
                                                return a.name.localeCompare(b.name)
                                            }).map((item) => {
                                                return <option key={item.id} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={6}>
                                <ControlLabel className="requiredField">Service Descriptions</ControlLabel>
                                <ul style={{ listStyle: 'none' }}>
                                    {
                                        this.mapServiceDescriptions()
                                    }
                                </ul>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }}>Exclusions</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.starsObj.serviceDescriptionID}
                                        onChange={(e) => this.updateDynamicFields(e, true, true)} disabled={this.state.isQADisabled}>
                                        <option value="">Select</option>
                                        {
                                            this.state.serviceDescs && this.state.serviceDescs.exclusions &&
                                            this.state.serviceDescs.exclusions.map((item) => {
                                                return <option key={item.id} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={6}>
                                {
                                    this.mapDynamicFields()
                                }
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Provider ID/NPI
                                        <Button bsSize="small" style={{ marginLeft: '1rem' }} onClick={() => this.toggleProviderSearchModal()}>
                                            <Glyphicon glyph="search"/>
                                        </Button>
                                    </ControlLabel>
                                    <FormControl type="text" value={this.state.starsObj.providerID} placeholder="Provider ID/NPI" required={true}
                                        onChange={this.handleProviderIDChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel style={{ arginRight: '1rem' }}>Image ID</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.starsObj.medicalRecordImageID ? 
                                        this.state.starsObj.medicalRecordImageID : ''} onChange={this.handleImageIDChange}
                                        style={{ marginTop: '10px' }}>
                                        <option value="">Select</option>
                                        {
                                            this.props.imageRecords &&
                                            this.props.imageRecords.map((item) => {
                                                return <option key={item.id} value={item.id}>{item.title}</option>
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">DOS</ControlLabel>
                                    <DatePicker value={this.state.starsObj.dos} onChange={this.handleDateChange} style={{ zIndex: 'auto' }}/>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }}>Pages</ControlLabel>
                                    <FormControl type="text" value={this.state.starsObj.pages} onChange={this.handlePageChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            this.renderValidationMessages()
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ float: 'left' }}>
                            <Button bsStyle="primary" onClick={this.saveRecord} disabled={this.state.isAddEntryDisabled}>
                                { this.props.editMode ? 'Save Changes' : 'Add Entry' }
                            </Button>
                            <Button bsStyle="primary" onClick={() => this.handleModalToggle(true)}>
                                Cancel
                            </Button>
                        </div>
                        <div style={{ float: 'right' }}>
                            {
                                !this.props.editMode &&
                                <FormGroup>
                                    <Checkbox inline checked={this.state.addAnother} onChange={this.handleAddAnother}><b>Create Another</b></Checkbox>
                                </FormGroup>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
                <ProviderSearchModal visible={this.state.showProviderModal} handleModalToggle={this.toggleProviderSearchModal} handleSelectSearchResult={this.handleProviderSelect}
                    memberID={this.props.memberID} toast={this.props.toast} />
            </div>
        );
    }
}

export default StarsEntryModal;
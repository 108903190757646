import { connect } from 'react-redux';
import { setDiagFormData, setDiagIDList, setClaimsDiagInitiatorIDList, setTriggerDiagRefresh } from "../../../../redux/actions/index";
import NLPInsightsByHCC from './NLPInsightsByHCC';

const mapStateToProps = state => ({
    diags: state.diags
});

const mapDispatchToProps = dispatch => ({
    setDiagFormData: diagFormData => dispatch(setDiagFormData(diagFormData)),
    setDiagIDList: diagIDList => dispatch(setDiagIDList(diagIDList)),
    setClaimsDiagInitiatorIDList: claimsDiagInitiatorIDList => dispatch(setClaimsDiagInitiatorIDList(claimsDiagInitiatorIDList)),
    setTriggerDiagRefresh: triggerDiagRefresh => dispatch(setTriggerDiagRefresh(triggerDiagRefresh))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NLPInsightsByHCC);
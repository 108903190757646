import React from 'react';
import { Button, Checkbox, Glyphicon, Modal } from 'react-bootstrap';

class ReprintModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includeHeaders: false
        };
    }

    handleIncludeHeadersChange = (e) => {
        this.setState({ includeHeaders: e.target.checked });
    }

    render() {
        return (
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle} className="modal-wide" keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Reprint Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Checkbox onChange={this.handleIncludeHeadersChange} checked={this.state.includeHeaders}>
                        Include Headers
                    </Checkbox>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="success" onClick={() => this.props.handleReprintAlert(this.state.includeHeaders)}
                        style={{ float: 'left' }}>
                            <Glyphicon glyph="print" style={{ marginRight: '0.6rem' }} />Reprint
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ReprintModal;
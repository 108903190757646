import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormGroup, Modal, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import Select from 'react-select';

export default class PracticeSummaryBulkUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceStatus: '',
            retrievalMethod: '',
            practiceGrouping: '',
            accountAdmin: '',
            selectedPracticeIDs: [],
            selectedPracticeDisplay: '',
            loaded: true
        }

        this.codeDisplayCountConfig = 5; //determines how many selected practice codes will render in the header
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.practices !== this.props.practices) {
            const selectedPracticeIDs = this.props.getSelected(nextProps.practices);
            this.setState({
                selectedPracticeDisplay: this.getPracticeIDDisplayString(nextProps.practices, selectedPracticeIDs),
                selectedPracticeIDs
            });
        }
    }

    handleBulkUpdate = () => {
        const selectedPracticeCount = this.state.selectedPracticeIDs?.length;

        this.setState({ loaded: false });
        if (selectedPracticeCount > 0) {
            axios.put('/campaignPracticeSummary', {
                campaignPracticeIDs: this.state.selectedPracticeIDs,
                practiceStatusID: this.state.practiceStatus,
                campaignRetrievalMethodID: this.state.retrievalMethod,
                practiceGroupingID: this.state.practiceGrouping,
                accountAdminUserID: this.state.accountAdmin,
            })
            .then((response) => {
                this.setState({
                    practiceStatus: '',
                    retrievalMethod: '',
                    practiceGrouping: '',
                    accountAdmin: '',
                    selectedPracticeIDs: [],
                    loaded: true
                });
                this.props.toggleBulkUpdateModal(true);
                this.props.toast('success', `${selectedPracticeCount} practices updated.`, 'Success');
            })
            .catch((error) => {
                this.setState({ loaded: true });
                this.props.toast('error', 'Failed to update practices.', 'Error');
            });
        }
    }

    handlePracticeStatusChange = (e) => {
        this.setState({ practiceStatus: e ? e.value : '' });
    }

    handleRetrievalMethodChange = (e) => {
        this.setState({ retrievalMethod: e ? e.value : '' });
    }

    handlePracticeGroupingChange = (e) => {
        this.setState({ practiceGrouping: e ? e.value : '' });
    }

    handleAccountAdminChange = (e) => {
        this.setState({ accountAdmin: e ? e.value : '' });
    }

    addClearDropdownOption = (dropdownOptionArray) => {
        return [
            { label: '(Clear saved value)', value: 0 },
            ...dropdownOptionArray
        ];
    }

    // get the concatenated practice codes for up to n selected practices
    getPracticeIDDisplayString = (practices, selectedPracticeIDs) => {
        let displayString = '';
        if (practices?.length > 0 && selectedPracticeIDs?.length > 0) {
            displayString = selectedPracticeIDs.slice(0, this.codeDisplayCountConfig).reduce((acc, cur, index) => {
                // get practice code desc. string for current selectedPracticeID
                let curPracticeCode = practices.find(x => x.campaignPracticeID === cur)?.practiceCode;
                if (!curPracticeCode) {
                    curPracticeCode = 'N/A'
                }

                // end case
                if (index >= this.codeDisplayCountConfig) {
                    return acc;
                }
                // single & first item cases
                else if (index === 0) {
                    if (selectedPracticeIDs.length === 1) {
                        return `(${curPracticeCode})`;
                    }
                    else {
                       return `(${curPracticeCode},`
                    }
                }
                // final item cases (separate cases for 2-5 items and > 5 items)
                else if (index === selectedPracticeIDs.length - 1 || index === this.codeDisplayCountConfig - 1) {
                    if (selectedPracticeIDs.length > this.codeDisplayCountConfig) {
                        return `${acc} ${curPracticeCode}, ...)`;
                    }
                    else {
                        return `${acc} ${curPracticeCode})`;
                    }
                }
                else {
                    return `${acc} ${curPracticeCode},`;
                }
            }, '');
        }

        return displayString;
    }

    render() {
        const selectedPracticeCount = this.state.selectedPracticeIDs?.length;
        return (
            <Modal show={this.props.showBulkUpdateModal} onHide={() => this.props.toggleBulkUpdateModal(false)} className="modal-wide" keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Update - {selectedPracticeCount} {selectedPracticeCount === 1 ? 'practice' : 'practices'} selected
                        {` ${this.state.selectedPracticeDisplay}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '300px' }}>
                    <Row>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>Status</ControlLabel>
                                <Select value={this.state.practiceStatus} options={this.props.practiceStatusOptions}
                                    onChange={this.handlePracticeStatusChange} />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>Retrieval Method</ControlLabel>
                                <Select value={this.state.retrievalMethod}
                                    options={this.addClearDropdownOption(this.props.retrievalMethodOptions)}
                                    onChange={this.handleRetrievalMethodChange} />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Practice Grouping</ControlLabel>
                                <Select value={this.state.practiceGrouping}
                                    options={this.addClearDropdownOption(this.props.practiceGroupingOptions)}
                                    onChange={this.handlePracticeGroupingChange} />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Account Admin</ControlLabel>
                                <Select value={this.state.accountAdmin}
                                    options={this.addClearDropdownOption(this.props.accountAdminOptions)}
                                    onChange={this.handleAccountAdminChange} />
                            </FormGroup>
                        </Col>
                        <Col xs={1} style={{ paddingLeft: 0 }}>
                            <Loader loaded={this.state.loaded}>
                                <FormGroup>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <Button onClick={this.handleBulkUpdate}
                                        disabled={selectedPracticeCount <= 0 || !this.state.loaded}>
                                        Update All
                                    </Button>
                                </FormGroup>
                            </Loader>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer />
            </Modal>
        )
    }
}
import React from 'react';

class MemberDemographicChart extends React.Component {
    render() {
        return (
            this.props.chart &&
            <div style={{ marginTop: '4rem' }}>
                <img src={`data:image/png;base64,${this.props.chart}`} alt="Member Demographic Chart" style={{ minWidth: '500px' }}/>
            </div>
        );
    }
}

export default MemberDemographicChart;
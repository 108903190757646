import React from 'react';
import axios from 'axios';
import { PDFLinkService, PDFFindController, PDFViewer, EventBus } from 'pdfjs-dist/web/pdf_viewer';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import Loader from 'react-loader';
import 'pdfjs-dist/web/pdf_viewer.css';

class NLPImagePDFNewWindow extends React.Component {
    constructor(props) {
        console.log('NLPImagePDFNewWindow loaded');
        super(props);
        this.state = {
            imageData: null,
            imageType: null,
            imageLoading: false,
            showImageError: false,
            data: null,
            pdfViewer: null,
            isInsightsPoppedOut: false,
            medicalRecordImageId:0
        };
        this.pdfUrlsExisted = [];
    }

    componentDidMount(){
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'popoutPageNumber' && event.newValue) {
              // Do something with event.newValue
              this.navigateToPage(Number(event.newValue));
            }
        });
        this.getNlpInsights();
    }

    navigateToPage = (pageNum) => {
        if(!isNaN(pageNum)){
            this.state.pdfViewer.currentPageNumber = pageNum;
        }
    }

    getNlpInsights = () => {
        axios.get(`/mrrnlpapi/v1/FilterdMedicalRecordImageInsight/${this.props.location.query.medicalRecordReviewID}`) //new nlp insights data
        .then((response) => {    
            if(response.status === 200 && response.data.length > 0){  
                this.setState({                        
                    medicalRecordImageId: response.data[0].medicalRecordImageId,
                });
            }
            this.displayImage();
        })
        .catch((error) => {
            console.log("NLPInsights-getNlpInsights " + error);
        });
    }

    displayImage = () => {
        this.setState({ imageLoading: true });
    
        axios.get(`/medicalrecordreview/${this.props.location.query.medicalRecordReviewID}/image`, {
            params: {},
            responseType: 'arraybuffer'
        })
        .then((response) => {
            if(response.status === 200){
                const imageType = "application/pdf";
                const fileUrl = URL.createObjectURL(new Blob([response.data], { type: imageType } ))
                const imageId = this.props.location.query.medicalRecordImageId;
                this.setState({
                    imageData: fileUrl,
                    showImageError: false,
                    imageLoading: false,
                    imageType: imageType,
                    data: response.data
                }, () => {
                    this.initialViewer(fileUrl);
                    this.pdfUrlsExisted.push({"imageId": imageId, "fileUrl": fileUrl});
                }
                );
            }
            else{
                this.setState({
                    showImageError: true,
                    imageLoading: false
                });
            }
        })
        .catch((error) => {
            console.log("NLPInsights-displayImage " + error);
            this.setState({
                showImageError: true,
                imageLoading: false
            });
        });
    }


    initialViewer = (url) => {

        const linkService = new PDFLinkService();
        const eventBus = new EventBus();
        const findController = new PDFFindController({linkService, eventBus});

        const viewerContainer = document.getElementById("viewerContainer");
        const newViewer = new PDFViewer({
            container: viewerContainer,
            linkService,
            useOnlyCssZoom: true,
            textLayerMode: 1,
            findController,
            eventBus
        });
        linkService.setViewer(newViewer);

        newViewer.currentScaleValue = 1.5;


        const loadingTask = pdfjs.getDocument({ url });
        loadingTask.promise.then(pdf => {
            if(pdf){
                const nums = pdf.numPages
                newViewer.setDocument(pdf);
                linkService.setDocument(pdf);
                this.setState({ pdfViewer:newViewer });
            }
        });
    }




    render() {
        return (
            <div style={{ maxHeight: `600px`, overflowY: 'scroll', overflowX: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>
                <Loader loaded={!this.state.imageLoading}>
                {
                    !this.state.showImageError && this.state.imageData && this.state.imageType === "text/html" &&
                    <embed width="100%" src={this.state.imageData} type={this.state.imageType} />
                }
                {
                    !this.state.showImageError && this.state.imageData && this.state.imageType === "application/pdf" &&
                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}>
                        <div id="viewerContainer" className="viewerContainer">
                            <div className="pdfViewer" id="innerContainer" style={{backgroundColor: 'lightgray'}}/>
                        </div>
                    </Worker>    
                }
                </Loader>
            </div>
        );
    }
}

export default NLPImagePDFNewWindow;

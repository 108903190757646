import React from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import EnrolleeReportFilter from './EnrolleeReportFilter'
import '../../styles/Report.css'

class EnrolleeReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: [],
            maxHCCs: null
        }
    }

    handleSearchAction = (params) => {
        if (this.props.projectID) {
            let queryStr = `/RADVEnrolleeReport?projectID=${this.props.projectID}`;

            if (params.codingInProgress) {
                queryStr += '&radvAuditHCCStatusIDs[]=1';
            }
            if (params.submittedToCMS) {
                queryStr += '&radvAuditHCCStatusIDs[]=2';
            }
            if (params.cmsAccepted) {
                queryStr += '&radvAuditHCCStatusIDs[]=4';
            }
            if (params.cmsDenied) {
                queryStr += '&radvAuditHCCStatusIDs[]=5';
            }
            if (params.interimDenied) {
                queryStr += '&radvAuditHCCStatusIDs[]=6';
            }
            if (params.mdFlag) {
                queryStr += '&showMDReviewRecords=true' + params.mdFlag;
            }
            if (params.chaseFlag) {
                queryStr += '&showAlternateChaseRecords=true' + params.chaseFlag
            }
            if (params.enrolleeID) {
                queryStr += '&enrolleeID=' + params.enrolleeID;
            }

            axios.get(queryStr)
            .then((response) => {
                this.setState({
                    reportData: response.data.reportData,
                    maxHCCs: response.data.maxHCCs
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to load search results.');
            });
        }
    }

    getHCCRows = () => {
        return this.state.maxHCCs > 0 ? this.state.reportData.map((row, i) => {
            const hccCells = Array(this.state.maxHCCs).fill(i).map((x, index) => {
                if (index < row.radvEnrolleeReportHCCs.length) {
                    return (
                        <td key={`radvEnrolleeReportHCCs-${index}`} style={{ fontWeight: "bold" }} className={"hccCell" + row.radvEnrolleeReportHCCs[index].radvAuditHCCStatusID}>
                            {row.radvEnrolleeReportHCCs[index].hccVersionAndNumber}
                        </td>
                    )
                }
                else {
                    return (<td key={`radvEnrolleeReportHCCs-${index}`}></td>);
                }
            });

            return (
                <tr key={i}>
                    <td>{row.enrolleeID}</td>
                    <td>{row.firstName} {row.lastName}</td>
                    {hccCells}
                    <td>{row.mdReview}</td>
                    <td>{row.alternateChaseReason}</td>
                </tr>
            );
        }) : null;
    }

    render() {
        return (
            <div style={{ display: "block", clear: "both" }}>
                <br/>
                <EnrolleeReportFilter projectID={this.props.projectID} searchAction={this.handleSearchAction} />
                <Table bordered>
                    <thead>
                        <tr>
                            <th colSpan={this.state.maxHCCs + 4} className="text-center" style={{ background: "#efefef" }}>
                                RADV Enrollee Report
                            </th>
                        </tr>
                        <tr>
                            <th>Enrollee ID</th>
                            <th>Enrollee Name</th>
                            {
                                this.state.maxHCCs &&
                                Array(this.state.maxHCCs).map((x, index) => {
                                    return <th key={`radvEnrolleeReportHCCsHeader-${index}`}>HCC {index+1}</th>
                                })
                            }
                            <th>MD Review</th>
                            <th style={{ width: '25%' }}>Alternate Chase Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getHCCRows()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default EnrolleeReport;
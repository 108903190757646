import React from 'react';
import { Col, Modal, Radio } from 'react-bootstrap';

class RADVModal extends React.Component {

    mapRADVScores = () => {
        if (this.props.radvScores && this.props.radvScores.length > 0 && this.props.row) {
            return this.props.radvScores.map(x => {
                return (
                    <li key={x.radvScoreID}>
                        <Radio id={x.radvScoreID} checked={this.props.row.radvScore && this.props.row.radvScore.radvScoreID === x.radvScoreID} 
                            name="selectedRADVScores" className="radvRadio" disabled={true}>
                            {x.description}
                        </Radio>
                    </li>
                )
            })
        }
    }

    mapHCCHierarchies = () => {
        if (this.props.hccHierarchies && this.props.hccHierarchies.length > 0 && this.props.row) {
            return this.props.hccHierarchies.map(x => {
                return (
                    <li key={x.hccHierarchyID}>
                        <Radio id={x.hccHierarchyID} checked={this.props.row.hccHierarchy && this.props.row.hccHierarchy.hccHierarchyID === x.hccHierarchyID} 
                            name="selectedHCCHierarchy" className="hccHierarchyRadio" disabled={true}>
                            {x.description}
                        </Radio>
                    </li>
                )
            })
        }
    }

    render() {
        const relatedHCCTitle = this.props.row && this.props.row.hccHierarchy && this.props.row.hccHierarchy.description !== "Non-Related" ? "Related Targeted HCC" : "Occurs on same DOS as";
        return (
            <Modal dialogClassName="radvModal" show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>RADV Score</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: '200px'}}>
                    <Col xs={4}>
                        <h5>Description</h5>
                        <ul style={{listStyle: 'none', padding:'0'}}>
                            {
                                this.mapRADVScores()
                            }
                        </ul>
                    </Col>
                    <Col xs={4}>
                        <h5>Hierarchy</h5>
                        <ul style={{listStyle: 'none', padding:'0'}}>
                            {
                                this.mapHCCHierarchies()
                            }
                        </ul>
                    </Col>
                    <Col xs={4}>
                        <h5>{ relatedHCCTitle }</h5>
                        <span>{ this.props.row && this.props.row.hccNumbersForRADVDetail }</span>
                    </Col>
                </Modal.Body>
            </Modal>
        );
    }
}

export default RADVModal;
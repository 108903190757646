import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';
import {formatDatetimeStringWithPrefixZero} from '../../../utils/DateUtils';
import FileViewModal from "../FileViewModal"

class Attachments extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showFileViewModal: false,
            filePath:'',
            fileName:''
        };
       
        this.columnHeaders = [
            ['ATTACHMENT'],
            ['DATE'],
            ['TYPE'],
            ['PRACTICE'],
            ['PRACTICE NPI'],
            ['PROVIDER'],
            ['PROVIDER NPI']
        ];
        
    }

    openFile = (url, fileName) => {
        this.setState({filePath: url, fileName: fileName});
        this.toggleFileViewModal();
    }

    toggleFileViewModal = () => {
        this.setState({ showFileViewModal: !this.state.showFileViewModal });
    }

    attachmentsData = () =>{
        return ( 
            
            this.props.memberLoaded && !isEmpty(this.props.data) ?
            <>
            <Table responsive>
            <thead>
                <tr>
                    {
                        this.columnHeaders.map((x, index) => {
                            return (
                            <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    this.props.data.map((x, index) => {
                      
                        return (                                     
                            <tr key={index}>
                                <td className='width10'><a onClick={() => this.openFile(`${x.attachment_filepath}/${x.attachment_name}`, `${x.attachment_name}`)} style={{ cursor: 'pointer' }} >{x.attachment_name}</a></td>
                                <td className='width15'>{formatDatetimeStringWithPrefixZero(x.creation_date)}</td>
                                <td className='width15'>{x.attachment_type}</td>
                                <td className='width20'>{x.practice_name}</td>
                                <td className='width15'>{x.practice_id !== '0' ? x.practice_id : ''}</td>
                                <td className='width10'>{x.provider_name}</td>
                                <td className='width15'>{x.provider_id !== '0' ? x.provider_id : ''}</td>
                            </tr>                                    
                        )
                    })
                }
            </tbody>
        </Table> <FileViewModal visible={this.state.showFileViewModal} handleModalToggle={this.toggleFileViewModal} filePath={this.state.filePath} fileName = {this.state.fileName} title=''/> </> : <NoData />  
        
        )
    }

    render() {
              
        return (
            <Panel id='attachments'>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Attachments
                    </div>
                    {this.attachmentsData()}                     
                </Panel.Body>
            </Panel>
        )
    }
}

export default Attachments;
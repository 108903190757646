import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortDate } from '../../../utils/SortUtils';

class ProspectiveTable extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.clearProspectiveTableSelected) {
            //wipe react-bootstrap-table's internal 'selected' state. ideally, the lib would do this for us, but here we are...
            this.refs.table.cleanSelected();
            this.props.setClearProspectiveTableSelected();
        }
    }

    onRowSelect = (row, isSelected) => {
        this.props.onRowSelect(row.medicalRecordEntityID, isSelected);
    }

    onSelectAll = (isSelected, rows) => {
        this.props.onSelectAll(rows.map(x => x.medicalRecordEntityID), isSelected);
    }

    //todo: a reference table should be created for MRREntityType and descriptions
    // from that table should be used instead of this
    formatEntityType = (cell) => {
        if (cell === 'A') {
            return 'CDI Alert Created';
        }
        else if (cell === 'C') {
            return 'CDI Alert Initial Response';
        }
        if (cell === 'M') {
            return 'Medical Record';
        }
        else if (cell === 'Q') {
            return 'CDI Alery Query Response';
        }
        else if (cell === 'Y') {
            return 'CDI Alert Query Created';
        }
        else {
            return '';
        }
    }

    formatMemberID = (cell) => {
        return this.props.planMemberID;
    }

    formatMemberName = () => {
        return this.props.firstName && this.props.lastName ? `${this.props.firstName} ${this.props.lastName}` : '';
    }

    formatProviderName = (cell, row) => {
        return row && row.providers ?
            row.providers.firstName ? `${row.providers.firstName} ${row.providers.lastName}` : `${row.providers.lastName}`
            : '';
    }

    formatProviderID = (cell, row) => {
        return row && row.providers ? row.providers.providerID : '';
    }

    formatNPI = (cell, row) => {
        return row && row.providers ? row.providers.providerNPI : '';
    }

    providerSortFunc = (a, b, order, sortField) => {
        if(!a.providers){
            return 1;
        }
        if(!b.providers){
            return -1;
        }
        if(isNaN(a.providers[sortField]) && isNaN(b.providers[sortField])){
            return order === 'asc' ? a.providers[sortField].localeCompare(b.providers[sortField]) : -a.providers[sortField].localeCompare(b.providers[sortField]); 
        }

        return order === 'asc' ? a.providers[sortField] - b.providers[sortField] : b.providers[sortField] - a.providers[sortField];
    }

    dateSortFunc = (a, b, order, dataField) => {
        return sortDate(a[dataField], b[dataField], order);
    }

    render() {
        const selectRowProp = {
            mode: 'checkbox',
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll
        };
        const options = {
            sortName: "dateOfServiceFrom",
            sortOrder: "desc",
        };

        return (
            <div style={{ marginTop: '4rem' }}>
                <BootstrapTable ref="table" selectRow={selectRowProp} data={this.props.memberMedicalRecords} options={options} pagination>          
                    <TableHeaderColumn dataField="medicalRecordEntityID" isKey hidden />
                    <TableHeaderColumn dataField="memberID" dataSort dataFormat={this.formatMemberID}>Member ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberName" dataSort dataFormat={this.formatMemberName}>Member Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="entityTypeCD" dataSort dataFormat={this.formatEntityType}>Record Entity Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerID" dataSort dataFormat={this.formatProviderID} sortFunc={this.providerSortFunc}>Provider ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerNPI" dataSort dataFormat={this.formatNPI} sortFunc={this.providerSortFunc}>NPI</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerName" dataSort dataFormat={this.formatProviderName} sortFunc={this.providerSortFunc} >Provider Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceFrom" dataSort sortFunc={this.dateSortFunc}>Date of Service</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceThru" dataSort sortFunc={this.dateSortFunc}>Date of Service Thru</TableHeaderColumn>               
                </BootstrapTable>
            </div>
        );
    }
}

export default ProspectiveTable;
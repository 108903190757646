import React from 'react';
import { Glyphicon } from 'react-bootstrap';

const WorkflowSectionHeader = (props) => {
    return (
        <div onClick={() => props.toggle(props.toggleKey)} style={{ cursor: 'pointer' }}>
            <h4 >
                {props.title}
                <Glyphicon glyph={props.expanded ? "minus" : "plus"}
                    style={{ color: 'rgb(108, 113, 108)', float: 'right', top: '2px' }} />
            </h4>
            <hr />
        </div>
    );
}

export default WorkflowSectionHeader;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';

export default class StarsReasonCodeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reasonCodeTypes: [],
            reasonCodes: [],
            selectedMeasure: '',
            selectedReasonCodeType: ''
        }

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillMount() {
        this.getReasonCodeTypes();
        this.getReasonCodes();
    }

    getReasonCodeTypes = () => {
        axios.get('/stars/reasoncodetypes', {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ reasonCodeTypes: response.data });
        })
        .catch((error) =>{
            this.props.toast('error', 'Could not load stars reason code types', 'Error');
        });
    }

    getReasonCodes = () => {
        axios.get('/stars/reasoncodes', {
            params: {
                mrrID: this.props.medicalRecordReviewID
            },
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ reasonCodes: response.data });
        })
        .catch((error) =>{
            this.props.toast('error', 'Could not load stars reason codes', 'Error');
        });
    }

    handleAddReasonCode = () => {
        axios.post('/stars/reasoncodes', {
            medicalRecordReviewID: this.props.medicalRecordReviewID,
            qualityMeasureID: this.state.selectedMeasure,
            starsReasonCodeTypeID: this.state.selectedReasonCodeType
        })
        .then((response) => {
            this.setState({ reasonCodes: [ ...this.state.reasonCodes, response.data ] });
        })
        .catch((error) =>{
            this.props.toast('error', 'Could not create reason code', 'Error');
        });
    }

    handleDeleteReasonCode = (row) => {
        axios.delete(`/stars/reasoncodes/${row.starsReasonCodeID}`)
        .then((response) => {
            this.setState({ reasonCodes:
                this.state.reasonCodes.filter(x => x.starsReasonCodeID !== row.starsReasonCodeID)
            });
        })
        .catch((error) =>{
            this.props.toast('error', 'Could not delete reason code', 'Error');
        });
    }

    handleSelectMeasure = (e) => {
        this.setState({ selectedMeasure: e ? e.value : '' });
    }

    handleSelectReasonCodeType = (e) => {
        this.setState({ selectedReasonCodeType: e ? e.value : '' });
    }

    //todo: do service description values need to be used?
    getQualityMeasureOptions = () => {
        return this.props.qualityMeasures.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });
    }

    getReasonCodeTypeOptions = () => {
        return this.state.reasonCodeTypes.map(x => {
            return {
                value: x.starsReasonCodeTypeID,
                label: x.starsReasonCodeTypeDescription
            };
        });
    }

    formatQualityMeasure = (val) => {
        const measure = this.props.qualityMeasures.find(x => x.id === val);
        return measure ? measure.name : '';
    }

    formatReasonCodeType = (val) => {
        const reasonCodeType = this.state.reasonCodeTypes.find(x => x.starsReasonCodeTypeID === val);
        return reasonCodeType ? reasonCodeType.starsReasonCodeTypeDescription : '';
    }

    formatDelete = (cell, row) => {
        return (
            <div>
                <Button onClick={() => this.handleDeleteReasonCode(row)}>
                    <Glyphicon glyph="trash" />
                </Button>
            </div>
        )
    }

    convertDatetimeString = (datetime) => {
        const date = new Date(datetime);
        return `${date.toLocaleDateString('en-us')} ${date.toLocaleTimeString('en-US', { timeStyle: 'short' })}`;
    }

    render() {
        return this.props.qualityMeasures && this.state.reasonCodeTypes ? (
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle} keyboard bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title><b>Stars Reason Codes</b></Modal.Title>
                </Modal.Header>
                    <Modal.Body style={{ minHeight: '420px' }}>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>Measure</ControlLabel>
                                    <Select options={this.getQualityMeasureOptions()}
                                        value={this.state.selectedMeasure}
                                        onChange={this.handleSelectMeasure} />
                                </FormGroup>
                            </Col>
                            <Col xs={7}>
                                <FormGroup>
                                    <ControlLabel>Reason Code</ControlLabel>
                                    <Select options={this.getReasonCodeTypeOptions()}
                                        value={this.state.selectedReasonCodeType}
                                        onChange={this.handleSelectReasonCodeType} />
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <Button onClick={this.handleAddReasonCode} style={{ display: 'block' }}>
                                        <Glyphicon glyph="plus" />
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <hr/>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <BootstrapTable data={this.state.reasonCodes}>
                                    <TableHeaderColumn dataField="starsReasonCodeID" isKey hidden>ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="qualityMeasureID" dataFormat={this.formatQualityMeasure} width="10%">Measure</TableHeaderColumn>
                                    <TableHeaderColumn dataField="starsReasonCodeTypeID" dataFormat={this.formatReasonCodeType} width="51%">Reason Code</TableHeaderColumn>
                                    <TableHeaderColumn dataField="updatedUsername" width="25%">Updated By</TableHeaderColumn>
                                    <TableHeaderColumn dataField="updatedDatetime" dataFormat={this.convertDatetimeString} width="18%">Updated On</TableHeaderColumn>
                                    <TableHeaderColumn dataField="" dataFormat={this.formatDelete} hidden={this.props.isComplete} width="8%"></TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                    </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        ) : null;
    }
}
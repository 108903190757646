import React from 'react';
import { Button, Checkbox, Modal } from 'react-bootstrap';
import { rejectionReasonValidation } from '../../../../utils/ValidationUtils';

class RejectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validationErrorMessages: [],
            rejectionReasons: []
        }
    }

    mapRejectionReasons = () => {
        if (this.props.rejectionReasons && this.props.rejectionReasons.length > 0) {
            return this.props.rejectionReasons.map(x => {
                return (
                    <li key={x.commentDefinitionID}>
                        <Checkbox onChange={() => this.commentCheckboxChanged(x)} name="selectedComments"
                            id={x.commentDefinitionID} checked={x.isChecked} className="commentCheckbox">
                            {x.commentText}
                        </Checkbox>
                    </li>
                )
            })
        }
    }

    commentCheckboxChanged = (comment) => {
        comment.isChecked = !comment.isChecked;
        this.props.setSelectedRejectionReasons([...this.props.rejectionReasons.filter(x => x.commentDefinitionID !== comment.commentDefinitionID), comment]);
    }

    handleReject = () => {
        this.setState({ 
            validationErrorMessages: rejectionReasonValidation(this.props.rejectionReasons)
        }, () => {
            if (this.state.validationErrorMessages.length === 0) {
                // pass true to rejectEntry() to toggle open the detail modal, if rejected from detail modal
                this.props.rejectEntry(this.props.row, this.props.rejectFromDetailModal);
            }
        })
    }
    
    render() {
        return (
            <Modal dialogClassName="rejectModal" show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul style={{ listStyle: 'none' }}>
                        {
                            this.mapRejectionReasons()
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{textAlign: 'left'}}>
                        {
                            this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0 &&
                            this.state.validationErrorMessages.map(error => {
                                return <div key={error.id} className="errorMessage">{error.errorText}</div>
                            })
                        }
                        <br/>
                    </div>
                    <div style={{ float: 'left' }}>
                        <Button bsStyle="primary" onClick={this.handleReject}>
                            Reject
                        </Button>
                        {/* pass true to rejectModalToggle to toggle detail modal on cancel, if cancelled from detail modal */}
                        <Button bsStyle="primary" onClick={() => this.props.handleModalToggle(this.props.row, this.props.rejectFromDetailModal)}>
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default RejectModal;
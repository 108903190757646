// exports ReferenceDataType - an immutable object 'enum'
// usage: import { ReferenceDataType } from '../../enums/ReferenceDataType';
const ReferenceDataTypes = {
    ProviderType: 1,
    Source: 2,
    MedicalRecordType: 3,
    ReviewType: 4,
    SuspectSource: 5,
    RetroReviewType: 6,
    InvoiceStatus: 7,
    MedicalRecordEntityType: 8,
    RafType: 9,
    InventoryType: 10,
    EscalateIssue: 11,
    EscalatedAction: 12,
    EscalateActionStatus: 13
};
Object.freeze(ReferenceDataTypes);
export const ReferenceDataType = ReferenceDataTypes;
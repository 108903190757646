import React from 'react';
import axios from 'axios';
import { ControlLabel, Form, FormControl, FormGroup, Modal, Row, Col, Button, Glyphicon } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { formatISODateOnly }  from '../../../../../utils/DateUtils';

class DiagnosisLookupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            diagnosisCD: '',
            dateOfServiceTo: '',
            description: '',
            hccPartCs: '',
            hccPartDs: ''
        }
    }

    handleDiagnosisChange = (e) => {
        this.resetHccValuesAndDescription();
        this.setState({
            diagnosisCD: e.target.value.toUpperCase()
        });
    }

    handleDateChange = (dateValue) => {
        this.resetHccValuesAndDescription();
        this.setState({ dateOfServiceTo: dateValue });
    }

    diagLookup = () => {
        axios.get(`/diagnosisLookup/`, {
            params: {
                memberInternalKey: this.props.memberID,
                diagnosisCD: this.state.diagnosisCD,
                dateOfServiceThru: formatISODateOnly(this.state.dateOfServiceTo ? new Date(this.state.dateOfServiceTo) : new Date())
            }
        })
        .then((response) => {
            this.fillHCCInfo(response.data);
        })
        .catch((error) => {
            this.props.toast('warning', 'Diagnosis code was not found.', '', {
                timeOut: 2000,
                extendedTimeOut: 5000
            });
        })
    }

     // set state values of hccC, hccD, and description
    fillHCCInfo = (data) => {
        if (data) {
            if (data.hccPartCs) {
                data.hccCs = data.hccPartCs.map(x => x.model + "-" + x.value).toString();
            }
            if (data.hccPartDs) {
                data.hccDs = data.hccPartDs.map(x => x.model + "-" + x.value).toString();
            }
            this.setState({
                description: data.description,
                hccPartCs: data.hccCs,
                hccPartDs: data.hccDs
            });
        }
    }

    // clear hcc values and description
    resetHccValuesAndDescription = () => {
        this.setState({
            description: '',
            hccPartCs: '',
            hccPartDs: ''
        });
    }

    clearModal = () => {
        this.setState({
            diagnosisCD: '',
            dateOfServiceTo: '',
            description: '',
            hccPartCs: '',
            hccPartDs: ''
        });
    }

    selectDiagLookup = () => {
        this.props.selectDiagLookup(this.state.diagnosisCD, this.state.dateOfServiceTo, true);
        this.clearModal();
    }

    handleModalToggle = () => {
        this.props.selectDiagLookup('', '', false);
        this.clearModal();
    }

    render() {
        return (
            <div>
                <Modal bsSize={'large'} show={this.props.visible} onHide={this.handleModalToggle} keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Diagnosis Lookup</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{marginBottom: '1.6rem', minHeight: '12rem'}}>
                            <Row>
                                <Col xs={4}>
                                    <FormGroup>
                                        <ControlLabel style={{marginRight: '1rem'}} className="requiredField">Diagnosis Code</ControlLabel>
                                        <FormControl type="text" className="uppercaseText" value={this.state.diagnosisCD}
                                            onChange={this.handleDiagnosisChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <ControlLabel style={{marginRight: '1rem'}} className="requiredField">DOS</ControlLabel>
                                        <DatePicker value={this.state.dateOfServiceTo} onChange={this.handleDateChange} style={{zIndex: 'auto'}} />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup style={{marginTop: '2.45rem'}}>
                                        <Button onClick={this.diagLookup} disabled={!this.state.dateOfServiceTo || !this.state.diagnosisCD}
                                            style={{display: 'inline', marginRight: '1rem', marginLeft: '2.8rem'}}>
                                            <Glyphicon bsSize="large" glyph="search" />
                                        </Button>
                                        {
                                            !this.props.isComplete && 
                                            <Button onClick={this.selectDiagLookup} disabled={!this.state.description}>
                                                <Glyphicon bsSize="large" glyph="ok" style={{color: this.state.description ? 'green' : 'inherit'}} />
                                            </Button>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <FormGroup>
                                        <ControlLabel style={{marginRight: '1rem'}}>HCC-C</ControlLabel>
                                        <FormControl type="text" value={this.state.hccPartCs} disabled />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <ControlLabel style={{marginRight: '1rem'}}>HCC-D</ControlLabel>
                                        <FormControl type="text" value={this.state.hccPartDs} disabled />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <FormGroup>
                                        <ControlLabel style={{marginRight: '1rem'}}>Description</ControlLabel>
                                        <FormControl type="text" value={this.state.description} disabled />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleModalToggle}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default DiagnosisLookupModal;
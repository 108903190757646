import React from 'react';
import axios from 'axios';
import { Button, Col, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import PrintPackageModal from'./PrintPackageModal';
import WorkflowHeaderDetails from './WorkflowHeaderDetails';

class PackageReviewPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMembersWithAlert: [],
            providerOptions: [],
            defaultReports: '',
            packageQueryList: [],
            alertSort: this.props.defaultAlertSort,
            querySort: this.props.defaultQuerySort,
            formattedAlertDisplay: '',
            formattedQueryDisplay: '',
            loaded: false
        };
    }

    componentWillMount() {
        if (this.props.provider.id) {
            Promise.all([this.getSelectedMembersWithAlert(this.props),
                this.getQueryList(this.props),
                this.getProvidersByPractice(this.props),
                this.getDefaultReportComponents(this.props)])
            .then((response) => {
                this.setState({ loaded: true });
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.provider.id && nextProps.provider.id !== this.props.provider.id) {
            Promise.all([this.getSelectedMembersWithAlert(nextProps),
                this.getQueryList(nextProps),
                this.getProvidersByPractice(nextProps),
                this.getDefaultReportComponents(nextProps)])
            .then((response) => {
                this.setState({ loaded: true });
            });
        }
    }

    getSelectedMembersWithAlert = (props) => {
        if (props.packageType !== "PrePrint") {
             return axios.get('/packages/' + props.packageID + '/Members/')
            .then((response) => {
                response.data.items.forEach((item) => {
                    item.appointmentPractice = props.practice.desc;
                    item.editStatusDesc = "Added";
                });
                this.setState({
                    selectedMembersWithAlert: response.data.items
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load member data.', 'Error');
            });
        }
        else if (props.packageType === "PrePrint") {
            return Promise.all([
                axios.get('/packages/' + props.packageID + '/Members/'),
                axios.get('/packages/' + props.packageID + '/MemberExclusions/')
            ]).then(response => {
                const memberList = response[0].data.items;
                const memberExclusionList = response[1].data;
                if(memberList) {
                    memberList.forEach((item) => {
                        item.appointmentPractice = props.practice.desc;
                        item.editStatusDesc = "Added";
                    });
                }
                if(memberExclusionList) {
                    memberExclusionList.forEach((item) => {
                        item.appointmentPractice = props.practice.desc;
                        item.editStatusDesc = "Removed";
                    });
                }
                this.setState({
                    selectedMembersWithAlert: memberExclusionList ? memberList.concat(memberExclusionList) : memberList,
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load member data.', 'Error');
            });
        }
    }

    getDefaultReportComponents = (props) => {
        return axios.get('/packages/' + props.packageID + '/DefaultComponents/')
        .then((response) => {
            this.setState({
                defaultReports: response.data
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load default reports.', 'Error');
        });
    }

    // placeholder for service call to get the querylist
    getQueryList = (props) => {            
        return axios.get('/packages/' + props.packageID + '/IncludedQueryWorkflows/')
        .then((response) => {
           this.setState({ packageQueryList: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load queries.', 'Error');
        });
    }

    formatAlert = (alert) => {
        if (alert) {
            return <a target="_blank" rel="noopener noreferrer" href={`/cdi/${alert}/viewonly`}>{alert}</a>;
        }
    }
       
    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }   

    onAlertSortChange = (sortName, sortDirection) => {
        //remove any current selections for this column
        const tempSort = [...this.state.alertSort.filter(a => a.sortName !== sortName)];

        this.setState({
            alertSort: [ ...tempSort, {sortName, sortDirection} ]
        }, () => this.props.setAlertSort(this.state.alertSort));
    }

    onQuerySortChange = (sortName, sortDirection) => {
        //remove any current selections for this column
        const tempSort = [...this.state.querySort.filter(a => a.sortName !== sortName)];

        this.setState({
            querySort: [ ...tempSort, {sortName, sortDirection} ]
        }, () => this.props.setQuerySort(this.state.querySort));
    }

    clearAlertSort = () => {
        this.setState({
            alertSort: []
        }, () => this.props.setAlertSort(this.state.alertSort));

        this.refs.alertTable.cleanSort();
    }

    clearQuerySort = () => {
        this.setState({
            querySort: []
        }, () => this.props.setQuerySort(this.state.querySort));

        this.refs.queryTable.cleanSort();
    }

    getFormattedAlertFields = (sortName, sortDirection) => {
        let formattedSortName, formattedSortDirection = null;
        switch (sortName) {
            case 'memberName':
                formattedSortName = "Member Name";
                break;
            case 'memberID':
                formattedSortName = "Member ID";
                break;
            case 'assignedProvider':
                formattedSortName = "Assigned Provider";
                break;
            case 'activeAlerts':
                formattedSortName = "Active Alerts";
                break;
            case 'appointmentDatetime':
                formattedSortName = "Appt. Date";
                break;
            case 'appointmentPractice':
                formattedSortName = "Appt. Practice";
                break;
            case 'appointmentProvider':
                formattedSortName = "Appt. Provider";
                break;
            case 'alternateProviderLastName':
                formattedSortName = "Alt. Provider Last";
                break;
            case 'alternateProviderFirstName':
                formattedSortName = "Alt. Provider First";
                break;
            case 'editStatusDesc':
                formattedSortName = "Edit Type";
                break;
            case 'medicalRecordNumber':
                formattedSortName = "MRN";
                break;
            default:
                break;
        }

        switch (sortDirection) {
            case 'asc':
                formattedSortDirection = 'Ascending';
                break;
            case 'desc':
                formattedSortDirection = 'Descending';
                break;
            default:
                break;
        }

        return `${formattedSortName} (${formattedSortDirection})`;
    }

    //format alert sort display string
    formatAlertSort = () => {
        if (this.state.alertSort.length <= 0) {
            return '';
        }

        return this.state.alertSort.map((item) => {
            return this.getFormattedAlertFields(item.sortName, item.sortDirection);
        }).reduce((sortString, value) => {
            return sortString + ', ' + value;
        });
    }

    //format query sort display string
    formatQuerySort = () => {
        if (this.state.querySort.length <= 0) {
            return '';
        }

        return this.state.querySort.map((item) => {
            return this.getFormattedQueryFields(item.sortName, item.sortDirection);
        }).reduce((sortString, value) => {
            return sortString + ', ' + value;
        });
    }

    getFormattedQueryFields = (sortName, sortDirection) => {
        let formattedSortName, formattedSortDirection = null;
        switch (sortName) {
            case 'memberName':
                formattedSortName = "Member Name";
                break;
            case 'memberID':
                formattedSortName = "Member ID";
                break;
            case 'providerName':
                formattedSortName = "Appt Provider";
                break;
            case 'providerID':
                formattedSortName = "Provider ID";
                break;
            case 'cdiAlertWorkflowID':
                formattedSortName = "Queried Alert Workflow";
                break;
            case 'appointmentDatetime':
                formattedSortName = "Appt Date";
                break;
            case 'numberOfQueries':
                formattedSortName = "# of Queried Lines";
                break;
            default:
                break;
        }

        switch (sortDirection) {
            case 'asc':
                formattedSortDirection = 'Ascending';
                break;
            case 'desc':
                formattedSortDirection = 'Descending';
                break;
            default:
                break;
        }

        return `${formattedSortName} (${formattedSortDirection})`;
    }

    getProvidersByPractice = (props) => {
        return axios.get('/practice/' + props.practice.groupingID + '/provider')
        .then((response) => {
            const providers = [{ value: 0, text: 'Select' }, ...response.data.systemEntered.concat(response.data.userEntered).map((item) => {
                return { value: item.internalKey, text: `${item.lastName}, ${item.firstName}` };
            })];
            this.setState({ providerOptions: providers });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load provider data.', 'Error');
        });
    }
    
    formatCDIAlertWorkflowLink = (cdiAlertWorkflowID) => {
        return <a href={`/cdi/${cdiAlertWorkflowID}/viewonly?isPECAddAlert=true`}>{cdiAlertWorkflowID}</a>;
    }

    render() { 
        var alertOptions = {
            onSortChange: this.onAlertSortChange,
            sortName: this.state.alertSort.length > 0 ? this.state.alertSort.map(function(item) { return item.sortName; }).reverse() : 'memberName',
            sortOrder: this.state.alertSort.length > 0 ? this.state.alertSort.map(function(item) { return item.sortDirection; }).reverse() : 'asc'
        };
        var queryOptions = {
            onSortChange: this.onQuerySortChange,
            sortName: this.state.querySort.length > 0 ? this.state.querySort.map(function(item) { return item.sortName; }).reverse() : 'memberName',
            sortOrder: this.state.querySort.length > 0 ? this.state.querySort.map(function(item) { return item.sortDirection; }).reverse() : 'asc'
        };
        var isPrePrint = this.props.packageType === "PrePrint";
        const alertSortDisplay = this.formatAlertSort();
        const querySortDisplay = this.formatQuerySort();

        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <h3 className="workflow-title">Package Review & Print</h3>
                        {
                            this.state.loaded &&
                            <WorkflowHeaderDetails provider={this.props.provider.desc + ' (' + this.props.provider.id + ')'}
                                practice={this.props.practice.desc} packageType={this.props.packageType}/>
                        }
                        <hr />
                    </Col>
                </Row>
                <Loader loaded={this.state.loaded}>
                    <Row>
                        <Col sm={12}>
                            <h4 style={{ marginBottom: '1.4rem', marginRight: '0.2rem' }}>
                                <b>Alert List</b>
                            </h4>
                            <PrintPackageModal onComplete={this.props.onModalComplete} defaultReports={this.state.defaultReports}
                                alertSort={isPrePrint ? null : this.state.alertSort} querySort={this.state.querySort} alertSortDisplay={alertSortDisplay}
                                querySortDisplay={querySortDisplay} visible={this.props.showPrintModal} packageID={this.props.packageID}
                                handleModalToggle={this.props.togglePrintModal} toast={this.props.toast} practiceCode={this.props.practice.groupingID}
                                faxEnabled={this.state.faxEnabled} reprint={this.props.reprint} />
                            <h5 style={{ display: this.state.alertSort.length > 0 || this.props.defaultAlertSort.length > 0 ? 'block' : 'none' }}>
                                The alerts will be printed in the order listed: <b>{alertSortDisplay} (<Button onClick={this.clearAlertSort}>clear</Button>)</b>
                            </h5>
                            <BootstrapTable data={this.state.selectedMembersWithAlert} ref='alertTable' options={alertOptions} multiColumnSort={10} maxHeight="300px" scroll-top="Top"
                                hover>
                                <TableHeaderColumn dataField='editStatusDesc' dataSort hidden={!isPrePrint}>Edit Type</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberName' isKey dataSort>Member Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberID' dataSort>Member ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='assignedProvider' dataSort>Assigned Provider</TableHeaderColumn>
                                <TableHeaderColumn dataField='activeAlerts' dataFormat={this.formatAlert}  dataSort>Active Alert</TableHeaderColumn>
                                <TableHeaderColumn dataField='appointmentDatetime' dataFormat={this.formatDateString} dataSort hidden={isPrePrint}>Appt. Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='appointmentProvider' dataSort hidden={isPrePrint}>Appt. Provider</TableHeaderColumn>
                                <TableHeaderColumn dataField='alternateProviderLastName' dataSort hidden={isPrePrint}>Alt. Provider Last</TableHeaderColumn>
                                <TableHeaderColumn dataField='alternateProviderFirstName' dataSort hidden={isPrePrint}>Alt. Provider First</TableHeaderColumn>
                                <TableHeaderColumn dataField='medicalRecordNumber' dataSort>MRN</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h4 style={{ marginTop: '4rem', marginRight: '0.2rem', marginBottom: '1.4rem' }}>
                                <b>Query List</b>
                            </h4>
                            <h5 style={{ display: this.state.querySort.length > 0 || this.props.defaultQuerySort.length > 0 ? 'block' : 'none' }}>
                                The queries will be printed in the order listed: <b>{querySortDisplay} (<Button onClick={this.clearQuerySort}>clear</Button>)</b>
                            </h5>
                            <BootstrapTable data={this.state.packageQueryList} ref='queryTable' options={queryOptions} maxHeight="300px" scroll-top="Top" hover>
                                <TableHeaderColumn dataField='cdiAlertWorkflowID' isKey dataSort dataFormat={this.formatCDIAlertWorkflowLink}>Queried Alert Workflow</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberName' dataSort>Member Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='memberID' dataSort>Member ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='providerName' dataSort>Appt. Provider</TableHeaderColumn>
                                <TableHeaderColumn dataField='providerID' dataSort>Provider ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='appointmentDatetime' dataSort dataFormat={this.formatDateString}>Appt. Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='numberOfQueries' dataSort># of Queried Lines</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                </Loader>
            </div>
        );  
    }
}

export default PackageReviewPrint;
import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader';
import Error from '../../Shared/Error';

class PDFViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            imageData: null,
            imageType: this.props.imageType ? this.props.imageType : "application/pdf",
            imageLoading: true
        }
    }

    componentDidMount() {
        axios.get(this.props.uri, {
            responseType: 'arraybuffer'
        })
        .then((response) => {
            this.setState({
                imageData: URL.createObjectURL(new Blob([response.data], { type: this.state.imageType })),
                showImageError: false,
                imageLoading: false
            });
        })
        .catch((error) => {
            this.setState({
                showImageError: true,
                imageLoading: false
            });
        });
    }

    open = () => {
        if (!this.props.uri) {
            this.props.toast('error', 'Could not open PDF.');
            return;
        }
        else {
            this.setState({ showModal: true });
        }
    }

    close = () => {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <div>
                <button className="buttonLink" onClick={this.open}>{this.props.displayText}</button>
                <Modal className="pdfModal" show={this.state.showModal} onHide={this.close}>
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title>View PDF</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Loader loaded={!this.state.imageLoading} loadedClassName="loaded-content-full-height">
                                {
                                    !this.state.showImageError && this.state.imageData &&
                                    <embed width="100%" height="100%" src={this.state.imageData} type={this.state.imageType} />
                                }
                                {
                                    this.state.showImageError &&
                                    <Error style={{ marginTop: '5rem' }} />
                                }
                            </Loader>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Close</Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default PDFViewerComponent;
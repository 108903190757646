import React from 'react';
import { Panel, ControlLabel, FormGroup } from 'react-bootstrap';

const OOAAlertHeader = (props) => {
    return (
        <Panel className="alert-workflow-header">
            <h4>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Name:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{`${props.alertData.memberLastName}, ${props.alertData.memberFirstName}`}</span>
                </FormGroup>
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Date of Birth:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{props.alertData.memberDateOfBirth}</span>
                </FormGroup>               
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Member ID:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{props.alertData.memberID}</span>
                </FormGroup>                
                <FormGroup style={{ display: 'inline-block', paddingRight: '3rem' }}>
                    <ControlLabel>Status:</ControlLabel>
                    <span style={{ marginLeft: '1rem' }}>{props.alertData.alertStatus}</span>
                </FormGroup>
            </h4>
        </Panel>
    );
};

export default OOAAlertHeader;
import React from 'react';
import axios from 'axios';
import { Col, Grid, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import ActionBar from './ActionBar/ActionBar';
import AlertToastr from '../../Shared/AlertToastr';
import MemberDemographicContainer from './MemberDemographic/MemberDemographicContainer';
import StarsProfileContainer from './StarsProfile/StarsProfileContainer';
import ClaimsContainerRedux from './ClaimsAndAlerts/ClaimsContainerRedux';
import HCCProfileContainer from './HCCProfile/HCCProfileContainer';
import CaptureContainerRedux from './Capture/CaptureContainerRedux';
import { Program } from '../../../enums/Program';
import { getRedirect, getNextQueuedAlertWorkflow } from '../../../services/ReviewProcessing';
import '../../../styles/Coding.css';
import EscalateModal from '../../MRR/Coding/ActionBar/EscalateModal';

class CodingContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            reviewProcessingUIConfig: {},
            memberID: null,
            radvAudit: null,
            project: null,
            reviewHasAddedRecords: false,
            pecCorrectionCommentSelected: false,
            showHCCProfileTab: false,
            getNextAlertReviewDefault: false,
            selectedStarMeasure: null,
            loaded: false,
            error: '',
            showEscalateModal:false,
            toast: null,
            isAutoQueue: false,
            inventoryOptions:[],
            cdiFreeCommentText:''
        };

        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.alertReviewQueueKey = `cdiAlertReviewQueue-${this.lineOfBusiness}`;
    }

    componentWillMount() {
        if (this.props.location.query.isCDIQueue) {
            this.setState({ getNextAlertReviewDefault: this.props.location.query.isCDIQueue ? this.props.location.query.isCDIQueue.toLowerCase() === 'true' : false });
        }
        
        const params = {
            medicalRecordReviewID: this.props.location.query.medicalRecordReviewID
        }
        if (this.props.route.reviewType === "InitialCoding") {
            params.isCDIQueue = this.props.location.query.isCDIQueue || false
        }
        else if (this.props.route.reviewType === "CoderQA") {
            params.coderQAQueueID = this.props.location.query.coderQAQueueID;
        }
        else if (this.props.route.reviewType === "AutoReview") {
            this.state.isAutoQueue = true;
        }

        let origionalMrrId;

        if (this.props.location.query.skip) {
            params.mrrId = this.props.location.query.mrrId ? this.props.location.query.mrrId : 0;
            origionalMrrId = Number(params.mrrId);
        }

        axios.get(`/ReviewProcessingUIConfig/${this.props.route.reviewType}`, { params })
        .then((response) => {
            console.log("reviewProcessingUIConfig"); console.log(response.data);
            if (origionalMrrId && origionalMrrId === response.data.medicalRecordReviewID) {
                this.state.toast('success', 'There are no other reviews available at this time.', '');
            }

            if (response.data.isNlpEnabled && response.data.isReviewFromVendorQueue){
                getRedirect({
                    redirectAction: "InitialCoding",
                    redirectController: "NLP",
                    params : {
                        medicalRecordReview: {
                            medicalRecordReviewID: response.data.medicalRecordReviewID
                        }
                    }
                });
            }

            this.setState({
                reviewProcessingUIConfig: response.data,
                //MRR-23588
                showHCCProfileTab:response.data.projectDefaultProgramID !== Program.Stars,
                memberID: response.data.memberInternalKey,
                pecCorrectionCommentSelected: response.data.comments && response.data.comments.find(x => x.id === '16' && x.isChecked === true),
                loaded: true
            }, () => {
                this.getProject();
                this.getRADVAudit();
                this.LoadOption();
            });
        })
        .catch((error) => {
            //todo: use error component instead of toast?
            if(this.props.route.reviewType && this.props.route.reviewType === "AutoReview" && error.response.status === 404){
                if (this.state.toast) {
                    this.state.toast('error', this.state.error, 'No review available through MRR Auto Queue');
                }
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 3000);
            }
            else if (error.response) {
                //display review error and redirect
                this.setState({
                    loaded: true,
                    error: error.response ? error.response.data.message : `Could not load ${this.props.route.reviewType} configuration.`
                }, () => {
                    if (this.state.toast) {
                        this.state.toast('error', this.state.error, 'Error');
                    }
                    setTimeout(() => {
                        getRedirect({
                            redirectAction: "Index",
                            redirectController: "Landing"
                        });
                    }, 5000);
                });
            }
            else {
                console.log(error);
            }
        });
    }

    componentDidMount() {
        if (this.state.error) {
            this.state.toast('error', this.state.error, 'Error');
        }
    }

    handleNavBarProject = (projectName, projectID) => {
        this.setState({
        reviewProcessingUIConfig: {...this.state.reviewProcessingUIConfig,
                                     projectName: projectName,
                                     projectID: projectID}
      }, () => {
        this.getProject();
      });
    }

    getProject = () => {
        axios.get(`/projects/${this.state.reviewProcessingUIConfig.projectID}`)
        .then((response) => {
            this.setState({
                project: response.data
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load project data.', 'Error');
        })
    }

    //todo: only call if isRADV is true? / rework loading logic
    getRADVAudit = () => {
        axios.get('/radvaudit', {
            params: {
                projectID: this.state.reviewProcessingUIConfig.projectID
            }
        })
        .then((response) => {
            this.setState({
                radvAudit: response.data
            });
        })
        .catch((error) => {
            //this.state.toast('error', 'Could not load RADV audit data.', 'Error');
        })
    }

    toggleTabVisibility = () => {
        this.setState({ showHCCProfileTab: !this.state.showHCCProfileTab });
    }

    //handle review processing actions
    updateReview = (mrrUpdateDTO, getNextCDIAlertWorkflowID) => {
        let submitButton = mrrUpdateDTO.submitButton;
        if (this.props.route.reviewType === "CoderQA") {
            mrrUpdateDTO.coderQAQueueID = this.props.location.query.coderQAQueueID;
        }

        let queuedCDIAlertWorkflow;
        if (getNextCDIAlertWorkflowID) {
            queuedCDIAlertWorkflow = getNextQueuedAlertWorkflow();
            if (queuedCDIAlertWorkflow) {
                mrrUpdateDTO.nextCDIAlertWorkflowID = queuedCDIAlertWorkflow.cdiAlertWorkflowID;
            }
            else {
                this.showEmptyAlertQueueNotification();
                this.redirectToLanding();
            }
        }

        axios.put(`/MRReviewProcessing/`, {
            ...mrrUpdateDTO,
            hdnMrrId: this.state.reviewProcessingUIConfig.medicalRecordReviewID,
            hdnUserAssignmentId: this.state.reviewProcessingUIConfig.medicalRecordUserAssignmentID,
            hdnExpectedDatetime: this.state.reviewProcessingUIConfig.expectedDatetime,
            isAutoQueue: this.state.isAutoQueue,
            cdiFreeCommentText:this.state.cdiFreeCommentText
        })
        .then((response) => {
            this.state.toast('success', response.data.message ? response.data.message : 'Update was successful.', '');
            if (response.data.redirectController === "CoderQA" && response.data.redirectAction === "NextReview") {
                this.getNextReviewCoderQA(response.data.params.coderQAQueueID);
            }
            else if (submitButton === "Cancel"){
                let redirectInfo = response.data;

                if(redirectInfo.params != null){
                    redirectInfo.params.skip = true;
                }

                getRedirect(redirectInfo);
            }
            else {
                setTimeout(() => {
                    getRedirect(response.data);
                }, 1000);
            }
        })
        .catch((error) => {
            if (error.response && error.response.data && error.response.data.redirectAction === "Skip" && error.response.data.redirectController === "Coding") {
                //todo: choose better glyphicon (i.e. not check mark)
                this.state.toast('success', 'Finding next queued alert...', '');
                this.startNextReview(this.state.reviewProcessingUIConfig.projectID);
            }
            else if (error.response && error.response.data && error.response.data.message === "No Record is available for review for this Project and Record Type.") {
                //handles pre-populated project "Complete and Get Next" when project is out of eligible reviews
                this.state.toast('success', 'Review completed. Project has no additional reviews in Ready for MRR status.', '');
                this.redirectToLanding();
            }
            else {
                if (queuedCDIAlertWorkflow) {
                    this.returnCDIAlertWorkflowToQueue(queuedCDIAlertWorkflow);
                }

                this.state.toast('error', error.response && error.response.data && error.response.data.message ? error.response.data.message :
                    'Could not update review.', 'Error');
            }
        });
    }

    startNextReview = (projectID) => {
        const queuedAlert = getNextQueuedAlertWorkflow();
        if (queuedAlert) {
            //attempt to create a new review for the queued alert
            axios.get('/MRReviewProcessing/GetNextAlertQueue', {
                params: {
                    projectId: projectID,
                    medicalRecordTypeID: 302,
                    nextAlertQueueID: queuedAlert.cdiAlertWorkflowID
                }
            })
            .then((response) => {
                //review created -> redirect to newly-created review
                getRedirect(response.data);
            })
            .catch((error) => {
                //failed to create the review - try the next review in the queue
                this.startNextReview(projectID);
            });
        }
        else {
            //nothing left in the queue - redirect to index
            this.showEmptyAlertQueueNotification();
            this.redirectToLanding();
        }
    }

    showEmptyAlertQueueNotification = () => {
        this.state.toast('success', 'No reviews left in queue.', '');
    }

    redirectToLanding = () => {
        setTimeout(() => {
            getRedirect({
                redirectAction: "Index",
                redirectController: "Landing"
            });
        }, 3000);
    }

    getQueuedCDIAlertWorkflow = () => {
        let queuedAlert;
        const queue = JSON.parse(sessionStorage.getItem(this.alertReviewQueueKey));
        if (queue && queue.length > 0) {
            //pop item from beginning of queue
            queuedAlert = queue.shift();
            sessionStorage.setItem(this.alertReviewQueueKey, JSON.stringify(queue));
        }

        return queuedAlert && queuedAlert.cdiAlertWorkflowID ? queuedAlert : null;
    }

    returnCDIAlertWorkflowToQueue(queuedCDIAlertWorkflow) {
        //push failed queued workflow back onto queue
        let queue = JSON.parse(sessionStorage.getItem(this.alertReviewQueueKey));
        queue.unshift(queuedCDIAlertWorkflow);
        sessionStorage.setItem(this.alertReviewQueueKey, JSON.stringify(queue));
    }

    getNextReviewCoderQA = (coderQAQueueID) => {
        axios.get('/ReviewProcessingUIConfig/CoderQA/NextReview', {
            params: {
                coderQAQueueID: coderQAQueueID
            }
        })
        .then((response) => {
            getRedirect({
                redirectAction: "Index",
                redirectController: "CoderQA",
                params: {
                    coderQAQueueID: response.data.coderQAQueueID,
                    medicalRecordReviewId: response.data.medicalRecordReviewID
                }
            });
        })
        .catch((error) => {
            this.state.toast('error', error.response.data.message ? error.response.data.message : 'Could not get next review.', 'Error');
            this.redirectToLanding();
        });
    }

    //todo: this is currently being set if any diag, star, or opp data is present when loaded on <CaptureContainer /> init --
    // may also need to set if any of this data is added during the current session
    setReviewHasAddedRecords = () => {
        this.setState({ reviewHasAddedRecords: true });
    }

    setPECCorrectionCommentSelected = (commentSelected) => {
        this.setState({ pecCorrectionCommentSelected: commentSelected });
    }

    setPECCommentFreeText = (freeText) => {
        this.setState({cdiFreeCommentText:freeText});
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    // select star measure from star profile
    selectStarMeasure = (starRecord) => {
        this.setState({
            selectedStarMeasure: starRecord ? starRecord : null
        });
    }
    
    renderEscalateModal = (visible) => {
        this.setState({showEscalateModal: visible})
        this.state.showEscalateModal = visible;
    }
    
    LoadOption = () => {
        axios.get(`/ReferenceDataOptions/11`)
        .then((response) => {
            if (response.data) {
                this.setState({
                    inventoryOptions: response.data.map((x) => {
                        return {
                            label: x.description,
                            value: x.referenceDataItemID
                        }
                    })
                })
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load inventory options', 'Error');
        })
    }


    render() {
        return (
            <div>
                <Loader loaded={this.state.loaded}>
                {
                    !this.state.error &&
                    <Grid fluid style={{ paddingRight: 0, paddingLeft: 0 }}>
                        <ActionBar reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} updateReview={this.updateReview}
                            reviewHasAddedRecords={this.state.reviewHasAddedRecords} setPECCorrectionCommentSelected={this.setPECCorrectionCommentSelected}
                            reviewType={this.props.route.reviewType} router={this.props.router} getNextAlertReviewDefault={this.state.getNextAlertReviewDefault}
                            getNextReviewCoderQA={this.getNextReviewCoderQA} handleNavBarProject={this.handleNavBarProject}
                            toast={this.state.toast} showEscalateModal={this.state.showEscalateModal} renderEscalateModal={this.renderEscalateModal}
                            handleCDIFreeText={this.setPECCommentFreeText}/>
                        <Row>
                            <Col xs={12}>
                                <MemberDemographicContainer reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} reviewType={this.props.route.reviewType} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <StarsProfileContainer memberID={this.state.memberID} visible={!this.state.showHCCProfileTab}
                                    toggleTab={this.toggleTabVisibility} selectStarMeasure={this.selectStarMeasure} />
                                <HCCProfileContainer reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} radvAudit={this.state.radvAudit}
                                    visible={this.state.showHCCProfileTab} reviewType={this.props.route.reviewType} toggleTab={this.toggleTabVisibility} />
                            </Col>
                            <Col xs={6}>
                                <ClaimsContainerRedux reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} memberID={this.state.memberID} project={this.state.project}
                                    pecCorrectionCommentSelected={this.state.pecCorrectionCommentSelected} toast={this.state.toast} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CaptureContainerRedux reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} setReviewHasAddedRecords={this.setReviewHasAddedRecords}
                                    selectedStarMeasure={this.state.selectedStarMeasure} pecCorrectionCommentSelected={this.state.pecCorrectionCommentSelected}
                                    clearSelectedStarMeasure={this.selectStarMeasure} toast={this.state.toast} />
                            </Col>
                        </Row>
                    </Grid>
                }
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} />
                <EscalateModal visible={this.state.showEscalateModal} reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} option={this.state.inventoryOptions}
                    isAutoQueue={this.state.isAutoQueue}
                />
            </div>
        );
    }
}

export default CodingContainer;
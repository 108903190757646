import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router';

const PracticeSummaryTable = (props) => {
    const onBeforeSaveCell = (row, cellName, cellValue) => {
        //todo(?): return false to stop update if validation is needed
        row[cellName] = cellValue;
        props.handleUpdateRow(row);
    }

    const onAfterSaveCell = (row, cellName, cellValue) => {
        if (cellValue) {
            return parseInt(cellValue, 10);
        }
    }

    const cellEdit = {
        mode: 'dbclick',
        blurToSave: true,
        beforeSaveCell: onBeforeSaveCell,
        afterSaveCell: onAfterSaveCell
    };

    const getMappedPracticeStatusOptions = () => {
        return props.practiceStatusOptions.map(x => {
            return { value: x.value, text: x.label };
        });
    }

    const getMappedRetrievalMethodOptions = () => {
        return props.retrievalMethodOptions.map(x => {
            return { value: x.value, text: x.label };
        });
    }

    const getMappedAccessTypeOptions = () => {
        return props.accessTypeOptions.map(x => {
            return { value: x.value, text: x.label };
        });
    }

    const getMappedPracticeGroupingOptions = () => {
        return props.practiceGroupingOptions.map(x => {
            return { value: x.value, text: x.label };
        });
    }

    const getMappedEngagedStatusOptions = () => {
        return props.engagedStatusOptions.map(x => {
            return { value: x.value, text: x.label };
        });
    }

    const getMappedAccountAdminOptions = () => {
        return [
            { text: '(Empty)', value: '' },
            ...props.accountAdminOptions.map(x => {
                return { value: x.value, text: x.label };
            })
        ];
    }

    const practiceStatusEditable = {
        type: 'select',
        options: {
            values: getMappedPracticeStatusOptions()
        }
    };

    const retrievalMethodEditable = {
        type: 'select',
        options: {
            values: getMappedRetrievalMethodOptions()
        }
    };

    const practiceGroupingEditable = {
        type: 'select',
        options: {
            values: getMappedPracticeGroupingOptions()
        }
    };

    const accountAdminOptions = {
        type: 'select',
        options: {
            values: getMappedAccountAdminOptions()
        }
    };

    const formatPracticeStatus = (cell, row) => {
        const matchingOption = getMappedPracticeStatusOptions().filter(x => x.value === parseInt(cell, 10));
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const formatRetrievalMethod = (cell, row) => {
        const matchingOption = getMappedRetrievalMethodOptions().filter(x => x.value === parseInt(cell, 10));
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const formatAddress = (cell, row) => {
        const pieces = [row.practiceAddress1, row.practiceAddress2, row.practiceCity,
            row.practiceState, row.practiceZipCode];

        return pieces.reduce((acc, cur) => {
            if (!acc) {
                return cur;
            }

            return cur ? `${acc}, ${cur}` : acc;
        }, '');
    }

    const formatAccessType = (cell, row) => {
        const matchingOption = getMappedAccessTypeOptions().filter(x => x.value === cell);
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const formatPracticeGrouping = (cell, row) => {
        const matchingOption = getMappedPracticeGroupingOptions().filter(x => x.value === parseInt(cell, 10));
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const formatIsEngaged = (cell, row) => {
        const matchingOption = getMappedEngagedStatusOptions().filter(x => x.value === Boolean(cell));
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const formatAccountAdmin = (cell, row) => {
        const matchingOption = getMappedAccountAdminOptions().filter(x => x.value === parseInt(cell, 10));
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const formatPracticeNameLink = (cell, row) => {
        return (
            <Link to={`/EMR/practices/${row.campaignPracticeID}`} replace>
                {cell}
            </Link>
        )
    }

    const options = {
        defaultSortName: ['practiceCode', 'practiceName'],
        defaultSortOrder: ['asc', 'asc']
    };

    const onRowSelect = ({ campaignPracticeID }, isSelected) => {
        const records = [...props.practices];
        const target = records.find(x => x.campaignPracticeID === campaignPracticeID);
        target.selected = isSelected; // sets checkbox 'checked' value

        // update search results (add selections to parent state)
        props.setPractices(records);
    }

    const onSelectAll = (isSelected, rows) => {
        const campaignPracticeIDs = rows.map(x => x.campaignPracticeID);
        const records = [...props.practices];

        let target;
        campaignPracticeIDs.forEach(x => {
            target = records.find(y => y.campaignPracticeID === x);
            target.selected = isSelected; // sets checkbox 'checked' value
        });

        // update search results (add selections to parent state)
        props.setPractices(records);
    }

    const selectRowProp = {
        mode: 'checkbox',
        onSelect: onRowSelect,
        onSelectAll: onSelectAll,
        selected: props.getSelected()
    };

    return (
        <BootstrapTable data={props.practices} options={options} cellEdit={cellEdit} selectRow={selectRowProp} maxHeight="516px"
            scrollTop={"Top"} hover pagination multiColumnSort={2}>
            <TableHeaderColumn dataField="campaignPracticeID" isKey hidden></TableHeaderColumn>
            <TableHeaderColumn dataField="practiceCode" editable={false} dataAlign="left" dataSort>Practice Code</TableHeaderColumn>
            <TableHeaderColumn dataField="practiceName" editable={false} dataAlign="left" dataSort dataFormat={formatPracticeNameLink}>Practice Name</TableHeaderColumn>
            <TableHeaderColumn dataField="practiceStatusID" editable={practiceStatusEditable} dataFormat={formatPracticeStatus} dataAlign="left" dataSort>
                Status<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="campaignRetrievalMethodID" editable={retrievalMethodEditable} dataFormat={formatRetrievalMethod} dataAlign="left" dataSort>
                Retrieval Method<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="practiceOrganizationCode" editable={false} dataAlign="left" dataSort>Provider Org. ID</TableHeaderColumn>
            <TableHeaderColumn dataField="practiceOrganizationName" editable={false} dataAlign="left" dataSort>Provider Org. Desc</TableHeaderColumn>
            <TableHeaderColumn dataField="hospitalMedicalCenter" editable={false} dataAlign="left" dataSort>Hospital Medical Center</TableHeaderColumn>
            <TableHeaderColumn dataField="practiceFullAddress" editable={false} dataFormat={formatAddress} dataAlign="left" dataSort>Practice Full Address</TableHeaderColumn>
            <TableHeaderColumn dataField="emrAccessType" editable={false} dataFormat={formatAccessType} dataAlign="left" dataSort>EMR Access Type</TableHeaderColumn>
            <TableHeaderColumn dataField="practiceGroupingID" editable={practiceGroupingEditable} dataFormat={formatPracticeGrouping} dataAlign="left" dataSort>
                Practice Grouping<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="isEngaged" editable={false} dataFormat={formatIsEngaged} dataAlign="left" dataSort>Engaged Status</TableHeaderColumn>
            <TableHeaderColumn dataField="accountAdminUserID" editable={accountAdminOptions} dataFormat={formatAccountAdmin} dataAlign="left" dataSort>
                Account Admin<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/>
            </TableHeaderColumn>
        </BootstrapTable>
    );
}

export default PracticeSummaryTable;
import React from 'react';
import { useState, useEffect } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; 
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { RotateDirection } from '@react-pdf-viewer/core';
import { RenderRotateProps, rotatePlugin } from '@react-pdf-viewer/rotate';

import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';


export function PdfViewerPlugin(props)  {
     const [plugins, setplugins] = useState(defaultLayoutPlugin()); 
     const [toolbarPluginInstance, settoolbarPlugin] = useState(toolbarPlugin());
     const rotatePluginInstance = rotatePlugin();
     const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;
     const pageNavigationPluginInstance = pageNavigationPlugin();
    

    useEffect(() => {
        pageNavigationPluginInstance.jumpToPage(props.jumpToPage -1); 
      });
     

 return (

    <div
        className="rpv-core__viewer"
        style={{
            border: '10px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}
    >
        <div
            style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                padding: '4px',
            }}
        >
           <div style={{ padding: '0px 2px' }}>
                <RotateBackwardButton />
            </div>
            <div style={{ padding: '0px 2px' }}>
                <RotateForwardButton />
            </div>
        </div>
        <div
            style={{
                flex: 1,
                overflow: 'hidden',
            }}
        ></div>

    <Viewer fileUrl={props.fileUrl} 
            plugins={[plugins, rotatePluginInstance, toolbarPluginInstance, pageNavigationPluginInstance]}
    ></Viewer>

    </div>
 );
}


import React from 'react';
import { Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import StarsDetailModal from './StarsDetailModal';
import { sortDate } from '../../../../../utils/SortUtils';

class StarsCaptureHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiagDetailModal: false,
            modalRow: null
        }
    }

    // format date
    formatDate = (cell) => {
        return new Date(cell).toLocaleDateString();
    }

    // generate dynamic values array and clean date values
    mapRowValues = (row) => {
        if (row.medicalRecordImageID === -1) {
            row.medicalRecordImageID = "Not Available in MRR";
        }
        row.dateOfServiceFrom = new Date(row.dateOfServiceFrom).toLocaleDateString();
        row.enteredDate = new Date(row.enteredDate).toLocaleDateString();
        row.dynamicValues = row.dynamicCapturedData.split(',').map(x => x.trim().split(':'));
    }

    toggleDiagDetailModal = (row) => {
        if (row && !row.target) {
            this.mapRowValues(row);
        }
        this.setState({ 
            showDiagDetailModal: !this.state.showDiagDetailModal,
            modalRow: row
        });
    }

    renderTable = (results) => {
        const options = {
            defaultSortName: 'medicalRecordQualityCaptureID',
            defaultSortOrder: 'asc',
            onRowDoubleClick: this.toggleDiagDetailModal
        };
        return (
            <div>
                <BootstrapTable data={results} options={options} hover striped>
                    <TableHeaderColumn dataField="projectName" dataSort width="12%" tdStyle={{whiteSpace: 'normal'}}>Project Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordQualityCaptureID" isKey dataSort width="5%">ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerNPI" dataSort>Provider NPI</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceFrom" dataFormat={this.formatDate} dataSort sortFunc={(a, b, order) => sortDate(a.dateOfServiceFrom, b.dateOfServiceFrom, order)}>DOS</TableHeaderColumn>
                    <TableHeaderColumn dataField="measure" dataSort width="6%">Measure</TableHeaderColumn>
                    <TableHeaderColumn dataField="serviceDescription" dataSort width="15%" tdStyle={{whiteSpace: 'normal'}}>Service Desc</TableHeaderColumn>
                    <TableHeaderColumn dataField="dynamicCapturedData" dataSort width="15%" tdStyle={{whiteSpace: 'normal'}}>Service Result</TableHeaderColumn>
                    <TableHeaderColumn dataField="enteredDate" dataFormat={this.formatDate} dataSort sortFunc={(a, b, order) => sortDate(a.enteredDate, b.enteredDate, order)}>Captured On</TableHeaderColumn>
                    <TableHeaderColumn dataField="enteredBy" dataSort tdStyle={{whiteSpace: 'normal'}}>Captured By</TableHeaderColumn>
                    <TableHeaderColumn dataField="qaFullName" dataSort tdStyle={{whiteSpace: 'normal'}}>QA</TableHeaderColumn>
                    <TableHeaderColumn dataField="statusDescription" dataSort>Status</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Panel className="capturePanel" header="Stars Capture History">
                    {
                        this.props.starsData &&
                        this.renderTable(this.props.starsData)
                    }
                </Panel>
                <StarsDetailModal visible={this.state.showDiagDetailModal} handleModalToggle={this.toggleDiagDetailModal}
                    row={this.state.modalRow} rejectionReasons={this.props.rejectionReasons} />
            </div>
        );
    }
}

export default StarsCaptureHistory;
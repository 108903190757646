import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Grid, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default class UsersListModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emrUserAccessList: [],
            emrUserID: null,
            userOptionList: []
        }
    }

    componentDidMount() {
        this.retrieveUserAccess();
    }

    componentDidUpdate(prevProps) {
        if((prevProps.practice && prevProps.practice.campaignEMRInstanceID != this.props.practice.campaignEMRInstanceID)){
            this.setState({
                emrUserAccessList: []
            });
            this.retrieveUserAccess();
        }
    }

    retrieveUserAccess = () => {
        if(this.props.practice && this.props.practice.campaignEMRInstanceID){
            axios.get(`/campaignEMRUserAccess/${this.props.practice.campaignEMRInstanceID}`)
                .then((response) => {
                    if (response.data) {
                        this.setState({
                            emrUserAccessList: response.data
                        });
                    }
                })
                .catch((error) => {
                    if(error.response.status !== 404){
                        this.props.toast('error', 'Could not Load User List', 'Error');
                    }
                });
        }
    }

    addEMRUser = () => {
        axios.post('/campaignEMRUserAccess', {
            userID: this.state.emrUserID,
            campaignEMRInstanceID: this.props.practice.campaignEMRInstanceID,
            isDeleted: false
        })
        .then((response) => {
            var newEMRUserAccessList = this.state.emrUserAccessList;    
            newEMRUserAccessList.push(response.data);   
            this.setState({emrUserAccessList: newEMRUserAccessList});
        })
        .catch((error) => {
            if(error.response.status === 409){
                this.props.toast('error', 'User already exists.', 'Error');
            } else {
                this.props.toast('error', 'Failed to create users list.', 'Error');
            }
        });
    }

    toggleDeleteConfirmModal = (user) => {
        axios.put('/campaignEMRUserAccess', {
            campaignEMRUserAccessID: user.campaignEMRUserAccessID,
            userID: user.userID,
            campaignEMRInstanceID: user.campaignEMRInstanceID,
            isDeleted: true
        })
        .then((response) => {
            const emrUser = this.state.emrUserAccessList.filter((x) => {
                return x.campaignEMRUserAccessID !== user.campaignEMRUserAccessID
            });
            this.setState({ emrUserAccessList: emrUser });
        })
        .catch((error) => {
            this.props.toast('error', 'Failed to save users list.', 'Error');
        });
    }

    formatAction = (cell, row) => {
        return(
                <Button onClick={() => this.toggleDeleteConfirmModal(row)}>
                    <Glyphicon bsSize="large" glyph="trash" />
                </Button>
        )
    }

    handleEMRUserID = (e) => {
        this.setState({ emrUserID: e ? e.value : '' });
    }

    formatUser = (cell, row) => {
        const matchingOption = this.props.userList.filter(x => x.value === cell);
        return matchingOption.length > 0 ? matchingOption[0].label : '';
    }

    render() {
        const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell
        };

        return (
            <div>
                <Modal show={this.props.showEMRUserAccessListModal} onHide={this.props.toggleEmrUserAccessListModal}  keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Users</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row style={{ marginTop: '2rem', padding: '0 1.5rem' }}>
                                <Col xs={10} style={{ paddingLeft: 0 }}>
                                    <FormGroup>
                                        <ControlLabel>Select a User</ControlLabel>
                                        <Select value={this.state.emrUserID} options={this.props.userList}
                                            onChange={this.handleEMRUserID} />
                                    </FormGroup>
                                </Col>
                                <Col xs={2} style={{ paddingLeft: 0, paddingTop: '2.5rem' }}>
                                    <Button onClick={this.addEMRUser}
                                        disabled={!(this.state.emrUserID > 0)}>
                                        <Glyphicon glyph="plus" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '2rem' }}>
                                <Col xs={12}>
                                    <BootstrapTable data={this.state.emrUserAccessList} maxHeight="516px" scrollTop={"Top"} 
                                        hover pagination multiColumnSort={2}
                                        >
                                        <TableHeaderColumn dataField="campaignEMRUserAccessID" isKey hidden></TableHeaderColumn>
                                        <TableHeaderColumn dataField="userID" dataFormat={this.formatUser} dataSort>
                                            User Name</TableHeaderColumn>
                                        <TableHeaderColumn width="12%" dataFormat={this.formatAction} >Action</TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
import React from 'react';
import axios from 'axios';
import { Panel } from 'react-bootstrap';
import { Col, Grid, Row } from 'react-bootstrap';
import AlertToastr from '../Shared/AlertToastr';
import BackToTop from "react-back-to-top-button";
import SidePanel from './SidePanel/SidePanel';
import MemberInfoBar from './ChartSection/MemberInfoBar';
import ProblemList from './ChartSection/ProblemList';
import Notes from './ChartSection/Notes';
import VitalSigns from './ChartSection/VitalSigns';
import Procedures from './ChartSection/Procedures';
import PastSurgicalProcedures from './ChartSection/PastSurgicalProcedures';
import Medications from './ChartSection/Medications';
import LabResults from './ChartSection/LabResults';
import Diagnosis from './ChartSection/Diagnosis';
import MemberDemographics from './ChartSection/MemberDemographics';
import Encounter from './ChartSection/Encounter';
import HistoryOfIllness from './ChartSection/HistoryOfIllness';
import Assessments from './ChartSection/Assessments';
import AssessmentQuestions from './ChartSection/AssessmentQuestions';
import Attachments from './ChartSection/Attachments';
import SocialHistory from './ChartSection/SocialHistory';
import isEmpty from 'lodash/isEmpty';
import { result } from 'lodash';
import { formatDatetimeStringWithPrefixZero } from '../../utils/DateUtils';

class MedicalRecordDisplayContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toast: null,//it's used to show the error message to user
            currentEncounterDate:'',
            additionalInfo:{},
            member: {},
            notes:[],
            problemList: [],
            vitalSigns: [],
            encounterDates: [],
            memberLoaded: false,
            encounter: {},
            historyOfIllness: [],
            assessments: [],
            assessmentquestions: [],
            attachments: [],
            medications: [],
            socialHistory: [],
            labResults:[],
            diagnosis:[],
            procedures: [],
            pastSurgicalProcedures: []
        };  
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }
 
    componentDidMount() {

        Promise.allSettled([
            this.getNavigationPanel()
                     
        ])
        .then((responses) => {

            if(responses[0].status === 'fulfilled')
            {
                //init static panel
                this.setState({
                    memberLoaded: responses[0].value.data.demographics !== null ? true : this.state.memberLoaded,
                    member: responses[0].value.data.demographics,
                    encounterDates: responses[0].value.data.encounters,
                    currentEncounterDate:!isEmpty(responses[0].value.data.encounters) ? formatDatetimeStringWithPrefixZero(responses[0].value.data.encounters[0].encounter_start_date):'',
                    notes:responses[0].value.data.notes,
                    historyOfIllness:responses[0].value.data.historyOfPresentIllness,
                    labResults:responses[0].value.data.labResults,
                    assessments:responses[0].value.data.assessments,
                    assessmentquestions:responses[0].value.data.assessmentQuestions,
                    attachments:responses[0].value.data.attachments,
                    additionalInfo:responses[0].value.data.additionalInfo,
                    procedures:responses[0].value.data.procedures,
                    pastSurgicalProcedures:responses[0].value.data.pastSurgicalProcedures
                });
                
                //init data with encouonter id
                var currentEncounterId = !isEmpty(responses[0].value.data.encounters) ? responses[0].value.data.encounters[0].encounter_id:'';
                this.getEncounterByEncounterId(currentEncounterId);  
            }                
        })
        .catch((error) => this.state.toast('error', `Could not load navigation panel data.`, 'Error'));
    }

    //get navigation panel and default chart data for a given member
    getNavigationPanel = () => {
        return axios.get(`/EMRMedicalRecord/NavigationPanel/${this.props.location.query.memberMasterID}`);
    }

    //get chart data for a specific encounter
    getEncounterByEncounterId = (encounterId) => {
        let promise = axios.get(`/EMRMedicalRecord/memberEncounterChart/${this.props.location.query.memberMasterID}?encounterID=${encounterId}`);
        if (promise) {
            return promise.then((response) => {
                var encounterCharts = response.data;
                this.refreshChartSections(encounterCharts);
            })
            .catch((error) => {
                this.state.toast('error', `Could not load chart section data for encounter id ${encounterId}.`, 'Error');
            });
        }
    }

    getEncounterWithoutEncounterId = () =>{
        this.refreshChartSections(this.state.additionalInfo);
    }

    //This method's shared between Encounters and Additional Info.
    refreshChartSections = (chartInfo) =>{
        this.setState({
            encounter:!isEmpty(chartInfo)?chartInfo.encounter : {},
            notes: !isEmpty(chartInfo)?chartInfo.notes: [],
            problemList: !isEmpty(chartInfo)?chartInfo.problemList: [],
            vitalSigns: !isEmpty(chartInfo)?chartInfo.vitalSigns : [],
            medications: !isEmpty(chartInfo)?chartInfo.medications : [],
            socialHistory: !isEmpty(chartInfo)?chartInfo.socialHistory: [],
            labResults: !isEmpty(chartInfo)?chartInfo.labResults: [],
            diagnosis: !isEmpty(chartInfo)?chartInfo.diagnosis: [],
            procedures: !isEmpty(chartInfo)?chartInfo.procedures: [],
            pastSurgicalProcedures: !isEmpty(chartInfo)?chartInfo.pastSurgicalProcedures: []
        });
    }

    changeCurrentEncounter = (encounterId, encounterDate) =>{
        this.setState({
            currentEncounterDate:encounterDate
        });
        if(encounterId !== -1)//-1 means additional info
            this.getEncounterByEncounterId(encounterId);
        else
            this.getEncounterWithoutEncounterId();
    }

    handleSamePageNav = (name) => {
        const element = document.getElementById(name);
        if (element)
        {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        //#region inline css
        const backToTopStyle = {
            width: '6rem',
            height: '4rem',
            borderRadius: '10px',
            backgroundColor: 'teal',
            color: 'beige',
            fontSize: '14pt'
        }
        //#endregion

        return (
            <Grid fluid>
                <Row>
                    <Col xs={3} style={{ position: 'sticky', top: '1rem', paddingLeft: '0rem' }}>
                       <SidePanel handleSamePageNav={this.handleSamePageNav} data={this.state.encounterDates} 
                                    changeCurrentEncounter={this.changeCurrentEncounter} />
                    </Col>
                    <Col xs={9}>
                        <MemberInfoBar memberLoaded={this.state.memberLoaded} 
                            member={this.state.member} encounterDate={this.state.currentEncounterDate}/>
                        <MemberDemographics memberLoaded={this.state.memberLoaded} 
                            member={this.state.member} />
                        <Encounter memberLoaded={this.state.memberLoaded} 
                            data={this.state.encounter}/>
                        <Notes memberLoaded={this.state.memberLoaded} 
                            data={this.state.notes}/>
                        <ProblemList memberLoaded={this.state.memberLoaded} 
                            data={this.state.problemList}/>
                        <VitalSigns memberLoaded={this.state.memberLoaded} 
                            data={this.state.vitalSigns} />
                        <Medications memberLoaded={this.state.memberLoaded} 
                            data={this.state.medications} />
                        <SocialHistory memberLoaded={this.state.memberLoaded} 
                            data={this.state.socialHistory} />
                        <Procedures memberLoaded={this.state.memberLoaded} 
                            data={this.state.procedures} />
                        <PastSurgicalProcedures memberLoaded={this.state.memberLoaded} 
                            data={this.state.pastSurgicalProcedures} />
                        <LabResults memberLoaded={this.state.memberLoaded} 
                            data={this.state.labResults} />
                        <Diagnosis memberLoaded={this.state.memberLoaded} 
                            data={this.state.diagnosis}/>
                        <HistoryOfIllness memberLoaded={this.state.memberLoaded} 
                            data={this.state.historyOfIllness} />
                        <Assessments memberLoaded={this.state.memberLoaded} 
                            data={this.state.assessments} />
                        <AssessmentQuestions memberLoaded={this.state.memberLoaded} 
                            data={this.state.assessmentquestions} />
			            <Attachments memberLoaded={this.state.memberLoaded} 
                            data={this.state.attachments} />
                    </Col>
                </Row>
                
                <BackToTop showAt={100} speed={1500} style={backToTopStyle}>
                    <span>Top</span>
                </BackToTop>

                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        )
    }
}

export default MedicalRecordDisplayContainer;
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';

const AlertModalMemberSearch = (props) =>
    <div>
        <Row>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>Member ID</ControlLabel>
                    <FormControl type="text" name="memberID" value={props.memberID}
                        onChange={props.handleInputChange} />
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>Member Last Name</ControlLabel>
                    <FormControl type="text" name="lastName" value={props.lastName}
                        onChange={props.handleInputChange} />
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>Member First Name</ControlLabel>
                    <FormControl type="text" name="firstName" value={props.firstName}
                        onChange={props.handleInputChange}
                        disabled={!props.lastName || (props.lastName && props.lastName.length < 2)} />
                </FormGroup>
            </Col>
        </Row>
        <Row style={{ marginBottom: "3rem" }}>
            <Col sm={12}>
                <Button onClick={props.handleMemberSearch}>
                    <Glyphicon glyph="search" style={{ marginRight: "0.2rem" }} /> Search Members
                </Button>
            </Col>
        </Row>
    </div>

export default AlertModalMemberSearch;
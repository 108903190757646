
import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {PdfViewerPlugin  } from './PdfViewerPlugin'
import {PdfjsBuild} from '../../../../constants/PdfjsBuild'



export const PdfViewerHOC = (props) => {

    return (
        
            props.fileUrl && 
            (
              <Worker workerUrl={PdfjsBuild}>
                <PdfViewerPlugin fileUrl={props.fileUrl} jumpToPage={props.jumpToPage}>
                </PdfViewerPlugin>
            </Worker> 
            )
      )
  };












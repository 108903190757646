import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Row, Modal, Glyphicon} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { AlertManagementStatus } from '../../../enums/AlertManagementStatus';
import { AlertRunStatus } from '../../../enums/AlertRunStatus';

class AlertManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertRuns: [],
            syncChoice: 0,
            memberRosterDate: '',
            practiceEngagementDate: '',
            memberRosterCacheData: [],
            practiceEngagementData: [],
            syncMemberRosterCacheStatus: '',
            syncPracticeEngagementStatus: '',
            newAlertRunStatus: '',
            finishFlag: false,
            showConfirmationModal: false
        }
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillMount() {
        this.getAllAlertRuns();
        this.getMemberRosterDate();
        this.getPracticeEngagementDate();
        this.getMostRecentAlertRun();
        this.getStatusesFromOthers();

        this.recentRunPollingInterval = setInterval(this.getMostRecentAlertRun,5000);
        this.syncDetailsPollingInterval = setInterval(this.getStatusesFromOthers,5000);
    }

    getAllAlertRuns = () => {
        axios.get(`/AlertManagement`, {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ alertRuns: response.data });
        });
    }

    getMemberRosterDate = () => {
        axios.get(`/AlertManagement/MemberRosterLastSyncDate`, {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ memberRosterDate: this.formatDateString(response.data) });
        });
    }

    getPracticeEngagementDate = () => {
        axios.get(`/AlertManagement/PracticeTargetEngagementLastSyncDate`, {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ practiceEngagementDate: this.formatDateString(response.data) });
        });
    }

    getMostRecentAlertRun = () => {
        axios.get(`/AlertManagement/MostRecentAlertRun`, {
            cancelToken: this.source.token
        })
        .then((response) =>{
            this.setState(state =>
                response.data == null ? null : {
                    alertRuns: [...this.state.alertRuns.filter(r => r.alertRunID !== response.data.alertRunID), response.data]
                }
            );
        });
    }

    formatAlertRunStatus = (alertRunStatusID) => {
        return Object.keys(AlertRunStatus).find(x => AlertRunStatus[x] === alertRunStatusID).toString();
    }

    getAlertRunningStatusInfo = (status) => {
        switch (status) {
            case 'Initialized':
                return 'Start Alert Run is processing.';
            case 'Error': 
                return 'Could not start new alert run.';
            case 'Processing':
                return 'Start Alert Run is Processing.';
            case 'Complete':
                return 'Start Alert Run is completed.';
            default:
                return null;
        }
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString() : '';
    }

    validateAlertRun = (id, isValidated ) => {
        axios.put(`/AlertManagement/UpdateValidation`, {
            AlertRunID: id,
            IsValidated: isValidated,
            cancelToken: this.source.token
        })
        .then((response) => {
            this.getAllAlertRuns();
        });
    }

    startAlertRun = () => {
        this.setState({
            finishFlag: false,
            newAlertRunStatus: "",
            showConfirmationModal: false
        });

        axios.post(`/AlertManagement/StartNewAlertRun`, {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.intervalAlertRun = setInterval(this.getAlertRunStatus, 10000);
        });
    }

    syncTypeChange = (e) => {
        this.setState({ syncChoice: e.target.value });
    }

    getStatusesFromOthers = () => {
        axios.get(`/AlertManagement/AlertManagementCompletionStatus`,{
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ finishFlag: response.data === true ? true : false });
        });
    }

    getStatuses = (choice) => {
        axios.get(`/AlertManagement/AlertManagementStatuses/${choice}`, {
            cancelToken: this.source.token
        })
        .then((response) => {
            if (this.state.syncChoice === 'MemberRoster')
                {
                    this.setState({
                        syncMemberRosterCacheStatus: Object.keys(AlertManagementStatus).find(x => AlertManagementStatus[x] === response.data[0]),
                        syncPracticeEngagementStatus: "Skip this time"
                    });
                    if (response.data[0] === AlertManagementStatus['Error'] || response.data[0] === AlertManagementStatus['Complete'])
                    {
                        clearInterval(this.intervalSyncData);
                        this.getMemberRosterDate();
                        this.setState({ finishFlag: true });
                    }
                }
                else if (this.state.syncChoice === 'PracticeEngagement')
                {
                    this.setState({
                        syncPracticeEngagementStatus: Object.keys(AlertManagementStatus).find(x => AlertManagementStatus[x] === response.data[0]),
                        syncMemberRosterCacheStatus: "Skip this time"
                    });
                    if (response.data[0] === AlertManagementStatus['Error'] || response.data[0] === AlertManagementStatus['Complete'])
                    {
                        clearInterval(this.intervalSyncData);
                        this.getPracticeEngagementDate();
                        this.setState({ finishFlag: true });
                    }
                }
                else if (this.state.syncChoice === 'Both')
                {
                    this.setState({
                        syncMemberRosterCacheStatus: Object.keys(AlertManagementStatus).find(x => AlertManagementStatus[x] === response.data[0]),
                        syncPracticeEngagementStatus: Object.keys(AlertManagementStatus).find(x => AlertManagementStatus[x] === response.data[1])
                    });
                    if ((response.data[0] === AlertManagementStatus['Error'] || response.data[0] === AlertManagementStatus['Complete'])
                        && (response.data[1] === AlertManagementStatus['Error'] || response.data[1] === AlertManagementStatus['Complete']))
                    {
                        clearInterval(this.intervalSyncData);
                        this.getMemberRosterDate();
                        this.getPracticeEngagementDate();
                        this.setState({ finishFlag: true });
                    }
                }
        });
    }

    getAlertRunStatus = () => {
        axios.get(`/AlertManagement/AlertRunStatus`,{
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                newAlertRunStatus: Object.keys(AlertManagementStatus).find(x => AlertManagementStatus[x] === response.data)
            })
            if (response.data === AlertManagementStatus['Error'] || response.data === AlertManagementStatus['Complete'])
            {
                clearInterval(this.intervalAlertRun);
                this.getAllAlertRuns();
                this.setState({
                    finishFlag: true
                })
            }
        });
    }

    syncData = () => {
        this.setState({
            syncMemberRosterCacheStatus: "",
            syncPracticeEngagementStatus: ""
        })
        if (this.state.syncChoice !== "0")
        {
            this.setState({
                finishFlag: false
            })
            axios.post(`/AlertManagement/SyncData/${this.state.syncChoice}`, {
                cancelToken: this.source.token
            })
            .then((response) => {
                this.intervalSyncData = setInterval(() => this.getStatuses(this.state.syncChoice),10000);
            })
        }
    }

    toggleConfirmationModal = () => {
        this.setState(prevState => ({
            showConfirmationModal: !prevState.showConfirmationModal
        }));
    }

    formatActions = (cell, row) => {
        return (
            <Button disabled={this.formatAlertRunStatus(row.alertRunStatusID) !== 'Complete'}
                onClick={() => this.validateAlertRun(row.alertRunID, row.isValidated)}>
                {row.isValidated ? 'Invalidate' : 'Validate'}
            </Button>
        )
    }

    renderAlertRunConfirmationModal = () => {
        const confirmButton = (
            <Button bsStyle="success" onClick={this.startAlertRun} style={{float: "right"}}>OK
                <Glyphicon glyph="ok" style={{marginLeft: "0.66rem", top: "1.6px", lineHeight: "14px", float: "right"}} />
            </Button>
        )
        return (
            <Modal show={this.state.showConfirmationModal} onHide={this.toggleConfirmationModal} >
                <Modal.Header style={{textAlign: "center", fontWeight: "bold"}} closeButton>New Alert Run</Modal.Header>
                <Modal.Body style={{textAlign: "center"}}>Please confirm you want to start a new alert run.</Modal.Body>
                <Modal.Footer>{confirmButton}</Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                <Row style={{ marginTop: '1rem'}}>
                    <Col sm={8}>
                        <Row>
                            <Col sm={12}>
                                <h4>Alert Run</h4>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '1rem'}}>
                            <Col sm={3}>
                                <Button onClick={this.toggleConfirmationModal} disabled={!this.state.finishFlag}>
                                    Start New Alert Run
                                </Button>
                            </Col>
                            <Col sm={9} style={{color: 'red'}}>
                                {this.getAlertRunningStatusInfo(this.state.newAlertRunStatus)}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '1rem'}}>
                            <Col sm={12}>
                                <BootstrapTable data={this.state.alertRuns} ref="table" multiColumnSort={8} maxHeight="554px" scrollTop={"Top"}
                                    hover pagination>
                                    <TableHeaderColumn dataField="alertRunID" dataAlign="left" dataSort isKey>Alert Run ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="createdDatetime" dataFormat={this.formatDateString} dataAlign="left" dataSort>Create Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField="updatedDatetime" dataFormat={this.formatDateString} dataAlign="left" dataSort>Update Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField="alertRunStatusID" dataFormat={this.formatAlertRunStatus} dataAlign="left">Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField="isValidated" dataAlign="left">Is Validated</TableHeaderColumn>
                                    <TableHeaderColumn dataField="validation" dataFormat={this.formatActions} dataAlign="left">Manage</TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col sm={12}>
                                <h4>Member Roster and Practice Engagement</h4>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '1rem'}}>
                            <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }}>Sync Choices:</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.syncChoice}
                                        onChange={this.syncTypeChange}>
                                        <option value={0}>Select</option>
                                        <option value="MemberRoster">Sync Alert Engine Member Roster</option>
                                        <option value="PracticeEngagement">Sync Alert Engine Practice Engagement</option>
                                        <option value="Both">Sync both</option>
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }}>Alert Engine Member Roster Date:</ControlLabel>
                                    <FormControl type="text" value={this.state.memberRosterDate} disabled={true} />
                                </FormGroup>
                                {this.state.syncMemberRosterCacheStatus}&nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: '1rem' }}>Alert Engine Practice Engagement Date:</ControlLabel>
                                    <FormControl type="text" value={this.state.practiceEngagementDate} disabled={true} />
                                </FormGroup>
                                {this.state.syncPracticeEngagementStatus}&nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Button onClick={this.syncData} disabled={!this.state.finishFlag} style={{ marginRight: '1rem' }}>Sync</Button>
                                    { this.state.finishFlag ? null : "Disabled - creating new alert run or syncing data." }
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.renderAlertRunConfirmationModal()}
            </div>
        );
    }
}

export default AlertManagement;
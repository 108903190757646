import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import AlertToastr from '../Shared/AlertToastr';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import { getDatePickerValueAsMidnightISOString } from '../../utils/DateUtils';

class CommentsReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectList: [],
            startDateInput: null,
            endDateInput: null,
            //NOTE: Filtering by multiple projects possible with some work on the backend
            searchFilter: {
                projectIds: [],
                startDate: null,
                endDate: null
            },
            reviewComments: [],
            toast: null
        };
        this.sizePerPageList = [10, 25, 50, 100, 250]
            .map(item => { return { text: item.toString(), value: item } });
    }

    componentWillMount() {
        Promise.all([
            this.getProjectsForDropdown(),
            this.getReviewComments()
        ])
        .catch((error) => {
            this.state.toast('error', 'Could not load project options.', 'Error');
        });
    }

    getProjectsForDropdown = () => {
        return axios.get('/projects')
            .then((response) => {
                this.setState({
                    projectList: response.data.map((proj) => {
                        return {
                            label: proj.projectName,
                            value: proj.projectID
                        };
                    })
                });
            });
    }

    handleSearch = () => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                startDate: this.state.startDateInput && getDatePickerValueAsMidnightISOString(this.state.startDateInput),
                endDate: this.state.endDateInput && getDatePickerValueAsMidnightISOString(this.state.endDateInput)
            }
        }, this.getReviewComments);
    }

    getReviewComments = () => {
        return axios.get('/Reports/ReviewComments/ReviewCommentsReport/ReviewCommentsOverview',
            { params: this.state.searchFilter })
            .then((response) => {
                this.setState({ reviewComments: response.data });
            });
    }

    handleProjectChange = (options) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                projectIds: options.map(item => item.value),
            },
        });
    }

    handleStartDateChange = (newStart) => {
        this.setState({ startDateInput: newStart });
    }

    handleEndDateChange = (newEnd) => {
        this.setState({ endDateInput: newEnd });
    }

    downloadReviewComments = () => {
        return axios.post('/Reports/ReviewComments/ReviewCommentsReport/ReviewCommentDownload',
            this.state.searchFilter);
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPageList: this.sizePerPageList,
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                });
            }
        };

        return (
            <div className='reportContainer'>
                <div className='innerReportContainer'>
                    <Grid fluid>
                        <Row style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            <Col>
                                <h3>Comments Report</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} lg={5}>
                                <FormGroup>
                                    <ControlLabel>Project</ControlLabel>
                                    <br />
                                    <Select multi placeholder="All Projects" value={this.state.searchFilter.projectIds} options={this.state.projectList} autosize={true}
                                        onChange={this.handleProjectChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={5} lg={2}>
                                <FormGroup>
                                    <ControlLabel>Start Date</ControlLabel>
                                    <DatePicker value={this.state.startDateInput} onChange={this.handleStartDateChange} style={{ zIndex: 'auto' }} />
                                </FormGroup>
                            </Col>
                            <Col xs={5} lg={2}>
                                <FormGroup>
                                    <ControlLabel>End Date</ControlLabel>
                                    <DatePicker value={this.state.endDateInput} onChange={this.handleEndDateChange} style={{ zIndex: 'auto' }} />
                                </FormGroup>
                            </Col>
                            <Col xs={1} lg={1}>
                                <ControlLabel>&nbsp;</ControlLabel>
                                <Button onClick={this.handleSearch}>
                                    <Glyphicon glyph="search" style={{ 'marginRight': '0.2rem' }} /> Search
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                            <BootstrapTable data={this.state.reviewComments} options={options} pagination maxHeight="420px">
                                <TableHeaderColumn dataField="commentTypeDesc" isKey dataSort>Comment Type</TableHeaderColumn>
                                <TableHeaderColumn dataField="commentID" dataSort>Comment ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="commentText" dataSort>Text</TableHeaderColumn>
                                <TableHeaderColumn dataField="reviewCount" dataSort>Count</TableHeaderColumn>
                            </BootstrapTable>
                        </Row>
                        <Row style={{ marginBottom: '1.4rem' }}>
                            <Col xs={12}>
                                <hr />
                                <Row style={{ marginBottom: '1.4rem', paddingLeft: '0px' }}>
                                    <Col xs={4} lg={3}>
                                        <LongRunningDownloadAnchor linkText="Export" postRequest={this.downloadReviewComments} displayCSVDownloader={true}
                                            glyph="file" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default CommentsReport;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, SizePerPageDropDown, TableHeaderColumn } from 'react-bootstrap-table';

class PracticeSearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceID: '',
            practiceName: '',
            searchResults: [],
            page: 1,
            sizePerPage: 10
        };
    }

    handlePracticeSearch = (e) => {
        axios.get('/practice/UnEngagedPractices', {
            params: {
                practiceID: this.state.practiceID,
                practiceName: this.state.practiceName
            }
        })
        .then((response) => {
            this.setState({ searchResults: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not search practices.', 'Error');
        });
    }

    handleSetPracticeID = (e) => {
        this.setState({ practiceID: e.target.value });
    }

    handleSetPracticeName = (e) => {
        this.setState({ practiceName: e.target.value });
    }

    handleModalToggle = () => {
        this.props.handleModalToggle();
    }

    handleSelectSearchResult = (rowIndex) => {
        const index = rowIndex + ((this.state.page - 1) * this.state.sizePerPage);
        this.props.handleSelectSearchResult(this.state.searchResults[index]);
        this.handleModalToggle();
    }

    render() {
        const customSelect = (props) => {
            const rowIndex = props.rowIndex;
            return rowIndex !== 'Header' ? (
                <div>
                    <Button onClick={() => this.handleSelectSearchResult(rowIndex)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
            ) : null;
        };
        const selectRowProp = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '6rem'
        };
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                });
            },
            sizePerPageList: [10, 25, 50, 100, 250],
            sizePerPageDropDown: (props) => { return <SizePerPageDropDown variation='dropup' {...props} /> }
        };

        return (
            <Modal className="practiceModal" show={this.props.visible} onHide={this.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Practice Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{'marginBottom': '1.6rem'}}>
                        <Row>
                            <Col sm={5}>
                                <FormGroup>
                                    <ControlLabel style={{'marginRight': '1rem'}}>ID</ControlLabel>
                                    <FormControl type="text" value={this.state.practiceID} placeholder="Practice ID" onChange={this.handleSetPracticeID} />
                                </FormGroup>
                            </Col>
                            <Col sm={5}>
                                <FormGroup>
                                    <ControlLabel style={{'marginRight': '1rem'}}>Name</ControlLabel>
                                    <FormControl type="text" value={this.state.practiceName} placeholder="Practice Name" onChange={this.handleSetPracticeName} />
                                </FormGroup>                            
                            </Col>
                            <Col sm={2}>
                                <FormGroup style={{'marginTop': '2.466rem'}}>
                                    <Button onClick={this.handlePracticeSearch} style={{'float': 'right'}}>
                                        <Glyphicon glyph="search" style={{'marginRight': '0.2rem'}} /> Search
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>                                
                    </Form>
                    <BootstrapTable data={this.state.searchResults} selectRow={selectRowProp} options={options}
                        maxHeight={window.innerHeight - 396} hover pagination>
                        <TableHeaderColumn dataField="groupingID" dataAlign="left" isKey>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="description" dataAlign="left">Name</TableHeaderColumn>
                    </BootstrapTable>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleModalToggle}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PracticeSearchModal;
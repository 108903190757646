import React from 'react';
import axios from 'axios';
import debounce from 'debounce-promise';
import Select from 'react-select';
import { Button, Col, ControlLabel, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import MemberSearchModal from './MemberSearchModal';
import { ClientSource } from '../../enums/ClientSource'

class MemberTypeaheadSearch extends React.Component {
    constructor(props) {
        super(props);
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }
    typeaheadOnBlur = this.props.onBlur ? this.props.onBlur : () => {};

    handleMemberChange = (e) => {
        if (e) {
            this.props.handleMemberChange(e);
        }
        else {
            this.props.handleMemberChange(null);
        }
    }

    handleSelectSearchResult = (e) => {
        if (e) {
            const selectedMember = {
                internalKey: e.internalKey,
                firstName: e.firstName,
                lastName: e.lastName,
                memberID: e.memberID,
                sourceID: e.sourceID,
                label: this.getMemberDropdownLabel(e.lastName, e.firstName,
                    e.memberID, e.sourceID)
            }

            this.props.handleMemberChange(selectedMember);
        }
    }

    handleTypeaheadSearch = debounce((input, callback) => {
        let promise = null;
            promise = axios.get(`/memberService/v1/MemberSearch?memberID=${input}`);

        if (promise) {
            return promise.then((response) => {
                callback(null, {
                    options: response.data.map(x => {
                        return this.buildMemberDropdownOption(x);
                    })
                });
            })
            .catch((error) => {
                console.log(error)
                this.props.toast('error', 'Could not search members.', 'Error');
            });
        }
    }, 1000);

    buildMemberDropdownOption = (searchResult) => {
        return {
            firstName: searchResult.firstName,
            lastName: searchResult.lastName,
            memberID: searchResult.memberID,
            sourceID: searchResult.sourceID,
            internalKey: searchResult.internalKey,
            label: this.getMemberDropdownLabel(searchResult.lastName, searchResult.firstName,
                searchResult.memberID, searchResult.sourceID)
        };
    }

    getMemberDropdownLabel = (lastName, firstName, memberID, sourceID) => {
        const memberName = firstName ? `${lastName}, ${firstName}` : lastName;

        if (sourceID > 0) {
            return `${memberName} (${memberID} - ${ClientSource[sourceID]})`;
        } else {
            return `${memberName} (${memberID})`;
        }
    }

    render() {
        return (
            <FormGroup bsClass="form-group member-typeahead-form-group">
            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Member ID</ControlLabel>
            <Row style={{ margin: 0 }}>
                <Col xs={10} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                <Select.Async
                    autoFocus={false}
                    autoload={false}
                    cache={false}
                    clearable
                    closeOnSelect
                    ignoreCase={false}
                    filterOptions={false}
                    tabSelectsValue
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={false}
                    isLoading={false}
                    value={this.props.value}
                    onChange={this.handleMemberChange}
                    onInputChange={this.handleMemberChange}
                    loadOptions={this.handleTypeaheadSearch}
                    valueKey="internalKey"
                    labelKey="label"
                    onBlur={(e) => this.typeaheadOnBlur(e, 'memberID')} />
                </Col>
                <Col xs={2} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                    <Button onClick={this.props.toggleMemberSearchModal} bsSize="small" style={{ height: '3.4rem', minWidth: '4rem', width: '100%' }}>
                        <Glyphicon glyph="search" />
                    </Button>
                </Col>
            </Row>
            <MemberSearchModal value={this.props.value} visible={this.props.showMemberSearchModal} handleModalToggle={this.props.toggleMemberSearchModal}
                handleSelectSearchResult={this.handleSelectSearchResult} toast={this.props.toast} />
        </FormGroup>
        )
    }
}

export default MemberTypeaheadSearch;
import React from 'react'
import axios from 'axios';
import { Modal, Button, Table } from 'react-bootstrap'
import Loader from 'react-loader';

class CodingDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            loaded: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.radvAuditEnrolleeID && nextProps.radvAuditEnrolleeID !== this.props.radvAuditEnrolleeID) {
            this.setState({ loaded: false });
            axios.get(`/RADVAuditEnrollee/${nextProps.radvAuditEnrolleeID}/CodingDetails`)
            .then((response) => {
                this.setState({
                    records: response.data,
                    loaded: true
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to load.');
                this.setState({ loaded: true });
            });
        }
    }

    formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.toggleModal("CodingDetails")} dialogClassName="modal-dialog-fullwidth">
                <Loader loaded={this.state.loaded}>
                    <Modal.Header closeButton style={{ display: this.state.records.length > 0 ? 'block' : 'none' }}>
                        <Modal.Title>Coding Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div className="landingSearchResults">
                                <Table bordered striped style={{ display: this.state.records.length > 0 ? 'block' : 'none' }}>
                                    <tbody>
                                        <tr>
                                            <th>MRR ID</th>
                                            <th>Member Name</th>
                                            <th>Diagnosis</th>
                                            <th>HCC</th>
                                            <th>DOS From</th>
                                            <th>DOS To</th>
                                            <th>Review Status</th>
                                            <th>Last Updated By</th>
                                            <th>Last Updated</th>
                                        </tr>
                                        {
                                            this.state.records.map((row, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{row.medicalRecordReviewID}</td>
                                                        <td>{row.memberFullName}</td>
                                                        <td>{row.diagnosisCD}</td>
                                                        <td>{row.hccNumber}</td>
                                                        <td>{this.formatDate(row.dateOfServiceFrom)}</td>
                                                        <td>{this.formatDate(row.dateOfServiceTo)}</td>
                                                        <td>{row.reviewStatus}</td>
                                                        <td>{row.updatedUsername}</td>
                                                        <td>{this.formatDate(row.updatedDatetime)}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div style={{ display: this.state.records.length === 0 ? 'block' : 'none' }}>
                                    <h4>No results were returned.</h4>
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleModal("CodingDetails")}>Close</Button>
                    </Modal.Footer>
                </Loader>
            </Modal>
        );
    }
}   

export default CodingDetails
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';

class PracticeTypeSearch extends React.Component {
    constructor(props) {
        super(props);

        this.defaultSearchParams = {
            providerID: '',
            practiceGroupingID: '',
            practiceID: '',
        };

        this.state = {
            searchParams: {...this.defaultSearchParams},
            providerID: '',
            practiceGroupingID: '',
            practiceSearchResults: [],
            practiceID: '',
            searchPracticeID: '',
            practiceData: []
        }
    }

    handleProviderChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{
                    providerID: e ? e.target.value : e,
                    practiceGroupingID: ''
                }
            },
            providerID: e ? e.target.value : e
        });

        this.props.setProviderOrg(e.target.value);
        this.props.setPractice('');
    }

    handlePracticeChange = (e) => {
        this.setState({ 
            searchParams: {
                ...this.state.searchParams,
                ...{ practiceGroupingID: e ? e.target.value : e }
            }
        });

        this.props.setPractice(e.target.value);
    }

    handleSearchClick = () => {
        this.props.getPracticeData(this.state.searchParams, 1, this.props.pageSize);
    }

    clearSearchCriteria = (e) => {
        this.setState({ 
            searchParams: {...this.defaultSearchParams},
            providerID: ''
         });
        this.props.clearSearch();
    }

    handleTextBoxChange = (statePropertyName) => (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{[statePropertyName]: e.target.value}
            }
        });

        this.props.setPracticeID(e.target.value);
    }

    render() {
        //add practices from practice search to options; filter options via provider org selection
        const practices = this.state.providerID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.providerID ? practice : false;
            }).concat(this.state.practiceSearchResults)
            : [...this.props.practices, ...this.state.practiceSearchResults];

        return (
            <Grid fluid>
                <Row style={{marginBottom: '1.4rem', paddingLeft: '13px'}}>
                    <h4>Practice Information</h4>
                </Row>
                <Row style={{marginBottom: '2rem'}}>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Provider Org</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.providerID} onChange={this.handleProviderChange}>
                                <option value="">Select</option>
                                {
                                    this.props.providers.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Practice</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.practiceGroupingID} onChange={this.handlePracticeChange} style={{marginRight: '0.2rem'}}>
                                <option value="">Select</option>
                                {
                                    practices.map((item) => {
                                        return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Practice ID</ControlLabel>
                            <FormControl type="text" value={this.state.searchParams.practiceID} onChange={this.handleTextBoxChange('practiceID')}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Button style={{marginRight: '2rem'}} onClick={this.handleSearchClick}>
                                <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Search
                            </Button>
                            <Button onClick={this.clearSearchCriteria}>
                                Clear Search
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default PracticeTypeSearch;
import React from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import ActionBar from './ActionBar';
import AlertInfo from './AlertInfo';
import AlertResponseContainer from './AlertResponseContainer';
import AlertToastr from '../../Shared/AlertToastr';
import AppointmentInfo from './AppointmentInfo';
import AssignedInfo from './AssignedInfo';
import ImageUploadModal from './ImageUploadModal';
import QueryInfo from './QueryInfo';
import ReprintModal from './ReprintModal';
import WorkflowErrors from './WorkflowErrors';
import WorkflowExceptionsContainer from './WorkflowExceptionsContainer';
import WorkflowHeader from './WorkflowHeader';
import WorkflowHistoryModal from './WorkflowHistoryModal';
import { CDIAlertWorkflowStatus } from '../../../enums/CDIAlertWorkflowStatus';
import { formatISODateOnly }  from '../../../utils/DateUtils';
import { sortBy } from '../../../utils/SortUtils';

//todo: AllowPreExtractQAButton and ShowCoderQA permission checks are needed here
// -- pass them from LandingTabs (parent) + test completedReviewIsEditable
//todo: find a better subroute scheme
const mapStateToProps = state => {
    const currentUserProp = state.session.find(x => x.currentUser);
    return currentUserProp ? currentUserProp : {};
};

class AlertWorkflowContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workflow: {},
            practices: [],
            unengagedPractices: [],
            practiceProviders: [],
            workflowResponses: [],
            queryCodes: [],
            queryResponses: [],
            alertWorkflowStatuses: [],
            users: [],
            uploadedFiles: [],
            workflowExceptions: [],
            workflowExceptionOptions: [],
            appointmentTypes: [],
            receivedTypes: [],
            providerDropdownDisabled: false,
            workflowHistory: [],
            isOOA: this.isOOA(),
            isQueryResponse: this.isQueryResponse(),
            isViewOnly: this.isViewOnly(),
            showImageUploadModal: false,
            showReprintModal: false,
            showWorkflowHistoryModal: false,
            toast: null,
            loaded: false,
            exceptionsExpanded: true,
            assignedExpanded: true,
            alertExpanded: true,
            appointmentExpanded: true,
            queryExpanded: true,
            responseExpanded: true,
            validationMessages: [],
            isDisabledForEpec: false,
        };
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    //todo: for use with the (tbd) consolidated non-ooa/ooa workflow implementation
    isOOA = () => {
        return this.props.location.pathname.toLowerCase().includes("/ooa/");
    }

    isQueryResponse = () => {
        return this.props.location.pathname.toLowerCase().includes("queryresponse");
    }

    isViewOnly = () => {
        return this.props.location.pathname.toLowerCase().includes("viewonly");
    }

    //todo: change to componentWillMount / pre-mount load (switched to componentDidMount to temporarily resolve toast issue)
    //todo: remaining practice/provider data init
    //todo: further combine promises / response handling below(?)
    componentDidMount() {
        Promise.all([this.getWorkflow(), this.getPractices(), this.getAlertDetails(), this.getQueryCodeTypes(),
            this.getQueryResponseTypes(), this.getAlertWorkflowStatuses(), this.getUsers(), this.getFiles(), this.getWorkflowExceptions(),
            this.getWorkflowExceptionOptions(), this.getAppointmentTypes(), this.getConfigValues(), this.getCDIAlertReceivedTypes()])
        .then((responses) => {
            //todo: is this mapping needed?
            //previously "$scope.exceptionComments"
            const workflowExceptionOptions = responses[9].data.map((item) => {
                return {'value': item.cdiAlertWorkflowExceptionOptionID, 'label': item.exceptionOptionText};
            });

            //todo -- what is cdiAlertQueryCodeID 175?
            this.setState({
                workflow: responses[0].data,
                practices: responses[1].data,
                workflowResponses: responses[2].data,
                queryCodes: responses[3].data.filter(x => x.cdiAlertQueryCodeID !== 175).sort(sortBy('cdiAlertQueryCodeDesc')),
                queryResponses: responses[4].data,
                alertWorkflowStatuses: responses[5].data,
                users: responses[6].data,
                uploadedFiles: responses[7].data,
                workflowExceptions: responses[8].data,
                workflowExceptionOptions: workflowExceptionOptions,
                appointmentTypes: responses[10].data,
                providerDropdownDisabled: responses[11].data && JSON.parse(responses[11].data.providerDropdownDisabled),
                receivedTypes: responses[12].data,
                loaded: true
            }, () => {
                if (this.state.workflow.appointmentPracticeCode) {
                    this.getUnengagedPractice();
                    this.getPracticeProviders();
                }
                this.getWorkflowHistory();
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to load workflow data.', 'Error');
        });
    }

    getWorkflow = () => {
        return axios.get(`/cdialertcapture/${this.props.router.params.alertWorkflowID}/capturedata`)
            // .catch((error) => {
            //     this.state.toast('error', 'Capture data was not loaded for this CDI Alert Workflow ID.', 'Error');
            // })
    }

    //populate appointment provider dropdown with system- & user-entered providers for the assigned practice
    getPracticeProviders = (groupingID = this.state.workflow.appointmentPracticeCode) => {
        axios.get(`/practice/${groupingID}/provider`)
        .then((response) => {
            this.setState({ practiceProviders: response.data.systemEntered.concat(response.data.userEntered) });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load provider data.', 'Error');
        });
    }

    //attach provider search modal result to appointment provider options
    attachProviderToPractice = (internalKey) => {
        axios.post(`/practice/${this.state.workflow.appointmentPracticeCode}/provider/${internalKey}`)
        .then((response) => {
            this.getPracticeProviders();
            this.handleWorkflowFieldChange({ appointmentProviderMasterID: internalKey });
            if(response.data.length > 0){
                this.state.toast('error', response.data)
            }
        })
        .catch((error) => {
            this.state.toast('error', 'Could not add provider to practice.', 'Error');
        });
    }

    getPractices = () => {
        return axios.get('/practice')
            // .catch((error) => {
            //     this.state.toast('error', 'Practice data was not loaded.', 'Error');
            // });
    }

    getUnengagedPractice = () => {
        // for use with workflows saved with a selection from the (unengaged) practice search modal
        if (!this.state.practices.find(x => x.groupingID === this.state.workflow.appointmentPracticeCode)) {
            axios.get('/practice/unengagedPractices', {
                params: {
                    practiceID: this.state.workflow.appointmentPracticeCode
                }
            })
            .then((response) => {
                this.setState({ unengagedPractices: [...response.data] });
            })
            .catch((error) => {
                this.state.toast('error', 'Could not load practice data.', 'Error');
            });
        }
    }

    //alert details, aka alert & query responses
    getAlertDetails = () => {
        return axios.get(`/cdialertworkflow/${this.props.router.params.alertWorkflowID}/cdialertdetails`)
            // .catch((error) => {
            //     this.state.toast('error', 'Alert and query response data was not loaded for this CDI Alert Workflow ID.', 'Error');
            // });
    }

    refreshAlertDetails = () => {
        this.getAlertDetails()
        .then((response) => {
            this.setState({
                workflowResponses: response.data
            })
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load Alert Response data.', 'Error');
        });
    }

    getQueryCodeTypes = () => {
        return axios.get('/cdialertworkflowquery/cdialertquerycodetypes')
            // .catch((error) => {
            //     this.state.toast('error', 'Query Code data was not loaded.', 'Error');
            // });
    }

    getQueryResponseTypes = () => {
        return axios.get('/cdialertworkflowquery/cdialertqueryresponsetypes')
            // .catch((error) => {
            //     this.state.toast('error', 'Query Response data was not loaded.', 'Error');
            // });
    }

    getAlertWorkflowStatuses = () => {
        return axios.get('/CDIAlerts/WorkflowStatuses')
            // .catch((error) => {
            //     this.state.toast('error', 'Workflow status data was not loaded.', 'Error');
            // });
    }

    getUsers = () => {
        return axios.get('/merlinuser')
            // .catch((error) => {
            //     this.state.toast('error', 'User data was not loaded.', 'Error');
            // });
    }

    //todo: confirm that strict equality doesn't break uniqueTimes() logic
    //todo: move uniqueness filtering to backend -- no need to have this on the client(?)
    getWorkflowHistory =() => {
        axios.get(`/CDIAlerts/WorkflowHistory/${this.props.router.params.alertWorkflowID}`)
        .then((response) => {
            const workflowHistoryData = response.data.map(x => {
                x.operation = x.operation && x.operation === 'I' ? 'Created' : 'Updated';
                x.updatedDatetime = x.updatedDatetime ? new Date(x.updatedDatetime) : null;
                x.status = this.state.alertWorkflowStatuses ?
                    this.state.alertWorkflowStatuses.filter(y => x.cdiAlertWorkflowStatusID === y.id)[0].description : '';
                
                const userMatch = this.state.users.filter(y => x.updatedUserID === y.userID)[0];
                x.user = userMatch ? userMatch.fullName : null;

                return x;
            });

            this.setState({
                workflowHistory: workflowHistoryData.filter(uniqueTimes).sort(sortBy('updatedDatetime'))
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Workflow history data was not loaded.', 'Error');
        });

        // filter function to check (and remove) workflowHistory for duplicate entries 
        // where display data is the same but the cdiAlertWorkflowHistoryIDs are different
        function uniqueTimes(item, index, arr) {
            return !arr.some(function(element) {
                // get standardized date string for minute-level comparison
                return item.updatedDatetime.toISOString().substring(0, 16) === element.updatedDatetime.toISOString().substring(0, 16)
                    && item.user === element.user
                    && item.operation === element.operation
                    && item.status === element.status
                    && item.cdiAlertWorkflowHistoryID !== element.cdiAlertWorkflowHistoryID;
            })
        }
    }

    getFiles = () => {
        return axios.get(`/cdialerts/${this.props.router.params.alertWorkflowID}/files`)
            // .error((error) => {
            //     this.state.toast('error', 'File uploads were not loaded.', 'Error');
            // });
    }

    refreshFiles = () => {
        this.getFiles()
        .then((response) => {
            this.setState({ uploadedFiles: response.data });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load file uploads.', 'Error');
        });
    }

    addOrUpdateFile = (file) => {
        const tempFiles = [...this.state.uploadedFiles];
        let target = tempFiles.find(x => x.imageID === file.imageID);
        if (target) {
            target = file;
            this.setState({ uploadedFiles: [...tempFiles] });
        }
        else {
            this.setState({ uploadedFiles: [...tempFiles, file] });
        }
    }

    removeFile = (imageID) => {
        this.setState({ uploadedFiles: this.state.uploadedFiles.filter(x => x.imageID !== imageID) });
    }

    // //todo: replace this
    // toLocalDate = (dateString) => {
    //     return new Date(dateString.split('T')[0].replace(/-/g, '/'));
    // }

    getWorkflowExceptions = () => {
        return axios.get(`/cdialerts/${this.props.router.params.alertWorkflowID}/corrections`)
            // .error((error) => {
            //     this.state.toast('error', 'Workflow exceptions were not loaded.', 'Error');
            // });
    }

    refreshWorkflowExceptions = () => {
        this.getWorkflowExceptions()
        .then((response) => {
            this.setState({ workflowExceptions: response.data });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load Workflow Exception data.', 'Error');
        });
    }

    getWorkflowExceptionOptions = () => {
        return axios.get('/cdialerts/corrections/workflowexceptionoptions')
            // .error((error) => {
            //     this.state.toast('error', 'Workflow exception options were not loaded.', 'Error');
            // });
    }

    getAppointmentTypes = () => {
        return axios.get('/cdialerts/appointmenttypes')
            // .error((error) => {
            //     this.state.toast('error', 'Appointment types were not loaded.', 'Error');
            // });
    }

    getCDIAlertReceivedTypes = () => {
        return axios.get('/cdialerts/alertReceivedTypes')
    }

    getConfigValues = () => {
        return axios.get('/securable/config', { params: { configKeys: ["providerDropdownDisabled"] } });
    }

    //todo -- confirm mappings
    getCDIAlertWorkflowDTO = () => {
        return {
            'CDIAlertWorkflowID': this.state.workflow.cdiAlertWorkflowID,
            'ProviderMasterID': this.state.workflow.providerMasterID,
            'GroupingID': this.state.workflow.appointmentPracticeCode,
            'CDIAlertEntityID': this.state.workflow.cdiAlertEntityID,
            'AppointmentProviderMasterID': this.state.workflow.appointmentProviderMasterID,
            'AppointmentDate': this.state.workflow.appointmentDate ?
                formatISODateOnly(new Date(this.state.workflow.appointmentDate)) : null,
            'AppointmentTypeID': this.state.workflow.appointmentTypeID,
            'AltProviderLastName': this.state.workflow.altProviderLastName,
            'AltProviderFirstName': this.state.workflow.altProviderFirstName,
            'DeliveryDate': this.state.workflow.deliveryDate ?
                formatISODateOnly(new Date(this.state.workflow.deliveryDate)) : null,
            'CDIReceivedDate': this.state.workflow.cdiReceivedDate ?
                formatISODateOnly(new Date(this.state.workflow.cdiReceivedDate)) : null,
            'CDIAlertWorkflowStatusID': this.state.workflow.cdiAlertWorkflowStatusID,
            'QueryReceivedFromCoderDate': this.state.workflow.queryReceivedFromCoderDate ?
                formatISODateOnly(new Date(this.state.workflow.queryReceivedFromCoderDate)) : null,
            'QueryForwardedToDoctorDate': this.state.workflow.queryForwardedToDoctorDate ?
                formatISODateOnly(new Date(this.state.workflow.queryForwardedToDoctorDate)) : null,
            'QueryResponseReceivedDate': this.state.workflow.queryResponseReceivedDate ?
                formatISODateOnly(new Date(this.state.workflow.queryResponseReceivedDate)) : null,
            'ProviderTaxID': this.state.workflow.providerTaxID,
            'MedicalRecordNumber' : this.state.workflow.medicalRecordNumber,
            'CDIAlertReceivedTypeID' : this.state.workflow.cdiAlertReceivedTypeID
        };
    }

    saveWorkflow = (showSuccessToast = true) => {
        this.setState({ validationMessages: [] });
        axios.post('/cdialerts', this.getCDIAlertWorkflowDTO())
        .then((response) => {
            if (showSuccessToast) {
                this.state.toast('success', 'Your changes were successfully saved.', '',
                    { timeOut: 2000, extendedTimeOut: 2000 });
                }
            }
        )
        .catch((error) => {
            this.state.toast('error', error.response.data.message, 'Error');
        });
    }

    saveWorkflowForLater = () => {
        this.setState({ validationMessages: [] });
        axios.post('/cdialerts', this.getCDIAlertWorkflowDTO())
        .then((response) => {
            this.state.toast('success', 'Your changes were successfully saved.', '');
            this.redirectToWorkflowSearch();
        })
        .catch((error) => {
            this.state.toast('error', error.response.data.message, 'Error');
        });
    }

    //todo: combine with redirectToLanding()?
    redirectToWorkflowSearch = () => {
        if (window.location.href.search("isPECAddAlert=true") > -1) {
            window.location.href = '/PEC?isPECAddAlert=true';
        }
        else {
            window.location.href = '/PEC';
        }
    }

    completeWorkflow = () => {
        // set user's scrollbar position to the top so the validation messages are in view
        window.scrollTo(0,0);

        const validationMessages = this.validateWorkflow();
        if (validationMessages.length === 0) {
            this.setState({ validationMessages: [] });
            const completeDTO = this.getCDIAlertWorkflowDTO();
            completeDTO.CDIAlertWorkflowStatusID = CDIAlertWorkflowStatus.Captured;

            axios.post('/cdialerts', completeDTO)
            .then((response) => {
                this.state.toast('success', 'Your changes were successfully saved.', '');
                this.redirectToWorkflowSearch();
            })
            .catch((error) => {
                this.state.toast('error', error.response.data.message, 'Error');
            });
        }
        else {
            this.setState({ validationMessages: validationMessages });
        }
    }

    redirectToLanding = () => {
        if (window.location.href.search("isPECViewAlert=true") > -1) {
            //$location.path('/PEC');
            window.location.href = '/PEC';
        }
        else {
            window.history.back();
        }
    }

    //todo: confirm refactored validation logic is working as intended
    validateWorkflow = () => {
        const errorMsgs = [];

        // create new Date instances out of date-only strings (all time components will be set to the same value)
        const start2016 = new Date("1/1/2016");
        const start2017 = new Date("1/1/2017");
        const currentDay = new Date(formatISODateOnly(new Date()));
        //delivery date is "Date CDI Distributed"
        const deliveryDate = this.state.workflow.deliveryDate ?
            new Date(formatISODateOnly(new Date(this.state.workflow.deliveryDate))) : null;
        const appointmentDate = this.state.workflow.appointmentDate ?
            new Date(formatISODateOnly(new Date(this.state.workflow.appointmentDate))) : null;
        const cdiReceivedDate = this.state.workflow.cdiReceivedDate ?
            new Date(formatISODateOnly(new Date(this.state.workflow.cdiReceivedDate))) : null;
        const queryReceivedFromCoderDate = this.state.workflow.queryReceivedFromCoderDate ?
            new Date(formatISODateOnly(new Date(this.state.workflow.queryReceivedFromCoderDate))) : null;
        const queryForwardedToDoctorDate = this.state.workflow.queryForwardedToDoctorDate ?
            new Date(formatISODateOnly(new Date(this.state.workflow.queryForwardedToDoctorDate))) : null;
        const queryResponseReceivedDate = this.state.workflow.queryResponseReceivedDate ?
            new Date(formatISODateOnly(new Date(this.state.workflow.queryResponseReceivedDate))) : null;

        //validation is based on these appointment types:
        // 1: "Regular, CDI Generated Prior to Appt."
        // 2: "Regular, Blank CDI"
        // 3: "Walk-In, No CDI Generated"
        // 4: "Pre-Print Office"
        // 5: "Pre-Print Office, Blank CDI"
        // 6: "Cancelled, Missed, No Show"
        // 7: "Labs only- No PCP visit" - same as cancelled for now
        const appointmentTypeRequiredFields = {
            1: ["practice", "provider", "appointmentDate", "deliveryDate", "cdiReceivedDate", "fileAttached"],
            2: ["practice", "provider", "appointmentDate", "deliveryDate", "cdiReceivedDate"],
            3: ["practice", "provider", "appointmentDate"],
            4: ["practice", "provider", "appointmentDate", "cdiReceivedDate", "fileAttached"],
            5: ["practice", "provider", "appointmentDate"],
            6: ["practice", "appointmentDate", "deliveryDate"],
            7: ["practice", "appointmentDate", "deliveryDate"],
            8: ["practice", "provider", "appointmentDate", "cdiReceivedDate", "fileAttached"],
            9: ["practice", "provider", "appointmentDate", "cdiReceivedDate", "fileAttached"],
            11: ["practice", "provider", "appointmentDate", "deliveryDate", "cdiReceivedDate"]
        };

        if (!this.state.workflow.appointmentTypeID) {
            errorMsgs.push('Appointment Type is required.');
        }
        else {
            //Prevent blank appointment types from being selected for populated alerts
            if ([2, 5].includes(this.state.workflow.appointmentTypeID) && this.state.workflowResponses.length > 0) {
                errorMsgs.push('The selected appointment type is not allowed for non-blank alerts.');
            }
            //practice
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("practice") && !this.state.workflow.appointmentPracticeCode) {
                errorMsgs.push('Appointment Practice is required.');
            }

            //provider
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("provider") && !this.state.workflow.appointmentProviderMasterID
                && !this.state.workflow.altProviderFirstName && !this.state.workflow.altProviderLastName) {
                errorMsgs.push('Appointment Provider is required.');
            }

            //appointmentDate
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("appointmentDate")) {
                if (!appointmentDate) {
                    errorMsgs.push('Appointment Date is required.');
                }
                else {
                    if (appointmentDate < start2016) {
                        errorMsgs.push('Appointment Date must be after 12/31/2015.');
                    }
                    if (appointmentDate > currentDay) {
                        errorMsgs.push('Appointment Date cannot be in the future.');
                    }
                }
            }

            //deliveryDate
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("deliveryDate")) {
                if (!deliveryDate) {
                    errorMsgs.push('Date CDI Distributed is required.');
                }
                else {
                    if (deliveryDate < start2016) {
                        errorMsgs.push('Date CDI Distributed cannot be earlier than 1/1/2016.');
                    }
                }
            }

            //cdiReceivedDate
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("cdiReceivedDate")) {
                if (!cdiReceivedDate) {
                    errorMsgs.push('Date CDI Received is required.');
                }
                else {
                    if (cdiReceivedDate < start2017) {
                        errorMsgs.push('Date CDI Received cannot be earlier than 1/1/2017.');
                    }
                    if (cdiReceivedDate > currentDay) {
                        errorMsgs.push('Date CDI Received cannot be in the future.');
                    }
                }
            }

            //appointmentDate & cdiReceivedDate
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("appointmentDate") &&
                appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("cdiReceivedDate") &&
                appointmentDate && cdiReceivedDate) {

                if (appointmentDate > cdiReceivedDate) {
                    errorMsgs.push('Appointment Date must be on or before Date CDI Received.');
                }
            }

            //appointmentDate & deliveryDate
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("appointmentDate") &&
                appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("deliveryDate") &&
                appointmentDate && deliveryDate) {

                if (appointmentDate < deliveryDate) {
                    errorMsgs.push('Date CDI Distributed must be on or before Appointment Date.');
                }
            }

            if (this.state.workflow.appointmentTypeID === 8 && deliveryDate) {
                errorMsgs.push('Date CDI Distributed must be not be selected for Provider Initiated appointments.');
            }

            //Query Data Validation
            if (this.state.workflow.cdiAlertWorkflowStatusID === CDIAlertWorkflowStatus.QueryInitiated) {
                const hasQueryCodeSelection = this.state.workflowResponses.filter(
                    x => x.alertDetails.find(y => y.selectedQueryCode)).length > 0;
                const hasQueryResponseSelection = this.state.workflowResponses.filter(
                    x => x.alertDetails.find(y => y.selectedQueryResponse)).length > 0;

                const receivedFromCoderDateErrorMsg = 'Received from Coder is required.';
                const responseReceivedDateErrorMsg = 'Response Received is required.';
                const forwardedToDoctorDateErrorMsg = 'Forwarded to Doctor is required.';

                if (hasQueryCodeSelection && !queryReceivedFromCoderDate) {
                    errorMsgs.push('Received from Coder is required when Query Codes are selected.');
                }

                if (queryResponseReceivedDate) {
                    if (queryForwardedToDoctorDate) {
                        if (queryResponseReceivedDate < queryForwardedToDoctorDate) {
                            errorMsgs.push('Forwarded to Doctor must be on or before Response Received.');
                        }
                    }
                    else {
                        errorMsgs.push(forwardedToDoctorDateErrorMsg);
                    }
                }
                else {
                    if (hasQueryCodeSelection && hasQueryResponseSelection && queryForwardedToDoctorDate) {
                        errorMsgs.push('Query Codes and Query Responses must not be selected when Response Received is null.');
                    }
                }

                if (queryForwardedToDoctorDate) {
                    if (queryReceivedFromCoderDate) {
                        if (queryForwardedToDoctorDate < queryReceivedFromCoderDate) {
                            errorMsgs.push('Received from Coder must be on or before Forwarded to Doctor.');
                        }
                    }
                    else {
                        errorMsgs.push(receivedFromCoderDateErrorMsg);
                    }

                    if (!queryResponseReceivedDate) {
                        errorMsgs.push(responseReceivedDateErrorMsg);
                    }
                }

                if (queryReceivedFromCoderDate) {
                    if (cdiReceivedDate) {
                        if (queryReceivedFromCoderDate < cdiReceivedDate) {
                            errorMsgs.push('Date CDI Received must be on or before Received from Coder.');
                        }
                    }

                    if (queryResponseReceivedDate) {
                        //cdiAlertImageFileType 'Q' == Query Response
                        if (!this.state.uploadedFiles.find(x => x.cdiAlertImageFileType === 'Q')) {
                            errorMsgs.push('At least one file of type Query Response must be uploaded to complete the alert');
                        }
                    }
                }

                if (hasQueryResponseSelection) {
                    if (!queryReceivedFromCoderDate) {
                        if (!errorMsgs.includes(receivedFromCoderDateErrorMsg)) {
                            errorMsgs.push('Received from Coder is required.');
                        }
                    }

                    if (!queryResponseReceivedDate) {
                        if (!errorMsgs.includes(responseReceivedDateErrorMsg)) {
                            errorMsgs.push(responseReceivedDateErrorMsg);
                        }
                    }

                    if (!queryForwardedToDoctorDate) {
                        if (!errorMsgs.includes(forwardedToDoctorDateErrorMsg)) {
                            errorMsgs.push(forwardedToDoctorDateErrorMsg);
                        }
                    }
                }

                if (queryResponseReceivedDate) {
                    if (this.state.workflowResponses.filter(x =>
                            x.alertDetails.find(y => y.selectedQueryCode && !y.selectedQueryResponse)
                        ).length > 0) {
                        errorMsgs.push('If Response Received has a value and any Query Code fields have a value, then the corresponding Query Response field must have a value');
                    }
                }
            }

            //fileAttached
            if (appointmentTypeRequiredFields[this.state.workflow.appointmentTypeID].includes("fileAttached")
                && this.state.workflow.cdiAlertWorkflowStatusID !== CDIAlertWorkflowStatus.Exception) {
                if (this.state.uploadedFiles.length === 0) {
                    errorMsgs.push('At least one file must be uploaded to complete the alert (appointment type restriction).');
                }
            }
        }

        return errorMsgs;
    }

    reprintAlert = (includeHeaders) => {
        axios.post(`/cdialerts/${this.props.router.params.alertWorkflowID}/PrintAlert?includeHeaders=${includeHeaders}`)
        .then((response) => {
            this.toggleReprintModal();
            this.refreshFiles();
            this.state.toast('success', 'Alert reprinted successfully.', '');
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to reprint alert. Please try again.', 'Error');
        });
    }

    toggleReprintModal = () => {
        this.setState({ showReprintModal: !this.state.showReprintModal });
    }

    //todo: add loading animation for grid(?)
    toggleWorkflowHistoryModal = () => {
        if (!this.state.isViewOnly && this.state.showWorkflowHistoryModal) {
            this.saveWorkflow(false);
        }
        this.getWorkflowHistory();
        this.setState({ showWorkflowHistoryModal: !this.state.showWorkflowHistoryModal });
    }

    //todo: add loading animation for grid(?)
    toggleImageUploadModal = () => {
        if (!this.state.isViewOnly) {
            this.saveWorkflow(false);
        }
        this.setState({ showImageUploadModal: !this.state.showImageUploadModal });
    }

    handleWorkflowFieldChange = (updateObj) => {
        this.setState({
            workflow: { ...this.state.workflow, ...updateObj }
        });
    }

    handleAppointmentTypeChange = (value) => {
        if (this.state.workflow.outboundTypeID !== 6 && value === 11) {
            this.state.toast('warning', 'Appointment Type- EPEC is for EMR practices with Digital solutions only.', 'Warning');
            this.setState({ isDisabledForEpec: true });
        } 
        else if (this.state.workflow.outboundTypeID !== 7 && value === 12) {
            this.state.toast('warning', 'Appointment Type- Alert File Exchange is for EMR practices with Digital solutions only.', 'Warning');
            this.setState({ isDisabledForEpec: true });
        }
        else if (this.state.isDisabledForEpec) {
            this.setState({ isDisabledForEpec: false });
        }

        this.handleWorkflowFieldChange({ appointmentTypeID: value });
    }

    handleWorkflowPracticeChange = (practice) => {
        const addedViaModal = Boolean(practice.groupingID);
        const groupingID = addedViaModal ? practice.groupingID : practice;
        if (groupingID) {
            this.getPracticeProviders(groupingID)
        }

        this.setState({
            workflow: {
                ...this.state.workflow,
                appointmentPracticeCode: groupingID,
                appointmentProviderMasterID: '',
            },
            unengagedPractices: addedViaModal &&
                !this.state.unengagedPractices.find(x => x.groupingID === groupingID) ?
                [...this.state.unengagedPractices, {
                    groupingID: practice.groupingID,
                    description: practice.description
                }]
                : this.state.unengagedPractices
        });
    }

    toggleSection = (sectionName) => {
        switch (sectionName) {
            case 'exceptions':
                this.setState({ exceptionsExpanded: !this.state.exceptionsExpanded });
                break;
            case 'assigned':
                this.setState({ assignedExpanded: !this.state.assignedExpanded });
                break;
            case 'alert':
                this.setState({ alertExpanded: !this.state.alertExpanded });
            break;
            case 'appointment':
                this.setState({ appointmentExpanded: !this.state.appointmentExpanded });
                break;
            case 'query':
                this.setState({ queryExpanded: !this.state.queryExpanded });
                break;
            case 'response':
                this.setState({ responseExpanded: !this.state.responseExpanded });
                break;
            default:
                return;
        }
    }

    render() {
        const practices = this.state.unengagedPractices.concat(this.state.practices);
        return (
            <div>
                <Loader loaded={this.state.loaded}>
                {
                    this.state.loaded &&
                    <div>
                        <ActionBar toggleImageUploadModal={this.toggleImageUploadModal} toggleReprintModal={this.toggleReprintModal}
                            toggleWorkflowHistoryModal={this.toggleWorkflowHistoryModal} saveWorkflow={this.saveWorkflow}
                            saveWorkflowForLater={this.saveWorkflowForLater} completeWorkflow={this.completeWorkflow}
                            redirectToLanding={this.redirectToLanding} isViewOnly={this.state.isViewOnly}
                            showReprint={this.state.workflow.cdiAlertWorkflowStatusID === CDIAlertWorkflowStatus.Created}
                            showWorkflowHistory={this.state.workflowHistory.length > 0} epecDisabled={this.state.isDisabledForEpec} />
                        <WorkflowHeader workflow={this.state.workflow} />
                        <WorkflowErrors validationMessages={this.state.validationMessages} />
                        <WorkflowExceptionsContainer workflow={this.state.workflow} workflowExceptionOptions={this.state.workflowExceptionOptions}
                            workflowExceptions={this.state.workflowExceptions} currentUser={this.props.currentUser}
                            refreshWorkflowExceptions={this.refreshWorkflowExceptions} isViewOnly={this.state.isViewOnly}
                            expanded={this.state.exceptionsExpanded} toggleSection={this.toggleSection}
                            toast={this.state.toast} />
                        <AssignedInfo workflow={this.state.workflow} handleWorkflowFieldChange={this.handleWorkflowFieldChange}
                            isViewOnly={this.state.isViewOnly} expanded={this.state.assignedExpanded}
                            toggleSection={this.toggleSection} />
                        <AlertInfo workflow={this.state.workflow} handleWorkflowFieldChange={this.handleWorkflowFieldChange}
                            isViewOnly={this.state.isViewOnly} expanded={this.state.alertExpanded} receivedTypes={this.state.receivedTypes}
                            toggleSection={this.toggleSection} />
                        <AppointmentInfo workflow={this.state.workflow} practices={practices}
                            practiceProviders={this.state.practiceProviders} appointmentTypes={this.state.appointmentTypes}
                            handleWorkflowPracticeChange={this.handleWorkflowPracticeChange} attachProviderToPractice={this.attachProviderToPractice}
                            providerDropdownDisabled={this.state.providerDropdownDisabled} handleWorkflowFieldChange={this.handleWorkflowFieldChange}
                            onAppointmentTypeChange={this.handleAppointmentTypeChange} isViewOnly={this.state.isViewOnly}
                            expanded={this.state.appointmentExpanded} toggleSection={this.toggleSection} toast={this.state.toast} />
                        <QueryInfo workflow={this.state.workflow} handleWorkflowFieldChange={this.handleWorkflowFieldChange}
                            isViewOnly={this.state.isViewOnly} expanded={this.state.queryExpanded}
                            toggleSection={this.toggleSection} />
                        <AlertResponseContainer workflow={this.state.workflow} workflowResponses={this.state.workflowResponses}
                            isQueryResponse={this.state.isQueryResponse} isViewOnly={this.state.isViewOnly}
                            queryCodes={this.state.queryCodes} queryResponses={this.state.queryResponses}
                            refreshAlertDetails={this.refreshAlertDetails} expanded={this.state.responseExpanded}
                            toggleSection={this.toggleSection} toast={this.state.toast} />
                        <ImageUploadModal workflowStatusID={this.state.workflow.cdiAlertWorkflowStatusID} uploadedFiles={this.state.uploadedFiles}
                            visible={this.state.showImageUploadModal} handleModalToggle={this.toggleImageUploadModal}
                            isViewOnly={this.state.isViewOnly} isOOA={this.state.isOOA} alertWorkflowID={this.props.router.params.alertWorkflowID}
                            addOrUpdateFile={this.addOrUpdateFile} removeFile={this.removeFile} allowManagePDF={Boolean(this.state.workflow.appointmentDate)}
                            toast={this.state.toast} />
                    </div>
                }
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} className="toast-top-right-below-nav" />
                <WorkflowHistoryModal workflowHistory={this.state.workflowHistory} visible={this.state.showWorkflowHistoryModal}
                    handleModalToggle={this.toggleWorkflowHistoryModal} />
                <ReprintModal visible={this.state.showReprintModal} handleModalToggle={this.toggleReprintModal} handleReprintAlert={this.reprintAlert} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(AlertWorkflowContainer);
import React from 'react';
import {Checkbox,  Modal } from 'react-bootstrap';

class NLPReviewCommentModal extends React.Component {
    mapComments = () => {
        return this.props.reviewType !== "CompletedCoding" ?
            this.mapEditableComments() : this.mapReadOnlyComments();
    }

    mapEditableComments = () => {
        if (this.props.comments && this.props.comments.length > 0) {
            return this.props.comments.map(x => {
                    //CommentGroupID 16 == pec correction comments
                    if (x.commentGroupID === 16) {
                        return !this.props.reviewHasAddedRecords ? (
                            <li key={x.commentDefinitionID}>
                                <Checkbox onChange={() => this.commentCheckboxChanged(x)} name="selectedComments"
                                    id={x.commentGroupID} checked={x.isChecked} className="commentCheckbox">
                                    {x.commentText}
                                </Checkbox>
                            </li>
                        ) : null;
                    }
                    else {
                        return (
                            <li key={x.commentDefinitionID}>
                                <Checkbox onChange={() => this.commentCheckboxChanged(x)} name="selectedComments"
                                    id={x.commentGroupID} checked={x.isChecked} className="commentCheckbox">
                                    {x.commentText}
                                </Checkbox>
                            </li>
                        );
                    }
                }
            )
        }
        else {
            return <li><label>No comments are available.</label></li>
        }
    }

    mapReadOnlyComments = () => {
        if (this.props.comments.find(x => x.isChecked)) {
            return this.props.comments.filter(y => y.isChecked).map(z => <li key={z.commentDefinitionID}><label>{z.commentText}</label></li>);
        }
        else {
            return <li><label>No comments were selected.</label></li>
        }
    }

    commentCheckboxChanged = (comment) => {
        comment.isChecked = !comment.isChecked;
        if (comment.commentGroupID === '16') {
            this.props.setPECCorrectionCommentSelected(false)
        }
        this.props.setSelectedComments([...this.props.comments.filter(x => x.commentDefinitionID !== comment.commentDefinitionID), comment]);
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-large-vertical-alignment" show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul style={{listStyle: 'none'}}>
                    {
                        this.mapComments()
                    }
                    </ul>
                </Modal.Body>
            </Modal>
        );
    }
}

export default NLPReviewCommentModal;
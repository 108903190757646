import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router';
import AlertToastr from '../../Shared/AlertToastr';
import { formatDatetimeString } from '../../../utils/DateUtils';
import { replace } from 'lodash';
import { getRedirect } from '../../../services/ReviewProcessing';

class CampaignManagementSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            campaigns: [],
            toast: null
        };
    }

    componentDidMount() {
        this.getCampaignManagementSummary();
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }
    
    getCampaignManagementSummary = () => {
        axios.get('/campaignCoderAssignment')
        .then((response) => {
            this.setState({
                campaigns: response.data
            })
        })
        .catch((error) => {
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else {
                this.state.toast('error', 'Could not get campaign management summary.', 'Error');
            }
        })
    }

    formatCampaignLink = (cell, row) => {
        return (
            <Link to={`/CampaignManagement/campaign/${row.campaignID}`} replace>{cell}</Link>
        )
    }

    formatProjectLink = (cell, row) => {
        return (
            <Link to={`/CampaignManagement/project/${row.projectID}/campaign/${row.campaignID}`} replace>{cell}</Link>
        )
    }

    handleEscalationQueueRouting = () => {
        this.props.router.push('/EscalationQueue');
    }

    formatDateString = (date) => {
        return formatDatetimeString(date);
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={9} style={{ padding: '0rem' }}>
                        <h3>Coder Campaign Assignment Management</h3>
                    </Col>
                    <Col xs={3}  style={{ paddingTop: '1rem', paddingRight: '0rem' }}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={this.handleEscalationQueueRouting}>Escalation Queue</Button>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <BootstrapTable data={this.state.campaigns} bordered>
                        <TableHeaderColumn dataField="projectID" hidden isKey></TableHeaderColumn>
                        <TableHeaderColumn dataField="year" dataSort>Year</TableHeaderColumn>
                        <TableHeaderColumn dataField="campaignName" dataSort dataFormat={this.formatCampaignLink}>Campaign</TableHeaderColumn>
                        <TableHeaderColumn dataField="projectName" dataSort dataFormat={this.formatProjectLink}>Project</TableHeaderColumn>
                        <TableHeaderColumn dataField="campaignRetrievalTypeDescription" dataSort>Retrieval Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="codingCompletionDate" dataFormat={this.formatDateString} dataSort>Coding Completion Date</TableHeaderColumn>
                        <TableHeaderColumn dataField="groupingCount" dataSort>Practice Grouping Count</TableHeaderColumn>
                        <TableHeaderColumn dataField="totalCharts" dataSort>Total Reviews</TableHeaderColumn>
                        <TableHeaderColumn dataField="chartsRemaining" dataSort>Review Remaining Count</TableHeaderColumn>
                        <TableHeaderColumn dataField="campaignStatusDescription" dataSort>Campaign Status</TableHeaderColumn>
                    </BootstrapTable>
                </Row>
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        )
    }
}

export default CampaignManagementSummary;
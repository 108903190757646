import React from 'react';
import { Col, Panel, Table } from 'react-bootstrap';

//todo: this can be combined with SubmittedEnrolleeRADV
class EnrolleeRADV extends React.Component {
    render() {
        return this.props.radvEnrollee ? (
            <Col xs={12} md={9}>
                <Panel className="enrolleePanel">
                    <h5> Enrollee RADV Information </h5>
                    <Table striped bordered className="enrolleeTable">
                        <thead>
                            <tr>
                                <th>Enrollee ID</th>
                                <th>Beneficiary ID</th>
                                <th>Enrollee Name</th>
                                <th>Enrollee DOB</th>
                                <th>Current HMOID</th>
                                <th>Sample Year HMOID</th>
                                <th>CMS Targeted HCC(s)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.props.radvEnrollee.enrolleeID}</td>
                                <td>{this.props.radvEnrollee.beneficiaryID}</td>
                                <td>{this.props.radvEnrollee.enrolleeName}</td>
                                <td>{new Date(this.props.radvEnrollee.enrolleeDOB).toLocaleDateString('en-US')}</td>
                                <td>{this.props.radvEnrollee.currentHMOID}</td>
                                <td>{this.props.radvEnrollee.sampleYearHMOID}</td>
                                <td>{this.props.radvEnrollee.cmsTargetedHCCs !== undefined ? this.props.radvEnrollee.cmsTargetedHCCs.join(", ") : ""}</td>
                                <td>{this.props.radvEnrollee.statusDesc}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Panel>
            </Col>
        ) : null;
    }
}

export default EnrolleeRADV;
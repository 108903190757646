import React from 'react';
import axios from 'axios';
import Error from '../../Shared/Error';

class DOCXViewer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showImageError: false
        }
    }

    componentDidMount() {

        axios.get(this.props.uri)
            .then((response) => {
                const docEl = document.createElement('div');
                docEl.innerHTML = response.data;
                document.getElementById('docx').innerHTML = docEl.outerHTML;
            })
            .catch((error) => {
                this.setState({
                    showImageError: true
                });
            });
    }

    render() {
        return (
            <div style={{ height: '800px' }}>
                {
                    <div id="docx">
                    </div>
                }
                {
                    this.state.showImageError &&
                    <Error style={{ marginTop: '5rem' }} />
                }
            </div>
        );
    }
}

export default DOCXViewer;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import Select from 'react-select';
import AlertDialog from '../../Shared/AlertDialog';
import PracticeSearchModal from '../../Shared/PracticeSearchModal';
import { PackageType } from '../../../enums/PackageType';
import { HttpCode } from '../../../enums/HttpCode';

class CreatePackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providerID: '',
            practiceGroupingID: '',
            showPracticeModal: false,
            showConfirmCreateModal: false,
            practiceSearchResults: [],
            packageTypeID: PackageType.Normal,
            packageTypeIDs: [
                { value: 1, label: "Appointment" }
                ,{ value: 2, label: "Pre-Print/Remote" }
                ,{ value: 3, label: "Hybrid" }
            ]
        };
    }

    handleProviderChange = (e) => {
        this.setState({
            providerID: e.target.value,
            practiceGroupingID: ''
        });
    }

    handlePracticeChange = (e) => {
        if(e.target) {
            this.setState({ practiceGroupingID: e.target.value });
        }
        else {
            const foundPractice = {
                groupingID: e.groupingID,
                description: e.description
            };
            this.setState({ 
                practiceSearchResults: [...this.state.practiceSearchResults, foundPractice],
                practiceGroupingID: e.groupingID
            });
        }
    }

    handlePackageTypeChange = (e) => {
        this.setState({ packageTypeID: e.value });
    }

    handleFindPractice = () => {
        this.setState({ showPracticeModal: !this.state.showPracticeModal });
    }

    handleConfirmCreate = () => {
        this.setState({ showConfirmCreateModal: !this.state.showConfirmCreateModal });
    }

    //attempt to create package. if the selected practice has current packages, launch the confirmation dialog
    createPackage = (forceCreate) => {
        axios.post('/packages', {
              groupingID: this.state.practiceGroupingID,
              packageTypeID: this.state.packageTypeID,
              forceCreatePackage: forceCreate
        })
        .then((response) => {
            //redirect to member selection screen
            this.props.router.push("/AlertPackages/" + response.data.packageID);
        })
        .catch((error) => {
            //handle conflict (package already exists) - display confirmation modal
            if(error.response && error.response.status === HttpCode.Conflict) {
                this.handleConfirmCreate();
            }
            else {
                this.props.toast('error', 'Could not create package.', 'Error');
            }
        });
    }

    render() {
        //add practices from practice search to options; filter options via provider org selection
        const practices = this.state.providerID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.providerID ? practice : false;
            }).concat(this.state.practiceSearchResults)
            : this.props.practices.concat(this.state.practiceSearchResults);

        return (
            <div>
                <Grid fluid>
                    <Row style={{'marginBottom': '1.4rem', 'paddingLeft': '13px'}}>
                        <h4>New Package</h4>
                    </Row>
                    <Row style={{'marginBottom': '1rem'}}>
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>Provider Org</ControlLabel>
                                <FormControl componentClass="select" placeholder="Select" value={this.state.provider} onChange={this.handleProviderChange}>
                                    <option value="">Select</option>
                                    {
                                        this.props.providers.map((item) => {
                                            return <option key={item.id} value={item.id}>{item.description}</option>
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>Practice</ControlLabel>
                                <div>
                                    <FormControl componentClass="select" placeholder="Select" value={this.state.practiceGroupingID} onChange={this.handlePracticeChange} style={{'marginRight': '0.2rem', 'width': '83%', 'display': 'inline-block'}}>
                                        <option value="">Select</option>
                                        {
                                            practices.map((item) => {
                                                return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                                            })
                                        }
                                    </FormControl>
                                    <Button onClick={this.handleFindPractice} style={{'verticalAlign': 'baseline'}}>
                                        <Glyphicon glyph="search" />
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{'marginBottom': '1rem'}}>
                        <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>Package Type</ControlLabel>
                                <Select 
                                    value={this.state.packageTypeID}
                                    options={this.state.packageTypeIDs}
                                    onChange={this.handlePackageTypeChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Button onClick={() => this.createPackage(false)} disabled={!this.state.practiceGroupingID}>
                                    <Glyphicon glyph="plus" style={{'marginRight': '0.2rem'}} /> Create New Package
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Grid>
                <PracticeSearchModal visible={this.state.showPracticeModal} handleModalToggle={this.handleFindPractice}
                    handleSelectSearchResult={this.handlePracticeChange} toast={this.props.toast} />
                <AlertDialog visible={this.state.showConfirmCreateModal} handleModalToggle={this.handleConfirmCreate} title={"Package In Progress"}
                    message={"A package is already in progress for this practice selection. Create another?"} handleConfirm={() => this.createPackage(true)}
                    confirmLabel={"Create"} cancelLabel={"Close"} confirmStyle={"success"} glyphicon={"ok"} />
            </div>
        );  
    }
}

export default CreatePackage;
import React from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import Loader from 'react-loader';
import AlertToastr from '../Shared/AlertToastr';
import CDIAlertWorkflows from './AlertWorkflowSearch/CDIAlertWorkflows';
import FaxStatus from './FaxStatus/FaxStatus';
import Packages from '../PEC/AlertPackages/Packages';
import PracticeInfo from './PracticeInfo/PracticeInfo';
import PracticeType from './PracticeType/PracticeType';
import ScanRosterContainer from './ScanRoster/ScanRosterContainer';
import { Securable } from '../../enums/Securable';
import { SecurableType } from '../../enums/SecurableType';
import { sortBy } from '../../utils/SortUtils';

class PECTabs extends React.Component {
    constructor(props) {
        super(props);
        const activeTabID = this.getActiveTab();
        this.state = {
            activeTab: activeTabID,
            loadedTabs: [activeTabID],
            landingSecurables: [],
            showPracticeInfoTab: false,
            practices: [],
            providerOrgs: [],
            allProviderOrgs: [],
            allPractices: [],
            outboundTypes: [],
            validationStatuses: [],
            users: [],
            loaded: false,
            toast: null,
            assignedPECs: []
        };
        this.showFailedFaxExceptionsQueue = [];
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    // to-do: getOutboundTypes() and getValidationStatuses() are used for PracticeType & PracticeInfo
    // -- only load these if those tabs are displayed
    componentWillMount() {
        Promise.all([
            this.getSecurables(),
            this.getSecurableConfigs(),
            this.getPractices(),
            this.getAllPractices(),
            this.getOutboundTypes(),
            this.getValidationStatuses(),
            this.getUsers(),
            this.refreshAssignedPECs()
        ])
        .then((responses) => {
            this.setState({
                landingSecurables: responses[0].data,
                showPracticeInfoTab: Boolean(responses[1].data.showPECPracticeInfoTab),
                providerOrgs: this.getUniqueProviderOrgs(responses[2].data),
                practices: responses[2].data,
                allProviderOrgs: this.getUniqueProviderOrgs(responses[3].data),
                allPractices: responses[3].data,
                outboundTypes: responses[4].data,
                validationStatuses: responses[5].data,
                users: responses[6].data,
                assignedPECs: this.getUniqueAssignedPECs(responses[7].data),
                loaded: true
            });
        })
        .catch((error) => {
            window.location.href = "/Error";
        });
    }

    getSecurables = () => {
        return axios.get('/securable', {
            params: {
                securableTypes: [SecurableType.MRRLandingPageTab]
            }
        });
    }

    getSecurableConfigs = () => {
        return axios.get('/securable/config', {
            params: {
                configKeys: ["showPECPracticeInfoTab"]
            }
        });
    }

    getPractices = () => {
        return axios.get('/practice');
    }

    getAllPractices = () => {
        return axios.get('/practice/AllPractices');
    }

    //get unique provider organizations & associated descriptions via practice data
    getUniqueProviderOrgs = (practiceData) => {
        return practiceData.reduce((uniqueProviderOrgs, practice) => {
            if (uniqueProviderOrgs.findIndex(p => p.id === practice.providerOrganizationID) === -1) {
                uniqueProviderOrgs.push({
                    id: practice.providerOrganizationID,
                    description: practice.providerOrganizationDescription + " - " + practice.providerOrganizationID
                });
            }
            return uniqueProviderOrgs;
        }, []).sort(sortBy('description'));
    }

    getOutboundTypes = () => {
        return axios.get('/practice/PracticeOutboundTypes');
    }

    getValidationStatuses = () => {
        return axios.get('/practice/PracticeValidationStatuses');
    }

    getUsers = () => {
        return axios.get('/MerlinUser');
    }

    refreshAssignedPECs = () => {
        return axios.get('/Practice/UserAssignments');
    }

    //returns an obj array of unique user IDs + names
    getUniqueAssignedPECs = (userAssignments) => {
        return userAssignments.reduce((a, b) => {
            if (b.userID && b.userName && a.findIndex(x => x.userID === b.userID) === -1) {
                a.push({ userID: b.userID, userName: b.userName });
            }
            return a;
        }, []);
    }

    updateAssignedPECs = (userID, userName) => {
        if (!this.state.assignedPECs.find(assignedPEC => assignedPEC.userID === userID)) {
            this.setState({
                assignedPECs: [...this.state.assignedPECs, { userID, userName }]
            });
        }
    }

    showFailedFaxExceptions = () => {
        if (this.showFailedFaxExceptionsQueue.length === 0)
        {
            const request = axios.get('/practice/FailedFaxPracticeValidations');
            this.showFailedFaxExceptionsQueue.push(request);
            request.then(response => {
                let faxExceptionCount = response.data.length;
                if (faxExceptionCount > 0) {
                    if (faxExceptionCount === 1)
                    {
                        this.state.toast('warning', <div><b>Practice {response.data[0].practiceCode}</b>
                            &nbsp;is in failed fax status. Please review.</div>, '', { tapToDismiss: false });
                    }
                    else {
                        this.state.toast('warning', 'Multiple practices are in failed fax status. Please review.', '', { tapToDismiss: false });
                    }
                }
                this.showFailedFaxExceptionsQueue.pop();
            })
            .catch((error) => {
                this.state.toast('error', 'Could not load alert exceptions.', 'Error');
                this.showFailedFaxExceptionsQueue.pop();
            });
        }
    }

    getActiveTab = () => {
        let activeTab = 1;
        if (this.props.location.query.tab)
        {
            if (this.props.location.query.tab === "Packages")
            {
                activeTab = 2;
            }
            else if (this.props.location.query.tab === "PracticeInfo")
            {
                activeTab = 3;
            }
            else if (this.props.location.query.tab === "PracticeType")
            {
                activeTab = 4;
            }
            else if (this.props.location.query.tab === "FaxStatus")
            {
                activeTab = 5;
            }
            else if (this.props.location.query.tab === "ScanRoster")
            {
                activeTab = 6;
            }
        }

        return activeTab;
    }

    handleSelect = (eventKey) => {
        this.getPractices();
        if (this.state.loadedTabs.includes(eventKey)) {
            this.setState({
                activeTab: eventKey
            });
        }
        else {
            this.setState({
                activeTab: eventKey,
                loadedTabs: [...this.state.loadedTabs, eventKey]
            });
        }
    }

    render() {
        return (
            <div style={{marginTop: '2rem'}}>
                <Loader loaded={this.state.loaded}>
                    <Tabs id="pecTabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                        <Tab eventKey={1} title="CDI Alerts">
                        {
                            this.state.loadedTabs.includes(1) &&
                            <CDIAlertWorkflows outboundTypes={this.state.outboundTypes} practices={this.state.practices} providerOrgs={this.state.providerOrgs} toast={this.state.toast}
                            assignedPECs={this.state.assignedPECs} />
                        }
                        </Tab>
                        <Tab eventKey={2} title="Packages">
                        {
                            this.state.loadedTabs.includes(2) &&
                            <Packages router={this.props.router} providers={this.state.providerOrgs} practices={this.state.practices} toast={this.state.toast} />
                        }
                        </Tab>
                        {
                            this.state.showPracticeInfoTab ?
                            <Tab eventKey={3} title="Practice Info" onEnter={this.showFailedFaxExceptions}>
                            {
                                this.state.loadedTabs.includes(3) &&
                                <PracticeInfo router={this.props.router} providers={this.state.allProviderOrgs} practices={this.state.allPractices} outboundTypes={this.state.outboundTypes}
                                    validationStatuses={this.state.validationStatuses} users={this.state.users} toast={this.state.toast} />
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.RemotePecEngagementAdminTab) ?
                            <Tab eventKey={4} title="Practice Type">
                            {
                                this.state.loadedTabs.includes(4) &&
                                <PracticeType router={this.props.router} providers={this.state.allProviderOrgs} practices={this.state.allPractices} outboundTypes={this.state.outboundTypes}
                                    validationStatuses={this.state.validationStatuses} users={this.state.users} refreshPractices={this.getPractices} toast={this.state.toast}
                                    updateAssignedPECs={this.updateAssignedPECs} />
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.FaxStatusTab) ?
                            <Tab eventKey={5} title="Fax Status">
                            {
                                this.state.loadedTabs.includes(5) &&
                                <FaxStatus router={this.props.router} toast={this.state.toast} providers={this.state.allProviderOrgs}
                                    practices={this.state.allPractices} refreshPractices={this.getPractices} users={this.state.users} />
                            }
                            </Tab> : null
                        }
                        {
                            this.state.landingSecurables && this.state.landingSecurables.find(x => x.securableID === Securable.ScanRosterTab) ?
                            <Tab eventKey={6} title="Scan Roster">
                            {
                                this.state.loadedTabs.includes(6) &&
                                <ScanRosterContainer router={this.props.router} toast={this.state.toast} providers={this.state.allProviderOrgs}
                                    practices={this.state.allPractices} />
                            }
                            </Tab> : null
                        }
                    </Tabs>
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}
export default PECTabs;
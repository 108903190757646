import React from 'react';
import axios from 'axios';
import { Grid } from 'react-bootstrap';
import AlertToastr from '../../Shared/AlertToastr';
import EnrolleeRADV from './EnrolleeRADV';
import UserConfirmedRanking from './UserConfirmedRanking';

class RankingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radvEnrollee: null,
            toast: null
        }
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        this.getRADVEnrollee();
    }

    getRADVEnrollee = () => {
        if (this.props.location.query.radvAuditID && this.props.location.query.enrolleeID) {
            axios.get(`/RADVAudits/${this.props.location.query.radvAuditID}/RADVAuditEnrollees/${this.props.location.query.enrolleeID}`)
            .then((response) => {
                this.setState({ radvEnrollee: response.data })
            })
            .catch((error) => {
                console.log(error);
            });
       }
    }

    render() {
        return (
            <Grid fluid>
                <EnrolleeRADV radvEnrollee={this.state.radvEnrollee} />
                <UserConfirmedRanking router={this.props.router} enrolleeID={this.props.location.query.enrolleeID}
                    radvEnrollee={this.state.radvEnrollee} toast={this.state.toast} />
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        );
    }
}

export default RankingPage;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import Select from 'react-select';
import MemberSearchModal from '../Shared/MemberSearchModal';
import AlertDialog from '../Shared/AlertDialog';
import AlertToastr from '../Shared/AlertToastr';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import ProviderTypeaheadSearch from '../Shared/ProviderTypeaheadSearch';
import '../../styles/Report.css';
import { ClaimMatchType } from '../../enums/ClaimMatchType';
import ClaimsRecyclingModal from './ClaimsRecyclingModal';

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

class ClaimsLinkingReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toast: null,
            projectList: [],
            claimMatchTypeList: [],
            showMemberSearchModal: false,
            showProviderSearchModal: false,
            showFinalizeConfirmModal: false,
            showApproveConfirmModal: false,
            showClaimRecyclingModal: false,
            selectedProvider: null,
            reportInfo: [],
            totalItems: 0,
            searchFilter: {
                projectID: '',
                memberID: '',
                providerNPI: '',
                claimMatchType: '',
                dosStart: '',
                dosEnd: '',
                pageNum: 1,
                pageSize: 10,
                orderByColumnName: 'memberID',
                orderByDescending: false
            },
            loaded: true
        }
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        Promise.all([
            this.getProjectsForDropdown(),
            this.getClaimMatchTypesForDropdown()
        ])
            .catch((error) => {
                this.state.toast('error', 'Could not load project options.', 'Error');
            });
    }

    getProjectsForDropdown = () => {
        return axios.get('/projects')
            .then((response) => {
                this.setState({
                    projectList: response.data.map((proj) => {
                        return {
                            label: proj.projectName,
                            value: proj.projectID
                        };
                    })
                });
            });
    }

    getClaimMatchTypesForDropdown = () => {
        return axios.get('/Reports/ClaimsLinkingReport/claimMatchTypes')
            .then((response) => {
                this.setState({
                    claimMatchTypeList: response.data.map((matchType) => {
                        return {
                            label: matchType.claimMatchTypeDescription,
                            value: matchType.claimMatchTypeID
                        };
                    })
                });
            });
    }

    toggleFinalizeConfirmModal = () => {
        this.setState({ showFinalizeConfirmModal: !this.state.showFinalizeConfirmModal });
    }

    finalizeClaimsLinking = () => {
        this.toggleFinalizeConfirmModal();
        axios.post('/medicalrecordreview/finalizeclaimslinking')
        .then(response => {
            this.state.toast('success',
                `${response.data.UpdatedReviewCount} reviews moved to "Evaluation for Submission" status.`,
                'Success');
        })
        .catch(error => {
            this.state.toast('error', 'Could not finalize claims linking.', 'Error');
        });
    }

    toggleApproveConfirmModal = () => {
        this.setState({ showApproveConfirmModal: !this.state.showApproveConfirmModal });
    }

    approveSearchResults = () => {
        this.toggleApproveConfirmModal();
        axios.put('/Reports/ClaimsLinkingReport/bulkapproval', {
                projectID: this.state.searchFilter.projectID,
                memberID: this.state.searchFilter.memberID ? this.state.searchFilter.memberID : null,
                providerNPI: this.state.searchFilter.providerNPI ? this.state.searchFilter.providerNPI : null,
                claimMatchType: this.state.searchFilter.claimMatchType,
                dosStart: this.state.searchFilter.dosStart,
                dosEnd: this.state.searchFilter.dosEnd,
                isInexactClaimMatchApproved: true
            })
            .then(response => {
                this.state.toast('success', response.data, 'Success');
            })
            .catch(error => {
                if (error.response && error.response.data.message) {
                    this.state.toast('error', error.response.data.message, 'Error');
                }
                else {
                    this.state.toast('error', 'Could not approve claims.', 'Error');
                }
            });
    }

    handleSearchResult = () => {
        this.setState({ loaded: false });
        return axios.get('/Reports/ClaimsLinkingReport/search', { params: this.state.searchFilter })
            .then((response) => {
                this.setState({
                    reportInfo: response.data.items ? response.data.items : [],
                    totalItems: response.data.totalItems ? response.data.totalItems : 0,
                    loaded: true
                });
            })
            .catch((error) => {
                this.setState({ loaded: true });
                this.state.toast('error', 'Could not search claims linking data', 'Error');
            });
    }

    //todo: gratuitous JSON.stringify use below might not be needed
    generateCSV = () => {
        return axios.post("/ApplicationReports/DownloadRequests", {
            applicationReportID: "15",
            reportParameters: {
                ProjectID: this.state.searchFilter.projectID ? JSON.stringify(this.state.searchFilter.projectID) : null,
                MemberID: this.state.searchFilter.memberID ? JSON.stringify(this.state.searchFilter.memberID) : null,
                ProviderNPI: this.state.searchFilter.providerNPI ? JSON.stringify(this.state.searchFilter.providerNPI) : null,
                ClaimMatchType: this.state.searchFilter.claimMatchType ? JSON.stringify(this.state.searchFilter.claimMatchType) : null,
                DOSStart: this.state.searchFilter.dosStart ? new Date(this.state.searchFilter.dosStart) : null,
                DOSEnd: this.state.searchFilter.dosEnd ? new Date(this.state.searchFilter.dosEnd) : null
            }
        })
            .catch((error) => {
                this.state.toast('error', 'Could not generate download.', 'Error');
            });
    }

    handleProviderTypeaheadChange = (provider) => {
        this.setState({
            selectedProvider: provider,
            searchFilter: {
                ...this.state.searchFilter,
                providerNPI: provider ? provider.providerNPI : null
            }
        });
    }

    handleMemberIDChange = (member) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                memberID: member.target.value
            }
        });
    }

    handleMemberSelect = (memberId) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                memberID: memberId
            }
        });
    }

    handleProjectChange = (project) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                projectID: project ? project.value : null,
            },
        });
    }

    handleClaimMatchTypeChange = (matchType) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                claimMatchType: matchType ? matchType.value : null,
            },
        });
    }

    handleStartDateChange = (newStart) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                dosStart: newStart,
            },
        });
    }

    handleEndDateChange = (newEnd) => {
        this.setState({
            searchFilter: {
                ...this.state.searchFilter,
                dosEnd: newEnd,
            },
        });
    }

    formatApprovalButtonAction = (cell, row) => {
        return (
            row.claimMatchType !== ClaimMatchType['Rank10'] ?  // Disable Approval Button when ClaimMatchType=Rank10 (NoMatch)
                <Button disabled={row.isInexactClaimMatchApproved === true} onClick={() => this.approveDiag(row.medicalRecordDiagID)}>
                    {row.isInexactClaimMatchApproved ? 'Approved' : 'Approve'}
                </Button> : 'N/A'
        )
    }

    approveDiag = (DiagID) => {
        axios.put(`/medicalrecorddiag/${DiagID}/diagclaimlink?isInexactClaimMatchApproved=true`, {
        }).then((response) => {
            this.handleSearchResult()
        })
    }

    formatDOSDateOnly = (date) => {
        return date ? date.toString().split('T')[0] : '';
    }

    toggleMemberSearchModal = () => {
        this.setState({ showMemberSearchModal: !this.state.showMemberSearchModal });
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderSearchModal: !this.state.showProviderSearchModal });
    }

    handleClaimsRecyclingClick = () => {
        this.setState({ showClaimRecyclingModal: true });
    }

    render() {
        const options = {
            noDataText: this.state.loaded ? '' : <Loader loaded={false} />,
            page: this.state.searchFilter.pageNum,
            sizePerPage: this.state.searchFilter.pageSize,
            sizePerPageList: [10, 25, 50, 100, 250],
            onPageChange: (page, sizePerPage) => {
                let newSearchFilter = { ...this.state.searchFilter };
                newSearchFilter.pageNum = page;
                newSearchFilter.pageSize = sizePerPage;
                this.setState({
                    searchFilter: newSearchFilter
                }, () => this.handleSearchResult());
            },
            onSortChange: (sortName, sortOrder) => {
                let newSearchFilter = { ...this.state.searchFilter };
                newSearchFilter.orderByColumnName = sortName;
                newSearchFilter.orderByDescending = sortOrder === "desc";
                newSearchFilter.pageNum = 1;
                this.setState({
                    searchFilter: newSearchFilter
                }, () => this.handleSearchResult());
            }
        }

        return (
            <div className="reportContainer">
                <div className="innerReportContainer" style={{ minHeight: '250px', marginLeft: '3%', marginRight: '3%' }}>
                    <br />
                    <Grid fluid style={{ maxWidth: window.innerWidth }}>
                        <Row style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            <Col>
                                <h3>Claims Linking Report</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <ControlLabel>Project</ControlLabel>
                                <Select options={this.state.projectList} placeholder='All Projects' value={this.state.searchFilter.projectID}
                                    onChange={this.handleProjectChange} />
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>Member ID</ControlLabel>
                                <Row style={{ margin: 0 }}>
                                    <Col xs={10} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                                        <FormControl type="text" value={this.state.searchFilter.memberID} onChange={this.handleMemberIDChange}
                                            style={{ display: 'inline-block' }} />
                                    </Col>
                                    <Col xs={2} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                                        <Button onClick={this.toggleMemberSearchModal} bsSize="small" style={{ height: '3.4rem', minWidth: '4rem', width: '100%' }}>
                                            <Glyphicon glyph="search" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3}>
                                <ProviderTypeaheadSearch value={this.state.selectedProvider} handleProviderChange={this.handleProviderTypeaheadChange}
                                    showProviderModal={this.state.showProviderSearchModal} toggleProviderSearchModal={this.toggleProviderSearchModal}
                                    toast={this.state.toast} />
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>Claim Match Type</ControlLabel>
                                <Select options={this.state.claimMatchTypeList} placeholder='Claim Match Type' value={this.state.searchFilter.claimMatchType}
                                    onChange={this.handleClaimMatchTypeChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <ControlLabel>DOS Start</ControlLabel>
                                <div style={{ width: '60%' }}>
                                    <DatePicker value={this.state.searchFilter.dosStart} onChange={this.handleStartDateChange} style={{ zIndex: 'auto' }} />
                                </div>
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>DOS End</ControlLabel>
                                <div style={{ width: '60%' }}>
                                    <DatePicker value={this.state.searchFilter.dosEnd} onChange={this.handleEndDateChange} style={{ zIndex: 'auto' }} />
                                </div>
                            </Col>
                            <Col xs={3}>
                            </Col>
                            <Col xs={3}>
                                <ControlLabel>&nbsp;</ControlLabel>
                                <div>
                                    <Button onClick={this.handleSearchResult}>
                                        <Glyphicon glyph="search" style={{ marginRight: '0.2rem' }} /> Search
                                    </Button>
                                    <Button bsStyle="primary" onClick={this.toggleApproveConfirmModal}
                                        disabled={!this.state.searchFilter.claimMatchType}
                                        style={{ marginLeft: '1rem' }}> Approve All Search Results
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                            <BootstrapTable data={this.state.reportInfo} options={options}
                                pagination remote fetchInfo={{ dataTotalSize: this.state.totalItems }} maxHeight="420px">
                                <TableHeaderColumn dataField="isInexactClaimMatchApproved" width='8%' dataFormat={this.formatApprovalButtonAction} dataAlign='center' >Manually Approve for Submission</TableHeaderColumn>
                                <TableHeaderColumn dataField="claimMatchType" isKey dataSort>Claim Link Type</TableHeaderColumn>
                                <TableHeaderColumn dataField="memberFirstName" dataSort>Member First Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="memberLastName" dataSort>Member Last Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="primaryProviderNPI" dataSort>Primary Provider NPI</TableHeaderColumn>
                                <TableHeaderColumn dataField="primaryProviderLastName" dataSort>Provider Last Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="secondaryProviderNPI" dataSort>Secondary Provider NPI</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagDOSFrom" dataSort dataFormat={this.formatDOSDateOnly}>Diag DOS From</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagDOSThru" dataSort dataFormat={this.formatDOSDateOnly}>Diag DOS Thru</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisCode" dataSort>Diag Code</TableHeaderColumn>
                                <TableHeaderColumn dataField="hcc">HCC</TableHeaderColumn>
                                <TableHeaderColumn dataField="claimNumber" dataSort>Claim Number</TableHeaderColumn>
                                <TableHeaderColumn dataField="claimDOSFrom" dataSort dataFormat={this.formatDOSDateOnly}>Claim DOS From</TableHeaderColumn>
                                <TableHeaderColumn dataField="claimDOSThru" dataSort dataFormat={this.formatDOSDateOnly}>Claim DOS Thru</TableHeaderColumn>
                                <TableHeaderColumn dataField="claimProviderNPI" dataSort>Claim Provider NPI</TableHeaderColumn>
                                <TableHeaderColumn dataField="claimProviderLastName" dataSort>Claim Provider Last Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisSubmissionRequestType" dataSort>Add / Delete</TableHeaderColumn>
                            </BootstrapTable>
                            <div style={{ display: 'inline-block', float: 'right' }}>
                                <h5>Total results: {this.state.totalItems}</h5>
                            </div>
                        </Row>
                        <br></br>
                        <Row style={{ marginBottom: '1.4rem' }}>
                            <Col xs={4}>
                                <Button bsStyle="primary" onClick={this.toggleFinalizeConfirmModal}>Finalize Claims Linking</Button>
                                {
                                    this.props.currentUser &&
                                    this.props.currentUser.authorizedTabs.allowClaimsRecyclingButton &&
                                    <Button bsStyle="primary" onClick={this.handleClaimsRecyclingClick}
                                        style={{ marginLeft: '2rem' }}>
                                        Start Claims Linking
                                    </Button>
                                }
                            </Col>
                            <Col xs={2} xsOffset={6}>
                                <LongRunningDownloadAnchor linkText="Download .CSV" glyph="file"
                                    postRequest={this.generateCSV} displayCSVDownloader containerStyle={{ float: 'right' }}
                                    buttonStyle={{ float: 'right' }} />
                            </Col>
                        </Row>
                        <MemberSearchModal visible={this.state.showMemberSearchModal} handleModalToggle={this.toggleMemberSearchModal}
                            handleSelectSearchResult={this.handleMemberSelect} toast={this.state.toast} />
                    </Grid>
                </div>
                <AlertDialog visible={this.state.showFinalizeConfirmModal} handleModalToggle={this.toggleFinalizeConfirmModal}
                    title={'Finalize all claims linking on hold reviews?'}
                    handleConfirm={this.finalizeClaimsLinking} confirmLabel={"Yes"} cancelLabel={"No"}
                    confirmStyle={"success"} glyphicon={"ok"} renderBody={false}
                    dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <AlertDialog visible={this.state.showApproveConfirmModal} handleModalToggle={this.toggleApproveConfirmModal}
                    title={'Approve all claim links matching the search criteria?'}
                    handleConfirm={this.approveSearchResults} confirmLabel={"Yes"} cancelLabel={"No"}
                    confirmStyle={"success"} glyphicon={"ok"} renderBody={false}
                    dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <AlertToastr setAddToast={this.setAddToast} />
                <ClaimsRecyclingModal
                    visible={this.state.showClaimRecyclingModal}
                    onClose={() => this.setState({ showClaimRecyclingModal: false })}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(ClaimsLinkingReport);

import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class SocialHistory extends React.Component {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            ['SOCIAL FACTOR'],
            ['ANSWER'],
            ['FREQUENCY'],
            ['FACTOR', 'CODING'],
            ['ONSET DATE', 'OFFSET DATE'],
            ['RECORDED'],
            ['STATUS']
        ]
    }

    formatMultidataCell = (value) => {
        if (!value || value.length === 0) {
            return '-';
        } else {
            return value;
        }
    }

    render() {
        return (
            <Panel id={'socialhistory'}>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Social History
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                        <Table>
                        <thead>
                            <tr>
                                {
                                    this.columnHeaders.map((x, index) => {
                                        return (x.length > 1 ?
                                            <th className='rawTableColumnHeader' key={index}>
                                                <div>{x[0]}</div>
                                                <div>{x[1]}</div>
                                            </th>
                                            : <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data.map((x, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width={'10%'}>{x.social_factor_name}</td>
                                            <td width={'20%'}>{x.description}</td>
                                            <td width={'15%'}>{x.frequency}{x.frequency_units}</td>
                                            <td width={'20%'}>
                                                <div>{this.formatMultidataCell(x.social_factor_code)}</div>
                                                <div>{this.formatMultidataCell(x.social_factor_coding_system)}</div>
                                            </td>
                                            <td width={'15%'}>
                                                <div>{this.formatMultidataCell(formatDatetimeStringWithPrefixZero(x.onset_date))}</div>
                                                <div>{this.formatMultidataCell(formatDatetimeStringWithPrefixZero(x.offset_date))}</div>
                                            </td>
                                            <td width={'10%'}>{formatDatetimeStringWithPrefixZero(x.record_date)}</td>
                                            <td width={'10%'}>{x.status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table> : <NoData />
                    }                       
                </Panel.Body>
        </Panel>
        )
    }
}

export default SocialHistory;
import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import { unionBy } from 'lodash';
import AlertDialog from '../../Shared/AlertDialog';
import ProviderSearchModal from '../../Shared/ProviderSearchModal';
import { AppointmentType } from '../../../enums/AppointmentType';

class MemberAppointment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            selectedMembers: [],
            appointmentTypes: [],
            providerOptions: [],
            targetMemberID: null,
            targetMemberName: "",
            showConfirmRemoveModal: false,
            showRemoveAllModal: false,
            showProviderModal: false,
            loaded: false,
            memberAppointmentSortColumn: [],
            memberAppointmentSortDirection: [],
            memberAppointmentSort: [],
            formattedSortDisplay: ""
        };
        this.loadingProviders = false;
        this.today = new Date();
        this.todayISODate = this.today.toISOString().substring(0, this.today.toISOString().indexOf('T')); //date portion of the ISO representation -- used to compare with date <input>
        this.maxAppointmentDate = new Date(new Date().setDate(this.today.getDate() + 30));
        this.maxAppointmentISODate = this.maxAppointmentDate.toISOString().substring(0, this.maxAppointmentDate.toISOString().indexOf('T'));
        this.deleteTooltip = (
            <Tooltip id="remove"><strong>Delete</strong></Tooltip>
        );
    }

    componentWillMount() {
        if (this.props.practice.groupingID) {
            this.loadTable(this.props.practice.groupingID);
            this.props.setRefreshMemberAppointment(this.loadTable);
        }
    }

    componentWillReceiveProps(nextProps) {
        //when the route is accessed directly, the workflow component hasn't been mounted, so we must wait for it to mount before groupingID is available
        if(!this.loadingProviders && nextProps.practice.groupingID && nextProps.practice.groupingID !== this.props.practice.groupingID) {
            this.loadTable(nextProps.practice.groupingID);
            this.props.setRefreshMemberAppointment(this.loadTable);
        }
    }

    //todo: refactor
    onSortChange = (sortName, sortDirection) => {
        const currentMemberAppointmentSortDirection = [...this.state.memberAppointmentSortDirection];
        const currentMemberAppointmentSortColumn = [...this.state.memberAppointmentSortColumn];
        const formattedFields = this.getFormattedSortFields(sortName, sortDirection);

        const index = currentMemberAppointmentSortColumn.map(function(item) { return item.name; }).indexOf(formattedFields[0]);
        if (index > -1) {
            currentMemberAppointmentSortDirection.splice(index, 1);
            currentMemberAppointmentSortColumn.splice(index, 1);
        }

        if (formattedFields[0] && formattedFields[1]) {
            currentMemberAppointmentSortDirection.unshift({ sortColumn: sortName, sortDirection: sortDirection });
            currentMemberAppointmentSortColumn.unshift({ name: formattedFields[0], sortDirection: formattedFields[1] });
        }

        if (currentMemberAppointmentSortDirection.length > 3) {
            currentMemberAppointmentSortDirection.splice(-1, 1);
            currentMemberAppointmentSortColumn.splice(-1, 1);
        }

        this.setState({ memberAppointmentSortDirection: currentMemberAppointmentSortDirection, memberAppointmentSortColumn: currentMemberAppointmentSortColumn });
        this.props.setMemberAppointmentSort(currentMemberAppointmentSortDirection);
    }

    getFormattedSortFields = (sortName, sortDirection) => {
        let formattedSortName = null;
        switch (sortName) {
            case 'memberName':
                formattedSortName = "Member Name";
                break;
            case 'memberID':
                formattedSortName = "Member ID";
                break;
            case 'memberDOB':
                formattedSortName = "Member DoB";
                break;
            case 'assignedProvider':
                formattedSortName = "Assigned Provider";
                break;
            case 'activeAlerts':
                formattedSortName = "Active Alerts";
                break;
            case 'appointmentType':
                formattedSortName = "Appt Type";
                break;
            case 'appointmentDatetime':
                formattedSortName = "Appt Date";
                break;
            case 'appointmentPractice':
                formattedSortName = "Appt Practice";
                break;
            case 'appointmentProviderMasterID':
                formattedSortName = "Appt Provider";
                break;
            case 'alternateProviderLastName':
                formattedSortName = "Alt Provider Last";
                break;
            case 'alternateProviderFirstName':
                formattedSortName = "Alt Provider First";
                break;
            case 'medicalRecordNumber':
                formattedSortName = "MRN";
                break;
            case 'alertLineCount':
                formattedSortName = "Line Count";
                break;
            default:
                break;
        }

        let formattedSortDirection = null;
        switch (sortDirection) {
            case 'asc':
                formattedSortDirection = 'Ascending';
                break;
            case 'desc':
                formattedSortDirection = 'Descending';
                break;
            default:
                break;
        }

        return [formattedSortName, formattedSortDirection];
    }

    loadTable = (groupingID) => {
        Promise.all([this.getSelectedMembers(), this.getAppointmentTypes(), this.getProvidersByPractice(groupingID)])
        .then(() => {
            this.setSelectedMemberDefaults();
        })
        .catch(() => {
            this.setState({ loaded: true });
        });
    }

    getSelectedMembers = () => {
        return axios.get('/packages/' + this.props.packageID + '/Members/', {
            packageId: this.props.packageID
        })
        .then((response) => {
            this.setState({ selectedMembers: response.data.items });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    //populate appointment provider dropdown
    getAppointmentTypes = () => {
        return axios.get('/cdialerts/appointmenttypes')
        .then((response) => {
            const types = [{ value: 0, text: 'Select' }, ...response.data.map((item) => {
                return { value: item.appointmentTypeID, text: item.appointmentTypeDesc };
            })];
            this.setState({ appointmentTypes: types });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load appointment type data.', 'Error');
        });
    }

    mapProviderToProviderSelectOption = (provider) => {
        return { value: provider.internalKey, text: `${provider.lastName}, ${provider.firstName}` };
    }

    //populate appointment provider dropdown with system- & user-entered providers for the assigned practice
    getProvidersByPractice = (groupingID) => {
        this.loadingProviders = true;
        return axios.get('/practice/' + groupingID + '/provider')
        .then((response) => {
            const systemEnteredProviderOptions = response.data.systemEntered.map(this.mapProviderToProviderSelectOption);
            const userEnteredProviderOptions = response.data.userEntered.map(this.mapProviderToProviderSelectOption);
            const providerOptions = [{ value: 0, text: 'Select' }, ...(unionBy(systemEnteredProviderOptions, userEnteredProviderOptions, 'value'))];
            this.setState({ providerOptions: providerOptions });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load provider data.', 'Error');
        });
    }

    setSelectedMemberDefaults = () => {
        const members = [...this.state.selectedMembers];
        members.forEach((item) => {
            //update initial model state (if untouched)
            if (!item.appointmentType && !item.appointmentDatetime && !item.appointmentProviderMasterID && !item.alternateProviderLastName && !item.alternateProviderFirstName) {
                item.appointmentType = (item.alertLineCount === 0) 
                    ? AppointmentType.Regular_BlankCDI 
                    : AppointmentType.Regular_CDIGeneratedPriorToAppointment;
                const targetOption = this.state.providerOptions.find(x => x.text === item.assignedProvider);
                if (targetOption) {
                    item.appointmentProviderMasterID = targetOption.value;
                }
                this.updateMember(item);
            }

            //set standard defaults (always applied)
            if (!item.appointmentDatetime) {
                item.appointmentDatetime = "";
            }
            else {
                if (item.appointmentDatetime.indexOf('T') > -1) {
                    item.appointmentDatetime = item.appointmentDatetime.substring(0, item.appointmentDatetime.indexOf('T'));
                }
            }
            item.appointmentPractice = this.props.practice.desc;
        });

        this.setState({ 
            members: [...members],
            selectedMembers: [...members], loaded: true 
        });
    }

    //update member with cell edits
    updateMember = (model) => {
        axios.put('/packages/' + this.props.packageID + '/Members/' + model.memberMasterID, {
             alternateProviderFirstName: model.alternateProviderFirstName,
             alternateProviderLastName: model.alternateProviderLastName,
             appointmentDatetime: model.appointmentDatetime,
             appointmentProviderMasterID: model.appointmentProviderMasterID !== "0" ? model.appointmentProviderMasterID : '',
             appointmentType: model.appointmentType !== "0" ? model.appointmentType : '',
             medicalRecordNumber: model.medicalRecordNumber !== "0" ? model.medicalRecordNumber : ''
        })
        .catch((error) => {
            this.props.toast('error', 'Could not update member.', 'Error');
        });
    }

    //remove member from package
    removeMember = () => {
        const memberID = this.state.targetMemberID;
        axios.delete('/packages/' + this.props.packageID + '/Members/' + memberID, {
            packageId: this.props.packageID,
            memberId: memberID
        })
        .then((response) => {
            this.setState({
                members: this.state.members.filter(member => member.memberMasterID !== memberID),
                selectedMembers: [...this.state.selectedMembers].filter(member => member.memberMasterID !== memberID)
            })
            this.handleConfirmRemove();
            this.props.memberRemovedEvent(memberID);
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    //remove all members from package
    removeAllMembers = () => {
        axios.delete('/packages/' + this.props.packageID + '/Members/', {
            packageId: this.props.packageID
        })
        .then((response) => {
            this.setState({
                members: [],
                selectedMembers: []
            });

            this.handleConfirmRemoveAll();
            this.props.memberRemovedEvent();
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove members.', 'Error');
        });
    }

    handleConfirmRemove = () => {
        this.setState({ showConfirmRemoveModal: !this.state.showConfirmRemoveModal });
    }

    handleConfirmRemoveAll = () => {
        this.setState({ showRemoveAllModal: !this.state.showRemoveAllModal });
    }

    handleRemoveClick = (memberID) => {
        this.setState({
            targetMemberID: this.state.selectedMembers.find(x => x.memberID === memberID).memberMasterID,
            targetMemberName: this.state.selectedMembers.find(x => x.memberID === memberID).memberName
         });
        this.handleConfirmRemove();
    }

    handleFindProviderClick = (memberID) => {
        this.setState({ targetMemberID: this.state.selectedMembers.find(x => x.memberID === memberID).memberMasterID })
        this.toggleProviderSearchModal();
    }

    //attach provider search modal result to appointment provider options
    handleProviderChange = (internalKey, providerID, providerNPI, lastName, firstName) => {
        this.setState({ loaded: false });
        axios.post('/practice/' + this.props.practice.groupingID + '/provider/' + internalKey)
        .then((response) => {
            this.getProvidersByPractice(this.props.practice.groupingID)
            .then((response) => {
                this.setSelectedMemberDefaults();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load provider data.', 'Error');
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not add provider.', 'Error');
        });
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderModal: !this.state.showProviderModal });
    }

    formatAlert = (cell, row) => {
        if (cell) {
            let link = `/cdi/${cell}/`;
            if (row.activeMemberWorkflowStatusID === 1) {
                link += "update";
            }
            else {
                link += "viewonly";
            }

            return <a target="_blank" rel="noopener noreferrer" href={link}>{cell}</a>;
        }
    }

    formatDateString = (cell) => {
        return cell ? new Date(cell).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    //validate min & max appointment date on entry
    dateValidator = (value, row) => {
        const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };
        if(value) {
            if (new Date(value) < new Date(this.todayISODate)) {
                response.isValid = false;
                response.notification.type = 'error';
                response.notification.msg = `Date must be on or after today's date (${this.today.toLocaleDateString('en-US')}).`;
                response.notification.title = 'Error - Min Date';
            }
            else if (new Date(value) > new Date(this.maxAppointmentISODate)) {
                response.isValid = false;
                response.notification.type = 'error';
                response.notification.msg = `Date cannot be more than 30 days from today (after ${this.maxAppointmentDate.toLocaleDateString('en-US')}).`;
                response.notification.title = 'Error - Max Date';
            }
        }

        return response;
    }

    //format dropdown cell selections (cell contents)
    formatSelect = (cell, options) => {
        if(cell && options) {
            if(cell === "0") {
                return '';
            }
            else {
                const targetOption = options.find(option => option.value === cell);
                return targetOption ? targetOption.text : '';
            }
        }
    }

    formatAppointmentType = (cell) => {
        return this.formatSelect(cell, this.state.appointmentTypes);
    }

    formatAppointmentProvider = (cell) => {
        return this.formatSelect(cell, this.state.providerOptions);
    }

    //validate dropdown cell selections
    // selectValidator = (value, row, errorMsg, errorTitle) => {
    //     const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };

    //     if (value === "0") {
    //         response.isValid = false;
    //         response.notification.type = 'error';
    //         response.notification.msg = errorMsg;
    //         response.notification.title = errorTitle;
    //     }
    //     return response;
    // }

    // appointmentTypeValidator = (value, row) => {
    //     return this.selectValidator(value, row, 'Appointment Type must be selected.', 'Error - Appointment Type');
    // }

    // appointmentProviderValidator = (value, row) => {
    //     return this.selectValidator(value, row, 'Appointment Provider must be selected.', 'Error - Appointment Provider');
    // }

    renderTable = () => {
        var sortOptions = {
            onSortChange: this.onSortChange,
            sortName: this.props.defaultMemberAppointmentSort.length > 0 ? this.props.defaultMemberAppointmentSort.map((item) => { return item.sortColumn; }) : '',
            sortOrder: this.props.defaultMemberAppointmentSort.length > 0 ? this.props.defaultMemberAppointmentSort.map((item) => { return item.sortDirection; }) : ''
        };

        const removeButton = (cell, row) => {
            return (
                <div>
                    <OverlayTrigger placement="top" overlay={this.deleteTooltip}>
                        <Button onClick={() => this.handleRemoveClick(row.memberID)}>
                            <Glyphicon glyph="trash" />
                        </Button>
                    </OverlayTrigger>
                </div>
            )
        };

        const onAfterSaveCell = (row, cellName, cellValue) => {
            if(row.appointmentType) {
                row.appointmentType = row.appointmentType !== "0" ? parseInt(row.appointmentType, 10) : null;
            }
            if(row.appointmentProviderMasterID) {
                row.appointmentProviderMasterID = row.appointmentProviderMasterID !== "0" ? parseInt(row.appointmentProviderMasterID, 10) : null;
            }

            //save cell edit
            this.updateMember(row);

            //this.members is used to avoid updating state directly --
            //keep this.state.selectedMembers in sync
            this.setState({ selectedMembers: [...this.state.members] });
        }
        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: onAfterSaveCell
        };
        return (
            <div>
                <Loader loaded={this.state.loaded}>
                    <Row>
                        <Col sm={12}>
                            <Button onClick={this.handleConfirmRemoveAll} style={{ float: 'right', marginBottom: '1.4rem' }}>
                                <Glyphicon glyph="trash" style={{ marginRight: '0.6rem' }} />Clear List
                            </Button>
                            <Button onClick={() => this.toggleProviderSearchModal()} style={{ float: 'right', marginRight: '1rem' }}>
                                <Glyphicon glyph="search" style={{ marginRight: '0.6rem' }} />Add Provider To Practice
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                        <BootstrapTable data={this.state.members} options={sortOptions} cellEdit={cellEditProp} maxHeight="320px" scroll-top="Top" hover multiColumnSort={3}>
                            <TableHeaderColumn dataField="removeButton"  editable={{ readOnly: true }} dataAlign="left" dataFormat={ removeButton } width="7rem"></TableHeaderColumn>
                            <TableHeaderColumn dataField="memberName"  editable={{ readOnly: true }} dataAlign="left" dataSort>Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberID"  editable={{ readOnly: true }} dataAlign="left" dataSort isKey width="6%">ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberDOB" editable={{ readOnly: true }} dataAlign="left" dataFormat={ this.formatDateString } dataSort width="5%">DoB</TableHeaderColumn>
                            <TableHeaderColumn dataField="assignedProvider" editable={{ readOnly: true }} dataAlign="left" dataSort width="10%">Assigned Provider</TableHeaderColumn>
                            <TableHeaderColumn dataField="activeAlerts" editable={{ readOnly: true }} dataAlign="left" dataFormat={this.formatAlert} dataSort>Active Alert</TableHeaderColumn>
                            <TableHeaderColumn dataField="appointmentType" editable={{ type: 'select', options: { values: this.state.appointmentTypes }, validator: this.appointmentTypeValidator }}
                                dataAlign="left" dataFormat={ this.formatAppointmentType } dataSort width="12%">Appt. Type<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/></TableHeaderColumn>
                            <TableHeaderColumn dataField="appointmentDatetime" editable={{ type: 'date', validator: this.dateValidator }} dataAlign="left" dataFormat={ this.formatDateString }
                                dataSort>Appt. Date<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/></TableHeaderColumn>
                            <TableHeaderColumn dataField="appointmentProviderMasterID" editable={{ type: 'select', options: { values: this.state.providerOptions }, validator: this.appointmentProviderValidator }}
                                dataAlign="left" dataFormat={ this.formatAppointmentProvider } dataSort>Appt. Pro.<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/></TableHeaderColumn>
                            <TableHeaderColumn dataField="alternateProviderLastName" dataAlign="left" dataSort width="9%">Alt Pro. Last<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/></TableHeaderColumn>
                            <TableHeaderColumn dataField="alternateProviderFirstName" dataAlign="left" dataSort width="9%">Alt Pro. First<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/></TableHeaderColumn>
                            <TableHeaderColumn dataField="medicalRecordNumber" dataAlign="left" dataSort>MRN<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/></TableHeaderColumn>
                            <TableHeaderColumn dataField="alertLineCount" editable={{ readOnly: true }} dataAlign="left" dataSort width="7%">Line Count</TableHeaderColumn>
                        </BootstrapTable>
                        </Col>
                    </Row>
                    <AlertDialog visible={this.state.showConfirmRemoveModal} handleModalToggle={this.handleConfirmRemove} title={"Remove Selected Member?"}
                        message = {`Are you sure you want to delete - ${this.state.targetMemberName}`} handleConfirm={this.removeMember} confirmLabel={"Remove"} cancelLabel={"Close"} confirmStyle={"success"} glyphicon={"ok"} />
                    <AlertDialog visible={this.state.showRemoveAllModal} handleModalToggle={this.handleConfirmRemoveAll} title={"Clear all list"}
                        message = {"Are you sure you want to clear the list?"} handleConfirm={this.removeAllMembers} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} />
                </Loader>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        {this.renderTable()}
                    </Col>
                </Row>
                <ProviderSearchModal visible={this.state.showProviderModal} handleModalToggle={this.toggleProviderSearchModal}
                    handleSelectSearchResult={this.handleProviderChange} toast={this.props.toast} />
            </div>
        );
    }
}

export default MemberAppointment;

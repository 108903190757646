import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import { Tabs, Tab, Grid } from 'react-bootstrap';
import OOAReportsDropdown from './OOAReportsDropdown';
import AlertToastr from '../Shared/AlertToastr';

const OOATabs = () => {
    const [reportsList, setReportsList] = useState({ reports: [], loaded: false })
    const [toast, setToast] = useState({ toastfn: null })

    const setAddToast = (addToast) => {
        setToast({ ...toast, toastfn: addToast })
    }

    useEffect(() => {
        const lineOfBusinessValue = localStorage.getItem('lineOfBusiness');
        if (lineOfBusinessValue === "Commercial") {
            window.location.href = '/MRR';
        }
        axios.get('/ApplicationReports')
            .then(response => {
                if (response.data && response.data.length) {
                    const ooaReportsList = response.data.filter(item => item.applicationReportGroupID === 4);
                    setReportsList({
                        ...reportsList,
                        reports: ooaReportsList,
                        loaded: true
                    })
                }
            })
            .catch(error => {
                setReportsList({
                    ...reportsList,
                    loaded: true
                })
                setToast({ ...toast, toastfn: ('error', 'Could not load OOA data.', 'Error') });
            })
    })

    return (
        <Grid fluid>
            <Loader loaded={reportsList.loaded}>
                <Tabs id="OOALandingTabs" defaultActiveKey={1} style={{ marginTop: '2rem' }}>
                    <Tab eventKey={1} title="Reports">
                        {
                            <OOAReportsDropdown OOAreports={reportsList} toast={toast} />
                        }
                    </Tab>
                </Tabs>
            </Loader>
            <AlertToastr setAddToast={setAddToast} />
        </Grid >
    )
}

export default OOATabs;
import React from 'react';
import axios from 'axios';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import AlertToastr from '../../../Shared/AlertToastr';
import AlertDialog from '../../../Shared/AlertDialog';
import AddReferenceItemModal from '../Access/AddReferenceItemModal';
import EMRHistory from './EMRHistory';
import PracticeSummaryFilters from '../PracticeSummary/PracticeSummaryFilters';
import UsersListModal from './UsersListModal';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';
import { getRedirect } from '../../../../services/ReviewProcessing';

export default class PracticeInformationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // practice obj (for practice/{practiceID})
            practice: null,

            // filter selections
            practiceCode: '',
            practiceName: '',
            practiceStatus: null,
            retrievalMethod: null,
            providerOrgCode: '',
            providerOrgName: '',
            hospitalMedicalCenter: '',
            practiceFullAddress: '',
            accessType: null,
            practiceGrouping: null,
            engaged: '',
            accountAdmin: null,
            services: null,

            // filter options
            practiceStatusOptions: [],
            retrievalMethodOptions: [],
            practiceGroupingOptions: [],
            accountAdminOptions: [],
            serviceOptions: [],

            // service modal
            showAddRefItemModal: false,
            showEMRUserAccessListModal: false,

            // misc.
            loaded: false,
            showEMRAccessModal: false,
            toast: null,
            emrAttribution: [],
            users: [],
            emrHistory:[]
        };

        this.engagedStatusOptions = [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ];
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentDidMount() {
        Promise.all([
            this.getEMRPractice(),
            this.getPracticeStatuses(),
            this.getRetrievalMethods(),
            this.getPracticeGroupings(),
            this.getUsers(),
            this.getEMRAccessTypes(),
            this.getCampaignServices(),
            this.getEMRAttribution()
        ])
        .then((responses) => {
            this.setState({
                practice: responses[0].data,
                practiceCode: responses[0].data.practiceCode,
                practiceName: responses[0].data.practiceName,
                practiceStatus: responses[0].data.practiceStatusID,
                retrievalMethod: responses[0].data.campaignRetrievalMethodID,
                providerOrgCode: responses[0].data.practiceOrganizationCode ?? '',
                providerOrgName: responses[0].data.practiceOrganizationName ?? '',
                hospitalMedicalCenter: responses[0].data.hospitalMedicalCenter ?? '',
                practiceFullAddress: responses[0].data ? this.formatAddress(responses[0].data) : '',
                accessType: responses[0].data.emrAccessType,
                practiceGrouping: responses[0].data.practiceGroupingID,
                engaged: responses[0].data.isEngaged,
                accountAdmin: responses[0].data.accountAdminUserID,
                services: responses[0].data.serviceIDs,
                emrAttribution: responses[7].data,
                practiceStatusOptions: this.getPracticeStatusDropdownOptions(responses[1].data),
                retrievalMethodOptions: this.getRetrievalMethodDropdownOptions(responses[2].data),
                practiceGroupingOptions: this.getPracticeGroupingDropdownOptions(responses[3].data),
                accountAdminOptions: this.getUserDropdownOptions(responses[4].data),
                emrAccessTypeOptions: responses[5].data[0].campaignEMRReferenceItems.map(x => {
                        return {
                            label: x.campaignEMRReferenceItemDescription,
                            value: x.campaignEMRReferenceItemID
                        }
                    }),
                serviceOptions: this.getCampaignServiceOptions(responses[6].data),
                loaded: true,
                users: responses[4].data,
                showEMRUserAccessListModal: false
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else {
                this.state.toast('error', 'Could not load EMR practice data.', 'Error');
            }
        });
    }

    updateRow = (row) => {
        //todo
        axios.put(`/campaignPracticeSummary/${this.props.router.params.practiceID}/emrHistory`, {
            campaignEMRAttributionID: row.campaignEMRAttributionID,
            startDate :row.startDate,
            endDate : row.endDate
        })
        .then(() => {
            this.handleReloadEMRHistory();
        })
        .then((response) => {
            this.state.toast('success', 'EMR History updated.', 'Success');
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update EMR History.', 'Error');
        });
    }

    // todo: consolidate with PracticeSummaryTable instance
    formatAddress = (row) => {
        const pieces = [row.practiceAddress1, row.practiceAddress2, row.practiceCity,
            row.practiceState, row.practiceZipCode];

        return pieces.reduce((acc, cur) => {
            if (!acc) {
                return cur;
            }

            return cur ? `${acc}, ${cur}` : acc;
        }, '');
    }

    getEMRPractice = () => {
        return axios.get(`/campaignPracticeSummary/${this.props.router.params.practiceID}`);
    }

    getPracticeStatuses = () => {
        return axios.get('/campaignPracticeSummary/campaignPracticeStatus');
    }

    getRetrievalMethods = () => {
        return axios.get('/campaignPracticeSummary/campaignRetrievalMethod');
    }

    getPracticeGroupings = () => {
        return axios.get('/campaignGroup');
    }

    // todo: add user class filter (e.g. 'campaign admin')?
    getUsers = () => {
        return axios.get('/merlinUser');
    }

    getEMRAccessTypes = () => {
        return axios.get(`/campaignEMRReferenceItem?typeID=${CampaignEMRReferenceItemType.AccessType}`);
    }

    getCampaignServices = () => {
        return axios.get(`/campaignService`);
    }

    getEMRAttribution = () => {
        return axios.get(`/campaignPracticeSummary/campaignEMRAttribution?campaignPracticeID=${this.props.router.params.practiceID}`);
    }

    getPracticeStatusDropdownOptions = (statuses) => {
        return statuses.map((x) => {
            return {
                label: x.campaignPracticeStatusDescription,
                value: x.campaignPracticeStatusID
            };
        });
    }

    getRetrievalMethodDropdownOptions = (retrievalMethods) => {
        return retrievalMethods.map((x) => {
            return {
                label: x.campaignRetrievalMethodDescription,
                value: x.campaignRetrievalMethodID
            };
        });
    }

    getPracticeGroupingDropdownOptions = (practiceGroupings) => {
        return practiceGroupings.map((x) => {
            return {
                label: `${x.groupCode} - ${x.groupName}`,
                value: x.campaignGroupID
            };
        });
    }

    getUserDropdownOptions = (users) => {
        return users.map((x) => {
            return {
                label: x.fullName,
                value: x.userID
            };
        });
    }

    getCampaignServiceOptions = (campaignServices) => {
        return campaignServices.map((x) => {
            return {
                label: x.campaignServiceDescription,
                value: x.campaignServiceID
            };
        });
    }

    handleSave = () => {
        axios.put(`/campaignPracticeSummary/${this.props.router.params.practiceID}`, {
            campaignPracticeID: this.props.router.params.practiceID,
            practiceStatusID: this.state.practiceStatus,
            campaignRetrievalMethodID: this.state.retrievalMethod,
            practiceGroupingID: this.state.practiceGrouping,
            accountAdminUserID: this.state.accountAdmin,
            serviceIDs: this.state.services.length > 0 ? this.state.services.map(x => x.value ? x.value : x) : [],
        })
        .then((response) => {
            this.handleReloadEMRHistory();    
            this.setState({ practice: response.data });
            this.setState({accessType: response.data.emrAccessType});
            this.setState({practiceStatus: response.data.practiceStatusID});
            this.setState({ retrievalMethod: response.data.campaignRetrievalMethodID});
            this.setState({accountAdmin: response.data.accountAdminUserID});
            this.setState({services: response.data.serviceIDs});
            this.state.toast('success', 'Practice updated.', 'Success');
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to save.', 'Error');
        });
        
    }

    handleReloadEMRHistory = () => {
        axios.get(`/campaignPracticeSummary/campaignEMRAttribution?campaignPracticeID=${this.props.router.params.practiceID}`)
        .then((response) => {
            this.setState({emrAttribution : response.data});
        });
    }

    toggleEMRAccessModal = () => {
        this.setState((prevState) => {
            return { showEMRAccessModal: !prevState.showEMRAccessModal }
        });
    }

    handleFilterFieldChange = (updateObj) => {
        const updateEntry = Object.entries(updateObj);
        this.setState({ [updateEntry[0][0]]: updateEntry[0][1] });
    }

    handleAddRefItemModalOpen = () => {
        this.setState({
            showAddRefItemModal: true,
            showEMRUserAccessListModal: false,
            currAddRefItemTypeID: 0,
            currAddRefItemTypeDesc: "Service",
            currAddRefItemOptions: this.state.serviceOptions,
        });
    }

    handleAddRefItemModalClose = () => {
        this.setState({ showAddRefItemModal: false });
    }

    refreshServiceItems = () => {
        this.getCampaignServices()
        .then((response) => {
            this.setState({ serviceOptions: this.getCampaignServiceOptions(response.data) });
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to refresh Service options.', 'Error');
        });
    }

    refreshEmrUserAccessList = () => {
        this.formatEMRUserAccess(this.state.practice, this.state.users);
    }

    handleEMRAccessClick = () => {
        if (!this.state.practice.campaignEMRInstanceID) {
            //show modal, if Group populated then create for Group, else Practice
            this.toggleEMRAccessModal();
        }
        else {
            this.props.router.push(`/EMR/Access/${this.state.practice.campaignEMRInstanceID}/practice/${this.state.practice.campaignPracticeID}`);
        }
    }

    handleEMRBackClick = () => {
        this.props.router.push(`/EMR/practices/`);
    }

    handleDocumentsClick = () => {
        this.props.router.push(`/EMR/practices/${this.props.router.params.practiceID}/documents`);
    }

    handleCreateEMRDetailsClick = () => {
        if (this.state.practice.practiceGroupingID) {
            this.props.router.push(`/EMR/Access/Wizard?campaignGroupID=${this.state.practice.practiceGroupingID}&campaignPracticeID=${this.state.practice.campaignPracticeID}`);
        }
        else {
            this.props.router.push(`/EMR/Access/Wizard?campaignPracticeID=${this.state.practice.campaignPracticeID}`);
        }
    }

    toggleEMRAccessModal = () => {
        this.setState({ showEMRAccessModal: !this.state.showEMRAccessModal });
    }    

    toggleEmrUserAccessListModal = () => {
        this.setState((prevState) => {
            return { showEMRUserAccessListModal: !prevState.showEMRUserAccessListModal }
        });
    }

    render() {
        const initialLoadComplete = this.state.practiceStatusOptions?.length > 0;

        let userButton;
        if(this.state.practice?.campaignEMRInstanceID){
            userButton = <Button onClick={this.toggleEmrUserAccessListModal} style={{ marginRight: '1rem' }}> Users </Button>;
        } else {
            userButton = null;
        }

        return (
            <Grid fluid style={{ marginBottom: '4rem' }}>
                <Row>
                    <Col xs={8} style={{ paddingLeft: '0rem' }}>
                        <h3 style={{ display: 'inline-block' }}>Practice Information</h3>
                    </Col>
                    <Col xs={4} style={{ paddingTop: '0.6rem', paddingRight: '0' }}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={this.handleEMRBackClick} style={{ marginRight: '1rem' }}>
                                EMR Summary
                            </Button>
                            <Button onClick={this.handleEMRAccessClick} style={{ marginRight: '1rem' }}>
                                EMR Access
                            </Button>
                            <Button onClick={this.handleDocumentsClick} style={{ marginRight: '1rem' }}>
                                Documents
                            </Button>
                            {userButton}
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '1rem' }}>
                    <hr style={{ marginTop: '0rem' }} />
                </Row>
                <Loader loaded={this.state.loaded}>
                    <Row style={{ marginBottom: '1rem' }}>
                    {
                        initialLoadComplete &&
                        <PracticeSummaryFilters practiceInformationMode={true} practiceCode={this.state.practiceCode} practiceName={this.state.practiceName}
                            practiceStatus={this.state.practiceStatus} retrievalMethod={this.state.retrievalMethod}
                            providerOrgCode={this.state.providerOrgCode} providerOrgName={this.state.providerOrgName}
                            hospitalMedicalCenter={this.state.hospitalMedicalCenter} practiceFullAddress={this.state.practiceFullAddress}
                            accessType={this.state.accessType} practiceGrouping={this.state.practiceGrouping} engaged={this.state.engaged}
                            accountAdmin={this.state.accountAdmin} services={this.state.services}
                            practiceStatusOptions={this.state.practiceStatusOptions} retrievalMethodOptions={this.state.retrievalMethodOptions}
                            accessTypeOptions={this.state.emrAccessTypeOptions} practiceGroupingOptions={this.state.practiceGroupingOptions}
                            engagedStatusOptions={this.engagedStatusOptions} accountAdminOptions={this.state.accountAdminOptions} serviceOptions={this.state.serviceOptions}
                            handleAddRefItemModalOpen={this.handleAddRefItemModalOpen} handleFilterFieldChange={this.handleFilterFieldChange}
                            handleSave={this.handleSave} />
                    }
                    </Row>
                    <EMRHistory data={this.state.emrAttribution} handleUpdateRow={this.updateRow} accessTypeOptions={this.state.emrAccessTypeOptions}
                            startDate={this.state.startDate}/>
                    <AlertToastr setAddToast={this.setAddToast} />
                    <UsersListModal showEMRUserAccessListModal={this.state.showEMRUserAccessListModal}
                        userList={this.state.accountAdminOptions}
                        practice = {this.state.practice}
                        toggleEmrUserAccessListModal={this.toggleEmrUserAccessListModal}
                        handleFilterFieldChange = {this.handleFilterFieldChange}
                        toast={this.state.toast}  />
                    <AddReferenceItemModal practiceInformationMode={true} visible={this.state.showAddRefItemModal} refTypeID={null} refTypeDesc="Service"
                        options={this.state.serviceOptions} handleModalClose={this.handleAddRefItemModalClose}
                        refreshServiceItems={this.refreshServiceItems} toast={this.state.toast} />
                    <AlertDialog visible={this.state.showEMRAccessModal} handleModalToggle={this.toggleEMRAccessModal}
                        title={`No EMR access found. Add EMR access details for this ${this.state.practiceGrouping ? 'practice grouping' : 'practice'}?`}
                        renderBody={false} handleConfirm={this.handleCreateEMRDetailsClick} confirmLabel={"Yes"}
                        cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                </Loader>
            </Grid>
        );
    }
}
import React from 'react';
import { Col, Glyphicon, Panel, Row } from 'react-bootstrap';
import MemberDemographicInfo from './MemberDemographicInfo';
import MemberDemographicChart from './MemberDemographicChart';

export default class MemberDemographic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded,
        };
    }

    expandPanel = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    openPopoutWindow = (evt) => {
        evt.stopPropagation();
        this.props.openNewWindow();
    }

    render() {
        const member = this.props.memberDemo ? this.props.memberDemo.member : null;
        return (
            <Panel expanded={this.state.expanded} onToggle={this.expandPanel}>
                <Panel.Heading>
                    <Panel.Title>
                        <div style={{ cursor: !this.props.isPopout ? 'pointer' : 'auto', minHeight: '22px' }} onClick={this.expandPanel}>
                            {
                                member &&
                                <span>
                                    {
                                        this.props.isPopout === false &&
                                        <span style={{ paddingRight: '10px' }}>
                                            <Glyphicon glyph="new-window" onClick={this.openPopoutWindow} />
                                        </span>
                                    }
                                    <span><b>{member.lastName}, {member.firstName}</b></span>
                                    <span className="demographicInfoItem"><b>DOB: </b>{member.dob}</span>
                                    <span className="demographicInfoItem"><b>Gender: </b>{member.gender}</span>
                                    <span className="demographicInfoItem"><b>Beneficiary ID: </b>{member.beneficiaryID}</span>
                                    <span className="demographicInfoItem"><b>Member ID: </b>{member.memberID}</span>
                                    {
                                        this.props.radvEnrolleeID &&
                                        <span className="demographicInfoItem"><b>External Reference ID: </b>{this.props.radvEnrolleeID}</span>
                                    }
                                </span>
                            }
                            {
                                this.props.isPopout === false &&
                                <span style={{ float: "right" }}>
                                    <Glyphicon glyph={this.state.expanded === true ? "menu-down" : "menu-left"} style={{ paddingRight: "0.4rem", color: "#6c716c" }} />
                                </span>
                            }
                        </div>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible={!this.props.isPopout} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                    <Row>
                        <Col xs={4}>
                            <MemberDemographicInfo memberDemo={this.props.memberDemo} />
                        </Col>
                        <Col xs={8}>
                            {
                                this.props.chart &&
                                <MemberDemographicChart chart={this.props.chart} />
                            }
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}
import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class HistoryOfIllness extends React.PureComponent {
    constructor(props) {
        super(props);
   }

    render() {

        const id = this.props.memberLoaded && !isEmpty(this.props.data) ? `History of Present Illness`: 'History of Present Illness';

        return (
            <Panel id={id}>
                <Panel.Body>
                    <div className='sectionHeader'>
                        History Of Present Illness
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                        <Table>
                        <thead>
                            <tr>
                                <th className='rawTableColumnHeader'>ASSESSMENT</th>
                                <th className='rawTableColumnHeader'>RESULT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data.map((x, index) => {
                                    if (!isEmpty(x.assessment_title) || !isEmpty(x.assessment_result )) {
                                    return (
                                        <tr key={index}>
                                            <td className='width30'>{x.assessment_title}</td>
                                            <td className='width70'>{x.assessment_result}</td>
                                        </tr>
                                    )
                                    }
                                })
                            }
                        </tbody>
                    </Table> : <NoData />
                    }                       
                </Panel.Body>
            </Panel>
        )
    }
}

export default HistoryOfIllness;
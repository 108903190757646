import React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';
import AccessFullView from './components/Campaign/EMR/Access/AccessFullView';
import AccessWizard from './components/Campaign/EMR/Access/AccessWizard';
import CredentialManagement from './components/Campaign/EMR/Credential/CredentialManagement';
import AdminContainer from './components/PEC/Admin/AdminContainer';
import AlertWorkflowContainer from './components/PEC/AlertWorkflow/AlertWorkflowContainer';
import ClaimsAuditReport from './components/Reports/ClaimsAuditReport';
import ClaimsLinkingReport from './components/Reports/ClaimsLinkingReport';
import ClaimsLinkingSummaryReport from './components/Reports/ClaimsLinkingSummaryReport/ClaimsLinkingSummaryReport';
import CoderPerformanceReport from './components/Reports/CoderPerformanceReport';
import CoderQAScoringReport from './components/Reports/CoderQAScoringReport';
import CodingContainer from './components/MRR/Coding/CodingContainer';
import CommentsReport from './components/Reports/CommentsReport';
import Container from './components/Container';
import DocumentContainer from './components/Campaign/EMR/PracticeInformation/DocumentContainer';
import Error from './components/Shared/Error';
import ExceptionComments from './components/MRR/Coding/ActionBar/ExceptionComments';
import ExtractReviewReport from './components/Reports/ExtractReviewReport';
import FinalMeasureReport from './components/Reports/FinalMeasureReport';
import HCCProfileNewWindow from './components/MRR/Coding/HCCProfile/HCCProfileNewWindow';
import ImageViewer from './components/MRR/Coding/ImageViewer/ImageViewer';
import Landing from './components/RADV/Landing/Landing';
import LandingTabs from './components/MRR/ReviewSearch/ReviewSearchContainer';
import Login from './components/Shared/Login';
import MemberDemographicNewWindow from './components/MRR/Coding/MemberDemographic/MemberDemographicNewWindow';
import NotFound from './components/Shared/NotFound';
import Packages from './components/PEC/AlertPackages/Packages';
import PackageWorkflow from './components/PEC/AlertPackages/PackageWorkflow';
import PracticeInformationContainer from './components/Campaign/EMR/PracticeInformation/PracticeInformationContainer.jsx';
import PracticeSummaryContainer from './components/Campaign/EMR/PracticeSummary/PracticeSummaryContainer.jsx';
import PECTabs from './components/PEC/PECTabs';
import PIRReport from './components/Reports/PIRReport';
import ProjectStatusReport from './components/Reports/ProjectStatusReport';
import RankingPage from './components/RADV/Ranking/RankingPage';
import RiskMitigationReport from './components/Reports/RiskMitigationReport';
import ScanRosterPrintable from './components/PEC/ScanRoster/ScanRosterPrintable';
import StarsProductivityReport from './components/Reports/StarsProductivityReport';
import SubmittedRankingResults from './components/RADV/Submitted/SubmittedRankingResults';
import Unauthorized from './components/Shared/Unauthorized';
import OOAAlertContianer from './components/PEC/OOAAlert/OOAAlertContainer';
import OOATabs from './components/OOA/OOATabs';
import RequestStatusByHostPlan from './components/Reports/RequestStatusByHostPlan';
import HostRequestStatusHomePlan from './components/Reports/HostRequestStatusByHomePlan';
import MembersStateReport from './components/Reports/MembersByStateReport';
import HostRequestStatusType from './components/Reports/HostRequestStatusByType';
import RequestStatusType from './components/Reports/RequestStatusByTypeReport';
import HostedMembersHomePlan from './components/Reports/HostedMembersByHomePlan';
import HostRequestClosureTimeframe from './components/Reports/HostRequestClosureTimeframe';
import MedicalRecordDisplayContainer from './components/EMRD/MedicalRecordDisplayContainer';
import CampaignManagementSummary from './components/Campaign/Management/CampaignManagementSummary';
import CampaignDetailContainer from './components/Campaign/Management/CampaignDetail/CampaignDetailContainer';
import ProjectDetailContainer from './components/Campaign/Management/ProjectDetail/ProjectDetailContainer';
import EscalationQueueContainer from './components/Campaign/EscalationQueue/EscalationQueueContainer';
import NLPCodingContainer from './components/MRR/Coding/NLP/NLPCodingContainer'; //This is for new NLP screen
import NLPTableImageNewWindowRedux from './components/MRR/Coding/NLP/NLPTableImageNewWindowRedux';
import NLPInsightHCCNewWindowRedux from './components/MRR/Coding/NLP/NLPInsightHCCNewWindowRedux';
import RiskMitigationAuditSummaryReport from './components/Reports/RiskMitigationAuditSummaryReport';
import RiskMitigationAuditDetailReport from './components/Reports/RiskMitigationAuditDetailReport';
import NLPImagePDFNewWindow from './components/MRR/Coding/NLP/NLPImagePDFNewWindow';

const Routes = (
    <div>
        <Route path="/Admin" component={Container} title="Admin">
            <IndexRoute component={AdminContainer} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/OOA" component={Container} title="OOA">
            <IndexRoute component={OOATabs} />
            <Route path="HostRequestStatusHomePlan" component={HostRequestStatusHomePlan}/>
            <Route path="RequestStatusHostPlan" component={RequestStatusByHostPlan}/>
            <Route path="MembersStateReport" component={MembersStateReport}/>
            <Route path="HostRequestStatusType" component={HostRequestStatusType}/>
            <Route path="RequestStatusType" component={RequestStatusType}/>
            <Route path="HostedMembersHomePlan" component={HostedMembersHomePlan}/>
            <Route path="HostClosureTimeframes" component={HostRequestClosureTimeframe}/>
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/AlertPackages" component={Container} title="Packages">
            <IndexRoute component={Packages} />
            <Route path="/AlertPackages/:packageID" component={PackageWorkflow} stage={"MemberSelection"} prev={"AlertPackages"} next={"PackageQuerySelection"} />
            <Route path="/AlertPackages/:packageID/PackageQuerySelection" component={PackageWorkflow} stage={"PackageQuerySelection"} prev={"MemberSelection"} next={"PackageReviewPrint"} />
            <Route path="/AlertPackages/:packageID/PackageReviewPrint" component={PackageWorkflow} stage={"PackageReviewPrint"} prev={"PackageQuerySelection"} next={"PackageReview"} />
            <Route path="/AlertPackages/:packageID/PackageReview" component={PackageWorkflow} stage={"PackageReview"} prev={"AlertPackages"} next={"AlertPackages"} />
            <Route path="/AlertPackages/:packageID/PackageReviewReprint" component={PackageWorkflow} stage={"PackageReviewReprint"} prev={"PackageReview"} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/cdi" component={Container} title="CDI Alert">
            {/* todo: find a better subroute scheme
            <Route path="/cdi/:alertWorkflowID" component={AlertWorkflowContainer} />*/}
            <Route path="/cdi/:alertWorkflowID/queryResponse" component={AlertWorkflowContainer} />
            <Route path="/cdi/:alertWorkflowID/update" component={AlertWorkflowContainer} />
            <Route path="/cdi/:alertWorkflowID/viewonly" component={AlertWorkflowContainer} />
            <Route path="/cdi/ooa/:ooaAlertID/update" component={OOAAlertContianer} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/CampaignManagement" component={Container} title="Campaign Management">
            <IndexRoute component={CampaignManagementSummary} />
            <Route path="campaign/:campaignID" component={CampaignDetailContainer} />
            <Route path="project/:projectID/campaign/:campaignID" component={ProjectDetailContainer} />
            <Route path="/EscalationQueue" component={EscalationQueueContainer} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/EMR" component={Container} title="EMR">
            <Route path="Credentials/:emrInstanceID" component={CredentialManagement} />
            <Route path="Credentials/:emrInstanceID/viewonly" component={CredentialManagement} />
            <Route path="Access/Wizard" component={AccessWizard} />
            <Route path="Access/:emrInstanceID" component={AccessFullView} />
            <Route path="Access/:emrInstanceID/practice/:practiceID" component={AccessFullView} />
            <Route path="Access/:emrInstanceID/practice/:practiceID/viewonly" component={AccessFullView} />
            <Route path="Access/:emrInstanceID/practice/:practiceID/emrview" component={AccessFullView} />
            <Route path="Access/:emrInstanceID/viewonly" component={AccessFullView} />
            <Route path="Practices" component={PracticeSummaryContainer} />
            <Route path="Practices/:practiceID" component={PracticeInformationContainer} />
            <Route path="Practices/:practiceID/documents" component={DocumentContainer} />
            <Route path="Practices/:practiceID/documents/viewonly" component={DocumentContainer} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/EMRD" component={Container} title="EMRD" >
            {/* TODO: remove this section after wiring up all done */}
            <IndexRoute component={MedicalRecordDisplayContainer} />
        </Route>
        <Route path="/Login" component={Container} title="Login">
            <IndexRoute component={Login} />
            <Route path="/Login/Login" component={Login} /> {/*this can be removed once the MrrUrl portal configs are updated*/}
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/MRR" component={Container} title="MRR">
            <IndexRoute component={LandingTabs} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/PEC" component={Container} title="PEC">
            <IndexRoute component={PECTabs} />
            <Route path="ExceptionComments/:mrrID" component={ExceptionComments} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/RADV" component={Container} title="RADV">
            <IndexRoute component={Landing} />
            <Route path="rankingpage" component={RankingPage} />
            <Route path="submittedpage" component={SubmittedRankingResults} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/Reports" component={Container} title="MRR Reports">
            <Route path="projectstatus" component={ProjectStatusReport} />
            <Route path="coderqascore" component={CoderQAScoringReport} />
            <Route path="riskmitigation" component={RiskMitigationReport} />
            <Route path="claims" component={ClaimsAuditReport} />
            <Route path="claimslinking" component={ClaimsLinkingReport} />
            <Route path="ClaimsLinkingSummary" component={ClaimsLinkingSummaryReport} />
            <Route path="finalmeasure" component={FinalMeasureReport} />
            <Route path="starsproductivity" component={StarsProductivityReport} />
            <Route path="extractreview" component={ExtractReviewReport} />
            <Route path="coderperformance" component={CoderPerformanceReport} />
            <Route path="comments" component={CommentsReport} />
            <Route path="pir" component={PIRReport} />
            <Route path="auditsummaryreport" component={RiskMitigationAuditSummaryReport} />
            <Route path="auditdetailreport" component={RiskMitigationAuditDetailReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Route path="/ReviewProcessing" component={Container} title="Review Processing">
        {/*<Route path="MemberDemographicNewWindow/:reviewType" component={MemberDemographicNewWindow} />*/}  {/* //This change is for new NLP screen  */}
            <Route path="HCCProfileNewWindow/:reviewType" component={HCCProfileNewWindow} />
            <Route path="InitialCoding" reviewType={"InitialCoding"} component={CodingContainer} />
            <Route path="CompletedCoding" reviewType={"CompletedCoding"} component={CodingContainer} />
            <Route path="QA" reviewType={"QA"} component={CodingContainer} />
            <Route path="MD" reviewType={"MD"} component={CodingContainer} />
            <Route path="CoderQA" reviewType={"CoderQA"} component={CodingContainer} />
            <Route path="Images" component={ImageViewer} />
            <Route path="EMRD" component={MedicalRecordDisplayContainer} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        {/* //Added this is for new NLP screen  */}
        <Route path="/NLP" component={Container} title="NLP">
            <Route path="HCCProfileNewWindow/:reviewType" component={HCCProfileNewWindow} />
            <Route path="NLPInsightsNewWindow/:reviewType" component={NLPTableImageNewWindowRedux} />
            <Route path="NLPInsightHccNewWindow/:reviewType" component={NLPInsightHCCNewWindowRedux} />
            <Route path="NLPImagePDFNewWindow" component={NLPImagePDFNewWindow} />
            <Route path="InitialCoding" reviewType={"InitialCoding"} component={NLPCodingContainer} />
            <Route path="CompletedCoding" reviewType={"CompletedCoding"} component={NLPCodingContainer} />
            <Route path="QA" reviewType={"QA"} component={NLPCodingContainer} />
            <Route path="MD" reviewType={"MD"} component={NLPCodingContainer} />
            <Route path="CoderQA" reviewType={"CoderQA"} component={CodingContainer} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>        
        <Route path="/MRRAutoQueue" component={Container} title="MRR Auto Queue">
            <IndexRoute component={(props) => <CodingContainer {...props}/>} reviewType={"AutoReview"}/>
            <Route path="InitialCoding/:medicalRecordReviewID/:isCDIQueue" reviewType={"AutoReview"} component={CodingContainer} />
            <Route path="CompletedCoding/:medicalRecordReviewID" reviewType={"AutoReview"} component={CodingContainer} />
        </Route>
        <Route path="/MemberDemographics" component={Container} title="Member Demographics">
            <Route path="NewWindow/:reviewType" component={MemberDemographicNewWindow} />
        </Route>
        <Route path="/ScanRosterPrintable" title="Scan Roster - Print">
            <IndexRoute component={ScanRosterPrintable} />
            <Route path="*" exact={true} component={NotFound} />
        </Route>
        <Redirect from="/" to="/MRR" />
        <Route path="/Error" exact={true} component={Error} />
        <Route path="/Unauthorized" exact={true} component={Unauthorized} />
        <Route path="*" exact={true} component={NotFound} />
    </div>
)

export default Routes;
import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class Medications extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    // TODO: these are all mockup data for now
    render() {
        return (
        <Panel id='medications'>
            <Panel.Body>
            <div className='sectionHeader'>
                Medications
            </div>           
                {
                    this.props.memberLoaded && !isEmpty(this.props.data) ?
                    this.props.data.map((x, index) => {
                        return (
                                <Row key={index}>
                                    <div className='threeSectionHeader'>
                                        <span>{x.medication_name}</span>
                                    </div>
                                    <Col className='threeSectionCol'>
                                        <div>
                                            <Row>
                                                <span>MED CODE: </span>
                                                <span className='threeSectionResults'>{x.medication_code}</span>
                                            </Row>
                                            <Row>
                                                <span>START DATE: </span>
                                                <span className='threeSectionResults'>{formatDatetimeStringWithPrefixZero(x.start_date)}</span>
                                            </Row>
                                            <Row>
                                                <span>END DATE: </span>
                                                <span className='threeSectionResults'>{formatDatetimeStringWithPrefixZero(x.end_date)}</span>
                                            </Row>
                                            <Row>
                                                <span>RX DATE: </span>
                                                <span className='threeSectionResults'>{formatDatetimeStringWithPrefixZero(x.prescription_date)}</span>
                                            </Row>
                                            <Row>
                                                <span>RX ID: </span>
                                                <span className='threeSectionResults'>{x.prescription_id}</span>
                                            </Row><Row>
                                                <span>RX FORM: </span>
                                                <span className='threeSectionResults'>{x.rx_form}</span>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col className='threeSectionCol'>
                                        <div>
                                            <Row>
                                                <span>DISPENSE: </span>
                                                <span className='threeSectionResults'>{x.dispensed_amount}</span>
                                            </Row>
                                            <Row>
                                                <span>REFILL: </span>
                                                <span className='threeSectionResults'>{formatDatetimeStringWithPrefixZero(x.date_recent_refill)}</span>
                                            </Row>
                                            <Row>
                                                <span>GENERIC?: </span>
                                                <span className='threeSectionResults'>{x.generic_flag}</span>
                                            </Row>
                                            <Row>
                                                <span>DAYS OF RX: </span>
                                                <span className='threeSectionResults'>{x.days_of_prescription}</span>
                                            </Row>
                                            <Row>
                                                <span># REFILLS: </span>
                                                <span className='threeSectionResults'>{x.no_of_refills}</span>
                                            </Row><Row>
                                                <span>REMAINING REFILLS: </span>
                                                <span className='threeSectionResults'>{x.refills_remaining}</span>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col className='threeSectionCol'>
                                        <div>
                                            <Row>
                                                <span>SIG: </span>
                                                <span className='threeSectionResults'>
                                                    {x.dosage_strength +' '+
                                                    x.dosage_unit +' '+ 
                                                    x.usage_frequency}
                                                </span>
                                            </Row>
                                            <Row>
                                                <span>NDC: </span>
                                                <span className='threeSectionResults'>{x.ndc_code}</span>
                                            </Row>
                                            <Row>
                                                <span>CODING SYSTEM: </span>
                                                <span className='threeSectionResults'>{x.coding_system}</span>
                                            </Row>
                                            <Row>
                                                <span>ROUTE OF ADMIN: </span>
                                                <span className='threeSectionResults'>{x.route_of_administration}</span>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                )
                            }) : <NoData /> 
                }         
            </Panel.Body>
        </Panel>
            
        )
    }
}

export default Medications;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';

class ProviderNPISearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParams: {
                lastName: '',
                firstName: '',
                credentials: '',
                city: '',
                state: '',
                zipCode: '',
                providerNPI: ''
            },
            searchResults: [],
            page: 1,
            sizePerPage: 10,
            loaded: true
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }
    
    componentWillReceiveProps(nextProps) {
        // Pull in first/last name from parent provider search modal
        if (nextProps.visible) {
            this.setState({
                searchParams: {
                    ...this.state.searchParams,
                    lastName: nextProps.providerLastName ? nextProps.providerLastName : '',
                    firstName: nextProps.providerFirstName ? nextProps.providerFirstName : '',
                    providerNPI: nextProps.providerNPI ? nextProps.providerNPI : ''
                },
                searchResults: []
            });
        }
    }

    handleProviderNPISearch = (e) => {
        this.setState({ loaded: false });
        axios.get('/merlinprovider/', {
            params: this.state.searchParams,
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                searchResults: response.data,
                page: 1,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({loaded:true});
            this.setState({searchResults:[]});
            this.props.toast('error',error.response.data.message,'Error');
        });
    }

    handleSelectSearchResult = (rowIndex) => {
        axios.post(`/memberService/v1/provider/importbynpi?providerNPI=${this.refs.table.state.data[rowIndex].providerNPI}&memberMasterID=${this.props.memberID}`)
        .then((response) => {
            // set diagnosis form provider ID field to selected search result value
            this.props.handleSelectSearchResult(response.data.providerMasterID, response.data.providerID, response.data.npi, response.data.lastName, response.data.firstName, response.data.planProviderID);
            // toggle NPI registry modal AND provider search modal to return user to diagnosis form
            this.props.toggleBackToDiagnosisForm();
        })
        .catch((error) => {
            this.props.toast('error', 'Could not select provider.', 'Error');
        });
    }

    handleSearchParamChange = (searchParam) => (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                [searchParam]: e.target.value
            }
        });
    }

    formatProviderName = (cell, row) => {
        if(row.lastName.length > 0 && row.firstName.length > 0)
        {
            return `${row.lastName}, ${row.firstName}`;
        }
        else if(row.lastName.length === 0 && row.firstName.length > 0)
        {
            return `${row.firstName}`;
        }

        return `${row.lastName}`;
    }

    formatProviderAddress = (cell, row) => {
        let address = row.addressLine1;
        if(row.addressLine2) {
            address += ` ${row.addressLine2}`;
        }
        address += ` ${row.state} ${row.zipcode}`;

        return address;
    }

    render() {
        const customSelect = (props) => {
            const rowIndex = props.rowIndex;
            return rowIndex !== 'Header' ? (
                <div>
                    <Button onClick={() => this.handleSelectSearchResult(rowIndex)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
            ) : null;
        };
        const selectRowProp = {
            mode: 'radio',
            customComponent: customSelect,
            columnWidth: '6rem'
        };
        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                });
            }
        };

        return (
            <Modal bsSize={'large'} show={this.props.visible} onHide={this.props.toggleBackToDiagnosisForm} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>NPI Registry Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{marginBottom: '1.6rem'}}>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Last Name</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.lastName} placeholder="Last Name" onChange={this.handleSearchParamChange('lastName')} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>First Name</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.firstName} placeholder="First Name" onChange={this.handleSearchParamChange('firstName')} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Credentials</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.credentials} placeholder="Credentials" onChange={this.handleSearchParamChange('credentials')} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>NPI</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.providerNPI} placeholder="NPI" onChange={this.handleSearchParamChange('providerNPI')} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>City</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.city} placeholder="City" onChange={this.handleSearchParamChange('city')} />
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}} className="requiredField">State</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.state} placeholder="State" onChange={this.handleSearchParamChange('state')} />
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Postal Code</ControlLabel>
                                    <FormControl type="text" value={this.state.searchParams.zipCode} placeholder="Postal Code" onChange={this.handleSearchParamChange('zipCode')} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={9}>
                                <FormGroup>
                                    <Button onClick={this.handleProviderNPISearch} style={{float: 'left'}} disabled={this.state.searchParams.providerNPI.length < 2 && this.state.searchParams.state.trim().length < 2}>
                                        <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Search
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <Button style={{float: 'right'}} onClick={this.props.handleModalToggle}>
                                    <Glyphicon glyph="collapse-down" style={{marginRight: '0.5rem'}} />Return to Provider Lookup
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{minHeight: '12rem'}}>
                        <Loader loaded={this.state.loaded}>
                            <BootstrapTable data={this.state.searchResults} ref='table' selectRow={selectRowProp} options={options} hover pagination>
                                <TableHeaderColumn dataField="lastName" dataAlign="left" width="20%" tdStyle={{ whiteSpace: 'normal' }}
                                    dataFormat={this.formatProviderName} dataSort>Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="credentials" dataAlign="left" width="12%" dataSort>Credentials</TableHeaderColumn>
                                <TableHeaderColumn dataField="providerNPI" dataAlign="left" width="13%" isKey dataSort>NPI</TableHeaderColumn>
                                <TableHeaderColumn dataField="addressLine1" dataAlign="left" width="40%" tdStyle={{ whiteSpace: 'normal' }}
                                    dataFormat={this.formatProviderAddress} dataSort>Address</TableHeaderColumn>
                                <TableHeaderColumn dataField="phoneNumber" dataAlign="left" width="15%" dataSort>Phone</TableHeaderColumn>
                            </BootstrapTable>
                        </Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.toggleBackToDiagnosisForm}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ProviderNPISearchModal;
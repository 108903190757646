import React from 'react';
import axios from 'axios';
import { Collapse } from 'react-bootstrap';
import AlertResponseGroup from './AlertResponseGroup';
import WorkflowSectionHeader from './WorkflowSectionHeader';

const AlertResponseContainer = (props) => {
    const updateAlertResponse = (value, cdiAlertEntityDetailID, cdiAlertWorkflowQueryID) => {
        if (value) {
            axios.put(getAlertUri(cdiAlertEntityDetailID, cdiAlertWorkflowQueryID), {
                response: value
            })
            .then((response) => {
                props.refreshAlertDetails();
            })
            .catch((error) => {
                props.toast('error', 'Could not save response.', 'Error');
            });
        }
    }

    const deleteAlertResponse = (value, cdiAlertEntityDetailID, cdiAlertWorkflowQueryID) => {
        axios.delete(getAlertUri(cdiAlertEntityDetailID, cdiAlertWorkflowQueryID))
        .then((response) => {
            props.refreshAlertDetails();
        })
        .catch((error) => {
            props.toast('error', 'Could not delete response.', 'Error');
        });
    }

    const getAlertUri = (cdiAlertEntityDetailID, cdiAlertWorkflowQueryID) => {
        if (cdiAlertEntityDetailID > 0) {
            return `/cdialertworkflow/${props.workflow.cdiAlertWorkflowID}/cdialertdetails/${cdiAlertEntityDetailID}`;
        }
        else if (cdiAlertWorkflowQueryID > 0) {
            return `/cdialertworkflow/${props.workflow.cdiAlertWorkflowID}/cdialertworkflowqueries/${cdiAlertWorkflowQueryID}`;
        }
        else {
            return '';
        }
    }

    const updateQueryResponse = (queryCodeID, queryResponseID, cdiAlertEntityDetailID, cdiAlertWorkflowQueryID) => {
        if (!queryResponseID) {
            queryResponseID = null;
        }
        axios.post('/cdialertworkflowquery/', {
            CDIAlertWorkflowQueryID: cdiAlertWorkflowQueryID,
            CDIAlertEntityDetailID: cdiAlertEntityDetailID,
            CDIAlertQueryCodeID: queryCodeID,
            CDIAlertQueryResponseID: queryResponseID,
            CDIAlertWorkflowID: props.workflow.cdiAlertWorkflowID
        })
        .then((response) => {
            props.refreshAlertDetails();
        })
        .catch((error) => {
            props.toast('error', 'Could not save response.', 'Error');
        });
    }

    const deleteQueryResponse = (cdiAlertWorkflowQueryID) => {
        if (cdiAlertWorkflowQueryID) {
            axios.delete(`/cdialertworkflowquery/${cdiAlertWorkflowQueryID}`)
            .then((response) => {
                props.refreshAlertDetails();
            })
            .catch((error) => {
                props.toast('error', 'Could not delete response.', 'Error');
            });
        }
    }

    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="response" title="Responses" />
            <Collapse in={props.expanded}>
                <div className="alert-workflow-alert-response-container">
                    {
                        props.workflowResponses.map(group => {
                            return (
                                <AlertResponseGroup key={group.groupCode} isQueryResponse={props.isQueryResponse}
                                    displayText={group.displayText} groupCode={group.groupCode} alertDetails={group.alertDetails}
                                    queryCodes={props.queryCodes} queryResponses={props.queryResponses}
                                    updateAlertResponse={updateAlertResponse} deleteAlertResponse={deleteAlertResponse}
                                    updateQueryResponse={updateQueryResponse} deleteQueryResponse={deleteQueryResponse}
                                    isViewOnly={props.isViewOnly} toast={props.toast} />
                            );
                        })
                    }
                </div>
            </Collapse>
        </div>
    );
}

export default AlertResponseContainer;
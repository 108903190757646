import React from 'react';
import { MenuItem, Nav, NavDropdown } from 'react-bootstrap';
import LineOfBusinessToggle from './LineOfBusinessToggle';
import NavItems from './NavItems';
import NavUserInfo from './NavUserInfo';
import { UserRole } from '../../enums/UserRole';

export default class NavHeader extends React.Component {
    constructor(props) {
        super(props);
        this.config = [
            {
                key: 'MRR',
                eventKey: 1.1,
                path: '/MRR',
                label: 'MRR'
            },
            {
                key: 'PEC',
                eventKey: 1.2,
                path: '/PEC',
                label: 'PEC'
            },
            {
                key: 'RADV',
                eventKey: 1.3,
                path: '/RADV',
                label: 'RADV'
            },
            {
                key: 'Admin',
                eventKey: 1.4,
                path: '/Admin',
                label: 'Administration'
            },
            {
                key: 'OOA',
                eventKey: 1.6,
                path: '/OOA',
                label: 'OOA'
            },
            {
                key: 'EMR',
                eventKey: 1.7,
                path: '/EMR/Practices',
                label: 'EMR'
            },
            {
                key: 'CampaignManagement',
                eventKey: 1.8,
                path: '/CampaignManagement',
                label: 'Campaign Management'
            },
            {
                key: 'MRRAutoQueue',
                eventKey: 2.1,
                path: '/MRRAutoQueue',
                label: 'MRR Auto Queue'
            }
        ];

        this.state = {
            selectedEventKey: this.getSelectedEventKey(props)
        };
    }

    getSelectedAppText = () => {
        const selectedEventKey = this.getSelectedEventKey(this.props);
        const selection = this.config.find(x => x.eventKey === selectedEventKey);
        if (selection && selection.label) {
            return selection.label;
        }
        else {
            return 'MRR';
        }
    }

    getSelectedEventKey = (props = this.props) => {
        const pathname = props.router.location.pathname.split('/');
        if (pathname.length > 1) {
            const selection = this.config.find(x => x.path.includes(pathname[1]));
            return selection ? selection.eventKey : 1.1;
        }
        else {
            return 1.1;
        }
    }

    setSelectedEventKey = (eventKey) => {
        this.setState({ selectedEventKey: eventKey }, () => {
            const selection = this.config.find(x => x.eventKey === eventKey);
            const path = selection && selection.path ? selection.path : '/MRR';
            this.props.router.push(path);
        });
    }

    setActiveStyle = (eventKey) => {
        return eventKey === this.getSelectedEventKey() ? 'nav-dropdown-active' : '';
    }

    getMenuItems = () => {
        const items = [];
        if (this.props.currentUser) {
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'MRR'))
            {
                items.push(<MenuItem eventKey={1.1} key={1.1} className={this.setActiveStyle(1.1)}>
                    MRR
                </MenuItem>);
            }
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'PEC'))
            {
                items.push(<MenuItem eventKey={1.2} key={1.2} className={this.setActiveStyle(1.2)}>
                    PEC
                </MenuItem>);
            }
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'RADV'))
            {
                items.push(<MenuItem eventKey={1.3} key={1.3} className={this.setActiveStyle(1.3)}>
                    RADV
                </MenuItem>);
            }
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'Administration'))
            {
                items.push(<MenuItem eventKey={1.4} key={1.4} className={this.setActiveStyle(1.4)}>
                    Administration
                </MenuItem>);
            }
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'OOA'))
            {
                items.push(<MenuItem eventKey={1.6} key={1.6} className={this.setActiveStyle(1.6)}>
                    OOA
                </MenuItem>);
            }
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'EMR')
                && this.props.currentUser.authorizedMRRApplicationRoles.some(a => a.roleID === UserRole.EMRAdmin))
            {
                items.push(<MenuItem eventKey={1.7} key={1.7} className={this.setActiveStyle(1.7)}>
                    EMR
                </MenuItem>);
            }
            if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'Campaign Management')
                && this.props.currentUser.authorizedMRRApplicationRoles.some(a => a.roleID === UserRole.CodingManagerAdmin))
            {
                items.push(<MenuItem eventKey={1.8} key={1.8} className={this.setActiveStyle(1.8)}>
                    Campaign Management
                </MenuItem>);
            }
            //This need to set-up for MRR Auto Queue
             if (this.props.currentUser.authorizedApplications.some(a => a.applicationName === 'MRR Auto Queue'))
             {
                items.push(<MenuItem eventKey={2.1} key={2.1} className={this.setActiveStyle(2.1)}>
                    MRR Auto Queue
                </MenuItem>);
             }
        }

        return items;
    }

    render() {
        const selection = this.config.find(x => x.eventKey === this.state.selectedEventKey);
        return (
            <header className="nav-header">
                <div style={{ display: 'inline-block' }}>
                    <Nav navbar>
                        <NavDropdown id="nav-dropdown" eventKey={1} title={this.getSelectedAppText()}
                            className="nav-dropdown" onSelect={this.setSelectedEventKey}>
                            {this.getMenuItems()}
                        </NavDropdown>
                        <NavItems pathname={this.props.router.location.pathname} pathRoot={selection.path}
                            selectedEventKey={this.state.selectedEventKey} />
                    </Nav>
                </div>
                <div style={{ display: 'inline' }}>
                    <NavUserInfo router={this.props.router} currentUser={this.props.currentUser} />
                    <LineOfBusinessToggle router={this.props.router} currentUser={this.props.currentUser} />
                </div>
            </header>
        );
    }
}
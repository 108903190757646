import React from 'react';
import { Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortDate } from '../../../../../utils/SortUtils';

class OpportunityCaptureHistory extends React.Component {
    renderTable = (results) => {
        const options = {
            defaultSortName: 'id',
            defaultSortOrder: 'asc'
        };
        return (
            <div>
                <BootstrapTable data={results} options={options} scroll-top="Top" hover striped>
                    <TableHeaderColumn dataField="id" hidden isKey></TableHeaderColumn>
                    <TableHeaderColumn dataField="dos" dataSort width="7%" sortFunc={(a, b, order) => sortDate(a.dos, b.dos, order)}>DOS</TableHeaderColumn>
                    <TableHeaderColumn dataField="group" dataSort width="7%" tdStyle={{ whiteSpace: 'normal' }}>Group</TableHeaderColumn>
                    <TableHeaderColumn dataField="alertText" dataSort width="42%" tdStyle={{ whiteSpace: 'normal' }}>Alert Text</TableHeaderColumn>
                    <TableHeaderColumn dataField="diagnosisCD" dataSort width="5%">DX Code</TableHeaderColumn>
                    <TableHeaderColumn dataField="coder" dataSort width="7%" tdStyle={{ whiteSpace: 'normal' }}>Coder</TableHeaderColumn>
                    <TableHeaderColumn dataField="status" dataSort width="7%">Status</TableHeaderColumn>
                    <TableHeaderColumn dataField="dosentered" dataSort width="7%" sortFunc={(a, b, order) => sortDate(a.dosentered, b.dosentered, order)}>Date Entered</TableHeaderColumn>
                    <TableHeaderColumn dataField="rejectComments" dataSort tdStyle={{ whiteSpace: 'normal' }}>Reject Comments</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Panel className="capturePanel" header="Opportunities Capture History">
                    {
                        this.props.opportunityData &&
                        this.renderTable(this.props.opportunityData)
                    }
                </Panel>
            </div>
        );
    }
}

export default OpportunityCaptureHistory;
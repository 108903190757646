import React from 'react';
import axios from 'axios';
import { Col, ControlLabel, Form, FormGroup, Button } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import AlertToastr from '../Shared/AlertToastr';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import '../../styles/Report.css';

class FinalMeasureReport extends React.Component {
    constructor(props) {
        super(props);
        this.tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();
        this.yearAgo = new Date(new Date().setDate(new Date().getDate() - 365)).toISOString();

        this.state = {
            projectRecords: [],
            projectName: "",
            projectIDs: [],
            reportData: [],
            reportDataCount: 0,
            page: 1,
            pageSize: 250,
            sortBy: '',
            sortByDesc: false,
            copied: "",
            copiedTimer: false,
            maxEntryDate: '',
            minEntryDate: '',
            statusIDs: [40, 50],
            toast: null
        }
    }

    componentWillMount() {
        axios.get('/projects', {
            params: {
                programID: 3
            }
        })
        .then((response) => {
            this.setState({ projectRecords: response.data });
        });
        // .catch((error) => {
        //     //todo: add error notification
        // });
    }

    handleProjectChange = (options) => {
        this.setState({ 
            projectIDs: options.map(item => item.value)
        });
    }

    //todo: Date.parse calls here should be replaced with new Date(dateValue)
    handleDateChange = (statePropertyName) => (dateValue) => {
        if (dateValue !== null && Date.parse(new Date(dateValue)) >= (new Date(this.tomorrow)).setHours(0,0,0,0)) {
                dateValue = "";
                this.state.toast('error', 'Max entry date is today', 'Error');
        }
        else if (dateValue !== null && Date.parse(new Date(dateValue)) < (new Date(this.yearAgo)).setHours(0,0,0,0)) {
                dateValue = "";
                this.state.toast('error', 'Min entry date is one year prior to today ', 'Error');
        }

        if (dateValue && (
            (statePropertyName === "maxEntryDate" && this.state.minEntryDate !== "" && dateValue < this.state.minEntryDate) ||
            (statePropertyName === "minEntryDate" && this.state.maxEntryDate !== "" && dateValue > this.state.maxEntryDate))) {
            dateValue = "";
            this.state.toast('error', 'Min entry date must fall before max entry date', 'Error');
        }
        this.setState({
            [statePropertyName]: dateValue
        });
    }

    handleStatusChange = (options) => {
        this.setState({
            statusIDs: options.map(item => item.value)
        })
    }

    getFinalMeasureReportData = (page = this.state.page, pageSize = this.state.pageSize) => {
        const queryParams = {
            page: page,
            pageSize: pageSize,
            minEntryDate: this.state.minEntryDate,
            maxEntryDate: this.state.maxEntryDate,
            projectIDs: this.state.projectIDs,
            reviewStatusIDs: this.state.statusIDs,
            orderByColumn: this.state.sortBy,
            orderByDesc: this.state.sortByDesc
        }
        axios.get('/starsFinalMeasureReport', { params: queryParams })
        .then(response => {
            response.data.items && response.data.items.filter((entry) => {
                return entry.fullName = entry.memberLastName + ', ' + entry.memberFirstName;
            })
            this.setState({
                reportData: response.data.items,
                reportDataCount: response.data.totalItems,
                page: page
            });
        })
        .catch(error => {
            this.setState({
                reportData: [],
                reportDataCount: 0,
                page: 1
            });
            this.state.toast('error', 'Could not load report data.', 'Error');
        });
    }

    formatDateString = (dateString) => {
        return dateString ? (new Date(dateString)).toLocaleDateString() : null;
    }

    copyToClipboard = (e) => {
        const element = this.table;
        const selection = window.getSelection();
        const range = window.document.createRange();
        try {
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.setState({ 
                copied: "Copied.",
                copiedTimer: true 
            });
            setTimeout(() => {
                this.setState({ copiedTimer: false })
            }, 3000);
        }
        catch (e) {
            console.log(e);
            this.setState({ copied: "Could not copy. Try again." });
        }
        selection.removeAllRanges();
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    buildFunctionForDownload = () => {
        const queryParams = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            minEntryDate: this.state.minEntryDate,
            maxEntryDate: this.state.maxEntryDate,
            projectIDs: this.state.projectIDs,
            reviewStatusIDs: this.state.statusIDs
        }
        return () => axios.post("/starsfinalmeasurereport/downloadrequest", queryParams);
    }

    render() {
        const reportData = this.state.reportData;
        // const statuses = [
        //     {value: 40, label: 'Evaluation for Submission'}, 
        //     {value: 50, label: 'Submission Evaluation Complete'}
        // ];
        const projects = this.state.projectRecords ?
        this.state.projectRecords.map(item => {
            return {
                value: item.projectID,
                label: item.projectName,
            }
        }) : [];

        const options = {
            page: this.state.page,
            sizePerPageList: [5, 10, 50, 100, 250]
                .map(item => { return {text: item.toString(), value: item}}),
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                }, this.getFinalMeasureReportData);
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortOrder === 'desc'
                }, this.getFinalMeasureReportData);
            }
        }
        
        return (
            <div className="reportContainer">
                <div className="innerReportContainer" style={{minHeight: '250px', marginLeft: '1%', marginRight: '1%'}}>
                    <br />
                    <header>
                        <Col style={{ marginLeft: 'auto', marginRight: 'auto', width: '240px' }}>
                            <h3>Final Measure Report</h3>
                        </Col>
                    </header>
                    <Form inline>
                        <FormGroup style={{ width: '20%', marginBottom: '20px', marginRight: '2.5rem' }}>
                            <ControlLabel>Project</ControlLabel>
                            <Select 
                                multi
                                value={this.state.projectIDs}
                                options={projects}
                                autosize={true}
                                onChange={this.handleProjectChange}/>
                        </FormGroup>
                        <FormGroup style={{'marginBottom': '2rem', verticalAlign: 'top'}}>
                            <Col style={{paddingLeft: '0px', marginRight: '1.9rem'}}>
                                <ControlLabel>
                                    Entry Date
                                </ControlLabel>
                            </Col>
                            <Col>
                                <div style={{ display: 'inline-block', marginRight: '2.5rem'}}>
                                    <DatePicker value={this.state.minEntryDate} onChange={this.handleDateChange('minEntryDate')} style={{zIndex: 'auto'}}/>
                                </div>
                                <div style={{display: 'inline-block', marginRight: '3rem'}}>
                                    <DatePicker value={this.state.maxEntryDate} onChange={this.handleDateChange('maxEntryDate')} style={{zIndex: 'auto'}}/>
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup style={{ verticalAlign: 'top', marginRight: '1.9rem', marginTop: '1.9rem'}}>
                            <Button bsStyle="success" style={{ verticalAlign: 'top'}} onClick={() => this.getFinalMeasureReportData(1)}>Generate</Button>
                            <div style={{display: reportData ? 'inline' : 'none', marginLeft: '2.5rem', verticalAlign: 'top'}}>
                                <Button bsStyle="info" onClick={this.copyToClipboard}>Copy</Button>
                                <p style={{display: this.state.copiedTimer === true ? 'inline' : 'none', marginLeft: '2.5rem'}}>{this.state.copied !== "" ?  this.state.copied : ""}</p>
                            </div>
                        </FormGroup>
                    </Form>
                    <div className="copyContent" ref={(table) => this.table = table} style={{ marginTop: '1.9rem', marginBottom: '1.9rem' }}>
                        <BootstrapTable data={this.state.reportData} options={options} pagination remote fetchInfo={{ dataTotalSize: this.state.reportDataCount }} maxHeight='400px'>
                            <TableHeaderColumn dataField="projectName" dataSort width='100px'>Project</TableHeaderColumn>
                            <TableHeaderColumn dataField="userName" dataSort width='120px'>User Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="medicalRecordReviewID" dataSort width='100px'>MRR ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberID" isKey dataSort width='110px'>Member ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="lastName" dataSort width='130px'>Last Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="firstName" dataSort width='130px'>First Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="measure" dataSort width='90px'>Measure</TableHeaderColumn>
                            <TableHeaderColumn dataField="dateOfServiceFrom" dataSort dataFormat={this.formatDateString} width='100px'>Date of Service</TableHeaderColumn>
                            <TableHeaderColumn dataField="serviceDescription" dataSort width='120px'>Service Description</TableHeaderColumn>
                            <TableHeaderColumn dataField="dynamicCaptureFieldTitle" dataSort width='120px'>Result Header</TableHeaderColumn>
                            <TableHeaderColumn dataField="resultValue" dataSort width='100px'>Result Value</TableHeaderColumn>
                            <TableHeaderColumn dataField="entryDate" dataSort dataFormat={this.formatDateString} width='100px'>Entry Date</TableHeaderColumn>
                            <TableHeaderColumn dataField="extractDate" dataSort  dataFormat={this.formatDateString} width='100px'>Extract Date</TableHeaderColumn>
                            <TableHeaderColumn dataField="recordTypeDescription" dataSort width='90px'>Record Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="statusDescription" dataSort width='100px'>Review Status</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <div>
                        <LongRunningDownloadAnchor linkText="Download .CSV" postRequest={this.buildFunctionForDownload()}
                            displayCSVDownloader={this.state.reportData ? this.state.reportData.length : false} />
                    </div>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default FinalMeasureReport;

import React from 'react';
import { Button, Glyphicon, Modal } from 'react-bootstrap';

const AlertDialog = ({ visible, handleModalToggle, title, message, handleConfirm, confirmLabel, cancelLabel, confirmStyle, glyphicon, showConfirm = true, renderInnerDiv = false, renderBody = true, dialogClassName = '' }) =>
    <Modal show={visible} onHide={handleModalToggle} dialogClassName={dialogClassName} keyboard>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        { renderBody &&
            <Modal.Body>
            { !renderInnerDiv && 
                <p><strong>{message}</strong></p>
            }
            { renderInnerDiv && 
                <div>{message}</div>
            }
            </Modal.Body>
        }
        <Modal.Footer>
            {
                showConfirm &&
                <Button bsStyle={confirmStyle} key="save" onClick={handleConfirm} style={{ float: 'left' }}>{confirmLabel}
                    <Glyphicon glyph={glyphicon ? glyphicon : ''} style={{ marginLeft: '0.66rem', top: '1.6px',
                        lineHeight: '14px', float: 'right'}} />
                </Button>
            }
            <Button onClick={handleModalToggle} style={{ float: 'right' }}>{cancelLabel}</Button>
        </Modal.Footer>
    </Modal>

export default AlertDialog;
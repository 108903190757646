import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import EPECProviderExclusionModal from './EPECProviderExclusionModal';

class EPECProviderExclusionButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            showEPECModal: false,
            providers: [],
            saveMessage: ""
        }

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    handleEPECModalToggle = () => {
        this.setState((prevState) => {
            return {
                showEPECModal: !prevState.showEPECModal,
                saveMessage: "",
                loading: prevState.showEPECModal ? false : true
            }
        }, () => {
            if (this.state.showEPECModal) {
                this.getEPECProviderExclusionList("isExcluded", true);
            }
        });
    }

    getEPECProviderExclusionList = (sortBy = this.state.sortBy, sortByDesc = this.state.sortByDesc) => {
        console.log(this.state)

        axios.get(`/practice/${this.props.practiceID}/ProviderExclusion`, {
            params: {
                sortBy: sortBy,
                sortByDesc: sortByDesc
            }, cancelToken: this.source.token
        })
        .then((response) => {
                this.setState({
                    providers: response.data,
                    loading: false
                })
        })
        .catch((error) => {
                this.setState({
                    loading: false
                })
        })
    }

    handleIsExcludedChange = (isExcluded, row, sortBy, sortByDesc) => {
        this.setState({
            saveMessage: "Saving..."
        });

        const updated = {
            ...row, 
            isExcluded: !isExcluded
        }

        this.saveRow(updated, sortBy, sortByDesc);
    }

    saveRow = (row, sortBy, sortByDesc) => {
        axios.put(`/practice/${this.props.practiceID}/ProviderExclusion`, row, {
            params: {
                sortBy: sortBy,
                sortByDesc: sortByDesc
            }, cancelToken: this.source.cancelToken
        })
        .then((response) => {
            this.setState({
                providers: response.data,
                saveMessage: "Saved"
            })
        })
        .catch((error) => {
            this.setState({
                saveMessage: "Error saving selection. Please try again later."
            })
        });
    }

    render() {
        return (
            <div>
                <Button onClick={this.handleEPECModalToggle}>
                    Edit
                </Button>
                <EPECProviderExclusionModal show={this.state.showEPECModal}
                        loading={this.state.loading}
                        handleToggle={this.handleEPECModalToggle}
                        handleIsExcludedChange={this.handleIsExcludedChange}
                        getEPECProviderExclusionList={this.getEPECProviderExclusionList}
                        providers={this.state.providers} 
                        saveMessage={this.state.saveMessage}
                        practiceName={this.props.practiceName} />
            </div>
        )
    }
}

export default EPECProviderExclusionButton;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, Glyphicon, Grid, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AlertDialog from '../../../Shared/AlertDialog';

export default class PracticeGroupingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            showEMRAccessModal: false,
            lastCreatedGroupingID: ''
        }
    }

    createPracticeGrouping = () => {
        axios.post('/campaignGroup', {
            groupName: this.state.groupName
        })
        .then((response) => {
            this.setState({ lastCreatedGroupingID: response.data.campaignGroupID });

            this.props.refreshPracticeGroupings();
            this.props.togglePracticeGroupingsModal();

            // ask if user wants to create emr access details
            this.toggleEMRAccessModal();
        })
        .catch((error) => {
            if (error.response.status === 403) {
                this.props.toast('error', 'You are not authorized to create practice grouping.', 'Error');
            } else {
                this.props.toast('error', 'Failed to create practice grouping.', 'Error');
            }
        });
    }

    updatePracticeGrouping = (params) => {
        axios.put('/campaignGroup', {
            campaignGroupID: params.campaignGroupID,
            groupName: params.groupName
        })
        .then((response) => {
            //todo - replace
            this.props.refreshPracticeGroupings();
        })
        .catch((error) => {
            if (error.response.status === 403) {
                this.props.toast('error', 'You are not authorized to save practice grouping.', 'Error');
            } else {
                this.props.toast('error', 'Failed to save practice grouping.', 'Error');
            }
        });
    }

    // todo(?)
    // deletePracticeGrouping = (campaignGroupID) => {
    //     axios.delete('/campaignGroup', {
    //         campaignGroupID
    //     })
    //     .then((response) => {
    //         //todo - replace
    //         this.props.refreshPracticeGroupings();
    //     })
    //     .catch((error) => {
    //         //this.setState({ loaded: true });
    //         this.props.toast('error', 'Failed to delete practice grouping.', 'Error');
    //     });
    // }

    setGroupName = (e) => {
        this.setState({ groupName: e.target.value });
    }

    onBeforeSaveCell = (row, cellName, cellValue) => {
        // return false to stop update if validation is needed, etc.
        if (!cellValue || row[cellName].trim() === cellValue.trim()) {
            return false;
        }

        row[cellName] = cellValue;
        this.updatePracticeGrouping(row);
    }

    toggleEMRAccessModal = () => {
        this.setState((prevState) => {
            return { showEMRAccessModal: !prevState.showEMRAccessModal }
        });
    }

    handleCreateEMRDetailsClick = () => {
        if (this.state.lastCreatedGroupingID) {
            this.props.router.push(`/EMR/Access/Wizard?campaignGroupID=${this.state.lastCreatedGroupingID}`);
        }
    }

    render() {
        const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell
        };

        return (
            <div>
                <Modal show={this.props.showPracticeGroupingModal} onHide={this.props.togglePracticeGroupingsModal}  keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Practice Groupings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row style={{ marginTop: '2rem' }}>
                                <Col xs={6}>
                                    <ControlLabel>Grouping Name</ControlLabel>
                                    <FormControl type="text" value={this.state.groupName} onChange={this.setGroupName} />
                                </Col>
                                <Col xs={1} style={{ paddingLeft: 0 }}>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <Button onClick={this.createPracticeGrouping}
                                        disabled={!(this.state.groupName && this.state.groupName.trim().length > 0)}>
                                        <Glyphicon glyph="plus" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '2rem' }}>
                                <Col xs={12}>
                                    <BootstrapTable data={this.props.practiceGroupings} cellEdit={cellEdit} maxHeight="516px" scrollTop={"Top"}
                                        hover pagination multiColumnSort={2}>
                                        <TableHeaderColumn dataField="campaignGroupID" isKey hidden></TableHeaderColumn>
                                        <TableHeaderColumn dataField="groupName" editable={{ type: 'textarea' }} dataAlign="left" dataSort>Grouping Name</TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Body>
                </Modal>
                <AlertDialog visible={this.state.showEMRAccessModal} handleModalToggle={this.toggleEMRAccessModal}
                    title={'Add EMR access details for this practice grouping?'}
                    renderBody={false} handleConfirm={this.handleCreateEMRDetailsClick} confirmLabel={"Yes"}
                    cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
            </div>
        )
    }
}
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import Select from 'react-select';
import { MedicalRecordType } from '../../../enums/MedicalRecordType';
import { SessionParamCache } from '../../../utils/SessionUtils';
import ProviderTypeaheadSearch from '../../Shared/ProviderTypeaheadSearch';
import MemberTypeaheadSearch from '../../Shared/MemberTypeaheadSearch';

//todo: fix member search modal button responsiveness / stacking
class ProspectiveSearch extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = 'prospectiveSearch';
        const savedParams = this.paramCache.getParamObj(this.paramCacheKey);

        this.state = {
            recordType: savedParams.recordType ? savedParams.recordType : '',
            recordEntityType: savedParams.recordEntityType ? savedParams.recordEntityType : '',
            planMemberID: savedParams.planMemberID ? savedParams.planMemberID : '',
            selectedProvider: savedParams.selectedProvider ? savedParams.selectedProvider : '',
            selectedMember: savedParams.selectedMember ? savedParams.selectedMember : '',
            showMemberSearchModal: false,
            showProviderSearchModal: false
        };
    }

    handleRecordTypeChange = (e) => {
        if (this.props.memberMedicalRecords.length > 0) {
            this.props.clearMemberMedicalRecords();
        }
        this.setState({ recordType: e });
    }

    handleRecordEntityTypeChange = (e) => {
        this.setState({ recordEntityType: e });
    }

    handleMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value })
    }

    handleProviderTypeaheadChange = (e) => {
        this.setState({ selectedProvider: e });
    }

    handleMemberTypeaheadChange = (e) => {
        this.setState({ selectedMember: e });
    }

    handleSearch = () => {
        const queryParams = {
            recordType: this.state.recordType ? this.state.recordType.value : null,
            recordEntityTypeID: this.state.recordEntityType ? this.state.recordEntityType.value : null,
            planMemberID: this.state.selectedMember ? this.state.selectedMember.memberID : null,
            providerMasterID: this.state.selectedProvider ? this.state.selectedProvider.internalKey : null
        };
        const cacheParams = {
            recordType: this.state.recordType,
            recordEntityType: this.state.recordEntityType,
            planMemberID: this.state.selectedMember?.memberID,
            selectedProvider: this.state.selectedProvider
        };

        this.props.handleSearch(queryParams, cacheParams);
    }

    handleStartReview = () => {
        this.paramCache.addParamObj('initialSearch', {
            project: this.props.project,
            reviewType: this.props.reviewType
        });
        this.paramCache.addParamObj(this.paramCacheKey, {
            recordType: this.state.recordType,
            recordEntityType: this.state.recordEntityType,
            planMemberID: '',
            selectedProvider: this.state.selectedProvider
        });

        const selectedMedicalRecordEntityIDs = this.props.memberMedicalRecords.filter(x => x.selected).map(x => x.medicalRecordEntityID).join(',');
        axios.post("/MRReviewProcessing/Insert", {
            memberInternalKey: this.state.selectedMember ? this.state.selectedMember.internalKey : null,
            memberId: this.state.planMemberID ? this.state.planMemberID : null,
            providerInternalKey: this.state.selectedProvider ? this.state.selectedProvider.internalKey : null,
            reviewTypeID: this.props.reviewType ? this.props.reviewType.value : 0,
            medicalRecordTypeID: this.state.recordType ? this.state.recordType.value : 0,
            projectId: this.props.project.value,
            userId: 0,
            medicalRecordEntityIDs: selectedMedicalRecordEntityIDs.length > 0 ? selectedMedicalRecordEntityIDs : null,
            isCDIQueue: false
        })
        .then((response) => {
            //todo: should refactor out the below:
            let queryParams = '';
            Object.entries(response.data.params).forEach((x) => {
                if (queryParams === '') {
                    queryParams = queryParams + x[0] + '=' + x[1];
                }
                else {
                    queryParams = queryParams + '&' + x[0] + '=' + x[1];
                }
            });

            window.location.href = `/ReviewProcessing/InitialCoding?${queryParams}`.replace("medicalRecordReviewId", "medicalRecordReviewID");
        })
        .catch((error) => {
            this.props.toast('error', error && error.response && error.response.data && error.response.data.message
                ? `Could not start review. ${error.response.data.message}` : 'Could not start review.', 'Error');
        });
    }

    toggleMemberSearchModal = () => {
        this.setState({ showMemberSearchModal: !this.state.showMemberSearchModal });
    }

    handleMemberSelect = (planMemberID) => {
        this.setState({ planMemberID: planMemberID });
    }

    emrRecordTypeSelected = () => {
        return this.state.recordType && this.state.recordType.value === MedicalRecordType.EMR
    }

    emrProviderSelectionRequired = () => {
        return this.emrRecordTypeSelected() && this.props.requireProviderIDForRecordTypeEMR;
    }

    providerIsSelected = () => {
        return this.state.selectedProvider && this.state.selectedProvider.internalKey;
    }

    areReviewsSelected = () => {
        return this.props.memberMedicalRecords.find(x => x.selected);
    }

    toggleProviderSearchModal = () => {
        this.setState({ showProviderSearchModal: !this.state.showProviderSearchModal });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel className="requiredField">Record Type</ControlLabel>
                            <Select value={this.state.recordType} options={this.props.getDropdownOptions(this.props.recordTypes)}
                                onChange={this.handleRecordTypeChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>Record Entity Type</ControlLabel>
                            <Select value={this.state.recordEntityType} options={this.props.getDropdownOptions(this.props.recordEntityTypes)}
                                onChange={this.handleRecordEntityTypeChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <MemberTypeaheadSearch value={this.state.selectedMember} handleMemberChange={this.handleMemberTypeaheadChange} 
                                showMemberSearchModal={this.state.showMemberSearchModal} toggleMemberSearchModal={this.toggleMemberSearchModal}
                                toast={this.props.toast} />
                    </Col>
                    <Col xs={3}>
                        <ProviderTypeaheadSearch value={this.state.selectedProvider} handleProviderChange={this.handleProviderTypeaheadChange}
                            showProviderModal={this.state.showProviderSearchModal} toggleProviderSearchModal={this.toggleProviderSearchModal}
                            isRequired={this.emrProviderSelectionRequired()} toast={this.props.toast} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Button onClick={this.handleSearch} style={{ marginTop: '2rem' }} disabled={this.props.waitingOnSearch || !this.props.project || !this.state.recordType
                            || !this.state.selectedMember || this.emrRecordTypeSelected()} >
                            <Glyphicon glyph="search" style={{ marginRight: '0.2rem'}} /> Search
                        </Button>
                        <Button onClick={this.handleStartReview} style={{ marginTop: '2rem', marginLeft: '1rem' }} disabled={!this.props.project || !this.state.recordType
                            || !this.state.selectedMember || (this.emrProviderSelectionRequired() && !this.providerIsSelected())}>
                            <Glyphicon glyph="plus" style={{ marginRight: '0.2rem'}} /> Start Review
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProspectiveSearch;
import React from 'react';
import axios from 'axios';
import { Button, Glyphicon, Modal } from 'react-bootstrap';
import { stringify } from '../../../external/query-string';
import LongRunningDownloadAnchor from '../../Shared/LongRunningDownloadAnchor';

class ScanRosterPrintModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
        };
    }

    //todo: test download generation request; poll for download; make download available on client when ready
    getRosterCSV = () => {
        return axios.post("/ApplicationReports/DownloadRequests", {
            applicationReportID: "10",
            reportParameters: this.props.searchParams
        })
        .catch((error) => {
            console.log(error);
        });
    }

    //todo: create printable scan roster interface
    redirectToPrintView = () => {
        //remove params that are null, undefined, or empty
        const paramsWithValues = Object.keys(this.props.searchParams).reduce((acc, key) => {
            if (this.props.searchParams[key] !== null && this.props.searchParams[key] !== undefined && this.props.searchParams[key] !== '') {
                acc[key] = this.props.searchParams[key];
            }
            return acc;
        }, {});

        this.props.router.push("/ScanRosterPrintable?" + stringify({...paramsWithValues, providerDesc: this.props.providerDesc(), practiceDesc: this.props.practiceDesc()}));
    }

    render() {
        return (
            <Modal className="practiceModal" show={this.props.visible} onHide={this.props.togglePrintModal} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Print Roster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LongRunningDownloadAnchor linkText="Download .CSV"
                        displayCSVDownloader="true" glyph="download"
                        postRequest={this.getRosterCSV}>
                    </LongRunningDownloadAnchor>
                    <Button onClick={this.redirectToPrintView}>
                        <Glyphicon glyph="print" style={{marginRight: '0.2rem'}} /> View Printable
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ScanRosterPrintModal;
import React, { useState } from "react";
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import Loader from 'react-loader';
import Select from 'react-select';
import { HttpCode } from '../../../../enums/HttpCode';

const AddReferenceItemModal = (props) => {
    
    const [loaded, setLoaded] = useState(true);
    const [selectedValue, setSelectedValue] = useState('');
    const [inputDescText, setInputDescText] = useState('');

    const handleInput = (e) => {
        setInputDescText(e.target.value);
    }

    const handleSelectChange = (e) => {
        setSelectedValue(e ? e.value : '');
    }

    const handleModalClose = () => {
        setLoaded(true);
        setSelectedValue('');
        setInputDescText('');
        props.handleModalClose();
    }

    const handleReferenceItemAdd = () => {
        if (!loaded) {
            return;
        }
        setLoaded(false);
        
        let promise = () => axios.post(`/campaignEmrReferenceItem`, {
            campaignEMRReferenceItemTypeID: props.refTypeID,
            campaignEMRReferenceItemDescription: inputDescText,
        });
        if (props.practiceInformationMode) {
            promise = () => axios.post(`/campaignService?serviceDescription=${inputDescText}`);
        }

        promise().then((response) => {
            if (props.practiceInformationMode) {
                props.refreshServiceItems();
                handleModalClose();
            }
            else {
                props.handleRefItemRefresh(props.refTypeID);
            }
            props.toast('success', 'Option added.', 'Success');
        })
        .catch((error) => {
            if (error.response.status === HttpCode.InternalServerError) {
                props.toast('error', 'Could not add option.', 'Error');
            }
            else {
                props.toast('error', error.response.data.message, 'Error');
            }
        })
        .finally(() => {
            setLoaded(true);
        });
    }

    
    return (
        <div>
            <Modal show={props.visible} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Add new {props.refTypeDesc} option?</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '340px' }}>
                    <Loader loaded={loaded}>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel>Existing Options</ControlLabel>
                                    <Select value={selectedValue} options={props.options} onChange={handleSelectChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel>New Item Description</ControlLabel>
                                    <FormControl type="text" value={inputDescText} onChange={handleInput} />
                                </FormGroup>
                            </Col>
                            <Col xs={1}>
                                <div style={{ paddingTop: '2.4rem' }}>
                                    <Button onClick={handleReferenceItemAdd}
                                        disabled={inputDescText.length < 1}>
                                        Add
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Loader>
                </Modal.Body>
                <Modal.Footer />
            </Modal>
        </div>
    );
}

export default AddReferenceItemModal;
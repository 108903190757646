import React from 'react';
import axios from 'axios';
import { Checkbox, Modal, Col, Row, FormControl, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus'

class CoderQADiagnosisFeedbackModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            penalty: 0,
            medicalRecordDiagID: null,
            feedbackTXT: '',
            coderQADiagComments: [],
            validationMessage: null
        }
    }
    
    componentWillMount() {
        this.getCoderQADiagComments();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.coderQADiagComments.length > 0 && nextProps.visible && nextProps.medicalRecordDiagID > 0) {
            this.setState({ validationMessage: null });
            this.getDiagnosisCoderQAScore(nextProps.medicalRecordDiagID);
        }
    }

    getCoderQADiagComments = () => {
        axios.get('/projectCommentDefinition',
        {
            params: {
                projectId: this.props.projectID,
                mrrId: this.props.medicalRecordReviewID,
                commentType: 'ReviewDiagCoderQA'
            }
        })
        .then((response) => {
            this.setState({ coderQADiagComments: response.data });
        })
        .catch((error) => {
            console.log(error);
        })
    }

    getDiagnosisCoderQAScore = (medicalRecordDiagID) => {
        axios.get(`/coderQA/${this.props.coderQAQueueID}/medicalRecordReviews/${this.props.medicalRecordReviewID}/diagnosis/${medicalRecordDiagID}/score`)
        .then((response) => {
            this.setState({
                coderQADiagComments: this.state.coderQADiagComments.map(comment => {
                    return {
                        ...comment,
                        isChecked: response.data.feedbackReasonsIDs.find(selectedCommentID => selectedCommentID === comment.commentDefinitionID) !== undefined
                    }
                }),
                feedbackTXT: response.data.feedbackTXT,
                penalty: response.data.penaltyValue
            });
        })
        .catch((error) => {
            this.setState({
                coderQADiagComments: this.state.coderQADiagComments.map(comment => {
                    return {
                        ...comment,
                        isChecked: false
                    }
                }),
                feedbackTXT: '',
                penalty: 0
            });

            console.log(error);
        })
    }

    coderQADiagCommentCheckboxChanged = (comment) => {
        comment.isChecked = !comment.isChecked;
        this.setState({ coderQADiagComments: this.state.coderQADiagComments });
    }

    mapCoderQADiagComments = () => {
        return this.state.coderQADiagComments.map(x => {
                return (
                    <li key={x.commentDefinitionID}>
                        <Checkbox onChange={() => this.coderQADiagCommentCheckboxChanged(x)} name="selectedCoderQAComments"
                            id={x.commentGroupID} checked={x.isChecked} className="commentCheckbox">
                            {x.commentText}
                        </Checkbox>
                    </li>
                )
            }
        )
    }

    saveFeedback = () => {
        const coderQAScore = {
            medicalRecordReviewCoderQAQueueID: this.props.coderQAQueueID,
            penaltyValue: this.state.penalty,
            feedbackTXT: this.state.feedbackTXT,
            feedbackReasonsIDs: this.state.coderQADiagComments.filter(comment => comment.isChecked).map(comment => comment.commentDefinitionID)
        }

        if (this.props.postSubmitStatusID === LineItemReviewStatus.CoderQARejected && !coderQAScore.feedbackReasonsIDs.length) {
            this.setState({ validationMessage: "At least one comment must be selected when rejecting a diagnosis" });
            return;
        }

        if(coderQAScore.penaltyValue < 0 || coderQAScore.penaltyValue > 100) {
            this.setState({ validationMessage: "Penalty value must be between 0 and 100" });
            return;
        }

        this.setState({ validationMessage: null });

        axios.post(`/coderQA/${this.props.coderQAQueueID}/medicalRecordReviews/${this.props.medicalRecordReviewID}/diagnosis/${this.props.medicalRecordDiagID}/score`, coderQAScore)
            .then((response) => {
                this.props.handleModalToggle();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    cancelFeedback = () => {
        this.props.handleModalToggle();
    }

    handleSetPenalty = (e) => {
        if (e.target.value < 0) {
            return;
        }
        this.setState({ penalty: e.target.value });
    }
    
    handleSetFeedbackTXT = (e) => {
        this.setState({ feedbackTXT: e.target.value });
    }

    render() {
        const confirmReject = this.props.postSubmitStatusID === LineItemReviewStatus.CoderQAConfirmed ? 'Confirm' : (this.props.postSubmitStatusID === LineItemReviewStatus.CoderQARejected ? 'Reject' : 'Add');

        return (
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Would you like to apply feedback?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col sm={6}>
                                <h5>Feedback Reasons</h5>
                                <ul style={{listStyle: 'none', height: '250px', overflow: 'auto'}}>
                                {
                                    this.mapCoderQADiagComments()
                                }
                                </ul>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <ControlLabel style={{marginRight: '1rem'}}>Penalty</ControlLabel>
                                            <FormControl type="number" min='0' max='100' value={this.state.penalty} onChange={this.handleSetPenalty} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <ControlLabel style={{marginRight: '1rem' }}>Additional Description</ControlLabel>
                                            <FormControl componentClass="textarea" value={this.state.feedbackTXT} onChange={this.handleSetFeedbackTXT}
                                                         placeholder="Comments" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Row show={this.state.validationMessage}>
                            <Col className="errorMessage" style={{textAlign: 'left'}} sm={12}>{this.state.validationMessage}</Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Button onClick={this.saveFeedback}>{confirmReject}</Button>
                            </Col>
                            <Col sm={2}>
                                <Button onClick={this.cancelFeedback}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CoderQADiagnosisFeedbackModal;
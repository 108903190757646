// exports EMRDocumentFileType - an immutable array
// usage: import { EMRDocumentFileType } from '../../enums/EMRDocumentFileType';
const emrDocumentFileType = [
    'pdf',
    'png',
    'jpg',
    'docx',
    'xlsx',
    'xls',
    'txt'
];
Object.freeze(emrDocumentFileType);
export const EMRDocumentFileType = emrDocumentFileType;
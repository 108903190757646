import React from 'react';
import { Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

//todo: should this be paginated or not (currently paginated; previously wasn't)?
const WorkflowHistoryModal = (props) => {
    //ex. 6/15/2008 - 9:15:07 PM
    const formatDateTime = (dateTime) => {
        return dateTime ? `${new Date(dateTime).toLocaleDateString('en-US')} -
            ${new Date(dateTime).toLocaleTimeString('en-US')}` : '';
    }

    return (
        <Modal show={props.visible} onHide={props.handleModalToggle} className="modal-wide" keyboard>
            <Modal.Header closeButton>
                <Modal.Title>Workflow History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BootstrapTable data={props.workflowHistory} pagination>
                    <TableHeaderColumn dataField="operation" dataAlign="left" dataSort isKey>Action</TableHeaderColumn>
                    <TableHeaderColumn dataField="user" dataAlign="left" dataSort>Updated User</TableHeaderColumn>
                    <TableHeaderColumn dataField="updatedDatetime" dataFormat={formatDateTime} dataAlign="left" dataSort>Updated On</TableHeaderColumn>
                    <TableHeaderColumn dataField="status" dataAlign="left" dataSort>Status</TableHeaderColumn>
                </BootstrapTable>
            </Modal.Body>
        </Modal>
    )
}

export default WorkflowHistoryModal;
import React from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import MemberAppointment from './MemberAppointment';
import MemberSelectionCombinedTable from './MemberSelectionCombinedTable';
import WorkflowHeaderDetailsInline from './WorkflowHeaderDetailsInline';

class MemberSelectionCombinedContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includedMembers: [],
            excludedMembers: [],
            showConfirmAddDeleteModal: false,
            showConfirmRemoveDeleteModal: false,
            loaded: false
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        this.refreshMemberAppointment = null;
    }

    componentWillMount() {
        this.getMembersIncludedInPackage();
        if (this.props.packageType === 'PrePrint') {
            this.getMembersExcludedFromPackage();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.packageType === 'PrePrint') {
            this.getMembersExcludedFromPackage();
        }
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    setRefreshMemberAppointment = (f) => {
        this.refreshMemberAppointment = f;
    }

    memberRemovedFromPackage = (memberID) => {
        // If no member ID is defined then reload all of the selected members
        if(!memberID) {
            this.getMembersIncludedInPackage();
            return;
        }

        this.setState({
            includedMembers: [...this.state.includedMembers].filter(member => member.memberMasterID !== memberID)
        });
    }

    getMembersIncludedInPackage = () => {
        axios.get('/packages/' + this.props.packageID + '/Members', {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({
                includedMembers: response.data.items
            });
            if (this.props.packageType !== 'PrePrint') {
                this.setState({
                    loaded: true
                });
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    //add member to package
    includeMemberInPackage = (memberID) => {
        axios.post('/packages/' + this.props.packageID + '/Members', {
            memberMasterID : memberID,
        })
        .then((response) => {
            this.setState({
                includedMembers: [...this.state.includedMembers].concat(response.data)
            });
            this.refreshMemberAppointment(this.props.practice.groupingID);
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    //remove all members from package
    deleteMembersIncludedInPackage = () => {
            axios.delete('/packages/' + this.props.packageID + '/Members')
            .then((response) =>
                this.setState({
                    includedMembers: []
                })
            )
            .catch((error) => {
                this.props.toast('error', 'Could not remove members.', 'Error');
            });
    }

    //remove member from package
    deleteMemberIncludedInPackage = (memberID) => {
        axios.delete('/packages/' + this.props.packageID + '/Members/' + memberID, {
            packageId: this.props.packageID,
            memberId: memberID
        })
        .then((response) => {
            this.setState({
                includedMembers: [...this.state.includedMembers].filter(member => member.memberMasterID !== memberID),
                showConfirmAddDeleteModal: false
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    getMembersExcludedFromPackage = () => {
        axios.get('/packages/' + this.props.packageID + '/MemberExclusions')
        .then((response) => {
            this.setState({
                excludedMembers: response.data,
                loaded: true
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load member data.', 'Error');
        });
    }

    excludeMemberFromPackage = (memberID) => {
        axios.post('/packages/' + this.props.packageID + '/MemberExclusions', {
            memberMasterID: memberID
        })
        .then((response) => {
            this.setState({
                excludedMembers: [...this.state.excludedMembers].concat(response.data)
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    //remove all members from package
    deleteMembersExcludedFromPackage = () => {
        axios.delete('/packages/' + this.props.packageID + '/MemberExclusions')
        .then((response) =>
            this.setState({
                excludedMembers: []
            })
        )
        .catch((error) => {
            this.props.toast('error', 'Could not remove members.', 'Error');
        });
    }

    //remove member from package
    deleteMemberExcludedFromPackage = (memberID) => {
        axios.delete('/packages/' + this.props.packageID + '/MemberExclusions/' + memberID, {
            packageId: this.props.packageID,
            memberId: memberID
        })
        .then((response) => {
            this.setState({
                includedMembers: [...this.state.includedMembers].filter(member => member.memberMasterID !== memberID),
                excludedMembers: [...this.state.excludedMembers].filter(member => member.memberMasterID !== memberID),
                showConfirmAddDeleteModal: false,
                showConfirmRemoveDeleteModal: false
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not remove member.', 'Error');
        });
    }

    handleConfirmDeleteAdd = () => {
        this.setState((prevState) => ({ showConfirmAddDeleteModal: !prevState.showConfirmAddDeleteModal }));
    }

    handleConfirmDeleteRemove = () => {
        this.setState({
            showConfirmRemoveDeleteModal: !this.state.showConfirmRemoveDeleteModal
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        {
                            this.state.loaded &&
                            <WorkflowHeaderDetailsInline title="Member Selection" provider={this.props.provider.desc + ' (' + this.props.provider.id + ')'}
                                practice={this.props.practice.desc} packageType={this.props.packageType} />
                        }
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Loader loaded={this.state.loaded}>
                        <Col sm={12}>
                            <MemberSelectionCombinedTable packageType={this.props.packageType} selectedMembers={[...this.state.includedMembers].concat([...this.state.excludedMembers])}
                                addMember={this.includeMemberInPackage} removeMember={this.excludeMemberFromPackage} providers={this.props.providers} practices={this.props.practices}
                                provider={this.props.provider} practice={this.props.practice} packageID={this.props.packageID} toast={this.props.toast} />
                        </Col>
                    </Loader>
                </Row>
                <Row>
                    <Col sm={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <MemberAppointment packageID={this.props.packageID} provider={this.props.provider} setRefreshMemberAppointment={this.setRefreshMemberAppointment}
                            setMemberAppointmentSort={this.props.setMemberAppointmentSort} defaultMemberAppointmentSort={this.props.defaultMemberAppointmentSort}
                            practice={this.props.practice} providers={this.props.providers} practices={this.props.practices} toast={this.props.toast}
                            memberRemovedEvent={this.memberRemovedFromPackage} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default MemberSelectionCombinedContainer;
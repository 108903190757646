import React from 'react';
import { Collapse, Glyphicon, ListGroup, ListGroupItem } from 'react-bootstrap';
import '../../../styles/SidePanel.css';

class EncounterGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.sections = this.props.encounterName ? [
            { label: 'Notes', elementID: 'notes'}, 
            { label: 'Problem List', elementID: 'problemlist'},
            { label: 'Vital Signs', elementID: 'vitalsigns'},
            { label: 'Medications', elementID: 'medications'},
            //{ label: 'Family History', elementID: 'familyhistory' },
            //{ label: 'Allergies', elementID: 'allergies' },
            { label: 'Social History', elementID: 'socialhistory'},
            { label: 'Procedures', elementID: 'procedures'},
            { label: 'Past Surgical Procedures', elementID: 'pastsurgicalprocedures'},
            { label: 'Lab Results', elementID: 'labresults'},
            //{ label: 'Immunizations', elementID: 'immunizations' },
            { label: 'Diagnosis', elementID: 'diagnosis'}
        ] : [];
    }

    render() {
        //#region inline css
        const headerStyle = {
            paddingLeft: this.props.encounterName === 'Additional Info' ? '0rem' : '1rem'
        }
        //#endregion
        
        return (
            <React.Fragment>
                <div className='panelDiv' style={headerStyle}>
                    <span className='panelLink'>{this.props.encounterName}</span>
                    <span onClick={this.props.onToggle} >
                        <Glyphicon glyph={this.props.active ? 'chevron-down' : 'chevron-right'} />
                    </span>
                </div>
                <Collapse in={this.props.active}>
                    <ListGroup style={{ boxShadow: 'none', marginBottom: '0rem' }}>
                        {
                            this.sections.map((x, index) => {
                                return (
                                    <ListGroupItem style={{ border: '0px', paddingTop: '0.5rem' ,paddingBottom: '0.5rem' }} key={index}>
                                        <div className='listItemDiv'>
                                            <span className='panelLink' onClick={()=>this.props.handleSamePageNav(x.elementID)}>{x.label}</span>
                                        </div>
                                    </ListGroupItem>
                                )
                            })
                        }
                    </ListGroup>
                </Collapse>
            </React.Fragment>
        )
    }
}

export default EncounterGroup;
import React from 'react';
import { Row, Col, ControlLabel, Table, Button, Pagination, DropdownButton, MenuItem } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-16-bootstrap-date-picker';
import axios from 'axios';
import '../../styles/Report.css';
import AlertToastr from '../Shared/AlertToastr';
import NoDataDisplay from './NoDataDisplay';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import { getTimeAsESTISODateString, getTimeAsESTISODateTimeString } from '../../utils/DateUtils';

class StarsProductivityReport extends React.Component {
    constructor(props) {
        super(props);
        const today = new Date();
        this.state = {
            isLoading: true,
            selectedProjects: [],
            minDate: new Date(new Date().setFullYear(today.getFullYear() - 1)),
            maxDate: today,
            fromDate: today,
            toDate: today,
            data: [],
            projectList: [],
            selectedNumPerPage: 50,
            selectedPage: 1,
            numOfPages: 1,
            selectedSortColumn: null,
            selectedSortDirection: null,
            recordsLoaded: false
        };
    }

    componentWillMount() {
        axios.get('/applicationreports/8/projects')
        .then((response) => {
            this.setState({
                projectList: response.data.map(x => {
                    return {
                        label: x.projectName,
                        value: x.projectID
                    };
                }),
                isLoading: false
            });
        })
        .catch((e) => {
            this.setState({ isLoading: false });
        });
    }

    componentDidMount() {
        try {
            //todo: do not modify the dom like this - refactor asap
            document.getElementById('toDate_group').children[0].setAttribute('readonly', 'readonly');
            document.getElementById('fromDate_group').children[0].setAttribute('readonly', 'readonly');
        }
        catch (e) {
            console.log(e);
        }
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    updateSelectedProjects = (val) => {
        this.setState({ selectedProjects: val.map((x) => { return x.value; }) });
    }

    handleDateChange = (val, type) => {
        let newDate = new Date(val);
        if (newDate > this.state.maxDate) {
            newDate = this.state.maxDate;
        }
        else if (newDate < this.state.minDate) {
            newDate = this.state.minDate;
        }

        switch (type) {
            case 1:
                if (newDate > this.state.toDate) {
                    this.setState({ toDate: newDate });
                }
                this.setState({ fromDate: newDate });
                break;
            case 2:
                if (newDate < this.state.fromDate) {
                    this.setState({ fromDate: newDate });
                }
                this.setState({ toDate: newDate });
                break;
            default:
                break;
        }
    }

    generateReport = () => {
        const newDateFrom = new Date(getTimeAsESTISODateTimeString(this.state.fromDate));
        const newDateThru = new Date(getTimeAsESTISODateTimeString(this.state.toDate));
        const filter = {
            ProjectIds: this.state.selectedProjects,
            DateFrom: newDateFrom,
            DateThru: newDateThru,
            Page: parseInt(this.state.selectedPage, 10),
            PerPage: parseInt(this.state.selectedNumPerPage, 10),
            SortColumn: this.state.selectedSortColumn,
            SortDirection: this.state.selectedSortDirection
        };
        this.setState({ isLoading: true });

        this.getReportData(filter).then((response) => {
            var result = this.groupBy(response.data.results, function (x) {
                return [x.projectName, x.totalProcessTime, x.userName, x.medicalRecordReviewID, x.memberID, x.lastName, x.firstName, x.reviewType];
            });

            this.setState({
                data: result,
                selectedNumPerPage: response.data.recordsPerPage,
                selectedPage: response.data.page,
                numOfPages: Math.ceil(response.data.count / response.data.recordsPerPage),
                selectedSortColumn: response.data.sortColumn,
                selectedSortDirection: response.data.sortDirection,
                isLoading: false,
                recordsLoaded: true
            });
        })
        .catch((e) => {
            this.setState({ isLoading: false });
        });
    }

    groupBy = (array, f) => {
        var groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    handleRowsPerPageChange = (num) => {
        if (parseInt(num, 10) !== parseInt(this.state.selectedNumPerPage, 10)) {
            this.setState({
                selectedNumPerPage: num,
                selectedPage: 1
            }, () => {
                this.generateReport();
            });
        }
    }

    handlePageChange = (page) => {
        if (parseInt(page, 10) !== parseInt(this.state.selectedPage, 10)) {
            this.setState({
                selectedPage: page
            }, () => {
                this.generateReport()
            });
        }
    }

    handleSortChange = (name) => {
        const sortDirection = name === this.state.selectedSortColumn && this.state.selectedSortDirection === 'asc' ?
            'desc' : 'asc';

        this.setState({
            selectedSortColumn: name,
            selectedSortDirection: sortDirection
        }, () => {
            this.generateReport();
        });
    }

    getReportData = (params) => {
        return axios.post('/Reports/Stars/StarsProductivityReport', params);
    }

    buildFunctionForDownload = () => {
        const queryParams = {
            ProjectIds: this.state.selectedProjects,
            DateFrom: this.state.fromDate,
            DateThru: this.state.toDate,
            SortColumn: this.state.selectedSortColumn,
            SortDirection: this.state.selectedSortDirection
        };
        return () => axios.post('/Reports/Stars/StarsProductivityReport/downloadrequest', queryParams);
    }

    getClass = (column) => {
        if (this.state.selectedSortColumn === column) {
            if (this.state.selectedSortDirection === 'desc') {
                return 'desc';
            }
            else {
                return 'asc';
            }
        }
        else {
            return '';
        }
    }

    isGenerateDisabled = () => {
        return !(this.state.selectedProjects && this.state.selectedProjects.length > 0 &&
            this.state.fromDate && this.state.toDate);
    }

    copyAsync = () => {
        return new Promise((resolve, reject) => {
            const element = document.getElementById('copyContent');
            const selection = window.getSelection();
            const range = document.createRange();
            try {
                range.selectNodeContents(element);
                selection.removeAllRanges();
                selection.addRange(range);
                document.execCommand('copy');
                selection.removeAllRanges();
                resolve();
            }
            catch (e) {
                reject(e);
            }
        });
    }

    copyToClipboard = () => {
        this.copyAsync().then(() => {
            this.state.toast('success', 'Copied to clipboard.', '');
        })
        .catch((e) => {
            this.state.toast('error', 'Failed to copy.', 'Error');
        });
    }

    render() {
        return (
            <div className="reportContainer starsProductivityReport">
                <div className="innerReportContainer">
                    <header>
                        <Row style={{ textAlign: 'center' }}>
                            <Col>
                                <h3>Productivity Report</h3>
                            </Col>
                        </Row>
                    </header>

                    <Row>
                        <Col md={4} mdOffset={1}>
                            <ControlLabel> Project: </ControlLabel>
                            <Select
                                name="project"
                                value={this.state.selectedProjects}
                                options={this.state.projectList}
                                multi={true}
                                onChange={this.updateSelectedProjects}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlLabel>Review Date: </ControlLabel>
                            <DatePicker
                                id="toDate"
                                value={getTimeAsESTISODateString(this.state.fromDate)}
                                minDate={getTimeAsESTISODateString(this.state.minDate)}
                                maxDate={getTimeAsESTISODateString(this.state.maxDate)}
                                onChange={(value, formattedValue) => { this.handleDateChange(value, 1); }}
                                showClearButton={false} />
                        </Col>
                        <Col md={3}>
                            <ControlLabel>Thru: </ControlLabel>
                            <DatePicker
                                id="fromDate"
                                value={getTimeAsESTISODateString(this.state.toDate)}
                                minDate={getTimeAsESTISODateString(this.state.fromDate)}
                                maxDate={getTimeAsESTISODateString(this.state.maxDate)}
                                onChange={(value, formattedValue) => { this.handleDateChange(value, 2); }}
                                showClearButton={false} />
                        </Col>
                    </Row>
                    {
                        this.state.isLoading &&
                        <div className="loader">
                            <i className="fa fa-3x fa-spinner fa-pulse" aria-hidden="true"></i>
                        </div>
                    }
                    <Row>
                        <Col md={10} sm={10}>
                            <Button bsStyle="primary" onClick={this.generateReport} disabled={this.isGenerateDisabled()}>Generate</Button>
                        </Col>
                        {
                            this.state.data && this.state.data.length > 0 &&
                            <Col md={2} sm={2} style={{ textAlign: 'right' }}>
                                <DropdownButton bsStyle="default" title={this.state.selectedNumPerPage} onSelect={this.handleRowsPerPageChange} id="rows-per-page">
                                    <MenuItem eventKey="5">5</MenuItem>
                                    <MenuItem eventKey="10">10</MenuItem>
                                    <MenuItem eventKey="50">50</MenuItem>
                                    <MenuItem eventKey="100">100</MenuItem>
                                    <MenuItem eventKey="250">250</MenuItem>
                                </DropdownButton>
                            </Col>
                        }
                    </Row>
                    {
                        this.state.recordsLoaded === true && this.state.data.length === 0 &&
                        <NoDataDisplay />
                    }
                    {
                        this.state.recordsLoaded === true && this.state.data && this.state.data.length > 0 &&
                        <Row>
                            <Col md={12}>
                                <Table bordered className="claimsAuditTable" id="copyContent">
                                    <thead>
                                        <tr>
                                            <th onClick={() => this.handleSortChange('ProjectName')} className={this.getClass('ProjectName')} style={{cursor:' pointer'}}>
                                                Project
                                            </th>
                                            <th onClick={() => this.handleSortChange('TotalProcessTime')} className={this.getClass('TotalProcessTime')} style={{cursor:' pointer'}}>
                                                Total Process Time (s)
                                            </th>
                                            <th>
                                                Process Time (s)
                                            </th>
                                            <th>
                                                Review Opened
                                            </th>
                                            <th>
                                                Review Closed
                                            </th>
                                            <th onClick={() => this.handleSortChange('UserName')} className={this.getClass('UserName')} style={{cursor:' pointer'}}>
                                                User Name
                                            </th>
                                            <th onClick={() => this.handleSortChange('MedicalRecordReviewID')} className={this.getClass('MedicalRecordReviewID')} style={{cursor:' pointer'}}>
                                                MRR ID
                                            </th>
                                            <th onClick={() => this.handleSortChange('MemberID')} className={this.getClass('MemberID')} style={{cursor:' pointer'}}>
                                                Member ID
                                            </th>
                                            <th onClick={() => this.handleSortChange('LastName')} className={this.getClass('LastName')} style={{cursor:' pointer'}}>
                                                Last Name
                                            </th>
                                            <th onClick={() => this.handleSortChange('FirstName')} className={this.getClass('FirstName')} style={{cursor:' pointer'}}>
                                                First Name
                                            </th>
                                            <th onClick={() => this.handleSortChange('ReviewType')} className={this.getClass('ReviewType')} style={{cursor:' pointer'}}>
                                                Review Type
                                            </th>
                                        </tr>
                                    </thead>
                                    {
                                        this.state.data.map((group, i) => {
                                            return (
                                                <tbody className="multi-row" key={`${group}-${i}`}>
                                                    {
                                                        group.map((item, idx) => {
                                                            var key = `${i}-${idx}`;
                                                            if (idx === 0) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.projectName}</td>
                                                                        <td className="vertical-center" rowSpan={group.length} style={{ textAlign: 'center' }}>{item.totalProcessTime}</td>
                                                                        <td style={{ textAlign: 'center' }}>{item.processTime}</td>
                                                                        <td>{item.reviewOpened}</td>
                                                                        <td>{item.reviewClosed}</td>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.userName}</td>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.medicalRecordReviewID}</td>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.memberID}</td>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.lastName}</td>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.firstName}</td>
                                                                        <td className="vertical-center" rowSpan={group.length}>{item.reviewType}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td style={{ textAlign: 'center', paddingLeft: 0 }}>{item.processTime}</td>
                                                                        <td>{item.reviewOpened}</td>
                                                                        <td>{item.reviewClosed}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            )
                                        })
                                    }
                                </Table>
                            </Col>
                            <Col md={3}>
                                <Button bsStyle="primary" onClick={this.copyToClipboard} className="paddedBtn">Copy</Button>
                            </Col>
                            <Col md={6} style={{ textAlign: 'center' }}>
                                <Pagination
                                    prev
                                    next
                                    first
                                    last
                                    ellipsis
                                    boundaryLinks
                                    items={this.state.numOfPages}
                                    maxButtons={8}
                                    activePage={this.state.selectedPage}
                                    onSelect={this.handlePageChange}
                                />
                            </Col>
                            <Col md={3}>
                                <LongRunningDownloadAnchor
                                    buttonStyle={{float: 'right', marginTop: '20px'}}
                                    className="paddedBtn"
                                    containerStyle={{display: 'inline'}}
                                    glyph="file"
                                    linkText="Download .CSV"
                                    postRequest={this.buildFunctionForDownload()}
                                    displayCSVDownloader={true}
                                />
                            </Col>
                        </Row>
                    }
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default StarsProductivityReport;
import React from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';

class MemberSelectionCombinedSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providerID: '',
            practiceGroupingID: '',
            practices: this.props.practices ? this.props.practices : [],
            providers: this.props.providers ? this.props.providers : [],
            firstName: '',
            lastName: '',
            memberID: '',
            searchEnabled: true,
            memberSearchResults: []
        };
    }

    componentWillMount() {
        if (this.props.setDefaults && this.props.provider.id) {
            //set default search provider & practice values
            this.setState({
                providerID: this.props.provider.id,
                practiceGroupingID: this.props.practice.groupingID
            });
            this.props.disableSearchDefaults();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.providers.length > 0) {
            //once the providers & practices (options) are available, remove invalid provider + practice default values
            if (this.state.providerID && nextProps.providers.filter(x => x.id === this.state.providerID).length === 0) {
                this.setState({
                    providerID: '',
                });
            }
            else if (this.props.setDefaults && nextProps.provider.id && nextProps.providers.filter(x => x.id === nextProps.provider.id).length !== 0) {
                this.setState({
                    providerID: nextProps.provider.id
                });
                this.props.disableSearchDefaults();
            }
            if (this.state.practiceGroupingID && nextProps.practices.filter(x => x.groupingID === this.state.practiceGroupingID).length === 0) {
                this.setState({
                    practiceGroupingID: '',
                });
            }
            else if (this.props.setDefaults && nextProps.practice.groupingID && nextProps.practices.filter(x => x.groupingID === nextProps.practice.groupingID).length !== 0) {
                this.setState({
                    practiceGroupingID: nextProps.practice.groupingID
                });
                this.props.disableSearchDefaults();
            }
        }
        if (nextProps.searchEnabled !== this.state.searchEnabled) {
            this.setState({ searchEnabled: nextProps.searchEnabled });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.searchEnabled) {
            if (prevState.searchEnabled === this.state.searchEnabled) {
                this.setSearchEnabled();
            }
        }
    }

    setSearchEnabled = () => {
        if (this.state.practiceGroupingID || this.state.memberID || this.state.lastName.length > 1) {
            if (!this.state.searchEnabled) {
                this.setState({ searchEnabled: true });
            }
        }
        else {
            if (this.state.searchEnabled) {
                this.setState({ searchEnabled: false });
            }
        }
    }

    handleProviderChange = (e) => {
        this.setState({
            providerID: e.target.value,
            practiceGroupingID: ''
        });
    }

    handlePracticeChange = (e) => {
        this.setState({ practiceGroupingID: e.target.value });
    }

    handleFirstNameChange = (e) => {
        this.setState({ firstName: e.target.value });
    }

    handleLastNameChange = (e) => {
        this.setState({ lastName: e.target.value });
    }

    handleMemberIDChange = (e) => {
        this.setState({ memberID: e.target.value });
    }

    handleSearch = () => {
        const searchParams = {
            providerID: this.state.providerID,
            practiceGroupingID: this.state.practiceGroupingID,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            memberID: this.state.memberID
        };

        this.props.searchMembers(searchParams);
    }

    render() {
        const practices = this.state.providerID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.providerID ? practice : false;
            })
            : this.props.practices ? this.props.practices : [];
        const providers = this.props.providers ? this.props.providers : [];

        return (
            <Grid fluid style={{paddingLeft: '16px'}}>
                {/*
                <Row style={{'marginBottom': '2rem'}}>
                    <h4>Members</h4>
                </Row>
                */}
                <Row style={{marginBottom: '2rem'}}>
                    <Form inline>
                        <FormGroup>
                            <ControlLabel style={{marginRight: '1rem'}}>Provider Org</ControlLabel>
                            <FormControl componentClass="select" value={this.state.providerID} onChange={this.handleProviderChange}>
                                <option value="">Select</option>
                                {
                                    providers.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                        <FormGroup style={{marginLeft: '4rem'}}>
                            <ControlLabel style={{marginRight: '1rem'}}>Practice</ControlLabel>
                            <FormControl componentClass="select" value={this.state.practiceGroupingID} onChange={this.handlePracticeChange} style={{marginRight: '0.2rem'}}>
                                <option value="">Select</option>
                                {
                                    practices.map((item) => {
                                        return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Form>
                </Row>
                <Row style={{'marginBottom': '2rem'}}>
                    <Form inline>
                        <FormGroup>
                            <ControlLabel style={{marginRight: '1rem'}}>First Name</ControlLabel>
                            <FormControl type="text" placeholder="First Name" value={this.state.firstName} onChange={this.handleFirstNameChange} />
                        </FormGroup>
                        <FormGroup style={{marginLeft: '2rem'}}>
                            <ControlLabel style={{marginRight: '1rem'}}>Last Name</ControlLabel>
                            <FormControl type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.handleLastNameChange} />
                        </FormGroup>
                        <FormGroup style={{marginLeft: '2rem'}}>
                            <ControlLabel style={{marginRight: '1rem'}}>Member ID</ControlLabel>
                            <FormControl type="text" placeholder="Member ID" value={this.state.memberID} onChange={this.handleMemberIDChange} />
                        </FormGroup>
                        <FormGroup style={{marginLeft: '2rem'}}>
                            <Button onClick={this.handleSearch} disabled={!this.state.searchEnabled}>
                                <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Search Members
                            </Button>
                        </FormGroup>
                    </Form>
                </Row>
            </Grid>
        );
    }
}

export default MemberSelectionCombinedSearch;
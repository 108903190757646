import React from 'react';
import { Button, Glyphicon, Modal } from 'react-bootstrap';

class CommunicationTypeChangeConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            practiceData: [],
            selectedRow: [],
            communicationTypeOptions: []
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            practiceData: nextProps.practiceData,
            selectedRow: nextProps.row,
            communicationTypeOptions: nextProps.communicationTypeOptions
        });
    }

    render() {
        const practiceName = this.state.selectedRow ? this.state.selectedRow.practiceName : '';
        const oldCommunicationType = this.props.originalOutboundTypeID ? this.state.communicationTypeOptions.filter(item => { return item.value === this.props.originalOutboundTypeID }) : [];
        const newCommunicationType = this.state.selectedRow ? this.state.communicationTypeOptions.filter(item => { return item.value === this.state.selectedRow.outboundTypeID }) : [];

        return (
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are about to update the communication method for <b>{practiceName}</b> from <b>{oldCommunicationType[0] ? oldCommunicationType[0].text : ''}</b> to <b>{newCommunicationType[0] ? newCommunicationType[0].text : ''}</b>. Please confirm.
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle={this.props.confirmStyle} key="save" onClick={this.props.handleConfirm} style={{float: "left"}}>{this.props.confirmLabel}
                        <Glyphicon glyph={this.props.glyphicon ? this.props.glyphicon : ""} style={{marginLeft: "0.66rem", top: "1.6px", lineHeight: "14px", float: "right"}} />
                    </Button>
                    <Button onClick={this.props.handleModalToggle} style={{float: 'right'}}>{this.props.cancelLabel}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default CommunicationTypeChangeConfirmation;
// exports ProjectInventoryType - an immutable object 'enum'
// usage: import { ProjectInventoryType } from '../../enums/ProjectInventoryType';
const projectInventoryType = {
    EMR: 1001,
    Vendor:  1002, //Vendor (Chase)
    ScannedImage: 1003, // Scanned Image
    UserCreated: 1004, //User Created
    RMVendor: 1005
};
Object.freeze(projectInventoryType);
export const ProjectInventoryType = projectInventoryType;
import React from 'react';
import axios from 'axios';
import { Button, DropdownButton, Glyphicon, Table, MenuItem, Modal, Pagination } from 'react-bootstrap';
import Loader from 'react-loader';
import '../../styles/Report.css';
 
class ProjectStatusReportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showError: false,
            modalResults: [],
            activePage: 1,
            orderByDescending: false,
            orderByColumnName: null,
            loaded: true,
            pageSize: 10
        }
    }

    // set showModal state to true
    open = () => {
        this.setState({ showModal: true, loaded: false });
        this.getProjectStatusModalRecords();
    }

    // set showModal state to false if 'cancel' or 'hide' selected
    close = () => {
        this.setState({ showModal: false, activePage: 1, pageSize: 10 });
    }

    getProjectStatusModalRecords = (orderByColumnName, pageNumber, pageSize) => {
        let orderByDescending = false;
        if (orderByColumnName !== undefined) {
            if (orderByColumnName !== this.state.orderByColumnName) {
                this.setState({ orderByColumnName: orderByColumnName, orderByDescending: false });
            }
            else {
                this.setState({ orderByDescending: !this.state.orderByDescending });
                orderByDescending = !this.state.orderByDescending;
            }
        }

        // Builds the query string
        const queryParams = [];
        queryParams.push('/Reports/MedicalRecordReviews/ProjectReviewStatusReport/' + this.props.projectID + '/Records?medicalRecordReviewStatusID=' + this.props.mrrStatusID);
        if (orderByColumnName) {
            queryParams.push('orderByColumnName=' + orderByColumnName);
            queryParams.push('orderByDescending=' + orderByDescending);
        }
        else {
            queryParams.push('orderByDescending=' + this.state.orderByDescending);
        }
        if (!isNaN(pageNumber)) {
            queryParams.push('pageNumber=' + pageNumber);
        }
        if (!isNaN(pageSize)) {
            queryParams.push('pageSize=' + pageSize);
        }else{
            queryParams.push('pageSize=' + this.state.pageSize);
        }

        axios.get(queryParams.join('&'))
        .then((response) => {
            this.setState({ modalResults: response.data.items, totalItems: response.data.totalItems, loaded: true });
        })
        .catch((error) => {
            this.setState({ loaded: true })
        });
    }

    handlePageSelection = (pageNumber) => {
        this.setState({activePage: pageNumber});
        this.getProjectStatusModalRecords(this.state.orderByColumnName, pageNumber, this.state.pageSize);
    }

    handlePageSizeSelection = (pageSize) => {
        this.setState({ pageSize: pageSize, activePage: 1 });
        this.getProjectStatusModalRecords(this.state.orderByColumnName, 1, pageSize);
    }

    getSortIcon = (columnName) => {
        if (columnName !== this.state.orderByColumnName) {
            return '';
        }
        return this.state.orderByDescending ? 'triangle-bottom' : 'triangle-top';
    }

    render() {
        return (
        <div style={{display: 'inline-block'}}>
            <button className="buttonLink" onClick={this.open}>{this.props.displayValue}</button>
            <Loader loaded={this.state.loaded}>
                <Modal className="projectStatusReportModal" show={this.state.showModal} onHide={this.close}>
                    <div hidden={this.state.showError}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DropdownButton id={`dropdown-size-medium`} title={this.state.pageSize} onSelect={this.handlePageSizeSelection} style={{marginTop: '-25px'}}>
                                <MenuItem eventKey="5">5</MenuItem>
                                <MenuItem eventKey="10">10</MenuItem>
                                <MenuItem eventKey="20">20</MenuItem>
                                <MenuItem eventKey="50">50</MenuItem>
                                <MenuItem eventKey="100">100</MenuItem>
                                <MenuItem eventKey="250">250</MenuItem>
                            </DropdownButton>
                            <Pagination bsSize="medium" items={Math.ceil(this.state.totalItems / this.state.pageSize)} 
                                activePage={this.state.activePage} style={{marginLeft: '10px', marginTop: '0px', marginBottom: '0px'}} 
                                onSelect={this.handlePageSelection} prev next first last ellipsis boundaryLinks maxButtons={5} />
                            <Table bordered className="projectStatusReportModalTable">  
                                <thead>
                                    <tr>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'MedicalRecordReviewID')}>MRR ID <Glyphicon glyph={this.getSortIcon('MedicalRecordReviewID')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'memberID')}>Member ID <Glyphicon glyph={this.getSortIcon('memberID')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'beneficiaryID')}>Beneficiary ID <Glyphicon glyph={this.getSortIcon('beneficiaryID')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'memberFullName')}>Name <Glyphicon glyph={this.getSortIcon('memberFullName')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'memberDateOfBirth')}>Date of Birth <Glyphicon glyph={this.getSortIcon('memberDateOfBirth')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'npi')}>NPI <Glyphicon glyph={this.getSortIcon('npi')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'providerFullName')}>Provider Name <Glyphicon glyph={this.getSortIcon('providerFullName')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'medicalRecordReviewTypeDesc')}>Record Type <Glyphicon glyph={this.getSortIcon('medicalRecordReviewTypeDesc')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'reviewTypeDesc')}>Review Type <Glyphicon glyph={this.getSortIcon('reviewTypeDesc')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'isSignatureImpaired')}>Signature Impaired <Glyphicon glyph={this.getSortIcon('isSignatureImpaired')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'diagnosisCount')}># of Diags <Glyphicon glyph={this.getSortIcon('diagnosisCount')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'lastUpdatedDate')}>Last Updated <Glyphicon glyph={this.getSortIcon('lastUpdatedDate')} /></th>
                                        <th onClick={this.getProjectStatusModalRecords.bind(this, 'lastUpdatedBy')}>Last Updated By <Glyphicon glyph={this.getSortIcon('lastUpdatedBy')} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.modalResults.map((row, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{row.medicalRecordReviewID}</td>
                                                <td>{row.memberID}</td>
                                                <td>{row.beneficiaryID}</td>
                                                <td>{row.memberFullName}</td>
                                                <td>{row.memberDateOfBirth}</td>
                                                <td>{row.npi}</td>
                                                <td>{row.providerFullName}</td>
                                                <td>{row.medicalRecordReviewTypeDesc}</td>
                                                <td>{row.reviewTypeDesc}</td>
                                                <td>{row.isSignatureImpaired}</td>
                                                <td>{row.diagnosisCount}</td>
                                                <td>{row.lastUpdatedDate}</td>
                                                <td>{row.lastUpdatedBy}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Close</Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </Loader>
        </div>
        );
    }
}

export default ProjectStatusReportModal;
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Row, Grid } from 'react-bootstrap';

const CampaignDetailTable = (props) => {
    return(
        <Grid fluid>
            <Row style={{ marginBottom: '2rem' }}>
                <BootstrapTable data={Array(1).fill(props.summaryDetail)} maxHeight="516px" margin="100px">
                    <TableHeaderColumn dataField="campaignID" isKey hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="projectCount" editable={false} dataAlign="left">Total Projects</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordReviewCount" editable={false} dataAlign="left">Total Reviews</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordReviewRemainingCount" editable={false} dataAlign="left">Remaining</TableHeaderColumn>
                    <TableHeaderColumn dataField="campaignStatus" editable={false} dataAlign="left">Campaign Status</TableHeaderColumn>
                </BootstrapTable>
            </Row>
        </Grid>
        );
    }

export default CampaignDetailTable;
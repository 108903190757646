// exports SortUtils
// usage: import { sortPageNumbers } from '../../utils/SortUtils';

// sorts an array of objects based on an object key provided by a parameter
// usage: collection.sort(sortBy("key-name"))
const sortBy = (key) => {
  return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
};

// sorts an array of objects based on an object key provided by a parameter + toLowerCase()
// usage: collection.sort(sortByLowercase("key-name"))
const sortByLowercase = (key) => {
    return (a, b) => (a[key].toLowerCase() > b[key].toLowerCase()) ? 1 : ((b[key].toLowerCase() > a[key].toLowerCase()) ? -1 : 0);
};

// gets a, b, and sort order to sort by pageNumFrom and then pageNumTo
function sortPageNumbers(a, b, order) {
    if (order === 'desc') {
        if (a.pageNumFrom === b.pageNumFrom) {
            return a.pageNumTo - b.pageNumTo;
        }
        return a.pageNumFrom - b.pageNumFrom;
    }
    else {
        if(b.pageNumFrom === a.pageNumFrom) {
            return b.pageNumTo - a.pageNumTo;
        }
        return b.pageNumFrom - a.pageNumFrom;
    }
}

// sort rejection comments alphabetically
function sortComments(a, b) {
    return a.commentText.localeCompare(b.commentText);
}

function sortDate(a, b, order) {
    if (order === 'asc') {
        return (new Date(a) - new Date(b));
    }
    else {
        return (new Date(b) - new Date(a));
    }
}

// standard sort for use with react-bootstrap-table. should be supplied with pre-formatted/selected sort data via params 'a' and 'b'
// and returned by the caller.
function reactBootstrapTableCustomSort(a, b, order, field) {
    if (order === 'desc') {
        if (a > b) {
            return -1;
        }
        else if (a < b) {
            return 1;
        }
        return 0;
    }
    else {
        if (a < b) {
            return -1;
        }
        else if (a > b) {
            return 1;
        }
        return 0;
    }
}

export { reactBootstrapTableCustomSort, sortBy, sortByLowercase, sortPageNumbers, sortComments, sortDate };
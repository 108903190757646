import React from 'react';
import axios from 'axios';
import debounce from 'debounce-promise';
import Select from 'react-select';

class ProviderTypeaheadSearchInput extends React.Component {
    constructor(props) {
        super(props);
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    handleProviderIDChange = (e) => {
        if (e) {
            if (typeof(e) === 'object') {
                //provider dropdown option (selected)
                this.props.handleProviderChange(e);
            }
            else {
                if (isNaN(parseInt(e, 10))) {
                    //provider name (input text)
                    this.props.handleProviderChange({
                        internalKey: '',
                        lastName: e.indexOf(',') > -1 ? e.split(',')[0].trim() : e.trim(),
                        firstName: e.indexOf(',') > -1 ? e.split(',')[1].trim() : '',
                        providerNPI: ''
                    });
                }
                else {
                    //provider npi (input text)
                    this.props.handleProviderChange({
                        internalKey: '',
                        lastName: '',
                        firstName: '',
                        providerNPI: e
                    });
                }
            }
        }
        else {
            //empty (input text)
            this.props.handleProviderChange(null);
        }
    }

    handleTypeaheadSearch = debounce((input, callback) => {
        if (!input || input.length < 2) {
            return Promise.resolve({ options: [] });
        }
        else {
            let promise = null;
            // if text, find providers by name
            if (isNaN(parseInt(input, 10))) {
                promise = axios.get('/memberService/v1/ProviderSearch', {
                    params: {
                        lastName: input.indexOf(',') > -1 ? (input.split(',')[0]).trim() : input.trim(),
                        firstName: input.indexOf(',') > -1 ? (input.split(',')[1]).trim() : null,
                        memberMasterID: this.props.memberID == null? null : this.props.memberID,
                        page: 1,
                        pageSize: 10
                    },
                    cancelToken: this.source.token
                });
            }
            // if number, find providers by providerNPI
            else {
                promise = axios.get('/memberService/v1/ProviderSearch', {
                    params: {
                        providerNPI: input,
                        memberMasterID: this.props.memberID == null? null : this.props.memberID,
                        page: 1,
                        pageSize: 10
                    },
                    cancelToken: this.source.token
                });
            }

            if (promise) {
                return promise.then((response) => {
                    callback(null, {
                        options: this.aggregateProviderIDsByProviderMaster(response.data.items).map(x => {
                            return this.buildProviderDropdownOption(x);
                        })
                    });
                })
                .catch((error) => {
                    this.props.toast('error', 'Could not search providers.', 'Error');
                });
            }
        }
    }, 1000)

    buildProviderDropdownOption = (searchResult) => {
        return {
            firstName: searchResult.firstName,
            lastName: searchResult.lastName,
            providerID: searchResult.providerID,
            providerNPI: searchResult.npi,
            planProviderID: searchResult.planProviderID,
            internalKey: searchResult.providerMasterID,
            label: this.props.getProviderDropdownLabel(searchResult.lastName, searchResult.firstName,
                searchResult.providerID, searchResult.npi, searchResult.alternateProviderIDs)
        };
    }

    aggregateProviderIDsByProviderMaster = (providers) => {
        return providers.reduce((acc, cur) => {
            const target = acc.find(x => x.providerMasterID === cur.providerMasterID);
            if (target) {
                if (target.providerID !== cur.providerID) {
                    if (target.alternateProviderIDs) {
                        if (!target.alternateProviderIDs.find(x => x === cur.providerID)) {
                            target.alternateProviderIDs.push(cur.providerID);
                        }
                    }
                    else {
                        target.alternateProviderIDs = [cur.providerID];
                    }
                }
            }
            else {
                acc.push(cur);
            }
            return acc;
        }, []);
    }

    render() {
        return (
            <Select.Async
                autoFocus={this.props.disabled}
                autoload={false}
                cache={false}
                clearable
                closeOnSelect
                ignoreCase={false}
                filterOptions={false}
                tabSelectsValue
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                onSelectResetsInput={false}
                isLoading={false}
                value={this.props.value}
                onChange={this.handleProviderIDChange}
                onInputChange={this.handleProviderIDChange}
                loadOptions={this.handleTypeaheadSearch}
                valueKey="internalKey"
                labelKey="label"
                onBlur={(e) => this.props.onBlur(e, 'providerMasterID')}
                searchPromptText="Search by Provider Name or NPI"
                disabled={this.props.disabled} />
        )
    }
}

export default ProviderTypeaheadSearchInput;
import React from 'react';
import { Button, Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';

const OOAActionBar = (props) => {
    return (
        <Navbar fluid fixedTop style={{ background: '#343a40', border: 'none', marginBottom: '0.2rem' }}>
            <Nav pullLeft bsClass="nav nav-no-padding">
                <NavItem>
                    <Button onClick={props.toggleImageUploadModal}>
                        <Glyphicon glyph="picture" style={{ fontWeight: '800', marginRight: '0.6rem' }} /> Images
                    </Button>
                </NavItem>
            </Nav>
            <Nav pullRight bsClass="nav nav-no-padding">
                {
                    !props.isViewOnly &&
                    <NavItem>
                        <Button onClick={props.saveAlertForLater}>
                            Save for Later
                            {/*<Glyphicon glyph="picture" style={{ fontWeight: '800', marginRight: '0.6rem' }} /> Images*/}
                        </Button>
                    </NavItem>
                }
                {
                    !props.isViewOnly &&
                    <NavItem>
                        <Button onClick={props.completeAlert}>
                            Complete
                            {/*<Glyphicon glyph="picture" style={{ fontWeight: '800', marginRight: '0.6rem' }} /> Images*/}
                        </Button>
                    </NavItem>
                }
                {
                    props.isViewOnly &&
                    <NavItem>
                        <Button onClick={props.redirectToLanding}>
                            Return to Landing
                            {/*<Glyphicon glyph="picture" style={{ fontWeight: '800', marginRight: '0.6rem' }} /> Images*/}
                        </Button>
                    </NavItem>
                }
            </Nav>
        </Navbar>
    );
};

export default OOAActionBar;
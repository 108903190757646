import React from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import { Col, Grid, Row } from 'react-bootstrap';
import MemberDemographicContainer from '../MemberDemographic/MemberDemographicContainer';
import NLPReview from './NLPReview';
import NLPActionBar from './NLPActionBar';
import AlertToastr from '../../../Shared/AlertToastr';
import { getRedirect, getNextQueuedAlertWorkflow } from '../../../../services/ReviewProcessing';
import CaptureContainerRedux from '../Capture/CaptureContainerRedux';
import { Program } from '../../../../enums/Program';
import { MedicalRecordType } from '../../../../enums/MedicalRecordType';

class NLPCodingContainer extends React.Component {
    constructor(props) {
        console.log('nlpContainer cstr');
        super(props);
        this.state = {
            reviewProcessingUIConfig: {},       //mrr-25463
            getNextAlertReviewDefault: false,   //mrr-25463
            toast: null,                        //mrr-25463
            reviewHasAddedRecords: false,
            selectedStarMeasure: null,
            pecCorrectionCommentSelected: false,
            error: '',
            loaded: false,
            //MRR-24571
            showHCCProfileTab: false,
            memberID: null,
            selectedStarMeasure: null,
            medicalRecordTypeID: 0,
            reviewCurrentUserID: 0,
            medicalRecordTypeData: null,
            sourceData: null,
            providerTypeData: null,
            rafTypeData: null,
            imageRecords: [],
            isNlp :true,
            diagnosisComments: []
        };

        //mrr-25463
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.alertReviewQueueKey = `cdiAlertReviewQueue-${this.lineOfBusiness}`;
    }


    componentWillMount() {
        
        //mrr-25463
        if (this.props.location.query.isCDIQueue) {
            this.setState({ getNextAlertReviewDefault: this.props.location.query.isCDIQueue ? this.props.location.query.isCDIQueue.toLowerCase() === 'true' : false });
        }
        const params = {
            medicalRecordReviewID: this.props.location.query.medicalRecordReviewID
        }
        if (this.props.route.reviewType === "InitialCoding") {
            params.isCDIQueue = this.props.location.query.isCDIQueue || false
        }
        else if (this.props.route.reviewType === "CoderQA") {
            params.coderQAQueueID = this.props.location.query.coderQAQueueID;
        }

        let origionalMrrId;
        if (this.props.location.query.skip) {
            params.mrrId = this.props.location.query.mrrId ? this.props.location.query.mrrId : 0;
            origionalMrrId = Number(params.mrrId);
        }

        axios.get(`/ReviewProcessingUIConfig/${this.props.route.reviewType}`, { params })
        .then((response) => {
            if (origionalMrrId && origionalMrrId === response.data.medicalRecordReviewID) {
                this.state.toast('success', 'There are no other reviews available at this time.', '');
            }
            this.setState({
                reviewProcessingUIConfig: response.data,
                //MRR-23588
                showHCCProfileTab: response.data.projectDefaultProgramID !== Program.Stars,  //MRR-24571
                memberID: response.data.memberInternalKey,
                medicalRecordTypeID: response.data.medicalRecordTypeID,
                reviewCurrentUserID: response.data.userID,
                pecCorrectionCommentSelected: response.data.comments && response.data.comments.find(x => x.id === '16' && x.isChecked === true),
                loaded: true
            }, () => {
                this.getProject();
                this.callPromises();
            }
            );
        })
        .catch((error) => {
            //todo: use error component instead of toast?
            if (error.response) {
                //display review error and redirect
                this.setState({
                    loaded: true,
                    error: error.response ? error.response.data.message : `Could not load ${this.props.route.reviewType} configuration.`
                }, () => {
                    if (this.state.toast) {
                        this.state.toast('error', this.state.error, 'Error');
                    }
                    setTimeout(() => {
                        getRedirect({
                            redirectAction: "Index",
                            redirectController: "Landing"
                        });
                    }, 5000);
                });
            }
            else {
                console.log(error);
            }
        });
    }

    //mrr-25463
    componentDidMound() {
        if (this.state.error) {
            this.state.toast('error', this.state.error, 'Error');
        }
    }

    //mrr-24950
    getProject = () => {
        axios.get(`/projects/${this.state.reviewProcessingUIConfig.projectID}`)
        .then((response) => {
            this.setState({
                project: response.data
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load project data.', 'Error');
        })
    }

    callPromises(){
        const promises = [this.getReferenceData(), this.populateDiagComments(), this.populateRejectionReasons(),
            this.populateImageIDOptions(), this.getCommentGroupOptions(), this.getMRRProviderNPIs(), this.getMemberDemographic()];

        Promise.all(promises)
        .then((response) => {})
        .catch((error) => {
            //this.setState({ loaded: true });
            //this.props.toast('error', 'Could not load capture data.', 'Error');
        })
    }

    //mrr-25463
    //handle review processing actions except "Complete and Get Next" for NLP associated projects
    updateReview = (mrrUpdateDTO, getNextCDIAlertWorkflowID) => {
            if (this.props.route.reviewType === "CoderQA") {
                mrrUpdateDTO.coderQAQueueID = this.props.location.query.coderQAQueueID;
            }
    
            let queuedCDIAlertWorkflow;
            if (getNextCDIAlertWorkflowID) {
                queuedCDIAlertWorkflow = getNextQueuedAlertWorkflow();
                if (queuedCDIAlertWorkflow) {
                    mrrUpdateDTO.nextCDIAlertWorkflowID = queuedCDIAlertWorkflow.cdiAlertWorkflowID;
                }
                else {
                    this.showEmptyAlertQueueNotification();
                    this.redirectToLanding();
                }
            }

            axios.put(`/MRReviewProcessing/`, {
                ...mrrUpdateDTO,
                hdnMrrId: this.state.reviewProcessingUIConfig.medicalRecordReviewID,
                hdnUserAssignmentId: this.state.reviewProcessingUIConfig.medicalRecordUserAssignmentID,
                hdnExpectedDatetime: this.state.reviewProcessingUIConfig.expectedDatetime
            })
            .then((response) => {
                this.state.toast('success', response.data.message ? response.data.message : 'Update was successful.', '');
                if (response.data.redirectController === "CoderQA" && response.data.redirectAction === "NextReview") {
                    this.getNextReviewCoderQA(response.data.params.coderQAQueueID);
                }
                else {
                    setTimeout(() => {
                        getRedirect(response.data);
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log("NLPCodingContainer.jsx - updateReview()-error"); console.log(error);
                if (error.response && error.response.data && error.response.data.redirectAction === "Skip" && error.response.data.redirectController === "Coding") {
                    //todo: choose better glyphicon (i.e. not check mark)
                    this.state.toast('success', 'Finding next queued alert...', '');
                    this.startNextReview(this.state.reviewProcessingUIConfig.projectID);
                }
                else if (error.response && error.response.data && error.response.data.message === "No Record is available for review for this Project and Record Type.") {
                    //handles pre-populated project "Complete and Get Next" when project is out of eligible reviews
                    this.state.toast('success', 'Review completed. Project has no additional reviews in Ready for MRR status.', '');
                    this.redirectToLanding();
                }
                else {
                    if (queuedCDIAlertWorkflow) {
                        this.returnCDIAlertWorkflowToQueue(queuedCDIAlertWorkflow);
                    }
    
                    this.state.toast('error', error.response && error.response.data && error.response.data.message ? error.response.data.message :
                        'Could not update review.', 'Error');
                }
            });
    }

    //MRR-24138 & MRR-26276
    updateAndGetNextNlpReview = (mrrUpdateDTO) => {
        //do Complete action first
        axios.put(`/MRReviewProcessing/`, {
            ...mrrUpdateDTO,
            hdnMrrId: this.state.reviewProcessingUIConfig.medicalRecordReviewID,
            hdnUserAssignmentId: this.state.reviewProcessingUIConfig.medicalRecordUserAssignmentID,
            hdnExpectedDatetime: this.state.reviewProcessingUIConfig.expectedDatetime,
            isNlp: this.state.isNlp
        })
        .then((response) => {
            this.state.toast('success', response.data.message ? response.data.message : 'retrieve was successful.', '');
            if (response.data.redirectController === "CoderQA" && response.data.redirectAction === "NextReview") {
                //Todo for coderQA this.getNextReviewCoderQA(response.data.params.coderQAQueueID);
            }
            else {
                //do Get Next action now
                console.log("medicalRecordTypeID"); console.log(this.state.reviewProcessingUIConfig.medicalRecordTypeID);
                setTimeout(() => {
                    this.getNextNlpReview(this.state.reviewProcessingUIConfig.projectID, this.state.reviewProcessingUIConfig.medicalRecordTypeID, this.state.reviewCurrentUserID);
                }, 1000);
            }
        })
        .catch((error) => {
            console.log("NLPCodingContainer.jsx - updateAndGetNextNlpReview()-error"); console.log(error);
            if (error.response && error.response.data && error.response.data.message === "No Record is available for review for this Project and Record Type.") {
                //handles pre-populated project "Complete and Get Next" when project is out of eligible reviews
                this.state.toast('success', 'Review completed. Project has no additional reviews in Ready for MRR status.', '');
                this.redirectToLanding();
            }
            else {
                this.state.toast('error', error.response && error.response.data && error.response.data.message ? error.response.data.message :
                    'Could not update review.', 'Error');
            }
        });
    }
    setReviewHasAddedRecords = () => {
        this.setState({ reviewHasAddedRecords: true });
    }

    // select star measure from star profile
    //MRR-24571
    selectStarMeasure = (starRecord) => {
        this.setState({
            selectedStarMeasure: starRecord ? starRecord : null
        });
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    //MRR-24138 & MRR-26276
    getNextNlpReview = (projectId, medicalRecordTypeID, userId) => {
        axios.get(`/mrrnlpapi/v1/NextMedicalRecordReview/?projectID=${projectId}&medicalRecordTypeId=${medicalRecordTypeID}&userid=${userId}`)
        .then((response) => {
            console.log("get next review respose.data"); console.log(response.data);
            getRedirect(response.data);
        })
        .catch((error) => {
            this.state.toast('error', error.response && error.response.data && error.response.data.message ? error.response.data.message :
                'Could not load next medical review.', 'Error');
        })
    }
    
    //MRR-24571
    toggleTabVisibility = () => {
        this.setState({ showHCCProfileTab: !this.state.showHCCProfileTab });
    }

    getReferenceData = () => {
        return axios.get(`/projects/${this.state.reviewProcessingUIConfig.projectID}/referenceData`,
        {
            params: {
                referenceDataType: 'providerType,source,medicalRecordType,rafType'
            }
        })
        .then((response) => {
            this.setState({ 
                medicalRecordTypeData: response.data.referenceDataList.filter(x => x.type === "medicalRecordType")[0],
                providerTypeData: response.data.referenceDataList.filter(x => x.type === "providerType")[0],
                sourceData: response.data.referenceDataList.filter(x => x.type === "source")[0],
                rafTypeData: response.data.referenceDataList.filter(x => x.type === "rafType")[0] 
            });

        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load reference data.', 'Error');
        });
    }

    populateDiagComments = () => {
        return axios.get(`/projectCommentDefinition`, {
            params: {
                projectId: this.state.reviewProcessingUIConfig.projectID,
                commentType: "ReviewDiagLevel"
            }
        })
        .then((response) => {
            this.setState({ diagnosisComments: response.data });
        })
    }

    populateRejectionReasons = () => {
        return axios.get(`/projectCommentDefinition`, {
            params: {
                projectId: this.state.reviewProcessingUIConfig.projectID,
                commentType: "RejectionReason"
            }
        })
        .then((response) => {
            this.setState({ rejectionReasons: response.data });
        })
    }

    populateImageIDOptions = () => {
        return axios.get(`/medicalrecordreview/${this.state.reviewProcessingUIConfig.medicalRecordReviewID}/images`)
        .then((response) => {
            const mapToOptionRow = function(record) {
                return {
                    id: record.medicalRecordImageID,
                    title: record.medicalRecordImageID
                }
            }
            // remove duplicates with same medicalRecordImageID and map to options
            const imageRecords = (response.data.reduce((x, y) => x.findIndex(e => 
                e.medicalRecordImageID === y.medicalRecordImageID) < 0 ? [...x, y]: x, [])).map(mapToOptionRow);

            const userUploadedImages = (response.data.filter(x => {
                return x.retrievalSourceID === 5 && x.retrievalMethodID === 6;
            })).map(mapToOptionRow);

            if (this.state.reviewProcessingUIConfig.medicalRecordTypeID === MedicalRecordType.Scan) {
                imageRecords.push({ id: -1, title: 'Not available in MRR' });
            }

            this.setState({ 
                imageRecords: imageRecords,
                userUploadedImages: userUploadedImages
            });
        })
    }

    getCommentGroupOptions = () => {
        return axios.get(`/commentGroup`)
        .then((response) => {
            this.setState({ commentGroupOptions : response.data });
        })
    }

    getMRRProviderNPIs = () => {
        axios.get(`/mrrprovidernpis/${this.state.reviewProcessingUIConfig.medicalRecordReviewID}`)
        .then((response) => {
            this.setState({ mrrProviderNPIs: response.data });
        })
    }

    getMemberDemographic = () => {
        axios.get(`/memberService/v1/member/${this.state.reviewProcessingUIConfig.memberInternalKey}/memberDemographic/`)
        .then((response) => {
            this.setState({
                memberDemo: response.data,
            });
        })
    }

    render() {
        return (
            <div>
                <Loader loaded={this.state.loaded}>
                    {
                        !this.state.error &&
                        <NLPActionBar reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} updateReview={this.updateReview} 
                        updateAndGetNextNlpReview={this.updateAndGetNextNlpReview} 
                        toast={this.state.toast}
                        reviewHasAddedRecords={this.state.reviewHasAddedRecords}
                        setPECCorrectionCommentSelected={this.setPECCorrectionCommentSelected}
                        />
                    }
                    {
                        !this.state.error &&
                        <Grid fluid style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <Row>
                                <Col xs={12}>
                                    <MemberDemographicContainer
                                        reviewProcessingUIConfig={this.state.reviewProcessingUIConfig}
                                        reviewType={this.props.route.reviewType} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <NLPReview location={this.props.location} reviewType={this.props.route.reviewType}
                                        reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} diagnosisComments={this.state.diagnosisComments}
                                        memberID={this.state.memberID} visible={!this.state.showHCCProfileTab}
                                        toggleTab={this.toggleTabVisibility} selectStarMeasure={this.selectStarMeasure}
                                        project={this.state.project}
                                        pecCorrectionCommentSelected={this.state.pecCorrectionCommentSelected} 
                                        toast={this.state.toast} medicalRecordTypeData={this.state.medicalRecordTypeData} sourceData={this.state.sourceData}
                                        providerTypeData={this.state.providerTypeData} rafTypeData={this.state.rafTypeData}
                                        medicalRecordTypeID={this.state.medicalRecordTypeID} imageRecords={this.state.imageRecords} reloadImages={this.populateImageIDOptions}
                                        userID={this.state.reviewCurrentUserID} setTriggerDiagRefresh={this.props.setTriggerDiagRefresh}
                                        />
                                </Col>
                            </Row>
                        </Grid>
                    }
                    {
                        !this.state.error &&
                        <Grid fluid style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <Row>
                                <Col xs={12}>
                                    <CaptureContainerRedux reviewProcessingUIConfig={this.state.reviewProcessingUIConfig} setReviewHasAddedRecords={this.setReviewHasAddedRecords}
                                        selectedStarMeasure={this.state.selectedStarMeasure} pecCorrectionCommentSelected={this.state.pecCorrectionCommentSelected}
                                        clearSelectedStarMeasure={this.selectStarMeasure} toast={this.state.toast}  setTriggerDiagRefresh={this.props.setTriggerDiagRefresh}/>
                                </Col>
                            </Row>
                        
                        </Grid>
                    }
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default NLPCodingContainer;
const session = (state = [], action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            if (state.find(x => x.type === action.type)) {
                return state.map(session =>
                    (session.type === action.type)
                    ? { ...session, currentUser: action.currentUser }
                    : session
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        currentUser: action.currentUser
                    }
                ];
            }
        default:
            return state;
    }
};

export default session;
import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import { Tabs, Tab } from 'react-bootstrap';
import NLPInsightsTabRedux from './NLPInsightsTabRedux';
import NLPHCCProfileTab from './NLPHCCProfileTab';
import NLPInsightsByHCCRedux from './NLPInsightsByHCCRedux';
import ReactPopout from '../../../../external/ReactPopout';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import 'pdfjs-dist/web/pdf_viewer.css';

const options = {
    toolbar: 'no',
    location: 'no',
    directories: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: window.screen.width * .8,
    height: window.screen.height * .8,
    top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
    left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
};

const windowTypes = {
    Pdf: 1,
    HCC: 2,
    Insights: 3
};

class ImageViewerNLP extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
            pageLoaded: true,
            pdfIsPoppedOut: false,
            hccIsPoppedOut: false,
            insightsIsPoppedOut: false,
            insightsHccIsPoppedOut: false,
            imageData: null,
            imageType: null,
            HCCVersion: 0,
            NLPInsightsByHCCTabVisible: false//process.env.REACT_APP_NLPInsightsByHCC_Visible
        }        
    }
    
    handleSelect = (eventKey) => {
        console.log('log eventkey:' + eventKey);
        this.setState({
            activeTab: eventKey
        });
    }
    togglePdfWindow = (imageData, imageType) => {
        if(!this.state.pdfIsPoppedOut){
            this.setState({ 
                pdfIsPoppedOut: !this.state.pdfIsPoppedOut,
                imageData: !this.state.pdfIsPoppedOut == true? imageData: null,
                imageType: !this.state.pdfIsPoppedOut == true? imageType: null
            });
        }
    }

    toggleInsightsWindow = () => {
        this.setState({ 
            insightsIsPoppedOut: !this.state.insightsIsPoppedOut
        });
    }
    toggleInsightsHccWindow = () => {
        this.setState({ 
            insightsHccIsPoppedOut: !this.state.insightsHccIsPoppedOut
        });
    }

    toggleHccWindow = (version) => {
        if(!this.state.hccIsPoppedOut){
            this.setState({ 
                hccIsPoppedOut: !this.state.hccIsPoppedOut,
                HCCVersion: isNaN(version) ? this.state.HCCVersion : version
            });
        }
    }
    closePdfWindow = () => {
        this.setState({ 
            pdfIsPoppedOut: false,
            imageData: null,
            imageType:  null
        });
    }

    closeInsightsWindow = () => {
        this.setState({ 
            insightsIsPoppedOut: false
        });
    }
    closeInsightsHccWindow = () => {
        this.setState({ 
            insightsHccIsPoppedOut: false
        });
    }

    closeHCCWindow = () => {
        this.setState({ 
            hccIsPoppedOut: false
        });
    }

    render() {
        const hccUrl = `/NLP/HCCProfileNewWindow/${this.props.reviewType}/${window.location.search}&HCCVersionNo=${this.state.HCCVersion}`;
        const insightsUrl = `/NLP/NLPInsightsNewWindow/${this.props.reviewType}/${window.location.search}`;
        const insightsHccUrl = `/NLP/NLPInsightHccNewWindow/${this.props.reviewType}/${window.location.search}`;
        const url = `/NLP/NLPImagePDFNewWindow?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`;
        return (
            <Grid fluid>
                <Loader loaded={this.state.pageLoaded}>
                    <Row>
                        <Col>
                            <Tabs id="nlpInsights-tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                                {
                                    <Tab eventKey={1} title="HCC Profile">
                                        {
                                            this.state.activeTab === 1 && !this.state.hccIsPoppedOut &&
                                            <NLPHCCProfileTab reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} 
                                                reviewType={this.props.reviewType} 
                                                memberID={this.props.memberID} visible={this.props.visible}
                                                toggleTab={this.props.toggleTab} selectStarMeasure={this.props.selectStarMeasure}
                                                project={this.props.project}
                                                pecCorrectionCommentSelected={this.props.pecCorrectionCommentSelected} 
                                                toast={this.props.toast} toggleHccWindow={this.toggleHccWindow}
                                                hccIsPoppedOut={this.state.hccIsPoppedOut}
                                            />
                                        }
                                    </Tab>
                                }
                                {
                                    
                                    <Tab eventKey={2} title="NLP Insights by Dx">
                                    {
                                        this.state.activeTab === 2 &&
                                        <NLPInsightsTabRedux location={this.props.location} 
                                        toast={this.props.toast} reviewProcessingUIConfig={this.props.reviewProcessingUIConfig}
                                        memberID={this.props.memberID} medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                                        providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData} diagnosisComments={this.props.diagnosisComments}
                                        medicalRecordTypeID={this.props.medicalRecordTypeID} imageRecords={this.props.imageRecords}
                                        reloadImages={this.props.reloadImages} userID={this.props.userID}
                                        reviewType={this.props.reviewType} togglePdfWindow={this.togglePdfWindow} 
                                        toggleInsightsWindow={this.toggleInsightsWindow} insightsIsPoppedOut={this.state.insightsIsPoppedOut}
                                        pdfIsPoppedOut={this.state.pdfIsPoppedOut} setTriggerDiagRefresh={this.props.setTriggerDiagRefresh}/>
                                    }
                                    </Tab>
                                }
                                {                                    
                                    <Tab eventKey={3} title="NLP Insights by HCC">
                                        {
                                            this.state.activeTab === 3 &&
                                            <NLPInsightsByHCCRedux location={this.props.location} 
                                        toast={this.props.toast} reviewProcessingUIConfig={this.props.reviewProcessingUIConfig}
                                        memberID={this.props.memberID} medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                                        providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData} diagnosisComments={this.props.diagnosisComments}
                                        medicalRecordTypeID={this.props.medicalRecordTypeID} imageRecords={this.props.imageRecords}
                                        reloadImages={this.props.reloadImages} userID={this.props.userID}
                                        reviewType={this.props.reviewType} togglePdfWindow={this.togglePdfWindow} 
                                        toggleInsightsHccWindow={this.toggleInsightsHccWindow} insightsHccIsPoppedOut={this.state.insightsHccIsPoppedOut}
                                        pdfIsPoppedOut={this.state.pdfIsPoppedOut}/>                                       
                                        }
                                    </Tab>                                    
                                }
                            </Tabs>
                        </Col>
                    </Row>
                    
                </Loader>
                {
                    this.state.imageData && this.state.imageType === "application/pdf" && this.state.pdfIsPoppedOut &&
                    <ReactPopout title="Insights pdf" containerId='containerId' onClosing={this.closePdfWindow}  options={options} url={this.state.imageData}>
                    </ReactPopout>
                }
                {
                    this.state.hccIsPoppedOut &&
                    <div style={{ maxHeight: `600px`, overflowY: 'scroll', overflowX: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>
                        <ReactPopout title="HCC Profile" onClosing={this.closeHCCWindow} options={options}
                            url={hccUrl}>
                        </ReactPopout>
                    </div>
                }
                {
                    this.state.insightsIsPoppedOut &&
                    <div style={{ maxHeight: `600px`, overflowY: 'scroll', overflowX: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>
                        <ReactPopout title="NLP Insights" onClosing={this.closeInsightsWindow} options={options} 
                        url={insightsUrl}>
                        </ReactPopout>
                    </div>
                }
                {
                    this.state.insightsHccIsPoppedOut &&
                    <div style={{ maxHeight: `600px`, overflowY: 'scroll', overflowX: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>
                        <ReactPopout title="NLP Insights By Hcc" onClosing={this.closeInsightsHccWindow} options={options} 
                        url={insightsHccUrl}>
                        </ReactPopout>
                    </div>
                }
            </Grid>
        );
    }
}
export default ImageViewerNLP;
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import '../../styles/Report.css'
import HCCReportModal from './HCCReportModal'
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor'

//todo: refactor this to use a single, shared HCCReportModal instead of the strange button/modal combinations inserted all over the place
class HCCReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: {
                statusGroups: []
            },
            submittedStatusesSummaries: [],
            submittedHCCScoreDetails: [],
            recordsByStatus: [],
            openFirstLayer: false,
            openSecondLayer: false
        };
    }

    componentDidMount() {
        if (this.props.radvAuditID) {
            axios.get(`/RADVAudits/${this.props.radvAuditID}/HCCReport`)
            .then((response) => {
                this.setState({
                    reportData: response.data,
                    submittedStatusesSummaries: response.data.statusGroups[3].statusesSummaries,
                    submittedHCCScoreDetails: response.data.statusGroups[3].statusesSummaries[0] ? response.data.statusGroups[3].statusesSummaries[0].scoreSummaries : []
                });
            })
            .catch((error) => {
                this.props.toast('error', 'HCC data failed to load.');
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.radvAuditID && nextProps.radvAuditID !== this.props.radvAuditID) {
            axios.get(`/RADVAudits/${nextProps.radvAuditID}/HCCReport`)
            .then((response) => {
                this.setState({
                    reportData: response.data,
                    submittedStatusesSummaries: response.data.statusGroups[3].statusesSummaries,
                    submittedHCCScoreDetails: response.data.statusGroups[3].statusesSummaries[0] ? response.data.statusGroups[3].statusesSummaries[0].scoreSummaries : []
                });
            })
            .catch((error) => {
                this.props.toast('error', 'HCC data failed to load.');
            });
        }
    }

    toggleCollapsibleRows = (row) => {
        if (row === 'Submitted to CMS') {
            this.setState({ openFirstLayer: !this.state.openFirstLayer, openSecondLayer: false });
        }
        else if (row === 'CMS') {
            this.setState({ openSecondLayer: !this.state.openSecondLayer });
        }
    }

    buildFunctionToSendToDownloadLink = () => {
        return axios.post("/ApplicationReports/DownloadRequests", {
            applicationReportID: "2",
            reportParameters: { radvAuditID : this.props.radvAuditID }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not create download.');
        });
    }

    render() {
        return (
            <div style={{ display: "block" }}>
                <br/>
                <Table bordered className="hccReportTable">
                    <thead>
                        <tr>
                            <th colSpan="3" className="text-center" style={{ background: "#efefef" }}>
                                RADV HCC Validation Report
                            </th>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <th>HCCs</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    {/*Not submitted and submitted rows*/}
                    <tbody>
                        {
                            this.state.reportData.statusGroups &&
                            this.state.reportData.statusGroups.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        {
                                            row.description !== 'Submitted to CMS' &&
                                            <td>{row.description}</td>
                                        }
                                        {
                                            row.description === 'Submitted to CMS' &&
                                            <td>
                                                <Button onClick={() => this.toggleCollapsibleRows(row.description)} className="buttonLink">
                                                    {row.description}
                                                </Button>
                                            </td>
                                        }
                                        {
                                            !row.statusesSummaries &&
                                            <td>
                                                <HCCReportModal radvAuditID={this.props.radvAuditID} cmsStatusDesc={row.description}
                                                    cmsStatus={1} displayText={row.count} toast={this.props.toast} />
                                            </td>
                                        }
                                        {
                                            row.statusesSummaries &&
                                            <td>
                                                <Button onClick={() => this.toggleCollapsibleRows('Submitted to CMS')} className="buttonLink">
                                                    {row.count}
                                                </Button>
                                            </td>
                                        }
                                        <td>{row.percentage}%</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                    {/*Submitted statuses - collapsible rows layer 1*/}
                    <tbody style={{display: this.state.openFirstLayer ? '' : 'none'}}>
                        {
                            this.state.submittedStatusesSummaries &&
                            this.state.submittedStatusesSummaries.map((row, i) => {
                                /*display all statuses except CMS Finalized*/
                                return row.radvAuditHCCStatusID !== 3 ? (
                                    <tr key={i} style={{ backgroundColor: '#fafafa' }}>
                                        <td style={{ paddingLeft: '3%' }}>
                                            <Button onClick={() => this.toggleCollapsibleRows('CMS')} className="buttonLink">
                                                CMS {row.radvAuditHCCStatusDesc}
                                            </Button>
                                        </td>
                                        <td>
                                            <HCCReportModal radvAuditID={this.props.radvAuditID} cmsStatusDesc={row.radvAuditHCCStatusDesc}
                                                cmsStatus={row.radvAuditHCCStatusID} displayText={row.statusCount} toast={this.props.toast} /></td>
                                        <td>{row.statusPercentage}%</td>
                                    </tr>
                                ) : '';
                            })
                        }
                    </tbody>
                    {/*Submitted statuses - collapsible rows - layer 2*/}
                    <tbody style={{ display: this.state.openSecondLayer ? '' : 'none' }}>
                        {
                            this.state.submittedHCCScoreDetails &&
                            this.state.submittedHCCScoreDetails.map((row, i) => {
                                return (
                                    <tr key={i} style={{ backgroundColor: '#fafafa', display: row.radvScoreDesc ? '' : 'none' }}>
                                        <td style={{ paddingLeft: '6%' }}>{row.radvScoreDesc}</td>
                                        {/* TODO: Add back in with drilldown story */}
                                        <td>
                                            <HCCReportModal radvAuditID={this.props.radvAuditID} cmsStatusDesc={row.radvScoreDesc} radvScoreID={row.radvScoreID}
                                                cmsStatus={this.state.submittedStatusesSummaries[0].radvAuditHCCStatusID} displayText={row.scoreCount}
                                                toast={this.props.toast} /></td>
                                        <td>{row.scorePercentage}%</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                    {/*Total row*/}
                    <tbody>
                        <tr key={(this.state.reportData.statusGroups.length + 2)}>
                            <td>Total CMS Audited HCCs</td>
                            <td>{this.state.reportData.totalAuditedHCCs}</td>
                            <td> </td>
                        </tr>
                    </tbody>
                </Table>
                <LongRunningDownloadAnchor linkText="Download .CSV"
                    postRequest={this.buildFunctionToSendToDownloadLink}
                    displayCSVDownloader={true}/>
            </div>
        );
    }
}

export default HCCReport;
import React from 'react';
import { Button, Col, Modal, FormGroup, FormControl, ControlLabel, Radio } from 'react-bootstrap';

class RADVFormModal extends React.Component {

    mapRADVScores = () => {
        if (this.props.radvScores && this.props.radvScores.length > 0 && this.props.row) {
            return this.props.radvScores.map(x => {
                return (
                    <li key={x.radvScoreID}>
                        <Radio id={x.radvScoreID} checked={this.props.row.radvScore && this.props.row.radvScore.radvScoreID === x.radvScoreID} 
                            name="selectedRADVScores" className="radvRadio" onChange={() => this.props.setRADVScore(x)}>
                            {x.description}
                        </Radio>
                    </li>
                )
            })
        }
    }

    mapHCCHierarchies = () => {
        if (this.props.hccHierarchies && this.props.hccHierarchies.length > 0 && this.props.row) {
            return this.props.hccHierarchies.map(x => {
                return (
                    <li key={x.hccHierarchyID}>
                        <Radio id={x.hccHierarchyID} checked={this.props.row.hccHierarchy && this.props.row.hccHierarchy.hccHierarchyID === x.hccHierarchyID } 
                            name="selectedHCCHierarchy" className="hccHierarchyRadio" onChange={() => this.props.setHCCHierarchy(x)}
                            disabled={x.description === "Full" || (this.props.row.hccHierarchy && this.props.row.hccHierarchy.description === "Full" && x.description !== "Full")}>
                            {x.description}
                        </Radio>
                    </li>
                )
            })
        }
    }

    render() {
        const relatedHCCTitle = this.props.row && this.props.row.hccHierarchy && this.props.row.hccHierarchy.description !== "Non-Related" ? "Related Targeted HCC" : "Occurs on same DOS as";
        const isFullOrNonRelated = this.props.row && this.props.row.hccHierarchy &&
            (this.props.row.hccHierarchy.description === "Full" || this.props.row.hccHierarchy.description === "Non-Related");
        return (
            <Modal dialogClassName="radvModal" show={this.props.visible} onHide={this.props.handleModalToggle} backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        RADV Score
                        <Button bsStyle="primary" style={{float: 'right'}} onClick={this.props.handleModalToggle}>Close</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: '200px'}}>
                    <Col xs={4}>
                        <h5>Description</h5>
                        <ul style={{listStyle: 'none', padding:'0'}}>
                            {
                                this.mapRADVScores()
                            }
                        </ul>
                    </Col>
                    <Col xs={4}>
                        <h5>Hierarchy</h5>
                        <ul style={{listStyle: 'none', padding:'0'}}>
                            {
                                this.mapHCCHierarchies()
                            }
                        </ul>
                    </Col>
                    <Col xs={4} style={{display: isFullOrNonRelated ? 'none' : 'block'}}>
                        <h5>{ relatedHCCTitle }</h5>
                        <FormGroup>
                            <ControlLabel style={{marginRight: '1rem'}}>V12:</ControlLabel>
                            <FormControl componentClass="select" value={this.props.row && this.props.row.radvAuditHCCID12}
                                onChange={(e) => this.props.handleRADVAuditHCCChange(e, 'V12')}>
                                <option value="">Select</option>
                                {
                                    this.props.radvAuditEnrolleeHCCs &&
                                    this.props.radvAuditEnrolleeHCCs.map((item) => {
                                        return item.hccVersion === 'V12' && 
                                            <option key={item.radvAuditHCCID} value={item.radvAuditHCCID}>{item.hccVersion}-{item.hccNumber}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel style={{marginRight: '1rem'}}>V22:</ControlLabel>
                            <FormControl componentClass="select" value={this.props.row && this.props.row.radvAuditHCCID22}
                                onChange={(e) => this.props.handleRADVAuditHCCChange(e, 'V22')}>
                                <option value="">Select</option>
                                {
                                    this.props.radvAuditEnrolleeHCCs &&
                                    this.props.radvAuditEnrolleeHCCs.map((item) => {
                                        return item.hccVersion === 'V22' && 
                                            <option key={item.radvAuditHCCID} value={item.radvAuditHCCID}>{item.hccVersion}-{item.hccNumber}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Modal.Body>
            </Modal>
        );
    }
}

export default RADVFormModal;
import React from 'react';
import axios from 'axios';
import { Button, Modal, Table } from 'react-bootstrap';
import Loader from 'react-loader';

class HCCReportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            recordsByStatus: [],
            loaded: false
        }
    }

    open = () => {
        this.setState({ showModal: true });
        this.getRecordsByStatus();
    }

    close = () => {
        this.setState({ showModal: false });
    }

    getRecordsByStatus() {
        if (this.props.radvAuditID) {
            this.setState({ loaded: false });
            axios.get(`/RADVAudits/${this.props.radvAuditID}/HCCReport/RADVAuditHCCs`, {
                params: {
                    radvHCCStatusID: this.props.cmsStatus,
                    reviewStatusDescription: this.props.cmsStatusDesc,
                    radvScoreID: this.props.radvScoreID
                }
            })
            .then((response) => {
                this.setState({
                    recordsByStatus: response.data,
                    loaded: true
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to load.');
                this.setState({ loaded: true });
            });
        }
    }

    render() {
        return (
            <div>
                <button className="buttonLink" onClick={this.open}>{this.props.displayText}</button>
                <Modal className="hccReportModal" show={this.state.showModal} onHide={this.close} bsSize="lg">
                    <Loader loaded={this.state.loaded}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.cmsStatusDesc}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table bordered className="hccReportModalTable">
                                <thead>
                                    <tr>
                                        <th>Enrollee ID</th>
                                        <th>Beneficiary ID</th>
                                        <th>Name</th>
                                        <th>Date of Birth</th>
                                        <th>HCC</th>
                                        <th>HCC Status</th>
                                        <th>Signature Impaired</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.recordsByStatus.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{row.enrolleeID}</td>
                                                    <td>{row.beneficiaryID}</td>
                                                    <td>{row.radvAuditEnrolleeFullName}</td>
                                                    <td>{row.radvAuditEnrolleeDOB}</td>
                                                    <td>{row.hccVersion}-{row.hccNumber}</td>
                                                    <td>{row.radvAuditHCCStatusDesc}</td>
                                                    <td>{row.isSignatureImpaired ? "Y" : "N"}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Close</Button>
                        </Modal.Footer>
                    </Loader>
                </Modal>
            </div>
        );
    }
}

export default HCCReportModal;
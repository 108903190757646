import React from 'react';
import { Col, Collapse, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import WorkflowSectionHeader from '../AlertWorkflow/WorkflowSectionHeader';

const OOAAlertInfo = (props) => {
    const handleDeliveryDateChange = (e) => {
        props.handleAlertDataFieldChange({ deliveryDate: e });
    }

    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="alert" title="Alert" />
            <Collapse in={props.expanded}>
                <div>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Alert ID</ControlLabel>
                                <FormControl type="text" value={props.alertData.alertID} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                           <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Date CDI Distributed</ControlLabel>
                                <DatePicker value={props.alertData.deliveryDate} onChange={handleDeliveryDateChange}
                                    style={{ zIndex: 'auto' }} disabled={props.isViewOnly} />
                           </FormGroup>
                        </Col>                       
                    </Row>
                </div>
            </Collapse>
        </div>
    );
};

export default OOAAlertInfo;
import React from 'react'
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';

class StatusHistory extends React.Component {
  constructor(props){
      super(props);
        this.state = {
            records: [],
            loaded: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.radvAuditEnrolleeID && nextProps.radvAuditEnrolleeID !== this.props.radvAuditEnrolleeID) {
            this.setState({ loaded: false });
            axios.get(`/RADVAuditEnrollee/${nextProps.radvAuditEnrolleeID}/History`)
            .then((response) => {
                this.setState({
                    records: response.data,
                    loaded: true
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to load.');
                this.setState({ loaded: true });
            });
        }
    }

    dateFormatter(cell, row) {
        return new Date(row.dateOfAction).toLocaleDateString('en-US', {timeZone: 'UTC'});
    }

    render() {
        const headerRecords = [
            {
                enrolleeID: this.props.enrolleeID,
                enrolleeName: this.props.enrolleeName,
                statusHeader: this.props.enrolleeStatusDesc
            }
        ];

        return (
            <div>
                <Modal show={this.props.showModal} onHide={() => this.props.toggleModal("StatusHistory")}>
                    <Loader loaded={this.state.loaded}>
                        <Modal.Header closeButton>
                            <Modal.Title>Status History</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="landingSearchResults">
                                <BootstrapTable data={headerRecords} hover>
                                    <TableHeaderColumn dataField="enrolleeID" isKey dataAlign="left">Enrollee ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="enrolleeName" dataAlign="left">Enrollee Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField="statusHeader" dataAlign="left">Status</TableHeaderColumn>
                                </BootstrapTable>
                                <br />
                                <BootstrapTable data={this.state.records} hover>
                                    <TableHeaderColumn dataField="enrolleeStatusDesc" isKey dataAlign="left">Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField="dateOfAction" dataFormat={this.dateFormatter} dataAlign="left" dataSort={true}>Status Change</TableHeaderColumn>
                                    <TableHeaderColumn dataField="updatedBy" dataAlign="left">Updated By</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.props.toggleModal("StatusHistory")}>Close</Button>
                        </Modal.Footer>
                    </Loader>
                </Modal>
            </div>
        );
    }
}   

export default StatusHistory
import React from 'react';
import axios from 'axios';
import { Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import EscalationQueueTable from './EscalationQueueTable';
import EscalationQueueFilters from './EscalationQueueFilters';
import AlertToastr from '../../Shared/AlertToastr';
import { ReferenceDataType } from '../../../enums/ReferenceDataType';
import ResolveReviewModal from './ResolveReviewModal';

const mapStateToProps = state => {
    const currentUserProp = state.session.find(x => x.currentUser);
    return currentUserProp ? currentUserProp : {};
};

class EscalationQueueContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            escalationQueueItems: [],
            issueTypeOptions: [],
            resolutionActionOptions: [],

            medicalRecordReviewID: '',
            campaignName: '',
            projectName: null,
            practiceName: null,
            providerName: null,
            capturedDiags: null,
            issueType: null,
            escalationReason: null,
            resolutionAction: null,

            resolutionActionsEditable: {},

            showBulkUpdate:false,
            bulkResolveActionID:-1,
            bulkSaveDisabled:true,

            capturedDiagsOptions: [
                { label: 'Y', value: 1 }, 
                { label: 'N', value: 0 }
            ],
            toast: null
        }
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentDidMount() {
        Promise.all([
            this.getEscalationQueueList(),
            this.getEscalateIssueTypes(),
            this.getResolutionActions()
        ])
        .then((responses) => {
            this.setState({
                escalationQueueItems: responses[0].data,
                issueTypeOptions: this.getEscalationIssueTypeDropdownOptions(responses[1].data),
                resolutionActionOptions: this.getResolutionActionDropdownOptions(responses[2].data),
                resolutionActionsEditable: this.getResolutionActionEditable(responses[2].data),
            })
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else {
                this.state.toast('error', 'Could not load Escalation Queue data.', 'Error');
            }
        })
    }

    getEscalationQueueList = () => {
        return axios.get('/escalationQueue', {
            params: {
                medicalRecordReviewID: this.state.medicalRecordReviewID,
                campaignName: this.state.campaignName,
                projectName: this.state.projectName,
                practiceName: this.state.practiceName,
                providerInfo: this.state.providerName,
                capturedDiags: this.state.capturedDiags,
                escalationTypeID: this.state.issueType,
                escalationReason: this.state.escalationReason,
                resolutionActionID: this.state.resolutionAction
            } 
            
        });
    }

    getReviewTypes = () => {
        return axios.get('/escalationQueue/escalationQueueReviewTypes');
    }

    getEscalateIssueTypes = () => {
        return axios.get(`referenceRetrievalData/ReferenceDataOptions/${ReferenceDataType.EscalateIssue}`);
    }

    getResolutionActions = () => {
        return axios.get(`referenceRetrievalData/ReferenceDataOptions/${ReferenceDataType.EscalatedAction}`);
    }

    getResolutionStatusDropdownOptions = (resolutionStatuses) => {
        return resolutionStatuses.map((x) => {
            return {
                label: x.description,
                value: x.id
            };
        });
    }

    getEscalationIssueTypeDropdownOptions = (issueTypes) => {
        return issueTypes.map((x) => {
            return {
                label: x.description,
                value: x.referenceDataItemID
            };
        });
    }

    getResolutionActionDropdownOptions = (resolutionActions) => {
        return resolutionActions.map((x) => {
            return {
                label: x.description,
                value: x.referenceDataItemID
            };
        });
    }

    getResolutionActionEditable = (resolutionOptions) => {
        return [
            { text: '(Empty)', value: ''},
            ...resolutionOptions.map((x) => {
                return {
                    text: x.description,
                    value: x.referenceDataItemID
                };
            })
        ];
    }

    handleFilterChange = (filterObj) => {
        const filterEntry = Object.entries(filterObj);
        this.setState({ [filterEntry[0][0]]: filterEntry[0][1] });
    }

    handleSearch = () => {
        this.getEscalationQueueList()
        .then((response) => {
            this.setState({ escalationQueueItems: response.data });
        })
        .catch((error) => {
            if(error.response.status === 404) {
                this.setState({
                    escalationQueueItems: []
                });
            } else {
                this.state.toast('error', 'Failed to load.', 'Error');
            }
        });
    }

    handleUpdateRow = (row) => {
        axios.put(`/medicalRecordReview/deescalateReivews`, [{
            medicalRecordReviewEscalationID: row.medicalRecordReviewEscalationID,
            medicalRecordReviewID: row.medicalRecordReviewID,
            resolutionActionID: row.resolutionActionID,
            resolvedBy: this.props.currentUser.userID,
        }])
        .then(() => {
            this.getEscalationQueueList()
            .then((response) => {
                this.setState({ escalationQueueItems: response.data })
            })
            .catch((error) => {
                if(error.response.status === 404) {
                    this.setState({
                        escalationQueueItems: []
                    });
                }
            });
        })
        .catch((error) => {
            this.state.toast('error', error.response.data.message, 'Error');
        })
    }

    setMRREscalations = (mRREscalations) => {
        this.setState({ escalationQueueItems: mRREscalations })
    }

    getSelectedEscalations = (searchResults = this.state.escalationQueueItems) => {
        return searchResults ? searchResults.filter(x => x.selected).map(x => x.medicalRecordReviewEscalationID) : [];
    }

    //this is to show bulk update modal
    //this is called when cancel button is clicked on the modal
    handleBulkUpdate = () => {
        this.setState({showBulkUpdate:!this.state.showBulkUpdate,
            bulkResolveActionID:-1,
            bulkSaveDisabled:true
        });
    }

    //this is to update all selected escalations
    handleBulkUpdateChanges = () => {
        this.setState({showBulkUpdate:!this.state.showBulkUpdate});
        if(this.state.escalationQueueItems !== null && this.state.escalationQueueItems.length > 0){
            let bulkUpdates =this.state.escalationQueueItems.filter(item => item.selected === true)
                .map(item => {
                    return {
                        medicalRecordReviewEscalationID: item.medicalRecordReviewEscalationID,
                        medicalRecordReviewID: item.medicalRecordReviewID,
                        resolutionActionID: this.state.bulkResolveActionID,
                        resolvedBy: this.props.currentUser.userID,                        
                    }
                });
            
                if(bulkUpdates.length > 0){
                    this.handleBulkUpdateEscalation(bulkUpdates);
                }
                else{
                    this.state.toast('error', 'No Reviews are selected', 'Error');
                }
        }        
    }

    handleBulkUpdateEscalation = (records) => {
        axios.put(`/medicalRecordReview/deescalateReivews`, records)
        .then(() => {
            this.getEscalationQueueList()
            .then((response) => {
                this.setState({ escalationQueueItems: response.data })
            })
            .catch((error) => {
                if(error.response.status === 404) {
                    this.setState({
                        escalationQueueItems: []
                    });
                }
            });
        })
        .catch((error) => {
            if(error.response.status === 400){
                this.setState({bulkResolveActionID:-1});
            }            
            this.state.toast('error', error.response.data.message, 'Error');
        })
    }

    //this is to set the bulk resolution action id selected
    handleBulkUpdateAction = (e) => {
        if(e === null){
            this.setState({bulkSaveDisabled:true});
        }
        else{
            this.setState({ bulkResolveActionID: e.value });
            if(e.value > 0){
                this.setState({bulkSaveDisabled:false});
            }
        }
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <h3>Escalation Queue</h3>
                </Row>
                <Row>
                    <EscalationQueueFilters issueTypeOptions={this.state.issueTypeOptions} capturedDiagsOptions={this.state.capturedDiagsOptions}
                        handleFilterChange={this.handleFilterChange} handleSearch={this.handleSearch}
                        medicalRecordReviewID={this.state.medicalRecordReviewID} campaignName={this.state.campaignName} projectName={this.state.projectName}
                        practiceName={this.state.practiceName} providerName={this.state.providerName} capturedDiags={this.state.capturedDiags} 
                        issueType={this.state.issueType} escalationReason={this.state.escalationReason} resolutionAction={this.state.resolutionAction}
                        resolutionActionOptions = {this.state.resolutionActionOptions}
                        handleBulkModal={this.handleBulkUpdate}/>
                </Row>
                <Row>
                    <EscalationQueueTable escalationQueueItems={this.state.escalationQueueItems} resolutionStatusOptions={this.state.resolutionStatusOptions}
                        resolutionActionOptions={this.state.resolutionActionOptions} resolutionActionsEditable={this.state.resolutionActionsEditable}
                        handleUpdateRow={this.handleUpdateRow} setMRREscalations={this.setMRREscalations} getSelectedEscalations={this.getSelectedEscalations} />
                </Row>
                <AlertToastr setAddToast={this.setAddToast} />
                <ResolveReviewModal showBulkModal={this.state.showBulkUpdate} handleBulkModal={this.handleBulkUpdate} 
                    defaultResolveActionID={this.state.bulkResolveActionID} resolutionActionOptions={this.state.resolutionActionOptions} 
                    onBulkActionChange={this.handleBulkUpdateAction} saveBulkUpdate={this.handleBulkUpdateChanges} saveDisabled={this.state.bulkSaveDisabled} />
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps
)(EscalationQueueContainer);
import React from 'react';
import { Button, Glyphicon, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Switch from "react-switch";

class ImageViewerTable extends React.Component {
    formatImageLink = (cell, row) => {
        return row.splitImageCount === 0 ? (
             <Button bsStyle="link" onClick={() => this.props.setSelectedImage(cell, row.fileTypeCD)}
                style={{ border: 'none', padding: '0px 0px' }}>{cell}</Button>
        ) : this.formatSplitImageLinks(cell, row);
    }

    formatSplitImageLinks = (cell, row) => {
        const splitImageLinks = [];
        let splitImageSequence;
        for (let i = 0; i < row.splitImageCount; i++) {
            splitImageSequence = i + 1;
            splitImageLinks.push(
                <Button bsStyle="link" sequence={splitImageSequence} onClick={(e) => this.props.setSelectedImage(cell, row.fileTypeCD, e)}
                    style={{ border: 'none', marginLeft: '0.2rem', marginRight: '0.2rem', padding: '0px 0px' }}>
                        {splitImageSequence}{splitImageSequence !== row.splitImageCount ? ',' : ''}
                </Button>
            );
        }
        return (
            <div>
                <span>{cell}&nbsp;
                ({
                    splitImageLinks.map(x => { return x })
                })
                </span>
            </div>
        )
    }

    formatDate = (date) => {
       return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatViewGroup = (cell, row) => {
        return cell && cell.length > 0 ? (
            <OverlayTrigger placement="bottom" overlay={this.getTooltip(row.medicalRecordImageID, cell)}>
            {
                this.getViewGroupIcon(cell)
            }
            </OverlayTrigger>
        ) : this.getViewGroupIcon(cell);
    }

    getTooltip = (id, viewGroup) => {
        return (
            <Tooltip id={id} style={{ maxHeight: "100px" }}>
            {
                viewGroup.map(x => {
                    return (
                        <p key={`${id}${x.viewedDateTime}`}>{x.viewedUsername} - {this.formatDateTime(x.viewedDateTime)}</p>
                    );
                })
            }
            </Tooltip>
        );
    }

    //ex. 6/15/2008 - 9:15:07 PM
    formatDateTime = (dateTime) => {
       return dateTime ? `${new Date(dateTime).toLocaleDateString('en-US', { timeZone: 'UTC' })} - ${new Date(dateTime).toLocaleTimeString('en-US', { timeZone: 'UTC' })}` : '';
    }

    getViewGroupIcon = (cell) => {
        return cell && cell.length > 0 ?
            <Glyphicon glyph="ok-circle" style={{fontSize: '20px', fontWeight: '800', color: "green"}} /> :
            <Glyphicon glyph="ban-circle" style={{fontSize: '20px', fontWeight: '800', color: "darkred"}} />
    }

    render() {
        return (
            <Row style={{ marginBottom: '1rem' }}>
                {
                    this.props.displayCollapseButton &&
                    <Row>
                        <label style={{ cursor: "pointer", float: "right", marginRight: "1.4rem", paddingTop: "0.2rem" }}>
                                <Switch onChange={this.props.collapseTable} checked={!this.props.tableCollapsed}
                                    uncheckedIcon={
                                        <Glyphicon glyph="chevron-right" style={{ color: "white", fontSize: "20px",
                                            lineHeight: "normal", marginLeft: "0.6rem" }} />
                                    }
                                    checkedIcon={
                                        <Glyphicon glyph="chevron-left" style={{ color: "white", fontSize: "20px",
                                            lineHeight: "normal", marginLeft: "0.4rem" }} />
                                    } />
                        </label>
                    </Row>
                }
                <h4 style={{ display: "inline-block", marginBottom: "1.4rem", marginLeft: "0.2rem" }}>
                    <b>{this.props.title}</b>
                </h4>
                <BootstrapTable data={this.props.images} className="imageViewerTable">
                    <TableHeaderColumn dataField="medicalRecordImageID" isKey dataFormat={this.formatImageLink} dataSort width="26%">ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceFrom" hidden={this.props.tableCollapsed} dataFormat={this.formatDate} dataSort width="22%">DOS From</TableHeaderColumn>
                    <TableHeaderColumn dataField="dateOfServiceTo" hidden={this.props.tableCollapsed} dataFormat={this.formatDate} dataSort width="22%">DOS To</TableHeaderColumn>
                    <TableHeaderColumn dataField="codingViewGroup" hidden={this.props.tableCollapsed} dataAlign="center" dataFormat={this.formatViewGroup} dataSort width="18%">Coding</TableHeaderColumn>
                    <TableHeaderColumn dataField="qaViewGroup" hidden={this.props.tableCollapsed} dataAlign="center" dataFormat={this.formatViewGroup} dataSort width="12%">QA</TableHeaderColumn>
                    <TableHeaderColumn dataField="fileTypeCD" hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="splitImageCount" hidden></TableHeaderColumn>
                </BootstrapTable>
            </Row>
        );
    }
}

export default ImageViewerTable;
import React from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, ButtonGroup, Glyphicon, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Loader from 'react-loader';
import { CDIAlertWorkflowStatus } from '../../../enums/CDIAlertWorkflowStatus';
import { SessionParamCache } from '../../../utils/SessionUtils';
import CDIAlertWorkflowsSearch from './CDIAlertWorkflowsSearch';

class CDIAlertWorkflows extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`pecSettings-${this.lineOfBusiness}`, 'local');
        this.paramCacheKey = 'cdiAlertWorkflowSearch';

        const savedParams = this.paramCache.getParamObj(this.paramCacheKey);
        this.state = {
            cdiAlertWorkflows: [],
            cdiAlertWorkflowStatuses: [],
            cdiAlertWorkflowsCount: 0,
            page: 1,
            pageSize: savedParams.pageSize ? savedParams.pageSize : 250,
            sortBy: '',
            sortByDesc: false,
            loadingResults: false,
            enableSearch: true
        };
        this.statusIDsFor = {
            view: [
                CDIAlertWorkflowStatus.Cancelled,
                CDIAlertWorkflowStatus.Captured,
                CDIAlertWorkflowStatus.Completed,
                CDIAlertWorkflowStatus.ReadyForMRR,
                CDIAlertWorkflowStatus.ReviewInProgress,
                CDIAlertWorkflowStatus.Exception].map(x => x.toString()),
            update: [CDIAlertWorkflowStatus.Created].map(x => x.toString()),
            queryInitiatedUpdate: [
                CDIAlertWorkflowStatus.QueryInitiated,
                CDIAlertWorkflowStatus.Exception].map(x => x.toString()),
            resetToqueryInitiated: [CDIAlertWorkflowStatus.Completed].map(x => x.toString()),
            delete: [CDIAlertWorkflowStatus.Created].map(x => x.toString()),
            returnWorkflowToPEC: [CDIAlertWorkflowStatus.ReadyForMRR].map(x => x.toString()),
        };
        this.queryParams = {};
    }

    componentWillMount() {
        this.getStatuses();
    }

    getProvidersForGroupingID = (groupingID) => {
        return axios.get(`/practice/${groupingID}/provider`);
    }

    getWorkflows = (queryParams, page = this.state.page, pageSize = this.state.pageSize, sortBy = this.state.sortBy, sortByDesc = this.state.sortByDesc) => {
        this.setState({
            loadingResults: true,
            cdiAlertWorkflows: [],
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortByDesc: sortByDesc
        });
        
        if (queryParams) {
            this.queryParams = {
                ...queryParams,
                pageNumber: page,
                pageSize,
                sortBy,
                sortByDesc
            }

            const cachedQueryParams = Object.assign({}, this.queryParams);
            const nonCachedQueryParams = ['maxAppointmentDate', 'minAppointmentDate', 'maxDeliveryDate', 'minDeliveryDate'];

            // remove the properties in nonCachedQueryParams from cachedQueryParams.
            // these are still used for the request, but will not be cached.
            nonCachedQueryParams.forEach(x => delete cachedQueryParams[x]);
            this.paramCache.addParamObj(this.paramCacheKey, cachedQueryParams);
        }

        axios.get('/Alert', { params: this.queryParams })
        .then(response => {
            this.setState({
                cdiAlertWorkflows: response.data.items,
                cdiAlertWorkflowsCount: response.data.totalItems,
                loadingResults: false,
            });
        })
        .catch(error => {
            this.setState({
                cdiAlertWorkflows: [],
                cdiAlertWorkflowsCount: 0,
                loadingResults: false,
            })
        });
    }

    getStatuses = () => {
        axios.get('/cdialerts/workflowstatuses')
        .then(response => {
            this.setState({
                cdiAlertWorkflowStatuses: response.data
            });
        })
        .catch(error => {
            this.setState({
                cdiAlertWorkflowStatuses: null
            })
        });
    }
    
    formatDateString = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }

    formatPractice = (practice, row) => {
        return practice !== null ? practice + ' - ' + row.groupingID : "";
    }

    formatStatus = (status, row) => {
        if (status === 'Query Initiated') {
            return row.queryForwardedToDoctorDate ? status + '\nDelivered: ' + new Date(row.queryForwardedToDoctorDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : status;
        }
        else {
            return status;
        }
    }
    
    loaderJSX = () => {
        if (this.state.loadingResults) {
            return (<Loader loaded={!this.state.loadingResults}/>)
        }
        else {
            return 'There is no data to display';
        }
    }

    handleDelete = (cdiAlertWorkflowID) => () => {
        axios.put(`/cdialerts/${cdiAlertWorkflowID}`
            , {cdiAlertWorkflowStatusID: CDIAlertWorkflowStatus.Cancelled})
        .then(res => this.getWorkflows())
    }

    handleStatusReset = (cdiAlertWorkflow) => () => {
        axios.put(`/cdialerts/${cdiAlertWorkflow.cdiAlertWorkflowID}`
             , {cdiAlertWorkflowStatusID: 
                    cdiAlertWorkflow.alertHasBeenInQueryInitiated
                    ? CDIAlertWorkflowStatus.QueryInitiated
                    : CDIAlertWorkflowStatus.Created})
            .then(res => this.getWorkflows())
    }

    handleResetToQueryInitiated = (cdiAlertWorkflowID) => () => {
        axios.put(`/cdialerts/${cdiAlertWorkflowID}`
            , {cdiAlertWorkflowStatusID: CDIAlertWorkflowStatus.QueryInitiated})
        .then(res => {
            window.location = `/cdi/${cdiAlertWorkflowID}/queryResponse?isPECUpdateAlert=true`;
        })
    }

    formatActions = (statusID, row) => {
        const viewTooltip = (
            <Tooltip id="update"><strong>View</strong></Tooltip>
        );
        const updateTooltip = (
            <Tooltip id="update"><strong>Update</strong></Tooltip>
        );
        const queryInitiatedUpdateTooltip = (
            <Tooltip id="update"><strong>Update Query Initiated</strong></Tooltip>
        );
        const resetToqueryInitiatedTooltip = (
            <Tooltip id="reset"><strong>Initiate Query</strong></Tooltip>
        );
        const cancelTooltip = (
            <Tooltip id="cancel"><strong>Cancel</strong></Tooltip>
        );
        const pullBackTooltip = (
            <Tooltip id="cancel"><strong>Get back from Ready for MRR</strong></Tooltip>
        );

        return (
            <div>
                <ButtonGroup>
                    <OverlayTrigger placement="top" overlay={viewTooltip}>
                        <Button style={{display: this.statusIDsFor.view.includes(statusID) ? 'auto': 'none'}}
                            onClick={() => {
                                window.location = `/cdi/${row.cdiAlertWorkflowID}/viewonly?isPECViewAlert=true`;
                            }}>
                            <Glyphicon glyph="search"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={updateTooltip}>
                        <Button style={{display: this.statusIDsFor.update.includes(statusID) ? 'auto': 'none'}}
                            onClick={() => {
                                window.location = `/cdi/${row.cdiAlertWorkflowID}/update?isPECUpdateAlert=true`;
                            }}>
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={queryInitiatedUpdateTooltip}>
                        <Button style={{display: this.statusIDsFor.queryInitiatedUpdate.includes(statusID) ? 'auto': 'none'}}
                            onClick={() => {
                                window.location = `/cdi/${row.cdiAlertWorkflowID}/queryResponse?isPECUpdateAlert=true`;
                            }}>
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={resetToqueryInitiatedTooltip}>
                        <Button style={{display: this.statusIDsFor.resetToqueryInitiated.includes(statusID) ? 'auto': 'none'}}
                            onClick={this.handleResetToQueryInitiated(row.cdiAlertWorkflowID)}>
                            <Glyphicon glyph="question-sign"/>
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={cancelTooltip}>
                        <Button style={{display: this.statusIDsFor.delete.includes(statusID) ? 'auto': 'none'}}
                            onClick={this.handleDelete(row.cdiAlertWorkflowID)}>
                            <Glyphicon glyph="remove"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={pullBackTooltip}>
                        <Button style={{display: this.statusIDsFor.returnWorkflowToPEC.includes(statusID) ? 'auto': 'none'}}
                            onClick={this.handleStatusReset(row)}>
                            <Glyphicon glyph='arrow-left'/>
                        </Button>
                    </OverlayTrigger>
                </ButtonGroup>
            </div>
        );
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return {text: item.toString(), value: item}}),
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                }, this.getWorkflows(this.queryParams, page, sizePerPage));
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortOrder === "desc",
                    page: 1,
                    pageSize: 250
                }, this.getWorkflows(this.queryParams, 1, 250, sortName, sortOrder === "desc"));
            },
            noDataText: this.loaderJSX()
        }

        return (
            <div>
                <div style={{margin: '10px'}}>
                    <CDIAlertWorkflowsSearch 
                        practices={this.props.practices}
                        providerOrgs={this.props.providerOrgs}
                        cdiAlertWorkflowStatuses={this.state.cdiAlertWorkflowStatuses}
                        getProvidersForPractice={this.getProvidersForGroupingID}
                        outboundTypes={this.props.outboundTypes}
                        search={this.getWorkflows}
                        countFromLastSearch={this.state.cdiAlertWorkflowsCount}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        onSortChange={this.onSortChange}
                        searchEnabled={!this.state.loadingResults}
                        toast={this.props.toast}
                        assignedPECs={this.props.assignedPECs} />
                </div>
                <div>
                    <BootstrapTable data={this.state.cdiAlertWorkflows} options={options} pagination remote
                        fetchInfo={{ dataTotalSize: this.state.cdiAlertWorkflowsCount}} maxHeight='400px'>
                        <TableHeaderColumn dataField="cdiAlertWorkflowStatusID" 
                            dataFormat={this.formatActions.bind(this)}>Actions</TableHeaderColumn>
                        <TableHeaderColumn dataField="alertID" isKey dataSort>Alert ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberID" dataSort>Member ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberName" dataSort>Member Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="memberDOB" dataSort dataFormat={this.formatDateString}>Member DOB</TableHeaderColumn>
                        <TableHeaderColumn dataField="appointmentDate" dataSort dataFormat={this.formatDateString}>Appt Date</TableHeaderColumn>
                        <TableHeaderColumn dataField="deliveryDate" dataSort dataFormat={this.formatDateString}>Delivery Date</TableHeaderColumn>
                        <TableHeaderColumn dataField="appointmentTypeDesc" dataSort>Appt Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceDesc" dataSort dataFormat={this.formatPractice}>Practice</TableHeaderColumn>
                        <TableHeaderColumn dataField="appointmentProviderName" dataSort>Appointment Provider</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerNPI" dataSort>Provider NPI</TableHeaderColumn>
                        <TableHeaderColumn dataField="cdiAlertStatusDesc" dataFormat={this.formatStatus} dataSort>Status</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}
export default CDIAlertWorkflows;
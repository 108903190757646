import React from 'react';
import axios from 'axios';
import fileDownload from 'react-file-download';
import {Button , Glyphicon} from 'react-bootstrap';
import Loader from 'react-loader';

class LongRunningDownloadAnchor extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            fileDownloading : false,
            errorOccurred : false,
            errorMessage : undefined
        }
    }

    postDownloadRequestAndSaveResponse = (actionToTakeIfRequestAccepted) => {
        var parentScope = this;
        this.props.postRequest()
            .then(function(response){
                parentScope.setState({
                    fileDownloading : true,
                    errorOccurred : false,
                    errorMessage : undefined
                });
                actionToTakeIfRequestAccepted(response.data);
            })
            .catch(function(error){
                parentScope.setState({
                    fileDownloading : false,
                    errorOccurred : true,
                    errorMessage : "Could not download the file"
                });
            })
    }

    downloadFileOrRetry = (nextRepresentation, backOfTime) => {
        var parentScope = this;
        var responseObjectHasDownloadLink = 
            nextRepresentation.links
                .find((current) => { return current.rel === "result-download" });
        var selfLink =
            nextRepresentation.links
                .find(function(current){
                    return current.rel === "self"
                });
        if(responseObjectHasDownloadLink){
            axios.get(responseObjectHasDownloadLink.href)
                .then(function(downloadResponse){
                    fileDownload(downloadResponse.data, 'DownloadRequest.csv', 'text/plain');
                    parentScope.setState({
                        fileDownloading : false,
                        errorOccurred : false,
                        errorMessage : undefined
                    });
                })
                .catch(function(error){
                    parentScope.setState({
                        fileDownloading : false,
                        errorOccurred : true,
                        errorMessage : "Could not download the file"
                    });
                });
        }
        else{
            setTimeout(() => {
                axios.get(selfLink.href)
                .then(function(response){
                    parentScope.downloadFileOrRetry(response.data, backOfTime * 2);
                })
                .catch(function(error){
                    parentScope.setState({
                        fileDownloading : false,
                        errorOccurred : true,
                        errorMessage : "Could not download the file"
                    });
                });
            }, backOfTime);
        }
    }

    controller = () => {
        var parentScope = this;
        var callback = function(initialRepresentation){
            parentScope.downloadFileOrRetry(initialRepresentation, 1000);
        }
        this.postDownloadRequestAndSaveResponse(callback)
    }

    render() {
        const display = { display: this.props.displayCSVDownloader ? 'inline-block' : 'none' };
        return(
            <div style={{ ...display, ...this.props.containerStyle}}>
                <Button bsStyle="primary" onClick={this.controller} disabled={this.state.fileDownloading} style={this.props.buttonStyle}>
                {
                    this.props.glyph &&
                    <Glyphicon glyph={this.props.glyph} style={{marginRight: '0.3rem'}} />
                }
                {this.props.linkText}
                </Button>
                <div style={{ marginLeft:'30px', display: 'inline-block'}}>
                    <Loader loaded={!this.state.fileDownloading} position='relative' left="0%" ></Loader>
                </div>
                <div hidden={!this.state.errorOccurred}>
                    <span>{this.state.errorMessage}</span>
                </div>
            </div>
        );
    }
}

export default LongRunningDownloadAnchor;
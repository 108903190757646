import React from 'react';
import { Collapse, Glyphicon, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class ImageViewerNLPHCCTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    toggleHCCDisplay = () => {
        this.setState((prevState) => {
            return { expanded: !prevState.expanded };
        });
    }

    render() {
        const options = {
            defaultSortName: ['hcc', 'dateOfServiceTo', 'dateOfServiceFrom', 'pageNumTo', 'pageNumFrom'],
            defaultSortOrder: ['asc', 'desc', 'desc', 'asc', 'asc']
        };
        return (
            <Row>
                <div onClick={this.toggleHCCDisplay} style={{ cursor: 'pointer' }}>
                    <h4 style={{ marginLeft: '1.4rem' }}>
                        {
                            this.props.tableCollapsed ? this.props.hcc.hcc
                                : `${this.props.hcc.hcc} - ${this.props.hcc.hccDescription}`
                        }
                        <Glyphicon glyph={this.state.expanded ? "minus" : "plus"}
                            style={{ color: 'rgb(108, 113, 108)', float: 'right', top: '2px' }} />
                    </h4>
                    <hr style={{ marginLeft: '1.4rem' }} />
                </div>
                <Collapse in={this.state.expanded}>
                    <div style={{ marginBottom: '1rem' }}>
                        <BootstrapTable data={this.props.insights} options={options} className="imageViewerNLPHCCTable"
                            multiColumnSort={6}>
                            <TableHeaderColumn dataField="pageNumFrom" dataSort width="8%">Pg. From</TableHeaderColumn>
                            <TableHeaderColumn dataField="pageNumTo" dataSort width="8%">Pg. To</TableHeaderColumn>
                            <TableHeaderColumn dataField="dateOfServiceFrom" hidden={this.props.tableCollapsed}
                                dataFormat={this.props.formatDate} dataSort width="14%">DOS From</TableHeaderColumn>
                            <TableHeaderColumn dataField="dateOfServiceTo" hidden={this.props.tableCollapsed}
                                dataFormat={this.props.formatDate} dataSort width="14%">DOS To&nbsp;&nbsp;</TableHeaderColumn>
                            <TableHeaderColumn dataField="diagnosisCD" dataSort width="8%">DX Code</TableHeaderColumn>
                            <TableHeaderColumn dataField="confidenceLevel" dataFormat={this.props.formatConfidenceLevel}
                                dataSort width="12%">Confidence Level</TableHeaderColumn>
                            <TableHeaderColumn dataField="medicalRecordInsightID" isKey hidden>ID</TableHeaderColumn>    
                        </BootstrapTable>
                    </div>
                </Collapse>
            </Row>
        );
    }
}

export default ImageViewerNLPHCCTable;
import React from 'react';
import axios from 'axios';
import { Col, Grid, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import ImageViewerTable from './ImageViewerTable';
import ImageViewerNLPContainer from './ImageViewerNLPContainer';
import Error from '../../../Shared/Error';

class ImageViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageDisplayGroups: [],
            nlpInsightData: [],
            selectedImageID: '',
            selectedImageURI: '',
            imageData: null,
            imageType: null,
            imageLoading: false,
            showImageError: false,
            pageLoaded: false,
            tableCollapsed: false
        };
    }

    componentWillMount() {
        this.getImageDisplayGroups();
    }

    getImageDisplayGroups = () => {
        Promise.all([
            axios.get(`/medicalrecordreview/${this.props.location.query.medicalRecordReviewID}/imageactivity`)
            //MRR-36078 -  Currently disabling the display of NLP insights from CIOX in UI, It might be enabled in the future, so commenting the code
            //axios.get(`/medicalrecordreview/${this.props.location.query.medicalRecordReviewID}/medicalrecordinsight`)
        ])
        .then((responses) => {
            this.setState({
                imageDisplayGroups: responses[0].data,
                //nlpInsightData: responses[1].data,
                pageLoaded: true
            });
        });
    }

    setSelectedImage = (imageID, fileTypeCD, e) => {
        this.setState({ imageLoading: true });

        let sequence = -1;
        if (e && e.target.attributes.sequence.value) {
            sequence = parseInt(e.target.attributes.sequence.value, 10);
        }

        axios.get(`/medicalrecordreview/${this.props.location.query.medicalRecordReviewID}/images/${imageID}`, {
            params: { sequence },
            responseType: 'arraybuffer'
        })
        .then((response) => {
            const imageType = (fileTypeCD && fileTypeCD.toUpperCase() === "HTM") ? "text/html" : "application/pdf";
            this.setState({
                selectedImageID: imageID,
                imageData: URL.createObjectURL(new Blob([response.data], { type: imageType })),
                showImageError: false,
                imageLoading: false,
                imageType: imageType
            }, () => {
                this.getImageDisplayGroups();
            });
        })
        .catch((error) => {
            this.setState({
                selectedImageID: '',
                showImageError: true,
                imageLoading: false
            }, () => {
                this.getImageDisplayGroups();
            });
        });
    }

    collapseTable = () => {
        this.setState({ tableCollapsed: !this.state.tableCollapsed });
    }

    getImageGroupTables = () => {
        return this.state.imageDisplayGroups ? this.state.imageDisplayGroups.map((x, ind) => {
            const groupMethodDesc = x.RetrievalMethodDesc ? x.RetrievalMethodDesc : "Unspecified Method";
            const groupSourceDesc = x.RetrievalSourceDesc ? x.RetrievalSourceDesc : "Unspecified Source";
            const title = `${groupMethodDesc} - ${groupSourceDesc}`;
            return (
                <ImageViewerTable key={title} title={title} images={x.images} setSelectedImage={this.setSelectedImage}
                    tableCollapsed={this.state.tableCollapsed} collapseTable={this.collapseTable} displayCollapseButton={ind === 0} />
            )
        }) : null;
    }

    getNLPTable = () => {
        if (this.state.selectedImageID && this.state.nlpInsightData && this.state.nlpInsightData.images && this.state.nlpInsightData.images.length > 0) {
            const selectedNLPData = this.state.nlpInsightData.images.find(x => x.medicalRecordImageID === this.state.selectedImageID);
            if (selectedNLPData.insightEntries.length > 0) {
                return (
                    <ImageViewerNLPContainer key={selectedNLPData.medicalRecordImageID}
                        title={`NLP Insights - ${selectedNLPData.medicalRecordImageID}`}
                        insightData={selectedNLPData.insightEntries} tableCollapsed={this.state.tableCollapsed} />
                );
            }
        }
        return null;
    }

    render() {
        return (
            <Grid fluid>
                <Loader loaded={this.state.pageLoaded}>
                    <Row>
                        <Col xs={this.state.tableCollapsed ? 2 : 4} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                            <div style={{ maxHeight: `${window.innerHeight - 35}px`, overflowY: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>
                            {
                                this.state.pageLoaded && this.getImageGroupTables()
                            }
                            {
                                this.state.pageLoaded && this.getNLPTable()
                            }
                            </div>
                        </Col>
                        <Col xs={this.state.tableCollapsed ? 10 : 8}>
                            <div>
                                <Loader loaded={!this.state.imageLoading}>
                                    {
                                        !this.state.showImageError && this.state.imageData && this.state.imageType === "text/html" &&
                                        <embed width="100%" height={window.innerHeight - 35} src={this.state.imageData} type={this.state.imageType} />
                                    }
                                    {
                                        !this.state.showImageError && this.state.imageData && this.state.imageType === "application/pdf" &&
                                        <embed width="100%" height={window.innerHeight - 35} src={this.state.imageData} type={this.state.imageType} />
                                    }
                                    {
                                        this.state.showImageError &&
                                        <Error style={{ marginTop: '5rem' }} />
                                    }
                                </Loader>
                            </div>
                        </Col>
                    </Row>
                </Loader>
            </Grid>
        );
    }
}

export default ImageViewer;
import React from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import CDIAlertContainerRedux from './CDIAlertContainerRedux';
import ClaimsTab from './ClaimsTab';

class ClaimsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            years: [],
            claimsData: [],
            displayAuditAndActions: false,
            claimsWithDiags: [],
            loaded: false,
            claimsTabCount: 0,
            activeTab: 1
        };
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");
    }

    componentWillMount() {
        this.getClaimsData();
    }

    componentWillReceiveProps(nextProps) {
        //check if project has a claims program
        if (!this.state.displayAuditAndActions && nextProps.project && nextProps.project.programs.find(x => x.programID === 6)) {
            if (!this.props.reviewProcessingUIConfig.isCoderQA && !this.props.reviewProcessingUIConfig.isMD && !this.props.reviewProcessingUIConfig.isComplete) {
                this.setState({ displayAuditAndActions: true });
            }
        }

        //refresh claims ID list if changed
        const claimsIDList = nextProps.diags.find(x => x.type === "SET_CLAIMS_DIAG_INITIATOR_ID_LIST");
        if (claimsIDList && claimsIDList.claimsDiagInitiatorIDList) {
            const a = new Set(claimsIDList.claimsDiagInitiatorIDList);
            const b = new Set(this.state.claimsWithDiags);
            const listsAreEqual = a.size === b.size && [...a].every(value => b.has(value));

            if (!listsAreEqual) {
                this.setState({ claimsWithDiags: claimsIDList.claimsDiagInitiatorIDList });
            }
        }
    }

    getClaimsData = () => {
        const years = [2,1,0].map(offset => { return { offset: offset, year: new Date().getFullYear() - offset } });
        axios.get(`/memberService/v1/member/${this.props.memberID}/diagnosisProfile`,
        {
            params : {
                years: years.map(y => {
                    return y.year;
                })
            }
        })
        .then(response => {
            //pass claims data to DiagnosisCapture for manual claim linking validation
            this.props.setClaimsList(this.buildClaimsList(response.data));
            this.setState({
                years: years,
                claimsData: response.data,
                claimsTabCount: this.getClaimsTabCount(years, response.data),
                loaded: true
            });
        })
        .catch(error => {
            this.props.toast('error', 'Could not load claims data.', 'Error');
            this.setState({ loaded: true });
        });
    }

    getClaimsTabCount = (years, claimsData) => {
        if (claimsData.length > 0) {
            return years.reduce((acc, year, index) => {
                return claimsData[index] && claimsData[index].hccModelClaim && Object.keys(claimsData[index].hccModelClaim).length > 0 ?
                     acc + 1 : acc;
            }, 0);
        }
        else {
            return 0;
        }
    }

    //returns a flattened array of objects containing a selection of claims properties
    buildClaimsList = (claimsResponse) => {
        return claimsResponse.reduce((acc, claimsForYear) => {
            return claimsForYear.hccModelClaim && Object.keys(claimsForYear.hccModelClaim).length > 0 ?
                acc.concat(Object.keys(claimsForYear.hccModelClaim).reduce((acc, version) => {
                    return acc.concat(claimsForYear.hccModelClaim[version].map(claim => {
                        return {
                            claimYear: claimsForYear.claimYear,
                            version: version,
                            claimNumber: claim.claimNumber,
                            dateOfServiceFrom: claim.dateOfServiceFrom,
                            dateOfServiceThru: claim.dateOfServiceThru
                        };
                    }));
                }, []))
                : acc;
        }, []);
    }

    handleSelectTab = (eventKey) => {
        this.setState({ activeTab: eventKey });
    }

    renderClaimsTabs = () => {
        let renderedClaimsTabCount = 0;
        if (this.state.claimsData.length > 0) {
            return this.state.years.map((year, index) => {
                 if (this.state.claimsData[index] && this.state.claimsData[index].hccModelClaim
                    && Object.entries(this.state.claimsData[index].hccModelClaim).length > 0)
                 {
                    renderedClaimsTabCount++;
                    return (
                        <Tab key={renderedClaimsTabCount} eventKey={renderedClaimsTabCount} title={`${year.year} Claims`}>
                            <ClaimsTab claimsYear={year.year} memberID={this.props.memberID} claimsData={this.state.claimsData[index].hccModelClaim}
                                hasClaimsProgram={this.state.hasClaimsProgram} isComplete={this.props.reviewProcessingUIConfig.isComplete}
                                displayAuditAndActions={this.state.displayAuditAndActions} userID={this.props.reviewProcessingUIConfig.userID}
                                medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                                medicalRecordTypeID={this.props.reviewProcessingUIConfig.medicalRecordTypeID}
                                claimsWithDiags={this.state.claimsWithDiags} setTriggerDiagRefresh={this.props.setTriggerDiagRefresh}
                                toast={this.props.toast} />
                        </Tab>
                    );
                }
                else {
                    return null;
                }
            });
        }
    }

    render() {
        return (
            <Tabs id="claims-cdi-tabs" activeKey={this.state.activeTab} onSelect={this.handleSelectTab} style={{ maxHeight: '450px' }}>
                {
                    this.renderClaimsTabs()
                }
                {
                    this.state.loaded && this.props.reviewProcessingUIConfig.showCodingQueryData &&
                    <Tab key={this.state.claimsTabCount + 1} eventKey={this.state.claimsTabCount + 1} title={"CDI Alerts"}>
                        <CDIAlertContainerRedux reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} pecCorrectionCommentSelected={this.props.pecCorrectionCommentSelected}
                            toast={this.props.toast} />
                    </Tab>
                }
            </Tabs>
        );
    }
}

export default ClaimsContainer;
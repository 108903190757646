import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

class OOAAlertReponseDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAlertResponse: this.props.responseDetail.selectedOption
        };
    }

    formatAlertResponseOptions = (options) => {
        return options.map(x => {
            return { label: x.optionText, value: x.optionID }
        });
    }

    handleAlertResponseChange = (e) => {
        this.setState({ selectedAlertResponse:  e ? e.value : '' });

        //Call parent methods to update the state response value
        this.props.refreshAlertResponse(e ? e.value : 0, this.props.responseDetail.alertResponseID);
    }

    handleAlertResponseUpdate = () => {
        if (this.state.selectedAlertResponse) {
            this.props.updateAlertResponse(this.state.selectedAlertResponse, this.props.responseDetail.alertResponseID);
        }
        else {
            this.props.deleteAlertResponse(this.props.responseDetail.alertResponseID);
        }

        this.props.refreshAlertResponse(this.state.selectedAlertResponse, this.props.responseDetail.alertResponseID);
    }

    render() {
        return (
            <Row>
                <Col lg={2}>
                    <Select value={this.state.selectedAlertResponse} options={this.formatAlertResponseOptions(this.props.responseDetail.responseOptions)}
                        onChange={this.handleAlertResponseChange} disabled={this.props.isViewOnly} />
                </Col>
                {/* <Col lg={1}>
                    <Button onClick={this.handleAlertResponseUpdate} disabled={this.props.isViewOnly}>
                        <Glyphicon glyph="floppy-disk" style={{ 'marginRight': '0.2rem' }} />
                    </Button>
                </Col> */}
                <Col lg={9}>
                    {this.props.responseDetail.alertLineText}
                </Col>
            </Row>
        );
    }
}

export default OOAAlertReponseDetail;
// exports PracticeValidationFileType - an immutable object 'enum'
// usage: import { PracticeValidationFileType } from '../../enums/PracticeValidationFileType';
const practiceValidationFileTypes = {
    Created: 1,
    Initial: 2,
    FollowUp: 3,
    Final: 4,
    Response: 5
};
Object.freeze(practiceValidationFileTypes);
export const PracticeValidationFileType = practiceValidationFileTypes;
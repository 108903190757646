import React from 'react';
import axios from 'axios';
import { Grid, Navbar } from 'react-bootstrap';
import MemberSelectionCombinedContainer from './MemberSelectionCombinedContainer';
import MemberSelectionContainer from './MemberSelectionContainer';
import PackageQuerySelection from './PackageQuerySelection';
import PackageReviewPrint from './PackageReviewPrint';
import PackageReview from './PackageReview';
import WorkflowNav from './WorkflowNav';
import AlertToastr from '../../Shared/AlertToastr';
import { sortBy } from '../../../utils/SortUtils';
import { OutboundType } from '../../../enums/OutboundType';

class PackageWorkflow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryDate: '',
            printOptions: {},
            provider: {},
            practice: {},
            providers: [],
            practices: [],
            showPrintModal: false,
            alertSort: [],
            querySort: [],
            memberAppointmentSort: [],
            toast: null
        };

        this.defaultConfig = {
            url: '',
            navCol: 12,
            prevLabel: 'Previous',
            nextLabel: 'Next',
            showPrev: true,
            showNext: true
        }
        this.stageConfig = {
            AlertPackages: Object.assign({...this.defaultConfig}, { url: "/PEC?tab=Packages" }),
            MemberSelection: Object.assign({...this.defaultConfig}, { url: "/AlertPackages/" + this.props.routeParams.packageID, showPrev: false }),
            PackageQuerySelection: Object.assign({...this.defaultConfig}, { url: "/AlertPackages/" + this.props.routeParams.packageID + "/PackageQuerySelection" }),
            PackageReviewPrint: Object.assign({...this.defaultConfig}, { url: "/AlertPackages/" + this.props.routeParams.packageID + "/PackageReviewPrint", nextLabel: "Finish" }),
            PackageReview: Object.assign({...this.defaultConfig}, { url: "/AlertPackages/" + this.props.routeParams.packageID + "/PackageReview", showPrev: false, showNext: false }),
            PackageReviewReprint: Object.assign({...this.defaultConfig}, { url: "/AlertPackages/" + this.props.routeParams.packageID + "/PackageReviewReprint",
                prevLabel: "Return to Package Review", nextLabel: "Reprint" }),
        };
    }

    componentWillMount() {
        //redirect to last active workflow screen
        if (this.props.route.stage !== "PackageReview") {
            this.resume();
        }

        if (this.props.practices) {
            this.setProvidersAndPracticeData(this.props.practices);
        } else {
            axios.get('/practice/')
            .then((response) => {
                this.setProvidersAndPracticeData(response.data);
            })
            .catch((error) => {
                this.state.toast('error', 'Could not load practice data.', 'Error');
            });
        }

        this.getPackageData();

        //store current workflow stage
        if (this.props.route.stage !== "PackageReviewReprint") {
            this.setActiveWorkflowStage(this.props.route.stage);
        }
    }
 
    componentWillReceiveProps(nextProps) {
        if(this.props.route.stage !== nextProps.route.stage && this.props.route.stage !== "PackageReviewReprint") {
            this.setActiveWorkflowStage(nextProps.route.stage);
        }
    }

    setProvidersAndPracticeData = (pracs) => {
        const practices = pracs.filter((practice) => {
            return practice.outboundTypeID !== OutboundType.EPEC;
        });

        const providers = getUniqueProviderOrgs(pracs).filter((provider) => {
            return practices.find(practice => practice.providerOrganizationID === provider.id);
        });

        this.setState({ providers, practices });

        //get unique provider organizations & associated descriptions via practice data
        function getUniqueProviderOrgs(practiceData) {
            return practiceData.reduce((uniqueProviderOrgs, practice) => {
                if (uniqueProviderOrgs.findIndex(p => p.id === practice.providerOrganizationID) === -1) {
                    uniqueProviderOrgs.push({
                        id: practice.providerOrganizationID,
                        description: practice.providerOrganizationDescription + " - " + practice.providerOrganizationID
                    });
                }
                return uniqueProviderOrgs;
            }, []).sort(sortBy('description'));
        }
    }

    getPackageData = (callback) => {
        axios.get('/packages/' + this.props.routeParams.packageID)
        .then((response) => {
            this.setState({
                deliveryDate: response.data.deliveryDate,
                packageType: response.data.packageTypeID,
                provider: {
                    id: response.data.providerOrganizationID,
                    desc: response.data.providerOrganization
                },
                practice: {
                    groupingID: response.data.groupingID,
                    desc: response.data.practiceName
                },
                printOptions: {
                    includeBlankAlerts: response.data.includeBlankAlerts,
                    includeHeaders: response.data.includeHeaders
                },
                memberCount: response.data.numberOfMembers,
                queryCount: response.data.numberOfQueries
            }, () => {
                if (callback) {
                    callback();
                }
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load package data.', 'Error');
            this.props.router.push("/PEC?tab=Packages");
        });
    }

    //redirect to last active workflow screen
    resume = () => {
        const activeStages = JSON.parse(localStorage.getItem('activeWorkflowStages'));
        let resumeStage;

        //find active stage via package ID
        if (activeStages) {
            resumeStage = activeStages.filter(p => {
                return p.packageID === this.props.routeParams.packageID
            });
            if (resumeStage.length === 1) {
                resumeStage = resumeStage[0].activeStage;
            }
        }

        if (typeof resumeStage === 'string') {
            this.props.router.push(this.stageConfig[resumeStage].url);
        }
        else {
            this.props.router.push("/AlertPackages/" + this.props.routeParams.packageID);
        }
    }

    setActiveWorkflowStage = (stage) => {
        const storedActiveStages = JSON.parse(localStorage.getItem('activeWorkflowStages'));
        let activeStages = storedActiveStages ? storedActiveStages : [];
        const currentDate = new Date();
        const oneDayAgo = new Date(new Date().setDate(currentDate.getDate() - 1)).getDate();

        const activeStage = {
            packageID: this.props.routeParams.packageID,
            lastActive: currentDate,
            activeStage: stage
        };

        //remove active stages older than one day
        activeStages = activeStages.filter(p => {
            return new Date(p.lastActive).getDate() > oneDayAgo;
        });

        //upsert existing active stage
        const target = activeStages.findIndex(p => {
            return p.packageID === activeStage.packageID;
        })
        if (target > -1) {
            activeStages[target] = activeStage;
        }
        else {
            activeStages.push(activeStage);
        }

        localStorage.setItem('activeWorkflowStages', JSON.stringify(activeStages));
    }

    //return to package search screen
    goHome = () => {
        this.props.router.push("/PEC?tab=Packages");
    }

    //navigate to previous workflow screen
    goBack = () => {
        let previousStage = '';
        if (this.state.packageType === 'PrePrint' && this.props.route.stage === 'PackageQuerySelection') {
            previousStage = 'MemberSelection';
        }
        else if (this.state.packageType === 'PrePrint' && this.props.route.stage === 'PackageReviewPrint') {
            previousStage = 'PackageQuerySelection';
        }
        else {
            previousStage = this.props.route.prev;
        }
        this.props.router.push(this.stageConfig[previousStage].url);
    }

    //navigate to next workflow screen
    goForward = () => {
        if (this.props.route.stage === 'PackageReviewPrint' || this.props.route.stage === 'PackageReviewReprint') {
            this.setState({ showPrintModal: true });
        }
        else {
            this.props.router.push(this.stageConfig[this.props.route.next].url);
        }
    }

    goToPackageReview = () => {
        this.getPackageData(this.togglePrintModal);
        this.props.router.push(`/AlertPackages/${this.props.routeParams.packageID}/PackageReview`);
    }

    goToPackageReviewReprint = () => {
        this.props.router.push(`/AlertPackages/${this.props.routeParams.packageID}/PackageReviewReprint`)
    }

    togglePrintModal = () => {
       this.setState({ showPrintModal: !this.state.showPrintModal });
    }

    setAlertSort = (sort) => {
        this.setState({ alertSort: sort });
    }

    setQuerySort = (sort) => {
        this.setState({ querySort: sort });
    }

    setMemberAppointmentSort = (sort) => {
        this.setState({ memberAppointmentSort: sort });  
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    renderStage = () => {
        switch(this.props.route.stage) {
            case "MemberSelection":
                if(this.state.packageType === undefined)
                    return;

                if (this.state.packageType === 'PrePrint') {
                    return (
                        <MemberSelectionContainer packageID={this.props.routeParams.packageID} packageType={this.state.packageType}
                            provider={this.state.provider} practice={this.state.practice} providers={this.state.providers} practices={this.state.practices} toast={this.state.toast} />
                    );
                }
                else {
                    return (
                        <MemberSelectionCombinedContainer packageID={this.props.routeParams.packageID} packageType={this.state.packageType}
                            setMemberAppointmentSort={this.setMemberAppointmentSort} defaultMemberAppointmentSort={this.state.memberAppointmentSort}
                            provider={this.state.provider} practice={this.state.practice} providers={this.state.providers} practices={this.state.practices} toast={this.state.toast} />
                    );
                }
            case "PackageQuerySelection":
                return (
                    <PackageQuerySelection packageID={this.props.routeParams.packageID} provider={this.state.provider} practice={this.state.practice} 
                        providers={this.state.providers} practices={this.state.practices} packageType={this.state.packageType} toast={this.state.toast} />
                )
            case "PackageReviewPrint":
                return (
                    <PackageReviewPrint packageID={this.props.routeParams.packageID} provider={this.state.provider} setAlertSort={this.setAlertSort}
                        setQuerySort={this.setQuerySort} defaultAlertSort={this.state.alertSort} defaultQuerySort={this.state.querySort}
                        onModalComplete={this.goToPackageReview} practice={this.state.practice} providers={this.state.providers} practices={this.state.practices}
                        packageType={this.state.packageType} togglePrintModal={this.togglePrintModal} showPrintModal={this.state.showPrintModal} reprint={false}
                        toast={this.state.toast} />
                );
            case "PackageReview":
                return (
                    <PackageReview packageID={this.props.routeParams.packageID} provider={this.state.provider} practice={this.state.practice} providers={this.state.providers}
                        practices={this.state.practices} deliveryDate={this.state.deliveryDate} printOptions={this.state.printOptions} memberCount={this.state.memberCount}
                        queryCount={this.state.queryCount} packageType={this.state.packageType} goToPackageReviewReprint={this.goToPackageReviewReprint}
                        toast={this.state.toast} />
                );
            case "PackageReviewReprint":
                return (
                    <PackageReviewPrint packageID={this.props.routeParams.packageID} provider={this.state.provider} setAlertSort={this.setAlertSort}
                        setQuerySort={this.setQuerySort} defaultAlertSort={this.state.alertSort} defaultQuerySort={this.state.querySort}
                        onModalComplete={this.goToPackageReview} practice={this.state.practice} providers={this.state.providers} practices={this.state.practices}
                        packageType={this.state.packageType} togglePrintModal={this.togglePrintModal} showPrintModal={this.state.showPrintModal}
                        reprint={true} toast={this.state.toast} />
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <Grid fluid>
                {this.renderStage()}
                <Navbar fixedBottom fluid>
                    <WorkflowNav home={this.goHome} prev={this.goBack} next={this.goForward} prevLabel={this.stageConfig[this.props.route.stage].prevLabel}
                        nextLabel={this.stageConfig[this.props.route.stage].nextLabel} showPrev={this.stageConfig[this.props.route.stage].showPrev}
                        showNext={this.stageConfig[this.props.route.stage].showNext} />
                </Navbar>
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        );
    }
}

export default PackageWorkflow;

import React from 'react';
import { Panel } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class Notes extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    // TODO: these are all mockup data for now
    render() {
        return ( 
            <Panel id = 'notes'>
                <Panel.Body>
                <div className='sectionHeader'>
                    Notes
                </div>
                {
                    this.props.memberLoaded && !isEmpty(this.props.data) ?
                    this.props.data.map((x, index) => {
                        return (
                            <div key={index}>
                                <div className='sectionHeader'>
                                    {!isEmpty(x.note_type)  ? <div>{formatDatetimeStringWithPrefixZero(x.note_date) +' - '+ x.note_type}</div>
                                                            :  <div>{formatDatetimeStringWithPrefixZero(x.note_date)}</div>}
                                </div>
                                <div>
                                    <p>{x.Note}</p>
                                </div> 
                            </div>
                        )
             }) : <NoData /> 
            }
            </Panel.Body> 
            </Panel>
        )
    }
}


export default Notes;
import React from 'react';

class MemberDemographicInfo extends React.Component {
    render() {
        const member = this.props.memberDemo ? this.props.memberDemo.member : null;
        return (
            <div>
                <div className="memberInfo">    
                    <h4><b>Member Information</b></h4>
                    {
                        member &&
                        <ul>
                            <li><b>Address 1:&nbsp;&nbsp;</b>{member.address.address1}</li>
                            <li><b>Address 2:&nbsp;&nbsp;</b>{member.address.address2}</li>
                            <li>
                                <b>City:&nbsp;&nbsp;</b>{member.address.city}&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>State:&nbsp;&nbsp;</b>{member.address.state}&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Zip:&nbsp;&nbsp;</b>{member.address.zipCode}
                            </li>
                        </ul>
                    }
                </div>
                <hr />
                <div className="eligibilityInfo">
                    <h4><b>Eligibility Information</b></h4>
                    {
                        member &&
                        <ul>
                            <li><b>CMS Eligibility:&nbsp;&nbsp;</b>{this.props.memberDemo.cmsEligibility}</li>
                            <li><b>Plan Eligibility:&nbsp;&nbsp;</b>{this.props.memberDemo.planEligibility}</li>
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

export default MemberDemographicInfo;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import { formatISODateOnly }  from '../../../utils/DateUtils';

//todo: move save button to footer? see elsewhere
class CoderQAAddQueueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordTypes: [],
            initialCoder: 0,
            minCompletedDate: getDefaultMinCompletedDate(),
            maxCompletedDate: getDefaultMaxCompletedDate(),
            project: '',
            selectedRecordTypes: [],
            reviewPercentage: '',
            numberOfReviews: '',
            hccAbstraction: '',
            cdiAbstraction: '',
            loaded: true
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        this.datepickerMin = null;
        this.datepickerMax = null;

        function getDefaultMinCompletedDate() {
            // default to first day of the previous month
            const minCompletedDate = new Date();
            if (minCompletedDate.getMonth() === 0) {
                minCompletedDate.setMonth(11);
                minCompletedDate.setYear(minCompletedDate.getYear() - 1);
            }
            else {
                minCompletedDate.setMonth(minCompletedDate.getMonth() - 1);
            }
            minCompletedDate.setDate(1)
            return minCompletedDate.toISOString();
        }

        function getDefaultMaxCompletedDate() {
            // default to last day of the previous month
            const maxCompletedDate = new Date();
            maxCompletedDate.setDate(0);
            return maxCompletedDate.toISOString();
        }
    }

    saveQueue = (calculateOnly = false) => {
        axios.post(`/coderQA/?calcOnly=${calculateOnly}`, {
            projectID: this.state.project ? this.state.project.value : null,
            completionDateStart: this.state.minCompletedDate ? formatISODateOnly(new Date(this.state.minCompletedDate)) : null,
            completionDateEnd: this.state.maxCompletedDate ? formatISODateOnly(new Date(this.state.maxCompletedDate)) : null,
            hccAbstraction: this.state.hccAbstraction ?? null,
            cdiAbstraction: this.state.cdiAbstraction ?? null,
            recordTypeIDs: this.state.selectedRecordTypes && this.state.selectedRecordTypes.length > 0 ? this.state.selectedRecordTypes.map(x => x.value) : null,
            medicalRecordReviewFullCount: this.state.numberOfReviews ? this.state.numberOfReviews : null,
            medicalRecordReviewPercentage: this.state.reviewPercentage ? this.state.reviewPercentage : null,
            medicalCoderUserID: this.state.initialCoder && this.state.initialCoder !== 0 ? this.state.initialCoder.value : null,
            cancelToken: this.source.token
        })
        .then((response) => {
            if (calculateOnly) {
                this.setState({ reviewPercentage: response.data.medicalRecordReviewPercentage });
                this.setState({ numberOfReviews: response.data.medicalRecordReviewFullCount });
            }
            else {
                this.props.addQueueToState(response.data);
                this.props.toast('success', 'Queue created.', '');
                this.props.handleModalToggle();
            }
        })
        .catch((error) => {
            if (error.response && error.response.data === 'There are not enough reviews matching the criteria to fulfill your request') {
                this.props.toast('error', 'Could not create queue. Not enough matching reviews.', 'Error');
            }
            else {
                this.props.toast('error', 'Could not create queue.', 'Error');
            }
        });
    }

    handleInitialCoderChange = (e) => {
        this.setState({ initialCoder: e ? e : 0 });
    }

    handleMinCompletedDateChange = (e) => {
        if (e && this.validateCompletedDateRange(e, this.state.minCompletedDate)) {
            this.setState({ minCompletedDate: e });
        }
        else {
            this.setState({ minCompletedDate: '' });
        }
    }

    handleMaxCompletedDateChange = (e) => {
        if (e && this.validateCompletedDateRange(this.state.maxCompletedDate, e)) {
            this.setState({ maxCompletedDate: e });
        }
        else {
            this.setState({ maxCompletedDate: '' });
        }
    }

    validateCompletedDateRange = (min, max) => {
        const minDate = min ? new Date(min) : null;
        const maxDate = max ? new Date(max) : null;

        if (minDate && maxDate) {
            if (minDate >= maxDate) {
                this.props.toast('error', "Max completed date must be greater than or equal to the min completed date.", 'Error');
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    handleProjectChange = (e) => {
        this.setState({ project: e }, this.getRecordTypes);
    }

    getRecordTypes = () => {
        if (this.state.project) {
            axios.get(`/projects/${this.state.project.value}/referenceData?referenceDataType=medicalRecordType`)
            .then((response) => {
                if (response.data && response.data.referenceDataList && response.data.referenceDataList[0] && response.data.referenceDataList[0].referenceData) {
                    this.setState({
                        recordTypes: response.data.referenceDataList[0].referenceData,
                        selectedRecordTypes: this.getDropdownOptions(response.data.referenceDataList[0].referenceData)
                    });
                }
            })
            .catch((error) => {
                this.props.toast('error', 'Could not load record types.', 'Error');
            });
        }
        else {
            this.setState({ recordTypes: [] });
        }
    }

    handleRecordTypeChange = (e) => {
        this.setState({ selectedRecordTypes: e });
    }

    handleNumberOfReviewsChange = (e) => {
        this.setState({ numberOfReviews: e.target.value })
    }

    handleReviewPercentageChange = (e) => {
        this.setState({ reviewPercentage: e.target.value })
    }

    handleHCCAbstractionChange = (e) => {
        this.setState({ hccAbstraction: e.target.value })
    }

    handleCDIAbstractionChange = (e) => {
        this.setState({ cdiAbstraction: e.target.value })
    }

    getInitialCoderDropdownOptions = () => {
        return [{ label: 'All Coders', value: 0 }, ...this.getUserDropdownOptions(this.props.initialCoders)];
    }

    getUserDropdownOptions = (users) => {
        return users.map((x) => {
            return {
                label: x.fullName,
                value: x.userID
            };
        });
    }

    getProjectDropdownOptions = (projects) => {
        return projects.map((proj) => {
            return {
                label: proj.projectName,
                value: proj.projectID
            };
        });
    }

    getDropdownOptions = (referenceData) => {
        return referenceData.map((x) => {
            return {
                label: x.description,
                value: x.value
            };
        });
    }

    validateRequiredFieldsForSave = () => {
        return this.state.project && this.state.project.value && this.state.minCompletedDate && this.state.maxCompletedDate
            && this.state.selectedRecordTypes && this.state.selectedRecordTypes.length > 0 && this.state.numberOfReviews && this.state.numberOfReviews > 0;
    }

    validateRequiredFieldsForCalculate = () => {
        return this.state.project && this.state.project.value && this.state.minCompletedDate && this.state.maxCompletedDate
            && this.state.selectedRecordTypes && this.state.selectedRecordTypes.length > 0;
    }

    render() {
        return (
            <div>
                <Modal show={this.props.visible} onHide={this.props.handleModalToggle} className="modal-wide" keyboard>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Queue</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginBottom: '1.6rem' }}>
                            <Row>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Medical Coder
                                        </ControlLabel>
                                        <Select value={this.state.initialCoder} options={this.getInitialCoderDropdownOptions()}
                                            onChange={this.handleInitialCoderChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField" style={{ marginRight: '1rem' }}>Min Completed Date</ControlLabel>
                                        <DatePicker value={this.state.minCompletedDate} onChange={this.handleMinCompletedDateChange} style={{ zIndex: 'auto' }}
                                            ref={(ref) => this.datepickerMin = ref}  />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField" style={{ marginRight: '1rem' }}>Max Completed Date</ControlLabel>
                                        <DatePicker value={this.state.maxCompletedDate} onChange={this.handleMaxCompletedDateChange} style={{ zIndex: 'auto' }}
                                            ref={(ref) => this.datepickerMax = ref}  />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Project</ControlLabel>
                                        <Select value={this.state.project ? this.state.project.value : ''} options={this.getProjectDropdownOptions(this.props.projects)}
                                            onChange={this.handleProjectChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Record Types</ControlLabel>
                                        <Select multi value={this.state.selectedRecordTypes} options={this.getDropdownOptions(this.state.recordTypes)}
                                            onChange={this.handleRecordTypeChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Number of Reviews</ControlLabel>
                                        <FormControl type="number" value={this.state.numberOfReviews} onChange={this.handleNumberOfReviewsChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={2}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Review Percentage
                                        </ControlLabel>
                                        <FormControl type="number" value={this.state.reviewPercentage} onChange={this.handleReviewPercentageChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={2} style={{ paddingLeft: '0rem'}}>
                                    <FormGroup>
                                        <ControlLabel>
                                            &nbsp;
                                        </ControlLabel>
                                        <Button onClick={() => this.saveQueue(true)} disabled={!this.validateRequiredFieldsForCalculate()} style={{ display: 'block' }}>
                                            <Glyphicon glyph="scale" style={{ marginRight: '0.2rem' }} /> Calculate %
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col xs={2} style={{ paddingLeft: '0rem'}}>
                                    <FormGroup>
                                        <ControlLabel>
                                            HCC Abstraction
                                        </ControlLabel>
                                        <FormControl type="number" value={this.state.hccAbstraction} onChange={this.handleHCCAbstractionChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={2} style={{ paddingRight: '0rem'}}>
                                    <FormGroup>
                                        <ControlLabel>
                                            CDI Abstraction
                                        </ControlLabel>
                                        <FormControl type="number" value={this.state.cdiAbstraction} onChange={this.handleCDIAbstractionChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <Button onClick={() => this.saveQueue()} disabled={!this.validateRequiredFieldsForSave()} style={{ marginTop: '2.4rem'}} >
                                        <Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} /> Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.handleModalToggle}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default CoderQAAddQueueModal;
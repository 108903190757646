// exports Securable - an immutable object 'enum'
// usage: import { Securable } from '../../enums/Securable';
const securables = {
    InitialReviewTab: 1,
    QATab: 2,
    IncompleteTab: 3,
    CompleteTab: 4,
    MedicalDirectorTab: 5,
    CDIAlertButton: 6,
    CoderQATab: 7,
    RADVButton: 8,
    ReportsTab: 9,
    AlertPackagesButton: 10,
    RemotePecEngagementAdminTab: 11,
    MRR: 12,
    PEC: 13,
    RADV: 14,
    PreExtractQAButton: 15,
    IncompleteQueueSearch: 16,
    Administration: 17,
    FaxStatusTab: 18,
    ScanRosterTab: 19,
    MACommercialToggle: 20,
    AlertManagement: 21,
    ChartUploadTab:39,
    // project securables haven't been added here, as these are client-specific
    ProjectStatusReport: 20001,
    RADVAuditHCCStatusDrillDown: 20002,
    CoderQAScoringReport: 20003,
    RiskMitigationReport: 20004,
    ClaimsAuditReport: 20005,
    ClaimsAuditReportDiagDrillDown: 20006,
    FinalMeasureReport: 20007,
    StarsProductivityReport: 20008,
    ExtractReviewReport: 20009,
    ScanCSVRoster: 20010,
    CoderPerformanceReport: 20011,
    CoderPerformanceReportDetail: 20012,
    ProjectImprovementReport: 20013,
    ReviewCommentsReport: 20014
};
Object.freeze(securables);
export const Securable = securables;
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import Select from 'react-select';


const EscalationQueueFilters = (props) => {
    const handleMRRIDChange = (e) => {
        props.handleFilterChange({ medicalRecordReviewID : e ? e.target.value: '' })
    }

    const handleCampaignNameChange = (e) => {
        props.handleFilterChange({ campaignName: e ? e.target.value : '' });
    }

    const handleProjectNameChange = (e) => {
        props.handleFilterChange({ projectName: e ? e.target.value : '' });
    }

    const handlePracticeNameChange = (e) => {
        props.handleFilterChange({ practiceName: e ? e.target.value : '' });
    }

    const handleProviderNameChange = (e) => {
        props.handleFilterChange({ providerName: e ? e.target.value : '' });
    }

    const handleCapturedDiagsChange = (e) => {
        props.handleFilterChange({ capturedDiags: e ? e.value : '' });
    }

    const handleIssueTypeChange = (e) => {
        props.handleFilterChange({ issueType: e ? e.value : '' });
    }

    const handleEscalationReasonChange = (e) => {
        props.handleFilterChange({ escalationReason: e ? e.target.value : '' });
    }
    
    return (
        <div>
            <Row>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>MRR ID</ControlLabel>
                        <FormControl type="text" value={props.medicalRecordReviewID} onChange={handleMRRIDChange}
                            maxLength="255" />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Campaign</ControlLabel>
                        <FormControl type="text" value={props.campaignName} onChange={handleCampaignNameChange}
                            maxLength="255"/>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Project</ControlLabel>
                        <FormControl type="text" value={props.projectName} onChange={handleProjectNameChange}
                            maxLength="255"/>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Practice Name</ControlLabel>
                        <FormControl type="text" value={props.practiceName} onChange={handlePracticeNameChange}
                            maxLength="255" />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Provider</ControlLabel>
                        <FormControl type="text" value={props.providerName} onChange={handleProviderNameChange}
                            maxLength="50" />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Description</ControlLabel>
                        <FormControl type="text" value={props.escalationReason} onChange={handleEscalationReasonChange}
                            maxLength="500" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Issue Type</ControlLabel>
                        <Select value={props.issueType} options={props.issueTypeOptions}
                            onChange={handleIssueTypeChange}  />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel>Captured Diags</ControlLabel>
                        <Select value={props.capturedDiags} options={props.capturedDiagsOptions} ///
                            onChange={handleCapturedDiagsChange} />
                    </FormGroup>
                </Col>                
            </Row>
            <Row style={{ marginBottom: '2rem' }}>
                <Col xs={1}>
                    <Button onClick={props.handleSearch} bsStyle="primary">
                        <Glyphicon glyph="search" /> Search
                    </Button>
                </Col>
                <Col xs={1}>
                    <Button bsStyle="default" onClick={props.handleBulkModal}>
                        <Glyphicon glyph="save" /> Bulk Update - Resolution Action
                    </Button>                     
                </Col>
            </Row>
        </div>
    ); 
}

export default EscalationQueueFilters;
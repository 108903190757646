import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import '../../styles/Report.css';


const HostedMembersHomePlan = () => {

    useEffect(() => {
        const lineOfBusinessValue = localStorage.getItem('lineOfBusiness');
        if (lineOfBusinessValue === "Commercial") {
            window.location.href = '/MRR';
        }
    }, []);

    return (
        <div className="hostPlan reportContainer">
            <div className="innerReportContainer" style={{ minHeight: '250px', marginLeft: '1%', marginRight: '1%' }}>
                <header>
                    <Col className="title" >
                        <h3> Hosted Members by Home Plan Report </h3>
                    </Col>
                </header>

                <section className="table-section">
                    <BootstrapTable data={''} maxHeight='400px'>
                        <TableHeaderColumn dataField="HostPlanID" isKey={true} hidden>Host Plan ID </TableHeaderColumn>
                        <TableHeaderColumn dataField="HomePlan" dataSort width='120px'>Home Plan</TableHeaderColumn>
                        <TableHeaderColumn dataField="HostedMembers" dataSort width='120px'>Hosted Members</TableHeaderColumn>
                    </BootstrapTable>
                </section>
                <div className="export-table-data">
                    <LongRunningDownloadAnchor linkText="Download .CSV"
                        displayCSVDownloader={true}
                    />
                </div>
            </div>
        </div >
    )
}

export default HostedMembersHomePlan;

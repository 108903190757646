import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import { tickMark } from '../../../constants/UnicodeConstants';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class LabResults extends React.Component {
    constructor(props) {
        super(props);
        
        this.columnHeaders = [
            ['RESULT'],
            ['TEST'],
            ['VALUE'],
            ['NORMAL'],
            ['REF. RANGE'],
            ['CODE'],
            ['SYSTEM'],
            ['ORDER'],
            ['NAME'],
            ['STATUS']
        ]
        // TODO: these are all mockup data for now
        
    }

    render() {
        return (
        <Panel id='labresults'>
            <Panel.Body>
                <div className='sectionHeader'>
                    Lab Results
                </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                            <Table>
                                <thead>
                                    <tr>
                                        {
                                            this.columnHeaders.map((x, index) => {
                                                return (x.length > 1 ?
                                                    <th className='rawTableColumnHeader' key={index}>
                                                        <div>{x[0]}</div>
                                                        <div>{x[1]}</div>
                                                    </th>
                                                    : <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.data.map((x, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='width10'>
                                                        <div>{formatDatetimeStringWithPrefixZero(x.result_datetime)}</div>
                                                    </td>
                                                    <td className='width15'>
                                                        <div>{x.lab_test_name}</div>
                                                    </td>
                                                    <td className='width10'>
                                                        <div>{x.value +' '+ x.observation_unit}</div>
                                                    </td>
                                                    <td className='width10'>
                                                        <div>
                                                            {x.normalcy_flag === "T" ? <div> {tickMark} </div> : <div> </div>}   
                                                        </div>
                                                    </td>
                                                    <td className='width15'>
                                                        <div>{x.reference_range}</div>
                                                    </td>
                                                    <td className='width10'>
                                                        <div>{x.result_code}</div>
                                                    </td>
                                                    <td className='width10'>
                                                        <div>{x.coding_system}</div>
                                                    </td>
                                                    <td className='width10'>
                                                        <div>{formatDatetimeStringWithPrefixZero(x.order_date)}</div>
                                                    </td>
                                                    <td className='width15'>
                                                        <div>{x.order_name}</div>
                                                    </td>
                                                    <td className='width10'>
                                                        <div>{x.order_status}</div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table> : <NoData />
                    }    
            </Panel.Body>
        </Panel>
        )
    }
}

export default LabResults;
export const setDiagFormData = diagFormData => ({
    type: 'SET_DIAG_FORM',
    diagFormData
});

export const setDiagIDList = diagIDList => ({
    type: 'SET_DIAG_ID_LIST',
    diagIDList
});

export const setClaimsList = claimsList => ({
    type: 'SET_CLAIMS_LIST',
    claimsList
});

export const setClaimsDiagInitiatorIDList = claimsDiagInitiatorIDList => ({
    type: 'SET_CLAIMS_DIAG_INITIATOR_ID_LIST',
    claimsDiagInitiatorIDList
});

export const setTriggerDiagRefresh = triggerDiagRefresh => ({
    type: 'SET_TRIGGER_DIAG_REFRESH',
    triggerDiagRefresh
});

export const setCurrentUser = currentUser => ({
    type: 'SET_CURRENT_USER',
    currentUser
});
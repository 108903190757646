import React from 'react';
import { Button, Col, ControlLabel, FormControl, Glyphicon, Table } from 'react-bootstrap';
import axios from 'axios';
import '../../styles/Report.css';
import ReportDrillDownModal from './ReportDrillDownModal';

class RiskMitigationReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectRecords: [],
            projectName: "",
            projectID: null,
            reportData: [],
            reportType: null,
            open: false,
            copied: "",
            copiedTimer: false,
            currentAuditProject: {
                projectName: "",
                projectID: null
            }
        }
    }
  
    componentWillMount() {
        axios.get('/applicationreports/4/projects')
        .then((response) => {
            this.setState({ projectRecords: response.data });
        });
        // .catch((error) => {
        //     //todo: add error notification
        // });

        if (localStorage.currentAuditProject) {
            const project = JSON.parse(localStorage.currentAuditProject);
            this.setState({ currentAuditProject: project });
            this.getRiskMitigationReportData(project.projectID);
        }
    }

    handleProjectChange = (evt) => {
        const projectName = [...evt.target.options].filter(o => o.selected).map(o => o.text)[0];
        const currentAuditProject = { projectID: evt.target.value, projectName: projectName };
        localStorage.currentAuditProject = JSON.stringify(currentAuditProject);
        this.setState({ currentAuditProject: currentAuditProject, projectID: currentAuditProject.projectID });
        this.getRiskMitigationReportData(currentAuditProject.projectID);
    }

    getRiskMitigationReportData = (currentProjectID) => {
        if (currentProjectID > 0) {
            axios.get('/Reports/RiskMitigation/RiskMitigationAuditReport?projectID=' + currentProjectID)
            .then((response) => {
                this.setState({ reportData: response.data,projectID: currentProjectID });
            });
            // .catch((error) => {
            //     //todo: add error notification
            // })
        }
        else {
            this.setState({ projectID: null });
        }
    }

    toggleCollapsibleRows = () => {
        this.setState({ open: !this.state.open })
    }

    handleRiskMitigationCellClick = (displayValue, diagReportFilter, modalTitle, initiatorTypeID, commentCD) => {
        return <ReportDrillDownModal
            displayValue={displayValue} projectID={this.state.projectID}
            diagReportFilter={diagReportFilter} modalTitle={modalTitle}
            initiatorTypeID={initiatorTypeID} commentCD={commentCD} reportType={"RiskMitigation"} displayDownloadButton={diagReportFilter === "CompletedHccs" && initiatorTypeID === 3}/>
    }
  
    truncateDecimalPlace = (value, decimalPlaces) => {
        return typeof(value) !== "number"
            ? value
            : (value % 1 === 0
                ? value
                : value.toFixed(decimalPlaces ? decimalPlaces : 2));
    }

    copyToClipboard = (e) => {
        const element = this.table;
        const selection = window.getSelection();
        const range = window.document.createRange();
        try {
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.setState({ copied: "Copied.", copiedTimer: true });
            setTimeout(() => {
                this.setState({ copiedTimer: false })
            }, 3000);
        }
        catch (e) {
            this.setState({ copied: "Could not copy. Try again." });
        }
        selection.removeAllRanges();
    }

    render() {
        const reportData = this.state.reportData;
        return (
            <div className="reportContainer">
                <div className="innerReportContainer">
                    <br/>
                    <header>
                        <Col style={{marginLeft: 'auto', marginRight: 'auto', width: '300px'}}>
                            <h3>Risk Mitigation Audit Report</h3>
                        </Col>
                    </header>

                    <div style={{float: 'left', width: '25%', marginBottom: '20px'}}>
                        <ControlLabel>Project:</ControlLabel>
                        <FormControl componentClass="select" placeholder="select" value={this.state.currentAuditProject.projectID !== null ? this.state.currentAuditProject.projectID : "Select"} onChange={this.handleProjectChange}>
                            <option value="Select">Select</option>
                            {this.state.projectRecords.map((pr) => <option key={pr.projectID} value={pr.projectID}>{pr.projectName}</option>)}
                        </FormControl>
                    </div>

                    <div style={{float: 'right'}}>
                        <Button bsStyle="info" onClick={this.copyToClipboard}>Copy</Button>
                        <p style={{display: this.state.copiedTimer === true ? 'block' : 'none'}}>{this.state.copied !== "" ?  this.state.copied : ""}</p>
                    </div>

                    <div className="copyContent" ref={(table) => this.table = table}>
                    {/*<Table bordered className="riskMitigationTable">*/}
                        <Table bordered className="riskMitigationTable" style={{display: this.state.projectID !== null ? 'table' : 'none'}}>
                            {/*Target data section*/}
                            <thead>
                                <tr>
                                    <th colSpan="4">
                                        Target Data
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2" style={{width: "50%"}}>Total Members</td>
                                    <td colSpan="2">{reportData.totalMembers}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Total Charts</td>
                                    <td colSpan="2">{reportData.totalCharts}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">HCCs Completed/Total HCCs</td>
                                    <td colSpan="2">{this.handleRiskMitigationCellClick(reportData.completedHCCs, "CompletedHccs", "HCCs Completed", 3)} / {this.handleRiskMitigationCellClick(reportData.totalHCCs, "TotalHccs", "Total HCCs", 3)}</td>
                                </tr>
                                <tr style={{cursor: 'pointer'}} onClick={this.toggleCollapsibleRows}>
                                    <td colSpan="2">
                                        <Button className="buttonLink" onClick={this.toggleCollapsibleRows}>
                                            Total HCCs Approved
                                            <Glyphicon style={{ display: !this.state.open ? '' : 'none' }} glyph="triangle-right" />
                                            <Glyphicon style={{ display: this.state.open ? '' : 'none' }} glyph="triangle-bottom" />
                                        </Button>
                                    </td>
                                    <td style={{width: "25%"}}><Button className="buttonLink" onClick={this.toggleCollapsibleRows}>{this.handleRiskMitigationCellClick(reportData.totalHCCsApproved, "ApprovedHccs", "Total HCCs Approved", 3)}</Button></td>
                                    <td>{this.truncateDecimalPlace(reportData.totalHCCsApprovedPercent)}%</td>
                                </tr>
                            </tbody>
                            {/*Target data collapsible rows*/}
                            <tbody style={{display: this.state.open ? '' : 'none'}}>
                                {reportData.riskMitigationApprovedHCCs &&
                                reportData.riskMitigationApprovedHCCs.map((row, i) => {
                                    return (
                                        <tr style={{background: '#f3f3f3'}} key={i}>
                                            <td colSpan="2" style={{paddingLeft: '5%'}}>{row.commentText}</td>
                                            <td style={{width: "25%"}}>{this.handleRiskMitigationCellClick(row.totalOccurrencesByProject, "ApprovedHccs", row.commentText, 3, row.commentDefinitionID)}</td>
                                            <td>{this.truncateDecimalPlace(row.totalPercent)}%</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colSpan="2">Total HCCs Not Approved</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalHCCsNonApproved, "NonApproved", "Total HCCs Not Approved", 3)}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalHCCsNonApprovedPercent)}%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Total Optimization</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalOptimization, "Optimization", "Total Optimization", 1)}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalOptimizationPercent)}%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" style={{paddingLeft: '5%'}}>Total Signature Impaired</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalOptimizedSignatureImpaired, "Optimization", "Total Signature Impaired", 1, 201)}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalOptimizedSignatureImpairedPercent)}%</td>
                                </tr>
                            </tbody>

                            {/*Not Approved Breakdown section*/}
                            <thead style={{display: reportData.totalHCCsNonApproved > 0 ? 'table-row-group' : 'none'}}>
                                <tr>
                                    <th colSpan="4">
                                        Not Approved Breakdown
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{display: reportData.totalHCCsNonApproved > 0 ? 'table-row-group' : 'none'}}>
                                <tr>
                                    <td colSpan="2">Total</td>
                                    <td colSpan="2">{this.handleRiskMitigationCellClick(reportData.totalHCCsNonApproved, "NonApproved", "Total HCCs Not Approved", 3)}</td>
                                </tr>
                                {reportData.riskMitigationNotApprovedHCCs &&
                                reportData.riskMitigationNotApprovedHCCs.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            <td colSpan="2" style={{paddingLeft: '5%'}}>{row.commentText}</td>
                                            <td>{this.handleRiskMitigationCellClick(row.totalOccurrencesByProject, "NonApproved", row.commentText, 3, row.commentDefinitionID)}</td>
                                            <td>{this.truncateDecimalPlace(row.totalPercent)}%</td>
                                        </tr>
                                    );
                                })}
                            </tbody>

                            {/*Negatively Closed Gaps section*/}
                            <thead style={{display: reportData.totalNegativelyClosedGapsHCCs > 0 ? 'table-row-group' : 'none'}}>
                                <tr>
                                    <th colSpan="4">
                                        Negatively Closed Gaps
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{display: reportData.totalNegativelyClosedGapsHCCs > 0 ? 'table-row-group' : 'none'}}>
                                <tr>
                                    <td colSpan="2">Total HCCs</td>
                                    <td colSpan="2">{this.handleRiskMitigationCellClick(reportData.totalNegativelyClosedGapsCompleted, "TotalCompleteNegativelyClosedGaps", "Total Negatively Closed Gaps Completed")} / {this.handleRiskMitigationCellClick(reportData.totalNegativelyClosedGapsHCCs, "TotalNegativelyClosedGaps", "Total Negatively Closed Gaps HCCs")}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Agree</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalNegativelyClosedGapsAgree, "TotalCompleteNegativelyClosedGapsAgree", "Total Negatively Closed Gaps Agree")}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalNegativelyClosedGapsAgreePercent)}%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Disagree</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalNegativelyClosedGapsDisagree, "TotalCompleteNegativelyClosedGapsDisagree", "Total Negatively Closed Gaps Disagree")}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalNegativelyClosedGapsDisagreePercent)}%</td>
                                </tr>
                            </tbody>

                            {/*Claims Audit section*/}
                            <thead style={{display: reportData.totalClaims > 0 ? 'table-row-group' : 'none'}}>
                                <tr>
                                    <th colSpan="4">
                                        Claims Audit
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{display: reportData.totalClaims > 0 ? 'table-row-group' : 'none'}}>
                                <tr>
                                    <td colSpan="2">Total Claims</td>
                                    <td colSpan="2">{reportData.totalClaims}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Total HCCs</td>
                                    <td colSpan="2">{this.handleRiskMitigationCellClick(reportData.totalClaimsAuditHCCs, "CompletedHccs", "Total Claims Audit HCCs", 2)}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Total HCCs Approved</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalClaimsAuditAgree, "ApprovedHccs", "Total Claims Audit Agree", 2)}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalClaimsAuditAgreePercent)}%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Total HCCs Not Approved</td>
                                    <td>{this.handleRiskMitigationCellClick(reportData.totalClaimsAuditDisagree, "NonApproved", "Total Claims Audit Disagree", 2)}</td>
                                    <td>{this.truncateDecimalPlace(reportData.totalClaimsAuditDisagreePercent)}%</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default RiskMitigationReport;
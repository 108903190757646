import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Row } from 'react-bootstrap';
import CoderQAAddQueueModal from './CoderQAAddQueueModal';
import CoderQATable from './CoderQATable';
import { CoderQAGeneratorType } from '../../../enums/CoderQAGeneratorType';

//todo: queue GET isn't returning any statuses...
class CoderQA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queues: [],
            fracturedQueues: [],
            showAddQueueModal: false
        };
    }

    componentWillMount() {
        this.getQueues();
    }

    //todo: is this "retry" business necessary? leaving it out for now...
    //$scope.getQueues(1, 6);
    // ...
    // if (!data) {
    //     if (retriesRemaining) {
    //         setTimeout(function () { $scope.getQueues(retryInterval * 2, retriesRemaining - 1); }
    //             , retryInterval * 1000)
    //     }
    //     else {
    //         $scope.queues = 'error';
    //         $scope.fracturedQueues = 'error';
    //     }
    // }
    getQueues = () => {
        axios.get('/coderQA')
        .then((response) => {
            this.setState({
                queues: response.data.filter(x => x.coderQAGeneratorTypeID === CoderQAGeneratorType.User_Generated),
                fracturedQueues: response.data.filter(x => x.coderQAGeneratorTypeID === CoderQAGeneratorType.System_Generated)
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load Coder QA queues.', 'Error');
        })
    }

    toggleAddQueueModal = () => {
        this.setState({ showAddQueueModal: !this.state.showAddQueueModal });
    }

    addQueueToState = (row) => {
        if (row.coderQAGeneratorTypeID === CoderQAGeneratorType.User_Generated) {
            this.setState({
                queues: updateQueues([...this.state.queues], row)
            });
        }
        else if (row.coderQAGeneratorTypeID === CoderQAGeneratorType.System_Generated) {
            this.setState({
                fracturedQueues: updateQueues([...this.state.fracturedQueues], row)
            });
        }

        function updateQueues(queues, row) {
            const targetIndex = queues.findIndex(x => x.coderQAQueueID === row.coderQAQueueID);
            if (targetIndex !== -1) {
                queues[targetIndex] = row;
            }
            else {
                queues.push(row);
            }

            return queues;
        }
    }

    render() {
        return (
            <div style={{ marginTop: '2rem' }}>
                <Row style={{ marginTop: '2rem' }}>
                    <Col xs={12}>
                        <h4 style={{ display: 'inline-block' }}>User-Created Queues</h4>
                        <Button onClick={() => this.toggleAddQueueModal()} style={{ float: 'right' }}>
                            <Glyphicon glyph="plus" style={{ marginRight: '0.2rem' }} /> Add Queue
                        </Button>
                    </Col>
                </Row>
                <CoderQATable queues={this.state.queues} addQueueToState={this.addQueueToState} toast={this.props.toast} />
                <Row style={{ marginTop: '2rem' }}>
                    <Col xs={6}>
                        <h4>System-Generated Queues</h4>
                    </Col>
                </Row>
                <CoderQATable queues={this.state.fracturedQueues} addQueueToState={this.addQueueToState} toast={this.props.toast} />
                <CoderQAAddQueueModal visible={this.state.showAddQueueModal} handleModalToggle={this.toggleAddQueueModal}
                    handleSelectSearchResult={this.handleMemberSelect} users={this.props.users} projects={this.props.projects}
                    initialCoders={this.props.initialCoders} addQueueToState={this.addQueueToState} toast={this.props.toast} />
            </div>
        );
    }
}

export default CoderQA;
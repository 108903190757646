import React from 'react';
import axios from 'axios';
import AlertModalMemberSearch from './AlertModalMemberSearch';
import AlertModalMemberTable from './AlertModalMemberTable';

class AlertModalMemberSearchTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberID: "",
            firstName: "",
            lastName: "",
            memberResults: [],
            memberSearchTotalCount: 0,
            page: 1,
            pageSize: 50,
            sortBy: '',
            sortByDesc: false,
            loadingResults: false
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleMemberSearch = () => {
        this.setState({ loadingResults: true });
        axios.get('/alert/GetMemberByPCPSearch/', {
            params: {
                memberFirstName: this.state.firstName,
                memberLastName: this.state.lastName,
                memberID: this.state.memberID,
                sortByProperty: this.state.sortBy,
                sortByDesc: this.state.sortByDesc,
                page: this.state.page,
                pageSize: this.state.pageSize,
                paginationNeeded: true
            },
            cancelToken: this.source.token
        })
        .then((response) => {
            if (!response.data.items.length) {
                this.setState({
                    memberResults: [],
                    memberSearchTotalCount: 0,
                    page: 1,
                    loadingResults: false
                });
                this.props.toast('error', 'No member records with the search criteria were found.', 'Error');
            }
            else {
                this.setState({
                    memberResults: response.data.items,
                    memberSearchTotalCount: response.data.totalItems,
                    page: this.state.page,
                    loadingResults: false
                });
            }
        })
        .catch((error) => {
            this.setState({
                memberResults: [],
                memberSearchTotalCount: 0,
                page: 1,
                loadingResults: false
            });
            this.props.toast('error', 'An error has occurred.', 'Error');
        });
    }

    onPageChange = (page, sizePerPage) => {
        this.setState({
            page: page,
            pageSize: sizePerPage
        }, this.handleMemberSearch);
    }

    onSortChange = (sortName, sortOrder) => {
        this.setState({
            sortBy: sortName,
            sortByDesc: sortOrder === "desc",
            page: 1,
            pageSize: 50,
        }, this.handleMemberSearch);
    }

    render() {
        return (
            <div style={{ margin: "2rem 1rem 0rem" }}>
                <AlertModalMemberSearch memberID={this.state.memberID} firstName={this.state.firstName} lastName={this.state.lastName}
                    handleInputChange={this.handleInputChange} handleMemberSearch={this.handleMemberSearch} />
                <AlertModalMemberTable memberResults={this.state.memberResults} memberSearchTotalCount={this.state.memberSearchTotalCount}
                    page={this.state.page} pageSize={this.state.pageSize} sortBy={this.state.sortBy} sortByDesc={this.state.sortByDesc}
                    loadingResults={this.state.loadingResults} onPageChange={this.onPageChange} onSortChange={this.onSortChange}
                    handleAddMemberClick={this.props.handleAddMemberClick} />
            </div>
        );
    }
}

export default AlertModalMemberSearchTab;
import React from 'react';
import { ToastContainer } from "react-toastr";
// ~wrapper for react-toastr~
// parent/caller requires this:
//    setAddToast = (addToast) => {
//        this.toast = addToast; OR this.setState({ toast: addToast }); (if passing as prop)
//    }
// parent requires component declaration:
//     <AlertToastr setAddToast={this.setAddToast} />
// toasts are generated via:
//    this.toast(type(str), message(str), title(str), (optional)options(obj))
class AlertToastr extends React.PureComponent {
    constructor(props) {
        super(props);
        this.container = null;
        this.defaultOptions = {
            closeButton: true,
            timeOut: 30000,
            extendedTimeOut: 10000
        };
    }

    componentDidMount() {
        this.props.setAddToast(this.toast);
    }

    toast = (type, message, title, options = {}) => {
        switch(type) {
            case 'error':
                this.container.error(
                      message, title, {
                        ...this.defaultOptions, ...options
                    });
                break;
            case 'info':
                this.container.info(
                      message, title, {
                        ...this.defaultOptions, ...options
                    });
                break;
            case 'success':
                this.container.success(
                      message, title, {
                        ...this.defaultOptions, ...options
                    });
                break;
            case 'warning':
                this.container.warning(
                      message, title, {
                        ...this.defaultOptions, ...options
                    });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <ToastContainer ref={ref => this.container = ref}
                className={this.props.className ? this.props.className : "toast-top-right"} />
        );
    }
}

export default AlertToastr;
import React from 'react';
import axios from 'axios';
import Error from '../../Shared/Error';

class TXTViewer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showImageError:false
        }
    }

    componentDidMount() {
        axios.get(this.props.uri)
            .then((response) => {
                const txtEl = document.createElement("div");
                txtEl.innerHTML = response.data;
                document.getElementById("txt").innerHTML = txtEl.innerHTML;
                
            })
            .catch((error) => {
                this.setState({
                    showImageError: true
                });
            });
    }

    render() {
        return (
            <div style={{ height: '800px' }}>
                {
                    <div id="txt">
                    </div>
                }
                {
                    this.state.showImageError &&
                    <Error style={{ marginTop: '5rem' }} />
                }
            </div>
        );
    }
}

export default TXTViewer;
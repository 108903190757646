import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class Assessments extends React.PureComponent {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            ['ASSESSMENT'],
            ['RESULT']
        ]
    }
    
    render() {
        return (
            <Panel id="assessments">
                <Panel.Body>
                    <div className='sectionHeader'>
                        Assessments
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                            <Table>
                                <thead>
                                    <tr>
                                        {
                                            this.columnHeaders.map((x, index) => {
                                                return (
                                                    <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        this.props.data.map((x, index) => {
                                            if ((x.assessment_title !== null && x.assessment_title !== '') || (x.assessment_result !== null && x.assessment_result !== '')) {
                                                return (
                                                    <tr key={index}>
                                                        <td width={'50%'}>{x.assessment_title}</td>
                                                        <td width={'50%'}>{x.assessment_result}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </Table> : <NoData />
                    }                       
                </Panel.Body>
            </Panel>
        )
    }
}

export default Assessments;
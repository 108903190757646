import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import CDIReviewTable from './CDIReviewTable';
import { MedicalRecordType } from '../../../enums/MedicalRecordType';
import { formatISODateOnly }  from '../../../utils/DateUtils';
import { SessionParamCache } from '../../../utils/SessionUtils';

class CDIReviewSearch extends React.Component {
    constructor(props) {
        super(props);
        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
        this.paramCache = SessionParamCache.create(`mrrLandingSettings-${this.lineOfBusiness}`);
        this.paramCacheKey = 'cdiAlertSearch';
        const savedParams = this.paramCache.getParamObj(this.paramCacheKey);
        this.alertReviewQueueKey = `cdiAlertReviewQueue-${this.lineOfBusiness}`;

        this.state = {
            providerOrgID: savedParams.providerOrgID ? savedParams.providerOrgID : '',
            practiceGroupingIDs: savedParams.practiceGroupingIDs ? savedParams.practiceGroupingIDs : [],
            planMemberID: savedParams.planMemberID ? savedParams.planMemberID : '',
            createdByUserID: savedParams.createdByUserID ? savedParams.createdByUserID : '',
            minAppointmentDate: savedParams.minAppointmentDate ? savedParams.minAppointmentDate : '',
            maxAppointmentDate: savedParams.maxAppointmentDate ? savedParams.maxAppointmentDate : '',
            alertID: savedParams.alertID ? savedParams.alertID : '',
            searchResults: [],
            page: 1,
            sizePerPage: 10,
            totalItems: 0,
            sortBy: '',
            sortByDesc: false,
            refreshSelected: true,
            waitingOnSearch: false
        };

        this.datepickerMin = null;
        this.datepickerMax = null;
    }

    handleProviderChange = (e) => {
        this.setState({
            providerOrgID: e ? e.value : '',
            practiceGroupingID: ''
         });
    }

    handlePracticeChange = (e) => {
        this.setState({ practiceGroupingIDs: e});
    }

    handleMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value })
    }

    handleCreatedByChange = (e) => {
        this.setState({ createdByUserID: e ? e.value : '' })
    }

    handleMinAppointmentDateChange = (e) => {
        if (e && this.validateApptDateRange(e, this.state.maxAppointmentDate)) {
            this.setState({ minAppointmentDate: e });
        }
        else {
            this.setState({ minAppointmentDate: '' });
        }
    }

    handleMaxAppointmentDateChange = (e) => {
        if (e && this.validateApptDateRange(this.state.minAppointmentDate, e)) {
            this.setState({ maxAppointmentDate: e });
        }
        else {
            this.setState({ maxAppointmentDate: '' });
        }
    }

    validateApptDateRange = (min, max) => {
        const minDate = min ? new Date(min) : null;
        const maxDate = max ? new Date(max) : null;

        if (minDate && maxDate) {
            if (minDate >= maxDate) {
                this.props.toast('error', "Max appointment date must be greater than or equal to the min appointment date.", 'Error');
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    handleAlertIDChange = (e) => {
        this.setState({ alertID: e.target.value })
    }

    //todo: add appt. date range validation (at least max > min)
    handleSearch = (page = this.state.page, sizePerPage = this.state.sizePerPage) => {
        this.setState({ waitingOnSearch: true });

        //providerID -- not used
        axios.get("/alert", {
            params: {
                statusIDs: [125, 150],
                parentOrganizationID: this.state.providerOrgID ? this.state.providerOrgID : null,
                practiceIDs: this.state.practiceGroupingIDs.length > 0 ? this.state.practiceGroupingIDs.map(p => p.value) : null,
                memberID: this.state.planMemberID ? this.state.planMemberID : null,
                createdByUserID: this.state.createdByUserID ? this.state.createdByUserID : null,
                minAppointmentDate: this.state.minAppointmentDate ? formatISODateOnly(new Date(this.state.minAppointmentDate)) : null,
                maxAppointmentDate: this.state.maxAppointmentDate ? formatISODateOnly(new Date(this.state.maxAppointmentDate)) : null,
                alertID: this.state.alertID ? this.state.alertID : null,
                pageNumber: page,
                pageSize: sizePerPage,
                sortBy: this.state.sortBy,
                sortByDesc: this.state.sortByDesc
            }
        })
        .then((response) => {
            if (response.data && response.data.items && response.data.items.length > 0) {
                //set 'selected' property for queued alerts
                const queue = this.getAlertReviewQueue();
                if (typeof(queue) === 'object' && queue.length > 0) {
                    response.data.items.forEach(x => {
                        if (queue.findIndex(y => x.cdiAlertWorkflowID === y.cdiAlertWorkflowID) !== -1) {
                            x.selected = true;
                        }
                    });
                }
                else {
                    sessionStorage.setItem(this.alertReviewQueueKey, JSON.stringify([]));
                }
            }

            this.paramCache.addParamObj('initialSearch', {
                project: this.props.project,
                reviewType: this.props.reviewType
            });
            this.paramCache.addParamObj(this.paramCacheKey, {
                providerOrgID: this.state.providerOrgID,
                practiceGroupingIDs: this.state.practiceGroupingIDs.length > 0 ? this.state.practiceGroupingIDs : null,
                planMemberID: this.state.planMemberID,
                createdByUserID: this.state.createdByUserID,
                minAppointmentDate: this.state.minAppointmentDate,
                maxAppointmentDate: this.state.maxAppointmentDate,
                alertID: this.state.alertID
            });
            this.setState({
                searchResults: response.data.items,
                totalItems: response.data.totalItems,
                waitingOnSearch: false
            })
        })
        .catch((error) => {
            this.setState({ waitingOnSearch: false });
            this.props.toast('error', "Could not search alerts.", 'Error');
        });
    }

    //todo: remove 'Refactored' from the key once the legacy version is deprecated. we'll avoid cross-contamination for now.
    getAlertReviewQueue = () => {
        const queue = sessionStorage.getItem(this.alertReviewQueueKey);
        return queue ? JSON.parse(queue) : [];
    }

    handleSetSearchResults = (searchResults) => {
        this.setState({ searchResults: searchResults })
    }

    // update sessionStorage[this.alertReviewQueueKey]
    updateAlertQueue = (cdiAlertWorkflowID, selected) => {
        const queue = this.getAlertReviewQueue();
        const target = queue.findIndex(x => x.cdiAlertWorkflowID === cdiAlertWorkflowID);
        if (selected && target === -1) {
            //selected, but not present in queue -- add to queue
            const resultTarget = this.state.searchResults.find(x => x.cdiAlertWorkflowID === cdiAlertWorkflowID);
            if (resultTarget) {
                sessionStorage.setItem(this.alertReviewQueueKey, JSON.stringify([...queue,
                    {
                        'alertID': resultTarget.alertID,
                        'cdiAlertWorkflowID': resultTarget.cdiAlertWorkflowID,
                        'memberID': resultTarget.memberID,
                        'providerID': resultTarget.providerID
                    }
                ]));
            }
        }
        else if (!selected && target !== -1) {
            //deselected, but in queue -- remove from queue
            sessionStorage.setItem(this.alertReviewQueueKey, JSON.stringify([...queue.filter(x => x.cdiAlertWorkflowID !== cdiAlertWorkflowID)]));
        }
    }

    startAlertReview = (row, isCDIQueue = false) => {
        this.cacheInitialReviewParams();
        //todo: is a dynamic medicalRecordTypeID actually needed?
        //todo: combine with prospective startReview func?
        axios.post('/MRReviewProcessing/Insert', {
            projectId: this.props.project.value,
            reviewTypeID: MedicalRecordType.CDIAlertReview,
            medicalRecordTypeID: MedicalRecordType.Scan,
            cdiAlertWorkflowID: row.cdiAlertWorkflowID,
            memberId: row.memberID,
            providerId: row.providerID,
            isCDIQueue: isCDIQueue
        })
        .then((response) => {
            //todo: should refactor out the below:
            let queryParams = '';
            Object.entries(response.data.params).forEach((x) => {
                if (queryParams === '') {
                    queryParams = queryParams + x[0] + '=' + x[1];
                }
                else {
                    queryParams = queryParams + '&' + x[0] + '=' + x[1];
                }
            });
            window.location.href = `/ReviewProcessing/InitialCoding?${queryParams}`.replace("medicalRecordReviewId", "medicalRecordReviewID");
        })
        .catch((error) => {
            this.props.toast('error', error && error.response && error.response.data && error.response.data.message
                ? `Could not start review. ${error.response.data.message}` : 'Could not start review.', 'Error');
        });
    }

    startQueue = () => {
        this.cacheInitialReviewParams();
        let queuedAlert;
        const queue = this.getAlertReviewQueue();
        if (queue.length > 0) {
            //dequeue and persist
            queuedAlert = queue.shift();
            sessionStorage.setItem(this.alertReviewQueueKey, JSON.stringify(queue));

            if (queuedAlert && queuedAlert.cdiAlertWorkflowID && queuedAlert.memberID && queuedAlert.providerID) {
                //set selected to false, unchecking associated checkbox if visible
                const records = [...this.state.searchResults];
                const target = records.find(x => x.cdiAlertWorkflowID === queuedAlert.cdiAlertWorkflowID);
                if (target) {
                    target.selected = false;
                }

                //trigger update of 'selected' (child state)
                this.setState({ refreshSelected: true });
                this.startAlertReview(queuedAlert, true);
            }
            else {
                //todo: alert user (invalid queue entry)
                this.props.toast('error', 'Could not start review.', 'Error');
            }
        }
        else {
            this.props.toast('warning', 'No reviews in queue.', '');
        }
    }

    cacheInitialReviewParams = () => {
        this.paramCache.addParamObj('initialSearch', {
            project: this.props.project,
            reviewType: this.props.reviewType
        });
    }

    toggleRefreshSelected = () => {
        this.setState({ refreshSelected: !this.state.refreshSelected });
    }

    handleOnPageChange = (page, sizePerPage) => {
        this.setState({
            page: page,
            sizePerPage: sizePerPage
        }, this.handleSearch);
    }

    handleOnSortChange = (sortName, sortOrder) => {
        this.setState({
            sortBy: sortName,
            sortByDesc: sortOrder === "desc",
            page: 1
        }, this.handleSearch);
    }

    getProviderDropdownOptions = (providers) => {
        return providers.map(item => {
            return {
                value: item.id,
                label: item.description
            }
        });
    }

    getPracticeDropdownOptions = (practices) => {
        return practices.map(item => {
            return {
                value: item.groupingID,
                label: item.description + ' - ' + item.groupingID,
            }
        });
    }

    getUserDropdownOptions = (users) => {
        return users.map(item => {
            return {
                value: item.userID,
                label: item.fullName,
            }
        });
    }

    render() {
        const practices = this.state.providerOrgID ? this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.providerOrgID ? practice : false;
            }) : this.props.practices ? this.props.practices : [];

        return (
            <div>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Provider Org
                            </ControlLabel>
                            <Select value={this.state.providerOrgID} options={this.getProviderDropdownOptions(this.props.providers)} onChange={this.handleProviderChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Practices
                            </ControlLabel>
                            <Select multi value={this.state.practiceGroupingIDs} options={this.getPracticeDropdownOptions(practices)} autosize={true} onChange={this.handlePracticeChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Member ID
                            </ControlLabel>
                            <div>
                                <FormControl type="text" value={this.state.planMemberID} onChange={this.handleMemberIDChange} />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Created By
                            </ControlLabel>
                            <Select value={this.state.createdByUserID} options={this.getUserDropdownOptions(this.props.users)} onChange={this.handleCreatedByChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }}>Min Appointment Date</ControlLabel>
                            <DatePicker value={this.state.minAppointmentDate} onChange={this.handleMinAppointmentDateChange} style={{ zIndex: 'auto' }}
                                ref={(ref) => this.datepickerMin = ref}  />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }}>Max Appointment Date</ControlLabel>
                            <DatePicker value={this.state.maxAppointmentDate} onChange={this.handleMaxAppointmentDateChange} style={{ zIndex: 'auto' }}
                                ref={(ref) => this.datepickerMax = ref}  />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                Alert ID
                            </ControlLabel>
                            <div>
                                <FormControl type="text" value={this.state.alertID} onChange={this.handleAlertIDChange} />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Button onClick={() => this.handleSearch(1)} disabled={this.state.waitingOnSearch}
                            style={{ marginTop: '2rem' }}>
                            <Glyphicon glyph="search" style={{ marginRight: '0.2rem'}} /> Search
                        </Button>
                        <Button onClick={this.startQueue} style={{ marginTop: '2rem', marginLeft: '1rem' }}>
                            <Glyphicon glyph="play" style={{ marginRight: '0.2rem'}} /> Start Queue
                        </Button>
                    </Col>
                </Row>
                <CDIReviewTable searchResults={this.state.searchResults} totalItems={this.state.totalItems} page={this.state.page}
                    sizePerPage={this.state.sizePerPage} onPageChange={this.handleOnPageChange} onSortChange={this.handleOnSortChange}
                    handleSetSearchResults={this.handleSetSearchResults} updateAlertQueue={this.updateAlertQueue}
                    startAlertReview={this.startAlertReview} refreshSelected={this.state.refreshSelected}
                    toggleRefreshSelected={this.toggleRefreshSelected} toast={this.props.toast} />
            </div>
        );
    }
}

export default CDIReviewSearch;
import React from 'react';
import NLPTableImage from './NLPTableImage';
import ReactPopout from '../../../../external/ReactPopout';

class NLPTableImageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isInsightsPoppedOut: false,
        };
    }

    componentDidMount(){
        console.log('Component loaded');
    }

    
    toggleInsightsWindow =() => {
       this.props.toggleInsightsWindow();
    }
    handlePaging = () => {
        this.props.handlePaging();
    }


    render() {
        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width * .8,
            height: window.screen.height * .8,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };

        //MRR-24571
        //Included this check to redirect to new NLP screen when popout
        const url = `/NLP/NLPInsightsNewWindow/${this.props.reviewType}/${window.location.search}`;

        return (
            <div>
                {
                    this.state.isInsightsPoppedOut &&
                    <ReactPopout title="NLP Insights" onClosing={this.toggleInsightsWindow} options={options} url={url}>
                    </ReactPopout>
                }
                <span style={{ display: (this.state.isInsightsPoppedOut) ? 'none' : 'initial' }}>
                    <NLPTableImage
                        title={this.props.title} nlpInsightData={this.props.nlpInsightData}
                        navigateToPage={this.props.navigateToPage}
                        tableCollapsed={this.props.tableCollapsed} collapseTable={this.props.collapseTable} displayCollapseButton={true}
                        toast={this.props.toast} medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                        providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData}
                        memberID={this.props.memberID}
                        medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                        reviewProcessingUIConfig={this.props.reviewProcessingUIConfig}
                        diagnosisComments={this.props.diagnosisComments}
                        medicalRecordTypeID={this.props.medicalRecordTypeID}
                        reloadImages={this.props.populateImageIDOptions} imageRecords={this.props.imageRecords}
                        userID={this.props.userID} getDiagnosisData={this.props.getDiagnosisData} 
                        getDiagnosisHistoryData={this.props.getDiagnosisHistoryData} setDiagFormData={this.props.setDiagFormData}
                        reviewType={this.props.reviewType} openNewWindow={this.toggleInsightsWindow} isInsightsPoppedOut={false}
                        handlePaging={this.handlePaging}
                    />
                </span>
            </div>
        );
    }
}

export default NLPTableImageContainer;

import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class AssessmentQuestions extends React.PureComponent {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            ['QUESTION'],
            ['ANSWER']
        ]
    }

    render() {
        return (
            <Panel id={"assessmentquestions"}>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Assessment Questions
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                            <Table>
                                <thead>
                                    <tr>
                                        {
                                            this.columnHeaders.map((x, index) => {
                                                return (
                                                    <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.data.map((x, index) => {
                                            if ((x.question !== null && x.question !== '') || (x.answer !== null && x.answer !== '')) {
                                                return (
                                                    <tr key={index}>

                                                        <td width={'50%'}>{x.question}</td>
                                                        <td width={'50%'}>{x.answer}</td>

                                                    </tr>
                                                )
                                            }
                                        })

                                    }
                                </tbody>
                            </Table> : <NoData />
                    }
                </Panel.Body>
            </Panel>
        )
    }
}

export default AssessmentQuestions;
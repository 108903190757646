import React from 'react';
import { BootstrapTable, TableHeaderColumn, BootstrapButton } from 'react-bootstrap-table';
import { Button, Glyphicon } from 'react-bootstrap';

    const PracticePriority = (props) => {

        const formatName = (cell, row) => {
            return row.campaignGroupName ? row.campaignGroupName : row.campaignPracticeName;
        }

        const actionFormatter = (cell, row) => {
            return (
                <div>
                    <Button block bsStyle="primary" onClick={() => props.updatePauseStatus(row.campaignProjectPracticeID)}>
                        { row.isPaused ? "Resume" : "Pause" }
                    </Button>
                </div>
            )
        }

        const buttonFormatter = (cell, row) => {
            const matchingCampaignProjectPractice = props.data.find(x => x.campaignProjectPracticeID == row.campaignProjectPracticeID);
            const disabledTop = matchingCampaignProjectPractice ? 
                matchingCampaignProjectPractice.isPaused || matchingCampaignProjectPractice.projectPriority === 1 : false;
            const disabledBottom = matchingCampaignProjectPractice ?
                matchingCampaignProjectPractice.isPaused || matchingCampaignProjectPractice.projectPriority === props.largestPriority : false;
            
            return (
            <div>
                <Button style={{marginRight: '0.7rem'}}
                        onClick={() => props.handleUpdatePriority(row, 1)}
                        disabled={disabledTop}>
                    <Glyphicon glyph='arrow-up' style={{ top: '1.6px',
                        lineHeight: '14px'}} />
                </Button>
                <Button style={{marginRight: '0.7rem'}}
                        onClick={() =>props.handleUpdatePriority(row, -1)}
                        disabled={disabledBottom}>
                    <Glyphicon glyph='arrow-down' style={{ top: '1.6px',
                        lineHeight: '14px'}} />
                </Button>
                <Button bsStyle="success" style={{marginRight: '0.7rem'}}
                        onClick={() => props.handleUpdatePriorityToOne(row)}
                        disabled={disabledTop}>
                        Top
                </Button>
            </div>
            )
          }    
        return(
            <div style={{ marginTop: '1rem' }}>
                <BootstrapTable data={props.data} maxHeight="516px" margin="100px">
                    <TableHeaderColumn dataField="campaignPracticeID" isKey hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="practiceID"  dataAlign="left" dataFormat={formatName}>Practice/Practice Grouping Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="projectPriority" dataAlign="center">Practice/Practice Grouping Priority</TableHeaderColumn>
                    <TableHeaderColumn dataField="pauseButton" dataAlign="center" dataFormat={actionFormatter}>Pause/Resume Practices/PG</TableHeaderColumn>
                    <TableHeaderColumn dataField="changePriorityButton" dataAlign="center" dataFormat={buttonFormatter}>Change Priority</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

export default PracticePriority;
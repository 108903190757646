import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import ProjectEditorModal from './ProjectEditorModal';

export default class ProjectManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            programs: [],
            clientDefaultReviewProcessingDelay: '',
            loaded: false,
            showProjectEditModal: false,
            selectedProjectID: null,
            campaignDropDownOptions: null
        };
    }

    componentWillMount() {
        Promise.all([
            axios.get('/projects', {
                params: {
                    includeInactive: true,
                    includeReviewCount: true
                }
            }),
            axios.get('/programs'),
            axios.get('/securable/config', { params: { configKeys: ["InitialReviewProcessingDelayInMin"] } }),
            axios.get('/campaign')
        ])
        .then((responses) => {
            // containsPrePopulatedReviews is used for two <TableHeaderColumn>;
            // mapping to an additional prop allows for the columns to be sorted separately
            responses[0].data.forEach(x => {
                x.containsPrePopulatedReviewsAlt = x.containsPrePopulatedReviews;
            });

            this.setState({
                projects: responses[0].data,
                programs: responses[1].data,
                clientDefaultReviewProcessingDelay: responses[2].data && responses[2].data.initialReviewProcessingDelayInMin ?
                    parseInt(responses[2].data.initialReviewProcessingDelayInMin, 10) : 120,
                campaignDropDownOptions: this.getCampaignDropDownOptions(responses[3].data),
                loaded: true
            })
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.props.toast('error', 'Could not load project data.', 'Error');
        })
    }

    isProjectNameUnique = (name) => {
        return !this.state.projects.some(x => x.projectName === name);
    }

    createProject = (project) => {
        axios.post('/projects', project)
        .then((response) => {
            this.setState({ projects: [...this.state.projects, { ...response.data,
                medicalRecordReviewCount: 0 }]
            });
            this.props.toast('success', 'Created new project.', '');
        })
        .catch((error) => {
            this.props.toast('error', 'Could not create project.', 'Error');
        });
    }

    updateProject = (project) => {
        axios.put(`/projects/${this.state.selectedProjectID}`, project)
        .then((response) => {
            // maintain the review count from the /projects response (not populated elsewhere)
            const existingProject = this.state.projects.find(x => x.projectID === project.projectID);
            this.setState({
                projects: [...this.state.projects.filter(x => x.projectID !== project.projectID), {
                    ...response.data, medicalRecordReviewCount: existingProject.medicalRecordReviewCount,
                    containsPrePopulatedReviewsAlt : response.data.containsPrePopulatedReviews
                }]
            });
            this.props.toast('success', 'Updated project.', '');
        })
        .catch((error) => {
            this.props.toast('error', 'Could not update project.', 'Error');
        });
    }

    formatBoolean = (cell) => {
        return cell ? 'Yes' : 'No';
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US') : '';
    }

    formatInt = (cell) => {
        return typeof(cell) === 'number' ? parseInt(cell, 10) : '';
    }

    formatPrograms = (cell) => {
        return cell && Array.isArray(cell) ?
            cell.reduce((acc, cur) => {
                const program = this.state.programs.find(x => x.programID === cur.programID);
                if (program) {
                    acc.push(program.programDescription);
                }
                return acc;
            }, []).join(', ')
            : '';
    }

    formatWorkflow = (cell) => {
        return cell ? 'Auto-serve' : 'Search and create';
    }

    getCampaignDropDownOptions = (campaigns) => {
        return campaigns.map((x) => {
            return {
                label: x.campaignName,
                value: x.campaignID
            };
        });
    }

    refreshCampaignDropDownOptions = () => {
        axios.get('/campaign')
        .then((response) => {
            this.setState({
                campaignDropDownOptions: this.getCampaignDropDownOptions(response.data)
            })
        })

    }

    toggleProjectEditModal = (row) => {
        if (this.state.showProjectEditModal) {
            this.setState({
                selectedProjectID: '',
                showProjectEditModal: false
             });
        }
        else {
            this.setState({ showProjectEditModal: true });
        }
    }

    selectAndOpenModal = (row) => {
        this.setState({ selectedProjectID: row.projectID }, this.toggleProjectEditModal);
    }

    renderEditButton = (cell, row) => {
        return (
            <Button onClick={() => this.selectAndOpenModal(row)}>
                <Glyphicon glyph="pencil" />
            </Button>
        );
    }

    render() {
        const options = {
            defaultSortName: ['projectName', 'dataCollectionYear', 'active'],
            defaultSortOrder: ['asc', 'desc', 'desc'],
            onRowDoubleClick: this.selectAndOpenModal
        }

        return (
            <Grid fluid>
                <Loader loaded={this.state.loaded}>
                    <Row style={{ marginTop: '1.4rem' }}>
                        <Col xs={10} style={{ paddingLeft: '8px' }}>
                            <h4>Project Management</h4>
                        </Col>
                        <Col xs={2} style={{ paddingRight: '8px' }}>
                            <Button bsStyle="success" style={{ float: 'right' }} title="Add Project"
                                onClick={this.toggleProjectEditModal}>
                                <Glyphicon glyph="plus" style={{ marginRight: '0.3rem' }} />Add Project
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '1rem' }}>
                        <BootstrapTable data={this.state.projects} ref="projectTable" maxHeight="800px" scroll-top="Top"
                            options={options} multiColumnSort={11} hover pagination>
                            <TableHeaderColumn dataField="projectID" dataAlign="left" dataSort hidden isKey></TableHeaderColumn>
                            <TableHeaderColumn dataField="active" dataAlign="left" dataFormat={this.formatBoolean} dataSort width="5%">Active</TableHeaderColumn>
                            <TableHeaderColumn dataField="dataCollectionYear" dataAlign="left" dataSort width="7%">Collection Year</TableHeaderColumn>
                            <TableHeaderColumn dataField="projectName" dataAlign="left" dataSort width="21%">Project Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="projectCode" dataAlign="left" dataSort width="7%">Project Code</TableHeaderColumn>
                            <TableHeaderColumn dataField="showTargetedConditions" dataFormat={this.formatBoolean} dataAlign="left" dataSort width="7%">Show Target / Suspect HCCs</TableHeaderColumn>
                            <TableHeaderColumn dataField="containsPrePopulatedReviews" dataAlign="left" dataFormat={this.formatBoolean} dataSort width="6%">Chase Required</TableHeaderColumn>
                            <TableHeaderColumn dataField="containsPrePopulatedReviewsAlt" dataAlign="left" dataFormat={this.formatWorkflow} dataSort width="7%">Workflow</TableHeaderColumn>
                            <TableHeaderColumn dataField="createdUsername" dataAlign="left" dataSort width="10%">Created By</TableHeaderColumn>
                            <TableHeaderColumn dataField="createdDatetime" dataAlign="left" dataFormat={this.formatDateString} dataSort width="6%">Created On</TableHeaderColumn>
                            <TableHeaderColumn dataField="programs" dataAlign="left" dataFormat={this.formatPrograms} width="15%" dataSort>Associated Programs</TableHeaderColumn>
                            <TableHeaderColumn dataField="medicalRecordReviewCount" dataAlign="left" dataFormat={this.formatInt} width="5%" dataSort>Review Count</TableHeaderColumn>
                            <TableHeaderColumn dataField="projectID" dataAlign="center" width="4%" dataFormat={this.renderEditButton}></TableHeaderColumn>
                        </BootstrapTable>
                        <ProjectEditorModal visible={this.state.showProjectEditModal}
                            toggleModal={this.toggleProjectEditModal} selectedProjectID={this.state.selectedProjectID}
                            programs={this.state.programs} createProject={this.createProject}
                            updateProject={this.updateProject} isProjectNameUnique={this.isProjectNameUnique}
                            clientDefaultReviewProcessingDelay={this.state.clientDefaultReviewProcessingDelay}
                            toast={this.props.toast} campaignDropDownOptions={this.state.campaignDropDownOptions}
                            refreshCampaignDropDownOptions={this.refreshCampaignDropDownOptions} />
                    </Row>
                </Loader>
            </Grid>
        );
    }
}
import React from 'react';
import axios from 'axios';
import { Row, Button, Glyphicon, Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NLPInsightHCCDiagModel from './NLPInsightHCCDiagModel';


class NLPInsightHCCProfile extends React.Component {
    constructor(props) {
        console.log('NLPInsightHCCProfile constructor')
        super(props);
        this.state = {
            showFormModal: false,
            editModalRow: null,
            editMode: false,
            editQABool: false,
            showDiagLookupModal: false,
            diagnosisCD: null,
            imageRecords: null,
            nlpInsightData : [],
            nlpInsightHccDiag : [],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            nlpInsightData: this.props.nlpInsightData
        });       
    }

    formatAddAction = (cell, row) => {
        return (
            <Button onClick={() => this.handleAddDiag(row)}>
                <Glyphicon glyph="plus" />
            </Button>    
        )
    }

    formatRemoveAction = (cell, row) => {
        return (
            <Button onClick={() => this.handleRemoveDiag(row)}>
                <Glyphicon glyph="minus" />
            </Button>
        )
    }

    sortNlpData = (a, b, order, sortField, index) => {
        index =index==undefined?0:index;
        if(a[sortField].split('_')[index] === b[sortField].split('_')[index])
        {
            a = a[sortField].split('_')[index] ? Number(a[sortField].split('_')[index]) + Number(a[sortField].split('_')[index+1]) : Number.MAX_SAFE_INTEGER;
            b = b[sortField].split('_')[index] ? Number(b[sortField].split('_')[index]) + Number(b[sortField].split('_')[index+1]) : Number.MAX_SAFE_INTEGER;
        }
        else{
        //place empty values at the bottom of the sort
        a = a[sortField].split('_')[index] ? a[sortField].split('_')[index] : Number.MAX_SAFE_INTEGER;
        b = b[sortField].split('_')[index] ? b[sortField].split('_')[index] : Number.MAX_SAFE_INTEGER;
        }
        if (order === 'asc') {           
            return a - b;
        }
        else {
            return b - a;
        }
    }

    handleRemoveDiag = (row) => {
        console.log(row);
    }
    
    handleAddDiag = (row) => {
        console.log(row);
        this.toggleFormModal(row);
    }

    activePageFormatter = (cell, row) => {
        return(
            <div style={{ cursor: 'pointer', color: '#23527c', textDecoration: 'underline'}} onClick={() => this.handlePageClick(cell)}> {cell} </div>
        );
    }

    handlePageClick = (cell) => {
        this.props.navigateToPage(cell);
    }

    formatDate = (cell) => {
        if(!cell){ return ""; }
        var options = {year: 'numeric', month: 'numeric', day: 'numeric'};
       return `${(cell ? new Date(cell).toLocaleDateString('en-US', options) : '')}`;
    }

    setClaimsAndClaimProviders = (dosRange, claimsResponse) => {
        this.setState({
            claimsMap: new Map([ ...this.state.claimsMap,
                [dosRange, claimsResponse.claims ? claimsResponse.claims : []]
            ]),
            claimsProviderMap: new Map([ ...this.state.claimsProviderMap,
                [dosRange, claimsResponse.claimProviders ? claimsResponse.claimProviders : []]
            ])
        });
    }   

    toggleFormModal = (row) => {
        this.constructNLPArray(row);
        this.setState({ showFormModal: !this.state.showFormModal});
            
    }

    constructNLPArray =(row) => {
        if(row){
        let diagResults=[];
        let hccValue=row.hccValue;
        let hccVersion=row.hccVersion;

         Object.values(row.diagnoses).forEach((key, index) => {            
            
            diagResults.push({
                        dosStart: key.dosStart, 
                        dosEnd: key.dosEnd, 
                        pageStart: key.pageStart, 
                        pageEnd: key.pageEnd, 
                        diagnosisCode: key.diagnosisCode, 
                        description: key.description, 
                        hccValue: hccValue, 
                        hccVersion:  hccVersion });
                });
                this.setState({ nlpInsightHccDiag: diagResults }); 
            }
    }

    toggleDiagLookupModal = () => {
        this.setState({ showDiagLookupModal: !this.state.showDiagLookupModal });
    }

    

    toggleDiagFeedbackModal = (diag) => {
        this.setState({
            showDiagFeedbackModal: !this.state.showDiagFeedbackModal,
            modalRow: diag ? {...diag} : null
        });
    }

    setProviderGroup = (planProviderID, providerGroup) => {
        this.setState({
            providerGroupMap: new Map([ ...this.state.providerGroupMap,
                [planProviderID, providerGroup ? providerGroup : [] ]
            ])
        });
    }

    openPopoutWindow = (evt) => {
        console.log('popped out');
        evt.stopPropagation();
        this.props.openNewWindow(this.props.activeTab);
    }

    render() {
        return (
            <div>
                 <Panel className={!this.props.isInsightsPoppedOut}>
                    <Panel.Heading>
                        <Panel.Title>
                            {
                            !this.props.isInsightsPoppedOut &&
                                <span style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                                    <Glyphicon glyph="new-window" onClick={this.openPopoutWindow} />
                                </span>
                            }
                            
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible={false}>
                    {                       
                        <Row style={{ marginBottom: '1rem', marginLeft: '0px', marginRight: '0px' }}>
                            <BootstrapTable data={this.props.nlpInsightData} > 
                            <TableHeaderColumn dataField="medicalRecordImageID" isKey hidden></TableHeaderColumn>                               
                                <TableHeaderColumn dataField="hccValue" dataSort sortFunc={this.sortNlpData} width="25%">HCC</TableHeaderColumn>                                
                                <TableHeaderColumn dataField="hccDescription" dataSort width="50%" >HCC Description</TableHeaderColumn>
                                <TableHeaderColumn width="25%" dataField='actions' dataFormat={this.formatAddAction}>Diagnosis</TableHeaderColumn>                                
                            </BootstrapTable>
                        </Row>
                    }                                   
                    <NLPInsightHCCDiagModel nlpInsightHccDiag={this.state.nlpInsightHccDiag} 
                    visible={this.state.showFormModal} handleModalToggle={this.toggleFormModal}/>                
                    </Panel.Body>
                </Panel>
            </div>
        );

        
    }
}

export default NLPInsightHCCProfile;
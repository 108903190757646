import React, { useState } from "react";
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import Loader from 'react-loader';
import Select from 'react-select';
import { connect } from 'react-redux';
import NavItems from "../../../NavHeader/NavItems";

const mapStateToProps = state => {
    const currentUserProp = state.session.find(x => x.currentUser);
    return currentUserProp ? currentUserProp : {};
};

class EscalateModal extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            showEscalateModal:false,
            disabled: false,
            escalateSelectOption: '',
            inventoryOptions:this.props.option
        }
    }

    SaveEvent = () => {
        axios.post(`/medicalRecordReview/escalateReview`, {
            EscalationTypeID: this.state.escalateSelectOption,
            EscalationReasonText: document.getElementById('escalateText').value,
            MedicalRecordReviewID: this.props.reviewProcessingUIConfig.medicalRecordReviewID,
            EscalatedBy: this.props.currentUser.userID
        }).then((response) => {
            this.closeModal();
            
            if(this.props.isAutoQueue)
            {
                window.location.href = `/MRRAutoQueue`;
            }
        }) .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 404) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            }
        });
    }

    SetOptionforSelectValue = () => {
        var j = this.props.currentUser.userID;
        var eventKey = document.getElementById('escalateSeletOption').value;
        this.setState({
            escalateSelectOption: eventKey
        })
    }

    componentDidUpdate(){
        if(this.state.visible !== this.props.visible)
        {
            this.setState({
                visible: this.props.visible,
                showEscalateModal:true
            });
        }
    }

    closeModal =() =>{
        this.setState({
            visible:this.props.visible,  
            showEscalateModal:false          
        })
    }
    
    render(){
        return (
            <div>
                <Modal show={this.state.showEscalateModal} bsSize="small">
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <b>Escalate Review</b>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Loader loaded={true}>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <ControlLabel>Issue Type</ControlLabel>
                                        <select class='form-control' id='escalateSeletOption'
                                        onChange={eventKey => this.SetOptionforSelectValue(eventKey = this._select)}
                                        >
                                            <option value='0'>Select</option>                                            
                                            {this.props.option.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs={12}>
                                    <label>Describe Issue</label>
                                </Col>  
                                <Col xs={12}>
                                    <textarea class='form-control' style={{width: '100'}} rows='4' cols='36' id='escalateText'/>
                                </Col>                                
                            </Row>
                            <Row style={{paddingTop: '5%'}}>
                                <Col xs={6}>
                                    <button style={{width: '100%'}} class="btn btn-primary" onClick={this.closeModal}>Cancel</button>
                                </Col>  
                                <Col xs={6}>
                                    <button style={{width: '100%'}} class="btn btn-primary" onClick={this.SaveEvent}>Save</button>
                                </Col>                                
                            </Row>
                        </Loader>
                    </Modal.Body>                    
                    <Modal.Footer />
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(EscalateModal);
import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import '../../styles/Report.css';


const HostRequestClosureTimeframe = () => {

    useEffect(() => {
        const lineOfBusinessValue = localStorage.getItem('lineOfBusiness');
        if (lineOfBusinessValue === "Commercial") {
            window.location.href = '/MRR';
        }
    }, []);

    return (
        <div className="hostPlan reportContainer">
            <div className="innerReportContainer" style={{ minHeight: '250px', marginLeft: '1%', marginRight: '1%' }}>
                <header>
                    <Col className="title" >
                        <h3> Host Request Closure Timeframes Report </h3>
                    </Col>
                </header>

                <section className="table-section">
                    <BootstrapTable data={''} maxHeight='400px'>
                        <TableHeaderColumn dataField="HostPlanID" isKey={true} hidden>Host Plan ID </TableHeaderColumn>
                        <TableHeaderColumn dataField="RequestType" dataSort width='100px'>Request Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="ClosedIn<30Days" dataSort width='100px'>Successfully Closed in {'<'} 30 Days</TableHeaderColumn>
                        <TableHeaderColumn dataField="ClosedIn30-60Days" dataSort width='100px'>Successfully Closed in 30-60 Days</TableHeaderColumn>
                        <TableHeaderColumn dataField="ClosedIn60-90Days" dataSort width='120px'>Successfully Closed in 60-90 Days</TableHeaderColumn>
                        <TableHeaderColumn dataField="ClosedIn>90Days" dataSort width='120px'>Successfully Closed in {'>'} 90 Days</TableHeaderColumn>
                    </BootstrapTable>
                </section>
                <div className="export-table-data">
                    <LongRunningDownloadAnchor linkText="Download .CSV"
                        displayCSVDownloader={true}
                    />
                </div>
            </div>
        </div >
    )
}

export default HostRequestClosureTimeframe;

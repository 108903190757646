import React from 'react';
import { Button, Col, Glyphicon, Row } from 'react-bootstrap';
import Select from 'react-select';

const AlertResponseLine = (props) => {
    const handleAlertResponseChange = (e) => {
        props.handleAlertResponseChange(e, props.line)
    }

    const handleQueryResponseChange = (e) => {
        // clearing the query code field via dropdown is permitted
        props.handleQueryResponseChange(e, props.line)
    }

    const handleQueryCodeChange = (e) => {
        // clearing the query code field via dropdown isn't permitted;
        // deletion is handled exclusively by the delete button.
        if (e) {
            props.handleQueryCodeChange(e, props.line)
        }
    }

    const renderAlertLine = () => {
        return (
            <Row>
                <Col lg={2}>
                    <Select value={props.selectedAlertResponse} options={props.alertResponseOptions}
                        onChange={handleAlertResponseChange} disabled={props.isViewOnly} />
                </Col>
                <Col lg={10}>
                    {props.line.alertLineText}
                </Col>
            </Row>
        );
    }

    const renderAlertQueryLine = () => {
        return (
            <Row>
                <Col lg={2}>
                    <Select value={props.selectedAlertResponse} options={props.alertResponseOptions}
                        onChange={handleAlertResponseChange} disabled={props.isViewOnly} />
                </Col>
                <Col lg={5}>
                    {props.line.alertLineText}
                </Col>
                <Col lg={2}>
                    <Select value={props.selectedQueryCode} options={props.queryCodeOptions}
                        onChange={handleQueryCodeChange} disabled={props.isViewOnly} />
                </Col>
                <Col lg={2}>
                    <Select value={props.selectedQueryResponse} options={props.queryResponseOptions}
                        onChange={handleQueryResponseChange} disabled={props.isViewOnly || !props.line.selectedQueryCode} />
                </Col>
                <Col lg={1}>
                    <Button onClick={() => props.handleQueryResponseDelete(props.line)}
                        disabled={(!props.selectedQueryCode && !props.selectedQueryResponse) || props.isViewOnly}>
                        <Glyphicon glyph="trash" style={{'marginRight': '0.2rem'}} />
                    </Button>
                </Col>
            </Row>
        );
    }

    return props.isQueryResponse || props.isViewOnly ? renderAlertQueryLine() : renderAlertLine();
}

export default AlertResponseLine;
import React from "react";
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import Select from 'react-select';

class ResolveReviewModal extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <Modal show={this.props.showBulkModal} bsSize="large"  >
                    <Modal.Header>
                        <Modal.Title>
                            <b>Resolve Escalated Reviews</b>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: '150px'}}>
                        <Row>
                            <Col xs={10}>
                                <FormGroup>
                                    <ControlLabel>Resolve Action</ControlLabel>
                                    <Select options={this.props.resolutionActionOptions} value={this.props.defaultResolveActionID}
                                     onChange={this.props.onBulkActionChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row style={{paddingTop: '5%'}}>
                            <Col xs={6}>
                                <button style={{width: '100%'}} className="btn btn-default" onClick={this.props.handleBulkModal}>Cancel</button>
                            </Col>  
                            <Col xs={6}>
                                <button style={{width: '100%'}} className="btn btn-primary" onClick={this.props.saveBulkUpdate} 
                                disabled={this.props.saveDisabled}>Save</button>
                            </Col>                                
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ResolveReviewModal;
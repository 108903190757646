import React from 'react';
import axios from 'axios';
import { Button, ButtonGroup, Checkbox, Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row,
    Radio, Glyphicon } from 'react-bootstrap';
import Loader from 'react-loader';
import Select from 'react-select';
import { Program } from '../../../enums/Program';
import CampaignEditorModal from './CampaignEditorModal'
import { ProjectInventoryType } from '../../../enums/ProjectInventoryType';


//todo: consolidate mapNewRecordForSave + mapExistingRecordForSave
export default class ProjectEditorModal extends React.Component {
    constructor(props) {
        super(props);
        const currentYear = new Date().getFullYear();
        let availableYears = [];
        for (let i = currentYear; i !== currentYear - 10; i--) {
            availableYears.push(i);
        }

        this.state = {
            collectionYears: availableYears,
            savedProject: {},
            project: {
                projectName: '',
                dataCollectionYear: '',
                projectCode: '',
                showTargetedConditions: false,
                active: true,
                initialReviewProcessingDelayInMin: this.props.clientDefaultReviewProcessingDelay
            },
            selectedPrograms: [],
            activeProgramID: null,
            validationErrors: [],
            containsReadyReviews: false,
            containsInProgressReviews: false,
            loaded: true,
            inventoryOptions: [],
            selectedInventory: null,
            showCampaignEditModal: false,
            disabled: ''
        };
    }

    componentDidMount() {
        axios.get('/ReferenceDataOptions/10')
        .then((response) => {
            if (response.data) {
                this.setState({
                    inventoryOptions: response.data.map((x) => {
                        return {
                            label: x.description,
                            value: x.referenceDataItemID
                        }
                    })
                })
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load inventory options', 'Error');
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedProjectID && this.props.selectedProjectID !== nextProps.selectedProjectID) {
            this.setState({ loaded: false }, this.getProjectData(nextProps.selectedProjectID));
        }
    }

    disableFields = () =>{
        if(this.state.project.campaignID == '' || this.state.project.campaignID == null)
        {
            this.state.disabled = false;
        }
        else
        {
            this.state.disabled = true;
        }
    }

    getProjectData = (projectID) => {
        Promise.all([axios.get(`/projects/${projectID}`),
            axios.get(`/projects/${projectID}/reviewStats`)])
        .then((responses) => {
            const mappedProject = (responses[1].data.containsInProgressReviews
                || responses[1].data.containsReadyReviews)
                ? this.mapProjectSelectedInventory(responses[0].data, true)
                : this.mapProjectSelectedInventory(responses[0].data, false);

            this.setState({
                savedProject: responses[0].data,
                project: mappedProject,
                selectedPrograms: mappedProject.programs,
                selectedInventory: mappedProject.selectedInventory,
                containsReadyReviews: responses[1].data.containsReadyReviews,
                containsInProgressReviews: responses[1].data.containsInProgressReviews,
                loaded: true
            });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load project data.', 'Error');
            this.close();
        });
    }

    mapProjectSelectedInventory = (project, containsInProgressOrReadyReviews) => {
        const updatedProject = {...project};
        updatedProject.selectedInventory = project.projectRetrieveTypeID;
        
        return updatedProject;
    }

    clearTextEntry = () => {
        this.setState({
            savedProject: {},
            project: {
                projectName: '',
                dataCollectionYear: 0,
                projectCode: '',
                showTargetedConditions: false,
                active: true,
                initialReviewProcessingDelayInMin: this.props.clientDefaultReviewProcessingDelay
            },
            selectedInventory: null,
            selectedPrograms: [],
            activeProgramID: null,
            validationErrors: [],
            containsReadyReviews: false,
            containsInProgressReviews: false
        });
    }

    close = () => {
        this.clearTextEntry();
        this.props.toggleModal();
    }

    toggleChecked = (statePropertyName) => {
        this.setState({
            project: {
                ...this.state.project,
                [statePropertyName]: !this.state.project[statePropertyName]
            }
        });
    }

    handleTextBoxChange = (statePropertyName) => (e) => {
        this.setState({ project: { ...this.state.project, [statePropertyName]: e.target.value }});
    }

    handleDataCollectionYearChange = (e) => {
        this.setState({ project: { ...this.state.project, dataCollectionYear: e ? e.value : '' }});
    }

    handleCampaignChange = (e) => {
        console.log(this.state.project);
        this.setState({ project: {...this.state.project, campaignID: e ? e.value : '' }});
    }

    handleInventoryOptionsChange = (e) => {
        this.setState({ selectedInventory: e ? e.value : '' });
    }

    handleProgramSelect = (e, programID) => {
        if (e.target.checked) {
            this.setState({
                selectedPrograms: [...this.state.selectedPrograms,
                    this.props.programs.find(x => x.programID === programID)]
            });
        }
        else {
            this.setState({
                selectedPrograms: this.state.selectedPrograms.filter(x => x.programID !== programID)
            });
        }
    }

    handleAuditTypeSelect = (e) => () => {
        this.setState({ project: { ...this.state.project, selectedAuditTypeID: e }});
    }

    validateProject = () => {
        const errors = [];

        if (!this.state.project.projectName || this.state.project.projectName.trim().length === 0) {
            errors.push('Project Name is required.');
        }
        else 
        {
            if (!this.state.savedProject || !this.state.savedProject.projectName || (this.state.savedProject.projectName.trim() !== this.state.project.projectName.trim()))
            {
                if (this.props.isProjectNameUnique(this.state.project.projectName.trim()) === false) {
                    errors.push('Project Name must be unique.');
                }                
            }
        }       

        if (!this.state.project.projectCode || this.state.project.projectCode.trim().length === 0) {
            errors.push('Project Code is required.');
        }

        if (this.state.project.projectCode && this.state.project.projectCode.trim().length > 0)
        {
            const reg = new RegExp('^[A-Za-z0-9]*$');
            if(reg.test(this.state.project.projectCode) === false)
            {
                errors.push('The Project Code can only contain numbers and letters');
            }
        }

        if (!this.state.project.dataCollectionYear) {
            errors.push('Must select a collection year.');
        }

        if (this.state.inventoryOptions.map(x => x.value).indexOf(this.state.selectedInventory) < 0) {
            errors.push('Must select an inventory.');
        }

        if (this.state.selectedPrograms.length === 0) {
            errors.push('Must select at least one program.')
        }
        else {
            const radvProgram = this.state.selectedPrograms.find((item) => {
                return item.programID === Program.RADV;
            });

            if (radvProgram && !this.state.project.selectedAuditTypeID) {
                errors.push('RADV projects require a RADV Audit Type to be selected.')
            }
        }

        return errors;
    }

    mapNewRecordForSave = () => {
        let query = { ...this.state.project };

        query.projectName = query.projectName.trim();
        query.projectCode = query.projectCode.trim();
        query.medicalRecordReviewCount = 0;
        query.ProjectRetrieveTypeID = this.state.selectedInventory;

        query.programs = this.state.selectedPrograms.map(x => {
            return {
                programID: x.programID,
                programDescription: x.programDescription,
                isDefaultDisplay: x.programID === Program.Stars
            }
        });

        query = this.setInventoryProps(query);
        query = this.setRADVAuditTypeID(query);

        if (this.state.project.initialReviewProcessingDelayInMin !== this.props.clientDefaultReviewProcessingDelay) {
            query.initialReviewProcessingDelayInMin = this.state.project.initialReviewProcessingDelayInMin;
        }

        return query;
    }

    mapExistingRecordForSave = () => {
        let query = { ...this.state.savedProject };

        query.active = this.state.project.active;
        query.showTargetedConditions = this.state.project.showTargetedConditions;
        query.ProjectRetrieveTypeID = this.state.selectedInventory;
        query.campaignID = this.state.project.campaignID;

        if (!this.state.containsInProgressReviews) {
            query.projectCode = this.state.project.projectCode.trim();
            query.dataCollectionYear = this.state.project.dataCollectionYear;

            //remove unselected programs
            query.programs = query.programs.filter(x =>
                this.state.selectedPrograms.find(y => y.programID === x.programID));

            //add newly-selected programs
            this.state.selectedPrograms.forEach(x => {
                let exists = query.programs.find(i => { return i.programID === x.programID; })
                if (!exists) {
                    query.programs.push({
                        programID: x.programID,
                        programDescription: x.programDescription,
                        isDefaultDisplay: x.programID === Program.Stars
                    });
                }
            });
            query.projectName = this.state.project.projectName.trim();

            query = this.setInventoryProps(query);
        }

        query = this.setRADVAuditTypeID(query);

        if (this.state.project.initialReviewProcessingDelayInMin !== this.props.clientDefaultReviewProcessingDelay) {
            query.initialReviewProcessingDelayInMin = this.state.project.initialReviewProcessingDelayInMin;
        }

        return query;
    }

    setInventoryProps = (query) => {
        query.containsPrePopulatedReviews = false;
        query.containsPrePopulatedReviewsAlt = false;
        query.isEntityMRR1to1 = false;        
        
        if(this.state.selectedInventory === ProjectInventoryType.Vendor 
            || this.state.selectedInventory === ProjectInventoryType.RMVendor)
        {
            query.containsPrePopulatedReviews = true;
            query.containsPrePopulatedReviewsAlt = true;
            query.isEntityMRR1to1 = true;
        }

        return query;
    }

    setRADVAuditTypeID = (query) => {
        if (!this.radvProjectIsSelected()) {
            delete query.selectedAuditTypeID;
        }
        else {
            query.selectedAuditTypeID = this.state.project.selectedAuditTypeID;
        }

        return query;
    }

    saveProject = () => {
        const errors = this.validateProject();
        if (errors.length > 0) {
            this.props.toast('error', errors[0], 'Error');
            return;
        }

        this.props.selectedProjectID ?
            this.props.updateProject(this.mapExistingRecordForSave())
            : this.props.createProject(this.mapNewRecordForSave());

        this.close();
    }

    radvProjectIsSelected = () => this.state.selectedPrograms.filter(x => x.programID === Program.RADV).length

    programToggleIsDisabled = (isAdd) => {
        if (this.state.containsInProgressReviews || !this.state.activeProgramID) {
            return true;
        }
        else {
            const alreadySelected = this.state.selectedPrograms.some(x => x.programID === this.state.activeProgramID);
            return isAdd ? alreadySelected : !alreadySelected;
        }
    }

    toggleCampaignEditModal = () => {
        this.setState({showCampaignEditModal: !this.state.showCampaignEditModal});
    }
    

    renderProgramSelections = () => {
        const grouped = [[]];
        let row = 0;
        let count = 0;
        this.props.programs.forEach(x => {
            if (count < 3) {
                grouped[row].push(x);
                count++;
            }
            else {
                row++;
                grouped[row] = [];
                grouped[row].push(x);
                count = 1;
            }
        });

        return (
            <React.Fragment>
                <Row style={{ marginTop: '2rem' }}>
                    <Col xs={12}>
                        <ControlLabel className="requiredField">Programs</ControlLabel>
                    </Col>
                </Row>
                {
                    grouped.map(x => {
                        return (
                            <Row key={`${x[0].programID}-${x[1].programID}-${x[2].programID}`}>
                                <Col xs={4}>
                                    <Checkbox onChange={(e) => this.handleProgramSelect(e, x[0].programID)}
                                        key={x[0].programID} checked={Boolean(this.state.selectedPrograms.find(y => y.programID === x[0].programID))}
                                        disabled={this.state.containsInProgressReviews}>
                                        {x[0].programDescription}
                                    </Checkbox>
                                </Col>
                                <Col xs={4}>
                                    <Checkbox onChange={(e) => this.handleProgramSelect(e, x[1].programID)}
                                        key={x[1].programID} checked={Boolean(this.state.selectedPrograms.find(y => y.programID === x[1].programID))}
                                        disabled={this.state.containsInProgressReviews}>
                                        {x[1].programDescription}
                                    </Checkbox>
                                </Col>
                                <Col xs={4}>
                                    <Checkbox onChange={(e) => this.handleProgramSelect(e, x[2].programID)}
                                        key={x[2].programID} checked={Boolean(this.state.selectedPrograms.find(y => y.programID === x[2].programID))}
                                        disabled={this.state.containsInProgressReviews}>
                                        {x[2].programDescription}
                                    </Checkbox>
                                </Col>
                            </Row>
                        );
                    })
                }
            </React.Fragment>
        );
    }

    render() {
        this.disableFields();
        return (
            <Modal show={this.props.visible} onHide={this.close} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: "inline-block" }}>
                        {this.props.selectedProjectID ? "Edit Project" : "Add Project"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loader loaded={this.state.loaded}>
                        <Grid fluid style={{ minHeight: '300px' }}>
                            <Row style={{ marginTop: '1rem' }}>
                                <Col xs={6}>
                                    <FormGroup>
                                        <ControlLabel>Campaign Name</ControlLabel>
                                        <Select value={this.state.project.campaignID} 
                                            options={this.props.campaignDropDownOptions}
                                            onChange={this.handleCampaignChange}
                                            disabled={this.state.disabled}
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs={1}>
                                    <div style={{ paddingTop: '2.4rem' }}>
                                        <Button onClick={this.toggleCampaignEditModal}
                                        disabled={this.state.disabled}>
                                        <Glyphicon glyph="plus" />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                                <Col xs={6}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Project Name</ControlLabel>
                                        <FormControl type="text" placeholder="Project Name"
                                            maxLength={255} value={this.state.project.projectName}
                                            disabled={this.state.containsReadyReviews
                                                || this.state.containsInProgressReviews}
                                            onChange={this.handleTextBoxChange('projectName')} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Project Code</ControlLabel>
                                        <FormControl type="text" placeholder="Project Code"
                                            maxLength={10} disabled={this.state.containsInProgressReviews}
                                            value={this.state.project.projectCode}
                                            onChange={this.handleTextBoxChange('projectCode')} />
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Collection Year</ControlLabel>
                                        <Select value={this.state.project.dataCollectionYear}
                                            disabled={this.state.containsInProgressReviews}
                                            options={this.state.collectionYears.map(x => {
                                                return { label: x, value: x }
                                            })}
                                            onChange={this.handleDataCollectionYearChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                                <Col xs={3} style={{ textAlign: 'left' }}>
                                    <ControlLabel className="requiredField">Inventory</ControlLabel>
                                    <FormGroup>
                                        <div style={{marginBottom: '1rem'}} inline="true" value={1}>
                                            <Select disabled={this.state.containsReadyReviews
                                                || this.state.containsInProgressReviews}
                                                value={this.state.selectedInventory}
                                                options={this.state.inventoryOptions}
                                                onChange={this.handleInventoryOptionsChange}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <ControlLabel>Show Target/Suspect HCCs</ControlLabel>
                                    <ButtonGroup>
                                        <Button bsStyle={this.state.project.showTargetedConditions
                                            ? 'success' : 'default'}
                                            disabled={this.state.project.showTargetedConditions}
                                            onClick={() => this.toggleChecked('showTargetedConditions')}>
                                            Yes
                                        </Button>
                                        <Button 
                                            disabled={!this.state.project.showTargetedConditions}
                                            onClick={() => this.toggleChecked('showTargetedConditions')}>
                                            No
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                                <Col xs={3}>
                                    <ControlLabel>Active</ControlLabel>
                                    <br/>
                                    <ButtonGroup>
                                        <Button bsStyle={this.state.project.active ? 'success' : 'default'}
                                            active={this.state.project.active}
                                            disabled={this.state.project.active}
                                            onClick={() => this.toggleChecked('active')}>Yes</Button>
                                        <Button active={!this.state.project.active}
                                            disabled={!this.state.project.active}
                                            onClick={() => this.toggleChecked('active')}>No</Button>
                                    </ButtonGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel className="requiredField">Processing Delay (Min)</ControlLabel>
                                        <FormControl type="number" min="0" max="32767" placeholder="Processing Delay"
                                            value={this.state.project.initialReviewProcessingDelayInMin ?
                                                this.state.project.initialReviewProcessingDelayInMin
                                                : this.props.clientDefaultReviewProcessingDelay}
                                            onChange={this.handleTextBoxChange('initialReviewProcessingDelayInMin')} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '1rem' }} hidden={!this.radvProjectIsSelected()}>
                                <FormGroup>
                                    <Col xs={12}>
                                        <ControlLabel className="requiredField">RADV Audit Type</ControlLabel>
                                        <Radio onChange={this.handleAuditTypeSelect(1)}
                                            checked={this.state.project.selectedAuditTypeID === 1}>
                                            Contract
                                        </Radio>
                                        <Radio onChange={this.handleAuditTypeSelect(2)}
                                            checked={this.state.project.selectedAuditTypeID === 2}>
                                            National
                                        </Radio>
                                        <Radio onChange={this.handleAuditTypeSelect(3)}
                                            checked={this.state.project.selectedAuditTypeID === 3}>
                                            Readiness
                                        </Radio>
                                    </Col>
                                </FormGroup>
                            </Row>
                            {this.renderProgramSelections()}
                        </Grid>
                        <CampaignEditorModal visible={this.state.showCampaignEditModal}
                            toggleModal={this.toggleCampaignEditModal} 
                            toast={this.props.toast}
                            refreshCampaignDropDownOptions={this.props.refreshCampaignDropDownOptions} />

                    </Loader>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.saveProject} style={{ float: 'left' }} bsStyle="success">Save</Button>
                    <Button onClick={this.close} style={{ float: 'right' }}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
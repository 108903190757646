import React from 'react';
import axios from 'axios';
import { Button, Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
import ActionMenu from './ActionMenu';
import AlertDialog from '../../../Shared/AlertDialog';
import ReviewCommentModal from './ReviewCommentModal';
import CoderQAReviewCommentModal from './CoderQAReviewCommentModal';
import ProjectEditModal from './ProjectEditModal';
import CoderQAReviewLevelModal from '../../../Reports/CoderQAReviewLevelModal';
import { MedicalRecordReviewStatus } from '../../../../enums/MedicalRecordReviewStatus';
import { getRedirect } from '../../../../services/ReviewProcessing';
import { connect } from 'react-redux';
import ImageViewerContainer from '../ImageViewer/ImageViewerContainer';
import MedicalRecordDisplayViewerContainer from '../../../EMRD/MedicalRecordDisplayViewerContainer';
import { ProjectInventoryType } from '../../../../enums/ProjectInventoryType';

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

class ActionBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProjectEditButton: false,
            showReviewCommentModal: false,
            showConfirmModal: false,
            showCoderQAReviewCommentModal: false,
            showProjectEditModal: false,
            comments: this.props.reviewProcessingUIConfig.comments ? this.sortComments([...this.props.reviewProcessingUIConfig.comments]) : []
        }
    }

    sortComments = (comments) => {
        return comments.sort((a, b) => {
            return (a.commentRank ? a.commentRank : Number.MAX_SAFE_INTEGER) - (b.commentRank ? b.commentRank : Number.MAX_SAFE_INTEGER)
                || a.commentText.localeCompare(b.commentText);
        });
    }

    setSelectedComments = (updatedComments) => {
        this.setState({ comments: this.sortComments(updatedComments) });
    }

    updateReview = (submitButton, getNextCDIAlertReview) => {
        //todo: confirm that commentDefinitionCD is the desired value (vs. commentDefinitionID)
        this.props.updateReview({
            selectedComments: this.state.comments.filter(x => x.isChecked === true).map(x => x.commentDefinitionCD).join(','),
            submitButton
        }, getNextCDIAlertReview);
    }

    handleConfirm = () => {
        switch (this.state.confirmAction) {
            case 'forceQA':
                this.forceQA();
                break;
            case 'reopen':
                this.editCompletedReview();
                break;
            case 'extract':
                this.preExtractQAReview();
                break;
            case 'coderQA':
                this.createCoderQAQueue();
                break;
            default:
                break;
        }
    }

    // force QA
    forceQA = () => {
        this.updateReview("Force QA", false);
    }

    // reopen review to edit completed review
    editCompletedReview = () => {
        axios.post(`/medicalRecordReview/reopen?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`)
        .then((response) => {
            window.location.href = `/ReviewProcessing/InitialCoding?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`;
        })
        .catch((error) => {
            if (error.message) {
                this.props.toast('error', error.message, 'Error');
            }
            else {
                this.props.toast('error', 'Could not reopen review.', 'Error');
            }
        })
    }

    // reopen extract review in MD review status
    preExtractQAReview = ()=> {
        axios.post(`/medicalRecordReview/preextractqa?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`)
        .then((response) => {
            window.location.href = `/ReviewProcessing/MD?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`;
        })
        .catch((error) => {
            if (error.message) {
                this.props.toast('error', error.message, 'Error');
            }
            else {
                this.props.toast('error', 'Could not send review to Pre-Extract QA.', 'Error');
            }
        })
    }

    // create new coderQA queue and redirect to next review
    createCoderQAQueue = () => {
        axios.post(`/coderQA/medicalRecordReview?medicalRecordReviewID=${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`)
        .then((response) => {
            this.props.getNextReviewCoderQA(response.data);
        })
        .catch((error) => {
            if (error.message) {
                this.props.toast('error', error.message, 'Error');
            }
            else {
                this.props.toast('error', 'Could not create Coder QA queue.', 'Error');
            }
        })
    }

    openCommentHistory = () => {
        window.location.href = `/PEC/ExceptionComments/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`;
    }

    returnToLanding = () => {
        getRedirect({
            redirectAction: "Index",
            redirectController: "Landing"
        });
    }

    toggleReviewCommentModal = () => {
        this.setState({ showReviewCommentModal: !this.state.showReviewCommentModal });
    }

    toggleProjectEditModal = () => {
        this.setState({ showProjectEditModal: !this.state.showProjectEditModal });
    }

    handleProjectEditButton = (status) => {
        this.setState({ showProjectEditButton: status });
    }

    toggleConfirmModal = (action) => {
        let modalMessage = '';
        switch (action) {
            case 'forceQA':
                modalMessage = "Are you sure you want to perform a Force QA?"
                break;
            case 'reopen':
                modalMessage = "Reopen this review for editing?";
                break;
            case 'extract':
                modalMessage = "Perform Pre-Extract QA for this review?";
                break;
            case 'coderQA':
                modalMessage = "Create a Coder QA Queue?";
                break;
            default:
                break;
        }
        this.setState({
            showConfirmModal: !this.state.showConfirmModal,
            confirmAction: action,
            modalMessage: modalMessage
        });
    }

    navigateToRmrAccessReadOnlyView = () => {
        getRedirect({
            redirectAction: "Access",
            redirectController: "EMR",
            params: {
                emrInstanceID : this.props.reviewProcessingUIConfig.campaignEmrAccessInstanceId,
                practiceID: this.props.reviewProcessingUIConfig.practiceId
            }
        });
    }

    handleEMRAccessButtonClick = () => {
        window.open( `/EMR/Access/${this.props.reviewProcessingUIConfig.campaignEmrAccessInstanceId}/practice/${this.props.reviewProcessingUIConfig.practiceId}/viewonly`);
    }

    handleDocumentsButtonClick = () => {
        window.open( `/EMR/practices/${this.props.reviewProcessingUIConfig.campaignEmrAccessInstanceId}/documents/viewonly`);
    }

    toggleCoderQAReviewCommentModal = () => {
        this.setState({ showCoderQAReviewCommentModal: !this.state.showCoderQAReviewCommentModal });
    }

    renderImageViewerButton = () => {
        return this.props.reviewProcessingUIConfig.hasImages ?
            <ImageViewerContainer medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID} />
            : null;
    }

    renderEMRDViewerButton = () => {
        return  (
            <MedicalRecordDisplayViewerContainer memberMasterID={this.props.reviewProcessingUIConfig.memberInternalKey} 
                medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                hasEMRData={this.props.reviewProcessingUIConfig.hasEMRData} />
            )
    }

    renderCoderQAScorecardButton = () => {
        return (
            <CoderQAReviewLevelModal displayValue={'Scorecard'} displayColor="#333" showScorecard={true}
                medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                coderQAQueueID={this.props.reviewProcessingUIConfig.coderQAQueueID}
                currentUser={this.props.currentUser} openedFromReview={true} toast={this.props.toast} />
        );
    }

    render() {
        return (
            <div>
                <Navbar fluid style={{ background: '#343a40', border: 'none' }}>
                    <Nav pullLeft bsClass="nav nav-no-padding">
                        {this.renderImageViewerButton()}
                        {this.renderEMRDViewerButton()}
                        <NavItem>
                            <div className="coding-actionbar-desc-div">
                                <span className="topLogo">
                                    {this.props.reviewProcessingUIConfig.reviewTypeDescription}
                                </span>
                                <span className="topLogo">
                                    <b>Project: </b>{this.props.reviewProcessingUIConfig.projectName}
                                </span>
                                {
                                    this.props.reviewProcessingUIConfig.practiceName && 
                                    this.props.reviewProcessingUIConfig.practiceName.trim() &&
                                    <span className="topLogo">
                                        <b>Practice Name: </b>{this.props.reviewProcessingUIConfig.practiceName}
                                    </span>
                                }
                                <span className="topLogo">
                                    <b>MRR ID: </b>{this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                                </span>
                                <span className="topLogo">
                                    <b>NPI: </b>{this.props.reviewProcessingUIConfig.providerNPI ?
                                        this.props.reviewProcessingUIConfig.providerNPI : 'Not available'}
                                    <span className="tooltipText">
                                        {this.props.reviewProcessingUIConfig.providerFullName ?
                                        this.props.reviewProcessingUIConfig.providerFullName : 'Not available'}
                                    </span>
                                </span>
                            </div>
                        </NavItem>
                    </Nav>
                    <Nav pullRight>
                    {
                            this.props.reviewProcessingUIConfig.campaignEmrAccessInstanceId &&
                            this.props.reviewProcessingUIConfig.projectRetrieveTypeID === ProjectInventoryType.EMR &&
                            <NavItem>
                                <Button onClick={this.handleEMRAccessButtonClick}>
                                    <Glyphicon glyph="comment" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}} /> EMR Access
                                </Button>
                                <Button onClick={this.handleDocumentsButtonClick} style={{marginLeft: '1rem'}} >
                                    <Glyphicon glyph="comment" style={{fontWeight: '800', margirIGHT: '0.6rem', verticalAlign: 'text-top'}} /> Documents
                                </Button>
                            </NavItem>
                        }
                        {
                            this.props.reviewProcessingUIConfig.isCoderQA &&
                            <NavItem className="scorecardButton">
                                <Button onClick={() => this.toggleConfirmModal('forceQA')}>
                                    <Glyphicon bsSize="large" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Force QA
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display force QA
                            this.props.reviewProcessingUIConfig.userCanForceQA &&
                            <NavItem className="forceQAButton">
                                <Button onClick={() => this.toggleConfirmModal('forceQA')}>
                                    <Glyphicon bsSize="large" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Force QA
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display reopen icon in completed reviews
                            this.props.reviewProcessingUIConfig.initialReviewCompletionDate &&
                            this.props.reviewProcessingUIConfig.initialCoderUserID === this.props.reviewProcessingUIConfig.userID &&
                            !this.props.reviewProcessingUIConfig.hasPassedCoding &&
                            (this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.EvaluationForQA
                                || this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.ReadyForQA
                                || this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.ReadyForMDQA) &&
                            <NavItem className="reopenButton">
                                <Button onClick={() => this.toggleConfirmModal('reopen')}>
                                    <Glyphicon bsSize="large" glyph="pencil" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Reopen
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display pre-extract qc icon in completed reviews
                            this.props.reviewProcessingUIConfig.initialReviewCompletionDate &&
                            this.props.reviewProcessingUIConfig.hasPreExtractPermission &&
                            this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.ExtractReview &&
                            <NavItem className="reopenButton">
                                <Button onClick={() => this.toggleConfirmModal('extract')}>
                                    <Glyphicon bsSize="large" glyph="pencil" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Pre-Extract QC
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display coderQA button in completed reviews
                            this.props.reviewProcessingUIConfig.hasCoderQAPermission &&
                            this.props.reviewProcessingUIConfig.initialCoderUserID !== this.props.reviewProcessingUIConfig.userID &&
                            !this.props.reviewProcessingUIConfig.hasCoderQARecord &&
                            this.props.reviewProcessingUIConfig.reviewStatusID === MedicalRecordReviewStatus.SubmissionEvaluationComplete &&
                            <NavItem className="reopenButton">
                                <Button onClick={() => this.toggleConfirmModal('coderQA')}>
                                    <Glyphicon bsSize="large" glyph="pencil" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}}/>Coder QA
                                </Button>
                            </NavItem>
                        }
                        {
                            // logic to display button to redirect to comment history page if available
                            this.props.reviewProcessingUIConfig.hasCommentHistory &&
                            <NavItem className="reopenButton">
                                <Button onClick={this.openCommentHistory}>Comment History Available</Button>
                            </NavItem>
                        }
                        {
                             this.state.showProjectEditButton &&
                            !this.props.reviewProcessingUIConfig.isComplete &&
                            <NavItem componentClass="span">
                               <Button onClick={this.toggleProjectEditModal}>
                                 <Glyphicon glyph="pencil" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}} /> Edit Project
                               </Button>
                            </NavItem>
                        }
                        <NavItem>
                            <Button onClick={this.props.reviewProcessingUIConfig.isCoderQA ? this.toggleCoderQAReviewCommentModal : this.toggleReviewCommentModal}>
                                <Glyphicon glyph="comment" style={{fontWeight: '800', marginRight: '0.6rem', verticalAlign: 'text-top'}} /> Comments
                            </Button>
                        </NavItem>
                        {
                            this.props.reviewProcessingUIConfig.isComplete &&
                            <NavItem>
                                <Button onClick={this.returnToLanding}>
                                    Return to Landing
                                </Button>
                            </NavItem>
                        }
                        {
                            !this.props.reviewProcessingUIConfig.isComplete &&
                            <NavItem componentClass="span">
                                <ActionMenu reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} updateReview={this.updateReview}
                                    getNextAlertReviewDefault={this.props.getNextAlertReviewDefault} reviewType={this.props.reviewType}
                                    showEscalateModal={this.props.showEscalateModal} renderEscalateModal={this.props.renderEscalateModal}
                                    />
                            </NavItem>
                        }
                    </Nav>
                </Navbar>
                <ReviewCommentModal comments={this.state.comments} reviewHasAddedRecords={this.props.reviewHasAddedRecords}
                    setSelectedComments={this.setSelectedComments} setPECCorrectionCommentSelected={this.props.setPECCorrectionCommentSelected}
                    visible={this.state.showReviewCommentModal} handleModalToggle={this.toggleReviewCommentModal} reviewType={this.props.reviewType} 
                    handleCDIFreeText={this.props.handleCDIFreeText} reviewProcessingUIConfig={this.props.reviewProcessingUIConfig}/>
                <CoderQAReviewCommentModal comments={this.state.comments} visible={this.state.showCoderQAReviewCommentModal}
                    medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID} projectID={this.props.reviewProcessingUIConfig.projectID}
                    coderQAQueueID={this.props.reviewProcessingUIConfig.coderQAQueueID} handleModalToggle={this.toggleCoderQAReviewCommentModal}
                    isCoderQAComplete={this.props.reviewProcessingUIConfig.isCoderQAComplete} toast={this.props.toast} />
                <AlertDialog visible={this.state.showConfirmModal} handleModalToggle={this.toggleConfirmModal} title={this.state.modalMessage}
                    renderBody={false} handleConfirm={this.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <ProjectEditModal medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID} toast={this.props.toast}
                    handleNavBarProject={this.props.handleNavBarProject} projectID={this.props.reviewProcessingUIConfig.projectID}
                    handleProjectEditButton={this.handleProjectEditButton} showProjectEditModal={this.state.showProjectEditModal} 
                    handleProjectEditModalToggle={this.toggleProjectEditModal}/>
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(ActionBar);
import React from 'react';
import { BootstrapTable, TableHeaderColumn, BootstrapButton } from 'react-bootstrap-table';
import { Button, Col, Row, Glyphicon } from 'react-bootstrap';

    const ProjectPriority = (props) => {

        const getMappedProjects = () => {
            return props.projects.map(x => {
                return { value: x.projectID, text: x.projectName };
            });
        }
    
        const formatProjects = (cell) => {
            const matchingOption = getMappedProjects().filter(x => x.value === cell);
            return matchingOption.length > 0 ? matchingOption[0].text : '';
        }

        const buttonFormatter = (cell, row) =>{
            return (
                
            <div>
                <Button style={{marginRight: '0.7rem'}}
                        onClick={() => props.handleUpdatePriorityAddOne(row)}
                        disabled={props.disableProjectPriorityButtons}>
                    <Glyphicon glyph='arrow-up' style={{ top: '1.6px',
                        lineHeight: '14px'}} />
                </Button>
                <Button style={{marginRight: '0.7rem'}}
                        onClick={() =>props.handleUpdatePrioritySubtractOne(row)}
                        disabled={props.disableProjectPriorityButtons}>
                    <Glyphicon glyph='arrow-down' style={{ top: '1.6px',
                        lineHeight: '14px'}} />
                </Button>
                <Button bsStyle="success" style={{marginRight: '0.7rem'}}
                        onClick={() => props.handleUpdatePriorityToOne(row)}
                        disabled={props.disableProjectPriorityButtons}>
                        Top
                </Button>
            </div>
            )
          }
        return(
            <div style={{ marginTop: '1rem' }}>
                <BootstrapTable data={props.data} maxHeight="516px" margin="100px">
                    <TableHeaderColumn dataField="campaignProjectID" isKey hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="projectID"  dataAlign="left" dataFormat={formatProjects}>Project Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="projectPriority"  dataAlign="left">Project Priority</TableHeaderColumn>
                    <TableHeaderColumn dataField="button" dataFormat={buttonFormatter}>Change Priority</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

export default ProjectPriority;
// exports CommentGroup - an immutable object 'enum'
// usage: import { CommentGroup } from '../../enums/CommentGroup';
const commentGroupTypes = {
    Angina: 1,
    PulmonaryDisease: 2,
    CoagulationDefects: 3,
    CongestiveHeartFailure: 4,
    DiabetesWithoutComplications: 5,
    DisordersOfImmunity: 6,
    EndocrineMetabolicDisorder: 7,
    MentalHealth: 8,
    NutritionalDisorders: 9,
    Paraplegia: 10,
    RenalFailure: 11,
    VascularDisease: 12,
    ChronicConditions: 13,
    EMR: 14,
    HighmarkEO: 15,
    PECCorrection: 16
};
Object.freeze(commentGroupTypes);
export const CommentGroup = commentGroupTypes;
import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import { BootstrapTable, DeleteButton, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import AlertToastr from '../../../Shared/AlertToastr';
import { formatDatetimeString } from '../../../../utils/DateUtils';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';
import AddCredentialModal from './AddCredentialModal';
import { getRedirect } from '../../../../services/ReviewProcessing';

export default class CredentialManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addUserId: '',
            addPassword: '',
            credentials: [],
            showPassword: [],
            credentialTypeOptions: [],
            testData: [],
            showModal: false,
            displayName: null,
            users: [],
            
            isDisabled:this.props.location.pathname.toLowerCase().includes("viewonly") ? true:false,
            loaded: false,
            toast: null
        };
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentDidMount() {
        Promise.all([
        this.getCredentials(),
        this.getCredentialTypeOptions(),
        this.getDisplayName(),
        this.getUsers()
    ])
    .then((responses) => {
        this.setState({
            credentials: responses[0].data,
            credentialTypeOptions: responses[1].data[0].campaignEMRReferenceItems.map(x => {
                return {
                    label: x.campaignEMRReferenceItemDescription,
                    value: x.campaignEMRReferenceItemID
                }
            }),
            users: responses[3].data,
            loaded: true
        })
    })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else {
                this.state.toast('error', 'Could not load EMRCredential data.', 'Error');
            }
        });
    }
    
    

    getCredentials = () => {
        return axios.get(`/CampaignEMRCredential?campaignEMRInstanceID=${this.props.router.params.emrInstanceID}`);
    }

    getDisplayName = () => {
        return axios.get(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/displayName`)
    }

    getUsers = () => {
        return axios.get('/merlinUser');
    }

    handleModalOpen = () => {
        this.setState({ showModal: true });
    }

    handleModalClose = () => {
        this.setState({ showModal: false });
    }

    handleAddCredentialTypeChange = (e) => {
        this.setState({ addCredentialType: e.target.value });
    }

    handleAddUserIdChange = (e) => {
        this.setState({ addUserId: e.target.value });
    }

    handleAddPasswordChange = (e) => {
        this.setState({ addPassword: e.target.value });
    }

    isAddDisabled = () => {
        return this.state.addUserId && this.state.addUserId.length &&
            this.state.addPassword && this.state.addPassword.length;
    }

    saveNewEntry = (row) => {
        this.setState({ loaded: false })

        axios.post(`/CampaignEMRCredential?campaignEMRInstanceID`, {
            campaignEMRInstanceID: this.props.router.params.emrInstanceID,
            campaignEMRCredentialTypeID: row.campaignEMRCredentialTypeID,
            lastTestedDateTime: row.lastTestedDateTime,
            credentialUserID: row.credentialUserID,
            credentialPassword: row.credentialPassword
        })
        .then((response) => {
            this.getCredentials().then((response) => {
                this.setState({ 
                    credentials: response.data,
                    loaded: true
                })
            })
            this.state.toast('success', 'New Credential added.', 'Success');
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to add Credential.', 'Error');
            this.setState({ loaded: true })
        });
    }

    removeEntries = (rows) => {
        //this is a soft delete
        axios.put(`/campaignEMRCredentials/softDelete?id=${rows[0]}`)
            .then((response) => {
                const credentials = this.state.credentials.filter((x) => {
                    return x.campaignEMRCredentialID !== rows[0]
                });
                this.setState({ credentials });
            })
            .catch((error) => {
                this.state.toast('error', 'Failed to update soft Delete.', 'Error');
            });
    }

    updateEntry = (row, cellName, cellValue) => {
        this.setState({ loaded: false })

        axios.put(`/campaignEMRCredential`,{
            campaignEMRCredentialID: row.campaignEMRCredentialID,
            campaignEMRInstanceID: this.props.router.params.emrInstanceID,
            campaignEMRCredentialTypeID: row.campaignEMRCredentialTypeID,
            credentialUserID: row.credentialUserID,
            credentialPassword: row.credentialPassword,
            lastTestedDateTime: row.lastTestedDateTime
        })
        .then((response) => {
            this.getCredentials().then((response) => {
                this.setState({ 
                    credentials: response.data,
                    loaded: true
                })
            })
            this.state.toast('success', 'Credentials updated.', 'Success');
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to update credential.', 'Error');
            this.setState({ loaded: true })
        });
    }

    beforeUpdate = (row, cellName, cellValue) => {
        if (!cellValue || row[cellName].trim() === cellValue.trim()) 
            return false;
        
        return true;
    }

    togglePassword = (id) => {
        let updated = [];
        if (this.state.showPassword.includes(id)) {
            updated = this.state.showPassword.filter((x) => {
                return x !== id;
            })
        } else {
            updated = [...this.state.showPassword, id];
        }
        this.setState({ showPassword: updated });
    }

    renderPassword = (cell, row) => {
        const isVisible = this.state.showPassword.includes(row.campaignEMRCredentialID);

        return (
            <div style={{ display: "flex", flexDirection: "row", }}>
                <div style={{ flexGrow: 1 }}>
                    {
                        isVisible ?
                            cell
                            :
                            cell.replace(/./g, "*")
                    }
                </div>
                <Glyphicon glyph={isVisible ? "eye-close" : "eye-open"} style={{ marginLeft: '0.8rem' }} onClick={() => this.togglePassword(row.campaignEMRCredentialID)} />
            </div>
        )
    }

    renderDateTimeString = (cell, row) => {
        return formatDatetimeString(cell);
    }

    getCredentialTypeOptions = () => {
        return axios.get(`/campaignEMRReferenceItem?typeID=${CampaignEMRReferenceItemType.CredentialType}`);
    }

    getDisplayName = () => {
        axios.get(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/displayName`)
        .then((response) => {
            this.setState({
                displayName: [...response.data],
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status !== 403) {
                this.state.toast('error', 'Could not load Display Name.', 'Error');
            }
        })
    }

    createCustomModal = (onModalClose, onSave, columns, validateState, ignoreEditable) => {
        const attr = {
            onModalClose, onSave, columns, validateState, ignoreEditable
        };
        return (
            <AddCredentialModal {...attr} />
        );
    }

    handlePracticeButtonClick(){
        window.history.back();
    }

    handleEMRBackClick = () => {
        this.props.router.push(`/EMR/practices/`);
    }

    render() {
        const options = {
            onAddRow: this.saveNewEntry,
            onDeleteRow: this.removeEntries,
            insertBtn: () => <Button className="btn btn-primary" style={{ marginRight: "1em", marginBottom: "1em" }} onClick={this.handleModalOpen} disabled={this.state.isDisabled}>Add</Button>,
            deleteBtn: () => <DeleteButton className="btn btn-danger" style={{ marginBottom: "1em" }} disabled={this.state.isDisabled} />,    
        };

        const getMappedCredentialTypeOptions = () => {
            return this.state.credentialTypeOptions.map(x => {
                return { value: x.value, text: x.label };
            });
        }

        const getMappedUserIDs = () => {
            return this.state.users.map(x => {
                return { value: x.userID, text: x.fullName };
            });
        }

        const credentialTypeEditable = {
            type: 'select',
            options: {
                values: getMappedCredentialTypeOptions()
            }
        };

        const formatCredentialType = (cell, row) => {
            const matchingOption = getMappedCredentialTypeOptions().filter(x => x.value === cell);
            return matchingOption.length > 0 ? matchingOption[0].text : '';
        }

        const formatUserID = (cell, row) => {
            const matchingOption = getMappedUserIDs().filter(x => x.value === cell);
            return matchingOption.length > 0 ? matchingOption[0].text : '';
        }

        const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,
            afterSaveCell: this.updateEntry,
            beforeSaveCell: this.beforeUpdate,
        };

        // Note: switch this to 'checkbox' for multi select delete
        const selectRowProp = {
            mode: 'radio',
        };

        return (
            <Grid fluid style={{ marginBottom: '4rem' }}>
                    <Row style={{ marginBottom: "2em" }}>
                        <Col xs={9} style={{ paddingLeft: '0rem' }}>
                            <h3 style={{ display: 'inline-block' }}>EMR Credentials - {this.state.displayName}</h3>
                        </Col>

                        <Col xs={3} style={{ paddingTop: '0.6rem' }}>
                            <div style={{ float: 'right' }}>
                                <Button onClick={this.handleEMRBackClick} style={{ marginRight: '1rem' }} disabled={this.state.isDisabled}>
                                    EMR Summary
                                </Button>
                                <Button onClick={this.handlePracticeButtonClick} disabled={this.state.isDisabled} >
                                    EMR Access
                                </Button>                                
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <BootstrapTable
                            data={this.state.credentials}
                            options={options}
                            selectRow={selectRowProp}
                            cellEdit={cellEdit}
                            insertRow
                            deleteRow
                        >
                            <TableHeaderColumn dataField="campaignEMRCredentialID" hidden isKey hiddenOnInsert />
                            <TableHeaderColumn dataField="campaignEMRCredentialTypeID" editable={credentialTypeEditable} dataFormat={formatCredentialType}>
                                Credential Type  <Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} />
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="credentialUserID">
                                User ID  <Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} />
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="credentialPassword" dataFormat={this.renderPassword}>
                                Password  <Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} />
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="updatedDatetime" editable={false}  
                            dataFormat={this.renderDateTimeString}
                            >
                                Updated On
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="updatedUserID" editable={false} hiddenOnInsert dataFormat={formatUserID}>
                                Updated By
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="lastTestedDateTime"
                                editable={{ type: 'datetime' }}
                                hiddenOnInsert
                                dataFormat={this.renderDateTimeString}
                            >
                                Last Tested  <Glyphicon glyph="pencil" style={{ marginLeft: '0.8rem' }} />
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Row>

                <AddCredentialModal showModal={this.state.showModal} handleModalClose={this.handleModalClose} options={this.state.credentialTypeOptions} 
                    saveNewEntry={this.saveNewEntry} />
                <AlertToastr setAddToast={this.setAddToast} />
                <Loader loaded={this.state.loaded} />
            </Grid>
        );
    }
}
import React from 'react';
import axios from 'axios';
import { Button, DropdownButton, Glyphicon, Panel, MenuItem } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { DiagnosisInitiatorType } from '../../../../enums/DiagnosisInitiatorType';
import { LineItemReviewStatus } from '../../../../enums/LineItemReviewStatus';
import { ProviderType } from '../../../../enums/ProviderType';

class ClaimsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedHCCVersion: null
        };
    }

    addDiagFromClaim = (row) => {
        row.dateOfServiceTo = row.dateOfServiceThru;
        //planProviderID not used for now
        //row.planProviderID = null; 
        row.providerTypeID = row.dateOfServiceFrom === row.dateOfServiceTo ? (row.billType ? ProviderType.Outpatient : ProviderType.Physician) : ProviderType.Inpatient;
        row.initiatorTypeID = DiagnosisInitiatorType.Claim;
        row.initiatorIdentifier = row.claimNumber;
        row.medicalRecordTypeID = this.props.medicalRecordTypeID;
        row.medicalRecordImageID = -1;
        row.pageNumFrom = 1;
        row.pageNumTo = 1;
        row.sourceId = 202;
        row.diagnosisCDReviewStatusID = LineItemReviewStatus.Captured;
        row.deleteFlag = 0;
        row.userId = this.props.userID;

        axios.post(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiag`, row)
        .then((response) => {
            //refresh diag data
            this.props.setTriggerDiagRefresh(true);
        })
        .catch((error) => {
            this.props.toast('error', 'Could not create diagnosis from selected claim.', 'Error');
        });
    }

    componentWillMount() {
        this.setDefaultHCCModel(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.setDefaultHCCModel(nextProps);
    }

    setDefaultHCCModel = (props) => {
        if (!this.state.selectedHCCVersion && Object.keys(props.claimsData).length > 0) {
            this.setState({
                selectedHCCVersion: this.getDefaultHCCModel(props)
            });
        }
    }

    getDefaultHCCModel = (props) => {
        if (Object.keys(props.claimsData).length > 0) {
            if (this.lineOfBusiness === 'Commercial' && props.claimsData['v05']) {
                return 'v05';
            }
            else if (props.claimsData['v23']) {
                return 'v23';
            }
            else if (props.claimsData['v22']) {
                return 'v22';
            }
            else {
                return Object.keys(props.claimsData)[0];
            }
        }
        else {
            return null;
        }
    }

    formatDateString = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }

    diagExistsForClaim = (row) => {
        return this.props.claimsWithDiags.length > 0 &&
            this.props.claimsWithDiags.findIndex(diag => {
                return diag.initiatorIdentifier === row.claimNumber && diag.diagnosisCD === row.diagnosisCD
            }) > -1;
    }

    formatClaimsActions = (cell, row) => {
        return (
            <div>
                <Button onClick={() => this.addDiagFromClaim(row)}
                    style={this.diagExistsForClaim(row) || this.props.isComplete ? { visibility: 'hidden' } : {}}>
                    <Glyphicon glyph="plus" />
                </Button>
            </div>
        );
    }

    handleSelectHCCVersion = (hccVersion) => {
        this.setState({ selectedHCCVersion: hccVersion });
    }

    renderResults = (results) => {
        return this.state.selectedHCCVersion && results[this.state.selectedHCCVersion] ? (
            <BootstrapTable data={results[this.state.selectedHCCVersion]} className="profileTable claimsProfileTable" condensed>
                <TableHeaderColumn isKey dataSort dataField="claimNumber" width='11%'>Claim #</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="dateOfServiceThru" width='8%' dataFormat={this.formatDateString}>DOS</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="providerName" width='11%' tdStyle={{whiteSpace: 'normal'}}>Provider Name</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="providerID" width='8%'>Provider ID</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="providerNPI" width='10%'>NPI</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="hccNumber" width='8%'>HCC</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="diagnosisCD" width='8%'>DX Code</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="description" width='15%' tdStyle={{whiteSpace: 'normal'}}>Description</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="cpt" width='8%'>CPT Code</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField="billType" width='7%'>Bill Type</TableHeaderColumn>
                <TableHeaderColumn hidden={!this.props.displayAuditAndActions} dataFormat={this.formatClaimsActions} width='6%'></TableHeaderColumn>
            </BootstrapTable>
        ) : null;
    }

    render() {
        return (
            <Panel className="capturePanel claims-panel">
                {
                    this.state.selectedHCCVersion &&
                    <div style={{ paddingTop: '1.6rem' }}>
                        <DropdownButton id="claims-tab-selected-hcc-dropdown" bsStyle="default" bsSize="xsmall" title={this.state.selectedHCCVersion}
                            style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
                            <MenuItem header>HCC Version</MenuItem>
                            {
                                this.props.claimsData &&
                                Object.keys(this.props.claimsData).map((hccVersion, index) => {
                                    return this.props.claimsData[hccVersion] ? (
                                        <MenuItem key={index} eventKey={index} onSelect={() => this.handleSelectHCCVersion(hccVersion)}>{hccVersion.toUpperCase()}</MenuItem>
                                    ) : null
                                })
                            }
                        </DropdownButton>
                    </div>
                }
                {
                    this.renderResults(this.props.claimsData)
                }
            </Panel>
        );
    }
}

export default ClaimsTab;
import React from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import AutomationConfig from './AutomationConfig';
import PracticePriority from './PracticePriority';
import ProjectDetailTable from './ProjectDetailTable';
import { Row, Col,Grid, Button } from 'react-bootstrap';
import AlertDialog from '../../../Shared/AlertDialog';
import AlertToastr from '../../../Shared/AlertToastr';
import { getRedirect } from '../../../../services/ReviewProcessing';

class ProjectDetailContainer extends React.Component {
    constructor(props) {
        super(props);

        
        this.state = {
            activeTab: 1,
            showConfirmationModal: false,
            disablePracticePriorityButtons: false,
            campaignProject: [],
            campaignProjectPractice: [],
            largestPriority: 1,
            project: {},
            campaignAutomationConfig: [],
            projectTotals:{
                l1CoderCount:0,
                l2CoderCount:0,
                qaCoderCount:0,
                campaignID:0,
            },
            projectStartCount:{
                l1CoderCount:0,
                l2CoderCount:0,
                qaCoderCount:0,
                campaignID:0,
            },
            toast: null,
            projectStatusOptions: []
        }
    }

    componentDidMount() {
        Promise.all([
            this.getProject(), 
            this.getCampaignAutomationConfig(),
            this.getProjectAutomationTotals(),
            this.getProjectAutomation(),
            this.getCampaignProjectPractice(),
            this.getProjectStatuses()
        ])
        .then((responses) => {
            this.setState({
               project: responses[0].data,
               campaignAutomationConfig: responses[1].data,
               campaignProjectPractice: this.formatDisplayOrder(responses[4].data),
               largestPriority: this.getLargestPriority(responses[4].data),
               projectStatusOptions: this.getProjectStatusesOptions(responses[5].data)
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            }  else {
                this.state.toast('error', 'Could not load Project Detail data.', 'Error');
            }
        });
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }
   
    getProject() {
        return axios.get(`/projects/${this.props.router.params.projectID}?includeReviewCount=true&includeReviewRemainingCount=true`);
    }

    getProjectStatuses = () => {
        return axios.get('/projects/getProjectStatuses');
    }

    formatDisplayOrder = (campaignProjectPractices) => {
        return campaignProjectPractices.sort(
            (x, y) => (x.projectPriority > y.projectPriority) ? 1 : -1
        );
    }

    getLargestPriority = (campaignProjectPractices) => {
        return Math.max(...campaignProjectPractices.map(x => x.projectPriority));
    }

    getProjectStatusesOptions = (statuses) => {
        return statuses.map((x) => {
            return {
                label: x.projectStatusDesc,
                value: x.projectStatusID
            };
        });
    }

    getProjectAutomation(){
        axios.get(`/projectAutomationConfig/GetbyProject/${this.props.router.params.projectID}`)
        .then((response) => {
            if(response.data)
            {
            this.setState({
                projectStartCount:{
                    l1CoderCount: response.data.l1CoderCount,
                    l2CoderCount: response.data.l2CoderCount,
                    qaCoderCount: response.data.qaCoderCount,
                    campaignID: response.data.campaignID,
                }
            })}})
        .catch((error) => {
            this.state.toast('error', 'Could not load Project Automation data.', 'Error');
        });
    }

    handleSaveAutomationConfig = (row) => {
        axios.post(`/projectAutomationConfig/${row.projectID}`, {
            l1CoderCount: row.l1CoderCounts,
            l2CoderCount: row.l2CoderCounts,
            qaCoderCount: row.qaCoderCounts,
            codingCompletionDate: row.codingCompletionDate
        })
        .then(() => {
            this.getProjectAutomationTotals();
            this.getProjectAutomation();
        })
        .then(() => {
            this.state.toast('success', 'Automation config saved.', 'Success');
        }
        )
        .catch((error) => {
            this.state.toast('error', 'Could not save automation config.', 'Error');
        })   
    }

    getCampaignProjectPractice = () => {
        return axios.get(`/campaignProjectPractice/getCampaignProjectPracticeDTOByProjectID?projectID=${this.props.router.params.projectID}&campaignID=${this.props.router.params.campaignID}`);
    }

    getProjectAutomationTotals() {
        return axios.get(`/projectAutomationConfig/GetbyCampaign/${this.props.router.params.campaignID}`)
        .then((response) => {
            if(response.data)
            {
            this.setState({
                projectTotals:{
                    l1CoderCount: response.data.l1CoderCount,
                    l2CoderCount: response.data.l2CoderCount,
                    qaCoderCount: response.data.qaCoderCount,
                    campaignID: response.data.campaignID,
                }
            })}})
        .catch((error) => {
            this.state.toast('error', 'Could not load Project Automation Totals data.', 'Error');
        });
    }

    getCampaignAutomationConfig() {
        return axios
            .get(`/campaignProject/getCampaignProjectByProjectID?projectID=${this.props.router.params.projectID}`)
        .then ((response) => {
            return axios.get(`/campaignAutomationConfig/${response.data.campaignID}`)  
        });        
    }

    handleSelect = (eventKey) => {
        this.setState({
            activeTab: eventKey
        });
    }

    toggleConfirmationModal = () => {
        this.setState({ showConfirmationModal: !this.state.showConfirmationModal });
    }

    handleConfirmationClick = () => {
        //TODO: Save data
        this.toggleConfirmationModal();
    }

    updatePauseStatus = (campaignProjectPracticeID) => {
        axios.put(`/campaignProjectPractice/toggleCampaignProjectPracticePause?campaignProjectPracticeID=${campaignProjectPracticeID}`)
        .then((response) => {
            this.resetCampaignProject();
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update pause status.', 'Error');
        })
    }

    handleUpdatePriority = (row, priorityOffset) => {
        const priorityObjs = [];

        var CampaignProjectPracticeOne = {
            campaignID: row.campaignID,
            canpaignGroupID: row.campaignGroupID,
            campaignPracticeID: row.campaignPracticeID,
            campaignProjectPracticeID: row.campaignProjectPracticeID,
            projectPriority: row.projectPriority - priorityOffset,
            isPaused: row.isPaused
        }
        priorityObjs.push(CampaignProjectPracticeOne);

        var CampaignProjectPracticeTwo = this.state.campaignProjectPractice.find(x => x.projectPriority == row.projectPriority - priorityOffset);
        CampaignProjectPracticeTwo.projectPriority = CampaignProjectPracticeTwo.projectPriority + priorityOffset;
        priorityObjs.push(CampaignProjectPracticeTwo)
        

        axios.put(`/campaignProjectPractice/updatePriority?campaignID=${row.campaignID}`, priorityObjs)
        .then(() => {
            this.resetCampaignProject();
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update priority.', 'Error');
        });
    }

    handleUpdatePriorityToOne = (row) => {
        let priorityObjs = [];

        for (let i = 0; i < row.projectPriority - 1; i++) {
            const item = this.state.campaignProjectPractice[i];
            priorityObjs[i] = {
                campaignID: item.campaignID,
                campaignGroupID: item.campaignGroupID,
                campaignPracticeID: item.campaignPracticeID,
                campaignProjectPracticeID: item.campaignProjectPracticeID,
                projectPriority: item.projectPriority + 1,
                isPaused: item.isPaused
            }
        }

        var CampaignProjectPracticeToOne = {
            campaignID: row.campaignID,
            campaignGroupID: row.campaignGroupID,
            campaignPracticeID: row.campaignPracticeID,
            campaignProjectPracticeID: row.campaignProjectPracticeID,
            projectPriority: 1,
            isPaused: row.isPaused
        }
        priorityObjs.push(CampaignProjectPracticeToOne);

        axios.put(`/campaignProjectPractice/updatePriority?campaignID=${row.campaignID}`, priorityObjs)
        .then(() => {
            this.resetCampaignProject()
        })
        .catch((error) => {
            this.state.toast('error', 'Could not update priority.', 'Error');
        });
    } 

    resetCampaignProject = () =>
    {
        this.getCampaignProjectPractice()
        .then((responses) => {
            this.setState({
                campaignProjectPractice: this.formatDisplayOrder(responses.data)
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not reload data.', 'Error');
        });
    }

    handleBackCampaignManagementClick = () => {
        this.props.router.push(`/CampaignManagement`);
    }
    
    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={8} style={{ paddingLeft: '0rem' }}>
                        <h3>Project Detail - {this.state.project.projectName}</h3>
                    </Col>
                    <Col xs={4} style={{ paddingTop: '1rem', paddingRight: '0' }}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={this.handleBackCampaignManagementClick} style={{ marginRight: '1rem' }}>
                                Campaign Summary
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <ProjectDetailTable project={this.state.project} campaignAutomationConfigs={this.state.campaignAutomationConfig} 
                    getProjectAutomationTotals={this.state.getProjectAutomationTotals} campaignAutomationConfig={this.state} 
                    projectTotals={this.state.projectTotals} projectStartCount={this.state.projectStartCount}
                    projectStatusOptions={this.state.projectStatusOptions}
                    />
                </Row>
                <Row>
                    <Tabs id="projectDetail-tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                        <Tab eventKey={1} title="Automation Config">
                            <AutomationConfig 
                                projectID={this.props.router.params.projectID} toast={this.state.toast} projectTotals={this.state.projectTotals}
                                campaignAutomationConfig={this.state.campaignAutomationConfig} projectTotalsChange={this.projectTotalsChange}
                                handleSaveAutomationConfig={this.handleSaveAutomationConfig}
                                projectStartCount={this.state.projectStartCount}
                            />
                        </Tab>
                        <Tab eventKey={3} title="Practice/Practice Grouping">
                            <PracticePriority 
                                data={this.state.campaignProjectPractice} largestPriority={this.state.largestPriority}
                                updatePauseStatus={this.updatePauseStatus}
                                handleUpdatePriority={this.handleUpdatePriority}
                                handleUpdatePriorityToOne={this.handleUpdatePriorityToOne}
                            />
                        </Tab>
                    </Tabs>
                </Row>
                <AlertDialog visible={this.state.showConfirmationModal} handleModalToggle={this.toggleConfirmationModal}
                title={`Are you sure you want to delete assignment to this coder?`} renderBody={false} handleConfirm={this.handleConfirmationClick} 
                confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        )
    }
}

export default ProjectDetailContainer;
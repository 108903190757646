import React from 'react';
import axios from 'axios';
import {Button, Col} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../styles/Report.css';
import AlertToastr from '../Shared/AlertToastr';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';

class ExtractReviewReport extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            reportData: [],
            toast: null,
            hasOpenExtract: false
        }
    }

    componentWillMount() {
        axios.get('/starsextractreviewreport')
        .then(response => {
            let totalValues = {projectName: 'Total', reviewCount: 0, qualityCaptureCount: 0, diagCount: 0};
            response.data.forEach(function(item){
                totalValues.reviewCount += item.reviewCount;
                totalValues.qualityCaptureCount += item.qualityCaptureCount;
                totalValues.diagCount += item.diagCount;
            });
            response.data.push(totalValues);

            this.setState({
                reportData: response.data
            });
        })
        .catch(error => {
            this.setState({
                reportData: []
            });
            this.state.toast('error', 'Could not load extract review report data.', 'Error');
        })
        this.checkOpenApprovalStatus();
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    checkOpenApprovalStatus = () => {
        axios.get('/starsextractapproval/hasopenapproval')
        .then(response => {
            this.setState({hasOpenExtract: response.data});
        })
        .catch(error => {
            this.state.toast('error', 'Could not check status of open extract requests.', 'Error');
        })
    }

    approveReportConfirm = () => {
        if(window.confirm("Are you sure you want to approve all stars entries for extraction?")) {
            this.approveReport();
        }
    }

    approveReport =() => {
        axios.put('/starsextractapproval')
        .then(response => {
            this.state.toast('success', 'Approval request created successfully.', 'Success');
            this.checkOpenApprovalStatus();
        })
        .catch(error => {
            this.state.toast('error', 'Cannot approve extract report if there are open captures.', 'Error');
        });
    }

    //TODO: csv download stub
    buildFunctionForDownload = () => {
        return () => axios.post('/starsextractreviewreport/downloadrequests');
    }

    render() {
        return (
            <div className="reportContainer">
            <div className="innerReportContainer" style={{minHeight: '250px', marginLeft: '3%', marginRight: '3%'}}>
                <br />
                <header>
                    <Col style={{ marginLeft: 'auto', marginRight: 'auto', width: '240px' }}>
                        <h3>Extract Review Report</h3>
                    </Col>
                </header>

                <div className="copyContent" ref={(table) => this.table = table} style={{marginTop: '1.9rem', marginBottom: '1.9rem'}}>
                    {this.state.reportData &&
                        <div>
                        <BootstrapTable data={this.state.reportData} maxHeight='400px'>
                            <TableHeaderColumn dataField="projectID" isKey={true} hidden>Project</TableHeaderColumn>
                            <TableHeaderColumn dataField="projectName" dataSort width='100px'>Project</TableHeaderColumn>
                            <TableHeaderColumn dataField="reviewCount" dataSort width='120px'>Number of Reviews</TableHeaderColumn>
                            <TableHeaderColumn dataField="qualityCaptureCount" dataSort width='120px'>Number of Star Entries</TableHeaderColumn>
                            <TableHeaderColumn dataField="diagCount" dataSort width='120px'>Number of Diags</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    }
                </div>
                <div style={{marginBottom: '20px'}}>
                    <Button bsStyle="primary" disabled={this.state.hasOpenExtract} onClick={this.approveReportConfirm} style={{marginRight: '10px'}}>Approve</Button>
                    <div style={{float: 'right'}}>
                        <LongRunningDownloadAnchor linkText="Download .CSV"
                            postRequest={this.buildFunctionForDownload()}
                            displayCSVDownloader={this.state.reportData ? this.state.reportData.length : false}
                        />
                    </div>
                </div>
            </div>
            <AlertToastr setAddToast={this.setAddToast} />
        </div>
        );
    }
}

export default ExtractReviewReport;

import React from 'react';
import axios from 'axios';
import { Collapse, Row } from 'react-bootstrap';
import WorkflowExceptionsAddLine from './WorkflowExceptionsAddLine';
import WorkflowExceptionsEditLine from './WorkflowExceptionsEditLine';
import WorkflowSectionHeader from './WorkflowSectionHeader';

//todo -- test PEC correction mode (i.e. commentText is present)
//todo (extra) -- use consistent naming conventions (ex. 'resolved' vs 'addressed', etc.); prune unused props, etc. from API
const WorkflowExceptionsContainer = (props) => {
    const saveException = (item) => {
        axios.post(`/cdialerts/${props.workflow.cdiAlertWorkflowID}/corrections`, item)
        .then((response) => {
            props.refreshWorkflowExceptions();
        })
        .catch((error) => {
            props.toast('error', 'Could not save exception.', 'Error');
        });
    }

    const deleteException = (correctionID) => {
        axios.delete(`/cdialerts/${props.workflow.cdiAlertWorkflowID}/corrections/${correctionID}`)
        .then((response) => {
            props.refreshWorkflowExceptions();
        })
        .catch((error) => {
            props.toast('error', 'Could not delete exception.', 'Error');
        })

    }

    const resolvedOptions =  [
        { value: true, label: 'Yes'},
        { value: false, label: 'No'},
    ];

    const renderWorkflowExceptionEditLines = () => {
        return props.workflowExceptions.length > 0 ? props.workflowExceptions.map(x => {
            return (
                <WorkflowExceptionsEditLine key={x.cdiAlertWorkflowCorrectionID} correction={x}
                    workflowExceptionOptions={props.workflowExceptionOptions} resolvedOptions={resolvedOptions}
                    saveWorkflowException={saveException} deleteWorkflowException={deleteException} isViewOnly={props.isViewOnly} 
                    cdiFreeCommentText={x.cdiFreeCommentText} />
            );
        }) : (<Row style={{ paddingLeft: '2rem' }}><h5>No exceptions found.</h5></Row>)
    }

    return (
        <div className="alert-workflow-exceptions-container">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="exceptions" title="Exceptions" />
            <Collapse in={props.expanded}>
                <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                    <div className="alert-workflow-exceptions-edit-container">
                        {renderWorkflowExceptionEditLines()}
                    </div>
                    {
                        !props.isViewOnly &&
                        <div style={{ marginTop: '2rem' }}>
                            <hr/>
                            <WorkflowExceptionsAddLine resolvedOptions={resolvedOptions}
                                workflowExceptionOptions={props.workflowExceptionOptions}
                                saveWorkflowException={saveException} />
                        </div>
                    }
                </div>
            </Collapse>
        </div>
    );
}

export default WorkflowExceptionsContainer;
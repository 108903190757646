import React from 'react';
import { FormControl } from 'react-bootstrap';
import CodingDetailsNotLanding from '../Shared/CodingDetailsNotLanding';
import PDFViewerComponent from '../Shared/PDFViewerComponent';
 
class SubmittedCard extends React.Component {
    enableCMSResults = () => {
        if (this.props.radvAuditEnrolleeStatusID === 2) {
            return true;
        }
        else {
            return false;
        }
    }

    handleDropdownSelection = (event) => {
        const selectedCMS = ([...event.target.options].filter(o => o.selected).map(o => o.value))[0];
        this.props.handleDropdownSelection(selectedCMS, this.props.card.medicalRecordDiagRADVDetailID);
    }

	render() {
        const dosFrom = this.props.dosFrom ? new Date(this.props.dosFrom).toLocaleDateString('en-US') : "";
        const dosThru = this.props.dosThru ? new Date(this.props.dosThru).toLocaleDateString('en-US') : "";
        const submittedDate = this.props.submittedDatetime ? new Date(this.props.submittedDatetime).toLocaleDateString('en-US') : "";
        const evidenceID = this.props.medicalRecordImageID !== null && this.props.medicalRecordImageID > 0 ?
			this.props.medicalRecordImageID : "";
        const disableInterimDenied = this.props.radvAuditTypeID !== 2;
		return (
            <tr id={this.props.id}>
                <td>{this.props.rank === null ? this.props.systemRank : this.props.rank}</td>
                <td>{this.props.coversheetID ? this.props.coversheetID : ""}</td>
                <td>{this.props.scoreDesc ? this.props.scoreDesc : ""}</td>
                <td>{this.props.targetedHCC ? this.props.targetedHCC : ""}</td>
                <td>{this.props.nonRelatedHCC ? this.props.nonRelatedHCC : ""}</td>
                <td>{this.props.providerTypeDesc ? this.props.providerTypeDesc : ""}</td>
                <td>{dosFrom}</td>
                <td>{dosThru}</td>
                <td>{this.props.attestation ? this.props.attestation : ""}</td>
                <td><CodingDetailsNotLanding medicalRecordDiagRADVDetailID={this.props.card.medicalRecordDiagRADVDetailID} toast={this.props.toast} /></td>
                <td><PDFViewerComponent uri={`/medicalrecordreview/${this.props.card.medicalRecordReviewID}/images/${evidenceID}`}
                    displayText={evidenceID} toast={this.props.toast} /></td>
                <td>{this.props.submittedUserFullName ? this.props.submittedUserFullName : ""}</td>
                <td>{submittedDate}</td>
                <td>
                    <FormControl componentClass="select" disabled={!this.enableCMSResults()} placeholder={this.props.selectedCMS} value={this.props.selectedCMS} onChange={this.handleDropdownSelection}>
                        <option key="Submitted" value="Submitted">Submitted</option>
                        <option key="Accepted" value="Accepted">Accepted</option>
                        <option key="Denied" value="Denied">Denied</option>
                        <option key="Interim Denied" value="Interim Denied" disabled={disableInterimDenied}>Interim Denied</option>
                    </FormControl>
                </td>
            </tr>
        )
	}
}

export default SubmittedCard;
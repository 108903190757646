import React from 'react';
import { Col, ControlLabel, FormControl, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import '../../styles/Report.css'
import ReportDrillDownModal from './ReportDrillDownModal'

class ClaimsAuditReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectRecords: [],
            projectName: "",
            projectID: null,
            reportData: null,
            copied: "",
            copiedTimer: false,
            currentAuditProject: {
                projectName: "",
                projectID: null
            }
        }
    }

    componentWillMount() {
        axios.get('/applicationreports/5/projects')
        .then((response) => {
            this.setState({projectRecords: response.data})
        });
        // .catch((error) => {
        //     //todo: add error notification
        // });

        if (localStorage.currentAuditProject) {
            const project = JSON.parse(localStorage.currentAuditProject);
            this.setState({ currentAuditProject: project });
            this.getClaimsReportData(project.projectID);
        }
    }

    handleProjectChange = (evt) => {
        const projectName = [...evt.target.options].filter(o => o.selected).map(o => o.text)[0];
        const currentAuditProject = { projectID: evt.target.value, projectName: projectName };
        localStorage.currentAuditProject = JSON.stringify(currentAuditProject);
        this.setState({ currentAuditProject: currentAuditProject, projectID: currentAuditProject.projectID });
        this.getClaimsReportData(currentAuditProject.projectID);
    }

    handleBackClick() {
        // redirect back to MRR reports page on click of back button
        window.location.href = '/#/reports';
    }

    getClaimsReportData(currentProjectID) {
        if (currentProjectID > 0) {
            axios.get('/Reports/ClaimsAudit/ClaimsAuditReport?projectID=' + currentProjectID)
            .then((response) => {
                this.setState({ reportData: response.data, projectID: currentProjectID });
            });
            // .catch((error) => {
            //     //todo: add error notification
            // });
        }
        else {
            this.setState({ projectID: null });
        }
    }

    handleClaimsAuditCellClick(displayValue, diagReportFilter, modalTitle, initiatorTypeID, commentCD) {
        return <ReportDrillDownModal
            displayValue={displayValue} projectID={this.state.projectID}
            diagReportFilter={diagReportFilter} modalTitle={modalTitle}
            initiatorTypeID={initiatorTypeID} commentCD={commentCD} reportType={"ClaimsAudit"}
            displayDownloadButton={diagReportFilter === "CompletedHccs" && initiatorTypeID === 2} />
    }

    truncateDecimalPlace(value, decimalPlaces){
        return typeof(value) !== "number"
            ? value
            : (value % 1 === 0
                ? value
                : value.toFixed(decimalPlaces ? decimalPlaces : 2)
            );
    }

    copyToClipboard = (e) => {
        const element = this.table;
        const selection = window.getSelection();
        const range = window.document.createRange();
        try {
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.setState({ copied: "Copied.", copiedTimer: true });
            setTimeout(() => {
                this.setState({ copiedTimer: false })
            }, 3000);
        }
        catch (e) {
            this.setState({ copied: "Could not copy. Try again." });
        }
        selection.removeAllRanges();
    }

    render() {
        const reportData = this.state.reportData;
        return (
            <div className="reportContainer">
                <div className="innerReportContainer">
                    <br/>
                    <header>
                        <Col style={{marginLeft: 'auto', marginRight: 'auto', width: '240px'}}>
                            <h3>Claims Audit Report</h3>
                        </Col>
                    </header>

                    <div style={{float: 'left', width: '25%', marginBottom: '20px'}}>
                        <ControlLabel>Project:</ControlLabel>
                        <FormControl componentClass="select" placeholder="select" value={this.state.currentAuditProject.projectID !== null ? this.state.currentAuditProject.projectID : "Select"} onChange={this.handleProjectChange}>
                            <option value="Select">Select</option>
                            {this.state.projectRecords.map((pr) => <option key={pr.projectID} value={pr.projectID}>{pr.projectName}</option>)}
                        </FormControl>
                    </div>

                    <div style={{float: 'right'}}>
                        <Button bsStyle="info" onClick={this.copyToClipboard}>Copy</Button>
                        <p style={{display: this.state.copiedTimer === true ? 'block' : 'none'}}>{this.state.copied !== "" ?  this.state.copied : ""}</p>
                    </div>

                    <div className="copyContent" ref={(table) => this.table = table}>
                        {
                            reportData &&
                            <Table bordered className="claimsAuditTable" style={{display: this.state.projectID !== null ? 'table' : 'none'}}>
                                {/*Target data section*/}
                                <thead>
                                    <tr>
                                        <th colSpan="4" className="text-center">
                                            Target Data
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="2" style={{width: "50%"}}>Total Members</td>
                                        <td colSpan="2">{reportData.totalMembers}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total Charts</td>
                                        <td colSpan="2">{reportData.totalCharts}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total Claims Audited</td>
                                        <td colSpan="2">{reportData.totalClaims}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total HCCs Audited</td>
                                        <td colSpan="2">{this.handleClaimsAuditCellClick(reportData.totalClaimsAuditHCCs, "CompletedHccs", "Total Claims Audit HCCs", 2)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total HCCs Approved</td>
                                        <td style={{width: "25%"}}>{this.handleClaimsAuditCellClick(reportData.totalHCCsApproved, "ApprovedHccs", "Total HCCs Approved", 2)}</td>
                                        <td>{this.truncateDecimalPlace(reportData.totalHCCsApprovedPercent)}%</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total HCCs Not Approved</td>
                                        <td>{this.handleClaimsAuditCellClick(reportData.totalHCCsNonApproved, "NonApproved", "Total HCCs Not Approved", 2)}</td>
                                        <td>{this.truncateDecimalPlace(reportData.totalHCCsNonApprovedPercent)}%</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total Signature Impaired</td>
                                        <td>{this.handleClaimsAuditCellClick(reportData.totalApprovedSignatureImpaired, "Optimization", "Total Signature Impaired", 2, 201)}</td>
                                        <td>{this.truncateDecimalPlace(reportData.totalApprovedSignatureImpairedPercent)}%</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Total Optimization</td>
                                        <td colSpan="2">{this.handleClaimsAuditCellClick(reportData.totalOptimizations, "Optimization", "Total Optimization", 1)}</td>
                                    </tr>
                                </tbody>

                                {/*Not Approved Breakdown section*/}
                                <thead style={{display: reportData.totalHCCsNonApproved > 0 ? 'table-row-group' : 'none'}}>
                                    <tr>
                                        <th colSpan="4" className="text-center">
                                            Not Approved Breakdown
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style={{display: reportData.totalHCCsNonApproved > 0 ? 'table-row-group' : 'none'}}>
                                    <tr>
                                        <td colSpan="2">Total</td>
                                        <td colSpan="2">{this.handleClaimsAuditCellClick(reportData.totalHCCsNonApproved, "NonApproved", "Total HCCs Not Approved", 2)}</td>
                                    </tr>
                                    {
                                        reportData.claimsAuditNotApprovedHCCs &&
                                        reportData.claimsAuditNotApprovedHCCs.map((row, i) => {
                                            return (
                                                <tr>
                                                    <td colSpan="2" style={{paddingLeft: '5%'}}>{row.commentText}</td>
                                                    <td>{this.handleClaimsAuditCellClick(row.totalOccurrencesByProject, "NonApproved", row.commentText, 2, row.commentDefinitionID)}</td>
                                                    <td>{this.truncateDecimalPlace(row.totalPercent)}%</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ClaimsAuditReport;

import React from 'react';
import axios from 'axios';
import { Col, Grid, Row } from 'react-bootstrap';
import HCCProfileContainer from '../HCCProfile/HCCProfileContainer';
import StarsProfileContainer from '../StarsProfile/StarsProfileContainer';
import ClaimsContainerRedux from '../ClaimsAndAlerts/ClaimsContainerRedux';

class NLPHCCProfileTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableCollapsed: false
        };
    }

    collapseTable = () => {
        this.setState({ tableCollapsed: !this.state.tableCollapsed });
    }

    toggleHccWindow =(version) => {
       this.props.toggleHccWindow(version);
    }

    render() {
        return (
            <Grid fluid>        
                <Row style={{ paddingTop: '2rem' }}>
                    <Col xs={this.state.tableCollapsed ? 4 : 6} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                        <StarsProfileContainer memberID={this.props.memberID} visible={this.props.visible}
                                    toggleTab={this.props.toggleTab} selectStarMeasure={this.props.selectStarMeasure} 
                        />

                        <HCCProfileContainer reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} radvAudit={this.props.radvAudit}
                            visible={!this.props.visible} 
                            reviewType={this.props.reviewType}
                            toggleTab={this.props.toggleTab}
                            toggleHccWindow={this.toggleHccWindow}
                            hccIsPoppedOut={this.props.hccIsPoppedOut}
                        />
                    </Col>
                    <Col xs={6}>
                        <ClaimsContainerRedux reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} 
                                memberID={this.props.memberID} project={this.props.project}
                                pecCorrectionCommentSelected={this.props.pecCorrectionCommentSelected} 
                                toast={this.props.toast} />
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default NLPHCCProfileTab;
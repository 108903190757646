import React from 'react';
import { Button, Well, Label } from 'react-bootstrap'
import '../../styles/Report.css'

class EnrolleeReportFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            codingInProgress: true,
            submittedToCMS: true,
            cmsAccepted: true,
            interimDenied: true,
            cmsDenied: true,
            mdFlag: false,
            altChaseFlag: false,
            enrolleeID: null
        };
    }

    componentDidMount() {
        this.handleSearchClick();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.projectID && nextProps.projectID !== this.props.projectID) {
            this.handleSearchClick();
        }
    }

    handleStatusChange = (event) => {
        this.setState({ [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value });
    }

    handleSearchClick = () => {
        this.props.searchAction({
            codingInProgress: this.state.codingInProgress,
            submittedToCMS: this.state.submittedToCMS,
            cmsAccepted: this.state.cmsAccepted,
            interimDenied: this.state.interimDenied,
            cmsDenied: this.state.cmsDenied,
            mdFlag: this.state.mdFlag,
            chaseFlag: this.state.altChaseFlag,
            enrolleeID: this.state.enrolleeID
        });
    }

    render() {
        return (
            <div className="EnrolleeReportSearchFilter">
                <Well style={{ height: "230px", padding: "1%" }}>
                    <div className="statusOptions" style={{ float: 'left', marginLeft: "2%" }}>
                        HCC Status<br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="codingInProgress" value={1} checked={this.state.codingInProgress} onChange={this.handleStatusChange}/> Coding In Progress
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="submittedToCMS" value={2} checked={this.state.submittedToCMS} onChange={this.handleStatusChange}/> Submitted To CMS
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="cmsAccepted" value={4} checked={this.state.cmsAccepted} onChange={this.handleStatusChange}/> CMS Accepted
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="interimDenied" value={6} checked={this.state.interimDenied} onChange={this.handleStatusChange}/> Interim Denied
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="cmsDenied" value={5} checked={this.state.cmsDenied} onChange={this.handleStatusChange}/> CMS Denied
                        </label><br/>
                    </div>
                    <div className="enrolleeOptions" style={{float: 'left', marginLeft: "5%"}}>
                        Show Only<br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="mdFlag" value={"mdFlag"} checked={this.state.mdFlag} onChange={this.handleStatusChange}/> Flagged for Medical Director Review
                        </label><br/>
                        <label style={{ paddingTop: '3px', fontSize: '12px' }}>
                            <input type="checkbox" name="altChaseFlag" value={"altChaseFlag"} checked={this.state.altChaseFlag} onChange={this.handleStatusChange}/> Flagged for Alternate Chase
                        </label><br/>
                    </div>
                    <div className="enrolleeOptions" style={{ float: 'left', marginLeft: "5%" }}>
                        Enrollee ID<br/>
                        <input type="text" name="enrolleeID" value={this.state.enrolleeID ? this.state.enrolleeID : ''} onChange={this.handleStatusChange} style={{ fontSize: "13px" }}/>
                    </div>
                    <div className="legend" style={{ float: 'right', marginRight: "3%" }}>
                        Legend<br/>
                        <h4><span><Label className="legendLabel" style={{ backgroundColor: "rgba(191, 188, 188, 1)" }}>Coding In Progress</Label></span></h4>
                        <h4><span><Label className="legendLabel" style={{ backgroundColor: "rgba(0, 161, 224, 0.45)" }}>Submitted To CMS</Label></span></h4>
                        <h4><span><Label className="legendLabel" style={{ backgroundColor: "rgba(5, 181, 90, 0.6)" }}>CMS Accepted</Label></span></h4>
                        <h4><span><Label className="legendLabel" style={{ backgroundColor: "rgba(211, 132, 4, 0.6)" }}>Interim Denied</Label></span></h4>
                        <h4><span><Label className="legendLabel" style={{ backgroundColor: "rgba(181, 17, 5, 0.6)" }}>CMS Denied</Label></span></h4>
                    </div>
                    <div className="buttonActions" style={{ float: 'left', marginLeft: "5%"}}>
                        <Button bsStyle="primary" onClick={this.handleSearchClick} style={{ marginTop: '1.4rem' }}>Search</Button>
                    </div>
                </Well>
            </div>
        );
    }
}

export default EnrolleeReportFilter;
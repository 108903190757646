import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router';
import CodingDetails from './CodingDetails';
import StatusHistory from './StatusHistory';

class ResultTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCodingDetailsModal: false,
            showStatusHistoryModal: this.props.showStatusHistoryModal || false,
            selectedRow: {}
        }
    }

    rankingOverScoredLink = (cell, row) => {
        return <Link to={{
            pathname: '/RADV/RankingPage',
            query: { radvAuditID: this.props.radvAuditID, enrolleeID: row.radvAuditEnrolleeID }
        }}>{row.numberOfRankedDetails}/{row.numberOfHCCsScored}</Link>;
    }

    submittedLink = (cell, row) => {
        return <Link to={{
            pathname: '/RADV/SubmittedPage',
            query: { radvAuditID: this.props.radvAuditID, enrolleeID: row.radvAuditEnrolleeID }
        }}>{row.numberOfHCCsSubmittedForAudit}</Link>;
    }

    handleStatusChangeClick = (cell, row) => {
        const formattedDate = new Date(row.dateTimeOfStatusChange).toLocaleDateString('en-US', { timeZone: 'UTC' });
        return <button className="buttonLink" onClick={() => this.openModal(row, "StatusHistory")}>{formattedDate}</button>
    }

    handleInCodingClick = (cell, row) => {
        return <button className="buttonLink" onClick={() => this.openModal(row, "CodingDetails")}>{row.numberOfHCCsInCoding}</button>
    }

    openModal = (row, modalName) => {
        this.setState({ selectedRow: row }, () => {
            this.handleModalToggle(modalName)
        });
    }
    componentWillReceiveProps(nextProps){
          if(nextProps && nextProps.showStatusHistoryModal  !== this.props.showStatusHistoryModal){
              this.setState({
                showStatusHistoryModal : nextProps.showStatusHistoryModal
              })
          }
    }

    handleModalToggle = (modalName) => {
        if (modalName === "CodingDetails") {
            this.setState({ showCodingDetailsModal: !this.state.showCodingDetailsModal })
        }
        else if (modalName === "StatusHistory") {
            if(this.state.showStatusHistoryModal) {
               this.props.closeStatusHistoryModal()
            }
            this.setState({ showStatusHistoryModal: !this.state.showStatusHistoryModal })
        }
    }

    mdFormatter = (cell, row) => {
        if (cell === true) {
            return <Glyphicon glyph="flag" />
        }
    }

    altChaseFormatter = (cell, row) => {
        if (cell != null) {
            //todo: determine if this is an acceptable replacement for fontawesome's "clipboard" icon
            return <Glyphicon glyph="list-alt" />
        }
    }

    render() {
        return (
            <div className="landingSearchResults">
                <BootstrapTable data={this.props.records} maxHeight="660px" key="rankedData.id" scroll-top="Top" hover>
                    <TableHeaderColumn row='0' colSpan='6'>Enrollee Information</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='4'>CMS-HCC Information</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='2'>Medical Record Level</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='4'>HCC Evidence Level</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="3%" dataField="mdReviewRankingNeeded" dataFormat={this.mdFormatter}>MD</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="3%" dataField="alternateChaseReasonID" dataFormat={this.altChaseFormatter}>ALT</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="8%" dataField="enrolleeID" isKey={true}>Enrollee ID</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="11%" dataField="enrolleeName">Enrollee Name</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="8%" dataField="enrolleeStatusDesc">Enrollee Status</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="7%" dataField="dateTimeOfStatusChange" dataFormat={this.handleStatusChangeClick}>Status Change</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="6%" dataField="numberOfHCCsTargetedForAudit"># of Targeted</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="7%" dataField="numberOfHCCsOutstandingForAudit"># of Outstanding</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="6%" dataField="numberOfHCCsAcceptedForAudit"># of Accepted</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="5%" dataField="numberOfHCCsDeniedForAudit"># of Denied</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="5%" dataField="numberOfPendingMedicalRecordEntities">Pending</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="5%" dataField="numberOfRetrievedMedicalRecordEntities">Retrieved</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="5%" dataField="numberOfHCCsInCoding" dataFormat={this.handleInCodingClick}>In Coding</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="8%" dataField="numberOfPendingAttestationResponses">Needs Attestation</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="8%" dataField="numberOfRankedDetails" dataFormat={this.rankingOverScoredLink}>Ranking/Scored Evidence</TableHeaderColumn>
                    <TableHeaderColumn row='1' width="5%" dataField="numberOfHCCsSubmittedForAudit" dataFormat={this.submittedLink}>Submitted</TableHeaderColumn>
                </BootstrapTable>
                <CodingDetails showModal={this.state.showCodingDetailsModal} toggleModal={this.handleModalToggle} radvAuditEnrolleeID={this.state.selectedRow.radvAuditEnrolleeID}
                    toast={this.props.toast} />
                <StatusHistory showModal={this.state.showStatusHistoryModal} toggleModal={this.handleModalToggle} radvAuditEnrolleeID={this.state.selectedRow.radvAuditEnrolleeID}
                    enrolleeID={this.state.selectedRow.enrolleeID} enrolleeName={this.state.selectedRow.enrolleeName} enrolleeStatusDesc={this.state.selectedRow.enrolleeStatusDesc}
                    toast={this.props.toast} />
            </div>
        );
    }
}

export default ResultTable;
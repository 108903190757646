import React from 'react';
import axios from 'axios';
import { Button, ControlLabel, Form, FormGroup, Glyphicon, Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import WorkflowHeaderDetails from './WorkflowHeaderDetails';

class PackageQuerySelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providerID: '',
            selectedProvider: '',
            practiceGroupingID: '',
            selectedPractice: '',
            queries: [],
            printList: [],
            practiceSearchResults: []
        };
        this.addTooltip = (
            <Tooltip id="add"><strong>Add Member Query</strong></Tooltip>
        );
        this.removeTooltip = (
            <Tooltip id="remove"><strong>Remove Member Query</strong></Tooltip>
        );
    }

    componentWillMount() {
        // set provider id and practice group id when navigating between pages
        if (this.props.provider.id && this.props.practice.groupingID) {
            this.setState({ 
                providerID: this.props.provider.id, 
                practiceGroupingID: this.props.practice.groupingID,
                selectedProvider: this.props.provider.desc + ' - ' + this.props.provider.id,
                selectedPractice: this.props.practice.desc
            });
        }
        if (this.props.packageID) {
            this.getOpenQueryWorkflows();
            this.getIncludedQueryWorkflows();
        }
    }

    componentWillReceiveProps(nextProps) {
        // set provider id and practice grouping id when refreshing the page
        if (nextProps.provider.id > 0 && nextProps.practice.groupingID) {
            this.setState({ 
                providerID: nextProps.provider.id, 
                practiceGroupingID: nextProps.practice.groupingID,
                selectedProvider: nextProps.provider.desc + ' - ' + nextProps.provider.id,
                selectedPractice: nextProps.practice.desc
            });
        }
        if (this.props.packageID) {
            this.getOpenQueryWorkflows();
            this.getIncludedQueryWorkflows();
        }
    }

    componentDidMount() {
        this.setState({ loaded: true });
    }

    getOpenQueryWorkflows = () => {
        axios.get('/packages/' + this.props.packageID + '/OpenQueryWorkflows')
        .then(response => {
            this.setState({
                queries: response.data
            })
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load open queries.', 'Error');
        });
    }

    getIncludedQueryWorkflows = () => {
        axios.get('/packages/' + this.props.packageID + '/IncludedQueryWorkflows')
        .then(response => 
            this.setState({
                printList: response.data
            }))
        .catch((error) => {
            this.props.toast('error', 'Could not load included queries.', 'Error');
        });
    }

    workflowIsNotContainedInWorkflowList = (workflowList) => (workflow) => {
        const workflowIsContainedInResponse = workflowList
            .filter(addedWorkflow => 
                addedWorkflow.cdiAlertWorkflowID === workflow.cdiAlertWorkflowID)
            .length;
        return !workflowIsContainedInResponse;
    }

    handleAddAllUndelivered = () => {
        axios.post('/packages/' + this.props.packageID + '/IncludedQueryWorkflows', 
            this.state.queries
                .filter(item => !item.deliveryDate)
                .map(item => item.cdiAlertWorkflowID))
            .then(response => 
                this.setState({
                    printList: response.data,
                    queries:  [...this.state.queries]
                        .filter(this.workflowIsNotContainedInWorkflowList(response.data))
                }))
            .catch((error) => {
                this.props.toast('error', 'Could not add queries.', 'Error');
            });
    }

    handleAddAll = () => {
        axios.post('/packages/' + this.props.packageID + '/IncludedQueryWorkflows', 
            this.state.queries.map(item => item.cdiAlertWorkflowID))
            .then(response => 
                this.setState({
                    queries: [],
                    printList: response.data
                }))
            .catch((error) => {
                this.props.toast('error', 'Could not add queries.', 'Error');
            });
    }

    clearList = () => {
        const cdiAlertWorkflowIDs = this.refs.printList.state.data.map(item => { return item.cdiAlertWorkflowID });
        // Move all lines from print list to query list
        axios.post('/packages/' + this.props.packageID + '/RemoveIncludedQueryWorkflows', cdiAlertWorkflowIDs)
            .then(response => {
                this.getOpenQueryWorkflows();
                this.getIncludedQueryWorkflows();
            })                
            .catch((error) => {
                this.props.toast('error', 'Could not remove queries.', 'Error');
            });
    }

    handleAddMemberQuery = (rowIndex) => {
        const cdiAlertWorkflowID = this.refs.queryTable.state.data[rowIndex].cdiAlertWorkflowID;
        // Move the query line from the query table to the print list table
        axios.post('/packages/' + this.props.packageID + '/IncludedQueryWorkflows', [cdiAlertWorkflowID])
            .then(response =>
                this.setState({
                    printList: response.data,
                    queries: [...this.state.queries]
                        .filter(this.workflowIsNotContainedInWorkflowList(response.data))
                }))
            .catch((error) => {
                this.props.toast('error', 'Could not add query.', 'Error');
            });
    }

    handleRemoveMemberQuery = (rowIndex) => {
        const cdiAlertWorkflowID = [this.refs.printList.state.data[rowIndex].cdiAlertWorkflowID];
        // Move the query line from the print list table to the query table
        axios.post('/packages/' + this.props.packageID + '/RemoveIncludedQueryWorkflows', cdiAlertWorkflowID)
            .then(response => {
                this.getOpenQueryWorkflows();
                this.getIncludedQueryWorkflows();
            })
            .catch((error) => {
                this.props.toast('error', 'Could not remove query.', 'Error');
            });
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatCDIAlertWorkflowLink = (cdiAlertWorkflowID) => {
        return <a target='_blank' href={`/cdi/${cdiAlertWorkflowID}/viewonly?isPECAddAlert=true`} rel="noopener noreferrer">{cdiAlertWorkflowID}</a>;
    }

    render() { 
        const customQuerySelect = (props) => {
            const rowIndex = props.rowIndex;
            if(rowIndex === 'Header') {
                return null;
            } else {
                return (
                    <div>
                        <OverlayTrigger placement="top" overlay={this.addTooltip}>
                            <Button onClick={() => this.handleAddMemberQuery(rowIndex)}>
                                <Glyphicon glyph="plus" />
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            }
        };

        const customPrintListSelect = (props) => {
            const rowIndex = props.rowIndex;
            if(rowIndex === 'Header') {
                return null;
            } else {
                return (
                    <div>
                        <OverlayTrigger placement="top" overlay={this.removeTooltip}>
                            <Button onClick={() => this.handleRemoveMemberQuery(props.rowIndex)}>
                                <Glyphicon glyph="trash" />
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            }
        };

        const selectQueryRow = {
            mode: 'radio',
            customComponent: customQuerySelect,
            columnWidth: '7rem'
        };

        const selectPrintListRow = {
            mode: 'radio',
            customComponent: customPrintListSelect,
            columnWidth: '7rem'
        };

        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <h3 className="workflow-title">Package Query Selection</h3>
                        {
                            this.state.loaded &&
                            <WorkflowHeaderDetails provider={this.props.provider.desc + ' (' + this.props.provider.id + ')'} practice={this.props.practice.desc} packageType={this.props.packageType} />
                        }
                        <hr />
                    </Col>
                </Row>
                <Row style={{ minHeight: '720px' }}>
                    <Col sm={12}>
                        <Loader loaded={this.state.loaded}>
                            <Row>
                                <Col sm={12}>
                                    <Form inline>
                                        <FormGroup>
                                            <ControlLabel style={{ fontSize: '18px', marginBottom: '1.4rem' }}>Queries</ControlLabel>
                                            <Button style={{ marginBottom: '1.4rem', marginLeft: '4rem' }} onClick={this.handleAddAllUndelivered}>Add All Undelivered</Button>
                                            <Button style={{ marginBottom: '1.4rem', marginLeft: '1.4rem' }} onClick={this.handleAddAll}>Add All</Button>
                                        </FormGroup>
                                    </Form>
                                    <BootstrapTable data={this.state.queries} ref='queryTable' multiColumnSort={3} maxHeight="300px" scroll-top="Top"
                                        hover selectRow={selectQueryRow}>
                                        <TableHeaderColumn dataField='memberName' isKey dataSort>Member Name</TableHeaderColumn>
                                        <TableHeaderColumn dataField='memberID' dataSort>Member ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField='providerName' dataSort>Appt. Provider</TableHeaderColumn>
                                        <TableHeaderColumn dataField='providerID' dataSort>Provider ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField='cdiAlertWorkflowID' dataSort dataFormat={this.formatCDIAlertWorkflowLink}>Query Alert</TableHeaderColumn>
                                        <TableHeaderColumn dataField='appointmentDatetime' dataFormat={this.formatDateString} dataSort>Appt. Date</TableHeaderColumn>
                                        <TableHeaderColumn dataField='numberOfQueries' dataSort># of Query Lines</TableHeaderColumn>
                                        <TableHeaderColumn dataField='deliveryDate' dataFormat={this.formatDateString} dataSort>Delivered to Provider</TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form inline style={{ marginTop: '4rem' }}>
                                        <FormGroup>
                                            <ControlLabel style={{ fontSize: '18px', marginBottom: '1.4rem' }}>Print List</ControlLabel>
                                            <Button style={{ marginBottom: '1.4rem', marginLeft: '3.2rem' }} onClick={this.clearList}>
                                                <Glyphicon glyph="trash" style={{ marginRight: '0.6rem' }} />Clear List
                                            </Button>
                                        </FormGroup>
                                    </Form>
                                    <BootstrapTable data={this.state.printList} ref='printList' maxHeight="300px" scroll-top="Top" hover
                                        selectRow={selectPrintListRow} multiColumnSort={3}>
                                        <TableHeaderColumn dataField='memberName' isKey dataSort>Member Name</TableHeaderColumn>
                                        <TableHeaderColumn dataField='memberID' dataSort>Member ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField='providerName' dataSort>Appt. Provider</TableHeaderColumn>
                                        <TableHeaderColumn dataField='providerID' dataSort>Provider ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField='cdiAlertWorkflowID' dataSort dataFormat={this.formatCDIAlertWorkflowLink}>Query Alert</TableHeaderColumn>
                                        <TableHeaderColumn dataField='appointmentDatetime' dataFormat={this.formatDateString} dataSort>Appt. Date</TableHeaderColumn>
                                        <TableHeaderColumn dataField='numberOfQueries' dataSort># of Query Lines</TableHeaderColumn>
                                        <TableHeaderColumn dataField='deliveryDate' dataFormat={this.formatDateString} dataSort>Delivered to Provider</TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                            </Row>
                        </Loader>
                    </Col>
                </Row>
            </div>
        );  
    }
}

export default PackageQuerySelection;
import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class ProblemList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            ['ONSET DATE', 'RECORDED DATE'],
            ['PROBLEM', 'PROBLEM TYPE'],
            ['PROBLEM CODE', 'CLINICAL STATUS'],
            ['CODING SYSTEM'],
            ['PROVIDER', 'NPI'],
            ['NOTES']
        ]
    }

    formatMultidataCell = (value) => {
        if (!value || value.length === 0) {
            return '-';
        } else {
            return value;
        }
    }
    // TODO: these are all mockup data for now
    render() {
        return (
        <Panel id='problemlist'>
            <Panel.Body>
            <div className='sectionHeader'> 
                Problem List
            </div>
            {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                        <Table>
                        <thead>
                            <tr>
                                {
                                    this.columnHeaders.map((x, index) => {
                                        return (x.length > 1 ?
                                            <th className='rawTableColumnHeader' key={index}>
                                                <div>{x[0]}</div>
                                                <div>{x[1]}</div>
                                            </th>
                                            : <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data.map((x, index) => {
                                    if (x.Provider_id !== null && x.Provider_id == '0') {x.Provider_id = ''}
                                    return (
                                        <tr key={index}>
                                            <td className='width15'>
                                                <div>{this.formatMultidataCell(formatDatetimeStringWithPrefixZero(x.onset_date))}</div>
                                                <div>{this.formatMultidataCell(formatDatetimeStringWithPrefixZero(x.record_date))}</div>
                                            </td>
                                            <td className='width25'>
                                                <div>{this.formatMultidataCell(x.problem_description)}</div>
                                                <div>{this.formatMultidataCell(x.problem_type_description)}</div>
                                            </td>
                                            <td className='width15'>
                                                <div>{this.formatMultidataCell(x.problem_code)}</div>
                                                <div>{this.formatMultidataCell(x.problem_status)}</div>
                                            </td>
                                            <td className='width15'>{x.coding_system}</td>
                                            <td className='width15'>
                                                <div>{this.formatMultidataCell(x.Provider_name)}</div>
                                                <div>{this.formatMultidataCell(x.Provider_id)}</div>
                                            </td>
                                            <td className='width20'>{x.notes}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table> : <NoData />
                    }      
            </Panel.Body>
        </Panel>
            
        )
    }
}

export default ProblemList;
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap';

export default class ContactInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            title: '',
            phone: '',
            email: '',
            helpDeskPhone: '',
            notes: ''
        };
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value });
    }

    handleTitleChange = (e) => {
        this.setState({ title: e.target.value });
    }

    handlePhoneChange = (e) => {
        const val = parseInt(e.target.value, 10);
        this.setState({ phone: Number.isInteger(val) ? val : ''});
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    handleHelpDeskPhoneChange = (e) => {
        const val = parseInt(e.target.value, 10);
        this.setState({ helpDeskPhone: Number.isInteger(val) ? val : ''});
    }

    handleNotesChange = (e) => {
        this.setState({ notes: e.target.value });
    }

    handleAddContact = (e) => {
        this.props.handleAddContact({
            name: this.state.name,
            title: this.state.title,
            phone: this.state.phone,
            email: this.state.email,
            helpDeskPhone: this.state.helpDeskPhone,
            notes: this.state.notes
        });

        this.setState({
            name: '',
            title: '',
            phone: '',
            email: '',
            helpDeskPhone: '',
            notes: ''
        });
    }

    isAddContactDisabled = () => {
        return this.props.readOnly || Boolean(!this.state.name);
    }

    render() {
        return (
            <div style={{ marginBottom: '3rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel className="requiredField">Name</ControlLabel>
                            <FormControl type="text" value={this.state.name} onChange={this.handleNameChange} maxLength="100" disabled={this.props.readOnly} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>Title</ControlLabel>
                            <FormControl type="text" value={this.state.title} onChange={this.handleTitleChange} maxLength="100" disabled={this.props.readOnly} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>Phone</ControlLabel>
                            <FormControl type="text" value={this.state.phone} onChange={this.handlePhoneChange} maxLength="15" disabled={this.props.readOnly} />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl type="text" value={this.state.email} onChange={this.handleEmailChange} maxLength="100" disabled={this.props.readOnly} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>Help Desk Phone</ControlLabel>
                            <FormControl type="text" value={this.state.helpDeskPhone} onChange={this.handleHelpDeskPhoneChange} maxLength="15" disabled={this.props.readOnly} />
                        </FormGroup>
                    </Col>
                    <Col xs={8}>
                        <FormGroup>
                            <ControlLabel>Notes</ControlLabel>
                            <FormControl type="text" value={this.state.notes} onChange={this.handleNotesChange} maxLength="1000" disabled={this.props.readOnly} />
                        </FormGroup>
                    </Col>
                    <Col xs={1} style={{ paddingLeft: '0rem' }}>
                        <div style={{ paddingTop: '2.4rem' }}>
                            <Button onClick={this.handleAddContact} disabled={this.isAddContactDisabled()}>
                                <Glyphicon glyph="plus" style={{ fontWeight: '800' }} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
import React from 'react';
import { Col, Collapse, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import WorkflowSectionHeader from './WorkflowSectionHeader';

const AlertInfo = (props) => {
    const handleDeliveryDateChange = (e) => {
         props.handleWorkflowFieldChange({ deliveryDate: e });
    }

    const handleCDIReceivedDateChange = (e) => {
        props.handleWorkflowFieldChange({ cdiReceivedDate: e });
    }

    const handleMedicalRecordNumberChange = (e) => {
        props.handleWorkflowFieldChange({ medicalRecordNumber: e.target.value });
    }

    const getReceivedTypeOptions = () => {
        return props.receivedTypes.map((item) => {
            return { value: item.cdiAlertReceivedTypeID, label: item.cdiAlertReceivedTypeDesc }
        });
    }

    const handleReceivedTypeChange = (e) => {
        props.handleWorkflowFieldChange({ cdiAlertReceivedTypeID: e ? e.value : '' });
    }

    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="alert" title="Alert" />
            <Collapse in={props.expanded}>
                <div>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Alert ID</ControlLabel>
                                <FormControl type="text" value={props.workflow.alertID} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel>Alert Received Type</ControlLabel>
                                    <Select value={props.workflow.cdiAlertReceivedTypeID} options={getReceivedTypeOptions()}
                                        onChange={handleReceivedTypeChange} disabled={props.isViewOnly} clearable={false} />
                                </FormGroup>
                            </Col>
                        <Col xs={3}>
                           <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Date CDI Distributed</ControlLabel>
                                <DatePicker value={props.workflow.deliveryDate} onChange={handleDeliveryDateChange}
                                    style={{ zIndex: 'auto' }} disabled={props.isViewOnly} />
                           </FormGroup>
                        </Col>
                        <Col xs={3}>
                           <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Date CDI Received</ControlLabel>
                                <DatePicker value={props.workflow.cdiReceivedDate} onChange={handleCDIReceivedDateChange}
                                    style={{ zIndex: 'auto' }} disabled={props.isViewOnly} />
                           </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Medical Record Number</ControlLabel>
                                <FormControl type="text" value={props.workflow.medicalRecordNumber ? props.workflow.medicalRecordNumber : ''}
                                    maxLength="25" onChange={handleMedicalRecordNumberChange} disabled={props.isViewOnly} />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>
    );
}

export default AlertInfo;
import { connect } from 'react-redux';
import { setDiagFormData } from "../../../../../redux/actions/index";
import DiagnosisCapture from './DiagnosisCapture';

const mapStateToProps = state => ({
    diags: state.diags
});

const mapDispatchToProps = dispatch => ({
    setDiagFormData: diagFormData => dispatch(setDiagFormData(diagFormData))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiagnosisCapture);
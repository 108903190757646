import React from 'react';
import { Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DiagnosisDetailModal from './DiagnosisDetailModal';
import { sortDate } from '../../../../../utils/SortUtils';

class DiagnosisCaptureHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiagDetailModal: false,
            modalRow: null
        }
    }

    // map history row values to matching capture row values for detail modal variables
    mapRowValues = (row) => {
        row.rejectionComments = row.rejectComments;
        row.dateOfServiceFrom = row.dosfrom;
        row.dateOfServiceTo = row.dosto;
        row.medicalRecordImageID = row.imageID;
        row.providerName = row.providerFirstName + " " + row.providerLastName;
        //planProviderID not used for now
        //row.planProviderID = null;
        row.description = row.hccDescription;
        row.createdDateTime = row.creationDate;
        // create newRow object without old mapped values
        const {dosfrom, dosto, imageID, providerFirstName, providerLastName, providerID, hccDescription, creationDate, ...newRow} = row;
        return newRow;
    }

    toggleDiagDetailModal = (row) => {
        if (row) {
            row = this.mapRowValues(row);
        }
        this.setState({ 
            showDiagDetailModal: !this.state.showDiagDetailModal,
            modalRow: row
        });
    }

    renderTable = (results) => {
        const options = {
            defaultSortName: 'medicalRecordDiagID',
            defaultSortOrder: 'asc',
            onRowDoubleClick: this.toggleDiagDetailModal
        };
        return (
            <div>
                <BootstrapTable data={results} ref={(table) => { this.table = table }} options={options} scroll-top="Top" striped>
                    <TableHeaderColumn dataField="medicalRecordDiagID" hidden isKey dataSort></TableHeaderColumn>
                    <TableHeaderColumn dataField="projectName" dataSort width="10%" tdStyle={{ whiteSpace: 'normal' }}>Project Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="diagnosisCD" dataSort width="5%" tdStyle={{ whiteSpace: 'normal' }}>DX Code</TableHeaderColumn>
                    <TableHeaderColumn dataField="hccNumber" dataSort width="6%">HCC</TableHeaderColumn>
                    <TableHeaderColumn dataField="dosfrom" dataSort width="7%" sortFunc={(a, b, order) => sortDate(a.dosfrom, b.dosfrom, order)}>DOS From</TableHeaderColumn>
                    <TableHeaderColumn dataField="dosto" dataSort width="7%" sortFunc={(a, b, order) => sortDate(a.dosto, b.dosto, order)}>DOS To</TableHeaderColumn>
                    <TableHeaderColumn dataField="creationDate" dataSort width="7%" sortFunc={(a, b, order) => sortDate(a.creationDate, b.creationDate, order)}>Captured On</TableHeaderColumn>
                    <TableHeaderColumn dataField="imageID" dataSort width="5%">Image ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerType" dataSort>Provider Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerNPI" dataSort>Provider NPI</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerID" dataSort>Provider ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="coderUserFullname" dataSort tdStyle={{ whiteSpace: 'normal' }}>Captured By</TableHeaderColumn>
                    <TableHeaderColumn dataField="qaUserFullname" dataSort tdStyle={{ whiteSpace: 'normal' }}>QA</TableHeaderColumn>
                    <TableHeaderColumn dataField="status" dataSort>Status</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Panel className="capturePanel" header="Diagnosis Capture History">
                    {
                        this.props.diagnosisData &&
                        this.renderTable(this.props.diagnosisData)
                    }
                </Panel>
                <DiagnosisDetailModal visible={this.state.showDiagDetailModal} handleModalToggle={this.toggleDiagDetailModal}
                    row={this.state.modalRow} medicalRecordTypeData={this.props.medicalRecordTypeData}
                    rejectionReasons={this.props.rejectionReasons} diagnosisComments={this.props.diagnosisComments}
                    sourceData={this.props.sourceData} providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData}
                    isQA={this.props.isQA} isComplete={this.props.isComplete} isMD={this.props.isMD} isCoderQA={this.props.isCoderQA} 
                    userID={this.props.userID} memberID={this.props.memberID} hideActions={true} />
            </div>
        );
    }
}

export default DiagnosisCaptureHistory;
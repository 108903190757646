import React from 'react';
import axios from 'axios';
import MemberDemographic from './MemberDemographic';
import ReactPopout from '../../../../external/ReactPopout';

class MemberDemographicContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberDemo: null,
            memberEKG: null,
            isPoppedOut: false,
            loaded: false
        };
    }

    componentWillMount() {
        Promise.all([
            this.getMemberDemographic(this.props.reviewProcessingUIConfig.memberInternalKey),
            this.getMemberEKG(this.props.reviewProcessingUIConfig.memberInternalKey)])
        .then((response) => {
            this.setState({loaded: true});
        })
    }

    getMemberDemographic = () => {
        axios.get(`/memberService/v1/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/memberDemographic/`)
        .then((response) => {
            this.setState({
                memberDemo: response.data
            });
        })
        .catch((error) => {
            //todo: this.props.toast('error', 'Could not load member info.', 'Error');
        });
    }

    getMemberEKG = () => {
        axios.get(`/memberService/v1/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/memberEKG/`)
        .then((response) => {
            this.setState({
                memberEKG: response.data.chart
            });
        })
        .catch((error) =>{
            //todo: this.props.toast('error', 'Could not load member EKG.', 'Error');
        });
    }

    toggleWindow = () => {
        this.setState({ isPoppedOut: !this.state.isPoppedOut });
    }

    render() {
        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width * .8,
            height: window.screen.height * .8,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };
        const radvEnrolleeID = this.props.reviewProcessingUIConfig.isRADV && this.props.reviewProcessingUIConfig.radvEnrollee ?
            this.props.reviewProcessingUIConfig.radvEnrollee.enrolleeID : null;

        return (
            <div>
                {
                    this.state.loaded && this.state.isPoppedOut === true &&
                    <ReactPopout title="Member Demographic" onClosing={this.toggleWindow} options={options}
                        url={`/MemberDemographics/NewWindow/${this.props.reviewType}${window.location.search}`}>
                    </ReactPopout>
                }
                {
                    this.state.loaded && this.state.memberDemo &&
                    <span style={{ display: (this.state.isPoppedOut) ? 'none' : 'initial' }}>
                        <MemberDemographic
                            memberDemo={this.state.memberDemo}
                            radvEnrolleeID={radvEnrolleeID}
                            openNewWindow={this.toggleWindow}
                            chart={this.state.memberEKG}
                            expanded={false}
                            isPopout={false}
                        />
                    </span>
                }
            </div>
        );
    }
}

export default MemberDemographicContainer;
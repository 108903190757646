import React from 'react';
import axios from 'axios';
import { Col, Button, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Moment from 'moment';
const colStyle = { paddingLeft: '0rem' };

class AutomationConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            l1CoderCount: 0,
            l2CoderCount: 0,
            qaCoderCount: 0,
            codingCompletionDate: '',
            saveCampaignDateFlag: false
        }
    }

    componentDidMount() {
        this.getCampaignAutomationConfig();
    }

    getCampaignAutomationConfig = () => {
        axios.get(`/campaignAutomationConfig/${this.props.campaignID}`)
        .then((response) => {
            if (response.data) {
                this.setState({
                    l1CoderCount: response.data.l1CoderCount,
                    l2CoderCount: response.data.l2CoderCount,
                    qaCoderCount: response.data.qaCoderCount,
                    codingCompletionDate: response.data.codingCompletionDate
                })
            }
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load automation config.', 'Error');
        })
    }

    handleSaveAutomationConfig = () => {
        if(this.state.saveCampaignDateFlag || this.handleCodingCompleteDate(this.state.codingCompletionDate)){
            axios.post(`/campaignAutomationConfig/${this.props.campaignID}`, {
                l1CoderCount: this.state.l1CoderCount,
                l2CoderCount: this.state.l2CoderCount,
                qaCoderCount: this.state.qaCoderCount,
                codingCompletionDate: this.state.codingCompletionDate
            })
            .then((response) => {
                this.props.toast('success', 'Automation config saved.', 'Success');
            })
            .catch((error) => {
                this.props.toast('error', 'Could not save automation config.', 'Error');
            })
        } else {
            this.props.toast('error', 'The Completion Date must be valid.', 'Error');
        }
    }

    handleL1Count = (e) => {
        if (e.target.value < 0) {
            this.props.toast('error', 'Value must be positive.', 'Error');
            this.getCampaignAutomationConfig();
        } else{
            this.setState({ l1CoderCount: e.target.value });
        }  
    }

    handleL2Count = (e) => {
        if (e.target.value < 0) {
            this.props.toast('error', 'Value must be positive.', 'Error');
            this.getCampaignAutomationConfig();
        } else{
            this.setState({ l2CoderCount: e.target.value });
        }
    }

    handleQACount = (e) => {
        if (e.target.value < 0) {
            this.props.toast('error', 'Value must be positive.', 'Error');
            this.getCampaignAutomationConfig();
        } else{
            this.setState({ qaCoderCount: e.target.value });
        }
    }

    handleCodingCompleteDate = (dateValue) => {
        if(dateValue){
            let date = Moment(Date()).format('yyyy-MM-DD');
            let selectedDate = Moment(dateValue).format('yyyy-MM-DD');
            let futureDate = Moment(date).add(18, 'M').format('yyyy-MM-DD');
    
            if(selectedDate <= date) {
                this.setState({ 
                    codingCompletionDate: dateValue,
                    saveCampaignDateFlag: false
                });
                this.props.toast('error', "Cannot select Today's Date or Past Date.", 'Error');
            } else if (selectedDate > (futureDate)) {
                this.setState({ 
                    codingCompletionDate: dateValue,
                    saveCampaignDateFlag: false
                });
                this.props.toast('error', "Date can not be more than 18 months in the future.", 'Error');
            } else{
                this.setState({ 
                    codingCompletionDate: dateValue,
                    saveCampaignDateFlag: true
                });
            } 
        }
    }

    render() {
        const inlineFormStyle = {
            marginLeft: '2rem'
        }
        return(
            <div>
                <Row>
                    <Col xs={9} style={colStyle}>
                        <h5 style={inlineFormStyle}>Coder Allocation</h5>
                        <Form inline>
                            <FormGroup>
                                <ControlLabel style={inlineFormStyle}>L1</ControlLabel>
                                <FormControl style={inlineFormStyle} type="number" value={this.state.l1CoderCount} onChange={this.handleL1Count} />
                            </FormGroup>
                            <FormGroup >
                                <ControlLabel style={inlineFormStyle}>L2</ControlLabel>
                                <FormControl style={inlineFormStyle} type="number" value={this.state.l2CoderCount} onChange={this.handleL2Count} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel style={inlineFormStyle}>QA</ControlLabel>
                                <FormControl style={inlineFormStyle} type="number" value={this.state.qaCoderCount} onChange={this.handleQACount} />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5} style={colStyle}>
                        <h5 style={inlineFormStyle}>Coding Completion Date</h5>
                        <FormGroup style={{ width: '40%', marginLeft: '2rem' }}>
                            <DatePicker value={this.state.codingCompletionDate} onChange={this.handleCodingCompleteDate} showClearButton />
                        </FormGroup>
                    </Col>                
                </Row>
                <Row>
                    <Col xs={1} style={colStyle}>
                        <Button bsStyle="primary" style={{ marginLeft: '2rem' }} onClick={this.handleSaveAutomationConfig}>Save</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AutomationConfig;
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';

class FaxStatusSearch extends React.Component {
    constructor(props) {
        super(props);
        this.defaultSearchParams = {
            faxStatus: null,
            providerOrgID: '',
            practiceID: '',
            initiatedDate: '',
            initiatingUserID: ''
        }

        this.state = {
            practiceSearchResults: [],
            searchParams: {...this.defaultSearchParams},
            initiatingUserIDOptions: [
                { value: 0, label: "Unassigned" },
                ...this.props.users.map((item) => { return { value: item.userID, label: item.fullName }; })
            ]
        }
    }

    handleProviderOrgChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{ providerOrgID: e.target.value },
                ...{ practiceID: '' }
            }
        });
    }

    handlePracticeChange = (e) => {
        if (e.target) {
            this.setState({ 
                searchParams: {
                    ...this.state.searchParams,
                    ...{ practiceID: e.target.value }
                }
            });
        }
        else {
            const foundPractice = {
                groupingID: e.groupingID,
                description: e.description
            };
            this.setState({ 
                practiceSearchResults: [...this.state.practiceSearchResults, foundPractice],
                searchParams: {
                    ...this.state.searchParams,
                    ...{ practiceID: e.groupingID }
                }
            });
        }
    }

    handleInitiatedDateChange = (dateValue) => {
        if(dateValue !== null) {
            dateValue = new Date((new Date(dateValue)).setHours(0,0,0,0)).toISOString();
        }

        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{ initiatedDate: dateValue }
            }
        });
    }

    search = () => {
        this.props.search(this.state.searchParams);
    }

    clearSearchParams = () => {
        this.setState({ searchParams: {...this.defaultSearchParams} },
            () => this.props.search(this.state.searchParams)
        );
    }

    handleFaxStatusChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{ faxStatusID: e !== null ? e.value : null }
            }
        });
    }

    handleInitiatingUserIDChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{ initiatingUserID: e.target.value }
            }
        });
    }

    render() {
        //add practices from practice search to options; filter options via provider org selection
        const practices = this.state.searchParams.providerOrgID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.searchParams.providerOrgID ? practice : false;
            }).concat(this.state.practiceSearchResults)
            : this.props.practices.concat(this.state.practiceSearchResults);

        return (
            <Grid fluid>
                <Row style={{marginBottom: '1.4rem', paddingLeft: '13px'}}>
                    <h4>Fax Search</h4>
                </Row>
                <Row style={{marginBottom: '2rem'}}>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Provider Org</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.providerOrgID} onChange={this.handleProviderOrgChange}>
                                <option value="">Select</option>
                                {
                                    this.props.providers.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Practice</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.practiceID} onChange={this.handlePracticeChange} style={{marginRight: '0.2rem'}}>
                                <option value="">Select</option>
                                {
                                    practices.map((item) => {
                                        return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{marginBottom: '2rem'}}>
                    <Col sm={2}>
                        <FormGroup>
                            <ControlLabel>Status</ControlLabel>
                                <Select
                                    value={this.state.searchParams.faxStatusID}
                                    options={this.props.faxStatusOptions}
                                    onChange={this.handleFaxStatusChange}
                                    />
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <ControlLabel>
                                Fax Date
                            </ControlLabel>
                            <DatePicker value={this.state.searchParams.initiatedDate} onChange={this.handleInitiatedDateChange} style={{zIndex: 'auto'}}/>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <ControlLabel>Initiating PEC</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.initiatingUserID} onChange={this.handleInitiatingUserIDChange}>
                                <option value="">Select</option>
                                {
                                    this.state.initiatingUserIDOptions.map((item) => {
                                        return <option key={item.value} value={item.value}>{item.label}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{marginBottom: '2rem'}}>
                    <Col sm={6}>
                        <Button onClick={this.search} style={{marginRight: '2rem'}}>
                            <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Search
                        </Button>
                        <Button onClick={this.clearSearchParams}>
                            Clear Search
                        </Button>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default FaxStatusSearch;
import React from 'react';
import { Row, Button, Glyphicon, Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NLPDiagnosisFormModal from './NLPDiagnosisFormModal';


class NLPTableImage extends React.Component {
    constructor(props) {
        console.log('NLPTableImage constructor')
        super(props);
        this.state = {
            showFormModal: false,
            editModalRow: null,
            editMode: false,
            editQABool: false,
            selectedDiagLookupDOS:null,
            selectedDiagLookupDOE:null,
            selectedDiagLookupCD:null,
            showDiagLookupModal: false,
            diagnosisCD: null,
            imageRecords: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            nlpInsightData: this.props.nlpInsightData
        });

        //value passed via react-redux from CDIAlertContainer/CDIAlertContainerRedux:
        if (this.props.nlpInsightData.length > 0 && this.props.nlpInsightData.find(x => x.diagFormData)) {
            this.toggleFormModal(this.props.nlpInsightData, false, false, true);
        }
    }

    formatAddAction = (cell, row) => {
        return (
            <Button onClick={() => this.handleAddDiag(row)}>
                <Glyphicon glyph="plus" />
            </Button>    
        )
    }

    formatRemoveAction = (cell, row) => {
        return (
            <Button onClick={() => this.handleRemoveDiag(row)}>
                <Glyphicon glyph="minus" />
            </Button>
        )
    }

    sortNlpData = (a, b, order, sortField) => {
        
        //place empty values at the bottom of the sort
        a = a[sortField].split(',')[0] ? a[sortField].split(',')[0] : Number.MAX_SAFE_INTEGER;
        b = b[sortField].split(',')[0] ? b[sortField].split(',')[0] : Number.MAX_SAFE_INTEGER;

        if(a.split('_')[0] === b.split('_')[0])
        {
            a = a.split('_')[0] ? Number(a.split('_')[0]) + Number(a.split('_')[1]) : Number.MAX_SAFE_INTEGER;
            b = b.split('_')[0] ? Number(b.split('_')[0]) + Number(b.split('_')[1]) : Number.MAX_SAFE_INTEGER;
        }
        else{
        //place empty values at the bottom of the sort
        a = a.split('_')[0] ? a.split('_')[0] : Number.MAX_SAFE_INTEGER;
        b = b.split('_')[0] ? b.split('_')[0] : Number.MAX_SAFE_INTEGER;
        }
        
        if (order === 'asc') {
            return a - b;
        }
        else {
            return b - a;
        }
    }

    sortdiagnosisCode = (a, b, order, sortField, index) => {
        
        index= (index===undefined) ?0: index;
        
        var aA = a[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var bA = b[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var result;
        if((aA===undefined) && (bA!==undefined))
        {
            return -1;
        }
        else if((aA!==undefined) && (bA===undefined))
        {
            return 1;
        }
        else if((aA===undefined) && (bA===undefined))
        {
            return -1;
        }
        if (aA === bA) {
           result= this.sortdiagnosisCodeDetail(a, b, order, sortField, index+1);                        
          } else {
            if(isNaN(aA) && isNaN(bA)){
                result = aA > bA ? 1 : -1;}
            else{
                result = Number(aA) > Number(bA) ? 1 : -1;}
          }
          if (order === 'asc') {
            return result;
        }
        else {
            return (result)*(-1);
        }
    }

    sortdiagnosisCodeDetail = (a, b, order, sortField, index) => {
        
        index= (index===undefined) ?0: index;
        
        var aA = a[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var bA = b[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var result;
        if((aA===undefined) && (bA!==undefined))
        {
            return -1;
        }
        else if((aA!==undefined) && (bA===undefined))
        {
            return 1;
        }
        else if((aA===undefined) && (bA===undefined))
        {
            return 1;
        }
        if (aA === bA) {
          return this.sortdiagnosisCodeDetail(a, b, order, sortField, index+1);     

          } else {
            if(isNaN(aA) && isNaN(bA))
            {
                return  aA > bA ? 1 : -1;}
            else{
                return Number(aA) > Number(bA) ? 1 : -1;}
          }
          
    }

    handleRemoveDiag = (row) => {
        console.log(row);
    }
    
    handleAddDiag = (row) => {
        console.log(row);
        this.toggleFormModal(row);
    }

    activePageFormatter = (cell, row) => {
        return(
            <div style={{ cursor: 'pointer', color: '#23527c', textDecoration: 'underline'}} onClick={() => this.handlePageClick(cell)}> {cell} </div>
        );
    }

    handlePageClick = (cell) => {
        this.props.navigateToPage(cell);
    }

    formatDate = (cell) => {
        if(!cell){ return ""; }
        var options = {year: 'numeric', month: 'numeric', day: 'numeric'};
       return `${(cell ? new Date(cell).toLocaleDateString('en-US', options) : '')}`;
    }

    setClaimsAndClaimProviders = (dosRange, claimsResponse) => {
        this.setState({
            claimsMap: new Map([ ...this.state.claimsMap,
                [dosRange, claimsResponse.claims ? claimsResponse.claims : []]
            ]),
            claimsProviderMap: new Map([ ...this.state.claimsProviderMap,
                [dosRange, claimsResponse.claimProviders ? claimsResponse.claimProviders : []]
            ])
        });
    }

    getProviderDropdownLabel = (lastName, firstName, providerID, providerNPI, alternateProviderIDs = null) => {
        const providerName = firstName ? `${lastName}, ${firstName}` : lastName;
        if (alternateProviderIDs) {
            providerID = `${providerID}, ${alternateProviderIDs.join(', ')}`;
        }
        if (providerNPI && providerID) {
            return `${providerName} (${providerNPI} - ${providerID})`;
        }
        else if (providerNPI && !providerID) {
            return `${providerName} (NPI: ${providerNPI})`;
        }
        else if (!providerNPI && providerID) {
            return `${providerName} (Provider ID: ${providerID})`;
        }
        else {
            return `${providerName} (Missing ProviderID and NPI)`;
        }
    }

    toggleRADVModal = (row) => {
        this.setState({
            showRADVModal: !this.state.showRADVModal,
            radvRow: row
        });
    }

    toggleFormModal = (row, isDetailModalClick, editQABool, viaAlert = false) => {
        let editRow; 
        let editMode = false;

        if ((row && row.medicalRecordDiagID) || (row && viaAlert)) {
            editRow = row;
            if (!viaAlert) {
                editMode = true;
            }
            else {
                row.viaAlert = true;
            }
        }
        if(row && row.dosStart){
        
            this.setState({selectedDiagLookupDOS: row.dosStart,
                selectedDiagLookupDOE: row.dosEnd,
                diagnosisCD: row.diagnosisCode
            });
        }
        this.setState({ showFormModal: !this.state.showFormModal,
            editModalRow: editRow, 
            editMode: editMode, 
            editQABool: editQABool
        });
    }

    toggleDiagLookupModal = () => {
        this.setState({ showDiagLookupModal: !this.state.showDiagLookupModal });
    }

    selectDiagLookup = (diagCD, dateOfService, addDiag) => {
        if (addDiag) {
            this.toggleDiagLookupModal();
            this.setState({
                selectedDiagLookupCD: diagCD,
                selectedDiagLookupDOS: dateOfService
            }, () => {
                this.toggleFormModal(null, false, null);
            });
        }
        else {
            if (this.state.showDiagLookupModal) {
                this.toggleDiagLookupModal();
            }
            this.setState({
                selectedDiagLookupCD: diagCD,
                selectedDiagLookupDOS: dateOfService
            });
        }
    }

    toggleDiagFeedbackModal = (diag) => {
        this.setState({
            showDiagFeedbackModal: !this.state.showDiagFeedbackModal,
            modalRow: diag ? {...diag} : null
        });
    }

    setProviderGroup = (planProviderID, providerGroup) => {
        this.setState({
            providerGroupMap: new Map([ ...this.state.providerGroupMap,
                [planProviderID, providerGroup ? providerGroup : [] ]
            ])
        });
    }

    populateImageIDOptions = () => {
        return axios.get(`/medicalrecordreview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/images`)
        .then((response) => {
            const mapToOptionRow = function(record) {
                return {
                    id: record.medicalRecordImageID,
                    title: record.medicalRecordImageID
                }
            }
            // remove duplicates with same medicalRecordImageID and map to options
            const imageRecords = (response.data.reduce((x, y) => x.findIndex(e => 
                e.medicalRecordImageID === y.medicalRecordImageID) < 0 ? [...x, y]: x, [])).map(mapToOptionRow);

            const userUploadedImages = (response.data.filter(x => {
                return x.retrievalSourceID === 5 && x.retrievalMethodID === 6;
            })).map(mapToOptionRow);

            if (this.props.reviewProcessingUIConfig.medicalRecordTypeID === MedicalRecordType.Scan) {
                imageRecords.push({ id: -1, title: 'Not available in MRR' });
            }

            this.setState({ 
                imageRecords: imageRecords,
                userUploadedImages: userUploadedImages
            });
        })
    }

    openPopoutWindow = (evt) => {
        console.log('popped out');
        evt.stopPropagation();
        this.props.openNewWindow(this.props.activeTab);
    }

    render() {
        return (
            <div>
                 <Panel className={!this.props.isInsightsPoppedOut}>
                    <Panel.Heading>
                        <Panel.Title>
                            {
                            !this.props.isInsightsPoppedOut &&
                                <span style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                                    <Glyphicon glyph="new-window" onClick={this.openPopoutWindow} />
                                </span>
                            }
                            
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible={false}>
                    {
                        this.props.reviewType === 'InitialCoding' &&
                        <Row style={{ marginBottom: '1rem', marginLeft: '0px', marginRight: '0px' }}>
                            <BootstrapTable data={this.props.nlpInsightData} className="imageViewerTable">
                                <TableHeaderColumn dataField="medicalRecordImageID" isKey hidden></TableHeaderColumn>
                                <TableHeaderColumn dataField="dosStart" dataFormat={this.formatDate} dataSort width="15%"> DOS From</TableHeaderColumn>
                                <TableHeaderColumn dataField="dosEnd" dataFormat={this.formatDate} dataSort width="15%"> DOS To</TableHeaderColumn>
                                <TableHeaderColumn dataField="pageStart" dataSort width="8%" dataFormat={ this.activePageFormatter } >Pg. From</TableHeaderColumn>
                                <TableHeaderColumn dataField="pageEnd" dataSort width="6%" dataFormat={ this.activePageFormatter } >Pg. To</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisCode" dataSort sortFunc={this.sortdiagnosisCode} width="10%">Diag Code</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisDescription" dataSort width="18%">Description</TableHeaderColumn>
                                <TableHeaderColumn dataField="hccValue" dataSort sortFunc={this.sortNlpData} width="9%">HCC</TableHeaderColumn>
                                <TableHeaderColumn dataField="hccVersion" dataSort width="10%">HCC Version</TableHeaderColumn>
                                <TableHeaderColumn dataField="hccDescription" dataSort width="18%" hidden>HCC Desc</TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField='actions' dataFormat={this.formatAddAction}></TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField='actions' dataFormat={this.formatRemoveAction} hidden></TableHeaderColumn>
                            </BootstrapTable>
                        </Row>
                    }
                    {
                        this.props.reviewType != 'InitialCoding' &&
                        <Row style={{ marginBottom: '1rem', marginLeft: '0px', marginRight: '0px' }}>
                            <BootstrapTable data={this.props.nlpInsightData} className="imageViewerTable">
                                <TableHeaderColumn dataField="medicalRecordImageID" isKey hidden></TableHeaderColumn>
                                <TableHeaderColumn dataField="dosStart" dataFormat={this.formatDate} dataSort width="15%"> DOS From</TableHeaderColumn>
                                <TableHeaderColumn dataField="dosEnd" dataFormat={this.formatDate} dataSort width="15%"> DOS To</TableHeaderColumn>
                                <TableHeaderColumn dataField="pageStart" dataSort width="8%" dataFormat={ this.activePageFormatter } >Pg. From</TableHeaderColumn>
                                <TableHeaderColumn dataField="pageEnd" dataSort width="6%" dataFormat={ this.activePageFormatter } >Pg. To</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisCode" dataSort sortFunc={this.sortdiagnosisCode} width="10%">Diag Code</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisDescription" dataSort width="18%">Description</TableHeaderColumn>
                                <TableHeaderColumn dataField="hccValue" dataSort sortFunc={this.sortNlpData} width="9%">HCC</TableHeaderColumn>
                                <TableHeaderColumn dataField="hccVersion" dataSort width="10%">HCC Version</TableHeaderColumn>
                                <TableHeaderColumn dataField="hccDescription" dataSort width="18%" hidden>HCC Desc</TableHeaderColumn>
                            </BootstrapTable>
                        </Row>
                    }
                    <NLPDiagnosisFormModal visible={this.state.showFormModal} handleModalToggle={this.toggleFormModal} 
                    medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                    providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData} diagnosisComments={this.props.diagnosisComments}
                    imageRecords={this.props.imageRecords} userUploadedImages={this.props.userUploadedImages} isRADV={this.props.isRADV}
                    isQA={this.props.isQA} isComplete={this.props.isComplete} isMD={this.props.isMD} isCoderQA={this.props.isCoderQA}
                    isRiskMitigation={this.props.isRiskMitigation} medicalRecordTypeID={this.props.medicalRecordTypeID}
                    userID={this.props.userID} memberID={this.props.memberID} medicalRecordReviewID={this.props.medicalRecordReviewID}
                    maxDateDifference={this.props.maxDateDifference} getDiagnosisData={this.props.getDiagnosisData} 
                    getDiagnosisDataHistory={this.props.getDiagnosisDataHistory} 
                    editModalRow={this.state.editModalRow} editMode={this.state.editMode} editQABool={this.state.editQABool}
                    radvScores={this.props.radvScores} hccHierarchies={this.props.hccHierarchies} radvAuditEnrolleeHCCs={this.props.radvAuditEnrolleeHCCs}
                    getDiagnosisHistoryData={this.props.getDiagnosisHistoryData} projectID={this.props.projectID}
                    toggleDiagFeedbackModal={this.toggleDiagFeedbackModal} showDiagFeedbackModal={this.state.showDiagFeedbackModal}
                    setDiagFormData={this.props.setDiagFormData} selectedDiagLookupCD={this.state.selectedDiagLookupCD}
                    selectedDiagLookupDOS={this.state.selectedDiagLookupDOS} selectedDiagLookupDOE={this.state.selectedDiagLookupDOE} selectDiagLookup={this.selectDiagLookup} toast={this.props.toast}
                    radvEnrolleeID={this.props.radvEnrolleeID} reloadImages={this.props.reloadImages} selectedProvider={this.state.selectedProvider}
                    selectedSecondaryProvider={this.state.selectedSecondaryProvider} member={this.props.member} getProviderDropdownLabel={this.getProviderDropdownLabel} reduxDiags={this.props.diags}
                    autoClaimsLinkingEnabled={this.props.autoClaimsLinkingEnabled}
                    parallelClaimsDiagCaptureEnabled={this.props.parallelClaimsDiagCaptureEnabled}
                    claimsMap={this.state.claimsMap} claimsProviderMap={this.state.claimsProviderMap}
                    setClaimsAndClaimProviders={this.setClaimsAndClaimProviders} providerGroupMap={this.state.providerGroupMap}
                    setProviderGroup={this.setProviderGroup} reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} />                    
                    </Panel.Body>
                </Panel>
            </div>
        );

        
    }
}

export default NLPTableImage;
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Radio, Row } from 'react-bootstrap';
import Select from 'react-select';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';

const EMRInfo = (props) => {
    const handleEMRChange = (e) => {
        props.handleWorkflowFieldChange({ emr: e ? e.value : '' });
    }

    const handlePlatformChange = (e) => {
        props.handleWorkflowFieldChange({ platform: e ? e.value : '' });
    }

    const handleAccessTypeChange = (e) => {
        props.handleWorkflowFieldChange({ accessType: e ? e.value : '' });
    }

    const handleURLChange = (e) => {
        props.handleWorkflowFieldChange({ url: e.target.value });
    }

    const handleMFAChange = (e) => {
        props.handleWorkflowFieldChange({ mfa: !props.emrInfo.mfa });
    }

    const handleNotesChange = (e) => {
        props.handleWorkflowFieldChange({ notes: e.target.value });
    }

    return (
        <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            <Row>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel className="requiredField">EMR</ControlLabel>
                        <Select value={props.emrInfo.emr} options={props.emrOptions}
                            onChange={handleEMRChange} disabled={props.readOnly} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.EMRType)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel className="requiredField">Platform</ControlLabel>
                        <Select value={props.emrInfo.platform} options={props.emrPlatformOptions}
                            onChange={handlePlatformChange} disabled={props.readOnly} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.EMRPlatform)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel className="requiredField">Access Type</ControlLabel>
                        <Select value={props.emrInfo.accessType} options={props.accessTypeOptions}
                            onChange={handleAccessTypeChange} disabled={props.readOnly} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.AccessType)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel className="requiredField">MFA</ControlLabel>
                        <div>
                            <Radio style={{ marginLeft: '1rem' }} onChange={handleMFAChange}
                                value="yes" checked={props.emrInfo.mfa} inline disabled={props.readOnly}>Yes
                            </Radio>
                            <Radio onChange={handleMFAChange}
                                value="no" checked={!props.emrInfo.mfa} inline disabled={props.readOnly}>No
                            </Radio>
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>URL</ControlLabel>
                        <FormControl type="text" value={props.emrInfo.url} onChange={handleURLChange} maxLength="255" disabled={props.readOnly} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <FormGroup>
                        <ControlLabel>Notes</ControlLabel>
                        <FormControl type="text" value={props.emrInfo.notes} onChange={handleNotesChange} maxLength="1000" disabled={props.readOnly} />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default EMRInfo;
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AlertResponseLine from './AlertResponseLine';

export default class AlertResponseGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selections: this.props.alertDetails.map(x => {
                return {
                    cdiAlertEntityDetailID: x.cdiAlertEntityDetailID,
                    cdiAlertWorkflowQueryID: x.cdiAlertWorkflowQueryID,
                    selectedAlertResponse: x.selectedOption,
                    selectedQueryCode: x.selectedQueryCode,
                    selectedQueryResponse: x.selectedQueryResponse
                };
            })
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.alertDetails !== prevProps.alertDetails) {
            this.setState({
                selections: this.props.alertDetails.map(x => {
                    return {
                        cdiAlertEntityDetailID: x.cdiAlertEntityDetailID,
                        cdiAlertWorkflowQueryID: x.cdiAlertWorkflowQueryID,
                        selectedAlertResponse: x.selectedOption,
                        selectedQueryCode: x.selectedQueryCode,
                        selectedQueryResponse: x.selectedQueryResponse
                    };
                })
            });
        }
    }

    renderHeader = () => {
        return this.props.isQueryResponse || this.props.isViewOnly ? (
            <Row>
                <Col lg={7}>
                    <h5>{this.props.displayText}</h5>
                </Col>
                <Col lg={2}>
                    <h5>Query Code</h5>
                </Col>
                <Col lg={3}>
                    <h5>Query Response</h5>
                </Col>
            </Row>
        ) : (
            <Row>
                <Col lg={12}>
                    <h5>{this.props.displayText}</h5>
                </Col>
            </Row>
        )
    }

    handleAlertResponseChange = (e, line) => {
        const selection = this.state.selections.find(x => this.props.isQueryResponse ?
            x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
                && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID
            : x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID);

        if (!e && selection.selectedQueryCode) {
            this.props.toast('warning', 'Query Code and Query Response must be deleted before Alert Response.', '');
        }
        else {
            selection.selectedAlertResponse = e ? e.value : '';
            this.setState({
                selections: [
                    ...this.state.selections.filter(x => this.props.isQueryResponse ?
                        !(x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
                            && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID)
                        : x.cdiAlertEntityDetailID !== line.cdiAlertEntityDetailID),
                    selection
                ]
            });

            if (selection.selectedAlertResponse) {
                this.props.updateAlertResponse(selection.selectedAlertResponse, line.cdiAlertEntityDetailID,
                    line.cdiAlertWorkflowQueryID);
            }
            else {
                this.props.deleteAlertResponse(this.state.selectedAlertResponse, line.cdiAlertEntityDetailID,
                    line.cdiAlertWorkflowQueryID);
            }
        }
    }

    handleQueryCodeChange = (e, line) => {
        const selection = this.state.selections.find(x => x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
            && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID);
        selection.selectedQueryCode = e ? e.value : '';
        this.setState({
            selections: [
                ...this.state.selections.filter(x => !(x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
                    && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID)),
                selection
            ]
        });

        this.props.updateQueryResponse(selection.selectedQueryCode, selection.selectedQueryResponse,
            line.cdiAlertEntityDetailID, line.cdiAlertWorkflowQueryID);
    }

    handleQueryResponseChange = (e, line) => {
        const selection = this.state.selections.find(x => x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
            && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID);
        selection.selectedQueryResponse = e ? e.value : '';
        this.setState({
            selections: [
                ...this.state.selections.filter(x => !(x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
                    && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID)),
                selection
            ]
        });

        this.props.updateQueryResponse(selection.selectedQueryCode, selection.selectedQueryResponse,
            line.cdiAlertEntityDetailID, line.cdiAlertWorkflowQueryID);
    }

    handleQueryResponseDelete = (line) => {
        const selection = this.state.selections.find(x => x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
            && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID);
        selection.selectedQueryCode = '';
        selection.selectedQueryResponse = '';

        this.setState({
            selections: [
                ...this.state.selections.filter(x => !(x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
                    && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID)),
                selection
            ]
        }, this.props.deleteQueryResponse(line.cdiAlertWorkflowQueryID));
    }

    formatAlertResponseOptions = (options) => {
        return options.map(x => {
            return { label: x.optionText, value: x.optionID }
        });
    }

    formatQueryCodeOptions = (options) => {
        return options.map(x => {
            return { label: x.cdiAlertQueryCodeDesc, value: x.cdiAlertQueryCodeID }
        });
    }

    formatQueryResponseOptions = (options) => {
        return options.map(x => {
            return { label: x.cdiAlertQueryResponseDesc, value: x.cdiAlertQueryResponseID }
        });
    }

    render() {
        const queryCodeOptions = this.formatQueryCodeOptions(this.props.queryCodes);
        const queryResponseOptions = this.formatQueryResponseOptions(this.props.queryResponses);

        return (
            <div key={this.props.groupCode} className="alert-workflow-alert-response-group">
                {this.renderHeader()}
                {
                    this.props.alertDetails.map(line => {
                        const alertResponseOptions = this.formatAlertResponseOptions(line.responseOptions);
                        let lineSelections = this.state.selections.find(x => this.props.isQueryResponse ?
                            (x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID
                                && x.cdiAlertWorkflowQueryID === line.cdiAlertWorkflowQueryID)
                            : x.cdiAlertEntityDetailID === line.cdiAlertEntityDetailID);
                        lineSelections = lineSelections ?? {};
                        let selectedAlertResponse = lineSelections.selectedAlertResponse ?? {};
                        let selectedQueryCode = lineSelections.selectedQueryCode ?? {};
                        let selectedQueryResponse = lineSelections.selectedQueryResponse ?? {};

                        return (
                            <AlertResponseLine key={line.cdiAlertEntityDetailID + line.cdiAlertWorkflowQueryID}
                                isQueryResponse={this.props.isQueryResponse}
                                line={line} alertResponseOptions={alertResponseOptions} queryCodeOptions={queryCodeOptions}
                                queryResponseOptions={queryResponseOptions}
                                handleAlertResponseChange={this.handleAlertResponseChange}
                                handleQueryResponseChange={this.handleQueryResponseChange}
                                handleQueryCodeChange={this.handleQueryCodeChange}
                                handleQueryResponseDelete={this.handleQueryResponseDelete}
                                isViewOnly={this.props.isViewOnly} selectedAlertResponse={selectedAlertResponse}
                                selectedQueryCode={selectedQueryCode} selectedQueryResponse={selectedQueryResponse} />
                        );
                    })
                }
            </div>
        );
    }
}
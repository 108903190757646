import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, InputGroup, Row } from 'react-bootstrap';

const AlertModalAlertSearch = (props) =>
    <div style={{ margin: "2rem 1rem 0rem" }}>
        <Row style={{ marginBottom: "2rem" }}>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>
                        Alert ID
                    </ControlLabel>
                    <InputGroup>
                        <FormControl type="text" value={props.alertID}
                            onChange={props.handleAlertIDChange}
                            onClick={props.clearTextEntry} />
                        <InputGroup.Button>
                            <Button onClick={props.getAlertMetadata}>Verify ID</Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>
                        Member ID
                    </ControlLabel>
                    <FormControl type="text" value={props.alertMetadata.memberID ? props.alertMetadata.memberID : ""} disabled style={{ cursor: "inherit" }} />
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>
                        Member Name
                    </ControlLabel>
                    <FormControl type="text" value={props.memberFullName} disabled style={{ cursor: "inherit" }} />
                </FormGroup>
            </Col>
        </Row>
        <Row style={{ marginBottom: "2rem" }}>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>
                        Assigned Practice
                    </ControlLabel>
                    <FormControl type="text" value={props.alertMetadata.practice ? props.alertMetadata.practice : ""} disabled style={{ cursor: "inherit" }} />
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>
                        Assigned Provider
                    </ControlLabel>
                    <FormControl type="text" value={props.providerFullName} disabled style={{ cursor: "inherit" }} />
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <ControlLabel>
                        Assigned Provider ID
                    </ControlLabel>
                    <FormControl type="text" value={props.alertMetadata.providerID ? props.alertMetadata.providerID : ""} disabled style={{ cursor: "inherit" }} />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Button onClick={props.saveAlertData} disabled={props.createWorkflowDisabled} bsStyle="success">
                    <Glyphicon glyph="plus" style={{ marginRight: '0.3rem' }} /> Create Workflow
                </Button>
            </Col>
        </Row>
    </div>

export default AlertModalAlertSearch;
// exports ClaimMatchType - an immutable object 'enum'
// usage: import { ClaimMatchType } from '../../enums/ClaimMatchType';
const claimMatchType = {
    'Rank1': 1, // Exact match, same provider
    'Rank2': 2, // Exact match, same practice
    'Rank3': 3, // Pending edge, same practice
    'Rank4': 4, // No provider match, edge accepted
    'Rank5': 5, // No provider match, pending edge
    'Rank6': 6, // DOS near, edge accepted, same practice
    'Rank7': 7, // DOS near, pending edge, same practice
    'Rank8': 8, // DOS not near, same practice
    'Rank9': 9, // DOS near, no provider match
    'Rank10': 10, // No Match
    'Rank11': 11  // QA rejected
};
Object.freeze(claimMatchType);
export const ClaimMatchType = claimMatchType;
import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import NavbarCollapse from 'react-bootstrap/lib/NavbarCollapse';

class WorkflowExceptionsEditLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exceptionReason: this.props.correction.cdiAlertWorkflowExceptionOptionID,
            resolved: this.props.correction.addressed,
            resolvedDate: this.props.correction.addressedDatetime ? this.props.correction.addressedDatetime : '',
            pecComments: this.props.correction.cdiAlertWorkflowExceptionPECComment ?
                this.props.correction.cdiAlertWorkflowExceptionPECComment : '',
            cdiFreeText: this.props.cdiFreeCommentText
        };
        this.createdByTooltip = (
            <Tooltip id="alert-workflow-exception-edit-createdby">
                {this.props.correction.reviewer}
            </Tooltip>
        );
    }

    handleExceptionReasonChange = (e) => {
        this.setState({ exceptionReason:  e ? e.value : '' });
    }

    handleResolvedChange = (e) => {
        if (e.value) {
            this.setState({
                resolved: true,
                resolvedDate: new Date().toISOString()
            });
        }
        else {
            this.setState({
                resolved: false,
                resolvedDate: ''
            })
        }
    }

    handlePECCommentsChange = (e) => {
        this.setState({ pecComments: e.target.value });
    }

    handleSaveWorkflowException = () => {
        this.props.saveWorkflowException({
            ...this.props.correction,
            CDIAlertWorkflowExceptionOptionID: this.state.exceptionReason,
            Addressed: this.state.resolved,
            CDIAlertWorkflowExceptionPECComment: this.state.pecComments
        });
    }

    handleDeleteWorkflowException = () => {
        this.props.deleteWorkflowException(this.props.correction.cdiAlertWorkflowCorrectionID);
    }

    isExceptionReasonDisabled = () => {
        return this.state.resolved || this.isExceptionEditDisabled();
    }

    isExceptionEditDisabled = () => {
        return this.props.correction.isViewOnly || this.props.isViewOnly;
    }

    isExceptionDeleteDisabled = () => {
        return this.props.correction.addressed || Boolean(this.props.correction.medicalRecordReviewCommentID) || this.isExceptionEditDisabled();
    }

    renderExceptionReason = () => {
        //if PEC comment text is provided, render that instead of an exception comment selection
        return !this.props.correction.commentText ?
            <Select value={this.state.exceptionReason} options={this.props.workflowExceptionOptions}
                onChange={this.handleExceptionReasonChange} disabled={this.isExceptionReasonDisabled()} />
        : <FormControl type="text" value={this.props.correction.commentText} disabled />
    }

    renderFreeText = () => {
        return this.props.cdiFreeCommentText ? 
        <p>
            <ControlLabel>Free Text</ControlLabel>
            <br></br>
            {this.props.cdiFreeCommentText}
        </p>
        : <p>&nbsp;</p>
    }

    render() {
        return (
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>Exception Reason</ControlLabel>
                        {this.renderExceptionReason()}
                        {this.renderFreeText()}
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <OverlayTrigger placement="top" overlay={this.createdByTooltip}>
                        <FormGroup style={{ display: 'inline-block', maxWidth: '160px', paddingRight: '15px', paddingLeft: '15px' }}>
                            <ControlLabel>Created Date</ControlLabel>
                            <DatePicker value={this.props.correction.commentDate} disabled style={{ zIndex: 'auto' }} />
                        </FormGroup>
                    </OverlayTrigger>
                    <FormGroup style={{ display: 'inline-block', width: '120px', paddingRight: '15px', paddingLeft: '15px' }}>
                        <ControlLabel>Resolved</ControlLabel>
                        <Select value={this.state.resolved} options={this.props.resolvedOptions}
                            onChange={this.handleResolvedChange} disabled={this.isExceptionEditDisabled()} />
                    </FormGroup>
                    <FormGroup style={{ display: 'inline-block', width: '180px', paddingRight: '15px', paddingLeft: '15px' }}>
                        <ControlLabel>Resolved Date</ControlLabel>
                        <DatePicker value={this.state.resolvedDate} disabled style={{ zIndex: 'auto' }} />
                    </FormGroup>
                </Col>
                <Col xs={4} style={{ paddingLeft: 0 }}>
                    <FormGroup>
                        <ControlLabel>PEC Comments</ControlLabel>
                        <FormControl componentClass="textarea" maxLength="500" value={this.state.pecComments}
                            onChange={this.handlePECCommentsChange} disabled={this.isExceptionEditDisabled()} />
                    </FormGroup>
                </Col>
                <Col lg={1}>
                    <FormGroup>
                        <ControlLabel>&nbsp;</ControlLabel>
                        <div style={{ display: 'block' }}>
                            <Button onClick={this.handleSaveWorkflowException} disabled={this.isExceptionEditDisabled()}
                                style={{ display: 'inline-block' }}>
                                <Glyphicon glyph="floppy-disk" style={{'marginRight': '0.2rem'}} />
                            </Button>
                            <Button onClick={this.handleDeleteWorkflowException} disabled={this.isExceptionDeleteDisabled()}
                                style={{ display: 'inline-block' }}>
                                <Glyphicon glyph="trash" style={{'marginRight': '0.2rem'}} />
                            </Button>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

export default WorkflowExceptionsEditLine;
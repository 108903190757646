// exports ClaimsSummaryRankDescription - an immutable object 'enum'
// usage: import { ClaimsSummaryRankDescription } from '../../enums/ClaimsSummaryRankDescription';
const claimsSummaryRankDescription = {
    1: ['Member Match', 'DOS Full', 'Status - Edge Accepted', 'RACsd Risk Adjustable - Yes', 'Same Provider'],
    2: ['Member Match', 'DOS Full', 'Status - Edge Accepted', 'RACsd Risk Adjustable - Yes', 'Same Practice'],
    3: ['Member Match', 'DOS Full', 'Status - Edge Not Submitted or Edge Rejected',
        'RACsd Risk Adjustable - N/A', 'Same Provider/Same Practice'],
    4: ['Member Match', 'DOS Full', 'Status - Edge Accepted', 'RACsd Risk Adjustable - Yes', 'No Provider Match'],
    5: ['Member Match', 'DOS Full', 'No Provider Match'],
    6: ['Member Match', 'DOS Near', 'Status - Edge Accepted', 'RACsd Risk Adjustable - Yes', 'Same Provider/Same Practice'],
    7: ['Member Match', 'DOS Near', 'Same Provider/Same Practice'],
    8: ['DOS Not Near', 'Same Practice'],
    9: ['DOS Near', 'No Provider Match'],
    10: ['No Match'],
    11: ['QA Rejected']
};
Object.freeze(claimsSummaryRankDescription);
export const ClaimsSummaryRankDescription = claimsSummaryRankDescription;
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import AlertDialog from '../../Shared/AlertDialog';
import { FaxRequestDocumentType } from '../../../enums/FaxRequestDocumentType';
import { FaxStatus } from '../../../enums/FaxStatus';
import { PracticeValidationFileType } from '../../../enums/PracticeValidationFileType';
import { getDatePickerValueAsMidnightISOString } from '../../../utils/DateUtils';

class PracticeInfoEditModal extends React.Component {
    constructor(props) {
        super(props);

        this.defaultPracticeValidationInfo = {
            practiceValidationID: '',
            groupingID: '',
            practiceName: '',
            providerOrganizationID: '',
            providerName: '',
            outboundTypeID: '',
            validationStatusID: '',
            validationReceivedDate: '',
            expirationDate: '',
            practiceContactID: '',
            contactName: '',
            contactPhone: '',
            originalAddress1: '',
            originalAddress2: '',
            originalCity: '',
            originalState: '',
            originalZip: '',
            originalPhone: '',
            originalFax: '',
            notes: '',
            lastFaxStatus: '',
            isExpired: false
        };

        this.state = {
            practiceValidationInfo: {...this.defaultPracticeValidationInfo},
            correctedAddress1: '',
            correctedAddress2: '',
            correctedCity: '',
            correctedState: '',
            correctedZipCode: '',
            correctedPhone: '',
            correctedFaxNumber: '',
            email: '',
            fileData: [],
            defaultAssignedPEC: '',
            assignedPEC: '',
            assignedPECOptions: [
                { value: 0, label: "Unassigned" },
                ...this.props.users.map((item) => { return { value: item.userID, label: item.fullName }; })
            ],
            visible: false,
            communicationTypeOptions: this.props.outboundTypes.map(item => { return { value: item.practiceOutboundTypeID, text: item.outboundMethod } }),
            practiceValidationStatusOptions: this.props.validationStatuses.map(item => { return { value: item.practiceValidationStatusID, label: item.statusDesc } }),
            faxRequestTypeOptions: Object.entries(PracticeValidationFileType).map(([k, v]) => {
                return k !== "FollowUp" ? { value: v, label: k } : {value: v, label: 'Follow-up'};
            }).filter(x => x.value !== 1 && x.value !== 5),
            lastCreatedValidationSheet: {},
            pendingFaxRequestTypeID: '',
            pendingFaxDoc: '',
            pendingFaxNumber: '',
            loaded: false,
            loadingResults: false,
            showFaxConfirmationModal: false,
            validationErrorMessages: [],
            minValidationReceivedDate: new Date(new Date().setDate(new Date().getDate() - 60)), // Validation rule of ValidationReceivedDate field: should not be more than 60 days from the current date.
            maxValidationReceivedDate: new Date()
        };
        this.timer = null;
        this.pollingInterval = 5000;

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillUnmount() {
        this.source.cancel();
        clearTimeout(this.timer);
    }

    getPracticeEditInfo = () => {
        let queryParams = { practiceID: this.props.selectedRow.groupingID };
        axios.get('/practice/PracticeEditInfo', {
            params: queryParams,
            cancelToken: this.source.token
        })
        .then(response => {
            if (response.data) {
                //replace null values with empty string
                Object.keys(response.data).forEach(key => {
                    response.data[key] = response.data[key] ? response.data[key] : ''
                });

                this.setState({
                    practiceValidationInfo: response.data,
                    correctedAddress1: response.data.address1,
                    correctedAddress2: response.data.address2,
                    correctedCity: response.data.city,
                    correctedState: response.data.state,
                    correctedZipCode: response.data.zipCode,
                    correctedPhone: response.data.phone,
                    correctedFaxNumber: response.data.faxNumber,
                    email: response.data.email,
                    loaded: true
                });
            }
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                //request canceled
            }
            else {
                // handle error
                this.props.toast('error', 'Could not load practice info.', 'Error');
            }
        });
    }

    handleModalToggle = () => {
        if (this.state.visible) {
            clearTimeout(this.timer);
        }
        this.setState({ 
            visible: !this.state.visible,
            validationErrorMessages: []
        });
    }

    openEditModal = () => {
        //get practice validation info (initial load)
        if (!this.state.practiceValidationInfo.validationStatusID > 0) {
            this.getPracticeEditInfo();
        }
        this.setState({ visible: true },
        () => {
            this.refreshData();
            this.getAssignedPEC();
        });
    }

    refreshData = () => {
        clearTimeout(this.timer);

        if (this.state.visible) {
            this.getFiles();
            this.timer = setTimeout(this.refreshData, this.pollingInterval);
        }
    }

    createValidationSheetBundle = () => {
        if (!this.state.practiceValidationInfo.practiceValidationID) {
            this.props.toast(
                'error', 
                'No practice validation exists for this record. Please select a validation status and click save before attempting to create a new validation sheet.',
                'Error'
            );
            return;
        }

        if (!this.faxNumberExists()) {
            this.props.toast(
                'error', 
                'A fax number is required to create a validation sheet.',
                'Error'
            );
            return;
        }

        this.setState({ loadingResults: true });

        axios.post(`/practice/PracticeValidations/${this.state.practiceValidationInfo.practiceValidationID}/ValidationFiles`, {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.setState({ loadingResults: false });
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                //request canceled
            }
            else {
                // handle error
                this.setState({ loadingResults: false });
                this.props.toast('error', 'Could not load created practice validation files.', 'Error');
            }
        });
    }

    faxNumberExists = () => {
        return this.state.practiceValidationInfo.originalFax || this.state.correctedFaxNumber;
    }

    getFiles = () => {
        const practiceValidationID = this.state.practiceValidationInfo.practiceValidationID ?
            this.state.practiceValidationInfo.practiceValidationID : this.props.selectedRow.practiceValidationID;

        if (practiceValidationID) {
            axios.get(`/practice/PracticeValidations/${practiceValidationID}/ValidationFiles`, {
                cancelToken: this.source.token
            })
            .then(response => {
                if (response.data.length) {
                    const downloaded = response.data.filter(this.filterForDownload);
                    this.setState({
                        fileData: downloaded,
                        lastCreatedValidationSheet: this.getLastCreatedValidationSheet(downloaded)
                    });
                }
                else {
                    this.setState({ fileData: [] });
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    //request canceled
                }
                else {
                    // handle error
                    this.props.toast('error', 'Could not load files.', 'Error');
                }
            });
        }
    }

    getAssignedPEC = () => {
        axios.get(`/practice/${this.props.selectedRow.groupingID}/UserAssignments`,
        {
            cancelToken: this.source.token
        })
        .then(response => {
            if (response.data) {
                this.setState({
                    defaultAssignedPEC: response.data.userID,
                    assignedPEC: response.data.userID
                });
            }
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                //request canceled
            }
            else {
                // handle error
                this.props.toast('error', 'Could not load assigned PEC.', 'Error');
            }
        });
    }

    setAssignedPEC = (e) => {
        this.setState({ assignedPEC: e.value });
    }

    filterForDownload = (item) => {
        if (this.linksContainsDownload(item.links)) {
            return true;
        }
        return false;
    }

    linksContainsDownload = (links) => {
        const downloadExists = links.some(function(element){
            return element.rel === "download";
        })
        return downloadExists;
    }

    handleFaxLastValidationSheet = () => {
        if (!this.faxNumberExists()) {
            this.props.toast('error', 'A fax number is required to create a validation sheet.', 'Error');
            return;
        }

        if (this.state.fileData && this.state.fileData.length > 0) {
            //get last created validation response sheet; launch modal after state is set
            const docToFax = this.getLastCreatedValidationSheet(this.state.fileData);
            if (docToFax.practiceValidationFileID) {
                this.setState({
                    pendingFaxNumber: this.state.correctedFaxNumber ? this.state.correctedFaxNumber : this.state.practiceValidationInfo.originalFax,
                    pendingFaxDoc: {
                        practiceValidationFileID: docToFax.practiceValidationFileID,
                        filename: docToFax.fileName
                    }
                }, () => this.handleShowFaxConfirmationModal());
            }
            else {
                this.props.toast('error', 'Please create a Response Sheet before attempting to fax.', 'Error');
            }
        }
        else {
            this.props.toast('error', 'Please create a Response Sheet before attempting to fax.', 'Error');
        }
    }

    getLastCreatedValidationSheet = (files) => {
        const createdFiles = files.filter((file) => file.fileType.practiceValidationFileTypeID !== PracticeValidationFileType.Response);
        return createdFiles.length > 0 ? createdFiles.reduce((a, b) => { return a.practiceValidationFileID > b.practiceValidationFileID ? a : b }) : {};
    }

    handleShowFaxConfirmationModal = () => {
        if (this.state.showFaxConfirmationModal === true) {
            this.setState({
                showFaxConfirmationModal: !this.state.showFaxConfirmationModal,
                pendingFaxNumber: '',
                pendingFaxDoc: {},
                pendingFaxRequestTypeID: ''
            });
        }
        else {
            this.setState({ showFaxConfirmationModal: !this.state.showFaxConfirmationModal });
        }
    }

    setFaxRequestType = (e) => {
        this.setState({ pendingFaxRequestTypeID: e.value });
    }

    createFaxRequest = () => {
        //ensure fax type is selected
        if (!this.state.pendingFaxRequestTypeID) {
            this.props.toast('error', 'No fax request type was selected. Fax was not sent.', 'Error');
            return;
        }
        else {
            const practiceValidationID = this.state.practiceValidationInfo.practiceValidationID ?
                    this.state.practiceValidationInfo.practiceValidationID : this.props.selectedRow.practiceValidationID;
            const validationFileID = this.state.pendingFaxDoc.practiceValidationFileID;

            //create fax request
            axios.post(`/FaxRequests/`, {
                documentType: FaxRequestDocumentType.PracticeValidationFile,
                documentEntityID: validationFileID,
                faxNumber: this.state.pendingFaxNumber,
                faxToName: this.state.practiceValidationInfo.contactName,
                cancelToken: this.source.token
            })
            .then((response) => {
                //update PracticeValidationFile's file type on successful create of fax request
                axios.put(`/practice/PracticeValidations/${practiceValidationID}/ValidationFiles/${validationFileID}`, {
                    PracticeValidationFileTypeID: this.state.pendingFaxRequestTypeID,
                    cancelToken: this.source.token
                })
                .then((response) => {
                    this.setState({ loadingResults: false });
                    this.props.toast('success', 'Fax in progress.', 'Success');
                    this.handleShowFaxConfirmationModal();
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        //request canceled
                    }
                    else {
                        // handle error
                        this.setState({ loadingResults: false });
                        this.props.toast('error', 'Could not send fax.', 'Error');
                    }
                });
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    //request canceled
                }
                else {
                    // handle error
                    this.setState({ loadingResults: false });
                    this.props.toast('error', 'Could not send fax.', 'Error');
                }
            });
        }
    }

    formatPdfLink = (cell, row) => {
        if (this.state.lastCreatedValidationSheet.practiceValidationFileID === row.practiceValidationFileID) {
            return (
                <div>
                    <Button onClick={() => this.getPdf(row)}>
                        <Glyphicon glyph="file" style={{ 'marginLeft': '0.66rem' }} title={`${row.fileName} - ${row.createdUser}`} />
                    </Button>
                    <Glyphicon glyph="send" style={{ 'marginLeft': '0.66rem' }} title={`${row.fileName} - ${row.createdUser}`} />
                </div>
            );
        }
        else {
            return (
                <Button onClick={() => this.getPdf(row)}>
                    <Glyphicon glyph="file" style={{ 'marginLeft': '0.66rem' }} title={`${row.fileName} - ${row.createdUser}`} />
                </Button>
            );
        }
    }

    formatFileType = (cell, row) => {
        if (row.fileType) {
            return row.fileType.fileTypeDesc;
        }
    }

    formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }

    formatTimestamp = (timeString, row) => {
        if (!timeString) {
            return '';
        }
        const date = new Date(timeString);
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString('en-US')}`;
        const formattedName = row.updatedUserID > 0 ? row.updatedUsername : row.createdUserFullname;
        if (formattedName.length) {
            return formattedDate + '\n' + formattedName;
        }
        else {
            return formattedDate;
        }
    }

    getFaxStatusDescription = (status) => {
        let desc;
        if (status) {
            //find key (status desc) that matches status code
            desc = Object.keys(FaxStatus).find(key => FaxStatus[key] === status);
        }

        return desc ? desc : 'N/A';
    }

    getPracticeValidationFileTypeDescription = (fileType) => {
        let desc;
        if (fileType) {
            desc = Object.keys(PracticeValidationFileType).find(key => PracticeValidationFileType[key] === fileType);
            if (desc === 'FollowUp') {
                desc = 'Follow-up';
            }
        }

        return desc ? desc : '';
    }

    getOutboundTypeDescription = (outboundTypeId) => {
        return this.state.communicationTypeOptions.find((item) =>
            item.value === this.state.practiceValidationInfo.outboundTypeID
        ).text;
    }

    getPdf = (bundle) => {
        const link = bundle.links.find(function(link){
            return link.rel === "download";
        });
        axios.get(link.href, {
            responseType: 'arraybuffer',
            cancelToken: this.source.token
        })
        .then((response) => {
            this.fileDownload(response.data, bundle.fileName, "application/pdf");
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                //request canceled
            }
            else {
                // handle error
                this.props.toast('error', 'Could not download bundle.', 'Error');
            }
        });
    }

    fileDownload = (data, filename, mime) => {
        const blob = new Blob([data], {type: mime || 'application/octet-stream'});
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were 
            // revoked by closing the blob for which they were created. 
            // These URLs will no longer resolve as the data backing 
            // the URL has been freed."
            window.navigator.msSaveOrOpenBlob(blob, filename);
        }
        else {  
            const blobURL = window.URL.createObjectURL(blob);
            window.open(blobURL);
        }
    }

    savePractice = () => {
        if (this.validate()){
            let savePromise;
            if (this.state.defaultAssignedPEC !== this.state.assignedPEC) {
                savePromise = Promise.all([
                    axios.put(`/practice/${this.props.selectedRow.groupingID}/UserAssignments`, {
                        UserID: parseInt(this.state.assignedPEC, 10),
                        cancelToken: this.source.token
                    }),
                    axios.put('/practice/PracticeInfo', {
                        PracticeContactID: this.state.practiceValidationInfo.practiceContactID,
                        GroupingID: this.state.practiceValidationInfo.groupingID,
                        ContactName: this.state.practiceValidationInfo.contactName,
                        ContactPhone: this.state.practiceValidationInfo.contactPhone,
                        OutboundTypeID: this.state.practiceValidationInfo.outboundTypeID,
                        ValidationStatusID: this.state.practiceValidationInfo.validationStatusID,
                        ValidationReceivedDate: this.state.practiceValidationInfo.validationReceivedDate,
                        Address1: this.state.correctedAddress1,
                        Address2: this.state.correctedAddress2,
                        City: this.state.correctedCity,
                        State: this.state.correctedState,
                        ZipCode: this.state.correctedZipCode,
                        Phone: this.state.correctedPhone,
                        FaxNumber: this.state.correctedFaxNumber,
                        Email: this.state.email,
                        Notes: this.state.practiceValidationInfo.notes,
                        cancelToken: this.source.token
                    })
                ]);
            }
            else {
                savePromise = axios.put('/practice/PracticeInfo', {
                    PracticeContactID: this.state.practiceValidationInfo.practiceContactID,
                    GroupingID: this.state.practiceValidationInfo.groupingID,
                    ContactName: this.state.practiceValidationInfo.contactName,
                    ContactPhone: this.state.practiceValidationInfo.contactPhone,
                    OutboundTypeID: this.state.practiceValidationInfo.outboundTypeID,
                    ValidationStatusID: this.state.practiceValidationInfo.validationStatusID,
                    ValidationReceivedDate: this.state.practiceValidationInfo.validationReceivedDate,
                    Address1: this.state.correctedAddress1,
                    Address2: this.state.correctedAddress2,
                    City: this.state.correctedCity,
                    State: this.state.correctedState,
                    ZipCode: this.state.correctedZipCode,
                    Phone: this.state.correctedPhone,
                    FaxNumber: this.state.correctedFaxNumber,
                    Email: this.state.email,
                    Notes: this.state.practiceValidationInfo.notes,
                    cancelToken: this.source.token
                });
            }
    
            savePromise.then(response => {
                const practiceContactModel = {
                    ...this.state.practiceValidationInfo, 
                    assignedPEC: this.state.assignedPEC};
                if (Array.isArray(response)) {
                    response.forEach(res => {
                        if (res.data && res.data.practiceValidationID) {
                            practiceContactModel.practiceValidationID = res.data.practiceValidationID;
                            practiceContactModel.expirationDate = res.data.validationExpirationDate;
                            practiceContactModel.validationReceivedDate = res.data.validationReceivedDate;
                            practiceContactModel.validationStatusID = res.data.practiceValidationStatusID;
                            this.setState({
                                practiceValidationInfo: {
                                    ...this.state.practiceValidationInfo,
                                    practiceValidationID: res.data.practiceValidationID,
                                    expirationDate: res.data.validationExpirationDate,
                                    validationReceivedDate: res.data.validationReceivedDate,
                                    validationStatusID: res.data.practiceValidationStatusID
                                }
                            });
                        }
                    });
                }
                else {
                    practiceContactModel.practiceValidationID = response.data.practiceValidationID;
                    practiceContactModel.expirationDate = response.data.validationExpirationDate;
                    practiceContactModel.validationReceivedDate = response.data.validationReceivedDate;
                    practiceContactModel.validationStatusID = response.data.practiceValidationStatusID;
                    this.setState({
                        practiceValidationInfo: {
                            ...this.state.practiceValidationInfo,
                            practiceValidationID: response.data.practiceValidationID,
                            expirationDate: response.data.validationExpirationDate,
                            validationReceivedDate: response.data.validationReceivedDate,
                            validationStatusID: response.data.practiceValidationStatusID
                        }
                    });
                }
    
                this.props.toast('success', 'Practice information was saved successfully.', 'Success');
                this.props.updateRow(practiceContactModel);
            })
            .catch(error => {
                this.props.toast('error', (error.response && error.response.data) ? error.response.data.message : 'Could not save practice information.', 'Error');
            });
        }
    }

    handleParamChange = (statePropertyName) => (e) => {
        this.setState({
            practiceValidationInfo: {
                ...this.state.practiceValidationInfo,
                ...{[statePropertyName]: statePropertyName === 'validationStatusID' ? e.value : (statePropertyName === 'validationReceivedDate' ? e : e.target.value)}
            }
        });

        switch (statePropertyName) {
            case 'address1':
                this.setState({ correctedAddress1: e.target.value });
                break;
            case 'address2':
                this.setState({ correctedAddress2: e.target.value });
                break;
            case 'city':
                this.setState({ correctedCity: e.target.value });
                break;
            case 'state':
                this.setState({ correctedState: e.target.value });
                break;
            case 'zipCode':
                this.setState({ correctedZipCode: e.target.value });
                break;
            case 'phone':
                this.setState({ correctedPhone: e.target.value });
                break;
            case 'faxNumber':
                this.setState({ correctedFaxNumber: e.target.value });
                break;
            case 'email':
                this.setState({ email: e.target.value});
                break;
            default:
                break;
        }
    }

    handleUpload = (e) => {
        if (!this.state.practiceValidationInfo.practiceValidationID) {
            this.props.toast(
                'error', 
                'No practice validation exists for this record. Please select a validation status and click save before attempting to upload a validation file.',
                'Error'
            );
            return;
        }

        let data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('name', 'temp');

        axios.put(`/practice/PracticeValidations/${this.state.practiceValidationInfo.practiceValidationID}/ValidationFiles`, data)
        .then(response => {
            this.props.toast('success', 'File uploaded.', '');
            this.refs.fileUpload.value = "";
        })
        .catch(error => {
            this.props.toast('error', 'Could not upload file.', 'Error');
            this.refs.fileUpload.value = "";
        })
    }

    renderFaxConfirmationBody = () => {
        return (
            <div style={{height: '24rem'}}>
                <h5 style={{'marginBottom': '2rem'}}><b>Document:</b>
                    <span style={{'marginLeft': '2rem'}}><b>{this.state.pendingFaxDoc.filename}</b></span>
                </h5>
                <h5 style={{'marginBottom': '2rem'}}><b>Fax Number:</b>
                    <span style={{'marginLeft': '1.3rem'}}><b>{this.state.pendingFaxNumber}</b></span>
                </h5>
                <FormGroup style={{'marginBottom': '2.4rem'}}>
                    <ControlLabel>Fax Type:</ControlLabel>
                    <Select
                        value={this.state.pendingFaxRequestTypeID ? this.state.pendingFaxRequestTypeID : ''}
                        options={this.state.faxRequestTypeOptions}
                        onChange={this.setFaxRequestType} />
                </FormGroup>
            </div>
        );
    }

    renderHeader = () => {
        return this.state.practiceValidationInfo.isExpired ?
            <h4>{this.state.practiceValidationInfo.groupingID} - {this.state.practiceValidationInfo.practiceName} (Expired)</h4>
            : <h4>{this.state.practiceValidationInfo.groupingID} - {this.state.practiceValidationInfo.practiceName}</h4>
    }

    handleDelete = (rowIndex) => {
        if (this.state.practiceValidationInfo.validationStatusID === 4 || this.state.practiceValidationInfo.validationStatusID === 5) {
            if (window.confirm("The file you are trying to delete may be evidence of the practice's response. Are you sure you want to delete this file?")) {
                axios.post(`/practice/PracticeValidations/${this.state.fileData[rowIndex].practiceValidationFileID}/PracticeValidationFile`,
                {
                    cancelToken: this.source.token
                })
                .then(response => {
                    this.props.toast('success', 'File deleted.', '');
                    this.getFiles();
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        //request canceled
                    }
                    else {
                        // handle error
                        this.props.toast('error', 'Could not delete file.', 'Error');
                    }
                });
            }
        }
        else if (window.confirm("Are you sure you want to delete this file?")) {
            axios.post(`/practice/PracticeValidations/${this.state.fileData[rowIndex].practiceValidationFileID}/PracticeValidationFile`,
            {
                cancelToken: this.source.token
            })
            .then(response => {
                this.props.toast('success', 'File deleted.', '');
                this.getFiles();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    //request canceled
                }
                else {
                    // handle error
                    this.props.toast('error', 'Could not delete file.', 'Error');
                }
            });
        }
    }

    validate = () => {
        const errorArr = [];
        // match all possible email addresses without considering double quotes and square brackets
        if (this.state.email && !this.state.email.match(/^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/))
        {
            errorArr.push({ id: "invalidEmail", field: "Email", errorText: "Email is not valid" });
        }
        // if the validation status is "Accepted", validation received date should be required
        if ((this.state.practiceValidationInfo.validationReceivedDate == null) && (this.state.practiceValidationInfo.validationStatusID === 4))
        {
            errorArr.push({ id: "requireValidationReceivedDate", field: "validationReceivedDate", errorText: "Received Date is required to accept the validation" });
        }
        
        if (new Date(this.state.practiceValidationInfo.validationReceivedDate) < new Date(this.state.minValidationReceivedDate.setHours(0,0,0,0)))
        {
            errorArr.push({ id: "badValidationReceivedDate", field: "validationReceivedDate", errorText: "Received Date must be within 60 days" })
        }

        // todo: add phone and fax validations after determining business requirements
        // match +1 1234567890, 123-456-7890, (123)456-7890, (123) 456-7890, 1234567890
        // if (this.state.practiceValidationInfo.contactPhone && !this.state.practiceValidationInfo.contactPhone.match(/^(\+[0-9]{1,3}\s*)?((\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4})|[0-9]{10}$/))
        // {
        //     errorArr.push({ id: "invalidPOCPhone", field: "POCPhone", errorText: "POC Phone number is not valid" });
        // }
        // match +1 1234567890, 123-456-7890, (123)456-7890, (123) 456-7890, 1234567890
        // if (this.state.correctedPhone && !this.state.correctedPhone.match(/^(\+[0-9]{1,3}\s*)?((\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4})|[0-9]{10}$/))
        // {
        //     errorArr.push({ id: "invalidCorrectedPhone", field: "correctedPhone", errorText: "Corrected Phone number is not valid" });
        // }

        this.setState({ validationErrorMessages: errorArr });
        return errorArr && !errorArr.length > 0;
    }

    render() {
        const faxConfirmationBody = this.renderFaxConfirmationBody();

        const customSelectInProgress = (props, row) => {
            if (props.rowIndex !== 'Header' && this.state.fileData[props.rowIndex].fileType.practiceValidationFileTypeID === 5) {
                return (
                    <div>
                        <Button onClick={() => this.handleDelete(props.rowIndex)} style={{'marginLeft': '1rem'}}>
                            <Glyphicon glyph="trash" />
                        </Button>
                    </div>
                );
            }
            else {
                return null;
            }
        };

        const selectRowInProgress = {
            mode: 'radio',
            customComponent: customSelectInProgress,
            columnWidth: '11rem'
        };

        return (
            <div>
                <Button onClick={this.openEditModal}>
                    <Glyphicon glyph="pencil"/>
                </Button>
                {
                    this.state.loaded &&
                    <Modal dialogClassName="modal-dialog-fullwidth" show={this.state.visible} onHide={this.handleModalToggle} keyboard>
                        <Modal.Header closeButton>
                            {this.renderHeader()}
                        </Modal.Header>
                            <Modal.Body style={{ maxWidth: '1860px' }}>
                                <Row style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={12}>
                                                <Form style={{ marginTop: '0.6rem', marginBottom: '1.6rem' }}>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Practice ID:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.groupingID} disabled placeholder="Practice Code" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Practice Name:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.practiceName} disabled placeholder="Practice Name" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>PO Code:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.providerOrganizationID} disabled placeholder="PO Code" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>PO ID:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.providerName} disabled placeholder="PO Name" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Communication Type:</ControlLabel>
                                                                <FormControl type="text" value={this.getOutboundTypeDescription(this.state.practiceValidationInfo.outboundTypeID)} disabled placeholder="Communication Type" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Validation Status:</ControlLabel>
                                                                <Select
                                                                    value={this.state.practiceValidationInfo.validationStatusID}
                                                                    options={this.state.practiceValidationStatusOptions}
                                                                    onChange={this.handleParamChange('validationStatusID')}
                                                                    clearable={false}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Received Date:</ControlLabel>
                                                                <DatePicker value={this.state.practiceValidationInfo.validationReceivedDate} 
                                                                            onChange={this.handleParamChange('validationReceivedDate')} 
                                                                            style={{ zIndex: 'auto' }} 
                                                                            minDate={getDatePickerValueAsMidnightISOString(this.state.minValidationReceivedDate)}
                                                                            maxDate={getDatePickerValueAsMidnightISOString(this.state.maxValidationReceivedDate)} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Last Faxed:</ControlLabel>
                                                                <FormControl type="text" value={this.getPracticeValidationFileTypeDescription(this.state.practiceValidationInfo.lastFaxType)} disabled placeholder="N/A" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>POC Name:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.contactName} placeholder="POC Name" onChange={this.handleParamChange('contactName')} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>POC Phone:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.contactPhone} placeholder="POC Phone" maxLength={25} onChange={this.handleParamChange('contactPhone')} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Assigned PEC:</ControlLabel>
                                                                <Select
                                                                    value={this.state.assignedPEC}
                                                                    options={this.state.assignedPECOptions}
                                                                    onChange={this.setAssignedPEC}
                                                                    clearable={false}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <ControlLabel>Validation Expiration:</ControlLabel>
                                                                <FormControl type="text" value={this.formatDate(this.state.practiceValidationInfo.expirationDate)} disabled placeholder="N/A" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                         <Row>
                                            <Col sm={12}>
                                                <Form>
                                                    <h4 style={{ marginBottom: '1.6rem' }}>Address Information</h4>
                                                    <hr style={{ marginTop: '0rem' }} />
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Address 1:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.originalAddress1} disabled placeholder="Address 1" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Corrected Address 1:</ControlLabel>
                                                                <FormControl type="text" value={this.state.correctedAddress1} placeholder="Address 1" autoComplete="text" onChange={this.handleParamChange('address1')} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Address 2:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.originalAddress2} disabled placeholder="Address 2" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Corrected Address 2:</ControlLabel>
                                                                <FormControl type="text" value={this.state.correctedAddress2} placeholder="Address 2" autoComplete="text" onChange={this.handleParamChange('address2')} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>City / State / Zip:</ControlLabel><br/>
                                                                <FormControl style={{ width: '55%', display: 'inline' }} disabled type="text" value={this.state.practiceValidationInfo.originalCity} placeholder="City" />
                                                                <FormControl style={{ width: '20%', display: 'inline' }} disabled type="text" value={this.state.practiceValidationInfo.originalState} placeholder="State" />
                                                                <FormControl style={{ width: '25%', display: 'inline' }} disabled type="text" value={this.state.practiceValidationInfo.originalZip} placeholder="Zip" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Corrected City / State / Zip:</ControlLabel><br/>
                                                                <FormControl style={{ width: '55%', display: 'inline' }} type="text" value={this.state.correctedCity} placeholder="City" autoComplete="text" onChange={this.handleParamChange('city')} />
                                                                <FormControl style={{ width: '20%', display: 'inline' }} type="text" maxLength={2} value={this.state.correctedState} placeholder="State" autoComplete="text" onChange={this.handleParamChange('state')} />
                                                                <FormControl style={{ width: '25%', display: 'inline' }} type="text" value={this.state.correctedZipCode} placeholder="Zip" autoComplete="text" onChange={this.handleParamChange('zipCode')} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Phone:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.originalPhone} disabled />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Corrected Phone:</ControlLabel>
                                                                <FormControl type="text" value={this.state.correctedPhone} placeholder="Corrected Phone" autoComplete="text" maxLength={25} onChange={this.handleParamChange('phone')} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Fax Number:</ControlLabel>
                                                                <FormControl type="text" value={this.state.practiceValidationInfo.originalFax} disabled />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Corrected Fax Number:</ControlLabel>
                                                                <FormControl type="text" value={this.state.correctedFaxNumber} placeholder="Corrected Fax Number" autoComplete="text" maxLength={25} onChange={this.handleParamChange('faxNumber')} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Email:</ControlLabel>
                                                                <FormControl type="text" value={this.state.email} placeholder="Email" maxLength={256} onChange={this.handleParamChange('email')} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6}>
                                        <Row style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                                            <Col sm={12}>
                                                <Row style={{ marginBottom: '1rem' }}>
                                                    <h4 style={{ marginTop: '0.6rem', marginBottom: '1.6rem' }}>Notes</h4>
                                                    <FormGroup>
                                                        <FormControl componentClass="textarea" placeholder="Notes" value={this.state.practiceValidationInfo.notes} maxLength={512} onChange={this.handleParamChange('notes')} style={{'minHeight': '170px', 'resize': 'none'}} />
                                                    </FormGroup>
                                                </Row>
                                                <Row style={{ marginBottom: '1.6rem' }}>
                                                    <div style={{ display: this.state.loadingResults ? 'inline-block': 'none', marginRight: '10px' }} >
                                                        Loading
                                                        <Glyphicon className="spinning" glyph="cog" style={{ fontSize: '22px', lineHeight: '14px', color: '#6c716c',
                                                            marginLeft: '0.7rem', verticalAlign: 'text-top' }} />
                                                    </div>
                                                    <h4 style={{ marginBottom: '1.6rem' }}>Validation Files</h4>
                                                    <BootstrapTable data={this.state.fileData} selectRow={selectRowInProgress} maxHeight='315px' tableHeaderClass="tableHeader" tableBodyClass="tableBody table-body-wrap-text" hover>
                                                        <TableHeaderColumn width="12%" dataField="fileName" dataFormat={this.formatPdfLink} isKey={true}>File Name</TableHeaderColumn>
                                                        <TableHeaderColumn width="25%" dataField="fileType" dataFormat={this.formatFileType}>File Type</TableHeaderColumn>
                                                        <TableHeaderColumn width="34%" dataField="lastFaxStatus" dataFormat={this.getFaxStatusDescription}>Last Fax Status</TableHeaderColumn>
                                                        <TableHeaderColumn width="20%" dataField="updatedDatetime" dataFormat={this.formatTimestamp}>Last Activity</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </Row>
                                                <Row>
                                                    <input ref="fileUpload" type="file" onChange={this.handleUpload} name="Upload" style={{ marginTop: '1rem' }}/>
                                                </Row>
                                                <Row>
                                                    <div style={{ marginTop: '2rem', textAlign: 'center' }}>
                                                    {
                                                        this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0 &&
                                                        this.state.validationErrorMessages.map(error => {
                                                           return <h4 key={error.id} className="errorMessage">{error.errorText}</h4>
                                                        })
                                                    }
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ float: 'left', 'marginRight': '1rem' }} bsStyle="success" onClick={this.savePractice}>Save</Button>
                            <Button style={{ float: 'left' }} onClick={this.createValidationSheetBundle}>Create New Validation Sheet</Button>
                            <Button style={{ float: 'left' }} onClick={this.handleFaxLastValidationSheet} disabled={!this.props.faxEnabled}>Fax Last Validation Sheet</Button>
                            <Button onClick={this.handleModalToggle}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
                <AlertDialog visible={this.state.showFaxConfirmationModal} handleModalToggle={this.handleShowFaxConfirmationModal} title={"Fax Confirmation"}
                    message={faxConfirmationBody} handleConfirm={this.createFaxRequest} cancelLabel="Close" confirmLabel="Send Fax" confirmStyle="success" renderInnerDiv={true} />
            </div>
        );
    }
}

export default PracticeInfoEditModal;
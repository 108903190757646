import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Panel, Table } from 'react-bootstrap';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { Link } from 'react-router';
import MDRankingNeeded from './MDRankingNeeded.jsx';
import AlternateChase from './AlternateChase.jsx';
import SubmitModal from './SubmitModal.jsx';
import RankingContainer from './RankingContainer.jsx';

//todo - refactor
class UserConfirmedRanking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enrolleeStatusID: 1,
            userRankedRows: [],
            systemRankedRows: [],
            showModal: false,
            isButtonDisabled: false
        }
    }

    componentWillMount() {
        this.getRADVAuditDetails();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.radvEnrollee && nextProps.radvEnrollee.radvAuditEnrolleeStatusID !== this.state.enrolleeStatusID) {
            this.setState({ enrolleeStatusID: nextProps.radvEnrollee.radvAuditEnrolleeStatusID });
        }
    }

    getRADVAuditDetails = () => {
        if (this.props.enrolleeID) {
            axios.get(`/RADVAuditEnrollees/${this.props.enrolleeID}/DiagRADVDetails`)
            .then((response) => {
                this.setState({
                    systemRankedRows: response.data.filter(x => x.rank === null).sort((a, b) => {
                        return a.systemRank - b.systemRank;
                    }),
                    userRankedRows: response.data.filter(x => x.rank).sort((a, b) => {
                        return a.rank - b.rank;
                    })
                });
            })
            .catch((error) => {
                this.props.toast('error', 'Failed to load RADV diagnosis details.');
            });
        }
    }

    //pass showModal:false value as prop to SubmitModal
    cancelModal = () => {
        this.setState({ showModal: false });
    }

    redirectToRADV = () => {
        this.props.router.push("RADV");
    }

    // set checkbox value and pass showModal:true value and selected row as props to SubmitModal 
    handleSubmitCheckbox = (row, checked) => {
        this.setState({
            checked: checked,
            showModal: true,
            currentRow: row
        });
    }

    // add coversheet value to row and set checked and submitted attributes to true
    handleSubmitButton = (coversheetValue) => {
        this.setState({ showModal: false });
        const rows = [...this.state.userRankedRows.concat([...this.state.systemRankedRows])];

        const target = rows.find(x => x.medicalRecordDiagRADVDetailID === this.state.currentRow.medicalRecordDiagRADVDetailID);

        //on submit, check if coversheet already exists and set to lowest available rank
        const coversheetRowTarget = rows.find(x => x.coversheetID === parseInt(coversheetValue, 10));
        if (coversheetRowTarget) {
            target.rank < coversheetRowTarget.rank ?
                coversheetRowTarget.rank = target.rank : target.rank = coversheetRowTarget.rank;
        }

        target.coversheetID = parseInt(coversheetValue, 10);
        target.submittedToCMS = true;
        target.checked = true;

        this.setState({
            systemRankedRows: rows.filter(x => x.rank === null),
            userRankedRows: rows.filter(x => x.rank)
        });
    }

    // remove coversheet value from row and set checked and submitted attributes to false
    handleUnsubmitButton = () => {
        this.setState({ showModal: false });
        const rows = [...this.state.userRankedRows.concat([...this.state.systemRankedRows])];

        const target = rows.find(x => x.medicalRecordDiagRADVDetailID === this.state.currentRow.medicalRecordDiagRADVDetailID);
        target.coversheetID = null;
        target.submittedToCMS = false;
        target.checked = false;

        this.setState({
            systemRankedRows: rows.filter(x => x.rank === null),
            userRankedRows: rows.filter(x => x.rank)
        });
    }
    
    saveRankings = () => {
        this.setState({ isButtonDisabled: true });
        if (this.state.userRankedRows.length + this.state.systemRankedRows.userRankedRows === 0) {
            this.redirectToRADV();
        }

        axios.post(`/RADVAuditEnrollee/${this.props.enrolleeID}/DiagRADVDetails`, this.state.userRankedRows.concat([...this.state.systemRankedRows]))
        .then((response) => {
            this.redirectToRADV();
        })
        .catch((error) => {
            this.setState({ isButtonDisabled: false });
            this.props.toast('error', 'Failed to save RADV diagnosis details.');
        });
    }

    loadState = (id, cards) => {
        if (id === 1) {
            this.setState({ userRankedRows: cards });
        }
        else if (id === 2) {
            this.setState({ systemRankedRows: cards });
        }
    }

    render() {
        return (
            <div className="UserConfirmedRanking">
                <Col md={3}>
                {
                    this.props.radvEnrollee &&
                    <Panel>
                        <MDRankingNeeded enrolleeInfo={this.props.radvEnrollee} toast={this.props.toast} />
                        <AlternateChase enrolleeInfo={this.props.radvEnrollee} toast={this.props.toast} />
                    </Panel>
                }
                </Col>
                <Col xs={12} md={12}>
                    <br/>
                    <Table bordered className="rankingTable">  
                        <thead>
                            <tr>
                                <th colSpan="13" className="text-center" style={{ background: "#efefef" }}>
                                    <Button className="btn-sm btn-default" style={{ float: "left", marginRight: '-50px', marginLeft: '10px' }} onClick={this.getRADVAuditDetails}
                                        disabled={this.state.enrolleeStatusID === 2 || this.state.enrolleeStatusID === 3 || this.state.isButtonDisabled}>
                                        <Glyphicon glyph="refresh" /> Reset
                                    </Button>
                                    <span style={{ marginLeft: '150px' }}>User Confirmed Evidence</span>
                                    <Button className="btn-sm btn-danger" style={{ float: "right", marginBottom: "3px" }}>
                                        <Link to="/RADV/"  style={{ textDecoration: 'none', color: "white" }}>Cancel and Return</Link>
                                    </Button>
                                    <Button className="btn-sm btn-success" style={{ float: "right", marginRight:"6px", marginBottom: "3px" }} onClick={this.saveRankings}
                                        disabled={this.state.enrolleeStatusID === 2 || this.state.enrolleeStatusID === 3 || this.state.isButtonDisabled}>
                                        <Glyphicon glyph="floppy-disk" /> Save and Return
                                    </Button>
                                </th>
                            </tr>
                            <tr style={{ background: 'rgb(239, 239, 239)' }}>
                                <th>Rank</th>
                                <th>Coversheet ID</th>
                                <th>Score</th>
                                <th>Targeted HCC</th>
                                <th>Non-Related HCC</th>
                                <th>Provider Type</th>
                                <th>DOS</th>
                                <th>DOS Thru</th>
                                <th>Attestation</th>
                                <th>Coding Details</th>
                                <th>Evidence File</th>
                                <th>Updated On</th>
                                <th>Submitted to CMS</th>
                            </tr>
                        </thead>
                            <RankingContainer id={1} list={this.state.userRankedRows} handleSubmitCheckbox={this.handleSubmitCheckbox}
                                loadState={this.loadState} enrolleeStatusID={this.state.enrolleeStatusID} toast={this.props.toast} />
                            <tbody>
                                <tr>
                                    <th colSpan="13" className="text-center" style={{background: "#efefef"}}>System Sorted Evidence</th>
                                </tr>
                            </tbody>
                            <RankingContainer id={2} list={this.state.systemRankedRows} handleSubmitCheckbox={this.handleSubmitCheckbox}
                                loadState={this.loadState} enrolleeStatusID={this.state.enrolleeStatusID} toast={this.props.toast} />
                    </Table>
                </Col>
               <SubmitModal inputRef={(input) => this.textInput = input} checked={this.state.checked} handleSubmitButton={this.handleSubmitButton}
                    handleUnsubmitButton={this.handleUnsubmitButton} currentRow={this.state.currentRow} showModal={this.state.showModal} 
                    cancelModal={this.cancelModal} />
            </div>
        );
    }
};

export default DragDropContext(HTML5Backend)(UserConfirmedRanking);
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class AdditionalOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFinalizedModal: false, 
            showGatheringModal: false,
            showCompleteModal: false,
            showFinalizedFromCompleteModal: false,
            gatheringChecked: false,
            finalizedChecked: false,
            completeChecked: false,
            lastChecked: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.radvAuditEnrolleeStatusID !== this.props.radvAuditEnrolleeStatusID) {
            if (nextProps.radvAuditEnrolleeStatusID === 1) {
                this.setGathering();
            }
            else if (nextProps.radvAuditEnrolleeStatusID === 2) {
                this.setFinalized();
            }
            else if (nextProps.radvAuditEnrolleeStatusID === 3) {
                this.setComplete();
            }
        }
    }

    setGathering = () => {
        this.setState({
            gatheringChecked: true,
            finalizedChecked: false,
            completeChecked: false
        });
    }

    setFinalized = () => {
        this.setState({
            gatheringChecked: false,
            finalizedChecked: true,
            completeChecked: false
        });
    }

    setComplete = () => {
        this.setState({
            gatheringChecked: false,
            finalizedChecked: false,
            completeChecked: true
        });
    }

    setStatusViaLastChecked = () => {
        if (this.state.lastChecked === "gatheringChecked") {
            this.setGathering();
        }
        else if (this.state.lastChecked === "finalizedChecked") {
            this.setFinalized();
        }
        else if (this.state.lastChecked === "completeChecked") {
            this.setComplete();
        }
    }

    closeGatheringModal = () => {
        this.setState({ showGatheringModal: false });
        this.setStatusViaLastChecked();
    }

    closeFinalizedModal = () => {
        this.setState({ showFinalizedModal: false });
        this.setStatusViaLastChecked();
    }

    closeCompleteModal = () => {
        this.setState({ showCompleteModal: false });
        this.setStatusViaLastChecked();
    }

    closeFinalizedFromCompleteModal = () => {
        this.setState({ showFinalizedFromCompleteModal: false });
        this.setStatusViaLastChecked();
    }

    setLastChecked = () => {
        if (this.state.gatheringChecked) {
            this.setState({ lastChecked: "gatheringChecked" });
        }
        else if (this.state.finalizedChecked) {
            this.setState({ lastChecked: "finalizedChecked" });
        }
        else if (this.state.completeChecked) {
            this.setState({ lastChecked: "completeChecked" });
        }
    }

    gatheringEvidenceSelected = () => {
        if (!this.state.gatheringChecked) {
            this.setLastChecked();
            this.setState({
                showGatheringModal: true,
                gatheringChecked: true,
                finalizedChecked: false,
                completeChecked: false
            });
        }
    }

    finalizedInCDATSelected = () => {
        if (!this.state.finalizedChecked) {
            this.setLastChecked();
            if (!this.state.completeChecked) {
                this.setState({
                    showFinalizedModal: true,
                    gatheringChecked: false,
                    finalizedChecked: true,
                    completeChecked: false
                });
            }
            else {
                this.setState({
                    showFinalizedFromCompleteModal: true,
                    gatheringChecked: false,
                    finalizedChecked: true,
                    completeChecked: false
                });
            }
        }
    }

    completeSelected = () => {
        if (!this.state.completeChecked) {
            this.setLastChecked();
            this.setState({
                showCompleteModal: true,
                gatheringChecked: false,
                finalizedChecked: false,
                completeChecked: true
            });
        }
    }

    handleModalYesClick = (param) => {
        if (param === "handleGatheringYesClick") {
            this.setState({ showGatheringModal: false });
            this.props.updateEnrolleeStatus(1);
        }
        else if (param === "handleFinalizedYesClick") {
            this.setState({ showFinalizedModal: false });
            this.props.updateEnrolleeStatus(2);
        }
        else if (param === "handleCompleteYesClick") {
            this.setState({ showCompleteModal: false });
            this.props.updateEnrolleeStatus(3);
        }
        else if (param === "handleFinalizedFromCompleteYesClick") {
            this.setState({ showFinalizedFromCompleteModal: false });
            this.props.updateEnrolleeStatus(2);
        }
    }

    render() {
        return (
            <div>
                <div style={{ float: 'left', marginLeft: '10px', marginTop: '20px' }}>
                    <table className="additionalOptions">
                        <tbody className="additionalOptions">
                            <tr className="additionalOptions">
                                <td className="additionalOptions">
                                    <label style={{ fontSize: '0.9em' }}>
                                        <input type="radio" onChange={this.gatheringEvidenceSelected} name="quality[25]" value="gatheringEvidence"
                                            disabled={this.state.completeChecked} checked={ this.state.gatheringChecked } /> Gathering Evidence
                                    </label> 
                                </td>
                            </tr>
                            <tr className="additionalOptions">
                                <td className="additionalOptions">
                                    <label style={{ fontSize: '0.9em' }}>
                                        <input type="radio" onChange={this.finalizedInCDATSelected} name="quality[25]" value="finalized"
                                            checked={this.state.finalizedChecked} /> Finalized
                                    </label>
                                </td>
                            </tr>
                            <tr className="additionalOptions">
                                <td className="additionalOptions"> 
                                    <label style={{ fontSize: '0.9em' }}>
                                        <input id="complete" type="radio" onChange={this.completeSelected} name="quality[25]" value="complete"
                                            disabled={!this.state.finalizedChecked} checked={this.state.completeChecked} /> Complete
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
                <Modal bsSize="small" show={this.state.showGatheringModal} onHide={this.closeGatheringModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>WARNING</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Once the Enrollee Status is set to Gathering Evidence, the following <br/>functionality will be disabled on the Submission Screen:</p>
                        <ul>
                            <li>Ability to set Submitted pieces of evidence as Finalized, CMS Accepted, or CMS Denied</li>
                        </ul>
                        <p>Are you sure you want to proceed?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleModalYesClick("handleGatheringYesClick")}>Yes</Button>
                        <Button onClick={this.closeGatheringModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
                <Modal bsSize="small" show={this.state.showFinalizedModal} onHide={this.closeFinalizedModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>WARNING</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Once the Enrollee Status is set to Finalized, the following <br/>functionality will be disabled on the Ranking Screen:</p>
                        <ul>
                            <li>Ability to mark additional pieces of evidence as submitted</li>
                            <li>Ability to edit the ranking order</li>
                        </ul>
                        <p>Are you sure you want to proceed?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleModalYesClick("handleFinalizedYesClick")}>Yes</Button>
                        <Button onClick={this.closeFinalizedModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
                <Modal bsSize="small" show={this.state.showCompleteModal} onHide={this.closeCompleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>WARNING</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Once the Enrollee Status is set to Complete, the following <br/>functionality will be disabled on the Submission Screen:</p>
                        <ul>
                            <li>Ability to set Submitted pieces of evidence as Finalized, CMS Accepted, or CMS Denied</li>
                        </ul>
                        <p>Are you sure you want to proceed?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleModalYesClick("handleCompleteYesClick")}>Yes</Button>
                        <Button onClick={this.closeCompleteModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
                <Modal bsSize="small" show={this.state.showFinalizedFromCompleteModal} onHide={this.closeFinalizedFromCompleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>WARNING</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Once the Enrollee Status is set to Finalized, Evidence Submission <br/>status will become editable.</p>
                        <p>Are you sure you want to proceed?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleModalYesClick("handleFinalizedFromCompleteYesClick")}>Yes</Button>
                        <Button onClick={this.closeFinalizedFromCompleteModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AdditionalOptions;
import React from 'react';
import { Glyphicon } from 'react-bootstrap';

const handleLogout = (props) => {
    localStorage.removeItem('token');
    props.router.push('/Unauthorized?logout=true');
}

const NavUserInfo = (props) => {
    return (
        <div className="nav-userinfo">
            <p>
                {
                    props.currentUser &&
                    <Glyphicon glyph="user" style={{ marginRight: '0.5em' }} />
                }
                <b>{props.currentUser ? props.currentUser.fullName : ''}</b>
                <Glyphicon glyph="log-out" onClick={() => handleLogout(props)}
                    style={{ cursor: 'pointer', marginLeft: '1em' }} />
            </p>
        </div>
    );
}

export default NavUserInfo;
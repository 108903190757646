import React from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import FaxStatusSearch from './FaxStatusSearch'

class FaxStatus extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            faxData: [],
            faxDataCount: 0,
            page: 1,
            pageSize: 10,
            sortBy: '',
            sortByDesc: null,
            faxStatusOptions: [],
            searchParams: {}
        }
    }

    componentWillMount() {
        this.getFaxStatusOptions();
    }

    getFaxData = (searchParams, page, pageSize, sortBy, sortByDesc) => {
        // cache search params in state for use if page, page number, etc. change
        this.setState({
            searchParams: {...searchParams}
        });

        // Standardize paging parameters
        page = page ? page : 1;

        // Execute the search call
        axios.post('/faxrequests/search', {
                providerOrgID: searchParams.providerOrgID !== '' ? searchParams.providerOrgID : null,
                practiceID: searchParams.practiceID !== '' ? searchParams.practiceID : null,
                faxStatusID: searchParams.faxStatusID !== '' ? searchParams.faxStatusID : null,
                initiatedDate: searchParams.initiatedDate,
                initiatingUserID: searchParams.initiatingUserID !== '' ? searchParams.initiatingUserID : null,
                pageNumber: page,
                pageSize: pageSize ? pageSize : this.state.pageSize,
                sortBy: sortBy ? sortBy : this.state.sortBy,
                sortByDesc: (sortByDesc !== null) ? sortByDesc : this.state.sortByDesc
        })
        .then(response => {
            this.setState({ faxData: response.data.items, faxDataCount: response.data.totalItems, page: page });
        })
        .catch((error) => {
            this.props.toast('error', (error.response && error.response.data) ? error.response.data.message : 'Could not load fax data.', 'Error');
        });
    }

    getFaxStatusOptions = () => {
        axios.get('/faxrequests/faxstatuses')
        .then(response => {
            const faxStatusOptions = response.data.map(item => {
                return {
                    value: item.faxStatusID,
                    label: item.faxStatusDesc
                }
            });
            this.setState({faxStatusOptions: faxStatusOptions});
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load fax status data.', 'Error');
        });
    }

    formatDocumentType = (cell, row) => {
        if(row.documentType === 1) {
            return 'Practice Validation';
        } else if(row.documentType === 2) {
            return 'Alert Package';
        } else {
            return '';
        }
    }

    formatFaxDate = (cell, row) => {
        return this.formatDateString(row.initiatedDate);
    }

    formatDateString = (dateString) => {
        if (!dateString) {
            return 'N/A';
        }
        const date = new Date(dateString);
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }

    formatFaxStatus = (cell, row) => {
        const status = this.state.faxStatusOptions.find(status => status.value === row.faxStatusID);

        return status ? status.label : '';
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return { value: item, text: item.toString() } }),
            sizePerPage: this.state.pageSize,                        
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                })
                this.getFaxData(this.state.searchParams, page, sizePerPage, this.state.sortBy, this.state.sortByDesc)
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortOrder === "desc"
                });
                this.getFaxData(this.state.searchParams, this.state.page, this.state.pageSize, sortName, sortOrder === "desc");
            },
            onSizePerPageList: (sizePerPage) => {
                this.setState({
                    page: 1,
                    pageSize: sizePerPage
                });
                this.getFaxData(this.state.searchParams, 1, sizePerPage, this.state.sortBy, this.state.sortByDesc);
            }
        }

        return (
            <div style={{marginTop: '2rem'}}>
                <FaxStatusSearch
                    router={this.props.router}
                    providers={this.props.providers}
                    practices={this.props.practices}
                    search={this.getFaxData}
                    toast={this.props.toast}
                    users={this.props.users}
                    faxStatusOptions={this.state.faxStatusOptions} />
                <hr/>
                <div>
                    <BootstrapTable data={this.state.faxData} options={options} fetchInfo={{ dataTotalSize: this.state.faxDataCount}}
                        maxHeight="400px" hover pagination remote>
                        <TableHeaderColumn dataField="faxRequestID" dataSort isKey>Fax Request ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="documentType" dataFormat={this.formatDocumentType} dataSort>Document Type</TableHeaderColumn>
                        <TableHeaderColumn dataField="faxNumber" dataSort>Fax Number</TableHeaderColumn>
                        <TableHeaderColumn dataField="faxToName" dataSort>Fax To</TableHeaderColumn>
                        <TableHeaderColumn dataField="faxStatusID" dataFormat={this.formatFaxStatus} dataSort>Fax Status</TableHeaderColumn>
                        <TableHeaderColumn dataField="initiatingUserFullName" dataSort>Initiating PEC</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceName" dataSort>Practice Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerOrgName" dataSort>Provider Organization</TableHeaderColumn>
                        <TableHeaderColumn dataField="initiatedDate" dataFormat={this.formatFaxDate} dataSort>Fax Date</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}


export default FaxStatus;
//todo: replace these functions with date-fns (lib)
// exports DateUtils
// usage: import { getLocalTimeAsESTISODateString } from '../../utils/DateUtils';

import moment from "moment";

//todo: replace this / move date calculations to the service.
//returns a react-16-bootstrap-date-picker selection as an ISO string with the time set to midnight
function getDatePickerValueAsMidnightISOString(date) {
    return new Date((new Date(date)).setHours(0,0,0,0)).toISOString();
}

// note: broken, do not use
// gets parameter time, converts to EST
function convertToESTHelper(date) {
    //convert parameter date to utc
    const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    
    //convert utc -> est
    return new Date(utc + (3600000 * -5.0));
}

// note: broken, do not use
// build ISO string representation (date-only); used with react-bootstrap-datepicker
function getTimeAsESTISODateString(date) {
    //convert utc -> est
    const est = convertToESTHelper(date);

    //build iso date string from est time
    const month = est.getMonth() + 1;
    const day = est.getDate();
    return `${est.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T12:00:00.000Z`;
}

// note: broken, do not use
// build ISO string representation (date and time)
function getTimeAsESTISODateTimeString(date) {
    //convert utc -> est
    const est = convertToESTHelper(date);

    //build iso datetime string from est time
    const month = est.getMonth() + 1;
    const day = est.getDate();
    const hours = est.getHours() < 10 ? '0' + est.getHours() : est.getHours();
    const minutes = est.getMinutes() < 10 ? '0' + est.getMinutes() : est.getMinutes();
    return `${est.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T${hours}:${minutes}:00.000Z`;
}

// returns the date substring of the ISO string representation of the provided Date instance
function formatISODateOnly(date) {
    return date.toISOString().split('T')[0];
}

// todo: confirm that this works + fix the poor style, or replace via a date lib
// get difference in days between two ISO strings
function getDifference(startDate, endDate) {
    try {
        var oneDay = 86400000; // 24 * 60 * 60 * 1000 hours*minutes*seconds*milliseconds
        var diffDays = Math.round((new Date(endDate).getTime() - new Date(startDate).getTime()) / (oneDay));
    } catch (err) {
    }
    return diffDays;
}

//formats datetime string into MM/DD/YYYY 
function formatDatetimeString(timeString) {
    
    if (!timeString || isNaN(Date.parse(timeString)) ) {
        return '';
    }
    return new Date(timeString).toLocaleDateString('en-US');
}

function formatDatetimeStringWithPrefixZero(timeString) {
    moment.locale('es');

    if (!timeString || isNaN(Date.parse(timeString)) ) {
        return '';
    }

    var d = moment(timeString).utc();
    return moment(d).format("MM/DD/YYYY");

}


export { getDatePickerValueAsMidnightISOString, getTimeAsESTISODateString, getTimeAsESTISODateTimeString, formatISODateOnly, getDifference, formatDatetimeString, formatDatetimeStringWithPrefixZero };
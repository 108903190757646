import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';

const WorkflowNav = ({ home, prev, next, prevLabel, nextLabel, showPrev, showNext }) =>
    <div style={{marginTop: '0.8rem'}}>
        <Button onClick={home} style={{float: 'left'}}>
            <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Package Search
        </Button>
        { showPrev &&
            <Button onClick={prev} style={{float: 'left', marginLeft: '1rem'}}>
                <Glyphicon glyph="arrow-left" style={{marginRight: '0.2rem'}} /> {prevLabel}
            </Button>
        }
        { showNext &&
            <Button onClick={next} style={{float: 'right'}}>
                {nextLabel} <Glyphicon glyph="arrow-right" style={{marginLeft: '0.2rem'}} />
            </Button>
        }
    </div>

export default WorkflowNav;
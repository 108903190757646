import React from 'react';
import { Well, Glyphicon, Panel, Button, Tabs, Tab, DropdownButton, MenuItem } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';

class HCCProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resultsByVersion: [],
            reviewProcessingUIConfig: null,
            loaded: false,
            hccProfileError: false,
            expanded: true,
            selectedTab: 0,
            defaultHCCVersion: []
        };
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");
    }

    componentWillMount() {
        this.getProfileData();
    }

    openPopoutWindow = (evt) => {
        evt.stopPropagation();
        this.props.openNewWindow(this.state.selectedTab);
    }

    getProfileData = () => {
        Promise.all([
            axios.get(`/memberService/v1/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/diagnosisProfile/MemberHCCDataByVersion`),
            axios.get(`/member/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/diagtargetbyhccversion`),
            axios.get(`/mrrService/v1/tepReference/defaultHCCVersion`)
        ])
            .then((response) => {
                this.setState({ defaultHCCVersion: response[2].data});

                let hccResults = {};
                response[0].data && Object.keys(response[0].data).forEach((key, index) => {
                    hccResults[key] = (response[0].data[key]).map(x => {
                        return { ...x, targeted: false, hccKey: x.hccValue };
                    });
                });

                let targetedResults = {};
                // add "targeted" property to targeted hccs
                if (response[1].data) {
                    Object.keys(response[1].data).forEach((key, index) => {
                        targetedResults[key] = (response[1].data[key]).map(x => {
                            return { ...x, targeted: true, hccKey: 't' + x.hccValue };
                        });
                    });
                }

                //hccResults was previously called "hccResultsByVersion"; targetedResults was previously called "diagTargetByHCCVersion"
                let results = {};
                if (Object.keys(hccResults).length && Object.keys(targetedResults).length) {
                    Object.keys(hccResults).forEach((key1, index1) => {
                        Object.keys(targetedResults).forEach((key2, index2) => {
                            if (key1 === key2) {
                                results[key1] = ([...hccResults[key1]]).concat([...targetedResults[key2]]);
                            }
                            else if (!results[key1]) {
                                results[key1] = [...hccResults[key1]]
                            }
                            if (!results[key2]) {
                                results[key2] = [...targetedResults[key2]]
                            }
                        })
                    })
                }
                else if (!Object.keys(hccResults).length) {
                    results = { ...targetedResults };
                }
                else {
                    results = { ...hccResults };
                }
                results = this.showMAVersions(results);

                this.setState({
                    resultsByVersion: results,
                    loaded: true,
                    hccProfileError: false,
                    selectedTab: this.props.location && this.props.location.query && this.props.location.query.HCCVersionNo ?
                        parseInt(this.props.location.query.HCCVersionNo) : this.getDefaultTab(results)
                });
            }).catch((error) => {
                this.setState({
                    loaded: true,
                    hccProfileError: true
                });
            })

    }

    showMAVersions = (results) => {
        if(this.lineOfBusiness === "MA"){
            let filteredResults = [];
            if(this.state.defaultHCCVersion.hccVersions && this.state.defaultHCCVersion.hccVersions.length > 0){
                this.state.defaultHCCVersion.hccVersions.forEach((key1, index1) => {
                    Object.keys(results).forEach((key2, index2) => {
                        if(key1 === key2){
                            filteredResults[key2] = results[key2];
                        }
                    })
                });
            }
            return this.hccVersionSort(filteredResults);
        }
        else {
            return results;
        }
    }

    getDefaultTab = (results) => {
        if (this.state.defaultHCCVersion) {
            let hccVersion = this.state.defaultHCCVersion.hccVersion;
            for (let i = 0; i < hccVersion.length; i++) {
                if (Object.keys(results).some(key1 => key1 === hccVersion[i])) {
                    return Object.keys(results).indexOf(hccVersion[i]);
                }
            }
        }
        return 0;
    }


    expandPanel = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    // assign appropriate className based on year value for HCC profile icons
    selectBackgroundClass = (year) => {
        switch (year) {
            case 'raps':
                return "rapsBackground";
            case 'claims':
                return "claimsBackground";
            case 'rapsPaid':
                return "rapsPaidBackground";
            case 'edps':
                return "edpsBackground";
            case 'rapsEdps':
                return "rapsEdpsBackground";
            case 'edge':
                return "edgeBackground";
            default:
                return "";
        }
    }

    formatTargeted = (cell, row) => {
        if (cell === true) {
            return <Glyphicon glyph="glyphicon glyphicon-record" />
        }
    }

    formatRowClassName = (row, rowIdx) => {
        return row.targeted ? 'targeted' : 'non-targeted';
    }

    isExpandableRow = (row) => {
        return row && row.diagnoses;
    }

    // display diag rows on expanded hcc profile row
    expandRow = (row) => {
        return (
            <Well className="hccProfileInsetWell" style={{ margin: 0 }} onClick={() => this.closeExpand(row.key)}>
                <BootstrapTable data={row.diagnoses} className="hccProfileInset" style={{ margin: 0 }}>
                    <TableHeaderColumn dataField="diagnosisCode" isKey dataAlign="left" width="25%">DX Code</TableHeaderColumn>
                    <TableHeaderColumn dataField="description" dataAlign="left" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Description</TableHeaderColumn>
                </BootstrapTable>
            </Well>
        )
    }

    //use react-bootstrap-table internals to simulate the onExpand func fired when the row is clicked, allowing us to extend the click area
    closeExpand = (rowKey) => {
        const expanding = this.refs.hccProfileTable.state.expanding.filter(x => x !== rowKey);
        this.refs.hccProfileTable.handleExpandRow(expanding, rowKey, true);
    }

    hccSort = (a, b, order, sortField) => {
        //place empty values at the bottom of the sort
        a = a[sortField] ? a[sortField] : Number.MAX_SAFE_INTEGER;
        b = b[sortField] ? b[sortField] : Number.MAX_SAFE_INTEGER;

        if (order === 'asc') {
            return a - b;
        }
        else {
            return b - a;
        }
    }

    hccVersionSort = (result)=>{
        const sortedKeys = Object.keys(result).sort().reverse();
        const sortedObject ={};
        sortedKeys.forEach((key)=>{
            sortedObject[key] =result[key];
        });
        return sortedObject;
    }

    formatClass = (cell, row, year) => {
        if (row.statusByYear && row.statusByYear[year]) {
            return this.selectBackgroundClass(row.statusByYear[year].statusClassName);
        }
        else {
            return "";
        }
    }

    selectTab = (eventKey) => {
        this.setState({ selectedTab: eventKey });
    }

    mapYearToLabel = (year) => {
        const currentYear = new Date().getFullYear();
        switch (year) {
            case 'currentYearMinus2':
                return `${currentYear - 2}`;
            case 'currentYearMinus1':
                return `${currentYear - 1}`;
            case 'currentYearFirstHalf':
                return `${currentYear} Jan-Jun`;
            case 'currentYearSecondHalf':
                return `${currentYear} Jul-Dec`;
            default:
                break;
        }
        return "";
    }

    hccYearSort = (a, b, order, sortField) => {
        const yearLabel = this.mapYearToLabel(sortField);
        const a1 = a.statusByYear[yearLabel] && a.statusByYear[yearLabel].statusClassName ? a.statusByYear[yearLabel].statusClassName : '';
        const b1 = b.statusByYear[yearLabel] && b.statusByYear[yearLabel].statusClassName ? b.statusByYear[yearLabel].statusClassName : '';
        return order === 'asc' ? a1.localeCompare(b1) : b1.localeCompare(a1);
    }

    renderResults = () => {
        const options = {
            defaultSortName: 'hccValue',
            defaultSortOrder: 'asc',
            expandRowBgColor: '#fcfcfc'
        }
        const currentYear = new Date().getFullYear();
        return (
            <Tab.Content animation>
                {
                    Object.keys(this.state.resultsByVersion).length ?
                        Object.keys(this.state.resultsByVersion).map((key, index) => {
                            return this.state.resultsByVersion[key] ? (
                                <Tab.Pane key={index} eventKey={index}>
                                    <BootstrapTable ref="hccProfileTable" data={this.state.resultsByVersion[key]} options={options} className="profileTable hccProfileTable" style={{ margin: 0 }} trClassName={this.formatRowClassName}
                                        expandableRow={(row) => this.isExpandableRow(row)} expandComponent={this.expandRow}>
                                        <TableHeaderColumn dataField="hccKey" isKey hidden></TableHeaderColumn>
                                        <TableHeaderColumn width="9%" dataField="targeted" dataAlign="left" dataFormat={this.formatTargeted} dataSort></TableHeaderColumn>
                                        <TableHeaderColumn width="9%" dataField="hccValue" dataAlign="left" dataSort sortFunc={this.hccSort}>HCC</TableHeaderColumn>
                                        <TableHeaderColumn width="46%" dataField="hccDescription" dataAlign="left" dataSort tdStyle={{ whiteSpace: 'normal' }}>HCC Description</TableHeaderColumn>
                                        <TableHeaderColumn width="9%" dataField="currentYearMinus2" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear - 2}`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear - 2}</TableHeaderColumn>
                                        <TableHeaderColumn width="9%" dataField="currentYearMinus1" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear - 1}`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear - 1}</TableHeaderColumn>
                                        <TableHeaderColumn width="9%" dataField="currentYearFirstHalf" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear} Jan-Jun`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear} Jan-Jun</TableHeaderColumn>
                                        <TableHeaderColumn width="9%" dataField="currentYearSecondHalf" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear} Jul-Dec`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear} Jul-Dec</TableHeaderColumn>
                                    </BootstrapTable>
                                </Tab.Pane>
                            ) : null
                        })
                        : <Tab.Pane key={0} eventKey={0}>
                            <BootstrapTable ref="hccProfileTable" data={[]} options={options} className="profileTable hccProfileTable" style={{ margin: 0 }} trClassName={this.formatRowClassName}
                                expandableRow={(row) => this.isExpandableRow(row)} expandComponent={this.expandRow}>
                                <TableHeaderColumn dataField="hccKey" isKey hidden></TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField="targeted" dataAlign="left" dataFormat={this.formatTargeted} dataSort></TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField="hccValue" dataAlign="left" dataSort sortFunc={this.hccSort}>HCC</TableHeaderColumn>
                                <TableHeaderColumn width="46%" dataField="hccDescription" dataAlign="left" dataSort tdStyle={{ whiteSpace: 'normal' }}>HCC Description</TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField="currentYearMinus2" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear - 2}`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear - 2}</TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField="currentYearMinus1" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear - 1}`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear - 1}</TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField="currentYearFirstHalf" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear} Jan-Jun`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear} Jan-Jun</TableHeaderColumn>
                                <TableHeaderColumn width="9%" dataField="currentYearSecondHalf" dataFormat={this.formatYear} columnClassName={(cell, row) => this.formatClass(cell, row, `${currentYear} Jul-Dec`)} dataAlign="left" dataSort sortFunc={this.hccYearSort}>{currentYear} Jul-Dec</TableHeaderColumn>
                            </BootstrapTable>
                        </Tab.Pane>
                }
            </Tab.Content>
        )
    }

    renderEmptyTable = () => {
        const currentYear = new Date().getFullYear();
        return (
            <BootstrapTable data={[]} className="profileTable hccProfileTable"
                style={{ margin: 0 }} trClassName={this.formatRowClassName}>
                <TableHeaderColumn dataField="hccKey" isKey hidden></TableHeaderColumn>
                <TableHeaderColumn width="9%" dataField="targeted" dataAlign="left" dataFormat={this.formatTargeted} dataSort></TableHeaderColumn>
                <TableHeaderColumn width="9%" dataField="hccValue" dataAlign="left" dataSort sortFunc={this.hccSort}>HCC</TableHeaderColumn>
                <TableHeaderColumn width="46%" dataField="hccDescription" dataAlign="left" dataSort>HCC Description</TableHeaderColumn>
                <TableHeaderColumn width="9%" dataField="currentYearMinus2" dataAlign="left">{currentYear - 2}</TableHeaderColumn>
                <TableHeaderColumn width="9%" dataField="currentYearMinus1" dataAlign="left">{currentYear - 1}</TableHeaderColumn>
                <TableHeaderColumn width="9%" dataField="currentYearFirstHalf" dataAlign="left">{currentYear} Jan-Jun</TableHeaderColumn>
                <TableHeaderColumn width="9%" dataField="currentYearSecondHalf" dataAlign="left">{currentYear} Jul-Dec</TableHeaderColumn>
            </BootstrapTable>
        );
    }

    render() {
        const dropdownTitle = this.state.resultsByVersion && Object.keys(this.state.resultsByVersion)[this.state.selectedTab] ? (Object.keys(this.state.resultsByVersion)[this.state.selectedTab]).toUpperCase() : "";
        if (this.state.loaded === false && !this.state.resultsByVersion.length) {
            return (
                <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                    <Glyphicon className="spinning" glyph="cog" style={{ fontSize: "30px", lineHeight: "14px", color: "#6c716c" }} />
                </div>)
        }
        return (
            <Panel className={!this.props.isPopout ? "hccProfilePanel" : "hccProfilePanelWindow"}
                style={{ display: this.props.visible ? 'block' : 'none' }} expanded={this.state.expanded} onToggle={this.expandPanel}>
                <Panel.Heading>
                    <Panel.Title>
                        {
                            !this.props.isPopout &&
                            <span style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                                <Glyphicon glyph="new-window" onClick={this.openPopoutWindow} />
                            </span>
                        }
                        <span onClick={this.expandPanel} style={{ cursor: "pointer" }}>HCC Profile</span>
                        {
                            this.state.resultsByVersion && Object.keys(this.state.resultsByVersion).length > 0 &&
                            <DropdownButton bsStyle="default" bsSize="xsmall" title={dropdownTitle} id="hccVersionDropdown" style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
                                <MenuItem header>HCC Version</MenuItem>
                                {
                                    Object.keys(this.state.resultsByVersion).map((key, index) => {
                                        return this.state.resultsByVersion[key] ? (
                                            <MenuItem key={index} eventKey={index} onSelect={this.selectTab}>{key.toUpperCase()}</MenuItem>
                                        ) : null
                                    })
                                }
                            </DropdownButton>
                        }
                        {
                            !this.props.isPopout &&
                            <div style={{ float: "right" }}>
                                <span>
                                    <Button className="profileButton" bsSize="xsmall" onClick={this.props.toggleTab}>View Star Profile</Button>
                                </span>
                                <span onClick={this.expandPanel} style={{ cursor: "pointer" }}>
                                    <Glyphicon glyph={this.state.expanded ? "menu-down" : "menu-left"} style={{ paddingRight: "0.4rem", color: "#6c716c" }} />
                                </span>
                            </div>
                        }
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible={!this.props.isPopout}>
                    <Tabs defaultActiveKey={0} activeKey={this.state.selectedTab} onSelect={this.selectTab} id="hccResultsTabs">
                        {
                            !this.state.hccProfileError && this.props.visible && this.state.resultsByVersion &&
                            this.renderResults()
                        }
                        {
                            this.state.hccProfileError && this.renderEmptyTable()
                        }
                    </Tabs>
                </Panel.Body>
            </Panel>
        );
    }
}

export default HCCProfile;

import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Button, Col, ControlLabel, FormGroup, Modal, Row } from 'react-bootstrap';

export default class ProjectEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectList: [],
            loaded: false,
            targetProjectID: this.props.projectID,
            targetProjectName: ''
        };
    }

    componentWillMount() {
        this.props.handleProjectEditButton(true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.showProjectEditModal && !prevProps.showProjectEditModal) {
            this.getProjectDropdownOptions();
        }
        if (!this.props.showProjectEditModal && prevProps.showProjectEditModal) {
            this.setState({ projectList: [], loaded: false });
        }
    }

    getProjectDropdownOptions = () => {
        axios.get(`/medicalrecordreview/${this.props.medicalRecordReviewID}/reassignmentProjects`)
            .then((response) => {
                this.setState({
                    projectList: response.data.map((proj) => {
                        return {
                            label: proj.projectName,
                            value: proj.projectID
                        };
                    }),
                    loaded: true
                });
            })
    }

    handleProjectChange = (project) => {
        this.setState({
            targetProjectID: project? project.value: '',
            targetProjectName: project? project.label: ''
         });
    }

    handleUpdateProject = () => {
         axios.patch(`/medicalrecordreview/${this.props.medicalRecordReviewID}/project/${this.state.targetProjectID}`)
         .then((response) => {
            this.props.toast('success', 'Project updated successfully', '')
            this.props.handleProjectEditModalToggle();
            this.props.handleNavBarProject(this.state.targetProjectName, this.state.targetProjectID);
        })
        .catch((error) => {
            this.props.toast('error', 'Could not update Project', 'Error');
        })
    }

    render() {
        return (
         <Modal className="projectEditModal" show={this.props.showProjectEditModal} onHide={this.props.handleProjectEditModalToggle} keyboard>
         <Modal.Header closeButton>
            <Modal.Title>Re-assign Project</Modal.Title>
          </Modal.Header>
          <div>
               <Modal.Body style={{height: '150px'}}>
                        <Row>
                           <Col sm={7}>
                           <FormGroup>
                           <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Project</ControlLabel>
                           <Select className="select-projectDropDown" value={this.state.targetProjectID} options={this.state.projectList} placeholder='All Projects' 
                                isLoading={!this.state.loaded} onChange={this.handleProjectChange}/>
                           </FormGroup>
                           </Col>
                       </Row>
               </Modal.Body>
               <Modal.Footer>
               <FormGroup style={{ marginTop: '1rem', marginBottom: '0' }}>
                   <div style={{ float: 'left' }}>
                      <Button bsStyle="success" onClick={this.handleUpdateProject}>Update</Button>
                   </div>
                   <div style={{ float: 'right' }}>
                      <Button style={{marginLeft: '2rem'}} onClick={this.props.handleProjectEditModalToggle}>Cancel</Button>
                   </div>
               </FormGroup>
               </Modal.Footer>
            </div>
         </Modal>
      );
    }
}


import React from 'react';
// import { BootstrapTable, TableHeaderColumn, Table} from 'react-bootstrap-table';
import { Row, Grid } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Left } from 'react-bootstrap/lib/Media';
const tableStyle = {marginLeft: '150px', width: '82%'};
const tableHead = {backgroundColor: 'gainsboro'}

const ProjectDetailTable = (props) => {
    
    const formatProjectStatus = () => {
        const projectStatusID = props.project.projectStatusID;
        const matchingOption = props.projectStatusOptions.filter(x => x.value === projectStatusID)
        if(projectStatusID != 0)
        {
            return matchingOption.length > 0 ? matchingOption[0].label : '';
        }
        return props.campaignAutomationConfigs.campaignStatusName;
    }

    const formatProjectL1Count = () => {
        return props.projectStartCount.l1CoderCount;
    }

    const formatProjectL2Count = () => {
        return props.projectStartCount.l2CoderCount;
    }

    const formatCampaignRemainingL1Count = () => {
        return formatCampaignRemaining(props.campaignAutomationConfigs.l1CoderCount, props.projectTotals.l1CoderCount);
    }

    const formatCampaignRemainingL2Count = () => {
        return formatCampaignRemaining(props.campaignAutomationConfigs.l2CoderCount, props.projectTotals.l2CoderCount);
    }

    const formatCampaignRemaining = (campaignCount, projectCount) =>{
        return campaignCount - projectCount ? campaignCount - projectCount : 0
    }
    
    return(
        <Grid fluid>
            <Row style={{marginBottom: "2rem" }}>
                <BootstrapTable data={Array(1).fill(props.project)} maxHeight="516px" margin="100px">
                    <TableHeaderColumn dataField="projectID" isKey hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordReviewCount" editable={false} dataAlign="left">Total Reviews</TableHeaderColumn>
                    <TableHeaderColumn dataField="medicalRecordReviewRemainingCount" editable={false} dataAlign="left">Reviews Remaining</TableHeaderColumn>
                    <TableHeaderColumn dataField="projectL1Count" editable={false} dataFormat={formatProjectL1Count} dataAlign="left">Project L1 #</TableHeaderColumn>
                    <TableHeaderColumn dataField="projectL2Count" editable={false} dataFormat={formatProjectL2Count}>Project L2 #</TableHeaderColumn>
                    <TableHeaderColumn dataField="campaignRemainingL1Count" editable={false} dataFormat={formatCampaignRemainingL1Count}>Campaign Remaining L1 #</TableHeaderColumn>
                    <TableHeaderColumn dataField="campaignRemainingL2Count" editable={false} dataFormat={formatCampaignRemainingL2Count}>Campaign Remaining L2 #</TableHeaderColumn>
                    <TableHeaderColumn dataField="projectStatus" editable={false} dataFormat={formatProjectStatus} dataAlign="left">Project Status</TableHeaderColumn>
                </BootstrapTable>
            </Row>
        </Grid>
    );    
}

export default ProjectDetailTable;

// exports CampaignEMRReferenceItemType - an immutable object 'enum'
// usage: import { CampaignEMRReferenceItemType } from '../../enums/CampaignEMRReferenceItemType';
const campaignEMRReferenceItemType = {
    VPNSoftware: 1,
    RDPSoftware: 1000,
    EMRType: 2000,
    EMRPlatform: 2001,
    AccessType: 3000,
    CredentialType: 4000
};
Object.freeze(campaignEMRReferenceItemType);
export const CampaignEMRReferenceItemType = campaignEMRReferenceItemType;
import { connect } from 'react-redux';
import { setDiagIDList, setClaimsDiagInitiatorIDList, setTriggerDiagRefresh
    } from '../../../../redux/actions/index';
import CaptureContainer from './CaptureContainer';

const mapStateToProps = state => ({
    diags: state.diags
});

const mapDispatchToProps = dispatch => ({
    setDiagIDList: diagIDList => dispatch(setDiagIDList(diagIDList)),
    setClaimsDiagInitiatorIDList: claimsDiagInitiatorIDList => dispatch(setClaimsDiagInitiatorIDList(claimsDiagInitiatorIDList)),
    setTriggerDiagRefresh: triggerDiagRefresh => dispatch(setTriggerDiagRefresh(triggerDiagRefresh))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaptureContainer);
import React from 'react';
import axios from 'axios';
import { Modal, Row } from 'react-bootstrap';
import Loader from 'react-loader';

class NLPInsightHCCDiagModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {           
            visible: false,            
            diagLoaded: true            
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");

    }

    componentWillReceiveProps(nextProps) {               
        if (nextProps.visible)
        {
            //todo: create distinct paths for add, edit, and alert add flows (lot of redundant work here)
            this.setState({
                visible: true,
                //editQABool: nextProps.editQABool
            }, () => {
                this.setState({ 
                    nlpInsightHccDiag: nextProps.nlpInsightHccDiag
                }); 
                
            });
        }
        else if (nextProps.visible !== this.state.visible) {
            this.setState({ visible: nextProps.visible });
        }  
    }

    handleModalToggle = (clearFormValues) => {
        this.props.handleModalToggle();
    }   

    formatDate = (cell) => {
        if(!cell){ return ""; }
        var options = {year: 'numeric', month: 'numeric', day: 'numeric'};
       return `${(cell ? new Date(cell).toLocaleDateString('en-US', options) : '')}`;
    }

    sortdiagnosisCode = (a, b, order, sortField, index) => {
        
        index= (index===undefined) ?0: index;
        
        var aA = a[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var bA = b[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var result;
        if((aA===undefined) && (bA!==undefined))
        {
            return -1;
        }
        else if((aA!==undefined) && (bA===undefined))
        {
            return 1;
        }
        else if((aA===undefined) && (bA===undefined))
        {
            return -1;
        }
        if (aA === bA) {
           result= this.sortdiagnosisCodeDetail(a, b, order, sortField, index+1);                        
          } else {
            if(isNaN(aA) && isNaN(bA)){
                result = aA > bA ? 1 : -1;}
            else{
                result = Number(aA) > Number(bA) ? 1 : -1;}
          }
          if (order === 'asc') {
            return result;
        }
        else {
            return (result)*(-1);
        }
    }

    sortdiagnosisCodeDetail = (a, b, order, sortField, index) => {
        
        index= (index===undefined) ?0: index;
        
        var aA = a[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var bA = b[sortField].match(/[a-zA-Z]+|\d+(\.\d)?/g).join(',').split(',')[index];
        var result;
        if((aA===undefined) && (bA!==undefined))
        {
            return -1;
        }
        else if((aA!==undefined) && (bA===undefined))
        {
            return 1;
        }
        else if((aA===undefined) && (bA===undefined))
        {
            return 1;
        }
        if (aA === bA) {
          return this.sortdiagnosisCodeDetail(a, b, order, sortField, index+1);     

          } else {
            if(isNaN(aA) && isNaN(bA))
            {
                return  aA > bA ? 1 : -1;}
            else{
                return Number(aA) > Number(bA) ? 1 : -1;}
          }
          
    }

    render() {        
        return (
            <div>
                <Modal show={this.state.visible} className="diagFormModal" onHide={() => this.handleModalToggle()} keyboard bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title>Diagnosis
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Loader loaded={this.state.diagLoaded}>
                        {
                            <Row style={{ marginBottom: '1rem', marginLeft: '0px', marginRight: '0px' }}>
                            <BootstrapTable data={this.state.nlpInsightHccDiag} >                               
                            <TableHeaderColumn dataField="dosStart" dataFormat={this.formatDate} dataSort width="10%">DOS From</TableHeaderColumn>
                            <TableHeaderColumn dataField="dosEnd" dataFormat={this.formatDate} dataSort  width="10%">DOS To</TableHeaderColumn>
                            <TableHeaderColumn dataField="pageStart"  dataSort   width="10%">Pg. From</TableHeaderColumn>
                            <TableHeaderColumn dataField="pageEnd"  dataSort   width="10%">Pg. To</TableHeaderColumn>
                                <TableHeaderColumn dataField="diagnosisCode" isKey dataSort sortFunc={this.sortdiagnosisCode}   width="10%">Diag Code</TableHeaderColumn>
                                <TableHeaderColumn dataField="description" dataSort width="30%" >Description</TableHeaderColumn>                               
                                <TableHeaderColumn dataField="hccValue" dataSort   width="7%">HCC</TableHeaderColumn>                                
                                <TableHeaderColumn dataField="hccVersion" dataSort width="13%" >HCC Version</TableHeaderColumn>                                
                            </BootstrapTable>
                        </Row>
                        }
                        </Loader>
                    </Modal.Body>
                    <Modal.Footer>                                             
                    </Modal.Footer>
                </Modal>                
            </div>
        );
    }
}

export default NLPInsightHCCDiagModel;
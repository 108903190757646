import React from 'react';
import axios from 'axios';
import { Col, ControlLabel, FormGroup, Row, } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import CommentControl from './CommentControl';

export default class CommentConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects : [],
            projectID : "",
            commentGroups : [],
            commentGroupID : null,
            comments : [],
            commentTypes: [],
            commentTypeID: "",
            commentDefaultGroups: [],
            commentDefaultGroupID: "",
        }
    }

    componentWillMount() {
        Promise.all([axios.get('/projects'), axios.get('/commentTypes'), axios.get('/commentDefaultGroups')])
        .then((responses) => {
            this.setState({
                projects: responses[0].data,
                commentTypes: responses[1].data,
                commentDefaultGroups: responses[2].data
            });
        });
    }

    getComments = () => axios.get(`/projects/${this.state.projectID}/comments?commentTypeID=${this.state.commentTypeID}&commentDefaultGroupID=${this.state.commentDefaultGroupID}`)
        .then((response) => {
            this.setState({ comments: response.data });
        })

    handleProjectChange = (e) => {
        this.setState({ projectID: e ? e.value : ''}, () => {
            if (e) {
                this.getComments();
            }
            else {
                this.setState({ comments: [] });
            }
        });
    }

    handleCommentTypeChange = (e) => {
        this.setState({ commentTypeID: e ? e.value : '' }, this.getComments);
    }

    handleCommentDefaultGroupChange = (e) => {
        this.setState({ commentDefaultGroupID: e ? e.value : '' }, this.getComments);
    }

    renderCommentControl = (cell, row) => {
        return <CommentControl projectID={this.state.projectID} row={row} getComments={this.getComments} />
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} style={{ marginTop: '1.4rem' }}>
                        <h4>Project Comments</h4>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel>Project</ControlLabel>
                            <Select options={this.state.projects.map((item) => {
                                    return {
                                        label: item.projectName,
                                        value: item.projectID
                                    }
                                })} value={this.state.projectID} onChange={this.handleProjectChange} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Comment Type</ControlLabel>
                            <Select options={this.state.commentTypes.map((item) => {
                                    return {
                                        label: item.commentTypeDesc,
                                        value: item.commentTypeID
                                    }
                                })} value={this.state.commentTypeID} onChange={this.handleCommentTypeChange} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Comment Default Group</ControlLabel>
                            <Select options={this.state.commentDefaultGroups.map((item) => {
                                    return {
                                        label: item.commentDefaultGroupTitle,
                                        value: item.commentDefaultGroupID
                                    }
                                })} value={this.state.commentDefaultGroupID} onChange={this.handleCommentDefaultGroupChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <BootstrapTable data={this.state.comments}>
                            <TableHeaderColumn dataField="commentDefinitionID" dataAlign="left" dataSort isKey hidden />
                            <TableHeaderColumn dataField="isActive" dataAlign="left" dataFormat={this.renderCommentControl} dataSort width="16%"
                                tdStyle={{ overflow: 'inherit' }}>Is Active</TableHeaderColumn>
                            <TableHeaderColumn dataField="commentDefaultGroups" dataAlign="left" dataSort width="20%">Default Groups</TableHeaderColumn>
                            <TableHeaderColumn dataField="commentText" dataAlign="left" dataSort>Comment Name</TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}
import React from 'react';
import axios from 'axios';
import { Modal, Button, Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { formatISODateOnly }  from '../../../../../utils/DateUtils';

class OpportunityDiagnosisLookupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dxLookupCode: '',
            hccPartCs: '',
            hccPartDs: '',
            diagnosis: null,
            validationErrorMessages: []
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible
        })
    }

    // clear data on modal toggle
    handleModalToggle = () => {
        this.resetHccValuesAndDescription(true);
        this.props.handleModalToggle();
    }

    // dx change handler
    handleDXChange = (e) => {
        if (e.target) {
            this.setState({ 
                dxLookupCode: e.target.value.toUpperCase()
            });
        }
    }

    // clear hcc values and description
    resetHccValuesAndDescription = (clearDXLookup) => {
        this.setState({
            diagnosis: null, 
            dxLookupCode: clearDXLookup ? '' : this.state.dxLookupCode,
            hccPartCs: '',
            hccPartDs: '',
            validationErrorMessages: clearDXLookup ? [] : this.state.validationErrorMessages
        });
    }

    // set state values of hccC, hccD, and description
    fillHCCInfo = (data) => {
        if (data) {
            this.setState({ 
                diagnosis: data,
                hccPartCs: data.hccPartCs ? data.hccPartCs.map(x => x.model + "-" + x.value).toString() : '',
                hccPartDs: data.hccPartDs ? data.hccPartDs.map(x => x.model + "-" + x.value).toString() : ''
            });
        }
    }

    // lookup diagnosis code, return values if valid, display error if invalid
    lookupDiagnosisCode = () => {
        axios.get(`/diagnosisLookup/`, {
            params: {
                memberInternalKey: this.props.memberID,
                diagnosisCD: this.state.dxLookupCode,
                dateOfServiceThru: formatISODateOnly(new Date(this.props.row.dateOfServiceFrom))
            }
        })
        .then((response) => {
            this.fillHCCInfo(response.data);
            this.setState({
                validationErrorMessages: []
            });
        })
        .catch((error) => {
            this.resetHccValuesAndDescription();
            this.setState({
                validationErrorMessages: [{ id: 'diagnosisNotFound', field: 'diagnosisCD', errorText: error.response.data.message }]
            });
        })
    }

    // select added dxCode and toggle lookup modal
    selectDXLookup = () => {
        this.props.selectDXLookup(this.state.dxLookupCode);
        this.handleModalToggle();
    }

    render() {
        return (
            <div>
                <Modal show={this.state.visible} className="diagLookupModal" onHide={this.handleModalToggle} keyboard bsSize="large">
                    <Modal.Header closeButton>
                        Opportunity Entry
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>DOS</ControlLabel>
                                    <DatePicker value={this.props.row.dateOfServiceFrom} style={{zIndex: 'auto'}} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}} className="requiredField">DX Code</ControlLabel>
                                    <FormControl type="text" className="uppercaseText requiredField" value={this.state.dxLookupCode} 
                                        onChange={this.handleDXChange} />
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>HCC-C</ControlLabel>
                                    <FormControl type="text" value={this.state.hccPartCs} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>HCC-D</ControlLabel>
                                    <FormControl type="text" value={this.state.hccPartDs} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <ControlLabel style={{marginRight: '1rem'}}>Description</ControlLabel>
                                    <FormControl type="text" value={this.state.diagnosis && this.state.diagnosis.description} disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{textAlign: 'left'}}>
                            {
                                this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0 &&
                                this.state.validationErrorMessages.map(error => {
                                    return <div key={error.id} className="errorMessage">{error.errorText}</div>
                                })
                            }
                            <br/>
                        </div>
                        <div style={{float: 'left'}}>
                            <Button bsStyle="primary" disabled={!this.state.dxLookupCode} 
                                onClick={this.lookupDiagnosisCode}>Diagnosis Lookup</Button>
                            <Button bsStyle="primary" disabled={!this.state.diagnosis} onClick={this.selectDXLookup}>Select Code</Button>
                            <Button bsStyle="primary" onClick={this.handleModalToggle}>Cancel</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default OpportunityDiagnosisLookupModal;
// exports PackageStatuses - an immutable object 'enum'
// usage: import { PackageStatuses } from '../../enums/PackageStatuses';
const packageStatus = {
    Cancelled: -100,
    Created: 10,
    UserFinalized: 20,
    CreatingPDFs: 40,
    PDFsCreated: 50,
    BundlingPDFs: 60,
    BundlingPDFsCompleted: 70,
    SyncingWorkflows: 80,
    SyncingWorkflowsCompleted: 90
};
Object.freeze(packageStatus);
export const PackageStatus = packageStatus;
import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Grid, Navbar, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import AlertToastr from '../../../Shared/AlertToastr';
import ContactInfo from './ContactInfo';
import ContactInfoTable from './ContactInfoTable';
import EMRInfo from './EMRInfo';
import RDPInfo from './RDPInfo';
import VPNInfo from './VPNInfo';
import AddReferenceItemModal from './AddReferenceItemModal';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';

export default class AccessWizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStage: 'contact',
            contactInfo: [],
            contactKeyCount: 0,
            vpnInfo: {
                vpn: false,
                vpnSoftware: null,
                accessType: null,
                urlOrIP: '',
                domain: '',
                port: '',
                notes: ''
            },
            rdpInfo: {
                remoteDesktop: false,
                remoteSoftware: null,
                accessType: null,
                ipOrComputerName: '',
                domain: '',
                notes: ''
            },
            emrInfo: {
                emr: null,
                platform: null,
                accessType: null,
                url: '',
                mfa: false,
                notes: ''
            },
            loaded: false,
            showAddRefItemModal: false,
            toast: null,
            // Types of Ref Items
            vpnSoftwareOptions: [],
            remoteSoftwareOptions: [],
            emrOptions: [],
            emrPlatformOptions: [],
            accessTypeOptions: [],
            //
            currAddRefItemTypeID: 0,
            currAddRefItemTypeDesc: '',
            currAddRefItemOptions: [],
            displayName: null
        };
    }

    componentDidMount() {
        this.getReferenceItems();
        this.getDisplayName();
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    handleWizardAddContact = (contact) => {
        contact.campaignEMRInstanceContactID = this.state.contactKeyCount;
        this.setState({
            contactInfo: [...this.state.contactInfo, contact],
            contactKeyCount: this.state.contactKeyCount + 1
        });
    }

    handleWizardRemoveContact = (contact) => {
        this.setState({ contactInfo: [...this.state.contactInfo.filter(x => x !== contact )]});
    }

    handleVPNInfoFieldChange = (updateObj) => {
        this.setState({ vpnInfo: { ...this.state.vpnInfo, ...updateObj }});
    }

    handleRDPInfoFieldChange = (updateObj) => {
        this.setState({ rdpInfo: { ...this.state.rdpInfo, ...updateObj } });
    }

    handleEMRInfoFieldChange = (updateObj) => {
        this.setState({ emrInfo: { ...this.state.emrInfo, ...updateObj } });
    }

    handleWizardBack = () => {
        switch (this.state.currentStage) {
            case 'vpn':
                this.setState({ currentStage: 'contact' });
                break;
            case 'rdp':
                this.setState({ currentStage: 'vpn' });
                break;
            case 'emr':
                this.setState({ currentStage: 'rdp' });
                break;
            default:
                break;
        }
    }

    handleWizardNext = () => {
        switch (this.state.currentStage) {
            case 'contact':
                this.setState({ currentStage: 'vpn' });
                break;
            case 'vpn':
                this.setState({ currentStage: 'rdp' });
                break;
            case 'rdp':
                this.setState({ currentStage: 'emr' });
                break;
            default:
                break;
        }
    }

    getReferenceItems = (typeID) => {
        const endpoint = typeID ? `/campaignEMRReferenceItem?typeID=${typeID}` : '/campaignEMRReferenceItem';
        return axios.get(endpoint)
            .then((response) => {
                this.setState({
                    vpnSoftwareOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.VPNSoftware) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.VPNSoftware).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.vpnSoftwareOptions,
                    remoteSoftwareOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.RDPSoftware) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.RDPSoftware).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.remoteSoftwareOptions,
                    emrOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRType) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRType).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.emrOptions,
                    emrPlatformOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRPlatform) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRPlatform).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.emrPlatformOptions,
                    accessTypeOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.AccessType) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.AccessType).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.accessTypeOptions,
                    loaded: true
                });
            })
            .catch(() => {
                this.setState({ loaded: true })
                this.state.toast('error', 'Could not load reference items.', 'Error');
            })
    }

    getDisplayName = () => {
        if(this.props.router.location.query.campaignGroupID)
        {
            return axios.get(`/campaignGroup/${this.props.router.location.query.campaignGroupID}/displayName`)
                .then((response) => {
                    this.setState({
                        loaded: true,
                        displayName: response.data
                    });
                })
                .catch(() => {
                    this.setState({ loaded: true })
                    this.state.toast('error', 'Could not load grouping.', 'Error');
                })
        }
        if(this.props.router.location.query.campaignPracticeID)
        {
            return axios.get(`/campaignPracticeSummary/${this.props.router.location.query.campaignPracticeID}/displayName`)
            .then((response) => {
                this.setState({
                    loaded: true,
                    displayName: response.data
                });
            })
            .catch(() => {
                this.setState({ loaded: true })
                this.state.toast('error', 'Could not load practice.', 'Error');
            })
        }
    }




    handleWizardSaveButton = () => {
        this.setState({ loaded: false });

        // create EMRInstance
        axios.post('/campaignEMRInstance', {
            CampaignEMRStatusID: 0,
            VPNAccess: this.state.vpnInfo.vpn,
            VPNSoftwareTypeID: this.state.vpnInfo.vpnSoftware ? this.state.vpnInfo.vpnSoftware : null,
            VPNCampaignEMRAccessTypeID: this.state.vpnInfo.accessType ? this.state.vpnInfo.accessType : null,
            VPNIP: this.state.vpnInfo.urlOrIP,
            VPNDomain: this.state.vpnInfo.domain,
            VPNPort: this.state.vpnInfo.port,
            VPNNotes: this.state.vpnInfo.notes,
            RDPAccess: this.state.rdpInfo.remoteDesktop,
            RDPSoftwareTypeID: this.state.rdpInfo.remoteSoftware ? this.state.rdpInfo.remoteSoftware : null,
            RDPCampaignEMRAccessTypeID: this.state.rdpInfo.accessType ? this.state.rdpInfo.accessType : null,
            RDPIP: this.state.rdpInfo.ipOrComputerName,
            RDPDomain: this.state.rdpInfo.domain,
            RDPNotes: this.state.rdpInfo.notes,
            CampaignEMRID: this.state.emrInfo.emr ? this.state.emrInfo.emr : null,
            EMRPlatformTypeID: this.state.emrInfo.platform ? this.state.emrInfo.platform : null,
            EMRCampaignEMRAccessTypeID: this.state.emrInfo.accessType ? this.state.emrInfo.accessType : null,
            EMRUrl: this.state.emrInfo.url,
            MFA: this.state.emrInfo.mfa,
            EMRNotes: this.state.emrInfo.notes,
            CampaignPracticeIDToUpdate: !this.props.location.query.campaignGroupID && this.props.location.query.campaignPracticeID ? this.props.location.query.campaignPracticeID : null,
            CampaignGroupIDToUpdate: this.props.location.query.campaignGroupID ? this.props.location.query.campaignGroupID : null
        })
        .then((response) => {
            if (this.state.contactInfo.length > 0) {
                // create EMRInstanceContacts
                Promise.all(this.state.contactInfo.map(
                    contact => axios.post(`/campaignEMRInstance/${response.data.campaignEMRInstanceID}/contacts`, {
                        campaignEMRInstanceID: response.data.campaignEMRInstanceID,
                        name: contact.name,
                        title: contact.title,
                        phone: contact.phone,
                        email: contact.email,
                        helpDeskPhone: contact.helpDeskPhone,
                        notes: contact.notes
                    })
                ))
                .then((innerResponse) => {
                    // navigate to full view for newly-created EMRInstance
                    this.props.router.push(`/EMR/Access/${response.data.campaignEMRInstanceID}/practice/${this.props.router.location.query.campaignPracticeID}`);
                })
                .catch((error) => {
                    this.setState({ loaded: true });
                    this.state.toast('error', 'Could not add contact. Please review the created contact list.', 'Error');
                    setTimeout(this.props.router.push(`/EMR/Access/${response.data.campaignEMRInstanceID}`), 1000);
                });
            }
            else {
                // navigate to full view for newly-created EMRInstance
                this.props.router.push(`/EMR/Access/${response.data.campaignEMRInstanceID}/practice/${this.props.router.location.query.campaignPracticeID}`);
            }
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.state.toast('error', 'Could not save EMR access data.', 'Error');
        });
    }

    isSaveDisabled = (isFinalValidation = true) => {
        const vpnValid = Boolean(this.state.vpnInfo.vpnSoftware) && Boolean(this.state.vpnInfo.accessType)
            && this.state.vpnInfo.urlOrIP;
        const rdpValid = Boolean(this.state.rdpInfo.accessType) && this.state.rdpInfo.ipOrComputerName;
        const emrValid = isFinalValidation ? (Boolean(this.state.emrInfo.emr) && Boolean(this.state.emrInfo.platform)
            && Boolean(this.state.emrInfo.accessType) && this.state.emrInfo.mfa !== null) : true;

        if (this.state.vpnInfo.vpn && this.state.rdpInfo.remoteDesktop) {
            return !(vpnValid && rdpValid && emrValid);
        }
        else if (this.state.vpnInfo.vpn) {
            return !(vpnValid && emrValid);
        }
        else if (this.state.rdpInfo.remoteDesktop) {
            return !(rdpValid && emrValid);
        }
        else {
            return !emrValid;
        }
    }

    handleRefItemRefresh = (typeID) => {
        this.getReferenceItems(typeID)
            .then(() => this.handleAddRefItemModalOpen(typeID));
    }

    handleAddRefItemModalOpen = (typeID) => {
        let currTypeID = 0;
        let currTypeDesc = '';
        let currOptions = [];
        switch (typeID) {
            case CampaignEMRReferenceItemType.VPNSoftware:
                currTypeID = typeID;
                currTypeDesc = 'VPN Software';
                currOptions = this.state.vpnSoftwareOptions;
                break;
            case CampaignEMRReferenceItemType.RDPSoftware:
                currTypeID = typeID;
                currTypeDesc = 'RDP Software';
                currOptions = this.state.remoteSoftwareOptions;
                break;
            case CampaignEMRReferenceItemType.EMRType:
                currTypeID = typeID;
                currTypeDesc = 'EMR';
                currOptions = this.state.emrOptions;
                break;
            case CampaignEMRReferenceItemType.EMRPlatform:
                currTypeID = typeID;
                currTypeDesc = 'EMR Platform';
                currOptions = this.state.emrPlatformOptions;
                break;
            case CampaignEMRReferenceItemType.AccessType:
                currTypeID = typeID;
                currTypeDesc = 'Access Type';
                currOptions = this.state.accessTypeOptions;
                break;
            default:
                break;
        }
        this.setState({ 
            showAddRefItemModal: true,
            currAddRefItemTypeID: currTypeID,
            currAddRefItemTypeDesc: currTypeDesc,
            currAddRefItemOptions: currOptions,
        });
    }

    handleAddRefItemModalClose = () => {
        this.setState({ showAddRefItemModal: false });
    }



    render() {
        return (
            <Grid fluid>
                <Loader loaded={this.state.loaded}>
                    <Row>
                        <Col xs={9} style={{ paddingLeft: '0rem' }}>
                            <h3>EMR Access - {this.state.displayName}</h3>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                    {
                        this.state.currentStage === 'contact' &&
                        <div>
                            <h3 style={{ marginBottom: '2.4rem' }}>Contact Info</h3>
                            <ContactInfo contactInfo={this.state.contactInfo} handleAddContact={this.handleWizardAddContact} />
                            <ContactInfoTable contactInfo={this.state.contactInfo} handleRemoveContact={this.handleWizardRemoveContact} />
                        </div>
                    }
                    {
                        this.state.currentStage === 'vpn' &&
                        <div>
                            <h3 style={{ marginBottom: '2.4rem' }}>VPN Info</h3>
                            <VPNInfo vpnInfo={this.state.vpnInfo} handleWorkflowFieldChange={this.handleVPNInfoFieldChange} handleAddRefItemModalOpen={this.handleAddRefItemModalOpen}
                                vpnSoftwareOptions={this.state.vpnSoftwareOptions} accessTypeOptions={this.state.accessTypeOptions} />
                        </div>
                    }
                    {
                        this.state.currentStage === 'rdp' &&
                        <div>
                            <h3 style={{ marginBottom: '2.4rem' }}>RDP Info</h3>
                            <RDPInfo rdpInfo={this.state.rdpInfo} handleWorkflowFieldChange={this.handleRDPInfoFieldChange} handleAddRefItemModalOpen={this.handleAddRefItemModalOpen}
                                remoteSoftwareOptions={this.state.remoteSoftwareOptions} accessTypeOptions={this.state.accessTypeOptions} />
                        </div>
                    }
                    {
                        this.state.currentStage === 'emr' &&
                        <div>
                            <h3 style={{ marginBottom: '2.4rem' }}>EMR Info</h3>
                            <EMRInfo emrInfo={this.state.emrInfo} handleWorkflowFieldChange={this.handleEMRInfoFieldChange} handleAddRefItemModalOpen={this.handleAddRefItemModalOpen}
                                emrOptions={this.state.emrOptions} emrPlatformOptions={this.state.emrPlatformOptions} accessTypeOptions={this.state.accessTypeOptions} />
                        </div>
                    }
                    </Row>
                </Loader>
                <Navbar fixedBottom fluid>
                    {
                        this.state.currentStage !== 'contact' &&
                        <Button onClick={this.handleWizardBack} style={{ float: 'left', marginTop: '0.7rem' }}>
                            <Glyphicon glyph="arrow-left" style={{ marginRight: '0.2rem' }} /> Back
                        </Button>
                    }
                    {
                        this.state.currentStage !== 'emr' &&
                        <Button onClick={this.handleWizardNext} disabled={this.isSaveDisabled(false)} style={{ float: 'right', marginTop: '0.7rem' }}>
                            Next <Glyphicon glyph="arrow-right" style={{ marginLeft: '0.2rem' }} />
                        </Button>
                    }
                    {
                        this.state.currentStage === 'emr' &&
                        <Button onClick={this.handleWizardSaveButton} disabled={this.isSaveDisabled()} bsStyle="primary"
                            style={{ float: 'right', marginTop: '0.7rem' }}>
                            <Glyphicon glyph="floppy-disk" style={{ marginRight: '0.6rem' }} />Save
                        </Button>
                    }
                </Navbar>

                <AddReferenceItemModal visible={this.state.showAddRefItemModal} refTypeID={this.state.currAddRefItemTypeID} refTypeDesc={this.state.currAddRefItemTypeDesc} 
                    options={this.state.currAddRefItemOptions} handleModalClose={this.handleAddRefItemModalClose} handleRefItemRefresh={this.handleRefItemRefresh} 
                    toast={this.state.toast} />

                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        );
    }
}
const diags = (state = [], action) => {
    switch (action.type) {
        case 'SET_DIAG_FORM':
            if (state.find(x => x.type === action.type)) {
                return state.map(diag =>
                    (diag.type === action.type)
                    ? { ...diag, diagFormData: action.diagFormData }
                    : diag
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        diagFormData: action.diagFormData
                    }
                ];
            }
        case 'SET_DIAG_ID_LIST':
            if (state.find(x => x.type === action.type)) {
                return state.map(diag =>
                    (diag.type === action.type)
                    ? { ...diag, diagIDList: action.diagIDList }
                    : diag
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        diagIDList: action.diagIDList
                    }
                ];
            }
        case 'SET_CLAIMS_DIAG_INITIATOR_ID_LIST':
            if (state.find(x => x.type === action.type)) {
                return state.map(diag =>
                    (diag.type === action.type)
                    ? { ...diag, claimsDiagInitiatorIDList: action.claimsDiagInitiatorIDList }
                    : diag
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        claimsDiagInitiatorIDList: action.claimsDiagInitiatorIDList
                    }
                ];
            }
        case 'SET_CLAIMS_LIST':
            if (state.find(x => x.type === action.type)) {
                return state.map(diag =>
                    (diag.type === action.type)
                    ? { ...diag, claimsList: action.claimsList }
                    : diag
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        claimsList: action.claimsList
                    }
                ];
            }
        case 'SET_TRIGGER_DIAG_REFRESH':
            if (state.find(x => x.type === action.type)) {
                return state.map(diag =>
                    (diag.type === action.type)
                    ? { ...diag, triggerDiagRefresh: action.triggerDiagRefresh }
                    : diag
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        triggerDiagRefresh: action.triggerDiagRefresh
                    }
                ];
            }
        default:
            return state;
    }
};

export default diags;
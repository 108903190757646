import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';

class AlertModalMemberTable extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        //only rerender this component when memberResults has changed
        return nextProps.memberResults !== this.props.memberResults;
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    formatAlert = (alert) => {
        if (alert) {
            const alertUrl = `/cdi/${alert}/viewonly`;
            return `<a target="_blank" rel="noopener noreferrer" href="${alertUrl}">${alert}</a>`;
        }
    }

    customSelect = (selectProps) => {
        const rowIndex = selectProps.rowIndex;
        if (rowIndex === 'Header') {
            return null;
        }
        else {
            return (
                <div>
                    <Button onClick={() => this.props.handleAddMemberClick(this.refs.memberSearchTable.state.data[selectProps.rowIndex].memberID)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
            );
        }
    }

    render() {
        const selectRow = {
            mode: 'radio',
            customComponent: this.customSelect,
            columnWidth: '7rem'
        };
        const options = {
            page: this.props.page,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return { text: item.toString(), value: item } }),
            sizePerPage: this.props.pageSize,
            pageStartIndex: 1,
            onPageChange: this.props.onPageChange,
            onSortChange: this.props.onSortChange,
            defaultSortName: this.props.sortBy ? this.props.sortBy : '',
            defaultSortOrder: this.props.sortBy ?
                this.props.sortByDesc ? 'desc' : 'asc'
                : ''
        };

        return (
            <Loader loaded={!this.props.loadingResults}>
                <BootstrapTable
                    data={this.props.memberResults}
                    tableHeaderClass="tableHeader" tableBodyClass="tableBody table-body-wrap-text" hover
                    pagination remote
                    fetchInfo={{ dataTotalSize: this.props.memberSearchTotalCount }}
                    options={options}
                    selectRow={selectRow}
                    maxHeight="440px" scroll-top="Top"
                    ref='memberSearchTable'>
                    <TableHeaderColumn dataField="memberID" dataAlign="left" dataSort isKey>Member ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberName" dataAlign="left" dataSort>Member Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberDateOfBirth" dataAlign="left" dataFormat={this.formatDateString} dataSort>Date of Birth</TableHeaderColumn>
                    <TableHeaderColumn dataField="assignedPractice" dataAlign="left" dataSort>Assigned Practice</TableHeaderColumn>
                    <TableHeaderColumn dataField="assignedProvider" dataAlign="left" dataSort>Assigned Provider</TableHeaderColumn>
                    <TableHeaderColumn dataField="activeMemberAlertWorkflowID" dataAlign="left" dataFormat={this.formatAlert} dataSort>Active Alert</TableHeaderColumn>
                </BootstrapTable>
            </Loader>
        );
    }
}

export default AlertModalMemberTable;
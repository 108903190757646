import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import { CommentProjectDTOCode } from '../../../enums/CommentProjectDTOCode';

export default class CommentConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.row.statusCode === CommentProjectDTOCode.AddedDeactivationDateInPast
            || props.row.statusCode === CommentProjectDTOCode.AddedDeactivationDateInFuture ? this.props.row.deactivationDatetime : ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.row.statusCode === CommentProjectDTOCode.AddedDeactivationDateInPast
            || nextProps.row.statusCode === CommentProjectDTOCode.AddedDeactivationDateInFuture) {
            this.setState({ value: nextProps.row.deactivationDatetime });
        }
    }

    handleAddComment = (row) => {
        axios.post(`/projects/${this.props.projectID}/comments/${this.props.row.commentDefinitionID}`,
            { activate: true })
        .then(this.props.getComments)
    }

    handleDatePickerChange = (value) => {
        const activate = value < new Date();
        axios.post(`/projects/${this.props.projectID}/comments/${this.props.row.commentDefinitionID}`,
            { activate: activate, DeactivationDateTime: value })
        .then(this.props.getComments)
    }

    renderCommentControl = () => {
        const datePickerStyle = {
            'zIndex': 'auto',          
           };

        if (this.props.row.statusCode === CommentProjectDTOCode.NotAddedToXref) {
            return <Button onClick={this.handleAddComment}>Add</Button>;
        }
        else if (this.props.row.statusCode === CommentProjectDTOCode.AddedNoDeactivationDate) {
            return <div>Set Deactivation Date as <DatePicker style={datePickerStyle} onChange={this.handleDatePickerChange} /></div>;
        }
        else if (this.props.row.statusCode === CommentProjectDTOCode.AddedDeactivationDateInPast) {
            return <div>Deactivated as of <DatePicker style={datePickerStyle} value={this.state.value} onChange={this.handleDatePickerChange} /></div>;
        }
        else if (this.props.row.statusCode === CommentProjectDTOCode.AddedDeactivationDateInFuture) {
            return <div>Will Deactivate as of <DatePicker style={datePickerStyle} value={this.state.value} onChange={this.handleDatePickerChange} /></div>;
        }
        else {
            return <div>Not Mapped {this.props.row.statusCode}</div>;
        }
    }

    render() {
        return this.renderCommentControl();
    }
}
import React from 'react';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ApplicationReportGroup } from '../../enums/ApplicationReportGroup';

const Reports = (props) => {
    const lineOfBusiness = localStorage.getItem("lineOfBusiness");

    const handleSelectReport = (selectedReport) => {
        if (selectedReport && selectedReport.value) {
            switch (selectedReport.value) {
                case 1:
                    window.location.href = '/Reports/ProjectStatus';
                    break;
                case 3:
                    window.location.href = '/Reports/CoderQAScore';
                    break;
                case 4:
                    window.location.href = '/Reports/RiskMitigation';
                    break;
                case 5:
                    window.location.href = '/Reports/Claims';
                    break;
                case 7:
                    window.location.href = '/Reports/FinalMeasure';
                    break;
                case 8:
                    window.location.href = '/Reports/StarsProductivity';
                    break;
                case 9:
                    window.location.href = '/Reports/ExtractReview';
                    break;
                case 11:
                    window.location.href = '/Reports/CoderPerformance';
                    break;
                case 13:
                    window.location.href = '/Reports/PIR';
                    break;
                case 14:
                    window.location.href = '/Reports/Comments';
                    break;
                case 15:
                    window.location.href = '/Reports/ClaimsLinking';
                    break;
                case 23:
                    window.location.href = '/Reports/ClaimsLinkingSummary';
                    break;
                case 24:
                    window.location.href = '/Reports/auditsummaryreport';
                    break;
                case 25:
                    window.location.href = '/Reports/auditdetailreport';
                    break;
                default:
                    props.toast('error', 'Could not navigate to selected report.', 'Error');
                    break;
            }
        }
    }

    const filterCommReports = (reports) => {
        return lineOfBusiness !== 'Commercial' ?
            reports.filter(x => x.applicationReportGroupID === ApplicationReportGroup.MRRReports)
            : reports;
    }

    const getReportDropdownOptions = (reports) => {
        return filterCommReports(reports).map(report => {
                return {
                    value: report.securableValueID,
                    label: report.securableDesc
                };
            });
    }

    return (
        <div style={{ marginTop: '2rem' }}>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>
                            Reports
                        </ControlLabel>
                        <Select value='' options={getReportDropdownOptions(props.reports)}
                            onChange={handleSelectReport} />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default Reports;
import React from 'react';
import axios from 'axios';
import { Collapse, Glyphicon, Panel, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { formatDatetimeString }  from '../../../../utils/DateUtils';

class StarsProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            memberID: null,
            starProfile: null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            memberID: nextProps.memberID,
            visible: nextProps.visible
        }, () => {
            this.getStarProfileData();
        });
    }

    getStarProfileData = () => {
        axios.get(`/member/${this.state.memberID}/starprofile/`)
        .then((response) => {
            this.setState({ starProfile: response.data });
            // display star profile container if rows exist
            if (response.data) {
                this.setState({ expanded: true });
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load stars profile.', 'Error');
        });
    }

    expandPanel = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    formatStatus = (cell, row) => {
        if (cell === "closed") {
            return <Glyphicon  style={{ color: '#3adb76', fontSize: '18px' }} glyph="glyphicon glyphicon-ok-sign" />
        }
        else if (cell === "open") {
            return <Glyphicon style={{ color: '#f00', fontSize: '18px' }} glyph="glyphicon glyphicon-remove-sign" />
        }
    }

    formatMeasure = (cell, row) => {
        return <Button bsStyle="primary" bsSize="small" onClick={() => this.props.selectStarMeasure(row)}>{cell}</Button>
    }

    renderTable = (results) => {
        return (
            <BootstrapTable data={results} className="profileTable starProfileTable" condensed hover>
                <TableHeaderColumn dataField="status" dataFormat={this.formatStatus} dataSort width="10%">Status</TableHeaderColumn>
                <TableHeaderColumn dataField="eventDate" dataFormat={formatDatetimeString} dataAlign="left" dataSort>Event Date</TableHeaderColumn>
                <TableHeaderColumn dataField="lastDOS" dataFormat={formatDatetimeString} dataAlign="left" dataSort>Last DOS</TableHeaderColumn>
                <TableHeaderColumn dataField="needByDate" dataAlign="left" dataSort>Need By</TableHeaderColumn>
                <TableHeaderColumn dataField="qualityMeasureName" dataAlign="left" dataFormat={this.formatMeasure} isKey dataSort>Measure</TableHeaderColumn>
                <TableHeaderColumn dataField="serviceDescription" dataAlign="left" dataSort width="25%">Service Description</TableHeaderColumn>
                <TableHeaderColumn dataField="serviceResult" dataAlign="left" dataSort width="25%">Service Result</TableHeaderColumn>
            </BootstrapTable>
        );
    }

    render() {
        return (
            <Panel className="starProfilePanel" style={{ display: this.props.visible ? 'block' : 'none' }}>
                <Panel.Heading>
                    <Panel.Title>
                        <div style={{ cursor: 'pointer' }} onClick={this.expandPanel}>
                            Star Profile
                            <div style={{ float: 'right' }}>
                                <Button className="profileButton" bsSize="xsmall" onClick={this.props.toggleTab}>View HCC Profile Data</Button>
                                <Glyphicon glyph="menu-down" style={{ paddingRight: "0.4rem", color: "#6c716c", cursor: 'pointer' }} />
                            </div>
                        </div>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <Collapse in={this.state.expanded}>
                        <div>
                            {
                                this.props.visible && this.state.starProfile &&
                                this.renderTable(this.state.starProfile)
                            }
                        </div>
                    </Collapse>
                </Panel.Body>
            </Panel>
        );
    }
}

export default StarsProfileContainer;
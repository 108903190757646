import React from 'react';
import { Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Glyphicon } from 'react-bootstrap';
import { formatDatetimeString } from '../../../../utils/DateUtils';

//todo
    const EMRHistory = (props) => {
        const onBeforeSaveCell = (row, cellName, cellValue) => {
        //todo(?): return false to stop update if validation is needed
        row[cellName] = cellValue;
        props.handleUpdateRow(row);
    }

    const renderDateTimeString = (cell, row) => {
         return formatDatetimeString(cell);
    }

    const onAfterSaveCell = (row, cellName, cellValue) => {
        if (cellValue) {
            return parseInt(cellValue, 10);
        }
    }

    const getMappedAccessTypeOptions = () => {
        return props.accessTypeOptions.map(x => {
            return { value: x.value, text: x.label };
        });
    }

    const formatAccessType = (cell, row) => {
        const matchingOption = getMappedAccessTypeOptions().filter(x => x.value === cell);
        return matchingOption.length > 0 ? matchingOption[0].text : '';
    }

    const startDateValidator = (value,row) => {
        if(value === ''){
            value = row.startDate;
            return 'Start date must have a value';
        }
        if(!row.startDate||!value) {
            return true;
        }
        if(value < row.endDate) {
            return true;
        }
        else return 'Start date must be before end date'; 
    };

    const endDateValidator = (value,row) => {
        if(value === ''){
            value = row.endDate;
            return 'End date must have a value';
        }
        if(!row.endDate||!value) {
            return true;
        }
        if(value>row.startDate) {
            return true;
        }
        else return 'End date must be after start date'; 
    };

    const cellEdit = {
        mode: 'dbclick',
        blurToSave: true,
        beforeSaveCell: onBeforeSaveCell,
        afterSaveCell: onAfterSaveCell
    };

    const cellHyperLinkFormatter = (cell, row) => {
        return (
            <div>
                <a href={'/EMR/Access/' + row.campaignEMRInstanceID + '/practice/' + row.campaignEMRPracticeID + '/emrview'}>
                    {renderDateTimeString(cell)}
                </a>
            </div>);
    }

    return (
        <div>
            <Row style={{ marginBottom: '1rem', paddingLeft: '0rem' }}>
                <h3 style={{ marginBottom: '1rem', marginTop: '0rem' }}>EMR History</h3>
                <hr />
            </Row>
            <Row>
                <BootstrapTable  data={props.data} cellEdit={cellEdit}>
                    <TableHeaderColumn dataField='campaignEMRAttributionID' isKey hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField='groupName' dataAlign="left" editable={false} dataSort>Practice Group Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='emr' dataAlign="left" editable={false} dataSort>EMR</TableHeaderColumn>
                    <TableHeaderColumn dataField='startDate' dataAlign="left" 
                        editable={{ type: 'date', validator: startDateValidator }} dataFormat={renderDateTimeString}  dataSort>
                        Start Date<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/>
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='endDate' dataAlign="left" 
                        editable={{ type: 'date', validator: endDateValidator }} dataFormat={renderDateTimeString} dataSort>
                        End Date<Glyphicon glyph="pencil" style={{ marginLeft: '0.5rem', fontSize:'10px' }}/>
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='createdDatetime' dataAlign="left" editable={false} dataFormat={cellHyperLinkFormatter} dataSort>Action Date</TableHeaderColumn>
                </BootstrapTable>
            </Row>
        </div>
    );
    
}

export default EMRHistory;
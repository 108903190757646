import React from 'react';
import Select from 'react-select';

export default class LineOfBusinessToggle extends React.PureComponent {
    constructor(props) {
        super(props);

        const lineOfBusiness = localStorage.getItem('lineOfBusiness');
        if (!lineOfBusiness) {
            localStorage.setItem('lineOfBusiness', 'MA');
        }

        this.state = {
            lineOfBusiness
        };

        this.options = [
            { value: 'MA', label: 'MA' },
            { value: 'Commercial', label: 'Commercial' }
        ];
    }

    handleLineOfBusinessChange = (e) => {
        if (e) {
            this.setState({ lineOfBusiness: e.value });
            localStorage.setItem('lineOfBusiness', e.value);
            window.location.reload(true);
        }
    }

    render() {
        return (
            this.props.currentUser && this.props.currentUser.authorizedTabs.allowMACommercialToggle ?
            <div className="nav-line-of-business-container">
                <Select value={this.state.lineOfBusiness} onChange={this.handleLineOfBusinessChange}
                    options={this.options} />
            </div> : null
        )
    }
}
import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Select from 'react-select';
import { CDIAlertWorkflowStatus } from '../../../enums/CDIAlertWorkflowStatus';
import AlertDialog from '../../Shared/AlertDialog';
import Error from '../../Shared/Error';

//todo: alternate between image table and pdf view?
//todo: componentize image embed
class ImageUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadTypeOptions: [
                { label: 'Initial', value: 'C' },
                { label: 'Query Response', value: 'Q' }
            ],
            uploadType: this.props.workflowStatusID !== CDIAlertWorkflowStatus.QueryInitiated
                ? 'C' : 'Q',
            imageID: '',
            imageData: null,
            mimeType: '',
            showImageError: false,
            showConfirmDeleteModal: false,
            fileToDelete: ''
        };
    }

    setFileDropEventListeners = (dropboxDiv) => {
        // set drag and drop event listeners via callback ref
        if (dropboxDiv) {
            this.fileDrop = dropboxDiv;

            addListenerMulti(this.fileDrop,
                'drag dragstart dragend dragover dragenter dragleave drop', this.preventDragDefault);

            addListenerMulti(this.fileDrop, 'dragover dragenter', () => this.setDragOverStyle(true));

            addListenerMulti(this.fileDrop, 'dragleave dragend drop', () => this.setDragOverStyle(false));

            this.fileDrop.addEventListener('drop', this.handleDrop)

            function addListenerMulti(el, s, fn) {
                s.split(' ').forEach(e => el.addEventListener(e, fn, false));
            }
        }
    }

    componentWillUnmount() {
        // remove drag and drop event listeners
        'drag dragstart dragend dragover dragenter dragleave drop'
            .split(' ')
            .forEach(s => this.fileDrop.removeEventListener(s));
    }

    preventDragDefault = (e) => {
        // prevent browser's default drag and drop behavior
        e.preventDefault();
        e.stopPropagation();
    }

    setDragOverStyle = (bool) => {
        this.setState({ dragOverActive: bool })
    }

    handleDrop = (e) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation();
            this.handleUpload(e.dataTransfer.files);
        }
    }

    getUrl = (httpVerb, params) => {
        if (!this.props.isOOA) {
            switch (httpVerb) {
                case 'post':
                    return `/cdialerts/${this.props.alertWorkflowID}/files?cdiFileType=${this.state.uploadType}`;
                case 'get':
                case 'delete':
                    return `/cdialerts/${this.props.alertWorkflowID}/files/${params[0]}`;
                default:
                    return '';
            }
        }
        else {
            switch (httpVerb) {
                case 'post':
                    return `/ooacdialert/${this.props.ooaAlertID}`;
                case 'get':
                    return `/ooacdialert/${this.props.ooaAlertID}/files/${params[0]}`;
                case 'delete':
                    return `/ooacdialert/${this.props.ooaAlertID}/files/${params[0]}`;
                default:
                    return '';
            }
        }
    }

    validateExtensionAndFileType = (extension, fileType) => {
        return !this.props.isOOA ? (extension === "pdf" && fileType === "application/pdf") ||
            (extension === "htm" && fileType === "text/html")
            : extension === "pdf" && fileType === "application/pdf";
    }

    handleUpload = (e) => {
        let uploadIsValid = true;
        const files = e.target ? Array.from(e.target.files) : Array.from(e);
        files.forEach(x => {
            const extension = x.name.split('.').pop().toLowerCase();
            if (!this.validateExtensionAndFileType(extension, x.type)) {
                uploadIsValid = false;
            }
        });

        if (!uploadIsValid) {
            this.refs.fileUpload.value = '';
            this.props.toast('error', `${!this.props.isOOA ? 'Invalid file type - must be .pdf or .htm'
                    : 'Invalid file type - must be .pdf'}`, 'Error');
            return;
        }

        const data = new FormData();
        data.append('file', files[0]);

        axios.post(this.getUrl('post'), data)
        .then((response) => {
            this.props.addOrUpdateFile(response.data);
            this.refs.fileUpload.value = '';
            this.props.toast('success', 'File uploaded', '');
        })
        .catch((error) => {
            const message = error && error.response
                && error.response.data && error.response.data.message ?
                error.response.data.message : 'Could not upload file.';
            this.props.toast('error', message, 'Error');
            this.refs.fileUpload.value = '';
        });
    }

    handleDelete = (imageID) => {
        this.setState({
            fileToDelete: '',
            showConfirmDeleteModal: false
        });

        axios.delete(this.getUrl('delete', [imageID]))
        .then((response) => {
            this.setState({ imageData: null }, this.props.removeFile(imageID))
        })
        .catch((error) => {
            this.props.toast('error', 'Could not delete file', 'Error');
        });
    }

    handleUpdateFileType = (e, row) => {
        const updateVal = e.target.value;
        if (row.cdiAlertImageFileType !== updateVal) {
            axios.put(`/cdialerts/${this.props.alertWorkflowID}/files/${row.medicalRecordEntityID}?fileType=${updateVal}`)
            .then((response) => {
                row.cdiAlertImageFileType = updateVal;
                this.props.addOrUpdateFile(row);
            })
            .catch((error) => {
                this.props.toast('error',  'Could not update file type', 'Error');
            });
        }
    }

    getFileTypeLabel = (fileType) => {
        switch (fileType) {
            case 'C':
                return "Initial";
            case 'Q':
                return "Query Response";
            case 'A':
                return "Created Alert";
            case 'Y':
                return "Created Query";
            default:
                return '';
        }
    }

    isFileTypeEditable = (fileType) => {
        if (this.props.isViewOnly) {
            return false;
        }
        switch (fileType) {
            case 'C':
                return true;
            case 'Q':
                return true;
            default:
                return false;
        }
    }

    handleUploadTypeChange = (e) => {
        this.setState({ uploadType: e ? e.value : '' });
    }

    setSelectedImage = (filename, imageID) => {
        this.setState({ imageLoading: true });
        axios.get(this.getUrl('get', [imageID]), {
            responseType: 'arraybuffer'
        })
        .then((response) => {
            const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.lastIndexOf('.') + 4).toLowerCase();
            const mimeType = extension === 'pdf' ?  'application/pdf' : 'text/html';
            this.setState({
                selectedImageID: imageID,
                mimeType: mimeType,
                imageData: URL.createObjectURL(new Blob([response.data], { type: mimeType })),
                showImageError: false,
                imageLoading: false
            });
        })
        .catch((error) => {
            this.setState({
                showImageError: true,
                imageLoading: false
            });
        });
    }

    formatImageLink = (cell, row) => {
        return (
            <Button bsStyle="link" onClick={() => this.setSelectedImage(cell, row.imageID)}
                style={{ border: 'none', padding: '0px 0px' }}>{cell}</Button>
        );
    }

    formatFileType = (cell, row) => {
        return this.canModifyUploads() && this.isFileTypeEditable(cell) ?
            (
                <FormControl value={cell} componentClass="select" placeholder="select"
                    onChange={(e) => this.handleUpdateFileType(e, row)}>
                    {
                        this.state.uploadTypeOptions.map(x => {
                            return (<option key={x.value} value={x.value}>{x.label}</option>)
                        })
                    }
                </FormControl>
            ) : this.getFileTypeLabel(cell);
    }

    //ex. 6/15/2008 - 9:15:07 PM
    formatDateTime = (dateTime) => {
        return dateTime ? `${new Date(dateTime).toLocaleDateString('en-US')} -
            ${new Date(dateTime).toLocaleTimeString('en-US')}` : '';
    }

    handleConfirmDeleteToggle = (row) => {
        this.setState({
            fileToDelete: row ? row : '',
            showConfirmDeleteModal: !this.state.showConfirmDeleteModal
        });
    }

    formatImageActions = (cell, row) => {
        return this.canModifyUploads() || this.props.isOOA ? (
            (<div>
                <Button onClick={() => this.handleConfirmDeleteToggle(row)}>
                    <Glyphicon glyph="trash" />
                </Button>
            </div>)
        ) : '';
    }

    canModifyUploads = () => !this.props.isViewOnly && this.props.allowManagePDF;

    render() {
        return (
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle}
                className={!this.props.isOOA ? "modal-extra-wide" : "modal-medium-wide"} keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (this.props.isOOA || this.canModifyUploads()) &&
                        <Row style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                            {
                                !this.props.isOOA &&
                                <Col xs={3}>
                                    <FormGroup>
                                        <ControlLabel>Upload Type</ControlLabel>
                                        <Select value={this.state.uploadType} options={this.state.uploadTypeOptions}
                                            onChange={this.handleUploadTypeChange} />
                                    </FormGroup>
                                </Col>
                            }
                            <Col xs={9}>
                                <div ref={this.setFileDropEventListeners} className={!this.state.dragOverActive ?
                                    "dropbox" : "dropbox dropbox-dragover"} onClick={() => this.refs.fileUpload.click()}>
                                    <div className="message">
                                        <Glyphicon glyph="file" style={{ 'marginLeft': '0.66rem' }} />
                                        <h5><b>Choose a file</b> or drag it here (<b>.pdf</b> <span hidden={this.props.isOOA}>or <b>.htm</b></span>)</h5>
                                    </div>
                                    <input ref="fileUpload" type="file" onChange={this.handleUpload}
                                        name="Upload" style={{ paddingLeft: '3rem', visibility: 'hidden' }} />
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row style={{ marginTop: '1rem', paddingRight: '1rem', paddingLeft: '1rem' }}>
                        <BootstrapTable data={this.props.uploadedFiles} maxHeight='400px'>
                            <TableHeaderColumn dataField="imageID" isKey hidden />
                            <TableHeaderColumn dataField="fileName" width="40%" dataFormat={this.formatImageLink} dataSort>Filename</TableHeaderColumn>
                            <TableHeaderColumn dataField="providerID" dataSort hidden={this.props.isOOA}>Provider ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="cdiAlertImageFileType" hidden={this.props.isOOA} dataFormat={this.formatFileType} dataSort>Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="uploadDate" dataFormat={this.formatDateTime} dataSort>Uploaded On</TableHeaderColumn>
                            <TableHeaderColumn dataField="userName">Uploaded By</TableHeaderColumn>
                            <TableHeaderColumn dataField="" width="5%" dataFormat={this.formatImageActions} hidden={!this.canModifyUploads() && !this.props.isOOA}></TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                    <Row style={{ padding: '2rem 1rem 0 1rem' }}>
                        {
                            this.state.selectedImageID && this.props.uploadedFiles.find(x => x.imageID === this.state.selectedImageID) &&
                            <h5 style={{ marginLeft: '1rem' }}>{this.props.uploadedFiles.find(x => x.imageID === this.state.selectedImageID).fileName}</h5>
                        }
                        <Loader loaded={!this.state.imageLoading}>
                            {
                                !this.state.showImageError && this.state.imageData && this.state.mimeType === "text/html" &&
                                <embed width="100%" height={window.innerHeight - 35} src={this.state.imageData} type={this.state.mimeType} />
                            }
                            {
                                !this.state.showImageError && this.state.imageData && this.state.mimeType === "application/pdf" &&
                                <embed width="100%" height={window.innerHeight - 35} src={this.state.imageData} type={this.state.mimeType} />
                            }
                            {
                                this.state.showImageError &&
                                <Error style={{ marginTop: '5rem' }} />
                            }
                        </Loader>
                    </Row>
                    <AlertDialog visible={this.state.showConfirmDeleteModal} handleModalToggle={this.handleConfirmDeleteToggle}
                        title={"Delete this file?"} message={this.state.fileToDelete.fileName}
                        handleConfirm={() => this.handleDelete(this.state.fileToDelete.imageID)}
                        confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"} />
                </Modal.Body>
            </Modal>
        )
    }
}

export default ImageUploadModal;
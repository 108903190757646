import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import { formatDatetimeStringWithPrefixZero } from '../../../utils/DateUtils';
import NoData from '../../Shared/NoData';
import isEmpty from 'lodash/isEmpty';

class VitalSigns extends React.PureComponent {
    constructor(props) {
        super(props);

        this.columnHeaders = [
            ['DATE'],
            ['VITAL CODE'],
            ['VITAL SIGN'],
            ['VALUE', 'REF RANGE'],
            ['BODY SITE', 'NORMAL?'],
            ['RECORD LOC', 'CODING SYS']
        ]
    }

    formatMultidataCell = (value) => {
        if (!value || value.length === 0) {
            return '-';
        } else {
            return value;
        }
    }

    render() {
        
        return (
            <Panel id='vitalsigns'>
                <Panel.Body>
                    <div className='sectionHeader'>
                        Vital Signs
                    </div>
                    {
                        this.props.memberLoaded && !isEmpty(this.props.data) ?
                        <Table>
                        <thead>
                            <tr>
                                {
                                    this.columnHeaders.map((x, index) => {
                                        return (x.length > 1 ?
                                            <th className='rawTableColumnHeader' key={index}>
                                                <div>{x[0]}</div>
                                                <div>{x[1]}</div>
                                            </th>
                                            : <th className='rawTableColumnHeader' key={index}>{x[0]}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data.map((x, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='width10'>{formatDatetimeStringWithPrefixZero(x.vital_date)}</td>
                                            <td className='width20'>{x.vital_code}</td>
                                            <td className='width15'>{x.vital_name}</td>
                                            <td className='width20'>
                                                <div>{this.formatMultidataCell(`${x.value} ${x.unit}`)}</div>
                                                <div>{this.formatMultidataCell(x.reference_range)}</div>
                                            </td>
                                            <td className='width15'>
                                                <div>{this.formatMultidataCell(x.body_site)}</div>
                                                <div>{this.formatMultidataCell(x.normalcy_flag)}</div>
                                            </td>
                                            <td className='width20'>
                                                <div>{this.formatMultidataCell(x.record_location)}</div>
                                                <div>{this.formatMultidataCell(x.coding_system)}</div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table> : <NoData />
                    }                       
                </Panel.Body>
            </Panel>
        )
    }
}

export default VitalSigns;
import React from 'react';
import axios from 'axios';
import { Collapse } from 'react-bootstrap';
import OOAAlertResponseGroup from './OOAAlertResponseGroup';
import WorkflowSectionHeader from '../AlertWorkflow/WorkflowSectionHeader';

const OOAAlertResponseContainer = (props) => {

    const updateAlertResponse = (value, alertResponseID) => {
        if (value) {
            axios.put(`/ooacdialert/${alertResponseID}/AlertResponse`, {
                Response: value
            })                   
            .catch((error) => {
                props.toast('error', 'Could not save response.', 'Error');
            })
        }
    }

    const deleteAlertResponse = (alertResponseID) => {
        axios.delete(`/ooacdialert/${alertResponseID}/AlertResponse`)
        .catch((error) => {
            props.toast('error', 'Could not delete response.', 'Error');
        });
    }    

    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="response" title="Responses" />
            <Collapse in={props.expanded}>
                <div className="alert-workflow-alert-response-container">
                    {
                        props.alertResponseData.map(group => {
                            return (                                
                                <OOAAlertResponseGroup key={group.groupCode} 
                                    displayText={group.displayText} groupCode={group.groupCode} responseDetails={group.responseDetails}                                    
                                    isViewOnly={props.isViewOnly} updateAlertResponse={updateAlertResponse} deleteAlertResponse={deleteAlertResponse}
                                    refreshAlertResponse = {props.refreshAlertResponse} />
                            );
                        })
                    }
                </div>
            </Collapse>
        </div>
    );
};

export default OOAAlertResponseContainer;
import React from 'react';
import { Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Select from 'react-select';

const OOAReportsDropdown = ({ OOAreports, toast }) => {
    const handleSelectReport = (selectedReport) => {
        if (selectedReport && selectedReport.value) {
            switch (selectedReport.value) {
                case 16:
                    window.location.href = '/OOA/HostClosureTimeframes';
                    break;
                case 17:
                    window.location.href = '/OOA/HostRequestStatusHomePlan';
                    break;
                case 18:
                    window.location.href = '/OOA/HostRequestStatusType';
                    break;
                case 19:
                    window.location.href = '/OOA/HostedMembersHomePlan';
                    break;
                case 20:
                    window.location.href = '/OOA/MembersStateReport';
                    break;
                case 21:
                    window.location.href = '/OOA/RequestStatusHostPlan';
                    break;
                case 22:
                    window.location.href = '/OOA/RequestStatusType';
                    break;
                default:
                    if (toast && toast.toastFn) {
                        toast.toastFn('error', 'Could not navigate to selected report.', 'Error');
                    }
                    break;
            }
        }
    }

    const OOAReportsOptions = (reports) => {
        return reports.map(report => (
            {
                value: report.applicationReportID,
                label: report.applicationReportDesc
            }
        ))
    }

    return (
        <div style={{ marginTop: '2rem' }}>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>
                            Reports
            </ControlLabel>
                        <Select value=''
                            options={OOAReportsOptions(OOAreports.reports)}
                            onChange={handleSelectReport}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
};

export default OOAReportsDropdown;

import React from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal, Panel } from 'react-bootstrap';

class SubmitModal extends React.Component {
  constructor(props){
      super(props);
        this.state = {
            dataRecords: [],
            showModal: false,
            coversheetValue: "",
            submitDisabled: true
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            showModal: nextProps.showModal,
            coversheetValue: "",
            submitDisabled: true
        });
    }

    closeModal = () => {
        this.setState({ showModal: false });
        this.props.cancelModal();
    }

    //todo: validation is for 4-6 digits; message specifies 4 digits -- make this consistent
    updateCoversheetValue = (evt) => {
        this.setState({
            coversheetValue: evt.target.value,
            submitDisabled: evt.target.value.length < 4 || evt.target.value.length > 6
        });
    }

    render() {
        const modalBody = this.props.checked ?
        <div>
            <p>Are you sure you want to mark this coversheet as unsubmitted? Ensure that you have successfully deleted this 
                Coversheet for the selected enrollee HCC in CDAT. This action will delete the prior submission for this 
                Medical Record Coversheet. </p>
            <p>Select 'Yes' to remove the prior submission of this Medical Record Coversheet and delete the Coversheet ID.</p>
            <p> Select 'No' to return to the Enrollee Ranking Screen.</p>
        </div>
        :
        <div>
            <p>Ensure you have successfully submitted this DOS to CMS for the selected enrollee HCC, without file format issues.</p>
            <p>Enter the unique CMS assigned four digit Coversheet ID in the box below.</p>
        </div>

        const formVis = this.props.checked ? "none" : "block";
        const unsubmitBtnVis = this.props.checked ? "block" : "none";

        return (
            <div>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.checked ? "Unsubmit Record" : "Submit Record"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Panel style={{ width: '500px' }}>
                            {modalBody}
                        </Panel>
                        <Form style={{ display: formVis }}>
                            <FormGroup controlId="formControlsCoversheetID">
                                <ControlLabel style={{ fontSize: '12px' }}>CMS Coversheet ID</ControlLabel>
                                <FormControl type="text" ref={this.props.inputRef} value={this.state.coversheetValue} onChange={this.updateCoversheetValue}/>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} style={{float: "left", display: formVis}}>Cancel</Button>
                        <Button onClick={() => this.props.handleSubmitButton(this.state.coversheetValue)} style={{ float: "right", display: formVis }}
                            disabled={this.state.submitDisabled} className="btn btn-success">Submit</Button>
                        <Button onClick={this.closeModal} style={{ float: "left", display: unsubmitBtnVis }}>No</Button>
                        <Button onClick={this.props.handleUnsubmitButton} style={{ float: "right", display: unsubmitBtnVis }}
                            className="btn btn-success">Yes</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}   

export default SubmitModal;
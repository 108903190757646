import React from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, Checkbox, Row, FormGroup, Glyphicon } from 'react-bootstrap';
import PracticeInfoSearch from './PracticeInfoSearch';
import PracticeInfoEditModal from './PracticeInfoEditModal';
import { PracticeValidationFileType } from '../../../enums/PracticeValidationFileType';

class PracticeInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            practiceInfo: [],
            selectedRow: [],
            selected: [],
            createdRequestFileIDs: [],
            remoteProviderCount: 0,
            providers: [],
            practices: [],
            page: 1,
            pageSize: 10,
            sortBy: '',
            sortByDesc: false,
            loadingResults: false,
            enableSearch: true,
            massFaxAfterCreation: false,
            faxEnabled: false,
            searchParams: []
        }
    }

    componentWillMount() {
        this.getFaxEnabled();
    }

    getFaxEnabled = () => {
        axios.get('/securable/FaxEnabled')
        .then((response) => {
            this.setState({ faxEnabled: response.data });
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load fax permissions.', 'Error');
        });
    }

    getPracticeInformation = (queryParams) => {
        this.setState({
            searchParams: {...queryParams},
            loadingResults: true,
            practiceInfo: []
        });
        axios.get('/practice/PracticeValidations', { params: queryParams })
        .then(response => {
            this.setState({
                practiceInfo: response.data.items,
                remoteProviderCount: response.data.length,
                loadingResults: false,
            });
        })
        .catch(error => {
            this.setState({
                practiceInfo: [],
                remoteProviderCount: 0,
                loadingResults: false
            })
        });
    }

    createValidationSheetBundle = () => {
        if (this.state.selected.length > 0) {
            const practicesWithoutValidation = this.state.selected.filter(item => {
                return item === 0;
            });
            if (practicesWithoutValidation.length > 0) {
                this.props.toast('error', 'One of the selected practices does not have a Validation Status selected. Please select a Validation Status and save the Practice.', 'Error');
                return;
            }

            this.setState({ loadingResults: true });
            const selectedRequests = this.state.selected.map(item => {
                var faxAfterCreation = this.state.massFaxAfterCreation ? "true" : "false";
                return axios.post("/practice/PracticeValidations/"+ item +"/ValidationFiles?faxAfterCreation=" + faxAfterCreation);
            });

            Promise.all(selectedRequests)
            .then((response) => {
                response.forEach(result => {
                    const createdIDs = [];
                    response.forEach(result => {
                        createdIDs.push(result.data.practiceValidationFileID);
                    });
                    this.setState({
                        createdRequestFileIDs: [...this.state.createdRequestFileIDs, ...createdIDs],
                        loadingResults: false
                    });
                });
            })
            .catch((error) => {
                this.setState({ loadingResults: false });
                this.props.toast('error', 'Could not load created practice validation files.', 'Error');
            });
        }
        else {
            this.props.toast('error', 'Practice validation(s) must be selected.', 'Error');
        }
    }

    handleShowResponseModal = () => {
        this.setState({ showResponseSheetModal: !this.state.showResponseSheetModal });
    }

    formatDateString = (dateString) => {
        if (!dateString) {
            return 'N/A';
        }
        const date = new Date(dateString);
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }

    formatEdit = (cell, row) => {
        return (
            <PracticeInfoEditModal selectedRow={row} updateRow={this.updateRow} practiceInfo={this.state.practiceInfo}
                users={this.props.users} outboundTypes={this.props.outboundTypes} validationStatuses={this.props.validationStatuses}
                faxEnabled={this.state.faxEnabled} toast={this.props.toast} />
        );
    }

    formatFaxNumber = (cell, row) => {
        return row.faxNumber ? row.faxNumber : row.originalFax;
    }

    faxSortFunc = (a, b, sortOrder, sortCol) => {
        let faxA = a.faxNumber ? a.faxNumber : a.originalFax;
        let faxB = b.faxNumber ? b.faxNumber : b.originalFax;
        faxA = (faxA == null ? 0 : parseInt(this.replaceAll(faxA, '-', ''), 10));
        faxB = (faxB == null ? 0 : parseInt(this.replaceAll(faxB, '-', ''), 10));
        return (sortOrder === 'desc') ? (faxB - faxA) : (faxA - faxB);
    }

    updateRow = (row) => {
        const practiceInfo = [...this.state.practiceInfo];
        const index = practiceInfo.findIndex(item => item.groupingID === row.groupingID);
        if (index > -1) {
            practiceInfo[index] = row;
        }
        this.setState({ practiceInfo: practiceInfo });
    }

    formatValidationStatus = (validationStatusID, row) => {
        let desc = '';
        const status = this.props.validationStatuses.find(item => item.practiceValidationStatusID === parseInt(validationStatusID, 10));
        if (status) {
            if (row.isExpired) {
                desc = "Expired"
            }
            else {
                desc = status.statusDesc;
                if (row.lastFaxType) {
                    let typeDesc = Object.keys(PracticeValidationFileType).find(key => PracticeValidationFileType[key] === row.lastFaxType);
                    if (typeDesc === 'FollowUp') {
                        typeDesc = 'Follow-up';
                    }
                    desc = `${desc} - ${typeDesc}`;
                }
                if (row.lastFaxStatus === -100) {
                    desc += ' - Fax Failed'
                }
            }
        }
        return desc;
    }

    formatOutboundType = (outboundTypeID) => {
        const outboundType = this.props.outboundTypes.find(item => item.practiceOutboundTypeID === outboundTypeID);
        return outboundType ? outboundType.outboundMethod : '';
    }
    
    handleMassFaxChange = (e) => {
        this.setState({ massFaxAfterCreation: e.target.value });
    }

    onRowSelect = (row, isSelected, e) => {
        if (isSelected) {
            this.setState({
                selectedRow: row,
                selected: [...this.state.selected, row.practiceValidationID]
            });
        }
        else {
            this.setState({selected: this.state.selected.filter(it => it !== row.practiceValidationID)});
        }
    }

    onSelectAll = (isSelected, rows)  => {
        let selected = [];
        if (isSelected) {
            rows.forEach(row => {
                selected.push(row.practiceValidationID);
            });
        }
        this.setState({
            selected: selected
        });
    }

    formatAssignedPEC = (userID) => {
        if (userID > 0) {
            return this.props.users.filter(user => { return user.userID === userID; })[0].fullName;
        }
        else {
            return 'Unassigned';
        }
    }

    assignedPECSortFunc = (a, b, sortOrder, sortCol) => {
        const pecA = a.userID > 0 ? this.props.users.filter(user => { return user.userID === a.userID; })[0].fullName : 'Unassigned';
        const pecB = b.userID > 0 ? this.props.users.filter(user => { return user.userID === b.userID; })[0].fullName : 'Unassigned';
        return (sortOrder === 'desc') ? (pecB - pecA) : (pecA - pecB);
    }

    replaceAll = function(originalString, search, replace) {
        if (replace === undefined) {
            return originalString;
        }
        return originalString.replace(new RegExp('[' + search + ']', 'g'), replace);
    }

    render() {
        const practicesWithoutFax = [...this.state.practiceInfo].reduce((practicesWithoutFax, practice) => {
            if (!practice.faxNumber && !practice.originalFax) {
                practicesWithoutFax.push(practice.groupingID);
            }
            return practicesWithoutFax;
        }, []);

        const options = {
            page: this.state.page,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return {text: item.toString(), value: item}}),
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                });
            }
        }

        const selectRowProp = {
            mode: 'checkbox',
            clickToSelect: false,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            unselectable: practicesWithoutFax
        };

        return (
            <div style={{ marginTop: '2rem' }}>
                <PracticeInfoSearch router={this.props.router} providers={this.props.providers} practices={this.props.practices}
                    search={this.getPracticeInformation} outboundTypes={this.props.outboundTypes} validationStatuses={this.props.validationStatuses}
                    users={this.props.users} toast={this.props.toast} />
                <hr/>
                <Row style={{ marginBottom: '2rem' }}>
                    <FormGroup>
                        <Button style={{ marginLeft: '1.9rem' }} onClick={this.createValidationSheetBundle}>
                            Create New Validation Sheet
                        </Button>
                        <Checkbox onChange={this.handleMassFaxChange} disabled={!this.state.faxEnabled} style={{ 'display': 'inline', 'marginLeft': '4rem',
                            'verticalAlign': 'middle' }}>
                            Fax Newly Created Validation Sheets
                        </Checkbox>
                        <div style={{ display: this.state.loadingResults ? 'inline-block' : 'none', marginLeft: '1rem' }} >
                            Loading
                            <Glyphicon className="spinning" glyph="cog" style={{ fontSize: "22px", lineHeight: "14px", color: "#6c716c",
                                marginLeft: '0.7rem', verticalAlign: 'text-top' }} />
                        </div>
                    </FormGroup>
                </Row>
                <BootstrapTable data={this.state.practiceInfo} options={options} selectRow={ selectRowProp } maxHeight="400px" hover pagination>
                    <TableHeaderColumn dataField="edit" dataSort dataFormat={this.formatEdit} width='60px'></TableHeaderColumn>
                    <TableHeaderColumn dataField="outboundTypeID" dataSort dataFormat={this.formatOutboundType}>Communication Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerName" dataSort>Provider Org</TableHeaderColumn>
                    <TableHeaderColumn dataField="practiceName" dataSort>Practice Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="groupingID" isKey dataSort>Practice ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="faxNumber" dataFormat={this.formatFaxNumber} dataSort sortFunc={this.faxSortFunc}>Fax #</TableHeaderColumn>
                    <TableHeaderColumn dataField="validationStatusID" dataSort dataFormat={this.formatValidationStatus}>Validation Status</TableHeaderColumn>
                    <TableHeaderColumn dataField="expirationDate" dataSort dataFormat={this.formatDateString}>Expiration Date</TableHeaderColumn>
                    <TableHeaderColumn dataField="contactName" dataSort>POC Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="contactPhone" dataSort>POC Phone</TableHeaderColumn>
                    <TableHeaderColumn dataField="assignedPEC" dataFormat={this.formatAssignedPEC} dataSort>Assigned PEC</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default PracticeInfo;

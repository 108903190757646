import React from 'react';
import axios from 'axios';
import { Button, Col, Collapse, Glyphicon, Grid, Navbar, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import AlertToastr from '../../../Shared/AlertToastr';
import ContactInfo from './ContactInfo';
import ContactInfoTable from './ContactInfoTable';
import EMRInfo from './EMRInfo';
import RDPInfo from './RDPInfo';
import VPNInfo from './VPNInfo';
import AddReferenceItemModal from './AddReferenceItemModal';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';
import { getRedirect } from '../../../../services/ReviewProcessing';

export default class AccessFullView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactInfo: [],
            vpnInfo: {
                vpn: false,
                vpnSoftware: null,
                accessType: null,
                urlOrIP: '',
                domain: '',
                port: '',
                notes: ''
            },
            rdpInfo: {
                remoteDesktop: false,
                remoteSoftware: null,
                accessType: null,
                ipOrComputerName: '',
                domain: '',
                notes: ''
            },
            emrInfo: {
                emr: null,
                platform: null,
                accessType: null,
                url: '',
                mfa: false,
                notes: ''
            },
            practiceID:this.props.router.params.practiceID == 'undefined'?0 :this.props.router.params.practiceID,
            visible: 'show',
            toDisplay:'show',
            visibleReturn:'hidden',
            displayName: null,
            loaded: false,
            showAddRefItemModal: false,
            // note: keeping "viewonly" below (vs. "readonly") for consistency with the like-named alert workflow route:
            readOnly: this.props.location.pathname.toLowerCase().includes("viewonly"),
            emrReadOnly: this.props.location.pathname.toLowerCase().includes("emrview"),
            toast: null,
            // Types of Ref Items:
            vpnSoftwareOptions: [],
            remoteSoftwareOptions: [],
            emrOptions: [],
            emrPlatformOptions: [],
            accessTypeOptions: [],
            // AddReferenceItemModal fields:
            currAddRefItemTypeID: 0,
            currAddRefItemTypeDesc: '',
            currAddRefItemOptions: [],
        };
    }
        
    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentDidMount() {
        this.getEMRInstance();
        this.getEMRInstanceContacts();
        this.getReferenceItems();
        this.getDisplayName();
        this.toDisplay();
    }

    getEMRInstance = () => {
        let url = this.state.readOnly? `/campaignEMRInstance/${this.props.router.params.emrInstanceID}/${this.state.readOnly & 1}`
            : `/campaignEMRInstance/${this.props.router.params.emrInstanceID}`
            || this.state.emrReadOnly? `/campaignEMRInstance/${this.props.router.params.emrInstanceID}/${this.state.emrReadOnly & 1}`
            : `/campaignEMRInstance/${this.props.router.params.emrInstanceID}`;
        axios.get(url)
        .then((response) => {
            this.setState({
                vpnInfo: {
                    vpn: response.data.vpnAccess,
                    vpnSoftware: response.data.vpnSoftwareTypeID,
                    accessType: response.data.vpnCampaignEMRAccessTypeID,
                    urlOrIP: response.data.vpnip != null ? response.data.vpnip : '',
                    domain: response.data.vpnDomain != null ? response.data.vpnDomain : '',
                    port: response.data.vpnPort != null ? response.data.vpnPort : '',
                    notes: response.data.vpnNotes  != null ? response.data.vpnNotes : '',
                },
                rdpInfo: {
                    remoteDesktop: response.data.rdpAccess,
                    remoteSoftware: response.data.rdpSoftwareTypeID,
                    accessType: response.data.rdpCampaignEMRAccessTypeID,
                    ipOrComputerName: response.data.rdpip != null ? response.data.rdpip : '',
                    domain: response.data.rdpDomain != null ? response.data.rdpDomain : '',
                    notes: response.data.rdpNotes != null ? response.data.rdpNotes : '',
                },
                emrInfo: {
                    emr: response.data.campaignEMRID,
                    platform: response.data.emrPlatformTypeID,
                    accessType: response.data.emrCampaignEMRAccessTypeID,
                    url: response.data.emrUrl != null ? response.data.emrUrl : '',
                    mfa: response.data.mfa,
                    notes: response.data.emrNotes != null ? response.data.emrNotes : '',
                },
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else {
                this.state.readOnly || this.state.emrReadOnly? this.state.toast('error', 'There isn\'t any EMR Access for this record.', 'Error')
                : this.state.toast('error', 'Could not load EMR access data.', 'Error');
            }
        })
    }

    getEMRInstanceContacts = () => {
        axios.get(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/contacts`)
        .then((response) => {
            this.setState({
                contactInfo: [...response.data],
                loaded: true
            });
            this.redactContactInformation();
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (!this.state.readOnly || !this.state.emrReadOnly&& error.response.status !== 403) {
                this.state.toast('error', 'Could not load EMR contact data.', 'Error');
            }
        })
    }

    getDisplayName = () => {        
        if(this.state.practiceID === 0){
            this.state.visible = "hidden";
        }
        else{
            this.state.visible = "show";
        }
        axios.get(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/displayName`)
        .then((response) => {
            this.setState({
                displayName: [...response.data],               
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (!this.state.readOnly || !this.state.emrReadOnly&& error.response.status !== 403) {
                this.state.toast('error', 'Could not load Display Name.', 'Error');
            } 
        })
    }

    getReferenceItems = (typeID) => {
        const endpoint = typeID ? `/campaignEMRReferenceItem?typeID=${typeID}` : '/campaignEMRReferenceItem';
        return axios.get(endpoint)
            .then((response) => {
                this.setState({
                    vpnSoftwareOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.VPNSoftware) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.VPNSoftware).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.vpnSoftwareOptions,
                    remoteSoftwareOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.RDPSoftware) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.RDPSoftware).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.remoteSoftwareOptions,
                    emrOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRType) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRType).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.emrOptions,
                    emrPlatformOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRPlatform) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.EMRPlatform).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.emrPlatformOptions,
                    accessTypeOptions: response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.AccessType) ? response.data.find(e => e.campaignEMRReferenceItemTypeID === CampaignEMRReferenceItemType.AccessType).campaignEMRReferenceItems.map(x => { return { label: x.campaignEMRReferenceItemDescription, value: x.campaignEMRReferenceItemID } }) : this.state.accessTypeOptions,
                    loaded: true
                });
            })
            .catch(() => {
                this.setState({ loaded: true })
                if (error.response.status !== 403) {
                    this.state.toast('error', 'Could not load reference items.', 'Error');
                }
            })
    }

    redactContactInformation = () => {
        if (this.state.readOnly || this.state.emrReadOnly) {
            axios.get(`/campaignPracticeSummary/${this.props.router.params.emrInstanceID}/${this.state.practiceID}/isHistory`)
            .then((response) => {
                if (response.data) {
                    axios.get(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/${this.state.practiceID}/historyContacts`)
                    .then((response) => {
                        this.setState({
                            contactInfo: [...response.data],
                            loaded: true
                        });
                    })
                    .catch((error) => {
                        this.setState({ loaded: true });
                        this.state.toast('error', 'Could not load history EMR contact data.', 'Error');
                    });
                } else {
                    let redactedContactInfo = this.state.contactInfo.map(x => {
                        return {
                            name: "***",
                            title: "***",
                            phone: "***",
                            email: "***",
                            helpDeskPhone: x.helpDeskPhone,
                            notes: "***"
                        }
                    });
        
                    this.setState({
                        contactInfo: redactedContactInfo
                    }); 
                }
            })
        }
    }

    handleAddContact = (contact) => {
        axios.post(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/contacts`, {
            campaignEMRInstanceID: this.props.router.params.emrInstanceID,
            name: contact.name,
            title: contact.title,
            phone: contact.phone,
            email: contact.email,
            helpDeskPhone: contact.helpDeskPhone,
            notes: contact.notes
        })
        .then((response) => {
            this.setState({ contactInfo: [...this.state.contactInfo, response.data] });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not add contact', 'Error');
        })
    }

    handleRemoveContact = (contact) => {
        axios.delete(`/campaignEMRInstance/${this.props.router.params.emrInstanceID}/contacts/${contact.campaignEMRInstanceContactID}`)
        .then((response) => {
            this.setState({ contactInfo: [...this.state.contactInfo.filter(x => x !== contact )]});
        })
        .catch((error) => {
            this.state.toast('error', 'Could not remove contact.', 'Error');
        });
    }

    handleVPNInfoFieldChange = (updateObj) => {
        this.setState({ vpnInfo: { ...this.state.vpnInfo, ...updateObj }});
    }

    handleRDPInfoFieldChange = (updateObj) => {
        this.setState({ rdpInfo: { ...this.state.rdpInfo, ...updateObj } });
    }

    handleEMRInfoFieldChange = (updateObj) => {
        this.setState({ emrInfo: { ...this.state.emrInfo, ...updateObj } });
    }

    handleSaveButton = () => {
        this.setState({ loaded: false });

        const emrInstanceID = parseInt(this.props.router.params.emrInstanceID, 10);
        axios.put(`/campaignEMRInstance/${emrInstanceID}`, {
            CampaignPracticeIDToUpdate: this.state.practiceID,
            CampaignEMRInstanceID: emrInstanceID,
            CampaignEMRStatusID: 0,
            VPNAccess: this.state.vpnInfo.vpn,
            VPNSoftwareTypeID: this.state.vpnInfo.vpnSoftware ? this.state.vpnInfo.vpnSoftware : null,
            VPNCampaignEMRAccessTypeID: this.state.vpnInfo.accessType ? this.state.vpnInfo.accessType : null,
            VPNIP: this.state.vpnInfo.urlOrIP,
            VPNDomain: this.state.vpnInfo.domain,
            VPNPort: this.state.vpnInfo.port,
            VPNNotes: this.state.vpnInfo.notes,
            RDPAccess: this.state.rdpInfo.remoteDesktop,
            RDPSoftwareTypeID: this.state.rdpInfo.remoteSoftware ? this.state.rdpInfo.remoteSoftware : null,
            RDPCampaignEMRAccessTypeID: this.state.rdpInfo.accessType ? this.state.rdpInfo.accessType : null,
            RDPIP: this.state.rdpInfo.ipOrComputerName,
            RDPDomain: this.state.rdpInfo.domain,
            RDPNotes: this.state.rdpInfo.notes,
            CampaignEMRID: this.state.emrInfo.emr ? this.state.emrInfo.emr : null,
            EMRPlatformTypeID: this.state.emrInfo.platform ? this.state.emrInfo.platform : null,
            EMRCampaignEMRAccessTypeID: this.state.emrInfo.accessType ? this.state.emrInfo.accessType : null,
            EMRUrl: this.state.emrInfo.url,
            MFA: this.state.emrInfo.mfa,
            EMRNotes: this.state.emrInfo.notes
        })
        .then((response) => {
            this.setState({ loaded: true });
            this.state.toast('success', 'Saved successfully.', '');
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if(error.response.status === 404) {
                this.state.toast('error', 'Failed to save.', 'Error');
            } else {
                this.state.toast('error', error.response.data.message, '');
            }
        });
    }

    handleRefItemRefresh = (typeID) => {
        this.getReferenceItems(typeID)
            .then(() => this.handleAddRefItemModalOpen(typeID));
    }

    handleAddRefItemModalOpen = (typeID) => {
        let currTypeID = 0;
        let currTypeDesc = '';
        let currOptions = [];
        switch (typeID) {
            case CampaignEMRReferenceItemType.VPNSoftware:
                currTypeID = typeID;
                currTypeDesc = 'VPN Software';
                currOptions = this.state.vpnSoftwareOptions;
                break;
            case CampaignEMRReferenceItemType.RDPSoftware:
                currTypeID = typeID;
                currTypeDesc = 'RDP Software';
                currOptions = this.state.remoteSoftwareOptions;
                break;
            case CampaignEMRReferenceItemType.EMRType:
                currTypeID = typeID;
                currTypeDesc = 'EMR';
                currOptions = this.state.emrOptions;
                break;
            case CampaignEMRReferenceItemType.EMRPlatform:
                currTypeID = typeID;
                currTypeDesc = 'EMR Platform';
                currOptions = this.state.emrPlatformOptions;
                break;
            case CampaignEMRReferenceItemType.AccessType:
                currTypeID = typeID;
                currTypeDesc = 'Access Type';
                currOptions = this.state.accessTypeOptions;
                break;
            default:
                break;
        }
        this.setState({ 
            showAddRefItemModal: true,
            currAddRefItemTypeID: currTypeID,
            currAddRefItemTypeDesc: currTypeDesc,
            currAddRefItemOptions: currOptions,
        });
    }

    handleAddRefItemModalClose = () => {
        this.setState({ showAddRefItemModal: false });
    }
    
    handleCredentialsButton = () => {
        if(this.state.readOnly || this.state.emrReadOnly)
        {
            this.props.router.push(`/EMR/Credentials/${this.props.router.params.emrInstanceID}/viewonly`);
        }
        else{
            this.props.router.push(`/EMR/Credentials/${this.props.router.params.emrInstanceID}`);
        }
    }

    isSaveDisabled = (isFinalValidation = true) => {
        const vpnValid = Boolean(this.state.vpnInfo.vpnSoftware) && Boolean(this.state.vpnInfo.accessType)
            && this.state.vpnInfo.urlOrIP;
        const rdpValid = Boolean(this.state.rdpInfo.accessType) && this.state.rdpInfo.ipOrComputerName;
        const emrValid = isFinalValidation ? (Boolean(this.state.emrInfo.emr) && Boolean(this.state.emrInfo.platform)
            && Boolean(this.state.emrInfo.accessType) && this.state.emrInfo.mfa !== null) : true;

        if (this.state.vpnInfo.vpn && this.state.rdpInfo.remoteDesktop) {
            return !(vpnValid && rdpValid && emrValid);
        }
        else if (this.state.vpnInfo.vpn) {
            return !(vpnValid && emrValid);
        }
        else if (this.state.rdpInfo.remoteDesktop) {
            return !(rdpValid && emrValid);
        }
        else {
            return !emrValid;
        }
    }


    handlePracticeButtonClick = () =>{
        if(this.state.practiceID === 0){
            return;
        }
        this.props.router.push(`/EMR/practices/${this.props.router.params.practiceID}`);

    }

    handleEMRBackClick = () => {
        this.props.router.push(`/EMR/practices/`);
    }

    toDisplay = () => {
        if (this.state.readOnly)
        {
            this.state.toDisplay = 'hidden';
            this.state.visibleReturn = 'show';
        }
    }

    render() {
        return (
            <Grid fluid style={{ marginBottom: '4rem' }}>
                <Loader loaded={this.state.loaded}>
                    <Row>
                        <Col xs={9} style={{ paddingLeft: '0rem' }}>
                            <h3 style={{ display: 'inline-block' }}>EMR Access - {this.state.displayName}</h3>
                        </Col>
                        
                        <Col xs={3} style={{ paddingTop: '0.6rem' }}>
                            <div style={{ float: 'right' }}>
                                <Button onClick={this.handleEMRBackClick} style={{ marginRight: '1rem', visibility:this.state.toDisplay}}>
                                    EMR Summary
                                </Button>

                                <Button style={{visibility:this.state.visible, visibility:this.state.toDisplay}} id="practiceIDButton" onClick={this.handlePracticeButtonClick}>
                                    EMR Practice
                                </Button> 

                            </div>
                        </Col>

                    </Row>
                    
                    <Row style={{ marginBottom: '1rem' }}>
                        <hr style={{ marginTop: '0rem' }} />
                    </Row>

                    <Row style={{ marginBottom: '1rem' }}>
                        <Col xs={9}>
                            <h3 style={{ marginBottom: '1rem', marginTop: '0rem' }}>Contact Info</h3>                            
                        </Col>
                        <Col xs={3} >                            
                            <Button onClick={this.handleCredentialsButton} style={{float: 'right' }}>
                                    Credentials
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '1rem' }}>    
                    <hr />                     
                        <ContactInfo contactInfo={this.state.contactInfo} handleAddContact={this.handleAddContact} readOnly={this.state.readOnly  || this.state.emrReadOnly} />
                        <ContactInfoTable contactInfo={this.state.contactInfo} handleRemoveContact={this.handleRemoveContact} readOnly={this.state.readOnly  || this.state.emrReadOnly} />
                    </Row>

                    <Row style={{ marginBottom: '1rem' }}>
                        <h3>VPN Info</h3>
                        <hr />
                        <VPNInfo vpnInfo={this.state.vpnInfo} handleWorkflowFieldChange={this.handleVPNInfoFieldChange} handleAddRefItemModalOpen={this.handleAddRefItemModalOpen}
                            vpnSoftwareOptions={this.state.vpnSoftwareOptions} accessTypeOptions={this.state.accessTypeOptions} readOnly={this.state.readOnly  || this.state.emrReadOnly} />
                    </Row>
                    <Row style={{ marginBottom: '1rem' }}>
                        <h3>RDP Info</h3>
                        <hr />
                        <RDPInfo rdpInfo={this.state.rdpInfo} handleWorkflowFieldChange={this.handleRDPInfoFieldChange} handleAddRefItemModalOpen={this.handleAddRefItemModalOpen}
                            remoteSoftwareOptions={this.state.remoteSoftwareOptions} accessTypeOptions={this.state.accessTypeOptions} readOnly={this.state.readOnly  || this.state.emrReadOnly} />
                    </Row>
                    <Row style={{ marginBottom: '5rem' }}>
                        <h3>EMR Info</h3>
                        <hr />
                        <EMRInfo emrInfo={this.state.emrInfo} handleWorkflowFieldChange={this.handleEMRInfoFieldChange} handleAddRefItemModalOpen={this.handleAddRefItemModalOpen}
                            emrOptions={this.state.emrOptions} emrPlatformOptions={this.state.emrPlatformOptions} accessTypeOptions={this.state.accessTypeOptions} readOnly={this.state.readOnly  || this.state.emrReadOnly}                                 
                            />
                    </Row>
                    {
                        !(this.state.readOnly  || this.state.emrReadOnly) &&
                        <Navbar fixedBottom fluid>
                            <Button onClick={this.handleSaveButton} disabled={this.isSaveDisabled()} bsStyle="primary" style={{ float: 'right', marginTop: '0.7rem' }}>
                                <Glyphicon glyph="floppy-disk" style={{ marginRight : '0.6rem' }} />Save
                            </Button>
                        </Navbar>
                    }
                </Loader>
                <AddReferenceItemModal visible={this.state.showAddRefItemModal} refTypeID={this.state.currAddRefItemTypeID} refTypeDesc={this.state.currAddRefItemTypeDesc} 
                    options={this.state.currAddRefItemOptions} handleModalClose={this.handleAddRefItemModalClose} handleRefItemRefresh={this.handleRefItemRefresh} 
                    toast={this.state.toast} />
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        );
    }
}
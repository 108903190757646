import React from 'react';
import { Col, Collapse, Glyphicon, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ClaimMatchTypeDescription } from '../../../enums/ClaimMatchTypeDescription';
import { ClaimsSummaryRankDescription } from '../../../enums/ClaimsSummaryRankDescription';

export default class RankContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    toggleExpansion = () => {
        this.setState((prevState) => { return { expanded: !prevState.expanded }});
    }

    render() {
        return (
            <div className="alert-workflow-subcontainer">
                <div onClick={this.toggleExpansion} style={{ cursor: 'pointer' }}>
                    <h4>
                        {`Rank ${this.props.data.claimMatchTypeID} - ${ClaimMatchTypeDescription[this.props.data.claimMatchTypeID]}
                         (${this.props.data.count} Claims)`}
                        <Glyphicon glyph={this.state.expanded ? "minus" : "plus"}
                            style={{ color: 'rgb(108, 113, 108)', float: 'right', top: '2px' }} />
                    </h4>
                    <hr />
                </div>
                <Collapse in={this.state.expanded}>
                    <div className="alert-workflow-alert-response-container">
                        {
                            <div style={{ marginBottom: '2rem' }}>
                                <Row>
                                    <h5 style={{ marginLeft: '2rem' }}>Ranking criteria:</h5>
                                    <ul>
                                        {
                                            ClaimsSummaryRankDescription[this.props.data.claimMatchTypeID].map(x => {
                                                return <li>{x}</li>
                                            })
                                        }
                                    </ul>
                                </Row>
                                {
                                    this.props.data.rankingDetails.length > 0 &&
                                    <Row>
                                        <Col xs={6}>
                                            <BootstrapTable data={this.props.data.rankingDetails}>
                                                <TableHeaderColumn dataField="claimsRankingDetailDescription" isKey dataSort>Description</TableHeaderColumn>
                                                <TableHeaderColumn dataField="count" dataSort>Count</TableHeaderColumn>
                                            </BootstrapTable>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
        )
    }
}
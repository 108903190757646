import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import { PracticeValidationStatus } from '../../../enums/PracticeValidationStatus';

class PracticeInfoSearch extends React.Component {
    constructor(props) {
        super(props);
        this.defaultSearchParams = {
            outboundTypeIDs: [],
            validationStatuses: [],
            lastFaxTypes: [],
            faxStatus: null,
            providerOrgID: '',
            practiceID: '',
            expirationDateMin: '',
            expirationDateMax: '',
            assignedPEC: ''
        }

        this.state = {
            practiceSearchResults: [],
            searchParams: {...this.defaultSearchParams},
            assignedPECOptions: [
                { value: 0, label: "Unassigned" },
                ...this.props.users.map((item) => { return { value: item.userID, label: item.fullName }; })
            ],
            faxStatusOptions: [],
            isFaxStatusSelectable: false
        }
    }

    componentWillMount() {
        this.getFaxStatusOptions();
    }

    handleProviderChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{providerOrgID: e.target.value},
                ...{practiceID: ''}
            }
        });
    }

    handlePracticeChange = (e) => {
        if (e.target) {
            this.setState({ 
                searchParams: {
                    ...this.state.searchParams,
                    ...{practiceID: e.target.value}
                }
            });
        }
        else {
            const foundPractice = {
                groupingID: e.groupingID,
                description: e.description
            };
            this.setState({ 
                practiceSearchResults: [...this.state.practiceSearchResults, foundPractice],
                searchParams: {
                    ...this.state.searchParams,
                    ...{practiceID: e.groupingID}
                }
            });
        }
    }

    handleDateChange = (statePropertyName) => (dateValue) => {
        if ((statePropertyName === "expirationDateMax") && dateValue !== null){
            dateValue = new Date((new Date(dateValue)).setHours(0,59,59,0)).toISOString();
        }
        else if ((statePropertyName === "expirationDateMin") && dateValue !== null){
            dateValue = new Date((new Date(dateValue)).setHours(0,0,0,0)).toISOString();
        }
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{[statePropertyName]: dateValue}
            }
        });
    }

    handleOutboundTypeChange = (options) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{outboundTypeIDs: options.map(item => item.value)}
        }});
    }

    handleCheckboxChange = (statePropertyName) => (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{[statePropertyName]: e.target.checked}
        }});
    }

    handleStatusChange = (options) => {
        const expired = options.filter(item => { return item.value === 7 });
        if (expired.length > 0) {
            this.setState({
                searchParams: {
                    ...this.state.searchParams,
                    ...{ validationStatuses: options.map(item => item.value) },
                    ...{ expirationDateMin: null },
                    ...{ expirationDateMax: null },
                    faxStatus: null
                },
                isFaxStatusSelectable: false
            });
        }
        else {
            const isFaxStatusSelectable = options.length === 1 && options[0].value === PracticeValidationStatus.PendingValidation;
            this.setState({
                searchParams: {
                    ...this.state.searchParams,
                    ...{ validationStatuses: options.map(item => item.value) },
                    faxStatus: isFaxStatusSelectable ? this.state.searchParams.faxStatus : null
                },
                isFaxStatusSelectable: isFaxStatusSelectable
            });
        }
    }

    search = () => {
        this.props.search({
            ...this.state.searchParams,
            ...{ lastFaxStatuses: this.state.searchParams.faxStatus !== null ? [ this.state.searchParams.faxStatus ] : null }
        });
    }

    clearSearchParams = () => {
        this.setState({ searchParams: {...this.defaultSearchParams} },
            () => this.props.search(this.state.searchParams)
        );
    }

    getValidationStatusOptions = () => {
        let validationStatuses = this.props.validationStatuses ?
            this.props.validationStatuses.map(item => {
                return {
                    value: item.practiceValidationStatusID,
                    label: item.statusDesc,
                }
            })
            .concat([
                { value: 7, label: 'Expired' }
            ]) : [];

        return validationStatuses;
    }

    getFaxStatusOptions = () => {
        axios.get('/faxrequests/faxstatuses')
        .then(response => {
            const faxStatusOptions = response.data.map(item => {
                return {
                    value: item.faxStatusID,
                    label: item.faxStatusDesc
                }
            });
            this.setState({faxStatusOptions: faxStatusOptions});
        })
        .catch((error) => {
            this.props.toast('error', 'Could not load fax status data.', 'Error');
        });
    }

    handleFaxStatusChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{ faxStatus: e !== null ? e.value : null }
            }
        });
    }

    handleAssignedPECChange = (e) => {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...{ assignedPEC: e.target.value }
            }
        });
    }

    render() {
        //add practices from practice search to options; filter options via provider org selection
        const practices = this.state.searchParams.providerOrgID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.searchParams.providerOrgID ? practice : false;
            }).concat(this.state.practiceSearchResults)
            : this.props.practices.concat(this.state.practiceSearchResults);

        const outboundTypes = this.props.outboundTypes ?
            this.props.outboundTypes.map(item => {
                return {
                    value: item.practiceOutboundTypeID,
                    label: item.outboundMethod,
                }
            }) : [];

        const validationStatusOptions = this.getValidationStatusOptions();

        return (
            <Grid fluid>
                <Row style={{marginBottom: '1.4rem', paddingLeft: '13px'}}>
                    <h4>Practice Search</h4>
                </Row>
                <Row>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Provider Org</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.providerOrgID} onChange={this.handleProviderChange}>
                                <option value="">Select</option>
                                {
                                    this.props.providers.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Practice</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.practiceID} onChange={this.handlePracticeChange} style={{marginRight: '0.2rem'}}>
                                <option value="">Select</option>
                                {
                                    practices.map((item) => {
                                        return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Type</ControlLabel>
                            <Select
                                multi
                                value={this.state.searchParams.outboundTypeIDs}
                                options={outboundTypes}
                                autosize={true}
                                onChange={this.handleOutboundTypeChange}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{marginBottom: '2rem'}}>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Status</ControlLabel>
                            <Select
                                multi
                                value={this.state.searchParams.validationStatuses}
                                options={validationStatusOptions}
                                autosize
                                onChange={this.handleStatusChange} />
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <Col sm={6} style={{paddingLeft: 0}}>
                            <FormGroup>
                                <ControlLabel>Fax Status</ControlLabel>
                                <Select
                                    value={this.state.searchParams.faxStatus}
                                    options={this.state.faxStatusOptions}
                                    onChange={this.handleFaxStatusChange}
                                    disabled={!this.state.isFaxStatusSelectable} />
                            </FormGroup>
                        </Col>
                        <Col sm={6} style={{paddingRight: 0}}>
                            <FormGroup>
                                <ControlLabel>Assigned PEC</ControlLabel>
                                <FormControl componentClass="select" placeholder="Select" value={this.state.searchParams.assignedPEC} onChange={this.handleAssignedPECChange}>
                                    <option value="">Select</option>
                                    {
                                        this.state.assignedPECOptions.map((item) => {
                                            return <option key={item.value} value={item.value}>{item.label}</option>
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                        </Col>
                    </Col>
                    <Col sm={3}>
                        <Col sm={6} style={{paddingLeft: 0}}>
                            <FormGroup>
                                <ControlLabel>Expiration Date</ControlLabel>
                                <DatePicker value={this.state.searchParams.expirationDateMin} onChange={this.handleDateChange('expirationDateMin')} style={{zIndex: 'auto'}}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6} style={{paddingRight: 0}}>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <FormGroup>
                                <DatePicker value={this.state.searchParams.expirationDateMax} onChange={this.handleDateChange('expirationDateMax')} style={{zIndex: 'auto'}}/>
                            </FormGroup>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Button style={{marginRight: '2rem'}} onClick={this.search}>
                            <Glyphicon glyph="search" style={{marginRight: '0.2rem'}} /> Search
                        </Button>
                        <Button onClick={this.clearSearchParams}>
                            Clear Search
                        </Button>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default PracticeInfoSearch;
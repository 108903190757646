import React, {useState} from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row,Modal} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Select from 'react-select';
import { MedicalRecordReviewStatus } from '../../../enums/MedicalRecordReviewStatus';
import { MedicalRecordReviewCoderQAStatus } from '../../../enums/MedicalRecordReviewCoderQAStatus';
import { reactBootstrapTableCustomSort } from '../../../utils/SortUtils';
import { ProjectInventoryType } from '../../../enums/ProjectInventoryType';
import AlertDialog from '../../Shared/AlertDialog';

class Incomplete extends React.Component {
    
    constructor(props) {
        super(props);
            this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
            
            this.state = {
            selectedUser: this.props.currentUser.userID,
            riskUri : this.props.riskMitigationSite,
            planMemberID: '',
            medicalRecordReviewID: '',
            searchResults: [],
            selectedRows: [], // Array to store selected row keys
            selectedCheckboxes: 0, // State to track selected checkboxes
            showConfirmCancelModal: false,
            commaSeparatedValues: this.props.commaSeparatedValue, // State to store the props from ReviewSearchContainer
            hasAuthorizedRole: false, // State to store the result of the role check
            modalStatus: false,
            modalMessage: ''
        };
    } 

    componentDidMount() {
        this.checkForAuthorizedRoles();
        this.searchIncompleteReviews();
    }

   checkForAuthorizedRoles = () => {
        const { commaSeparatedValues } = this.state;      
        const rolesArray = commaSeparatedValues.split(',').map(role => role.trim()); // Split and trim the roles
        const authorizedRoles = this.props.currentUser.authorizedMRRApplicationRoles.map(authRole => authRole.roleDescription); // Extract roles from authorizedRoles
        const hasAuthorizedRole = authorizedRoles.some(role => rolesArray.includes(role));
        this.setState({ hasAuthorizedRole });     
       };
    
handleRowSelect = (row, isSelected) => {
    console.log(row, isSelected);  
    this.setState(prevState => {  
     // Determine the new selectedRows and selectedCheckboxes  
     const newSelectedRows = isSelected ? [...prevState.selectedRows, row.medicalRecordReviewID]  
      : prevState.selectedRows.filter(id => id !== row.medicalRecordReviewID);
  
     const newSelectedCheckboxes = isSelected ? prevState.selectedCheckboxes + 1  
      : prevState.selectedCheckboxes - 1;
  
     // Filter out rows where IsOverTwentyHour is false  
     const filteredSelectedRows = newSelectedRows.filter(id => {  
      const selectedRow = this.findRowById(id);  
      return selectedRow && selectedRow.isOverTwentyHour;  
     });  
     const filteredSelectedCheckboxes = filteredSelectedRows.length;  
     return {  
      selectedRows: filteredSelectedRows,  
      selectedCheckboxes: filteredSelectedCheckboxes  
     };  
    });  
    console.log(this.state , "state check");  
   };

   findRowById = (id) => {
    return this.state.searchResults.find(row => row.medicalRecordReviewID === id);  
   };  

handleSelectAll = (isSelected, rows) => {
    if (isSelected) {   
     // Filter out rows where IsOverTwentyHour is false
   
     const newSelectedRows = rows.filter(row => row.isOverTwentyHour).map(row => row.medicalRecordReviewID);   
     this.setState({   
      selectedRows: newSelectedRows,
      selectedCheckboxes: newSelectedRows.length   
     });   
    } else {   
     this.setState({   
      selectedRows: [],   
      selectedCheckboxes: 0   
     });   
    }   
   };

    toggleConfirmCancelModal = () => {
        this.setState({ showConfirmCancelModal: !this.state.showConfirmCancelModal });
    }

    closeModal = () => {
        this.setState({modalStatus: false});
    }

    deleteReview = (reviewId) => {
       return axios.put(`/CancelReview`, {
            reviewId: reviewId,
            cancellationUserId: this.props.currentUser.userID
        });
    }

   cancelReviews = () => {
        const selectedRows= this.state.selectedRows;
        const deleteReviews = selectedRows.map((reviewId) => {
                return this.deleteReview(reviewId);
        });

        Promise.all(deleteReviews)
        .then((response) => {
        let errorReview=[];
        response.map( item=>{
            if(item.data.success == false)
                errorReview = [...errorReview,item.data.reviewId];
        });

        this.setState({modalStatus: true});

         if(errorReview.length>0)
            this.setState({modalMessage: `Could not perform cancel for the review(s)  ${errorReview.join(',')}`});
         else
            this.setState({modalMessage: `Review(s) cancelled successfully.`});

         this.searchIncompleteReviews();
        })
        .catch((error) => {
            this.props.toast('error', error && error.response && error.response.data && error.response.data.message
            ? `Could not cancel review. ${error.response.data.message}` : 'Could not cancel review.', 'Error');
        });
        this.toggleConfirmCancelModal();
        this.setState({selectedRows:[]});
        this.setState({selectedCheckboxes:0});
    }

    searchIncompleteReviews = () => {
        axios.get(`/medicalrecordreview/incompleteReviews?lineOfBusiness=${localStorage.getItem('lineOfBusiness')}`, {
            params: {
                selectedUserID: this.state.selectedUser,
                medicalRecordReviewID: this.state.medicalRecordReviewID,
                memberID: this.state.planMemberID
            }
        })
        .then((response) => {
            this.setState({
                searchResults: response.data
            });
        })
        .catch((error) => {
            this.props.toast('error', error && error.response && error.response.data && error.response.data.message
                ? `Could not start review. ${error.response.data.message}` : 'Could not start review.', 'Error');
        });
    }

    getUserDropdownOptions = (users) => {
        return users.map((x) => {
            return {
                label: x.fullName,
                value: x.userID
            };
        });
    }

    handleUserChange = (e) => {
        this.setState({ selectedUser: e ? e.value : '' })
		this.setState({selectedRows:[]});
        this.setState({selectedCheckboxes:0});
    }

    handleMemberIDChange = (e) => {
        this.setState({ planMemberID: e.target.value })
    }

    handleMedicalRecordReviewIDChange = (e) => {
        const mrrID = e.target.value ? parseInt(e.target.value, 10) : '';
        this.setState({ medicalRecordReviewID: Number.isInteger(mrrID) ? mrrID : '' });
    }

    formatMemberID = (cell) => {
        return cell ? cell.planMemberID : '';
    }

    formatMemberName = (cell, row) => {
        return row && row.member ? `${row.member.firstName} ${row.member.lastName}` : '';
    }

    formatProject = (cell) => {
        return cell ? cell.projectName : '';
    }

    formatRecordTypeReviewTypeReviewStatus = (cell) => {
        return cell ? cell.description : '';
    }

    formatDate = (cell) => {
        return cell ? cell.split('T')[0] : '';
    }
    
    redirectToReview = (reviewType, medicalRecordReviewID, recordType, coderQAQueueID = null) => {
        //if Record Type is Scan and NLP process is enabled for the client and if the new NLP screen is enabled
        if(recordType.toLowerCase() === 'scan' && this.props.nlpProcessEnabledValue === 'true' && this.props.newNLPScreenEnabled === 'true')
        {
            console.log("check review for Nlp Insights data");
            axios.get(`/mrrnlpapi/v1/ReviewHasInsightsData/${medicalRecordReviewID}`)
            .then((response) => {
                if(response.data && ((this.lineOfBusiness === 'MA' && this.props.maLobNlpProcessEnabled === 'true') || (this.lineOfBusiness === 'Commercial' && this.props.commLobNlpProcessEnabled === 'true')))
                {
                    if(!coderQAQueueID)
                        window.location.href = `/NLP/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
                    else
                        window.location.href = `/NLP/CoderQA?medicalRecordReviewID=${medicalRecordReviewID}&coderQAQueueID=${coderQAQueueID}`;

                }
                else
                {
                    if(!coderQAQueueID)
                            window.location.href = `/ReviewProcessing/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
                    else
                        window.location.href = `/ReviewProcessing/CoderQA?medicalRecordReviewID=${medicalRecordReviewID}&coderQAQueueID=${coderQAQueueID}`;
                }
                    
            }).catch((error) => {
                console.log('CompletedCoding screen error' + error);
                this.props.toast('error', "Could not view CompletedCoding Review.", 'Error');
            });    
        }
        else{
            if(!coderQAQueueID)
                window.location.href = `/ReviewProcessing/${reviewType}?medicalRecordReviewID=${medicalRecordReviewID}`;
            else
                window.location.href = `/ReviewProcessing/CoderQA?medicalRecordReviewID=${medicalRecordReviewID}&coderQAQueueID=${coderQAQueueID}`;
        }
            
    }

    redirectToRiskReview = (medicalRecordReviewID,projectId) => {
        window.location.href = encodeURI(`${this.props.riskMitigationSite}/review/?userId=${this.state.selectedUser}&mrReview=${medicalRecordReviewID}&projectId=${projectId}&id=${localStorage.token}`);
    }
    
    //todo: add icons to buttons
    formatActions = (cell, row) => {
        
        if (this.state.selectedUser && this.state.selectedUser.value && this.state.selectedUser.value !== this.props.currentUser.userID) {
            // read-only - review doesn't belong to the current user
            return (
                <Button onClick={() => this.redirectToReview('CompletedCoding', row.medicalRecordReviewID, row.recordType.description)} disabled={row.isEscalated}>
                    View
                </Button>
            )
        }
        else {
            if (row.reviewStatus.id) {
                if (row.activeCoderQAQueue && row.activeCoderQAQueue.medicalRecordReviewCoderQAStatusID === MedicalRecordReviewCoderQAStatus.InProgress) {
                    return (
                        <Button onClick={() => this.redirectToReview('CoderQA', row.medicalRecordReviewID, row.recordType.description, row.activeCoderQAQueue.coderQAQueueID)} disabled={row.isEscalated}>
                            Resume Coder QA
                        </Button>
                    );
                }
                else {
                    switch (row.reviewStatus.id) {                      
                        case MedicalRecordReviewStatus.ReviewInProgress:
                                if(row.project.projectRetrieveTypeID === ProjectInventoryType.RMVendor)
                                {
                                    return (
                                        <Button onClick={() => this.redirectToRiskReview(row.medicalRecordReviewID, row.project.projectID)} disabled={row.isEscalated}>
                                            Resume Risk Review
                                        </Button>
                                    );      
                                }
                                else{
                                    return (
                                        <Button onClick={() => this.redirectToReview('InitialCoding', row.medicalRecordReviewID, row.recordType.description)} disabled={row.isEscalated}>
                                            Resume Review
                                        </Button>
                                    );
                                }

                        case MedicalRecordReviewStatus.QAInProgress:
                                return (
                                     <Button onClick={() => this.redirectToReview('QA', row.medicalRecordReviewID, row.recordType.description)} disabled={row.isEscalated}>
                                        Resume QA
                                    </Button>
                        );
                            
                        case MedicalRecordReviewStatus.MDQAInProgress:
                            return (
                                <Button onClick={() => this.redirectToReview('MD', row.medicalRecordReviewID, row.recordType.description)} disabled={row.isEscalated}>
                                    Resume MD
                                </Button>
                            );
                        default:
                            return '';
                    }
                }
            }
        }
    }

    customSort = (a, b, order, field) => {
        let sortProp = "";
        switch (field) {
            case "member":
                sortProp = "planMemberID";
                a = a[field][sortProp];
                b = b[field][sortProp];
                break;
            case "memberName":
                a = this.formatMemberName(null, a);
                b = this.formatMemberName(null, b);
                break;
            case "project":
                sortProp = "projectName";
                a = a[field][sortProp];
                b = b[field][sortProp];
                break;
            case "recordType":
                sortProp = "description";
                a = a[field][sortProp];
                b = b[field][sortProp];
                break;
            case "reviewType":
                sortProp = "description";
                a = a[field][sortProp];
                b = b[field][sortProp];
                break;
            case "reviewStatus":
                sortProp = "description";
                a = a["reviewStatus"][sortProp];
                b = b["reviewStatus"][sortProp];
                break;
            default:
                a = "";
                b = "";
        }

        return reactBootstrapTableCustomSort(a, b, order, field);
    }

    render() {
        const options = {
            page: this.props.page,
            sizePerPage: this.props.sizePerPage,
            onPageChange: this.props.onPageChange
        };

        const selectRowProp = {
            mode: 'checkbox',       
            clickToSelect: true,       
            selected: this.state.selectedRows, 
            onSelect: this.handleRowSelect,    
            onSelectAll: this.handleSelectAll      
        }; 

        return (
            <div style={{ marginTop: '2rem' }}>
                <Row style={{ marginBottom: '1.6rem' }}>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel>
                                User
                            </ControlLabel>
                            <Select value={this.state.selectedUser ? this.state.selectedUser : ''} options={this.getUserDropdownOptions(this.props.users)}
                                searchable={true} onChange={this.handleUserChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={2}>    
                        <FormGroup>
                            <ControlLabel>
                                MRR ID
                            </ControlLabel>
                            <div>
                                <FormControl type="text" value={this.state.medicalRecordReviewID}
                                    onChange={this.handleMedicalRecordReviewIDChange} />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <ControlLabel>
                                Member ID
                            </ControlLabel>
                            <div>
                                <FormControl type="text" value={this.state.planMemberID} onChange={this.handleMemberIDChange} />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <Button onClick={this.searchIncompleteReviews} style={{ marginTop: '2.5rem' }}>
                            <Glyphicon glyph="search" style={{ marginRight: '0.2rem'}} /> Search
                        </Button>
                        <Button onClick={()=>this.toggleConfirmCancelModal(true)} 
                        disabled={this.state.selectedCheckboxes<=0 || !this.state.hasAuthorizedRole} 
                        style={{ marginTop: '2.5rem',marginLeft: '0.9rem' }}>
                            <Glyphicon glyph="remove" style={{ marginRight: '0.7rem' }} /> Cancel
                        </Button>
                    </Col>              
                </Row>

                <BootstrapTable data={this.state.searchResults} options={options}
                    selectRow={selectRowProp}              
                    hover pagination style={{ marginTop: '4rem' }}>
                
                    <TableHeaderColumn dataField="medicalRecordReviewID" isKey dataSort>MRR ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="member" dataFormat={this.formatMemberID} dataSort
                        sortFunc={this.customSort}>Member ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="memberName" dataFormat={this.formatMemberName} dataSort
                        sortFunc={this.customSort}>Member Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="project" dataFormat={this.formatProject} dataSort
                        sortFunc={this.customSort}>Project</TableHeaderColumn>
                    <TableHeaderColumn dataField="recordType" dataFormat={this.formatRecordTypeReviewTypeReviewStatus} dataSort
                        sortFunc={this.customSort}>Record Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="reviewType" dataFormat={this.formatRecordTypeReviewTypeReviewStatus} dataSort
                        sortFunc={this.customSort}>Review Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="reviewStatus" dataFormat={this.formatRecordTypeReviewTypeReviewStatus} dataSort
                        sortFunc={this.customSort}>Review Status</TableHeaderColumn>
                    <TableHeaderColumn dataField="updatedDateTime" dataFormat={this.formatDate} dataSort>Last Updated</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" dataFormat={this.formatActions}>Action</TableHeaderColumn>
                </BootstrapTable>
         
                <AlertDialog visible={this.state.showConfirmCancelModal} 
                handleModalToggle={this.toggleConfirmCancelModal} renderBody={true} title={"Cancel Reviews?"}
                message={"Are you sure you want to cancel the review(s)? Doing so will delete the entries made by the user and the review will be set to Previous status"} handleConfirm={this.cancelReviews} 
                confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"} glyphicon={"ok"}/>

                <Modal  show={this.state.modalStatus}  onHide={this.closeModal} style={{ height: 'auto',width:'auto',top:'30%',left:'30%',bottom:'auto',right:'auto'}} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Cancel Review</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {this.state.modalMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
                     
            </div>
        );
    }
}

export default Incomplete;
import React from 'react';
import { Col, Collapse, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import WorkflowSectionHeader from '../AlertWorkflow/WorkflowSectionHeader';

const OOAAssignedInfo = (props) => {
    return (
        <div className="alert-workflow-subcontainer">
            <WorkflowSectionHeader expanded={props.expanded} toggle={props.toggleSection}
                toggleKey="assigned" title="Assigned" />
            <Collapse in={props.expanded}>
                <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Organization Code</ControlLabel>
                                <FormControl type="text" value={props.alertData.providerOrgCode} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Organization Name</ControlLabel>
                                <FormControl type="text" value={props.alertData.providerOrgName} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Practice Code</ControlLabel>
                                <FormControl type="text" value={props.alertData.practiceCode} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Practice Name</ControlLabel>
                                <FormControl type="text" value={props.alertData.practiceName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider ID</ControlLabel>
                                <FormControl type="text" value={props.alertData.providerID} disabled />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider NPI</ControlLabel>
                                <FormControl type="text" value={props.alertData.providerNPI} disabled />
                            </FormGroup>
                        </Col>                       
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>Provider Name</ControlLabel>
                                <FormControl type="text" value={props.alertData.providerFullName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>    
    );
};

export default OOAAssignedInfo;
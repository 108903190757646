import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Radio, Row } from 'react-bootstrap';
import Select from 'react-select';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';

const VPNInfo = (props) => {
    const handleVPNChange = (e) => {
        props.handleWorkflowFieldChange({ vpn: !props.vpnInfo.vpn });
    }

    const handleVPNSoftwareChange = (e) => {
        props.handleWorkflowFieldChange({ vpnSoftware: e ? e.value : '' });
    }

    const handleAccessTypeChange = (e) => {
        props.handleWorkflowFieldChange({ accessType: e ? e.value : '' });
    }

    const handleURLOrIPChange = (e) => {
        props.handleWorkflowFieldChange({ urlOrIP: e.target.value });
    }

    const handleDomainChange = (e) => {
        props.handleWorkflowFieldChange({ domain: e.target.value });
    }

    const handlePortChange = (e) => {
        const val = parseInt(e.target.value, 10);
        props.handleWorkflowFieldChange({ port: Number.isInteger(val) ? val : '' });
    }

    const handleNotesChange = (e) => {
        props.handleWorkflowFieldChange({ notes: e.target.value });
    }

    return (
        <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel className="requiredField">VPN?</ControlLabel>
                        <div>
                            <Radio style={{ marginLeft: '1rem' }} onChange={handleVPNChange}
                                value="yes" checked={props.vpnInfo.vpn} inline disabled={props.readOnly}>Yes
                            </Radio>
                            <Radio onChange={handleVPNChange}
                                value="no" checked={!props.vpnInfo.vpn} inline disabled={props.readOnly}>No
                            </Radio>
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel className="requiredField">VPN Software</ControlLabel>
                        <Select value={props.vpnInfo.vpnSoftware} options={props.vpnSoftwareOptions}
                            onChange={handleVPNSoftwareChange} disabled={props.readOnly || !props.vpnInfo.vpn} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.VPNSoftware)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <ControlLabel className="requiredField">Access Type</ControlLabel>
                        <Select value={props.vpnInfo.accessType} options={props.accessTypeOptions}
                            onChange={handleAccessTypeChange} disabled={props.readOnly || !props.vpnInfo.vpn} />
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <div style={{ paddingTop: '2.4rem' }}>
                        <Button onClick={() => props.handleAddRefItemModalOpen(CampaignEMRReferenceItemType.AccessType)}
                            disabled={props.readOnly}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel className="requiredField">URL / IP</ControlLabel>
                        <FormControl type="text" value={props.vpnInfo.urlOrIP} onChange={handleURLOrIPChange}
                            disabled={props.readOnly || !props.vpnInfo.vpn} maxLength="255" />
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>Domain</ControlLabel>
                        <FormControl type="text" value={props.vpnInfo.domain} onChange={handleDomainChange}
                            disabled={props.readOnly || !props.vpnInfo.vpn} maxLength="255" />
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup>
                        <ControlLabel>Port</ControlLabel>
                        <FormControl type="text" value={props.vpnInfo.port} onChange={handlePortChange}
                            disabled={props.readOnly || !props.vpnInfo.vpn} maxLength="65535" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <FormGroup>
                        <ControlLabel>Notes</ControlLabel>
                        <FormControl type="text" value={props.vpnInfo.notes} onChange={handleNotesChange}
                            disabled={props.readOnly || !props.vpnInfo.vpn} />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default VPNInfo;
import React from 'react';
import { Glyphicon, Panel } from 'react-bootstrap';

const WorkflowErrors = (props) => {
    return props.validationMessages && props.validationMessages.length > 0 ? (
        <Panel>
            <Panel.Body style={{ padding: '0.6rem 1rem' }}>
                <h4>
                    <Glyphicon glyph="exclamation-sign"
                        style={{ color: '#a94442', marginRight: '1rem' }} />
                    Validation Issues:
                </h4>
                <ul style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                {
                    props.validationMessages.map(msg => {
                        return <li key={msg}>{msg}</li>
                    })
                }
                </ul>
            </Panel.Body>
        </Panel>
    ) : null;
}

export default WorkflowErrors;
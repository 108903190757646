import React from 'react';
import axios from 'axios';
import { FormControl, ControlLabel, Tab, Tabs } from 'react-bootstrap';
import AlertToastr from '../../Shared/AlertToastr';
import SearchFilter from './SearchFilter';
import ReportContainer from '../../Reports/ReportContainer';
import ResultTable from './ResultTable';

class Landing extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            rankingRecords: [],
            projects: [],
            currentAuditProject: {
                radvAuditID: '',
                projectID: '',
                projectName: ''
            },
            selectedTab: 2,
            toast: null,
            showStatusHistoryModal: false,
        };
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        axios.get('/RADVAuditProjects')
        .then((response) => {
            let currentAuditProject = localStorage.getItem("currentAuditProject");
            currentAuditProject = currentAuditProject ? JSON.parse(currentAuditProject) : null;
            if (currentAuditProject && response.data.find(x => x.projectID === currentAuditProject.projectID)) {
                this.setState({
                    projects: [...response.data],
                    currentAuditProject: JSON.parse(localStorage.currentAuditProject),
                });
            }
            else {
                this.setState({ projects: [...response.data] });
            }
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to load RADV projects.');
        });
    }

    handleProjectChange = (evt) => {
        const projectOption = this.state.projects.find(x => x.radvAuditID === parseInt(evt.target.value, 10));
        const currentAuditProject = projectOption ? {
            radvAuditID: projectOption.radvAuditID,
            projectName: projectOption.projectName,
            projectID: projectOption.projectID
        } : {
                radvAuditID: '',
                projectName: '',
                projectID: '',
        };

        if (evt.target.value !== "Select") {
            localStorage.setItem("currentAuditProject", JSON.stringify(currentAuditProject));
        }
        else {
            localStorage.setItem("currentAuditProject", JSON.stringify({}));
        }
        this.setState({ currentAuditProject: currentAuditProject });
    }

    handleSelectTab = (key) => {
        this.setState({ selectedTab: key });
    }

    handleSearchAction = (params) => {
        if (this.state.currentAuditProject.radvAuditID) {
                axios.get(`/RADVAudits/${this.state.currentAuditProject.radvAuditID}/EnrolleeReport`, {
                params: {
                    enrolleeID: params.enrolleeID,
                    includeEnrolleeWithStatusGatheringEvidence: params.gatheringEvidence,
                    includeEnrolleeWithStatusFinalizedInCDAT: params.finalizedInCDAT,
                    includeEnrolleeWithStatusComplete: params.complete
                }
            })
            .then((response) => {
                this.setState({ rankingRecords: response.data });
            })
            .catch((error) => {
                this.state.toast('error', 'Failed to load search results.');
            })
        }
    }
    openStatusHistory = () => {
      this.setState({showStatusHistoryModal: true })
    }
    closeStatusHistoryModal = () => {
        this.setState({showStatusHistoryModal: false })
    }

    //todo: convert the layout in this component tree to use react-bootstrap
    render() {
        return (
            <div className="RADVLanding">
                <h5 style={{ textAlign: 'center', fontWeight: 'bold', visibility:
                    this.state.currentAuditProject && this.state.currentAuditProject.projectName !== "Select" ? 'visible' : 'hidden' }}>
                    {this.state.currentAuditProject.projectName}&nbsp;
                </h5>
                <div>
                    <div style={{ float: 'left', width: '25%' }}>
                        <ControlLabel>Project:</ControlLabel>
                        <FormControl componentClass="select" placeholder="select" value={this.state.currentAuditProject.radvAuditID} onChange={this.handleProjectChange}>
                            <option value="Select">Select</option>
                            {
                                this.state.projects.map((pr) => <option key={`radvProjectSelection-${pr.radvAuditID}`} value={pr.radvAuditID}>{pr.projectName}</option>)
                            }
                        </FormControl>
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <br/>
                <div>
                    <Tabs id="landingTabs" defaultActiveKey={1} animation={true} onSelect={this.handleSelectTab}>
                        <Tab eventKey={1} title="Ranking and Tracking Tool">
                        {
                            this.state.currentAuditProject.radvAuditID &&
                            <div>
                                <SearchFilter openStatusHistory={this.openStatusHistory} projectID={this.state.currentAuditProject.projectID !== "Select" ? this.state.currentAuditProject.projectID : null}
                                    searchAction={this.handleSearchAction} />
                                <br/>
                                <ResultTable
                                 closeStatusHistoryModal={this.closeStatusHistoryModal}
                                 showStatusHistoryModal={this.state.showStatusHistoryModal} 
                                 records={this.state.rankingRecords}
                                 radvAuditID={this.state.currentAuditProject.radvAuditID} toast={this.state.toast} />
                            </div>
                        }
                        </Tab>
                        <Tab eventKey={2} title="RADV Reporting">
                        {
                            this.state.currentAuditProject.radvAuditID &&
                            <ReportContainer radvAuditID={this.state.currentAuditProject.radvAuditID !== "Select" ? this.state.currentAuditProject.radvAuditID : null}
                                projectID={this.state.currentAuditProject.projectID !== "Select" ? this.state.currentAuditProject.projectID : null} toast={this.state.toast} />
                        }
                        </Tab>
                    </Tabs>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        );
    }
}

export default Landing;
import React from 'react';
import { Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';

class PackageTableSearch extends React.Component {
    constructor(props) {
        super(props);
        const today = new Date();
        this.state = {
            providerID: '',
            practiceGroupingID: '',
            createdDateMin: new Date(new Date().setDate(today.getDate() - 30)).toISOString(),
            createdDateMax: today.toISOString(),
            showAllUsers: false,
            practiceSearchResults: []
        };
    }

    componentDidMount() {
        this.handleSearch();
    }

    handleProviderChange = (e) => {
        this.setState({
            providerID: e.target.value,
            practiceGroupingID: ''
         });
    }

    handlePracticeChange = (e) => {
        this.setState({ practiceGroupingID: e.target.value });
    }

    //todo: determine date format for param
    handleDateChange = (value, formattedValue, dateField) => {
        if(dateField === 'Min') {
            this.setState({
                createdDateMin: value // ISO String, ex: "2016-11-19T12:00:00.000Z"
            });
        }
        else {
            this.setState({
                createdDateMax: value // ISO String, ex: "2016-11-19T12:00:00.000Z"
            });
        }
    }

    handleShowAllUsersChange = (e) => {
        this.setState({ showAllUsers: !this.state.showAllUsers });
    }

    handleSearch = () => {
        const searchParams = {
            statuses: ['Created'],
            providerID: this.state.providerID,
            groupingID: this.state.practiceGroupingID,
            showAllUsers: this.state.showAllUsers
        };

        if(this.props.status === "Completed") {
            Object.assign(searchParams, {
                statuses: ['UserFinalized', 'Complete', 'CreatingPDFs', 'PDFsCreated', 'BundlingPDFs', 'BundlingCompleted', 'SyncingWorkflows', 'SyncCompleted'],
                createdDateMin: this.props.formatDateString(this.state.createdDateMin),
                createdDateMax: this.props.formatDateString(this.state.createdDateMax)
            });
        }

        this.props.searchPackages(searchParams);
    }

    render() {
        const practices = this.state.providerID ?
            this.props.practices.filter((practice) => {
                return practice.providerOrganizationID === this.state.providerID ? practice : false;
            })
            : this.props.practices ? this.props.practices : [];
        const providers = this.props.providers ? this.props.providers : [];

        let dateSearch = null;
        if(this.props.status === "Completed") {
            dateSearch =
            (
                <Col sm={4}>
                    <Row>
                        <Col sm={6} style={{paddingLeft: '0rem'}}>
                            <FormGroup>
                                <ControlLabel>Created Date</ControlLabel>
                                <DatePicker value={this.state.createdDateMin} onChange={(value, formattedValue) => this.handleDateChange(value, formattedValue, 'Min')}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <ControlLabel>&nbsp;</ControlLabel>
                                <DatePicker value={this.state.createdDateMax} onChange={(value, formattedValue) => this.handleDateChange(value, formattedValue, 'Max')}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            )
        }

        return (
            <Grid fluid style={{'marginTop': '3.2rem', 'paddingLeft': '13px'}}>
                <Row style={{'marginBottom': '1rem'}}>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel>Provider Org</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.providerID} onChange={this.handleProviderChange}>
                                <option value="">Select</option>
                                {
                                    providers.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup style={{width: '93%'}}>
                            <ControlLabel>Practice</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.practiceGroupingID} onChange={this.handlePracticeChange}>
                                <option value="">Select</option>
                                {
                                    practices.map((item) => {
                                        return <option key={item.groupingID} value={item.groupingID}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    {dateSearch}
                </Row>
                <Row style={{'marginBottom': '1rem'}}>
                    <Col sm={12}>
                        <FormGroup>
                            <Button onClick={this.handleSearch}>
                                <Glyphicon glyph="search" style={{'marginRight': '0.2rem'}} /> Search Packages
                            </Button>
                            <Checkbox onChange={this.handleShowAllUsersChange} checked={!this.state.showAllUsers} style={{display: 'inline', marginLeft: '6.25rem', verticalAlign: 'middle'}}> My Alert Packages Only</Checkbox>
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default PackageTableSearch;
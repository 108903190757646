import React from 'react';
import axios from 'axios';
import { Button, Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import AlertDialog from '../../Shared/AlertDialog';
import PackageTableSearch from './PackageTableSearch';

const mapStateToProps = (state) => {
    const currentUser = state.session.find(x => x.type === 'SET_CURRENT_USER');
    return {
        currentUser: currentUser ? currentUser.currentUser : null
    };
}

class PackageTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            page: 1,
            sizePerPage: 10,
            showConfirmDeleteModal: false,
            targetPackageID: null,
            canModifyRow: [],
            loaded: false
        };
        this.openTooltip = (
            <Tooltip id="open"><strong>Open Package</strong></Tooltip>
        );
        this.viewTooltip = (
            <Tooltip id="update"><strong>View Package</strong></Tooltip>
        );
        this.deleteTooltip = (
            <Tooltip id="cancel"><strong>Delete Package</strong></Tooltip>
        );
    }

    searchPackages = (params) => {
        this.setState({ loaded: false });
        //search packages via selected params
        axios.get('/packages/', {
            params: {
                statuses: params.statuses,
                providerOrgId: params.providerID,
                practiceId: params.groupingID,
                minCreateDate: params.createdDateMin,
                maxCreateDate: params.createdDateMax,
                showAllUsers: params.showAllUsers
            }
        })
        .then((response) => {
            this.setCanModifyRow(response.data);
            this.setState({
                searchResults: response.data,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.props.toast('error', 'Could not load data.', 'Error');
        });
    }

    deletePackage = () => {
        const packageID = this.state.targetPackageID;
        axios.delete('/packages/' + packageID)
        .then((response) => {
            //remove from UI on successful delete
            const tempResults = {...this.state.searchResults};
            tempResults.items = tempResults.items.filter((item) => {
                return item.packageID !== packageID;
            });
            this.setCanModifyRow(tempResults);
            this.setState({
                searchResults: tempResults
            });

            //close modal
            this.toggleConfirmDeleteModal();
        })
        .catch((error) => {
            this.toggleConfirmDeleteModal();
            this.props.toast('error', 'Could not delete package.', 'Error');
        });
    }

    //determine which rows were created by the current user / can be resumed or deleted
    setCanModifyRow = (searchResults) => {
        const canModify = [];
        searchResults.items.forEach(result => {
            if(result.createdBy === this.props.currentUser.fullName && (result.packageStatusID === 'SyncCompleted' || result.packageStatusID === 'Created')) {
                canModify.push(true);
            }
            else {
                canModify.push(false);
            }
        });
        this.setState({
            canModifyRow: canModify
        });
    }

    //redirect to the last active workflow screen
    handleResume = (rowIndex) => {
        this.props.router.push("/AlertPackages/" + this.inProgressTable.state.data[rowIndex].packageID);
    }

    handleDelete = (rowIndex) => {
        const targetPackageID = this.inProgressTable ?
            this.inProgressTable.state.data[rowIndex].packageID
            : this.completedTable.state.data[rowIndex].packageID;

        this.setState({
            targetPackageID: targetPackageID
        });
        this.toggleConfirmDeleteModal();
    }

    handleViewPackage = (rowIndex) => {
        //redirect to package review screen
        this.props.router.push("/AlertPackages/" + this.completedTable.state.data[rowIndex].packageID + "/PackageReview");
    }

    toggleConfirmDeleteModal = () => {
        this.setState({ showConfirmDeleteModal: !this.state.showConfirmDeleteModal });
    }

    formatDateString = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '';
    }

    //display mappings for PackageStatuses enum
    formatStatus = (status) => {
        switch(status) {
            case 'Cancelled':
            case 'Created':
                return status;
            case 'UserFinalized':
            case 'CreatingPDFs':
            case 'PDFsCreated':
            case 'BundlingPDFs':
                return 'Building Package';
            case 'BundlingCompleted':
            case 'SyncingWorkflows':
                return 'Generating Workflows';
            case 'SyncCompleted':
            case 'Complete':
                return 'Ready';
            default:
                return '';
        }
    }

    renderTable = (results) => {
        const customSelectInProgress = (props) => {
            if(props.rowIndex !== 'Header') {
                //calculate index relative to the entire (non-paged) data set
                const currentRowIndex = props.rowIndex + ((this.state.page - 1) * this.state.sizePerPage);
                if(!this.state.canModifyRow[currentRowIndex]) {
                    return (
                        <div>
                            <OverlayTrigger placement="top" overlay={this.openTooltip}>
                                <Button onClick={() => this.handleResume(props.rowIndex)} disabled>
                                    <Glyphicon glyph="share-alt" />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.deleteTooltip}>
                                <Button onClick={() => this.handleDelete(props.rowIndex)} style={{'marginLeft': '1rem'}} disabled>
                                    <Glyphicon glyph="trash" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            <OverlayTrigger placement="top" overlay={this.openTooltip}>
                                <Button onClick={() => this.handleResume(props.rowIndex)}>
                                    <Glyphicon glyph="share-alt" />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.deleteTooltip}>
                                <Button onClick={() => this.handleDelete(props.rowIndex)} style={{'marginLeft': '1rem'}}>
                                    <Glyphicon glyph="trash" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    );
                }
            }
            else {
                return null;
            }
        };
        const selectRowInProgress = {
            mode: 'radio',
            customComponent: customSelectInProgress,
            columnWidth: '11rem'
        };

        const customSelectCompleted = (props) => {
            const rowIndex = props.rowIndex;
            
            if(rowIndex === 'Header')
                return null;
            
            //calculate index relative to the entire (non-paged) data set
            const currentRowIndex = props.rowIndex + ((this.state.page - 1) * this.state.sizePerPage);
            if(!this.state.canModifyRow[currentRowIndex]) {
                return (
                    <div>
                        <OverlayTrigger placement="top" overlay={this.viewTooltip}>
                            <Button onClick={() => this.handleViewPackage(props.rowIndex)}>
                                <Glyphicon glyph="search" />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={this.deleteTooltip}>
                            <Button onClick={() => this.handleDelete(props.rowIndex)} style={{'marginLeft': '1rem'}} disabled>
                                <Glyphicon glyph="trash" />
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <OverlayTrigger placement="top" overlay={this.viewTooltip}>
                            <Button onClick={() => this.handleViewPackage(props.rowIndex)}>
                                <Glyphicon glyph="search" />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={this.deleteTooltip}>
                            <Button onClick={() => this.handleDelete(props.rowIndex)} style={{'marginLeft': '1rem'}}>
                                <Glyphicon glyph="trash" />
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            }
        };
        const selectRowCompleted = {
            mode: 'radio',
            customComponent: customSelectCompleted,
            columnWidth: '11rem'
        };

        const options = {
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    sizePerPage: sizePerPage
                });
            }
        };

        if(this.props.status !== "Completed")
        {
            return (
                <div>
                    <BootstrapTable data={results} ref={(table) => { this.inProgressTable = table }} selectRow={selectRowInProgress} options={options}
                        maxHeight="454px" scroll-top="Top" multiColumnSort={8} hover pagination>
                        <TableHeaderColumn dataField="packageID" dataAlign="left" dataSort isKey hidden>Package ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerOrganization" dataAlign="left" dataSort>Provider Org.</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceName" dataAlign="left" dataSort>Practice Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="numberOfMembers" dataAlign="left" dataSort># of Members</TableHeaderColumn>
                        <TableHeaderColumn dataField="numberOfQueries" dataAlign="left" dataSort># of Queries</TableHeaderColumn>
                        <TableHeaderColumn dataField="createDate" dataAlign="left" dataFormat={this.formatDateString} dataSort>Started</TableHeaderColumn>
                        <TableHeaderColumn dataField="lastUpdateDate" dataAlign="left" dataFormat={this.formatDateString} dataSort>Last Update</TableHeaderColumn>
                        <TableHeaderColumn dataField="createdBy" dataAlign="left" dataSort>Created By</TableHeaderColumn>
                    </BootstrapTable>
                    <AlertDialog visible={this.state.showConfirmDeleteModal} handleModalToggle={this.toggleConfirmDeleteModal} title={"Delete Package?"}
                        message={"Are you sure you want to delete this package?"} handleConfirm={this.deletePackage} confirmLabel={"Delete"} cancelLabel={"Close"} confirmStyle={"success"} glyphicon={"ok"}/>
                </div>
            );
        }
        else {
            return (
                <div>
                    <BootstrapTable data={results} ref={(table) => { this.completedTable = table }} selectRow={selectRowCompleted} options={options}
                        maxHeight="454px" scroll-top="Top" multiColumnSort={9} hover pagination>
                        <TableHeaderColumn dataField="packageID" dataAlign="left" dataSort isKey hidden>Package ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="providerOrganization" dataAlign="left" dataSort>Provider Org.</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceName" dataAlign="left" dataSort>Practice Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="numberOfMembers" dataAlign="left" dataSort># of Members</TableHeaderColumn>
                        <TableHeaderColumn dataField="numberOfQueries" dataAlign="left" dataSort># of Queries</TableHeaderColumn>
                        <TableHeaderColumn dataField="createDate" dataAlign="left" dataFormat={this.formatDateString} dataSort>Started</TableHeaderColumn>
                        <TableHeaderColumn dataField="lastUpdateDate" dataAlign="left" dataFormat={this.formatDateString} dataSort>Last Update</TableHeaderColumn>
                        <TableHeaderColumn dataField="deliveryDate" dataAlign="left" dataFormat={this.formatDateString} dataSort>Delivered</TableHeaderColumn>
                        <TableHeaderColumn dataField="packageStatusID" dataAlign="left" dataFormat={this.formatStatus} dataSort>Status</TableHeaderColumn>
                        <TableHeaderColumn dataField="createdBy" dataAlign="left" dataSort>Created By</TableHeaderColumn>
                    </BootstrapTable>
                    <AlertDialog visible={this.state.showConfirmDeleteModal} handleModalToggle={this.toggleConfirmDeleteModal} title={"Cancel Package?"}
                        message={"If you cancel this package, all of the workflows in it will be cancelled. Do you want to continue?"} handleConfirm={this.deletePackage} confirmLabel={"Yes"} cancelLabel={"Close"} confirmStyle={"success"} glyphicon={"ok"}/>
                </div>
            );
        }
    }

    render() {
        const results = this.state.searchResults.items && this.state.searchResults.items.length > 0 ? this.state.searchResults.items : [];
        return (
                <div style={{'marginTop': '2rem'}}>
                    <PackageTableSearch status={this.props.status} providers={this.props.providers} practices={this.props.practices} searchPackages={this.searchPackages} formatDateString={this.formatDateString} />
                    <Loader loaded={this.state.loaded}>
                        <div style={{'paddingLeft': '4px', 'marginTop': '1.4rem'}}>
                            {this.renderTable(results)}
                        </div>
                    </Loader>
                </div>
        );
    }
}

export default connect(
    mapStateToProps
)(PackageTable);
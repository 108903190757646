import React from 'react';
import HCCProfile from './HCCProfile';
import ReactPopout from '../../../../external/ReactPopout';

class HCCProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPoppedOut: false,
            HCCVersion: 0
        };
        this.hasHccIsPoppedOutProp = false;
        if(this.props.hccIsPoppedOut !== undefined){
            this.hasHccIsPoppedOutProp = true;
        }
    }

    toggleWindow = (version) => {
        if(this.hasHccIsPoppedOutProp)
        {
            this.setState({isPoppedOut: !this.props.hccIsPoppedOut});
            if(this.props.toggleHccWindow){
                this.props.toggleHccWindow(version);
            }
        }
        else {
            this.setState({isPoppedOut: !this.state.isPoppedOut});
        }
    }


    render() {
        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width * .8,
            height: window.screen.height * .8,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };

        //MRR-24571
        //Included this check to redirect to new NLP screen when popout
        const url = window.location.href.includes('NLP') ? `/NLP/HCCProfileNewWindow/${this.props.reviewType}/${window.location.search}&HCCVersionNo=${this.state.HCCVersion}`
                :`/ReviewProcessing/HCCProfileNewWindow/${this.props.reviewType}/${window.location.search}&HCCVersionNo=${this.state.HCCVersion}`;

        return (
            <div>
                {
                    this.state.isPoppedOut &&
                    <ReactPopout title="HCC Profile" onClosing={this.toggleWindow} options={options}
                        url={url}>
                    </ReactPopout>
                }
                <span style={{ display: (this.state.isPoppedOut) ? 'none' : 'initial' }}>
                    <HCCProfile reviewProcessingUIConfig={this.props.reviewProcessingUIConfig} radvAudit={this.props.radvAudit}
                        visible={this.props.visible} toggleTab={this.props.toggleTab} openNewWindow={this.toggleWindow}
                        isPopout={false} />
                </span>
            </div>
        );
    }
}

export default HCCProfileContainer;

import React from 'react';
import { Col, Glyphicon, Grid, Panel, Row } from 'react-bootstrap';

const Error = ({ errorType = 'default' }) => {
    const errorMessages = {
        default: 'An error has occurred.',
        notFound: 'The page you are looking for cannot be found.',
        auth: 'You are not authorized to view this page.',
        previewNotSupported: 'This file preview is not supported.',
        noData: 'No Data to display',
        noImage: 'No Image to display'
    };
    return (
        <Grid fluid style={{maxWidth: window.innerWidth, marginTop: '4rem'}}>
            <Row>
                <Col xs={10} xsOffset={1}>
                    <Panel>
                        <div className="panel-body">
                            <h4><Glyphicon glyph="exclamation-sign" style={{color: "#a94442", marginRight: "1rem"}} />{errorMessages[errorType]}</h4>
                        </div>
                    </Panel>
                </Col>
            </Row>
        </Grid>
    );
}
export default Error;
import React from 'react';
import axios from 'axios';
import { Grid, Row, Col, FormGroup, ControlLabel, Glyphicon, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import LongRunningDownloadAnchor from '../Shared/LongRunningDownloadAnchor';
import { sortDate } from '../../utils/SortUtils';
import AlertToastr from '../Shared/AlertToastr';

class PIRReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectList: [],
            projectID: null,
            eligibilityIDs: [1, 2], // todo: storing Active/Termed as ints for use in Select - determine if this will need to change for the service endpoint
            rafOnly: false,
            pirResults: [],
            toast: null,
            availableYears: []
        };
    }

    componentWillMount() {
        this.getProjectsForDropdown();        
    }

    getProjectsForDropdown = () => {
        return axios.get('/projects')
        .then((response) => {
            this.setState({
                projectList: response.data.map((proj) => {
                    return {
                        label: proj.projectName,
                        value: proj.projectID
                    };
                })
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Could not load project options.', 'Error');
        });
    }

    getPIRResults = () => {
        const params = {
            projectID: this.state.projectID,
            active: this.state.eligibilityIDs.includes(1),
            termed: this.state.eligibilityIDs.includes(2),
            rafOnly: this.state.rafOnly
        }
        if (!this.state.projectID) {
            this.state.toast('error', 'Project must be selected.', 'Error');
        }
        else {
            return axios.get('/Reports/ProjectImprovement/ProjectImprovementReport/GetPIROverview', { params: params })
            .then((response) => {
                const maxValue = response.data ? Math.max(...response.data.map(x => {
                    return x.rafByYear && x.rafByYear.length
                })) : null;
                const maxRecord = response.data.find(x => x.rafByYear && x.rafByYear.length === maxValue);
                const availableYears = maxRecord && maxRecord.rafByYear;
                this.setState({
                    pirResults: response.data,
                    availableYears: availableYears
                });
            });
        }
    }

    getDownloadDetails = () => {
        const params = {
            projectID: this.state.projectID,
            active: this.state.eligibilityIDs.includes(1),
            termed: this.state.eligibilityIDs.includes(2),
            rafOnly: this.state.rafOnly
        }
        return axios.post('/Reports/ProjectImprovement/ProjectImprovementReport/DownloadPIRAtDiagLevel', params);
    }

    handleProjectChange = (project) => {
        this.setState({ 
            projectID: project && project.value 
        });
    };

    handleEligibilityChange = (options) => {
        this.setState({ 
            eligibilityIDs: options.map(item => item.value)
        });
    }

    handleRAFSelect = () => {
        this.setState({
            rafOnly: !this.state.rafOnly
        })
    }

    formatDOB = (cell, row) => {
        return (new Date(cell)).toLocaleDateString();
    }

    formatYear = (cell, row, column) => {
        const yearData = row.rafByYear && row.rafByYear.find(x => x.year === column);
        return yearData ? yearData.rafIncrease : null;
    }

    sortNumeric = (a, b, order, column) => {
        if (column === 'memberID') {
            return order === 'asc' ? a.memberID - b.memberID : b.memberID - a.memberID;
        }
        else {
            const aYearData = a.rafByYear && a.rafByYear.find(x => x.year === column);
            const bYearData = b.rafByYear && b.rafByYear.find(x => x.year === column);
            const aRAF = aYearData ? aYearData.rafIncrease : 0;
            const bRAF = bYearData ? bYearData.rafIncrease : 0;
            return order === 'asc' ? aRAF - bRAF : bRAF - aRAF;
        }
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    render() {
        const eligibilityList = [{value: 1, label: 'Active'}, {value: 2, label: 'Termed'}];
        const options = {
            page: this.state.page,
            sizePerPageList: [5, 10, 50, 100, 250]
                .map(item => { return { text: item.toString(), value: item } }),
            sizePerPage: this.state.pageSize,
            pageStartIndex: 1,
            defaultSortName: 'memberID',
            defaultSortOrder: 'asc',
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                });
            }
        }

        return (
            <div className='reportContainer'>
                <div className='innerReportContainer'>
                    <Grid fluid>
                        <Row style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            <Col>
                                <h3>Project Improvement Report</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={5}>
                                <FormGroup>
                                    <ControlLabel>Project</ControlLabel>
                                    <Select
                                        name="project"
                                        placeholder="Select Project"
                                        options={this.state.projectList}
                                        value={this.state.projectID}
                                        onChange={this.handleProjectChange} />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <ControlLabel>Eligibility</ControlLabel>
                                    <Select
                                        multi
                                        name="eligibility"
                                        placeholder="Select Eligibility"
                                        options={eligibilityList}
                                        value={this.state.eligibilityIDs}
                                        onChange={this.handleEligibilityChange} />
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <Button 
                                    onClick={this.handleRAFSelect} 
                                    className="rafSelectButton"
                                    style={{ 'marginTop': '2.4rem' }}
                                    active={this.state.rafOnly}>RAF Increase Only</Button>
                            </Col>
                            <Col sm={1}>
                                <Button onClick={this.getPIRResults} 
                                    bsStyle="primary"
                                    style={{ 'marginTop': '2.4rem' }}>
                                    <Glyphicon glyph="search" style={{ 'marginRight': '0.2rem' }} /> Search
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ paddingRight: '15px', paddingLeft: '15px', marginTop: '20px', marginBottom: '20px' }}>
                            <BootstrapTable data={this.state.pirResults} options={options} pagination maxHeight="420px">
                                <TableHeaderColumn dataField="memberID" isKey dataSort>Member ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="memberName" dataSort>Member Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="dob" dataSort sortFunc={(a, b, order) => sortDate(a.dob, b.dob, order)} dataFormat={this.formatDOB}>DOB</TableHeaderColumn>
                                {
                                    this.state.availableYears && this.state.availableYears.map(column =>
                                        <TableHeaderColumn key={column.year} dataField={column.rafIncrease.toString()} dataFormat={(cell, row) => this.formatYear(cell, row, column.year)} 
                                        dataSort sortFunc={(a, b, order) => this.sortNumeric(a, b, order, column.year)}>{column.year}</TableHeaderColumn>)
                                }
                                <TableHeaderColumn dataField="totalRAFIncrease" dataSort>Total RAF Score Increase</TableHeaderColumn>
                            </BootstrapTable>
                        </Row>
                        <Row style={{ marginBottom: '1.4rem', display: this.state.projectID && this.state.eligibilityIDs.length > 0 ? 'block': 'none' }}>
                            <Col xs={12}>
                                <hr />
                                <Row style={{ marginBottom: '1.4rem', paddingLeft: '0px' }}>
                                    <Col xs={4} lg={3}>
                                        <h5><b>Diag Level .CSV Report</b></h5>
                                        <LongRunningDownloadAnchor
                                            linkText="Download"
                                            postRequest={this.getDownloadDetails}
                                            displayCSVDownloader
                                            glyph="file" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <AlertToastr setAddToast={this.setAddToast} />
            </div>
        )
    }
}

export default PIRReport;
import React from 'react';
import axios from 'axios';
import { Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import Loader from 'react-loader';
import AlertToastr from '../../../Shared/AlertToastr';
import PracticeGroupingModal from './PracticeGroupingModal';
import PracticeSummaryBulkUpdateModal from './PracticeSummaryBulkUpdateModal';
import PracticeSummaryFilters from './PracticeSummaryFilters';
import PracticeSummaryTable from './PracticeSummaryTable';
import { CampaignEMRReferenceItemType } from '../../../../enums/CampaignEMRReferenceItemType';
import { getRedirect } from '../../../../services/ReviewProcessing';

export default class PracticeSummaryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceCode: '',
            practiceName: '',
            practiceStatus: null,
            retrievalMethod: null,
            providerOrgCode: '',
            providerOrgName: '',
            hospitalMedicalCenter: '',
            practiceFullAddress: '',
            accessType: null,
            practiceGrouping: null,
            engaged: '',
            accountAdmin: null,
            practices: [],
            practiceStatusOptions: [],
            retrievalMethodOptions: [],
            practiceGroupings: [],
            practiceGroupingOptions: [],
            accountAdminOptions: [],
            emrAccessTypeOptions: [],
            loaded: false,
            showPracticeGroupingModal: false,
            showBulkUpdateModal: false,
            toast: null
        };

        this.engagedStatusOptions = [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ];
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentDidMount() {
        Promise.all([
            this.getEMRPractices(),
            this.getPracticeStatuses(),
            this.getRetrievalMethods(),
            this.getPracticeGroupings(),
            this.getUsers(),
            this.getEMRAccessTypes()
        ])
        .then((responses) => {
            this.setState({
                practices: responses[0].data,
                practiceStatusOptions: this.getPracticeStatusDropdownOptions(responses[1].data),
                retrievalMethodOptions: this.getRetrievalMethodDropdownOptions(responses[2].data),
                practiceGroupings: responses[3].data,
                practiceGroupingOptions: this.getPracticeGroupingDropdownOptions(responses[3].data),
                accountAdminOptions: this.getUserDropdownOptions(responses[4].data),
                emrAccessTypeOptions: responses[5].data[0].campaignEMRReferenceItems.map(x => {
                        return {
                            label: x.campaignEMRReferenceItemDescription,
                            value: x.campaignEMRReferenceItemID
                        }
                    }),
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            if (error.response.status === 403) {
                this.state.toast('error', error.response.data.message, 'Error');
                setTimeout(() => {
                    getRedirect({
                        redirectAction: "Index",
                        redirectController: "Landing"
                    });
                }, 1000);
            } else {
                this.state.toast('error', 'Could not load EMR practice data.', 'Error');
            }
        });
    }

    getEMRPractices = () => {
        return axios.get('/campaignPracticeSummary', {
            params: {
                practiceCode: this.state.practiceCode !== null ? this.state.practiceCode : '',
                practiceName: this.state.practiceName !== null ? this.state.practiceName : '',
                practiceStatusID: this.state.practiceStatus,
                campaignRetrievalMethodID: this.state.retrievalMethod !== null ? this.state.retrievalMethod : '',
                practiceOrganizationName: this.state.providerOrgName !== null ? this.state.providerOrgName : '',
                practiceOrganizationCode: this.state.providerOrgCode !== null ? this.state.providerOrgCode : '',
                hospitalMedicalCenter: this.state.hospitalMedicalCenter !== null ? this.state.hospitalMedicalCenter : '',
                practiceFullAddress: this.state.practiceFullAddress !== null ? this.state.practiceFullAddress : '',
                emrAccessType: this.state.accessType !== null ? this.state.accessType : '',
                practiceGroupingID: this.state.practiceGrouping !== null ? this.state.practiceGrouping : '',
                isEngaged: this.state.engaged !== null ? this.state.engaged : '',
                accountAdminUserID: this.state.accountAdmin !== null ? this.state.accountAdmin : '',
            }
        });
    }

    getPracticeStatuses = () => {
        return axios.get('/campaignPracticeSummary/campaignPracticeStatus');
    }

    getRetrievalMethods = () => {
        return axios.get('/campaignPracticeSummary/campaignRetrievalMethod');
    }

    getPracticeGroupings = () => {
        return axios.get('/campaignGroup');
    }

    // todo: add user class filter (e.g. 'campaign admin')?
    getUsers = () => {
        return axios.get('/merlinUser');
    }

    getEMRAccessTypes = () => {
        return axios.get(`/campaignEMRReferenceItem?typeID=${CampaignEMRReferenceItemType.AccessType}`);
    }

    getPracticeStatusDropdownOptions = (statuses) => {
        return statuses.map((x) => {
            return {
                label: x.campaignPracticeStatusDescription,
                value: x.campaignPracticeStatusID
            };
        });
    }

    getRetrievalMethodDropdownOptions = (retrievalMethods) => {
        return retrievalMethods.map((x) => {
            return {
                label: x.campaignRetrievalMethodDescription,
                value: x.campaignRetrievalMethodID
            };
        });
    }

    getPracticeGroupingDropdownOptions = (practiceGroupings) => {
        return practiceGroupings.map((x) => {
            return {
                label: `${x.groupCode} - ${x.groupName}`,
                value: x.campaignGroupID
            };
        });
    }

    getUserDropdownOptions = (users) => {
        return users.map((x) => {
            return {
                label: x.fullName,
                value: x.userID
            };
        });
    }

    updateRow = (row) => {
        axios.put(`/campaignPracticeSummary/${row.campaignPracticeID}`, {
            campaignPracticeID: row.campaignPracticeID,
            practiceStatusID: row.practiceStatusID,
            campaignRetrievalMethodID: row.campaignRetrievalMethodID,
            practiceGroupingID: row.practiceGroupingID,
            accountAdminUserID: row.accountAdminUserID
        })
        .then((response) => {
            this.setState({ practices: [...this.state.practices.filter(x => x.campaignPracticeID !== row.campaignPracticeID),
                {...response.data}] });
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to save.', 'Error');
        });
    }

    setPractices = (practices) => {
        this.setState({ practices });
    }

    getSelectedPractices = (searchResults = this.state.practices) => {
        return searchResults ? searchResults.filter(x => x.selected).map(x => x.campaignPracticeID) : [];
    }

    togglePracticeGroupingsModal = () => {
        this.setState((prevState) => {
            return { showPracticeGroupingModal: !prevState.showPracticeGroupingModal }
        });
    }

    toggleBulkUpdateModal = (refreshResults = false) => {
        // safety check to ensure refreshResults is supplied (versus an event, etc.)
        if (typeof(refreshResults !== "object")) {
            this.setState((prevState) => {
                return { showBulkUpdateModal: !prevState.showBulkUpdateModal }
            });

            if (refreshResults) {
                this.handleSearchButton();
            }
        }
    }

    refreshPracticeGroupings = () => {
        this.getPracticeGroupings()
        .then((response) => {
            this.setState({
                practiceGroupings: response.data,
                practiceGroupingOptions: this.getPracticeGroupingDropdownOptions(response.data)
            });
        })
        .catch((error) => {
            this.state.toast('error', 'Failed to load practice groupings.', 'Error');
        });
    }

    handleSearchButton = () => {
        this.setState({ loaded: false });

        this.getEMRPractices()
        .then((response) => {
            this.setState({
                practices: response.data,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.state.toast('error', 'Could not load search results.', 'Error');
        });
    }

    // sets a state property via a single-property object (updateObj)
    // { [state property name]: [state property value] }
    handleFilterFieldChange = (updateObj) => {
        const updateEntry = Object.entries(updateObj);
        this.setState({ [updateEntry[0][0]]: updateEntry[0][1] });
    }

    render() {
        const initialLoadComplete = this.state.practiceStatusOptions?.length > 0;
        const selectedPracticeCount = this.getSelectedPractices().length;

        return (
            <Grid fluid style={{ marginBottom: '4rem' }}>
                <Row>
                    <Col xs={9} style={{ paddingLeft: '0rem' }}>
                        <h3 style={{ display: 'inline-block' }}>EMR Summary</h3>
                    </Col>
                    <Col xs={3} style={{ paddingTop: '0.6rem', paddingRight: '0' }}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={this.togglePracticeGroupingsModal}>
                                Practice Groupings
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '1rem' }}>
                    <hr style={{ marginTop: '0rem' }} />
                </Row>
                <Row style={{ marginBottom: '1rem' }}>
                {
                    initialLoadComplete &&
                    <PracticeSummaryFilters practiceCode={this.state.practiceCode} practiceName={this.state.practiceName}
                        practiceStatus={this.state.practiceStatus} retrievalMethod={this.state.retrievalMethod}
                        providerOrgCode={this.state.providerOrgCode} providerOrgName={this.state.providerOrgName}
                        hospitalMedicalCenter={this.state.hospitalMedicalCenter} practiceFullAddress={this.state.practiceFullAddress}
                        accessType={this.state.accessType} practiceGrouping={this.state.practiceGrouping} engaged={this.state.engaged}
                        accountAdmin={this.state.accountAdmin} practiceStatusOptions={this.state.practiceStatusOptions}
                        retrievalMethodOptions={this.state.retrievalMethodOptions} accessTypeOptions={this.state.emrAccessTypeOptions}
                        practiceGroupingOptions={this.state.practiceGroupingOptions} engagedStatusOptions={this.engagedStatusOptions}
                        accountAdminOptions={this.state.accountAdminOptions} handleFilterFieldChange={this.handleFilterFieldChange} />
                }
                </Row>
                {
                    initialLoadComplete &&
                    <Row style={{ marginTop: '0.7rem', marginBottom: '4rem' }}>
                        <Col xs={3} style={{ paddingRight: '0', paddingLeft: '0.5rem' }}>
                            <Button onClick={this.handleSearchButton} bsStyle="primary" style={{ float: 'left', marginRight: '2rem' }}>
                                <Glyphicon glyph="search" style={{ marginRight: '0.5rem' }} />Search
                            </Button>
                            <Button onClick={() => this.toggleBulkUpdateModal(false)} disabled={selectedPracticeCount <= 0}>
                                Bulk Update
                            </Button>
                        </Col>
                    </Row>
                }
                <Row style={{ marginBottom: '1rem' }}>
                    <Loader loaded={this.state.loaded}>
                        <PracticeSummaryTable practices={this.state.practices} practiceStatusOptions={this.state.practiceStatusOptions}
                            retrievalMethodOptions={this.state.retrievalMethodOptions} accessTypeOptions={this.state.emrAccessTypeOptions}
                            practiceGroupingOptions={this.state.practiceGroupingOptions} engagedStatusOptions={this.engagedStatusOptions}
                            accountAdminOptions={this.state.accountAdminOptions} handleUpdateRow={this.updateRow} setPractices={this.setPractices}
                            getSelected={this.getSelectedPractices} />
                    </Loader>
                </Row>
                <PracticeGroupingModal showPracticeGroupingModal={this.state.showPracticeGroupingModal}
                    practiceGroupings={this.state.practiceGroupings} practiceGroupingOptions={this.state.practiceGroupingOptions}
                    refreshPracticeGroupings={this.refreshPracticeGroupings} togglePracticeGroupingsModal={this.togglePracticeGroupingsModal}
                    router={this.props.router} toast={this.state.toast} />
                <PracticeSummaryBulkUpdateModal showBulkUpdateModal={this.state.showBulkUpdateModal}
                    toggleBulkUpdateModal={this.toggleBulkUpdateModal} practices={this.state.practices} practiceStatusOptions={this.state.practiceStatusOptions}
                    retrievalMethodOptions={this.state.retrievalMethodOptions} accessTypeOptions={this.state.emrAccessTypeOptions}
                    practiceGroupingOptions={this.state.practiceGroupingOptions} accountAdminOptions={this.state.accountAdminOptions}
                    getSelected={this.getSelectedPractices} toast={this.state.toast} />
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        );
    }
}
// exports PackageComponentType - an immutable object 'enum'
// usage: import { PackageComponentType } from '../../enums/PackageComponentType';
const packageComponentTypes = {
    CoverSheet: 1,
    FaxCoverSheet: 2,
    InstructionSheet: 3,
    AlertDocumentationTips: 4,
    AlertCompletionTips: 5,
    AlertReferenceTool: 6,
    ValidationFax: 7,
    QueryFaxCoverSheet: 8,
    CDIQuickOverview: 9
};
Object.freeze(packageComponentTypes);
export const PackageComponentType = packageComponentTypes;
import React from 'react';
import { findDOMNode } from 'react-dom';
import { Checkbox, Form, FormGroup } from 'react-bootstrap';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import CodingDetailsNotLanding from '../Shared/CodingDetailsNotLanding'
import PDFViewerComponent from '../Shared/PDFViewerComponent'
import AttestationModal from '../Ranking/AttestationModal'
 
class Card extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: Boolean(props.submitted),
            rowClass: props.submitted ? "info" : ""
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.submitted) {
            this.setState({
                checked: nextProps.submitted,
                rowClass: "info"
            });
        }
        else {
            this.setState({
                checked: nextProps.submitted,
                rowClass: ""
            });
        }
    }

    isFinalizedOrComplete = () => {
        return this.props.enrolleeStatusID === 2 || this.props.enrolleeStatusID === 3;
    }
 
    //todo: refactor this
    render() {
        const backgroundColor = this.isFinalizedOrComplete() ? "#f8f8f8" : "";
        const dosFrom = this.props.dosFrom ? new Date(this.props.dosFrom).toLocaleDateString('en-US') : "";
        const dosThru = this.props.dosThru ? new Date(this.props.dosThru).toLocaleDateString('en-US') : "";
        const evidenceID = this.props.medicalRecordImageID !== null && this.props.medicalRecordImageID > 0 ?
            this.props.medicalRecordImageID : "";

        return this.props.connectDragSource(this.props.connectDropTarget(
            <tr style={{ cursor: 'move', opacity: this.props.isDragging ? 0 : 1, backgroundColor: backgroundColor }} className={this.state.rowClass} id={this.props.id}>
                <td>{this.props.rank === null ? this.props.systemRank : this.props.rank}</td>
                <td>{this.props.coversheetID ? this.props.coversheetID : ""}</td>
                <td>{this.props.scoreDesc ? this.props.scoreDesc : ""}</td>
                <td>{this.props.targetedHCC ? this.props.targetedHCC : ""}</td>
                <td>{this.props.nonrelatedHCC ? this.props.nonrelatedHCC : ""}</td>
                <td>{this.props.providerTypeDesc ? this.props.providerTypeDesc : ""}</td>
                <td>{dosFrom}</td>
                <td>{dosThru}</td>
                <td><AttestationModal row={this.props} dos={dosFrom} dosThru={dosThru} medicalRecordDiagID={this.props.medicalRecordDiagID} toast={this.props.toast} /></td>
                <td><CodingDetailsNotLanding medicalRecordDiagRADVDetailID={this.props.card.medicalRecordDiagRADVDetailID} toast={this.props.toast} /></td>
                <td><PDFViewerComponent uri={`/medicalrecordreview/${this.props.card.medicalRecordReviewID}/images/${evidenceID}`}
                    displayText={evidenceID} toast={this.props.toast} /></td>
                <td>{this.props.updatedOn ? new Date(this.props.updatedOn).toLocaleString('en-US') : ""}</td>
                <td>
                    <Form>
                        <FormGroup>
                            <Checkbox disabled={this.isFinalizedOrComplete() || this.props.attestation === "Y"} checked={this.state.checked}
                                onChange={() => this.props.handleSubmitCheckbox(this.props.card, this.state.checked)}>Submitted</Checkbox>
                        </FormGroup>
                    </Form>
                </td>
            </tr>
        ));
    }
}

const cardSource = {
    beginDrag(props) {
        return {
            index: props.index,
            listId: props.listId,
            card: props.card
        };
    },

    canDrag(props) {
        return props.enrolleeStatusID !== 2 && props.enrolleeStatusID !== 3;
    },
 
    endDrag(props, monitor) {
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();
 
        if (dropResult && dropResult.listId !== item.listId) {
            props.removeCard(item.index);
        }
    }
};

const cardTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        const sourceListId = monitor.getItem().listId;
 
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
 
        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
 
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
 
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
 
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
 
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
 
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }
 
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }
 
        // Time to actually perform the action
        if ( props.listId === sourceListId ) {
            props.moveCard(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        }
    }
};

export default flow(
    DropTarget("CARD", cardTarget, connect => ({
        connectDropTarget: connect.dropTarget()
    })),
    DragSource("CARD", cardSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(Card);
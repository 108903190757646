import React from 'react';
import axios from 'axios';
import {
    Button,
    Modal,
    Panel,
} from 'react-bootstrap';
import Loader from 'react-loader';

const MODAL_STATE = Object.freeze({
    ERROR: 0,
    NOT_STARTED: 1,
    IN_PROGRESS: 2,
    COMPLETE: 3,
});

export default class ClaimsRecyclingModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: MODAL_STATE.NOT_STARTED,
            error: null,
        };
    }

    componentDidMount() {
        axios.get('/claimlink/recycle/status')
        .then((response) => {
            if (response.data.recyclingInProgress) {
                this.setState({
                    status: MODAL_STATE.IN_PROGRESS
                }, () => {
                    this.interval = setInterval(this.polling, 10000);
                });
            }
        });
    }

    componentWillUnmount() {
        this.removePolling();
    }

    handleStartClick = () => {
        axios.post('/claimlink/recycle')
            .then((response) => {
                this.setState({
                    status: MODAL_STATE.IN_PROGRESS
                }, () => {
                    this.interval = setInterval(this.polling, 10000);
                });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        status: MODAL_STATE.ERROR,
                        error: error.response.status === 400 ?
                            "Claims Linking already in progress" :
                            "An error has occurred"
                    })
                }
            })
    }

    polling = () => {
        axios.get('/claimlink/recycle/status')
            .then((response) => {
                var status = response.data.recyclingInProgress ? MODAL_STATE.IN_PROGRESS : MODAL_STATE.COMPLETE;
                this.setState({ status });
            });
    }

    removePolling = () => {
        if (this.interval)
            clearInterval(this.interval);
    }

    handleHideClick = () => {
        this.removePolling();
        this.props.onClose();
    }

    render() {
        const { visible } = this.props;

        return (
            <Modal
                backdrop="static"
                show={visible}
                onHide={this.handleHideClick}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Start Claims Linking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        {
                            this.state.status === MODAL_STATE.NOT_STARTED &&
                            <Button bsStyle="primary" onClick={this.handleStartClick}>
                                Start
                            </Button>
                        }
                        {
                            (this.state.status === MODAL_STATE.IN_PROGRESS ||
                            this.state.status === MODAL_STATE.COMPLETE) &&
                            <Loader loaded={this.state.status === MODAL_STATE.COMPLETE}>
                                <h4>Completed Claims Linking</h4>
                                <Button bsStyle="primary" onClick={this.handleHideClick}>
                                    Close
                                </Button>
                            </Loader>
                        }
                        {
                            this.state.status === MODAL_STATE.ERROR &&
                            <Panel bsStyle="danger">
                                <Panel.Heading>
                                    <Panel.Title componentClass="h3">
                                        {this.state.error}
                                    </Panel.Title>
                                </Panel.Heading>
                            </Panel>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
} 
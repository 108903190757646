import React from 'react';
import { DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap';
import AlertDialog from '../../../Shared/AlertDialog';
import { MedicalRecordType } from '../../../../enums/MedicalRecordType'

class ActionMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalMessage: '',
            handleConfirm: null,
            showConfirmModal: false,
            showEscalateModal:true,
            getNextCDIAlertReview: this.props.getNextAlertReviewDefault
        };

        //todo: confirm that simplifying the message wording and adding confirmations for all actions is desired
        const saveForLaterMessage = 'Save this review for later?';
        const saveForLaterGetNextMessage = 'Save this review for later and get the next review?';

        const completeMessage = 'Complete this review?';
        const completeGetNextMessage = 'Complete this review and get the next review?';

        const skipMessage = 'Skip this review?';
        const skipGetNextMessage = 'Skip this review and get the next review?';

        const sendToMDMessage = 'Complete this review and send it to MD?';
        const sendToMDGetNextMessage = 'Send this review to MD and get the next review?';

        const cancelMessage = 'Cancel this review?';
        const cancelGetNextMessage = 'Cancel this review and get the next review?';

        const exitMessage = 'Exit this review?';
        const exitGetNextMessage = 'Exit this review?';

        this.actions = [
            {
                id: 1,
                action: 'Save For Later',
                label: 'Save for Later',
                confirmationMessage: saveForLaterMessage,
                confirmationMessageGetNext: saveForLaterGetNextMessage
            },
            {
                id: 2,
                action: 'Complete',
                label: 'Complete',
                confirmationMessage: completeMessage,
                confirmationMessageGetNext: completeGetNextMessage
            },
            {
                id: 3,
                action: 'Complete And Get Next',
                label: 'Complete and Get Next',
                confirmationMessage: completeGetNextMessage
            },
            {
                id: 4,
                action: 'Save For Later Coder QA',
                label: 'Save for Later',
                confirmationMessage: saveForLaterMessage,
                confirmationMessageGetNext: saveForLaterGetNextMessage
            },
            {
                id: 5,
                action: 'Skip',
                label: 'Skip',
                confirmationMessage: skipMessage,
                confirmationMessageGetNext: skipGetNextMessage
            },
            {
                id: 6,
                action: 'Complete Coder QA',
                label: 'Complete',
                confirmationMessage: completeMessage,
                confirmationMessageGetNext: completeGetNextMessage
            },
            {
                id: 7,
                action: 'Complete And Get Next Coder QA',
                label: 'Complete and Get Next',
                confirmationMessage: completeMessage,
                confirmationMessageGetNext: completeGetNextMessage
            },
            {
                id: 8,
                action: 'Complete And Send To MD',
                label: 'Complete and Send to MD',
                confirmationMessage: sendToMDMessage,
                confirmationMessageGetNext: sendToMDGetNextMessage
            },
            {
                id: 9,
                action: 'Cancel',
                label: 'Cancel',
                confirmationMessage: cancelMessage,
                confirmationMessageGetNext: cancelGetNextMessage
            },
            {
                id: 10,
                action: 'Exit',
                label: 'Exit',
                confirmationMessage: exitMessage,
                confirmationMessageGetNext: exitGetNextMessage
            },
            {
                id: 11,
                action: 'Escalate',
                label: 'Escalate'
            },
        ];
    }

    //determine the displayed menu options (review actions)
    getActions = () => {
        let actions = [...this.actions];

        if (!this.props.reviewProcessingUIConfig.isCoderQA) {
            if (this.props.reviewProcessingUIConfig.includeCompleteAndGetNext) {
                actions = actions.filter(x => [1, 2, 3, 8, 9].includes(x.id));
            }
            else if (this.props.reviewType === "AutoReview")
            {
                actions = actions.filter(x => [1, 2, 8, 9, 10,11].includes(x.id));
            }
            else {
                actions = actions.filter(x => [1, 2, 8, 9].includes(x.id));
            }
        }
        else {
            if (this.props.reviewProcessingUIConfig.includeCompleteAndGetNext) {
                actions = actions.filter(x => [4, 5, 6, 7, 8, 9].includes(x.id));
            }
            else {
                actions = actions.filter(x => [4, 5, 6, 8, 9,11].includes(x.id));
            }
        }

        if (!this.props.reviewProcessingUIConfig.isQA || !this.props.reviewProcessingUIConfig.isRADV) {
            actions = actions.filter(x => x.id !== 8);
        }

        return actions;
    }

    //handles menu item selection
    handleSelect = (eventKey) => {
        if (eventKey !== "getNextCDIAlertReview") {
            const action = this.actions[eventKey - 1];
            // if action is Save for Later, don't display confirm modal; save directly
            if (action.id === 1) {
                this.props.updateReview(action.action, this.state.getNextCDIAlertReview);
            }
            else if (action.id === 11) {
                if(this.state.showEscalateModal)
                {
                    this.setState({
                    showEscalateModal: false
                    })
                }
                else{
                    this.setState({
                        showEscalateModal: true
                    })
                }                
                this.props.renderEscalateModal(this.state.showEscalateModal);
            }
            // otherwise, display confirm modal
            else {
                this.setState({
                    modalMessage: !this.state.getNextCDIAlertReview ? action.confirmationMessage : action.confirmationMessageGetNext,
                    handleConfirm: () => this.handleConfirm(action.action),
                    showConfirmModal: true
                });
            }
        }
        else {
            this.setState({ getNextCDIAlertReview: !this.state.getNextCDIAlertReview });
        }
    }

    //handles modal confirmation success click (i.e. takes the review action associated with a menu item)
    handleConfirm = (action) => {
        this.props.updateReview(action, this.state.getNextCDIAlertReview);
        this.toggleConfirmModal();
    }

    toggleConfirmModal = () => {
        this.setState({ showConfirmModal: !this.state.showConfirmModal });
    }

    renderActions = () => {
        return this.getActions().map(x => {
            return <MenuItem key={`review-processing-action-${x.id}`} eventKey={x.id}>{x.label}</MenuItem>
        });
    }

    renderDivider = () => {
        return this.props.reviewProcessingUIConfig.reviewTypeID === MedicalRecordType.CDIAlertReview && !this.props.reviewProcessingUIConfig.isQA ?
        (
            <MenuItem divider />
        ) : null;
    }

    renderGetNextCDIAlertReview = () => {
        return this.props.reviewProcessingUIConfig.reviewTypeID === MedicalRecordType.CDIAlertReview && !this.props.reviewProcessingUIConfig.isQA ?
        (
            <MenuItem key="getNextCDIAlertReview" eventKey="getNextCDIAlertReview">
                Get Next Alert Review?
                <Glyphicon glyph="ok" style={{marginLeft: '1rem', visibility: this.state.getNextCDIAlertReview ? 'visible' : 'hidden'}} />
            </MenuItem>
        ) : null;
    }

    render() {
        return (
                <DropdownButton bsStyle={'default'} title={'Actions'} id={'action-menu'} onSelect={this.handleSelect} >
                    { this.renderActions() }
                    { this.renderDivider() }
                    { this.renderGetNextCDIAlertReview() }
                    <AlertDialog visible={this.state.showConfirmModal} handleModalToggle={this.toggleConfirmModal} title={this.state.modalMessage}
                        renderBody={false} handleConfirm={this.state.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                        glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />                        
                </DropdownButton>
        );
    }
}
export default ActionMenu;
import React from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import Error from '../../Shared/Error';

class PDFViewer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageData: null,
            imageType: "application/pdf",
            imageLoading: true,
            showImageError:false
        }
    }

    componentDidMount() {
        axios.get(this.props.uri, {
            responseType: 'arraybuffer'
        })
        .then((response) => {
            this.setState({
                imageData: URL.createObjectURL(new Blob([response.data], { type: this.state.imageType })),
                showImageError: false,
                imageLoading: false
            });
        })
        .catch((error) => {
            this.setState({
                showImageError: true,
                imageLoading: false
            });
        });
    }

    render() {
        return (
            <div style={{height:'800px'}}>
                
                <Loader loaded={!this.state.imageLoading} loadedClassName="loaded-content-full-height">
                    {
                        !this.state.showImageError && this.state.imageData &&
                        <embed width="100%" height="100%" src={this.state.imageData} type={this.state.imageType} />
                    }
                    {
                        this.state.showImageError &&
                        <Error style={{ marginTop: '5rem' }} />
                    }
                </Loader>
                      
            </div>      
        );
    }
}

export default PDFViewer;